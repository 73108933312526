import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypeaheadDirective } from 'ngx-bootstrap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject, defer, iif, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { Doctor, FilterType } from 'src/API';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { ModelWithDates } from 'src/app/core/api/model-utils';
import { Patient } from '../../core/api/patient.service';
import { NameFormat, PersonNamePipe } from '../shared-pipes/person-name.pipe';
import { FilterService } from './../../filter/filter.service';

interface SearchablePatient {
  patientDataToSearch: string;
  fullName: string;
  patient: Partial<Patient>;
}

@Component({
  selector: 'csi-patient-lookup',
  templateUrl: './patient-lookup.component.html',
  styleUrls: ['./patient-lookup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PersonNamePipe]
})
export class PatientLookupComponent implements OnDestroy, AfterViewInit {
  @Input() doctor: Doctor;
  @Input() placeholder: string;
  @Input() addNewPatients = false;
  @Input() clearOnSelect = false;
  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }
  @Input() displayContactInformation: boolean;
  @Output() selectedPatient: EventEmitter<Partial<Patient>> = new EventEmitter<Partial<Patient>>();
  @Output() createNewPatient: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('ngSelectComponent', { static: true }) hostNgSelectComponent: any;

  public isServerErroring = false;
  public _loading = true;
  public get loading() {
    return this._loading;
  }

  public set loading(val: boolean) {
    this._loading = val;
    this.ngZone.runOutsideAngular(() =>
      setTimeout(() => this.changeDetectorRef.detectChanges(), 0)
    );
  }

  public searchControl = new FormControl();
  public filteredPatients$: Observable<SearchablePatient[]>;
  public searchTerm$ = new Subject<string>();

  @ViewChild(TypeaheadDirective, { static: false }) typeAheadDirective: TypeaheadDirective;

  constructor(
    private personNamePipe: PersonNamePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private filterService: FilterService
  ) {
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(searchablePatient => !!searchablePatient)
      )
      .subscribe((searchablePatient: SearchablePatient) => {
        this.onSelect(searchablePatient);
      });

    // Initialize
    this.filteredPatients$ = this.getPatients(this.normalizeSearchTerm('')).pipe(
      tap(() => {
        this.filteredPatients$ = this.searchTerm$.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap((term: string) => this.getPatients(this.normalizeSearchTerm(term))),
          untilDestroyed(this)
        );
      })
    );
  }

  ngAfterViewInit() {
    if (this.addNewPatients) {
      // Code partly taken from https://github.com/ng-select/ng-select/commit/d5e97af72201a3fbedb1fa78d6c69be61776c872
      // See feature request https://github.com/ng-select/ng-select/issues/1232
      Object.defineProperty(this.hostNgSelectComponent, 'showAddTag', {
        get: this.showAddTagRewrite
      });
    }
  }

  ngOnDestroy() {}

  private normalizeSearchTerm(term: string) {
    return term
      ? term
          .toLocaleLowerCase()
          .trim()
          .replace(/\s+/g, ' ')
      : term;
  }

  private onSelect(patient: SearchablePatient | string): void {
    if ((patient as SearchablePatient).patient) {
      this.selectedPatient.emit((patient as SearchablePatient).patient);
    } else {
      this.createNewPatient.emit((patient as SearchablePatient).fullName || (patient as string));
    }
  }

  private showAddTagRewrite = (): boolean => {
    return this.hostNgSelectComponent.searchTerm && this.hostNgSelectComponent.addTag;
  };

  private transformToSearchablePatients(
    items: Partial<Patient>[] & ModelWithDates[]
  ): SearchablePatient[] {
    const searchable: SearchablePatient[] = items.map((patient: Patient) => {
      const fullName = this.personNamePipe.transform(patient, NameFormat.Fullname);
      return {
        fullName,
        patientDataToSearch: (
          fullName +
          ((patient.healthCard && patient.healthCard.province + ' ' + patient.healthCard.number) ||
            '') +
          (patient.email || '') +
          (patient.phone || '')
        ).toLowerCase(),
        patient: patient
      };
    });

    return searchable;
  }

  private sortByBestMatch(searchTerm: string, results: SearchablePatient[]): SearchablePatient[] {
    return results.sort(
      (a, b) =>
        a.patientDataToSearch.indexOf(searchTerm) - b.patientDataToSearch.indexOf(searchTerm)
    );
  }

  private getPatients(searchValue: string): Observable<SearchablePatient[]> {
    this.loading = true;
    return iif(
      () => !!this.doctor && !!this.doctor.clinic,
      defer(() => this.filterService.queryFilterData(FilterType.patient, null)),
      of([])
    ).pipe(
      map((results: Partial<Patient>[]) => {
        this.isServerErroring = false;
        this.loading = false;
        return this.sortByBestMatch(
          searchValue,
          this.filterPatients(searchValue, this.transformToSearchablePatients(results))
        );
      }),
      catchError(err => {
        console.log(err);
        this.isServerErroring = true;
        this.loading = false;
        ErrorHandlerService.trySendingSentryError(err);
        return of([]);
      })
    );
  }

  private filterPatients(searchTerm: string, patients: SearchablePatient[]): SearchablePatient[] {
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      return patients.filter(patient =>
        patient.patientDataToSearch.toLowerCase().includes(searchTerm)
      );
    }
    return patients;
  }

  public getNotFoundText(): string {
    return this.isServerErroring
      ? 'Server or connection error. Please try again.'
      : 'No patients found.';
  }

  onClose() {
    if (this.clearOnSelect) {
      this.searchControl.setValue('');
    }
  }
}

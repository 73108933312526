import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
export interface QrCodeModalComponentData {
  qrCodePath: string;
  modalTitle?: string;
  buttonText?: string;
}
@Component({
  selector: 'csi-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.css']
})
export class QrCodeModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<QrCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeModalComponentData
  ) {}

  public static open(
    dialog: MatDialog,
    data: QrCodeModalComponentData
  ): MatDialogRef<QrCodeModalComponent> {
    return dialog.open(QrCodeModalComponent, {
      data: data
    });
  }

  ngOnInit() {}
}

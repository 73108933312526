import cleanDeep from 'clean-deep';
import { DryEyeMedicalHistory } from './assessment-body.model';

export const AssessmentBodyUtils = {
  isHistoryNonEmpty(history: Partial<DryEyeMedicalHistory>): boolean {
    const cleanedHistory = cleanDeep(history);
    const noHistory: Partial<DryEyeMedicalHistory> = {
      eyeTearingQuantity: 0,
      eyeItchiness: 0
    };
    return (
      cleanedHistory != null &&
      !Object.keys(cleanedHistory).every(
        key => cleanedHistory[key] === noHistory[key] || key === 'schemaVersion'
      )
    );
  }
};

import { Injectable } from '@angular/core';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import { tap } from 'rxjs/operators';
import { AssessmentBody } from 'src/app/econsult/assessment-body/assessment-body.model';
import { Assessment } from '../api/assessment.service';
import { AssessmentBodyParserService } from './assessment-body-parser.service';

// This is an Apollo Link "Afterware", see https://www.apollographql.com/docs/react/advanced/network-layer#linkAfterware
// It intercepts assessment objects in the server responses and migrates the body json string which has to be done
// asynchronously due to how treatment service works
// Apollo uses Zen Observable for observables rather than RxJs so watch out for this here

// Source code based on these two examples of apollo links:
// https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link-error/src/index.ts
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/master/packages/aws-appsync/src/link/complex-object-link.ts

const findAndMigrateAssessmentBodies = (
  obj: any,
  bodyParser: AssessmentBodyParserService,
  cache: InMemoryCache,
  promises: Promise<AssessmentBody>[] = []
): Promise<AssessmentBody>[] => {
  if (obj && typeof obj === 'object') {
    if (obj.__typename && obj.__typename === 'Assessment') {
      if (!!obj.body) {
        promises.push(
          bodyParser
            .transform(obj as Assessment)
            .pipe(
              tap(bodyObj => {
                obj.body = JSON.stringify(bodyObj);
                cache.writeData({ data: obj });
              })
            )
            .toPromise()
        );
      }
    } else {
      Object.keys(obj).forEach(key => {
        const val = obj[key];
        if (Array.isArray(val)) {
          val.forEach(value => findAndMigrateAssessmentBodies(value, bodyParser, cache, promises));
        } else {
          findAndMigrateAssessmentBodies(val, bodyParser, cache, promises);
        }
      });
    }
  }
  return promises;
};

const assessmentBodiesResolver = (
  promises: Promise<AssessmentBody>[],
  result
): Observable<FetchResult> => {
  return new Observable(observer => {
    Promise.all(promises).then(() => {
      observer.next(result);
      observer.complete();
    });
    return () => null;
  });
};

export const assessmentBodyLink = (bodyParser: AssessmentBodyParserService) => {
  return new ApolloLink(
    (operation, forward): Observable<FetchResult> | null => {
      return new Observable(observer => {
        let handle;
        let bodySubscription;

        try {
          handle = forward(operation).subscribe({
            next: result => {
              const promises = findAndMigrateAssessmentBodies(
                result,
                bodyParser,
                operation.getContext().cache
              );
              if (!!promises && promises.length > 0) {
                bodySubscription = assessmentBodiesResolver(promises, result).subscribe({
                  next: parsedResults => {
                    observer.next(parsedResults);
                  },
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                });
              } else {
                observer.next(result);
              }
            },
            error: observer.error.bind(observer),
            complete: () => {
              if (!!!bodySubscription) {
                observer.complete();
              }
            }
          });
        } catch (e) {
          observer.error(e);
        }

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
          if (bodySubscription) {
            bodySubscription.unsubscribe();
          }
        };
      });
    }
  );
};

@Injectable({
  providedIn: 'root'
})
export class AssessmentBodyLink extends ApolloLink {
  private link: ApolloLink;
  constructor(private assessmentBodyParser: AssessmentBodyParserService) {
    super();
    this.link = assessmentBodyLink(this.assessmentBodyParser);
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    return this.link.request(operation, forward);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPopoverComponent } from './info-popover.component';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { SatPopoverModule } from '@ncstate/sat-popover';

@NgModule({
  imports: [CommonModule, SharedAngularMaterialModule, SatPopoverModule],
  declarations: [InfoPopoverComponent],
  exports: [InfoPopoverComponent]
})
export class InfoPopoverModule {}

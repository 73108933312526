import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ChartPoint,
  ReferenceLine
} from '../patient-chart.component';

@Component({
  selector: 'csi-patient-chart-tooltip',
  templateUrl: './patient-chart-tooltip.component.html',
  styleUrls: ['./patient-chart-tooltip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientChartTooltipComponent implements OnChanges {
  @Input() point: ChartPoint;
  @Input() referenceLines: ReferenceLine[];

  public score: number | string;
  public severity: string;
  public date: Date;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referenceLines']) {
      const largestFirst = (refLine1: ReferenceLine, refLine2: ReferenceLine) =>
        refLine2.value - refLine1.value;
      this.referenceLines.sort(largestFirst);
    }

    this.updateScore();
    this.updateSeverity();
    this.updateDate();
  }

  public updateSeverity() {
    if (this.referenceLines) {
      const value = this.point.normalizedValue;

      const refLine = this.referenceLines
        .slice(1)
        .find((referenceLine: ReferenceLine) => value >= referenceLine.value);
      this.severity = !!refLine ? refLine.name : value.toString();
    }
  }

  public updateScore() {
    this.score = this.point.displayValue;
  }

  public updateDate() {
    this.date = this.point.date;
  }
}

import { Injectable } from '@angular/core';
import { AssessmentBody } from '../../../../econsult/assessment-body/assessment-body.model';
import { leftEyeKey, rightEyeKey, symptomMethodKey } from '../../../form.model';
import schemaJSON from '../../../schema.json';
import {
  MethodSymptom,
  ModalSymptom,
  ModalSymptomCustomModalOptions,
  ModalSymptomSimpleModalOptions,
  ModalSymptomTypes,
  NumericSymptom,
  Symptom,
  SymptomMethod,
  SymptomTypes
} from '../../symptom.model';
import { ThermalScaleConfig } from '../../thermal-scale/thermal-scale.model';
import { ImagePreloadingService } from '../image-preloading/image-preloading.service';
import {
  CONTACT_LENS_FIELD,
  KeyValueMap,
  OSDI,
  SchemaJSON,
  SPEED,
  SymptomKey,
  SymptomsSchema
} from './schema.model';

export interface ExtendedRadioConditionsSchema {
  [key: string]: {
    name: string;
    values: KeyValueMap;
    valueKeys: string[];
    required?: boolean;
    deprecated: KeyValueMap;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SchemaService {
  public readonly schema: SchemaJSON;

  public readonly symptomKeys: string[];
  public readonly symptomMap: SymptomsSchema;

  public readonly usageReasonKeys: string[];
  public readonly usageReasonMap: KeyValueMap;

  public readonly checkboxConditionKeys: string[];
  public readonly checkboxConditionMap: KeyValueMap;

  public readonly medicalConditionKeys: string[];
  public readonly medicalConditionMap: KeyValueMap;

  public readonly environmentalSymptomKeys: string[];
  public readonly environmentalSymptomMap: KeyValueMap;

  public readonly otherConditionsFreeText: string;

  public readonly radioConditionKeys: string[];
  public readonly radioConditionMap: ExtendedRadioConditionsSchema;

  constructor(private imagePreloadingService: ImagePreloadingService) {
    this.schema = schemaJSON as SchemaJSON;

    const schemaCopy = JSON.parse(JSON.stringify(this.schema));


    this.symptomMap = schemaCopy.symptoms;
    this.symptomKeys = Object.keys(this.symptomMap);

    // console.log(
    //   JSON.stringify(
    //     Object.entries(this.symptomMap).reduce((acc, [key, value]) => {
    //       return { ...acc, [key]: value.name };
    //     }, {})
    //   )
    // );

    this.loadSymptomCustomModalValueMappingFunctions();

    this.usageReasonMap = schemaCopy.medicalHistory.usageReasons;
    this.usageReasonKeys = Object.keys(this.usageReasonMap);
    this.checkboxConditionMap = schemaCopy.medicalHistory.checkboxConditions;
    this.checkboxConditionKeys = Object.keys(this.checkboxConditionMap).sort(
      (key1: string, key2: string) => {
        if (this.checkboxConditionMap[key1] < this.checkboxConditionMap[key2]) {
          return -1;
        } else if (this.checkboxConditionMap[key1] > this.checkboxConditionMap[key2]) {
          return 1;
        } else {
          return 0;
        }
      }
    );

    this.medicalConditionMap = schemaCopy.medicalHistory.medicalConditions;
    this.medicalConditionKeys = Object.keys(this.medicalConditionMap);

    this.environmentalSymptomMap = schemaCopy.medicalHistory.environmentalSymptoms;
    this.environmentalSymptomKeys = Object.keys(this.environmentalSymptomMap).sort(
      (key1: string, key2: string) => {
        if (this.environmentalSymptomMap[key1] < this.environmentalSymptomMap[key2]) {
          return -1;
        } else if (this.environmentalSymptomMap[key1] > this.environmentalSymptomMap[key2]) {
          return 1;
        } else {
          return 0;
        }
      }
    );

    this.otherConditionsFreeText = schemaCopy.medicalHistory.otherConditionsFreeText;

    this.checkPredefinedConstants();

    this.radioConditionMap = schemaCopy.medicalHistory
      .radioConditions as ExtendedRadioConditionsSchema;
    this.radioConditionKeys = Object.keys(this.radioConditionMap);

    this.radioConditionKeys.forEach(key => {
      const radioCondition = this.radioConditionMap[key];
      radioCondition.valueKeys = Object.keys(radioCondition.values);
      radioCondition.deprecated = radioCondition.deprecated;
    });

    this.preloadImages();
  }

  public getAllFormControlsKeys(): string[] {
    return this.symptomKeys.reduce((previous: string[], key: string) => {
      return [...previous, ...this.getFormControlKeys(key)];
    }, []);
  }

  public getFormControlKeys(formControlKey: string): string[] {
    if (this.isSymptom(formControlKey)) {
      return [leftEyeKey(formControlKey), rightEyeKey(formControlKey)];
    } else {
      return [formControlKey];
    }
  }

  public getThermalScaleConfig(symptomKey: string, method?: SymptomMethod): ThermalScaleConfig {
    const symptom: Symptom = this.symptomMap[symptomKey] || this.schema[symptomKey];

    if (symptom && (<NumericSymptom>symptom).thermalScale) {
      return symptom['thermalScale'];
    } else if (method && symptom && symptom.type === SymptomTypes.Method) {
      return (<NumericSymptom>this.getSymptomForMethod(<MethodSymptom>symptom, method))
        .thermalScale;
    }
    return null;
  }

  public getSymptomForMethod(methodSymptom: MethodSymptom, method: SymptomMethod): Symptom {
    if (method === SymptomMethod.Quantitative) {
      return methodSymptom.quantitative;
    } else {
      return methodSymptom.qualitative;
    }
  }

  public getUnitsForSymptomKey(symptomKey: string, method: SymptomMethod): string {
    const symptom: Symptom = this.symptomMap[symptomKey];
    if (symptom) {
      if (method && symptom && symptom.type === SymptomTypes.Method) {
        return (<NumericSymptom>this.getSymptomForMethod(<MethodSymptom>symptom, method)).units;
      } else if (symptom.type === SymptomTypes.Numeric) {
        return (<NumericSymptom>symptom).units;
      }
    }
    return '';
  }

  public getMethodType(
    assessmentBody: AssessmentBody,
    formControlKey: string
  ): (SymptomMethod | null)[] {
    if (assessmentBody.dryEyeForm) {
      if (this.isSymptom(formControlKey)) {
        return [
          assessmentBody.dryEyeForm[leftEyeKey(symptomMethodKey(formControlKey))],
          assessmentBody.dryEyeForm[rightEyeKey(symptomMethodKey(formControlKey))]
        ];
      } else {
        return [assessmentBody.dryEyeForm[leftEyeKey(symptomMethodKey(formControlKey))]];
      }
    } else {
      console.warn(`'assessmentBody' does not contain a 'dryEyeForm' field'`);
      return null;
    }
  }

  private checkPredefinedConstants() {
    const missingConstants = [];
    if (!this.checkboxConditionMap[CONTACT_LENS_FIELD]) {
      missingConstants.push('CONTACT_LENS_FIELD');
    }
    if (!this.schema[OSDI]) {
      missingConstants.push('OSDI');
    }
    if (!this.schema[SPEED]) {
      missingConstants.push('SPEED');
    }

    for (let symptomKey in SymptomKey) {
      symptomKey = SymptomKey[symptomKey];
      if (!this.isSymptom(symptomKey)) {
        missingConstants.push(symptomKey);
      }
    }

    if (missingConstants.length !== 0) {
      console.error(
        'These constants could not be found: ' +
          missingConstants +
          '. These constants need to be defined properly so they can be found in the schema.'
      );
    }
  }

  private isSymptom(formControlKey: string): boolean {
    return !!this.schema.symptoms[formControlKey];
  }

  private loadSymptomCustomModalValueMappingFunctions() {

    // FIXME: Once Array.prototype.flatMap becomes standard use that in-place of the 2nd map and reduce
    this.symptomKeys
      .map(symptomKey => this.symptomMap[symptomKey])
      .map(symptom =>
        symptom.type === SymptomTypes.Method
          ? [(<MethodSymptom>symptom).qualitative, (<MethodSymptom>symptom).quantitative]
          : [symptom]
      )
      .reduce((mappedArray, mappedArrays) => mappedArray.concat(mappedArrays))
      .filter(
        symptom =>
          symptom.type === SymptomTypes.Modal &&
          (<ModalSymptom>symptom).modal.type === ModalSymptomTypes.Custom &&
          (<ModalSymptomCustomModalOptions>(<ModalSymptom>symptom).modal).valueMap
      )
      .forEach((customModalSymptom: ModalSymptom) => {
        const customModalOptions = customModalSymptom.modal as ModalSymptomCustomModalOptions;

        customModalOptions.valueMap = new Function(
          'value',
          `return (${customModalOptions.valueMap})(value)`
        ).bind(customModalOptions);
      });
  }

  private preloadImages() {
    // FIXME: Once Array.prototype.flatMap becomes standard use that in-place of the 2nd and 2nd last map and reduce
    this.symptomKeys
      .map(symptomKey => this.symptomMap[symptomKey])
      .map(symptom =>
        symptom.type === SymptomTypes.Method
          ? [(<MethodSymptom>symptom).qualitative, (<MethodSymptom>symptom).quantitative]
          : [symptom]
      )
      .reduce((mappedArray, mappedArrays) => mappedArray.concat(mappedArrays))
      .filter(symptom => symptom.type === SymptomTypes.Modal)
      .map((modalSymptom: ModalSymptom) =>
        modalSymptom.modal.type === ModalSymptomTypes.Simple
          ? Object.keys((<ModalSymptomSimpleModalOptions>modalSymptom.modal).media).map(
              mediaKey => (<ModalSymptomSimpleModalOptions>modalSymptom.modal).media[mediaKey]
            )
          : (<ModalSymptomCustomModalOptions>modalSymptom.modal).preloadImages
      )
      .reduce((mappedArray, mappedArrays) => mappedArray.concat(mappedArrays))
      .forEach(url => {
        if (url) {
          this.imagePreloadingService.preloadImage(url);
        }
      });
  }

  getFriendlyValueName(encounterValue, key): string {
    if (!encounterValue) {
      return 'N/A';
    }

    if (typeof encounterValue === 'object') {
      if (Array.isArray(encounterValue)) {
        return encounterValue
          .map(item =>
            typeof encounterValue !== 'object' ? item : item['brand_name'] || item['name']
          )
          .sort()
          .join(', ');
      }
      return Object.entries(encounterValue)
        .map(([fieldKey, value]) => `<b>${fieldKey + '</b>: ' + value}`)
        .join('<br>');
    }
    const symptom = this.symptomMap[key];

    const getModalValue = modalSymptom => {
      return (
        ((modalSymptom as ModalSymptom).modal.values &&
          (modalSymptom as ModalSymptom).modal.values[encounterValue]) ||
        encounterValue
      );
    };
    if (symptom) {
      if (symptom.type === 'modal') {
        return getModalValue(symptom);
      }

      if (symptom.type === 'method') {
        const symptomMethod = symptom as MethodSymptom;
        const methods = ['qualitative', 'quantitative'];

        for (let i = 0; i < methods.length; i++) {
          if (symptomMethod[methods[i]].type === 'modal') {
            return getModalValue(symptomMethod[methods[i]]);
          }
        }
      }
    }

    const radioCondition = this.radioConditionMap[key];

    if (radioCondition) {
      return (
        radioCondition &&
        (radioCondition.values[encounterValue] ||
          (radioCondition.deprecated && radioCondition.deprecated[encounterValue]))
      );
    }
    return encounterValue;
  }
}

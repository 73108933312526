import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { SignsAttachmentsModalComponent } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments-modal.component';
import { SignsAttachmentsService } from '../../../encounters/signs/signs-attachments-modal/signs-attachments.service';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { PreOpFormGroup } from '../../consult-forms/dry-eye-forms/pre-op/pre-op-form.model';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { leftEyeKey, rightEyeKey } from '../../form.model';
import { SymptomControlAdapterComponent } from '../symptom-control-adapter/symptom-control-adapter.component';
import { Symptom, SymptomMethod, SymptomTypes } from '../symptom.model';
import {
  LEFT_EYE_KEY_PREFIX,
  RIGHT_EYE_KEY_PREFIX,
  SYMPTOM_METHOD_KEY_SUFFIX
} from './../../form.model';

@Component({
  selector: 'csi-symptom',
  templateUrl: './symptom.component.html',
  styleUrls: ['./symptom.component.css']
})
export class SymptomComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly SymptomTypes = SymptomTypes;

  @Input() key: string;
  @Input() symptom: Symptom;
  @Input() formGroup: DryEyeFormGroup | PreOpFormGroup;
  @Input() isRequired: boolean;
  @Input() skipMethod: boolean;

  @ViewChild('leftSymptomControlAdapter', { static: false })
  leftSymptomControlAdapter: SymptomControlAdapterComponent;

  public showRequiredStar$: Observable<boolean>;
  public isInvalidLeft$: Observable<boolean>;
  public isInvalidRight$: Observable<boolean>;

  public isOptional = true;
  public selectedSignAttachmentsIndex = 0;

  public readonly RIGHT_EYE_KEY_PREFIX = RIGHT_EYE_KEY_PREFIX;
  public readonly LEFT_EYE_KEY_PREFIX = LEFT_EYE_KEY_PREFIX;

  constructor(
    private focusMonitor: FocusMonitor,
    private hostElementRef: ElementRef,
    private symptomValidatorsService: SymptomValidatorsService,
    private signsAttachmentsService: SignsAttachmentsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.symptom.type === SymptomTypes.Method && !this.skipMethod) {
      this.assignQuantitativeMethodByDefault();
    }

    this.focusMonitor.monitor(this.hostElementRef, true);
    this.selectedSignAttachmentsIndex = Object.keys(
      this.signsAttachmentsService.signToFriendlyNameMap
    ).indexOf(this.key);
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.hostElementRef);
  }

  ngAfterViewInit() {
    setTimeout(() => (this.isOptional = this.isControlOptional()));
  }

  get rightEyeFormControlName(): string {
    return rightEyeKey(this.key);
  }

  get leftEyeFormControlName(): string {
    return leftEyeKey(this.key);
  }

  showAttachmentsButton() {
    return this.signsAttachmentsService.signToFriendlyNameMap[this.key];
  }

  assignQuantitativeMethodByDefault() {
    const rightMethodControlKey = RIGHT_EYE_KEY_PREFIX + this.key + SYMPTOM_METHOD_KEY_SUFFIX;
    const leftMethodControlKey = LEFT_EYE_KEY_PREFIX + this.key + SYMPTOM_METHOD_KEY_SUFFIX;
    if (
      !this.formGroup.controls[rightMethodControlKey] &&
      this.formGroup.controls[this.rightEyeFormControlName] &&
      this.formGroup.controls[this.rightEyeFormControlName].value
    ) {
      this.formGroup.addControl(rightMethodControlKey, new FormControl());
      this.formGroup.controls[rightMethodControlKey].setValue(SymptomMethod.Qualitative);
    }

    if (
      !this.formGroup.controls[leftMethodControlKey] &&
      this.formGroup.controls[this.leftEyeFormControlName] &&
      this.formGroup.controls[this.leftEyeFormControlName].value
    ) {
      this.formGroup.addControl(leftMethodControlKey, new FormControl());
      this.formGroup.controls[leftMethodControlKey].setValue(SymptomMethod.Qualitative);
    }
  }

  copyRightEyeValueToLeftEye() {
    const rightKeys = this.leftSymptomControlAdapter.allPossibleSymptomControlKeys;
    const keyValues = {};

    rightKeys.forEach(rightKey => {
      const leftKey = rightKey.replace(this.rightEyeFormControlName, this.leftEyeFormControlName);
      const leftControl = this.formGroup.get(leftKey);
      const rightControl = this.formGroup.get(rightKey);

      if (rightControl) {
        const rightValue = rightControl.value;
        if (!leftControl) {
          this.formGroup.addControl(leftKey, new FormControl(rightValue));
        } else {
          keyValues[leftKey] = rightControl.value;
        }
      } else if (leftControl) {
        keyValues[leftKey] = '';
      }
    });

    this.patchValueUntilFormStopsChanging(keyValues);
  }

  private isControlOptional(): boolean {
    const leftEyeControl = this.formGroup.get(this.leftEyeFormControlName);
    return this.isRequired || this.symptomValidatorsService.isOptionalFormControl(leftEyeControl);
  }

  // TODO: econsult.component.ts has this same method. Maybe refactor and put it into
  // the FormGroup itself (extend FormGroup class).
  private patchValueUntilFormStopsChanging(
    value: {
      [key: string]: any;
    },
    previousJsonValue?: string
  ) {
    this.formGroup.patchValue(value);
    const currentJsonValue = JSON.stringify(this.formGroup.getRawValue());
    if (!previousJsonValue || currentJsonValue.localeCompare(previousJsonValue) !== 0) {
      setTimeout(() => this.patchValueUntilFormStopsChanging(value, currentJsonValue));
    }
  }

  public openAttachmentsDialog() {
    const dialogRef = SignsAttachmentsModalComponent.open(
      this.dialog,
      this.selectedSignAttachmentsIndex
    );

    dialogRef.afterClosed().subscribe(() => {
      this.signsAttachmentsService.updateAttachments();
    });
  }
}

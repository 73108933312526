import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreatmentPickerModule } from '../../treatment-picker/treatment-picker.module';
import { MedicationLookupModule } from '../medication-lookup/medication-lookup.module';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { SymptomValuePipe } from '../symptoms/pipes/symptom-value.pipe';
import { SymptomsPipesModule } from '../symptoms/pipes/symptoms-pipes.module';
import { SymptomsPipe } from '../symptoms/pipes/symptoms.pipe';
import { ThermalScaleModule } from '../symptoms/thermal-scale/thermal-scale.module';
import { SharedModule } from './../shared.module';
import { DryEyePdfExportComponent } from './dry-eye-pdf-export.component';
import { PagesPipe } from './pages.pipe';
import { PdfExportButtonComponent } from './pdf-export-button/pdf-export-button.component';
import { PdfExportDryEyeCategoryComponent } from './pdf-export-dry-eye-category/pdf-export-dry-eye-category.component';
import { PdfExportThermalScaleComponent } from './pdf-export-thermal-scale/pdf-export-thermal-scale.component';
import { PdfExportTreatmentComponent } from './pdf-export-treatment/pdf-export-treatment.component';
import { PDFExportService } from './pdf-export.service';
import { PdfSummaryExportComponent } from './pdf-summary-export/pdf-summary-export.component';

@NgModule({
  declarations: [
    PdfExportTreatmentComponent,
    PdfExportDryEyeCategoryComponent,
    PdfExportButtonComponent,
    DryEyePdfExportComponent,
    PdfExportThermalScaleComponent,
    PagesPipe,
    PdfSummaryExportComponent
  ],
  imports: [
    CommonModule,
    ThermalScaleModule,
    SymptomsPipesModule,
    MedicationLookupModule,
    SharedPipesModule,
    TreatmentPickerModule,
    SharedAngularMaterialModule,
    SharedModule
  ],
  exports: [PdfExportButtonComponent],
  providers: [PDFExportService, SymptomsPipe, SymptomValuePipe],
  entryComponents: [DryEyePdfExportComponent]
})
export class DryEyePDFExportModule {}

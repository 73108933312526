import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatTabChangeEvent, Sort } from '@angular/material';
import { of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { TreatmentTemplate } from './../../../../API';
import { ClinicSetupService } from './../clinic-setup.service';
import { TreatmentTemplateActionsModalComponent } from './treatment-template-actions-modal/treatment-template-actions-modal.component';
import { defaultTreatmentTemplateMap } from './treatment-template-model';
import { TreatmentTemplateViewModalComponent } from './treatment-template-view-modal/treatment-template-view-modal.component';
import { TreatmentTemplateService } from './treatment-template.service';

interface TreatmentTemplateHeader {
  columnDef: string;
  label: string;
  displayLength?: boolean;
}

interface MatTabConfig {
  name: string;
}

@Component({
  selector: 'csi-treatment-template',
  templateUrl: './treatment-template.component.html',
  styleUrls: ['./treatment-template.component.css']
})
export class TreatmentTemplateComponent implements OnInit {
  @Input() treatmentTemplateHiddenControl: FormControl;
  public treatmentTemplateControl = this.treatmentTemplateService.treatmentTemplatesFormControl;

  public defaultTreatmentTemplateMap = defaultTreatmentTemplateMap;

  public filterControl: FormControl = new FormControl();

  public filteredDataMap: { [key: string]: any[] } = {
    custom: [],
    sysDefault: [],
    hidden: []
  };

  currentTreatmentTemplates = [];

  public activeTab = 'sysDefault';
  public tabMap: { [key: string]: MatTabConfig } = {
    sysDefault: { name: 'System Default' },
    custom: { name: 'Custom' },
    hidden: { name: 'Hidden' }
  };
  private tabArray: string[] = Object.keys(this.tabMap);

  public filteredTreatment: TreatmentTemplate[];
  public tableHeaders: TreatmentTemplateHeader[] = [
    { columnDef: 'friendlyId', label: 'Treatment Template Id' },
    { columnDef: 'otcEyeDrops', label: 'Number of OTC', displayLength: true },
    { columnDef: 'rxEyeDrops', label: 'Number of RX', displayLength: true },
    { columnDef: 'exercises', label: 'Number of Exercises', displayLength: true },
    { columnDef: 'procedures', label: 'Number of Procedures', displayLength: true }
  ];
  public displayedColumns = this.tableHeaders.map(tableHeader => tableHeader.columnDef);

  public isSaving: boolean;

  constructor(
    public dialog: MatDialog,
    public treatmentTemplateService: TreatmentTemplateService,
    public clinicSetupService: ClinicSetupService,
    public confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    if (this.clinicSetupService.isClinicAdmin) {
      this.displayedColumns.push('actions');
    }

    Object.keys(this.tabMap).forEach(tabKey => {
      this.filteredDataMap[tabKey] = (this.treatmentTemplateControl.value || []).filter(
        (treatmentTemplate: TreatmentTemplate) => {
          if (tabKey === 'custom') {
            return !defaultTreatmentTemplateMap[treatmentTemplate.id];
          } else if (tabKey === 'sysDefault') {
            return (
              defaultTreatmentTemplateMap[treatmentTemplate.id] &&
              !(this.treatmentTemplateHiddenControl.value || []).includes(treatmentTemplate.id)
            );
          } else {
            return (this.treatmentTemplateHiddenControl.value || []).includes(treatmentTemplate.id);
          }
        }
      );
    });

    this.activeFilter('');

    this.treatmentTemplateControl.valueChanges.subscribe(value => {
      if (this.currentTreatmentTemplates.length === 0) {
        this.tabArray.forEach(tabKey => {
          this.filteredDataMap[tabKey] = value.filter((treatmentTemplate: TreatmentTemplate) => {
            if (tabKey === 'custom') {
              return !defaultTreatmentTemplateMap[treatmentTemplate.id];
            } else if (tabKey === 'sysDefault') {
              return (
                defaultTreatmentTemplateMap[treatmentTemplate.id] &&
                !(this.treatmentTemplateHiddenControl.value || []).includes(treatmentTemplate.id)
              );
            } else {
              return (this.treatmentTemplateHiddenControl.value || []).includes(
                treatmentTemplate.id
              );
            }
          });
        });
      }

      this.activeFilter(this.filterControl.value || '');
    });
    this.filterControl.setValue('');
    this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe((filter: string) => {
      this.activeFilter(filter);
    });
  }

  deleteTreatmentTemplate(id: string, index: number) {
    this.confirmModalService
      .show(
        'Delete Treatment Template',
        'Are you sure you want to delete this Treatment Template? This action cannot be done.',
        'Delete Treatment Template',
        'Cancel'
      )
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            this.isSaving = true;
            return this.treatmentTemplateService.deleteTreatmentTemplate(id);
          }
          return of(null);
        })
      )
      .subscribe(result => {
        if (result) {
          this.isSaving = false;
          this.removeItemFromArrayList(result);
          this.currentTreatmentTemplates = [...this.filteredDataMap['custom']];
          this.filterControl.setValue(this.filterControl.value);
        }
      });
  }

  openTreatmentTemplateActionsModal(
    action: 'add' | 'update',
    treatmentTemplate: TreatmentTemplate = null,
    isView = false
  ) {
    this.dialog
      .open(TreatmentTemplateActionsModalComponent, {
        width: '75vw',
        data: { action, treatmentTemplate, isView }
      })
      .afterClosed()
      .subscribe(treatmentTemplate => {
        if (treatmentTemplate) {
          if (this.activeTab === 'sysDefault') {
            this.filteredDataMap['custom'] = [...this.filteredDataMap['custom'], treatmentTemplate];
            return;
          }
          if (this.activeTab === 'custom') {
            const index = this.findIndexOfFavorite(treatmentTemplate);
            this.filteredDataMap[this.activeTab].splice(index, 1);
            this.filteredDataMap[this.activeTab].splice(index, 0, treatmentTemplate);
            this.currentTreatmentTemplates = this.filteredDataMap[this.activeTab];
            return;
          }
          this.currentTreatmentTemplates = [...this.currentTreatmentTemplates, treatmentTemplate];
        }
      });
  }
  openTreatmentTemplateViewModal() {
    this.dialog.open(TreatmentTemplateViewModalComponent, {
      data: {
        treatmentTemplates: this.currentTreatmentTemplates
      }
    });
  }

  onSelect(event: MatTabChangeEvent) {
    this.activeTab = this.tabArray[event.index];
    this.activeFilter(this.filterControl.value || '');
  }

  activeFilter(filter: string) {
    if (!filter.trim()) {
      this.currentTreatmentTemplates = this.filteredDataMap[this.activeTab];
    }
    const lowerCaseFilter = filter.toLocaleLowerCase();
    this.currentTreatmentTemplates = this.filteredDataMap[this.activeTab].filter(treatment => {
      return treatment.friendlyId.toLowerCase().includes(lowerCaseFilter);
    });
  }

  noSort() {
    return 0;
  }

  addToHiddenArray(treatment: TreatmentTemplate, type: string) {
    this.filteredDataMap[type] = this.filteredDataMap[type].filter(
      item => item.id !== treatment.id
    );

    this.currentTreatmentTemplates = [...this.filteredDataMap[type]];

    if (this.treatmentTemplateHiddenControl.value == null) {
      this.treatmentTemplateHiddenControl.setValue([treatment.id]);

      this.filteredDataMap['hidden'].push(treatment);
      this.treatmentTemplateHiddenControl.markAsDirty();

      return;
    }
    const tempHiddenArray = this.treatmentTemplateHiddenControl.value;
    this.treatmentTemplateHiddenControl.setValue([...tempHiddenArray, treatment.id]);
    this.treatmentTemplateHiddenControl.markAsDirty();
    this.filteredDataMap['hidden'] = [...this.filteredDataMap['hidden'], treatment];
  }
  addToSysDefaultArray(treatment: TreatmentTemplate, type: string) {
    this.filteredDataMap[type] = this.filteredDataMap[type].filter(
      item => item.id !== treatment.id
    );
    this.currentTreatmentTemplates = [...this.filteredDataMap[type]];
    const tempHiddenArray = this.treatmentTemplateHiddenControl.value.filter(item => {
      return item !== treatment.id;
    });
    this.treatmentTemplateHiddenControl.setValue(tempHiddenArray);
    this.treatmentTemplateHiddenControl.markAsDirty();
    this.filteredDataMap['sysDefault'] = [...this.filteredDataMap['sysDefault'], treatment];
  }

  announceSortChange(sort: Sort, currentTemplates: Partial<TreatmentTemplate>[]) {
    this.currentTreatmentTemplates = [
      ...currentTemplates.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'friendlyId':
            return this.compare(a.friendlyId, b.friendlyId, isAsc);
          case 'otcEyeDrops':
            return this.compare(a.otcEyeDrops.length, b.otcEyeDrops.length, isAsc);
          case 'rxEyeDrops':
            return this.compare(a.rxEyeDrops.length, b.rxEyeDrops.length, isAsc);
          case 'exercises':
            return this.compare(a.exercises.length, b.exercises.length, isAsc);
          case 'procedures':
            return this.compare(a.procedures.length, b.procedures.length, isAsc);
        }
      })
    ];
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  removeItemFromArrayList(treatmentTemplate: TreatmentTemplate) {
    const index = this.filteredDataMap['custom'].findIndex(
      (treatment: TreatmentTemplate) => treatment.id === treatmentTemplate.id
    );
    if (index === -1) return;
    this.filteredDataMap['custom'].splice(index, 1);
  }
  findIndexOfFavorite(treatmentTemplate: TreatmentTemplate) {
    const index = this.filteredDataMap[this.activeTab].findIndex(
      treatmentTemp =>
        treatmentTemp.id.toLowerCase().trim() === treatmentTemplate.id.toLowerCase().trim()
    );
    return index;
  }
}

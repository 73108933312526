import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemoteJsonFileService } from 'src/app/core/remote-json/remote-json-file.service';
import { Medication, MedicationActiveIngredient } from '../medication.model';

interface ActiveIngredientList {
  name: string;
  health_canada_report_number?: string;
  active_ingredient_code?: number;
  drug_brand_name?: string;
  drugbank_id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DryEyeSideEffectFlaggingService extends RemoteJsonFileService {
  private activeIngredientCodesThatCanCauseDryEyes: Set<number> = new Set<number>();
  private activeIngredientNamesThatCanCauseDryEyes: Set<string> = new Set<string>();
  constructor(httpClient: HttpClient) {
    super(httpClient, 'assets/bad-ingredients.json');
    this.loadBadIngredients().subscribe();
  }

  public canMedicationCauseDryEyes(medication: Medication): Observable<boolean> {
    return this.loadBadIngredients().pipe(
      map(wasProperlyLoaded => {
        let canItCauseDryness: boolean;
        if (!wasProperlyLoaded) {
          console.error('Error, failed to load bad ingredients.');
          canItCauseDryness = false;
        } else {
          canItCauseDryness = medication.active_ingredients.some(ingredient =>
            this.canActiveIngredientCauseDryEyes(ingredient)
          );
        }
        return canItCauseDryness;
      })
    );
  }

  private loadBadIngredients(): Observable<boolean> {
    return this.load<ActiveIngredientList[]>(data => this.setActiveIngredients(data));
  }

  private setActiveIngredients(data: ActiveIngredientList[]) {
    data.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseDryEyes.add(ingredient.name.toLowerCase());
      if (ingredient.active_ingredient_code) {
        this.activeIngredientCodesThatCanCauseDryEyes.add(ingredient.active_ingredient_code);
      }
    });
  }

  private canActiveIngredientCauseDryEyes(activeIngredient: MedicationActiveIngredient): boolean {
    return (
      this.activeIngredientCodesThatCanCauseDryEyes.has(activeIngredient.active_ingredient_code) ||
      this.activeIngredientNamesThatCanCauseDryEyes.has(activeIngredient.ingredient.toLowerCase())
    );
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { PreOpFormGroup } from '../pre-op-form.model';
import { PreOPExpansionPanel, SignsSchema } from '../pre-op.model';
import { PreOpService } from '../pre-op.service';
import { LEFT_EYE_KEY_PREFIX, RIGHT_EYE_KEY_PREFIX } from './../../../../form.model';
import { SymptomModes } from './../../../../symptoms/symptom.model';
import { DryEyeFormGroup } from './../../dry-eye-form/dry-eye-form.model';
import { DryEyeExpansionPanel, DryEyeFormsService } from './../../dry-eye-forms.service';

@Component({
  selector: 'csi-pre-op-signs',
  templateUrl: './pre-op-signs.component.html',
  styleUrls: ['./pre-op-signs.component.scss']
})
export class PreOpSignsComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() signsFormGroup: DryEyeFormGroup;
  @Input() showOSD: boolean;
  @Input() showLookExams: boolean;
  @Input() showLiftExams: boolean;
  @Input() showPullExams: boolean;
  @Input() showPushExams: boolean;

  @Output() isSignsNormal = new EventEmitter<boolean>();

  public readonly ExpansionPanel = DryEyeExpansionPanel;
  public readonly PreOPExpansionPanel = PreOPExpansionPanel;
  public showSecondGroupOfSigns = false;
  public optionalNonInvasiveSignFormGroup = new FormGroup({});
  public signsSchema: SignsSchema;

  public osdOfSignKeys: string[] = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['osd'].show;
  public lookExamsGroupsKeys: string[] = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['look'].show;
  public liftExamsGroupsKeys: string[] = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['lift'].show;
  public pullExamsGroupsKeys: string[] = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['pull'].show;
  public pushExamsGroupsKeys: string[] = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['push'].show;

  public subscriptionMap = {};

  constructor(
    public schemaService: SchemaService,
    public dryEyeFormsService: DryEyeFormsService,
    public preOpService: PreOpService,
    private layoutsService: LayoutsService
  ) {}

  ngOnDestroy(): void {
    Object.values(this.subscriptionMap).forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptionMap = {};
  }

  ngOnInit(): void {
    this.signsSchema = this.preOpService.signsSchema;

    if (this.showOSD) {
      this.preOpService.osdFormGroup = this.createFormGroupWithNewControls(
        this.getKeysForBothEyes(this.osdOfSignKeys)
      );
      [
        ...['value1', 'value2', 'value3'].map(key => 'RIGHT_NITBUT_' + key),
        ...['value1', 'value2', 'value3'].map(key => 'LEFT_NITBUT_' + key)
      ].forEach(key => {
        this.optionalNonInvasiveSignFormGroup.addControl(
          key,
          new FormControl(
            this.signsFormGroup.controls[key] && this.signsFormGroup.controls[key].value
              ? this.signsFormGroup.controls[key].value
              : null
          )
        );
      });
    }

    if (this.showLookExams) {
      this.preOpService.lookExamsFormGroup = this.createFormGroupWithNewControls(
        this.getKeysForBothEyes(this.lookExamsGroupsKeys)
      );
    }

    if (this.showLiftExams) {
      this.preOpService.liftExamsFormGroup = this.createFormGroupWithNewControls(
        this.getKeysForBothEyes(this.liftExamsGroupsKeys)
      );
    }
    if (this.showPullExams) {
      this.preOpService.pullExamsFormGroup = this.createFormGroupWithNewControls(
        this.getKeysForBothEyes(this.pullExamsGroupsKeys)
      );
    }
    if (this.showPushExams) {
      this.preOpService.pushExamsFormGroup = this.createFormGroupWithNewControls(
        this.getKeysForBothEyes(this.pushExamsGroupsKeys)
      );
    }

    [
      this.showOSD ? this.preOpService.osdFormGroup : null,
      this.showLookExams ? this.preOpService.lookExamsFormGroup : null,
      this.showLiftExams ? this.preOpService.liftExamsFormGroup : null,
      this.showPullExams ? this.preOpService.pullExamsFormGroup : null,
      this.showPushExams ? this.preOpService.pushExamsFormGroup : null
    ].forEach(group => {
      if (group) {
        group.valueChanges.subscribe(() => {
          this.syncNewControlWithOriginal(group);
        });
      }
    });
  }

  ngAfterViewInit() {
    // Set timeout Needed so the controls are dynamically added to the signsFormGroup

    setTimeout(() => {
      setTimeout(() => {
        combineLatest(
          ['CORNEAL_STAINING_PATTERN', 'ITBUT']
            .reduce(
              (acc, key) => acc.concat(...[LEFT_EYE_KEY_PREFIX + key, RIGHT_EYE_KEY_PREFIX + key]),
              []
            )
            .filter(signKey => this.signsFormGroup && this.signsFormGroup.controls[signKey])
            .map(signKey =>
              this.signsFormGroup.controls[signKey].valueChanges.pipe(
                startWith(this.signsFormGroup.controls[signKey].value)
              )
            )
        ).subscribe(
          ([
            leftStainingPattern,
            rightStainingPattern,
            leftInvasiveTearBreakup,
            rightInvasiveTearBreakup,
            leftSchimers,
            rightSchimers
          ]) => {
            this.preOpService.isOSDFactorsAbnormal.stains =
              (leftStainingPattern && leftStainingPattern !== 'noStaining') ||
              (rightStainingPattern && rightStainingPattern !== 'noStaining') ||
              (leftInvasiveTearBreakup && leftInvasiveTearBreakup < 8.4) ||
              (rightInvasiveTearBreakup && rightInvasiveTearBreakup < 8.4) ||
              (leftSchimers && leftSchimers <= 10) ||
              (rightSchimers && rightSchimers <= 10);

            if (!this.preOpService.isOSDFactorsAbnormal.stains) {
              this.preOpService.isOSDFactorsAbnormal.stains = false;
            }
            this.preOpService.calculateNeuroPathic();
          }
        );
      });
    });
  }

  getKeysForBothEyes(signKeys: string[]): string[] {
    return signKeys
      .map(sign => this.schemaService.getFormControlKeys(sign))
      .reduce((acc, val) => acc.concat(val), []);
  }

  createFormGroupWithNewControls(signKeys: string[]): PreOpFormGroup {
    return new PreOpFormGroup(
      signKeys.reduce(
        (acc, signKey) => ({
          ...acc,
          [signKey]: new FormControl(
            // Assign value from another control if it exists
            this.signsFormGroup.controls[signKey] &&
            (this.signsFormGroup.controls[signKey].value !== null ||
              this.signsFormGroup.controls[signKey].value !== undefined)
              ? this.signsFormGroup.controls[signKey].value
              : null,
            !this.preOpService.signsSchema[this.getSignKeyWithoutEyeOrMethod(signKey)].optional
              ? [Validators.required]
              : null
          ),
          ...(this.schemaService.symptomMap[this.getSignKeyWithoutEyeOrMethod(signKey)].type ===
          'method'
            ? {
                [signKey + '_METHOD']: new FormControl(
                  this.signsFormGroup.controls[signKey + '_METHOD'] &&
                  this.signsFormGroup.controls[signKey + '_METHOD'].value
                    ? this.signsFormGroup.controls[signKey + '_METHOD'].value
                    : null
                )
              }
            : {})
        }),
        {}
      )
    );
  }

  syncNewControlWithOriginal(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([signKey, formControl]: [string, FormControl]) => {
      if (!this.signsFormGroup.controls[signKey]) {
        this.signsFormGroup.addControl(
          signKey,
          new FormControl(
            formControl.value,
            this.preOpService.signsSchema[this.getSignKeyWithoutEyeOrMethod(signKey)] &&
            this.preOpService.signsSchema[this.getSignKeyWithoutEyeOrMethod(signKey)].optional
              ? null
              : [Validators.required]
          )
        );
      }
      this.subscriptionMap[signKey] = formControl.valueChanges.subscribe(value => {
        this.signsFormGroup.controls[signKey].setValue(value);
      });
    });
  }

  getSignKeyWithoutEyeOrMethod(signKey: string): string {
    return signKey.replace(/(RIGHT_|LEFT_|_METHOD)/g, '');
  }
}

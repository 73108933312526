import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueMap } from '../services/schema/schema.model';
import { SchemaService } from '../services/schema/schema.service';

@Pipe({
  name: 'radioConditions'
})
export class RadioConditionsPipe implements PipeTransform {
  constructor(private schemaService: SchemaService) {}

  transform(keyValue: KeyValueMap): KeyValueMap[] {
    const result: KeyValueMap[] = [];
    this.schemaService.radioConditionKeys.forEach(key => {
      if (keyValue[key]) {
        result.push({ key: key, value: keyValue[key] });
      }
    });

    return result;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionResolver'
})
export class FunctionResolverPipe implements PipeTransform {
  transform(conditionFn: any, params: any, changes: any): any {

    if (!conditionFn) {
      return null;
    }
    return conditionFn(params);
  }
}

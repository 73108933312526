import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Drug } from 'src/API';
import { AssessmentRequestType, AssessmentType } from '../../API';
import { AccountSettingsFormGroup } from '../authentication/account-settings-form.model';
import { Assessment, AssessmentRequest } from '../core/api/assessment.service';
import { Attachment } from '../shared/attachments/attachments.component';
import { S3Object } from '../shared/attachments/s3-attachment.service';
import { AssessmentFormGroup } from '../shared/consult-forms/assessment-form/assessment-form.model';
import { ClinicFormGroup } from '../shared/consult-forms/clinic-form/clinic-form.model';
import { DoctorFormGroup } from '../shared/consult-forms/doctor-form/doctor-form.model';
import { DryEyeAssessmentFormGroup } from '../shared/consult-forms/dry-eye-forms/dry-eye-assessment-form/dry-eye-assessment-form.model';
import { DryEyeFormGroup } from '../shared/consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { DryEyeMedicalHistoryFormGroup } from '../shared/consult-forms/dry-eye-forms/dry-eye-medical-history-form/dry-eye-medical-history-form.model';
import { WeatherFormGroup } from '../shared/consult-forms/dry-eye-forms/weather/weather-form.model';
import { MedicalHistoryFormGroup } from '../shared/consult-forms/medical-history-form/medical-history-form.model';
import { PatientFormGroup } from '../shared/consult-forms/patient-form/patient-form.model';
import {
  AbstractControlsMap,
  DynamicFormGroup
} from '../shared/dynamic-form-group/dynamic-form-group';
import { ResponseFormGroup } from '../shared/response-form/response-form.model';
import { SymptomModes } from '../shared/symptoms/symptom.model';
import { Referral } from './../../API';
import { AssessmentBody } from './assessment-body/assessment-body.model';

export enum EConsultMode {
  Create,
  Reply,
  View
}

export enum EntryMethod {
  AttachFromEMR = 'attachFromEMR',
  Manual = 'manual'
}

interface EConsultFormControls extends AbstractControlsMap {
  id: FormControl;
  assessmentType: FormControl;
  otherAssessmentTypeName: FormControl;
  informationEntryMethod: FormControl;
  priority: FormControl;
}

interface ManualEConsultFormControls extends AbstractControlsMap {
  doctorInformationForm: DoctorFormGroup;
  clinicInformationForm: ClinicFormGroup;
  patientInformationForm: PatientFormGroup;
  medicalHistoryForm: MedicalHistoryFormGroup;
  assessmentForm: AssessmentFormGroup;
  accountSettingsFormGroup: AccountSettingsFormGroup;
}

export interface DryEyeEConsultFormControls extends AbstractControlsMap {
  doctorInformationForm: DoctorFormGroup;
  clinicInformationForm: ClinicFormGroup;
  patientInformationForm: PatientFormGroup;
  medicalHistoryForm: DryEyeMedicalHistoryFormGroup;
  weather: WeatherFormGroup;
  dryEyeForm: DryEyeFormGroup;
  assessmentForm: DryEyeAssessmentFormGroup;
  expansionStateFormControl: FormControl;
  enabledStateFormControl: FormControl;
}

interface EMREConsultFormControls extends AbstractControlsMap {
  emrAttachments: FormControl;
}

interface EConsultResponseFormControl extends AbstractControlsMap {
  responseForm: ResponseFormGroup;
}

type EConsultEntryControls =
  | ManualEConsultFormControls
  | DryEyeEConsultFormControls
  | EMREConsultFormControls;

type CreateEConsultControls = EConsultFormControls & EConsultEntryControls;

type ReplyToEConsultControls = CreateEConsultControls & EConsultResponseFormControl;

@Injectable()
export class EConsultFormGroup extends DynamicFormGroup<EConsultEntryControls> {
  controls: CreateEConsultControls | ReplyToEConsultControls;

  public readonly manualEntryControls: ManualEConsultFormControls = {
    doctorInformationForm: new DoctorFormGroup(),
    accountSettingsFormGroup: new AccountSettingsFormGroup(),
    clinicInformationForm: new ClinicFormGroup(),
    patientInformationForm: new PatientFormGroup(),
    medicalHistoryForm: new MedicalHistoryFormGroup(),
    assessmentForm: new AssessmentFormGroup()
  };

  public readonly emrControls: EMREConsultFormControls = {
    emrAttachments: new FormControl([], Validators.required)
  };

  public readonly dryEyeFormControls: DryEyeEConsultFormControls = {
    doctorInformationForm: this.manualEntryControls.doctorInformationForm,
    clinicInformationForm: this.manualEntryControls.clinicInformationForm,
    patientInformationForm: this.manualEntryControls.patientInformationForm,
    medicalHistoryForm: new DryEyeMedicalHistoryFormGroup(),
    weather: new WeatherFormGroup(),
    dryEyeForm: new DryEyeFormGroup(),
    assessmentForm: new DryEyeAssessmentFormGroup(),
    expansionStateFormControl: new FormControl(),
    enabledStateFormControl: new FormControl()
  };

  public readonly responseFormControls: EConsultResponseFormControl = {
    responseForm: new ResponseFormGroup()
  };

  public readonly value: AssessmentBody | Attachment[];

  public get assessmentMethod(): BehaviorSubject<SymptomModes> {
    return this.dryEyeFormControls.dryEyeForm.assessmentMethod;
  }

  private _mode: EConsultMode;

  private didSubmit = false;

  private _assessmentRequestType: AssessmentRequestType | null;

  constructor() {
    super({
      id: new FormControl(''),
      assessmentType: new FormControl(''),
      informationEntryMethod: new FormControl(''),
      otherAssessmentTypeName: new FormControl(''),
      priority: new FormControl('')
    });

    this.updateFormControlsOnEntryMethodSwitch();
    this.updateFormControlsOnAssessmentTypeSwitch();

    this.linkRelatedControls();
  }

  set assessmentRequestType(assessmentRequestType: AssessmentRequestType) {
    this._assessmentRequestType = assessmentRequestType;
  }

  get assessmentRequestType(): AssessmentRequestType {
    return this._assessmentRequestType;
  }

  public patchFromAssessment(assessment: Assessment, formsToRetain: string[] = []) {
    if (assessment) {
      const body: AssessmentBody = JSON.parse(assessment.body);
      formsToRetain.forEach(key => delete body[key]);
      body.medicalHistoryForm.medications = this.normalizeDrugs(
        body.medicalHistoryForm.medications
      );
      this.patchValue(body);
      this.patchAssessmentAttachments(
        assessment.attachments,
        assessment.type,
        body.informationEntryMethod
      );
      if (
        assessment.requests &&
        assessment.requests.items &&
        assessment.requests.items.length > 0
      ) {
        this.patchAssessmentRequestResponse(
          (assessment.requests.items[0] as unknown) as AssessmentRequest
        );
      }
    }
  }

  patchFromReferral(referral: Referral) {
    if (referral) {
      const body: AssessmentBody = JSON.parse(referral.body);
      this.patchValue(body);
      this.dryEyeFormControls.assessmentForm.controls.images.setValue(referral.attachments);
    }
  }

  public normalizeDrugs(drugs) {
    if (!drugs) return;

    const normalizedDrugs: Drug[] = drugs.map((drug: any) => {
      if (this.isDrug(drug)) return drug;
      const normalizedDrug: Drug = {
        __typename: 'Drug',
        id: drug._id || null,
        ndc_product_codes: drug.ndc_product_codes || null,
        dpd_codes: drug.dpd_codes || null,
        ema_product_codes: null,
        name: drug.brand_name || null,
        rx_norm_prescribable_name: null,
        country: null,
        routes: drug.routes
          ? drug.routes.map((route: any) => ({
              __typename: 'DrugRoute',
              route_of_administration: route.route_of_administration || null
            }))
          : null,
        dosage_form: drug.dosage_form || null,
        active_ingredients: drug.active_ingredients
          ? drug.active_ingredients.map((ingredient: any) => ({
              __typename: 'DrugActiveIngredients',
              ingredient: ingredient.ingredient || null,
              strength:
                typeof ingredient.strength === 'string'
                  ? parseFloat(ingredient.strength)
                  : ingredient.strength || null,
              strength_unit: ingredient.strength_unit || null
            }))
          : null
      };

      return normalizedDrug;
    });

    return normalizedDrugs;
  }

  private isDrug(drug: any): drug is Drug {
    return drug && drug.__typename === 'Drug';
  }

  // TODO: Hopefully one day we can remove the 2nd and 3rd arguments, they exist only for
  //       backwards compatibility with EConsult component for now.
  public patchAssessmentAttachments(
    attachments: S3Object[],
    assessmentType: AssessmentType = this.controls.assessmentType.value,
    entryMethod: EntryMethod = this.controls.informationEntryMethod.value
  ) {
    if (attachments) {
      if (entryMethod === EntryMethod.AttachFromEMR) {
        this.emrControls.emrAttachments.setValue(attachments);
      } else {
        this.getAssessmentFormImagesControl(assessmentType).setValue(attachments);
      }
    }
  }

  public patchFromAssessmentRequest(assessmentRequest: AssessmentRequest) {
    if (assessmentRequest) {
      this.assessmentRequestType = assessmentRequest.type;
      this.patchFromAssessment(assessmentRequest.assessment as Assessment);
      this.patchAssessmentRequestResponse(assessmentRequest);
    }
  }

  public patchAssessmentRequestResponse(assessmentRequest: AssessmentRequest) {
    if (assessmentRequest) {
      this.responseFormControls.responseForm.patchValue(assessmentRequest);
    }
  }

  public getRawValue(): AssessmentBody | Attachment[] {
    return super.getRawValue();
  }

  get submitted(): boolean {
    return this.didSubmit;
  }

  set submitted(value: boolean) {
    this.didSubmit = value;

    if (this.activeFormControls) {
      Object.values(this.activeFormControls).forEach((control: any & FormControl) => {
        if (control.submitted !== undefined) {
          control.submitted = value;
        }
      });
    }
  }

  get mode(): EConsultMode {
    return this._mode;
  }

  set mode(value: EConsultMode) {
    this._mode = value;
    this.removeResponseControls();
    switch (this._mode) {
      case EConsultMode.Create:
        this.enableControls();
        break;

      case EConsultMode.View:
        this.addResponseControls();
        this.disableControls();
        break;

      case EConsultMode.Reply:
        this.addResponseControls();
        this.disableAllControlsExceptResponse();
        break;

      default:
        break;
    }
  }

  get isNewAssessment(): boolean {
    return !this.controls.id.value;
  }

  set assessmentType(value: AssessmentType) {
    this.controls.assessmentType.setValue(value);
  }

  get assessmentType(): AssessmentType {
    return this.controls.assessmentType.value;
  }

  set entryMethod(value: EntryMethod) {
    this.controls.informationEntryMethod.setValue(value);
  }

  get entryMethod(): EntryMethod {
    return this.controls.informationEntryMethod.value;
  }

  get attachments(): Attachment[] {
    if (this.entryMethod === EntryMethod.AttachFromEMR) {
      return this.emrControls.emrAttachments.value;
    } else {
      return this.getAssessmentFormImagesControl(this.assessmentType).value;
    }
  }

  get isAssessmentValid(): boolean {
    return Object.values(this.controls).every(control => {
      if (
        control === this.controls.doctorInformationForm ||
        control === this.controls.clinicInformationForm ||
        control === this.controls.patientInformationForm
      ) {
        return true;
      } else {
        return control.valid;
      }
    });
  }

  private updateFormControlsOnEntryMethodSwitch() {
    this.controls.informationEntryMethod.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.matchFormControlsWithFormInputMethods();
      }, 0);
    });
  }

  private updateFormControlsOnAssessmentTypeSwitch() {
    this.controls.assessmentType.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.matchFormControlsWithFormInputMethods();

        if (value === AssessmentType.Other) {
          this.controls.otherAssessmentTypeName.setValidators(Validators.required);
          this.controls.otherAssessmentTypeName.updateValueAndValidity();
        } else {
          this.controls.otherAssessmentTypeName.clearValidators();
        }
      }, 0);
    });
  }

  private matchFormControlsWithFormInputMethods() {
    if (this.controls.informationEntryMethod.value === EntryMethod.AttachFromEMR) {
      this.currentFormControlSet = this.emrControls;
    } else {
      if (this.controls.assessmentType.value === AssessmentType.DryEyeSpecialized) {
        this.currentFormControlSet = this.dryEyeFormControls;
      } else {
        this.currentFormControlSet = this.manualEntryControls;
      }
    }
  }

  private removeResponseControls() {
    this.removeControls(this.responseFormControls);
  }

  private addResponseControls() {
    this.addControls(this.responseFormControls);
  }

  private disableAllControlsExceptResponse() {
    this.disableControls(controlKey => !this.responseFormControls[controlKey]);
  }

  private getAssessmentFormImagesControl(assessmentType: AssessmentType): FormControl {
    return assessmentType === AssessmentType.DryEyeSpecialized
      ? this.dryEyeFormControls.assessmentForm.controls.images
      : this.manualEntryControls.assessmentForm.controls.images;
  }

  private linkRelatedControls() {
    this.linkSeasonalAllergiesControl();
    this.linkDrugAllergiesControl();
    this.linkImpressionsControl();
    this.linkRecommendedPlanControl();
    this.linkImagesControl();
  }

  private linkSeasonalAllergiesControl() {
    this.linkFormControls(
      this.manualEntryControls.medicalHistoryForm.controls.seasonalAllergies,
      this.dryEyeFormControls.medicalHistoryForm.controls.seasonalAllergies
    );
  }

  private linkDrugAllergiesControl() {
    this.linkFormControls(
      this.manualEntryControls.medicalHistoryForm.controls.drugAllergies,
      this.dryEyeFormControls.medicalHistoryForm.controls.drugAllergies
    );
  }

  private linkImpressionsControl() {
    this.linkFormControls(
      this.manualEntryControls.assessmentForm.controls.impressions,
      this.dryEyeFormControls.assessmentForm.controls.impressions
    );
  }

  private linkRecommendedPlanControl() {
    this.linkFormControls(
      this.manualEntryControls.assessmentForm.controls.recommendedPlanByDoctor,
      this.dryEyeFormControls.assessmentForm.controls.recommendedPlanByDoctor
    );
  }

  private linkImagesControl() {
    this.linkFormControls(
      this.manualEntryControls.assessmentForm.controls.images,
      this.dryEyeFormControls.assessmentForm.controls.images
    );
  }
}

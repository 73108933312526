// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import awsmobile from '../aws-exports';
import { version } from './appVersion';

export const environment = {
  production: false,
  appSyncGraphQLEndpoint: awsmobile['aws_appsync_graphqlEndpoint'],
  appSyncRegion: awsmobile['aws_appsync_region'],
  userUploads: {
    bucket: 'dry-eye-app-uploads',
    region: 'ca-central-1'
  },
  enableSentry: false,
  version: version.versionValue,
  cookieStorageConfig: {
    domain: window.location.hostname,
    secure: false
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { TranslocoService } from '@ngneat/transloco';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import flatten from 'lodash-es/flatten';
import get from 'lodash-es/get';
import uniqBy from 'lodash-es/uniqBy';
import { Assessment } from '../../../core/api/assessment.service';
import { ModelUtils } from '../../../core/api/model-utils';
import { AssessmentBody } from '../../../econsult/assessment-body/assessment-body.model';
import { SymptomValuePipe } from '../../../shared/symptoms/pipes/symptom-value.pipe';
import { OSDI, SPEED, SymptomKey } from '../../../shared/symptoms/services/schema/schema.model';
import { SchemaService } from '../../../shared/symptoms/services/schema/schema.service';
import { SymptomMethod } from '../../../shared/symptoms/symptom.model';
import {
  ThermalScaleColor,
  ThermalScaleService
} from '../../../shared/symptoms/thermal-scale/thermal-scale.service';

export abstract class ChartPoint {
  protected constructor(
    public series: string,
    public displayValue: string | number,
    public units?: string
  ) {}
  public abstract get internalValue(): string | number;
  public abstract get date(): Date;
  public abstract get normalizedValue(): number;
  public abstract set normalizedValue(newVal);
}

export class ReferenceLine {
  name: string;
  value: number;
}

export enum EyePosition {
  left,
  right
}

interface NgxLineChartPoint {
  name: Date;
  value: number;
}

interface NgxBubbleChartPoint {
  name: number | string;
  x: Date;
  y: number;
}

export class LineChartPoint extends ChartPoint implements NgxLineChartPoint {
  name: Date;
  value: number;
  _internalValue: number | string;

  public get date(): Date {
    return this.name;
  }
  public get normalizedValue(): number {
    return this.value;
  }
  public get internalValue(): number | string {
    return this._internalValue;
  }
  public set normalizedValue(newVal) {
    this.value = newVal;
  }

  constructor(
    date: Date,
    normalizedValue: number,
    internalValue: number | string,
    series: string,
    tooltipDisplay: number | string,
    units?: string
  ) {
    super(series, tooltipDisplay, units);
    this.name = date;
    this.value = normalizedValue;
    this._internalValue = internalValue;
  }
}

class Series {
  name: string;
  series: ChartPoint[];
}

enum ChartType {
  line,
  bubble
}

class BubbleChartPoint extends ChartPoint implements NgxBubbleChartPoint {
  name: number | string;
  x: Date;
  y: number;
  public get date(): Date {
    return this.x;
  }
  public get normalizedValue(): number {
    return this.y;
  }
  public get internalValue(): number | string {
    return this.name;
  }
  public set normalizedValue(newVal) {
    this.y = newVal;
  }

  constructor(
    date: Date,
    normalizedValue: number,
    internalValue: number | string,
    series: string,
    tooltipDisplay: number | string,
    units?: string
  ) {
    super(series, tooltipDisplay, units);
    this.name = internalValue;
    this.y = normalizedValue;
    this.x = date;
  }
}

@Component({
  selector: 'csi-patient-chart',
  templateUrl: './patient-chart.component.html',
  styleUrls: ['./patient-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientChartComponent implements OnChanges {
  @Input() eyePosition: EyePosition;
  @Input() set patientAssessments(assessments: Assessment[]) {
    this._patientAssessments = assessments.concat();
  }
  @HostBinding('class.hidden') get hideChart(): boolean {
    return (
      this.data.reduce((total: number, series: Series) => total + series.series.length, 0) === 0
    );
  }

  public readonly EyePosition = EyePosition;
  public readonly ChartType = ChartType;

  public data: Series[];
  public colors: { name: string; value: string }[];
  public referenceLines: ReferenceLine[] = [];
  public yAxisTicks: number[];
  public xAxisTicks: Date[];
  public hideXAxis: boolean;
  public chartType: ChartType;

  public readonly renameYAxis = (val: number): string => {
    const referenceLine = this.referenceLines.find(
      (refLine: ReferenceLine) => val === refLine.value
    );
    return !!referenceLine ? referenceLine.name : val.toString();
  };

  private _patientAssessments: Assessment[];
  private get numberOfDisplayableAssessments(): number {
    return this._patientAssessments.filter(assessment => {
      const dataToShow = this.getDataToShow(JSON.parse(assessment.body));
      return Object.keys(dataToShow).some(seriesName => dataToShow[seriesName].value != null);
    }).length;
  }
  private readonly unscaledReferenceLines: ReferenceLine[] = [
    { name: 'Normal', value: 0 },
    { name: 'Mild', value: this.findValueForColor(ThermalScaleColor.green) },
    {
      name: 'Moderate',
      value: this.findValueForColor(ThermalScaleColor.yelloworange)
    },
    { name: 'Severe', value: this.findValueForColor(ThermalScaleColor.orangered) },
    { name: '', value: 1 }
  ];
  private readonly symptomValuePipe: SymptomValuePipe;

  constructor(
    private thermalScaleService: ThermalScaleService,
    private schemaService: SchemaService,
    private translocoService: TranslocoService
  ) {
    this.symptomValuePipe = new SymptomValuePipe(this.schemaService, this.translocoService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['patientAssessments']) {
      this.setChartType();
      this.assessmentsToGraphData();
      this.scaleNormalizedGraph();

      this.updateChartDataBindings();
    }
  }

  public toReadableDate(date: Date): string {
    const month = date.toLocaleString('default', { month: 'short' });
    return month + ' ' + date.getDate();
  }

  public findChartPoint(
    model: (NgxBubbleChartPoint | NgxLineChartPoint) & { series: string }
  ): ChartPoint {
    let chartPoint: ChartPoint = null;
    const asBubbleChartPoint = model as BubbleChartPoint;
    const asLineChartPoint = model as LineChartPoint;
    this.data.find(series => {
      if (model.series === series.name) {
        return !!series.series.find((point: ChartPoint) => {
          if (
            asBubbleChartPoint.x &&
            point.date.getMilliseconds() === asBubbleChartPoint.x.getMilliseconds() &&
            point.internalValue === asBubbleChartPoint.name
          ) {
            chartPoint = point;
            return true;
          } else if (
            asLineChartPoint.name.getMilliseconds &&
            point.date.getMilliseconds() === asLineChartPoint.name.getMilliseconds() &&
            point.internalValue === asLineChartPoint._internalValue
          ) {
            chartPoint = point;
            return true;
          }
        });
      }
    });
    return chartPoint;
  }

  private updateChartDataBindings() {
    this.yAxisTicks = this.referenceLines.map(refLine => refLine.value);
    this.xAxisTicks = uniqBy(
      flatten(this.data.map(series => series.series.map(chartPoint => chartPoint.date))),
      date => date.getTime()
    );

    this.hideXAxis = this.numberOfDisplayableAssessments < 2;
  }

  private scaleNormalizedGraph() {
    const nonInclusive = 0.000001;
    this.referenceLines = cloneDeep(this.unscaledReferenceLines);
    const numberOfAreas = this.referenceLines.length - 1;
    const incrementPerArea = 1 / numberOfAreas;
    const scalingsForAreas: { originalRange: number; scaling: number }[] = [];

    this.referenceLines.forEach((refLine: ReferenceLine, index: number) => {
      if (index !== 0) {
        const originalRange = refLine.value;
        const distance = refLine.value - this.referenceLines[index - 1].value;
        const scalingFactor = incrementPerArea / distance;

        scalingsForAreas[index - 1] = { originalRange: originalRange, scaling: scalingFactor };
      }
    });

    this.referenceLines.forEach((refLine: ReferenceLine) => {
      refLine.value =
        this.scaleToEvenSpacing(refLine.value, scalingsForAreas, incrementPerArea) +
        (refLine.value === 0 ? 0 : nonInclusive);
    });

    this.data
      .reduce((total: ChartPoint[], current: Series) => {
        total.push(...current.series);
        return total;
      }, [] as ChartPoint[])
      .forEach((point: ChartPoint) => {
        const normalizedValue = point.normalizedValue;
        point.normalizedValue = this.scaleToEvenSpacing(
          normalizedValue,
          scalingsForAreas,
          incrementPerArea
        );
      });
  }

  private scaleToEvenSpacing(
    value: number = 0,
    scalingsForAreas: { originalRange: number; scaling: number }[],
    incrementPerArea: number
  ) {
    const index = scalingsForAreas.findIndex(
      ({ originalRange, scaling }) => value <= originalRange
    );
    const lengthOfPreviousArea = !!index ? scalingsForAreas[index - 1].originalRange : 0;
    return (
      scalingsForAreas[index].scaling * (value - lengthOfPreviousArea) + incrementPerArea * index
    );
  }

  private findValueForColor(colorToCompare: string) {
    return this.thermalScaleService.normalizedValueToColor.find(
      ({ color, value }) => color === colorToCompare
    ).value;
  }

  private assessmentsToGraphData() {
    ModelUtils.sortByDate(this._patientAssessments);

    const emptyData = this.getDataToShow(null);
    const seriesNames = Object.keys(emptyData);
    this.data = seriesNames
      .map((name: string) => this.createSeries(name))
      .filter(val => val !== undefined);
    this.colors = seriesNames.map((name: string) => {
      return { name: name, value: emptyData[name].color };
    });
  }

  private createSeries(seriesName: string): Series {
    const series: ChartPoint[] = [];
    this._patientAssessments.map((assessment: Assessment) => {
      const assessmentBody = JSON.parse(assessment.body);
      const dataToShow = this.getDataToShow(assessmentBody);
      if (dataToShow[seriesName].value != null) {
        const methodType: SymptomMethod = this.schemaService.getMethodType(
          assessmentBody,
          dataToShow[seriesName].symptomKey
        )[this.eyePosition];
        const config = this.schemaService.getThermalScaleConfig(
          dataToShow[seriesName].symptomKey,
          methodType
        );
        const units = this.schemaService.getUnitsForSymptomKey(
          dataToShow[seriesName].symptomKey,
          methodType
        );
        const y = this.thermalScaleService.getColorValue(dataToShow[seriesName].value, config);
        if (this.chartType === ChartType.line) {
          series.push(
            new LineChartPoint(
              new Date(assessment.createdAt),
              y,
              dataToShow[seriesName].value,
              seriesName,
              this.symptomValuePipe.transform(
                dataToShow[seriesName].value,
                dataToShow[seriesName].symptomKey,
                methodType
              ),
              units
            )
          );
        } else {
          series.push(
            new BubbleChartPoint(
              new Date(assessment.createdAt),
              y,
              dataToShow[seriesName].value,
              seriesName,
              this.symptomValuePipe.transform(
                dataToShow[seriesName].value,
                dataToShow[seriesName].symptomKey,
                methodType
              ),
              units
            )
          );
        }
      }
    });

    return series.length > 0 ? { name: seriesName, series: series } : undefined;
  }

  private setChartType() {
    if (this.numberOfDisplayableAssessments > 1) {
      this.chartType = ChartType.line;
    } else {
      this.chartType = ChartType.bubble;
    }
  }

  private getDataToShow(
    assessment: AssessmentBody
  ): { [seriesName: string]: { value: string; symptomKey: string; color: string } } {
    const mge_secretion = this.schemaService.getFormControlKeys(SymptomKey.MGE_SECRETION);
    const vital_dye_staining = this.schemaService.getFormControlKeys(
      SymptomKey.CORNEAL_STAINING_EXTENT
    );
    const tear_meniscus_height = this.schemaService.getFormControlKeys(
      SymptomKey.TEAR_MENISCUS_HEIGHT
    );
    const nitbut = this.schemaService.getFormControlKeys(SymptomKey.NITBUT);
    return {
      OSDI: {
        value: get(assessment, 'dryEyeForm.' + OSDI) as string,
        symptomKey: OSDI,
        color: '#25bb34'
      },
      SPEED: {
        value: get(assessment, 'dryEyeForm.' + SPEED) as string,
        symptomKey: SPEED,
        color: '#ffa305'
      },
      'MGE Secretion': {
        value: get(assessment, 'dryEyeForm.' + mge_secretion[this.eyePosition]) as string,
        symptomKey: SymptomKey.MGE_SECRETION,
        color: '#f820e6'
      },
      'Vital Dye Staining': {
        value: get(assessment, 'dryEyeForm.' + vital_dye_staining[this.eyePosition]) as string,
        symptomKey: SymptomKey.CORNEAL_STAINING_EXTENT,
        color: '#72ccd4'
      },
      'Tear Meniscus Height': {
        value: get(assessment, 'dryEyeForm.' + tear_meniscus_height[this.eyePosition]) as string,
        symptomKey: SymptomKey.TEAR_MENISCUS_HEIGHT,
        color: '#594336'
      },
      NITBUT: {
        value: get(assessment, 'dryEyeForm.' + nitbut[this.eyePosition]) as string,
        symptomKey: SymptomKey.NITBUT,
        color: '#d42920'
      }
    };
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { noop } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { Attachment } from '../attachments.component';

@Component({
  selector: 'csi-mobile-upload',
  templateUrl: './mobile-upload.component.html',
  styleUrls: ['./mobile-upload.component.scss']
})
export class MobileUploadComponent implements OnInit {
  @Input() accept = '';
  @Input() expandAttachments: boolean;
  @Input() maxAttachments: number;
  @Input() addFilesMessage: string = null;
  @Input() shouldStaySameHeight = false;

  public isReadOnly = false;
  public id: string;
  public metadata: string;
  public metadataKey: string;

  attachments: Attachment[] = [];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  @HostListener('drop', ['$event'])
  onFileDrop(event) {
    event.preventDefault();

    if (event.dataTransfer.items) {
      this.addFiles(
        Array.from(event.dataTransfer.items).map((item: any) => item.getAsFile()) as File[]
      );
      event.dataTransfer.items.clear();
    } else {
      this.addFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }
  constructor(
    private loaderSpinnerService: LoadingSpinnerService,
    private assessmentService: AssessmentService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.id = location.pathname.substring(1, location.pathname.indexOf('/', 2));
    this.metadataKey = location.pathname.substring(
      this.id.length + 2,
      location.pathname.indexOf('/', this.id.length + 2)
    );
    this.metadata = location.pathname.substring(
      this.metadataKey.length + this.id.length + 3,
      location.pathname.length - 7
    );
    if (this.metadataKey === 'sign' && this.metadata !== 'main') {
      this.maxAttachments = 1;
    }
  }

  addFiles(files: FileList | File[]) {
    const filteredFiles = Array.from(files).filter(file => {
      return (
        this.accept === '' ||
        this.accept.includes(file.type) ||
        this.accept.includes(file.name.match(/[a-z0-9]*$/i)[0])
      );
    });

    const metadata = {};
    metadata[this.metadataKey] = this.metadata;

    if (this.metadata) {
      filteredFiles.forEach(file => (file['metadata'] = JSON.stringify(metadata)));
    }

    this.attachments = this.attachments
      .concat(filteredFiles)
      .filter(attachment => attachment !== null);
    if (this.attachments.length > this.maxAttachments) {
      this.attachments.splice(0, this.attachments.length - this.maxAttachments);
    }
    this.updateControlValue();
  }

  removeAttachment(attachmentToRemove: Attachment) {
    this.attachments = this.attachments.filter(attachment => attachment !== attachmentToRemove);
    this.updateControlValue();
  }

  public writeValue(value: any) {
    this.attachments = value ? value : new Array<Attachment>();
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  private updateControlValue() {
    this.onChangeCallback(this.attachments);
  }

  save() {
    if (this.attachments.length === 0) {
      this._snackBar.open('No files uploaded!', '', {
        duration: 2000,
        verticalPosition: 'top'
      });
      return;
    }

    this.loaderSpinnerService.show();
    this.assessmentService
      .getAssessment(this.id, 'no-cache')
      .pipe(
        mergeMap(data => {
          return this.assessmentService.updateAssessment(
            data.id,
            data.type,
            JSON.parse(data.body),
            data.attachments ? [...data.attachments, ...this.attachments] : this.attachments,
            data.patient.id,
            data.doctor.id,
            data.locked
          );
        })
      )
      .subscribe(() => {
        this.loaderSpinnerService.hide();
        this.attachments = [];
        this.updateControlValue();
        this._snackBar.open('File(s) saved successfully!', '', {
          duration: 2000,
          verticalPosition: 'top'
        });
      });
  }
}

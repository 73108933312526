const describe = {
  label: 'Please describe',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const describeFrequency = {
  label: 'How often do you use them?',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};
const specify = {
  label: 'Please specify',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const specifyOptometrist = {
  label: 'Optometrist',
  required: true,
  typeConfig: {
    type: 'input',
    multiline: false
  }
};

const referredBy = {
  label: 'How were you referred?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      optometrist: {
        label: 'Optometrist',
        subFieldIdList: [
          {
            id: 'specifyOptometrist'
          }
        ]
      },
      familyDoctor: {
        label: 'Family doctor',
        subFieldIdList: [
          {
            id: 'familyDoctorName'
          }
        ]
      },
      existingPatient: {
        label: 'I am an existing patient'
      },
      other: {
        label: 'Other',
        subFieldIdList: [
          {
            id: 'specify'
          }
        ]
      }
    }
  }
};

const describeRefractiveSurgery = {
  label:
    'Which of the following statements best reflects your primary reason for seeking Refractive surgery?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      forLifeStyleReasons: { label: 'For life style reasons' },
      betterWithoutGlasses: {
        label: 'I think I look better without glasses and do not like contact lenses'
      },
      careerReason: { label: 'For career reasons' },
      reduceDependency: { label: 'To reduce my dependence on glasses and/or contact lenses' }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const astigmatism = {
  label: 'Do you have astigmatism?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: { label: 'Yes' },
      no: { label: 'No' },
      dontKnow: {
        label: "I don't know"
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const eyeDropsForLubrication = {
  label: 'Do you use eye drops for lubrication?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        subFieldIdList: [
          {
            id: 'describeFrequency'
          }
        ]
      },
      no: {
        label: 'No'
      }
    }
  }
};

const prescriptionChanged = {
  label: 'Has your prescription changed over the past two years?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const visionProblemsAtNight = {
  label:
    'Are you experiencing vision problems when driving at night, such as halos, glare or blurriness?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const willingnessToSurgery = {
  label:
    'Like all surgical procedures, Refractive surgery has the risk of complications. Are you willing to educate yourself about those risks, accept a reasonable risk if you are an appropriate candidate and comply with a schedule of post-surgery medications and follow-up exams?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const enforcement = {
  label: 'Are you considering applying to police, RCMP, Military?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const medicalConditionList = {
  label:
    'Do you have any of the following conditions, or have had them in the past? (Select all that apply)',
  required: true,
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      anxiety: {
        label: 'Anxiety',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      migraine: {
        label: 'Migraine',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      asthma: {
        label: 'Asthma',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      seasonalAllergies: {
        label: 'Seasonal allergies',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      drugAllergies: {
        label: 'Drug allergies',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      environmentalAllergies: {
        label: 'Environmental allergies',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sunAllergy: {
        label: 'Sun allergy, or rash on sun exposure',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      chronicOBstructivePulmonaryDisease: {
        label: 'Chronic Obstructive Pulmonary Disease (COPD)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      abnormalBloodClotting: {
        label: 'Abnormal blood clotting',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      otherSkinConditions: {
        label: 'Other skin condition(s)',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      geneticConditions: {
        label: 'Genetic condition(s)',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      seizureDisorder: {
        label: 'Seizure disorder',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      graftHostDisease: {
        label: 'Graft versus host disease (GVHD)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      pigmentationChangeInFace: {
        label: 'Pigmentation change in the skin of the face.',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      fibromyalgia: {
        label: 'Fibromyalgia',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sensationOfHeatCondition: {
        label: 'Condition affecting sensation of heat',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      heatIllness: {
        label: 'Heat illness',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      heaterDermatitis: {
        label: 'Heater dermatitis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      keloidScars: {
        label: 'History of keloid scars (difficult and blistered healing)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      hypertension: {
        label: 'Hypertension',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      glaucoma: {
        label: 'Glaucoma',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      diabetes: {
        label: 'Diabetes',
        subFieldIdList: [
          {
            id: 'diabetesType',
            required: true
          },
          {
            id: 'meanBloodGlucose',
            required: true
          },
          {
            id: 'yearOfDiagnosis',
            required: true
          }
        ]
      },
      hypercholesterolemia: {
        label: 'Hypercholesterolemia',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      eczema: {
        label: 'Eczema',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      acne: {
        label: 'Acne',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      rosacea: {
        label: 'Rosacea',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      psoriasis: {
        label: 'Psoriasis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      lupus: {
        label: 'Lupus erythematosus',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      arthritis: {
        label: 'Arthritis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      rheumatoidArthritis: {
        label: 'Rheumatoid arthritis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      osteoporosis: {
        label: 'Osteoporosis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sclerosis: {
        label: 'Sclerosis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      thyroid: {
        label: 'Thyroid disease',
        subFieldIdList: [
          { id: 'describe', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      chronicPainSyndrome: {
        label: 'Chronic widespread pain syndrome',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      hepatitis: {
        label: 'Hepatitis C',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      hivOrAids: {
        label: 'HIV/AIDS or immunosuppressive disease',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      htlv: {
        label: 'Human T-cell lymphotropic virus-1 (HTLV-1)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      bellsPalsy: {
        label: 'Bell’s palsy',
        showSubField: true,
        sort: 4,
        subFieldIdList: [
          {
            id: 'bellsPalsySide',
            required: true
          },
          {
            id: 'yearOfDiagnosis',
            required: true
          }
        ]
      },
      pacemaker: {
        label: 'Pacemaker or implantable cardioverter defibrillator (ICD)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      stroke: {
        label: 'Stroke',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      cancer: {
        label: 'Cancer',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      vitaminDeficiency: {
        label: 'Vitamin deficiency',
        subFieldIdList: [
          { id: 'specify', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sexSteroidDeficiency: {
        label: 'Sex steroid deficiency',
        subFieldIdList: [
          { id: 'describe', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      gout: {
        label: 'Gout',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      vitiligo: {
        label: 'Vitiligo',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      liverDisease: {
        label: 'Liver disease',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      renalFailure: {
        label: 'Renal failure',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sjorgenSyndrome: {
        label: 'Sjögren syndrome',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      radiotherapy: {
        label: 'Radiotherapy',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      boneMarrow: {
        label: 'Bone marrow transplantation',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      irritableBowelSyndrome: {
        label: 'Irritable bowel syndrome (IBS)',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      polycysticOvary: {
        label: 'Polycystic ovary syndrome',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      fertility: {
        label: 'Fertility problems',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      pelvicPain: {
        label: 'Pelvic pain',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      psychiatricConditions: {
        label: 'Psychiatric conditions (e.g. depression, bipolar, schizophrenia)',
        subFieldIdList: [
          { id: 'describe', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      demodexSkinInfection: {
        label: 'Demodex skin infection',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      androgenDeficiency: {
        label: 'Androgen deficiency',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      sarcoidosis: {
        label: 'Sarcoidosis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      accutaneTreatment: {
        label: 'Treatment with accutane',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      heartDisease: {
        label: 'Heart disease(s)',
        subFieldIdList: [
          {
            id: 'specify',
            required: true
          },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      dandruff: {
        label: 'Dandruff',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      scleroderma: {
        label: 'Scleroderma',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      seborrheicDermatitis: {
        label: 'Seborrheic dermatitis',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      epilepsy: {
        label: 'Epilepsy',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      rheumaticFever: {
        label: 'Rheumatic fever',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      coldSoresOrGentialHerpesOrShingles: {
        label: 'Cold sores, genital herpes, and/or shingles',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      coronaryArteryDisease: {
        label: 'Coronary artery disease',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      lungDisease: {
        label: 'Lung disease',
        subFieldIdList: [
          {
            id: 'yearOfDiagnosis'
          }
        ]
      },
      conditionOther: {
        label: 'Other conditions',
        subFieldIdList: [
          { id: 'describe', required: true },
          {
            id: 'yearOfDiagnosis'
          }
        ]
      }
    }
  }
};

const otherGeneralHealthDetailsList = {
  label: '',
  typeConfig: {
    type: 'checkbox',
    required: true,
    valuesConfig: {
      denture: {
        label: 'Dentures',
        sort: 0
      },
      frontCaps: {
        label: 'Front caps',
        sort: 1
      },
      looseTooth: {
        label: 'Loose tooth',
        sort: 2
      },
      earPiercing: {
        label: 'Ear piercing',
        sort: 3
      },
      tonguePiercing: {
        label: 'Tongue piercing',
        sort: 4
      },
      bellyPiercing: {
        label: 'Belly piercing',
        sort: 5
      },
      otherPiercing: {
        label: 'Other piercing',
        sort: 6,
        subFieldIdList: [
          {
            id: 'describe',
            required: true
          }
        ]
      }
    }
  }
};

const smokeOrVape = {
  label: 'Do you smoke, vape, or have done either in the last 6 months?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No, I never have'
      },
      past: {
        label: 'No, but I have in the past',
        subFieldIdList: [
          {
            id: 'cigarettesPerWeek',
            required: true
          },
          {
            id: 'vapesPerWeek',
            required: true
          }
        ]
      },
      yes: {
        label: 'Yes',
        subFieldIdList: [
          {
            id: 'cigarettesPerWeek',
            required: true
          },
          {
            id: 'vapesPerWeek',
            required: true
          }
        ]
      }
    }
  }
};

const diabetesType = {
  label: 'Type',
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      type1: {
        label: 'Type 1',
        sort: 1
      },
      type2: {
        label: 'Type 2',
        sort: 2
      }
    }
  }
};
const wearGlasses = {
  label: 'Do you wear glasses?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const problemsWithGlassesOrContactLenses = {
  label: 'Do you have problems seeing with your glasses/contact lenses?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'wearGlasses'
        }
      ],
      type: 'or'
    }
  ]
};

const spectacleRxChanged = {
  label: 'Has your spectacle rx changed by more than 0.75 D in the last year?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'wearGlasses'
        }
      ],
      type: 'or'
    }
  ]
};

const firstName = {
  label: 'First name',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const lastName = {
  label: 'Last name',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};
const dateOfBirth = {
  label: 'Date of Birth',
  required: false,
  typeConfig: {
    type: 'date'
  }
};

const address = {
  label: 'Address',
  required: true,
  typeConfig: {
    multiLine: true,
    type: 'input'
  }
};
const phone = {
  label: 'Phone number',
  required: false,
  typeConfig: {
    type: 'phone'
  }
};

const email = {
  label: 'Email',
  required: true,
  typeConfig: {
    type: 'email'
  }
};

const healthCard = {
  label: 'Health card',
  required: false,
  typeConfig: {
    type: 'healthcard'
  }
};

const occupation = {
  label: 'Occupation',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const hobbies = {
  label: 'Hobbies',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const lastEyeExam = {
  label: 'Date of last eye exam',
  required: true,
  typeConfig: {
    type: 'date'
  }
};

const lookingForRefractiveSurgery = {
  label: 'Are you looking for refractive surgery?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes'
      },
      no: {
        label: 'No'
      }
    }
  }
};

const issueInEyeExam = {
  label: 'Were there any issues arising from this or any previous eye examination?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        subFieldIdList: [
          {
            id: 'describe',
            required: true
          }
        ]
      },
      no: {
        label: 'No'
      }
    }
  }
};

const patientMedications = {
  label: 'Please list any medications you are currently taking',
  typeConfig: {
    type: 'list',
    itemName: 'medication'
  }
};
const generalMedications = {
  label: 'Medications',
  required: false,
  typeConfig: {
    type: 'list',
    itemName: 'Medication'
  }
};

const meanBloodGlucose = {
  label: 'Mean blood glucose (mmol/L)',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const primaryCareProviderName = {
  label: "Primary care provider's name",
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const familyDoctor = {
  label: 'Family doctor',
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const preferredPharmacy = {
  label: 'Preferred Pharmacy',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const yearOfDiagnosis = {
  label: 'Year of diagnosis',
  required: false,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const cigarettesPerWeek = {
  label: 'Cigarettes per week',
  required: true,
  typeConfig: {
    max: 100,
    maxLabel: '',
    min: 0,
    minLabel: '',
    step: 5,
    showPlusSign: true,
    type: 'slider'
  }
};

const vapesPerWeek = {
  label: 'Vape puffs per week',
  required: true,
  typeConfig: {
    max: 1000,
    maxLabel: '',
    min: 0,
    minLabel: '',
    step: 100,
    showPlusSign: true,
    type: 'slider'
  }
};

// const gender = {
//   label: 'Which of the following best describes you? (Select all that apply)',
//   required: true,
//   typeConfig: {
//     type: 'checkbox',
//     valuesConfig: {
//       male: {
//         label: 'Male'
//       },
//       female: {
//         label: 'Female'
//       },
//       transgender: {
//         label: 'Transgender'
//       },
//       nonBinary: {
//         label: 'Non-binary/Non-conforming'
//       },
//       intersex: {
//         label: 'Intersex'
//       },
//       preferToDescribeGender: {
//         label: 'Prefer to describe',
//         subFieldIdList: [
//           {
//             id: 'describe',
//             required: true
//           }
//         ]
//       },
//       preferNotToSayGender: {
//         label: 'Prefer not to say'
//       }
//     }
//   }
// };

const gender = {
  label: 'Which of the following best describes you? (Select all that apply)',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      male: {
        label: 'Male'
      },
      female: {
        label: 'Female'
      },
      preferToDescribeGender: {
        label: 'Prefer to describe',
        subFieldIdList: [
          {
            id: 'describe',
            required: true
          }
        ]
      }
    }
  }
};

const menstruation = {
  label: 'Which of the following are you currently experiencing:',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      menopause: {
        label: 'Menopause'
      },
      regularMenstruation: {
        label: 'Regular menstruation'
      },
      irregularMenstruation: {
        label: 'Irregular menstruation'
      },
      pregnancy: {
        label: 'Pregnancy'
      },
      postmenopause: {
        label: 'Postmenopause'
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'female',
          key: 'gender'
        }
      ],
      type: 'or'
    }
  ]
};

const familyDoctorName = {
  label: "Family Doctor's name",
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const insulin = {
  label: 'Do you take Insulin?',
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No'
      },
      yes: {
        label: 'Yes'
      }
    }
  }
};
const optometristName = {
  label: "Optometrist's Name",
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};
const childBirth = {
  label: 'Have you given birth to a child?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'female',
          key: 'gender'
        }
      ],
      type: 'or'
    }
  ]
};
const describeAutoImmuneConditions = {
  label: 'Auto-immune conditions',
  typeConfig: {
    multiLine: true,
    type: 'input'
  }
};
const autoImmuneConditions = {
  label: 'Do you have any auto-immune conditions?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No',
        sort: 1
      },
      yes: {
        label: 'Yes',
        sort: 0,
        subFieldTitle: 'Please list them:',
        subFieldIdList: [{ id: 'describeAutoImmuneConditions', required: true }]
      }
    }
  }
};
const refractiveSurgeryRelatedConditions = {
  label: 'Do you have any of the following conditions? Please select all that apply',
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      diabetes: { label: 'Diabetes' },
      immunocompromised: { label: 'Immunocompromised for any reason' },
      autoimmuneDisease: {
        label:
          'Autoimmune disease (i.e. AIDS, lupus, rheumatoid arthritis, multiple sclerosis, myasthenia gravis, etc.)'
      },
      collagenVascularDisease: { label: 'Collagen vascular disease' },
      noConditions: {
        label: 'To the best of my knowledge I have none of these conditions'
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const slowHealing = {
  label:
    'Are you currently on any medications such as steroids, immuno-suppressants, which can slow or prevent healing?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const refractiveError = {
  label: 'What type of refractive error do you have?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      nearsighted: {
        label: 'Nearsightedness (myopia)'
      },
      farsighted: {
        label: 'Farsightedness (hyperopia)'
      },
      dontKnow: {
        label: "I don't know"
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'yes',
          key: 'lookingForRefractiveSurgery'
        }
      ],
      type: 'and'
    }
  ]
};

const levelOfNearsightedness = {
  label: 'What level of nearsightedness (myopia) do you have?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      low: { label: 'Low (Less than -3D)' },
      moderate: { label: 'Moderate (-3D to -6D)' },
      high: { label: 'High (Greater than -6D up to -9D)' },
      veryHight: { label: 'Very High (Greater than -9D)' },
      dontKnow: { label: `I don't know` }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'nearsighted',
          key: 'refractiveError'
        }
      ],
      type: 'and'
    }
  ]
};

const emergencyContact = {
  label: 'Emergency contact number',
  required: true,
  typeConfig: {
    type: 'phone'
  }
};

const familyHistoryConditionList = {
  label: 'Do you have a family history of any of the following:',
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      diabetesHistory: {
        label: 'Diabetes'
      },
      highBloodPressureHistory: {
        label: 'High blood pressure'
      },
      highCholestrolHistory: {
        label: 'High cholesterol'
      },
      cancerHistory: {
        label: 'Cancer'
      },
      autoImmuneDiseaseHistory: {
        label: 'Autoimmune disease'
      },
      rosaceaHistory: {
        label: 'Rosacea'
      },
      glaucomaHistory: {
        label: 'Glaucoma'
      },
      retinalDetachmentHistory: {
        label: 'Detached retina'
      },
      cataractsHistory: {
        label: 'Cataracts'
      }
    }
  }
};

const currentOrPastMedications = {
  label: 'Are you taking any medications, or have been taking any in the last 6 months?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No, I never have',
        sort: 0
      },
      past: {
        label: 'No, but I have in the past',
        sort: 1,
        subFieldIdList: [
          {
            id: 'medicationList'
          }
        ]
      },
      yes: {
        label: 'Yes',
        sort: 2,
        subFieldIdList: [
          {
            id: 'medicationList'
          }
        ]
      }
    }
  }
};

const ocularHistoryList = {
  label: 'Please select all that applies to your eye health history:',
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      wearsGlasses: {
        label: 'I wear glasses'
      },
      pastContactLensDiscomfort: {
        label: 'I have contact lens discomfort or intolerance'
      },
      lasikOrPRK: {
        label: 'I had Lasik or PRK'
      },
      orthokeratology: {
        label: 'I had orthokeratology'
      },
      myopia: {
        label:
          'I have myopia (near-sightedness), or I need prescription lenses to see distant objects'
      },
      hyperopia: {
        label:
          'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects'
      },
      presbyopia: {
        label: 'I have presbyopia, or I cannot see the computer screen without reading glasses'
      },
      amblyopia: {
        label: 'I have amblyopia, or lazy eye'
      },
      diplopia: {
        label: 'I have diplopia, or double vision'
      },
      strabismus: {
        label: 'I have or had strabismus, or my eyes are not aligned'
      },
      eyeTrauma: {
        label: 'I had trauma or injury to the eyes'
      },
      pterygium: {
        // tslint:disable-next-line:quotemark
        label: "I have pterygium, or surfer's Eye"
      },
      nystagmus: {
        label: 'Nystagmus'
      },
      retinalConditions: {
        label: 'Retinal conditions (e.g. previous eye infections)',
        subFieldIdList: [
          {
            id: 'specify',
            required: true
          }
        ]
      },
      dryEyes: {
        label: 'Dry eyes (burning, gritty, tearing, etc.)'
      },
      prisim: {
        label: 'Prism'
      },
      keratoconus: {
        label: 'Keratoconus'
      },
      previousEyeInfections: {
        label: 'Previous eye infections',
        subFieldIdList: [
          {
            id: 'specify',
            required: true
          }
        ]
      },
      cataracts: {
        label: 'Cataracts'
      },
      otherPastOcularHistory: {
        label: 'Other',
        subFieldIdList: [
          {
            id: 'describe',
            required: true
          }
        ]
      }
    }
  }
};

const specifyMedications = {
  label: 'Medication names(s)',
  required: true,
  typeConfig: {
    multiLine: false,
    type: 'input'
  }
};

const medicationList = {
  label: 'Select all that apply',
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      glaucomaDrops: {
        label: 'Glaucoma eye drops',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      antihistamineEyeDrops: {
        label: 'Antihistamine eye drops',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      allergyDrops: {
        label: 'Allergy drops',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      antihistamineDrugs: {
        label: 'Antihistamine drugs',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      tranquilizers: {
        label: 'Tranquilizers',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      sleepingTablets: {
        label: 'Sleeping tablets',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      antidepressents: {
        label: 'Antidepressants',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      oralSteroids: {
        label: 'Oral steroids',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      pillsForSkin: {
        label: 'Oral medication for skin problems or disorders',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      diuretics: {
        label: 'Diuretics (water pills for hypertension)',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      bloodPressurePills: {
        label: 'Blood pressure pills',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      hormoneTherapy: {
        label: 'Hormone therapy',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      oralContraceptives: {
        label: 'Oral Contraceptives',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      },
      medicationOther: {
        label: 'Other or unknown',
        subFieldIdList: [
          {
            id: 'specifyMedications',
            required: true
          }
        ]
      }
    }
  }
};

const eyeSurgeryPerformed = {
  label: 'Have you had any eye surgery?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0,
        subFieldIdList: [
          {
            id: 'eyeSurgeriesList',
            required: true
          }
        ]
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const driving = {
  label: 'Do you drive?',
  required: true,
  sort: 6,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const eyeSurgeriesList = {
  label: 'Select all that apply',
  typeConfig: {
    type: 'checkbox',
    required: true,
    valuesConfig: {
      glaucomaSurgery: {
        label: 'Glaucoma surgery',
        sort: 0
      },
      cataractSurgery: {
        label: 'Cataract surgery',
        sort: 1
      },
      strabismusSurgery: {
        label: 'Strabismus surgery',
        sort: 2
      },
      ptosisSurgery: {
        label: 'Ptosis surgery',
        sort: 3
      },
      retinalSurgery: {
        label: 'Retinal surgery',
        sort: 4
      },
      refractiveSurgery: {
        label: 'Refractive surgery (laser eye surgery)',
        sort: 5
      },
      eyelidSurgery: {
        label: 'Eyelid surgery',
        sort: 6
      },
      ocularSurfaceSurgery: {
        label: 'Ocular surface surgery',
        sort: 7
      },
      surgeryOther: {
        label: 'Other',
        sort: 8,
        subFieldIdList: [
          {
            id: 'describe',
            required: true
          }
        ]
      }
    }
  }
  // triggerGroups: [
  //   {
  //     triggers: [
  //       {
  //         compare: 'equals',
  //         compareValue: 'yes',
  //         key: 'eyeSurgeryPerformed'
  //       }
  //     ],
  //     type: 'or'
  //   }
  // ]
};

const botoxInjectionsToFace = {
  label: 'Have you had Botox injections to your face in the past?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const foodSwallowingAndDryMouth = {
  label: 'Do you experience difficulty swallowing food and/or dryness in your mouth?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const drinkAtNight = {
  label: 'Do you have to get up at night to drink?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  },
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'Yes',
          key: 'foodSwallowingAndDryMouth'
        }
      ],
      type: 'and'
    }
  ]
};

const lessSaliva = {
  label: 'Did your dentist tell you that you had less saliva and/or more cavities?',
  required: true,
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'Yes',
          key: 'foodSwallowingAndDryMouth'
        }
      ],
      type: 'and'
    }
  ],
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const swallowCracker = {
  label: 'Do you have to drink water to swallow a cracker?',
  required: true,
  sort: 16,
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'Yes',
          key: 'foodSwallowingAndDryMouth'
        }
      ],
      type: 'and'
    }
  ],
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const pastDryEyeTreatments = {
  label:
    'Are you currently using, or have you tried any of the following dry eye treatments? (select all that apply)',
  sort: 4,
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      artificialTears: {
        label: 'Artificial tears',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      warmCompresses: {
        label: 'Warm compresses',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      lidScrubs: {
        label: 'Lid scrubs / Lid hygiene',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      pharmaceuticals: {
        label: 'Pharmaceuticals',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      inOfficeTreatments: {
        label:
          'In office treatments such as intense pulsed light, Lipiflow, iLux, low level light therapy, and radiotherapy',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      omega3Supplements: {
        label: 'Omega-3 supplements',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      ointment: {
        label: 'Ointment',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      scleralLenses: {
        label: 'Scleral lenses',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      topicalCorticosteroids: {
        label: 'Topical corticosteroids (Lotemax, FML, etc.)',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      punctalPlugs: {
        label: 'Punctal plugs',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      },
      otherPastDryEyeTreatments: {
        label: 'Other',
        subFieldIdList: [
          {
            id: 'dryEyeTreatmentDetails',
            required: true
          }
        ]
      }
    }
  }
};

const contactLenses = {
  label: 'Do you wear contact lenses?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No, I never have'
      },
      past: {
        label: 'No, but I have in the past',
        subFieldIdList: [
          {
            id: 'contactLensdaysPerWeek',
            required: true
          },
          {
            id: 'contactType',
            required: true
          }
        ]
      },
      yes: {
        label: 'Yes',
        subFieldIdList: [
          {
            id: 'contactLensdaysPerWeek',
            required: true
          },
          {
            id: 'contactType',
            required: true
          }
        ]
      }
    }
  }
};

const contactLensdaysPerWeek = {
  label: 'Wearing days per week',
  required: true,
  typeConfig: {
    max: 7,
    maxLabel: '',
    min: 1,
    minLabel: '',
    step: 1,
    type: 'slider'
  }
  // triggerGroups: [
  //   {
  //     triggers: [
  //       {
  //         compare: 'equals',
  //         compareValue: 'yes',
  //         key: 'contactLenses'
  //       }
  //     ],
  //     type: 'and'
  //   }
  // ]
};

const contactType = {
  label: 'Contact lens type:',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      hard: {
        label: 'Hard',
        sort: 0
      },
      soft: {
        label: 'Soft',
        sort: 1,
        subFieldIdList: [
          {
            id: 'contactLensDailyDisposable',
            required: true
          }
        ]
      }
    }
  }
};

const sleepWithEyesOpen = {
  label: 'To your knowledge, do you sleep with your eyes open?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const contactLensDailyDisposable = {
  label: 'Are your contacts daily disposables?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      yes: {
        label: 'Yes',
        sort: 0
      },
      no: {
        label: 'No',
        sort: 1
      }
    }
  }
};

const eyeDryness = {
  label: 'Do you experience eye dryness and/or eye irritability?',
  required: true,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      no: {
        label: 'No',
        sort: 1
      },
      yes: {
        label: 'Yes',
        sort: 0
      }
    }
  }
};

const eyeDrynessSeverity = {
  label: 'Please rate your following symptoms on a scale of 0 to 10:',
  required: true,
  typeConfig: {
    max: 10,
    maxLabel: 'VERY SEVERE',
    min: 1,
    minLabel: 'VERY MILD',
    step: 1,
    type: 'slider'
  }
};

const eyeDrynessFrequency = {
  label: 'Frequency of symptoms',
  required: true,
  typeConfig: {
    max: 10,
    maxLabel: 'ALL THE TIME',
    min: 1,
    minLabel: 'RARELY',
    step: 1,
    type: 'slider'
  }
};

const eyeDrynessTiming = {
  label: 'When do these symptoms typically occur?',
  required: true,
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      onAwakening: {
        label: 'On awakening'
      },
      morning: {
        label: 'Morning'
      },
      afternoon: {
        label: 'Afternoon'
      },
      evening: {
        label: 'Evening'
      },
      night: {
        label: 'Night'
      },
      stable: {
        label: 'Stable all day'
      },
      noPattern: {
        label: 'No particular pattern'
      }
    }
  }
};
const bellsPalsySide = {
  label: 'Which side is affected?',
  required: true,
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      left: {
        label: 'Left side',
        sort: 1
      },
      right: {
        label: 'Right side',
        sort: 2
      }
    }
  }
};
const typeOfCpapMask = {
  label: 'What type of mask do you use?',
  required: true,
  sort: 5,
  triggerGroups: [
    {
      triggers: [
        {
          compare: 'equals',
          compareValue: 'Yes',
          key: 'cpapDuringSleep'
        }
      ],
      type: 'and'
    }
  ],
  typeConfig: {
    type: 'checkbox',
    valuesConfig: {
      nasal: {
        label: 'Nasal',
        sort: 1
      },
      full: {
        label: 'Full',
        sort: 0
      }
    }
  }
};
export const group1 = {
  formFields: {
    // artificialTears: {
    //   label: 'Artificial tears',
    //   sort: 0,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     },
    //     {
    //       id: 'numberOfTimesPerDay',
    //       required: true
    //     }
    //   ]
    // },
    // medicatedDrops: {
    //   label: 'Medicated drops',
    //   showSubField: true,
    //   sort: 1,
    //   subFieldIdList: [
    //     {
    //       id: 'specificMedicatedDrops',
    //       required: true
    //     }
    //   ]
    // },
    // ointment: {
    //   label: 'Ointment',
    //   showSubField: true,
    //   sort: 2,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     }
    //   ]
    // },
    // heatingMask: {
    //   label: 'Heating mask',
    //   showSubField: true,
    //   sort: 3,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     },
    //     {
    //       id: 'numberOfTimesPerDay',
    //       required: true
    //     }
    //   ]
    // },
    // wipeOrCleanser: {
    //   label: 'Wipes or cleanser for eyelashes and eyelids',
    //   showSubField: true,
    //   sort: 4,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     }
    //   ]
    // },
    // Omega3Supplements: {
    //   label: 'Omega-3 supplements',
    //   showSubField: true,
    //   sort: 5,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     }
    //   ]
    // },
    // antiRednessDrops: {
    //   label: 'Anti-redness drops',
    //   showSubField: true,
    //   sort: 6,
    //   subFieldIdList: [
    //     {
    //       id: 'specifyWhichOnes',
    //       required: true
    //     }
    //   ]
    // },
    // pastDryEyeTreatments: {
    //   label: 'Please tick the dry eye treatments you have tried in the past',
    //   required: true,
    //   sort: 1,
    //   typeConfig: {
    //     type: 'checkbox',
    //     valuesConfig: {
    //       intensePulsedLight: {
    //         label: 'Intense Pulsed Light (IPL)',
    //         sort: 3
    //       },
    //       lipiflow: {
    //         label: 'Lipiflow',
    //         sort: 2
    //       },
    //       other: {
    //         label: 'Other',
    //         sort: 6,
    //         subFieldIdList: [
    //           {
    //             id: 'specify',
    //             required: true
    //           }
    //         ]
    //       },
    //       punctalPlugs: {
    //         label: 'Punctal plugs',
    //         sort: 1
    //       },
    //       radioFrequency: {
    //         label: 'Radiofrequency',
    //         sort: 4
    //       },
    //       scleralLenses: {
    //         label: 'Scleral lenses',
    //         sort: 5
    //       },
    //       topicalCorticosteroids: {
    //         label: 'Topical corticosteroids (Lotemax, FML, etc.)',
    //         showSubField: true,
    //         sort: 0,
    //         subFieldIdList: [
    //           {
    //             id: 'specifyWhichOnes',
    //             required: true
    //           }
    //         ]
    //       }
    //     }
    //   }
    // },
    pastDryEyeTreatments,
    eyeDiscomfort: {
      label:
        'Do you experience discomfort in your eyes when you are in front of a screen or when you are reading (eg: confusion, watery eyes, which are hot, etc.)?',
      required: true,
      sort: 2,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0,
            subFieldTitle: 'After how long do you feel the discomfort?',
            subFieldIdList: [
              {
                id: 'discomfortAfterHowLongHours',
                required: true
              },
              {
                id: 'discomfortAfterHowLongMinutes',
                required: true
              }
            ]
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    workInDustyOrWindyPlace: {
      label: 'Do you work in a dusty or windy place?',
      required: true,
      sort: 3,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    mostDrynessTiming: {
      label: 'When do you feel the most dryness in your eyes?',
      required: true,
      sort: 4,
      typeConfig: {
        type: 'checkbox',
        valuesConfig: {
          afternoon: {
            label: 'Afternoon',
            sort: 2
          },
          evening: {
            label: 'Evening',
            sort: 3
          },
          morning: {
            label: 'Morning',
            sort: 1
          },
          night: {
            label: 'Night',
            sort: 4
          },
          onAwakening: {
            label: 'On awakening',
            sort: 0
          },
          stable: {
            label: 'Stable all day',
            sort: 5
          }
        }
      }
    }
  },
  nextLabel: 'Next',
  sort: 1,
  title: 'General'
};
export const group2 = {
  formFields: {
    eyeDiscomfortFrequency: {
      label: 'During a typical day in the past month, how often did your eyes feel discomfort?',
      required: true,
      sort: 5,
      typeConfig: {
        max: 4,
        min: 0,
        step: 1,
        type: 'slider',
        bottomLabels: ['NEVER', 'RARELY', 'SOMETIMES', 'FREQUENTLY', 'CONSTANTLY']
      }
    },
    eyeDiscomfortIntensity: {
      label:
        'When your eyes feel discomfort, how intense was this feeling of discomfort at the end of the day, within two hours of going to bed?',
      required: true,
      sort: 6,
      typeConfig: {
        max: 5,
        maxLabel: 'VERY INTENSE',
        min: 0,
        minLabel: 'NO DISCOMFORT',
        step: 1,
        type: 'slider'
      }
    },
    eyeDrynessFrequency: {
      label: 'During a typical day in the past month, how often did your eyes feel dry?',
      required: true,
      sort: 7,
      typeConfig: {
        max: 4,
        min: 0,
        step: 1,
        type: 'slider',
        bottomLabels: ['NEVER', 'RARELY', 'SOMETIMES', 'FREQUENTLY', 'CONSTANTLY']
      }
    },
    eyeDrynessIntensity: {
      label:
        'When your eyes felt dry, how intense was this feeling of dryness at the end of the day, within two hours of going to bed?',
      required: true,
      sort: 8,
      typeConfig: {
        max: 5,
        maxLabel: 'VERY INTENSE',
        min: 0,
        minLabel: 'NO DISCOMFORT',
        step: 1,
        type: 'slider'
      }
    },
    wateryEyes: {
      label:
        'During a typical day in the past month, how often did your eyes look or feel exessively watery?',
      required: true,
      sort: 9,
      typeConfig: {
        max: 4,
        min: 0,
        step: 1,
        type: 'slider',
        bottomLabels: ['NEVER', 'RARELY', 'SOMETIMES', 'FREQUENTLY', 'CONSTANTLY']
      }
    }
  },
  nextLabel: 'Next',
  sort: 2,
  title: 'DEQ'
};
export const group3 = {
  formFields: {
    botoxInjectionsToFace,
    eyeSurgeryPerformed,
    gender,
    menstruation,
    childBirth,
    foodSwallowingAndDryMouth,
    drinkAtNight,
    swallowCracker,
    lessSaliva,
    contactLenses,
    sleepWithEyesOpen,
    blurryVision: {
      label: 'Is your vision blurred in the morning when you wake up?',
      required: true,
      sort: 0,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    eyelidsGlued: {
      label: 'Do your eyelids seem glued together when you wake up?',
      required: true,
      sort: 1,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    sleepApneaTested: {
      label: 'Have you ever been tested for sleep apnea?',
      required: true,
      sort: 2,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    sleepApneaDiagnosed: {
      label: 'Have you been diagnosed with sleep apnea?',
      required: true,
      sort: 3,
      triggerGroups: [
        {
          triggers: [
            {
              compare: 'equals',
              compareValue: 'Yes',
              key: 'sleepApneaTested'
            }
          ],
          type: 'and'
        }
      ],
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0,
            subFieldIdList: [
              {
                id: 'yearOfDiagnosis',
                required: true
              }
            ]
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    cpapDuringSleep: {
      label: 'Do you use CPAP (assisted breathing during sleep)?',
      required: true,
      sort: 4,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0,
            subFieldIdList: [
              {
                id: 'typeOfCpapMask',
                required: true
              }
            ]
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    currentOrPastMedications,
    otherProductsInEyes: {
      label: 'Do you use any other products in your eyes or around the eyes?',
      required: true,
      sort: 8,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            showSubField: true,
            sort: 0,
            subFieldIdList: [
              {
                id: 'whichProducts',
                required: true
              }
            ]
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    }
  },
  nextLabel: 'Next',
  sort: 3,
  title: 'Medical History - 1'
};
export const group4 = {
  formFields: {
    medicalConditionList
  },
  nextLabel: 'Next',
  sort: 4,
  title: 'Medical History - 2'
};
export const group5 = {
  formFields: {
    selfTanner: {
      label: 'Are you currently using self-tanners?',
      required: true,
      sort: 0,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    uvExposure: {
      label: 'Have you been exposed to the sun or UV rays in the past 3 weeks?',
      required: true,
      sort: 1,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    // coldSores: {
    //   label: 'Presence or history of cold sore?',
    //   required: true,
    //   sort: 2,
    //   typeConfig: {
    //     type: 'radio',
    //     valuesConfig: {
    //       yes: {
    //         label: 'Yes',
    //         sort: 0
    //       },
    //       no: {
    //         label: 'No',
    //         sort: 1
    //       }
    //     }
    //   }
    // },
    ocularHerpes: {
      label: 'Presence or history of ocular herpes?',
      required: true,
      sort: 3,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    skinLesions: {
      label: 'Do you currently have skin lesions on your face?',
      required: true,
      sort: 4,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    skinProcedures: {
      label:
        'Have you had previous procedures on the skin such as: Botox, exfoliation, scrub, chemical peel, microdermabrasion, fillers, laser aesthetic treatment, neurotoxins, etc.?',
      required: true,
      sort: 5,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    facialProductsWithAcids: {
      label:
        'Do you use products with glycolic acid, salicylic acid, lactic acid, retinoid/retinol (vitamin A) or vitamin C on the face?',
      required: true,
      sort: 6,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    facialTattoos: {
      label:
        'Do you have a tattoo (including permanent make-up, eyebrows), microblading of eyebrows or pigmented lesion on the face?',
      required: true,
      sort: 7,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    },
    piercingsAboveHips: {
      label:
        'Do you have any piercing(s), metallic implants (fillings or pins included) located above the hips?',
      required: true,
      sort: 8,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          yes: {
            label: 'Yes',
            sort: 0
          },
          no: {
            label: 'No',
            sort: 1
          }
        }
      }
    }
  },
  nextLabel: 'Next',
  sort: 5,
  title: 'Information for the treatment suggestion'
};

export const naturalHairColor = {
  label: 'What color is your hair naturally?',
  required: true,
  sort: 1,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      hairColorRedhead: { label: '0 - Redhead', sort: 0 },
      hairColorBlond: { label: '1 - Blond', sort: 1 },
      hairColorBrownDarkBlond: { label: '2 - Brown, dark blond', sort: 2 },
      hairColorDarkBrowns: { label: '3 - Dark browns', sort: 3 },
      hairColorBlack: { label: '4 - Black', sort: 4 }
    }
  }
};

export const skinColor = {
  label: 'What is the color of your unexposed skin?',
  required: true,
  sort: 2,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      pink: { label: '0 - Pink', sort: 0 },
      veryPale: { label: '1 - Very pale', sort: 1 },
      paleBrownOlive: { label: '2 - Pale brown, olive', sort: 2 },
      brown: { label: '3 - Brown', sort: 3 },
      darkBrunette: { label: '4 - Dark brunette', sort: 4 }
    }
  }
};

export const eyeColor = {
  label: 'What color are your eyes?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      bluesGreensPaleGrays: { label: '0 - Blues, greens, pale grays', sort: 0 },
      bluesGreensGrays: { label: '1 - Blues, greens, grays', sort: 1 },
      bluesGreensDarkGraysPers: { label: '2 - Blues, greens, dark grays, pers', sort: 2 },
      darkBrowns: { label: '3 - Dark browns', sort: 3 },
      brownishBlacks: { label: '4 - Brownish blacks', sort: 4 }
    }
  }
};

export const freckles = {
  label: 'Do you have freckles on unexposed areas of your skin?',
  required: true,
  sort: 3,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      aLot: { label: '0 - A lot', sort: 0 },
      several: { label: '1 - Several', sort: 1 },
      some: { label: '2 - Some', sort: 2 },
      veryLittle: { label: '3 - Very little', sort: 3 },
      none: { label: '4 - None', sort: 4 }
    }
  }
};

export const longSunExposure = {
  label: 'What happens to your skin when you are in the sun for a long time?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      severeBurns: { label: '0 - Severe burns, blisters, peeling', sort: 0 },
      moderateBurns: { label: '1 - Moderate burns, blisters, peeling', sort: 1 },
      sometimesBurning: { label: '2 - Sometimes burning, then peeling', sort: 2 },
      rareBurns: { label: '3 - Rare burns', sort: 3 },
      noBurns: { label: '4 - No burns', sort: 4 }
    }
  }
};

export const darkerAfterSunExposure = {
  label: 'Do you get darker/tanned after sun exposure?',
  required: true,
  sort: 1,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      never: { label: '0 - Never', sort: 0 },
      rarely: { label: '1 - Rarely', sort: 1 },
      sometimes: { label: '2 - Sometimes', sort: 2 },
      often: { label: '3 - Often', sort: 3 },
      always: { label: '4 - Always', sort: 4 }
    }
  }
};

export const tanIntensity = {
  label: 'How intense is your tan?',
  required: true,
  sort: 0,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      slightNone: { label: '0 - Very slight or none', sort: 0 },
      lightTan: { label: '1 - Light tan', sort: 1 },
      mediumTan: { label: '2 - Medium tan', sort: 2 },
      darkTan: { label: '3 - Dark tan', sort: 3 },
      veryDarkTan: { label: '4 - Very dark tan', sort: 4 }
    }
  }
};

export const faceSunSensitivity = {
  label: 'Is your face sensitive to the sun?',
  required: true,
  sort: 2,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      verySensitive: { label: '0 - Very sensitive', sort: 0 },
      sensitive: { label: '1 - Sensitive', sort: 1 },
      moderatelySensitive: { label: '2 - Moderately sensitive', sort: 2 },
      resistant: { label: '3 - Resistant', sort: 3 },
      veryResistant: { label: '4 - Very resistant', sort: 4 }
    }
  }
};

export const sunTanExposureFrequency = {
  label: 'How often do you go in the sun to tan?',
  required: true,
  sort: 3,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      never: { label: '0 - Never', sort: 0 },
      rarely: { label: '1 - Rarely', sort: 1 },
      sometimes: { label: '2 - Sometimes', sort: 2 },
      often: { label: '3 - Often', sort: 3 },
      always: { label: '4 - Always', sort: 4 }
    }
  }
};

export const lastSunExposure = {
  label: 'When was your last exposure to the sun or UV?',
  required: true,
  sort: 4,
  typeConfig: {
    type: 'radio',
    valuesConfig: {
      moreThanThreeMonths: { label: '0 - More than 3 months', sort: 0 },
      lastTwoToThreeMonths: { label: '1 - In the last 2 to 3 months', sort: 1 },
      lastOneToTwoMonths: { label: '2 - In the last 1 to 2 months', sort: 2 },
      lastWeek: { label: '3 - In the last week', sort: 3 },
      lastDay: { label: '4 - In the last day', sort: 4 }
    }
  }
};

export const fitzpatrickQuestionGroup = {
  formFields: {
    eyeColor,
    naturalHairColor,
    skinColor,
    freckles,
    longSunExposure,
    darkerAfterSunExposure,
    tanIntensity,
    faceSunSensitivity,
    sunTanExposureFrequency,
    lastSunExposure
  },
  nextLabel: 'Next',
  sort: 6,
  title: 'Fitzpatrick Scale'
};

const speedFrequencyTemplateGroup = {
  formFields: {
    drynessGrittinessScratchinessFrequency: {
      label: 'Dryness, Grittiness or Scratchiness',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT']
      }
    },
    sorenessIrritationFrequency: {
      label: 'Soreness or Irritation',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT']
      }
    },
    burningWateringFrequency: {
      label: 'Burning or Watering',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT']
      }
    },
    eyeFatigueFrequency: {
      label: 'Eye Fatigue',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT']
      }
    }
  },
  nextLabel: 'Next',
  sort: 3,
  title: 'Report the FREQUENCY of your symptoms'
};

const speedSeverityTemplateGroup = {
  formFields: {
    drynessGrittinessScratchinessSeverity: {
      label: 'Dryness, Grittiness or Scratchiness',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 5,
        min: 0,
        step: 1,
        bottomLabels: [
          'UNKNOWN',
          'NO PROBLEMS',
          'TOLERABLE',
          'UNCOMFORTABLE',
          'BOTHERSOME',
          'INTOLERABLE'
        ]
      }
    },
    sorenessIrritationSeverity: {
      label: 'Soreness or Irritation',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 5,
        min: 0,
        step: 1,
        bottomLabels: [
          'UNKNOWN',
          'NO PROBLEMS',
          'TOLERABLE',
          'UNCOMFORTABLE',
          'BOTHERSOME',
          'INTOLERABLE'
        ]
      }
    },
    burningWateringSeverity: {
      label: 'Burning or Watering',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 5,
        min: 0,
        step: 1,
        bottomLabels: [
          'UNKNOWN',
          'NO PROBLEMS',
          'TOLERABLE',
          'UNCOMFORTABLE',
          'BOTHERSOME',
          'INTOLERABLE'
        ]
      }
    },
    eyeFatigueSeverity: {
      label: 'Eye Fatigue',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 5,
        min: 0,
        step: 1,
        bottomLabels: [
          'UNKNOWN',
          'NO PROBLEMS',
          'TOLERABLE',
          'UNCOMFORTABLE',
          'BOTHERSOME',
          'INTOLERABLE'
        ]
      }
    }
  },
  nextLabel: 'Next',
  sort: 4,
  title: 'Report the SEVERITY of your symptoms'
};

const osdiGeneralSymptomsTemplateGroup = {
  formFields: {
    lightSensitivity: {
      label: 'Eyes that are sensitive to light?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    },
    grittyFeeling: {
      label: 'Eyes that feel gritty?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    },
    painOrSoreness: {
      label: 'Painful or sore eyes?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    },
    blurredVision: {
      label: 'Blurred vision?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    },
    poorVision: {
      label: 'Poor vision?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: 0,
        step: 1,
        bottomLabels: ['NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    }
  },
  nextLabel: 'Next',
  sort: 5,
  title: 'Have you experienced any of the following during the last week?'
};

const osdiLimitedActivitiesTemplateGroup = {
  formFields: {
    reading: {
      label: 'Reading?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true,
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS']
      }
    },
    drivingAtNight: {
      label: 'Driving at night?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    },
    workingWithScreens: {
      label: 'Working with a computer or ATM?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    },
    watchingTV: {
      label: 'Watching TV?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    }
  },
  nextLabel: 'Next',
  sort: 6,
  title: 'Have your eyes limited you in performing any of the following during the last week?'
};

const osdiWeatherExacerbationsTemplateGroup = {
  formFields: {
    windyConditions: {
      label: 'Windy conditions?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    },
    lowHumidity: {
      label: 'Places with low humidity (very dry)?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    },
    airConditioned: {
      label: 'Areas that are air conditioned?',
      required: true,
      triggers: [],
      typeConfig: {
        type: 'slider',
        max: 4,
        min: -1,
        step: 1,
        bottomLabels: ['N/A', 'NEVER', 'SOMETIMES', 'HALF THE TIME', 'USUALLY', 'ALWAYS'],
        firstLabelIndex: 2,
        alwaysDisplayBottomLabelEndpoints: true
      }
    }
  },
  nextLabel: 'Next',
  sort: 6,
  title:
    'Have your eyes felt uncomfortable in any of the following situations during the last week?'
};
export const dedq = {
  id: 'dedq',
  abbreviation: 'DEDQ',
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing  management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Dry Eye Disease Questionnaire',
  type: 'questionnaire',
  supportingFields: {
    typeOfCpapMask,
    diabetesType,
    medicationList,
    specifyMedications,
    contactLensdaysPerWeek,
    contactType,
    eyeSurgeriesList,
    contactLensDailyDisposable,
    dryEyeTreatmentDetails: {
      label: 'Brand / Times per day / Duration / Last used',
      typeConfig: {
        multiLine: true,
        type: 'input'
      }
    },
    bellsPalsySide,
    discomfortAfterHowLongHours: {
      label: 'hours',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    discomfortAfterHowLongMinutes: {
      label: 'minutes',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    meanBloodGlucose: {
      label: 'Mean blood glucose (mmol/L)',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    // specificMedicatedDrops: {
    //   label: 'Please specify',
    //   required: true,
    //   typeConfig: {
    //     type: 'checkbox',
    //     valuesConfig: {
    //       cequa: {
    //         label: 'Cequa'
    //       },
    //       restasis: {
    //         label: 'Restasis'
    //       },
    //       xiidra: {
    //         label: 'Xiidra'
    //       }
    //     }
    //   }
    // },
    describe: {
      label: 'Please describe',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    specify: {
      label: 'Specify',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    specifyWhichOnes: {
      label: 'Specify which ones',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    numberOfTimesPerDay: {
      label: 'Number of times per day',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    whichDrops: {
      label: 'Which drops do you use?',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    whichProducts: {
      label: 'Which products do you use?',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    yearOfDiagnosis: {
      label: 'Year of diagnosis',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    }
  },
  templateGroups: {
    group1,
    group2,
    group3,
    group4,
    group5,
    fitzpatrickQuestionGroup
  }
};

export const fitzpatrick = {
  id: 'fitzpatrick',
  abbreviation: 'FST',
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing  management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Fitzpatrick Skin Type',
  type: 'questionnaire',
  supportingFields: {},
  templateGroups: {
    fitzpatrickQuestionGroup
  }
};

export const oliver_intake = {
  id: 'oliver_intake',
  abbreviation: 'INTK',
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing  management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Intake',
  type: 'questionnaire',
  supportingFields: {
    cigarettesPerWeek,
    meanBloodGlucose,
    vapesPerWeek,
    bellsPalsySide,
    eyeSurgeriesList,
    contactLensdaysPerWeek,
    contactType,
    yearOfDiagnosis,
    contactLensDailyDisposable,
    describe,
    specify,
    diabetesType
  },
  templateGroups: {
    patientInformation: {
      formFields: {
        firstName: {
          label: 'First name',
          sort: 0,
          typeConfig: {
            multiLine: false,
            type: 'input'
          },
          required: true
        },
        lastName: {
          label: 'Last name',
          required: true,
          sort: 1,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        email: {
          label: 'Email',
          required: true,
          sort: 6,
          typeConfig: {
            type: 'email'
          }
        },
        phone: {
          label: 'Phone number',
          required: true,
          sort: 5,
          typeConfig: {
            type: 'phone'
          }
        },
        address: {
          label: 'Address',
          required: true,
          sort: 4,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        hobbies: {
          label: 'Hobbies',
          required: true,
          sort: 8,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        occupation: {
          label: 'Occupations',
          required: true,
          sort: 7,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        emergencyContact,
        familyDoctor,
        optometristName,
        gender,
        menstruation,
        childBirth,
        patientMedications,
        familyHistoryConditionList,
        medicalConditionList
      },
      nextLabel: 'Next',
      sort: 1,
      title: 'General Questions'
    }
  }
};

export const vlq = {
  id: 'vlq',
  abbreviation: 'VLQ',
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing  management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Vision Lifestyle Questionnaire',
  type: 'questionnaire',
  supportingFields: {
    cigarettesPerWeek,
    meanBloodGlucose,
    vapesPerWeek,
    bellsPalsySide,
    eyeSurgeriesList,
    contactLensdaysPerWeek,
    contactType,
    yearOfDiagnosis,
    contactLensDailyDisposable,
    describe,
    specify,
    describeAutoImmuneConditions,
    diabetesType
  },
  templateGroups: {
    healthHistory: {
      formFields: {
        smokeOrVape,
        contactLenses,
        wearGlasses,
        problemWithoutGlassesOrLens: {
          label: 'Do you have problem seeing with your glasses/contact lenses?',
          required: true,
          sort: 2,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'Yes',
                  key: 'wearGlasses'
                },
                {
                  compare: 'equals',
                  compareValue: 'Yes',
                  key: 'contactLenses'
                }
              ],
              type: 'or'
            }
            // {
            //   triggers: [
            //     {
            //       compare: 'equals',
            //       compareValue: 'Yes',
            //       key: 'contactLenses'
            //     }
            //   ],
            //   type: 'or'
            // }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              yes: {
                label: 'Yes',
                sort: 0
              },
              no: {
                label: 'No',
                sort: 1
              }
            }
          }
        },
        glassesToDrive: {
          label: 'Do you wear glasses to drive?',
          required: true,
          sort: 5,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              yes: {
                label: 'Yes',
                sort: 0
              },
              no: {
                label: 'No',
                sort: 1
              }
            }
          }
        },
        glassesToReadPhone: {
          label: 'Do you wear glasses to read or use your phone?',
          required: true,
          sort: 3,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              yes: {
                label: 'Yes',
                sort: 0
              },
              no: {
                label: 'No',
                sort: 1
              }
            }
          }
        },
        glassesToWatchTV: {
          label: 'Do you wear glasses to watch TV?',
          required: true,
          sort: 4,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              yes: {
                label: 'Yes',
                sort: 0
              },
              no: {
                label: 'No',
                sort: 1
              }
            }
          }
        },
        driving,
        nightDriving: {
          label:
            'Are you experiencing vision problems when driving at night, such as halos, glare or blurriness?',
          required: true,
          sort: 7,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              yes: {
                label: 'Yes',
                sort: 0
              },
              no: {
                label: 'No',
                sort: 1
              }
            }
          }
        },
        nightGlassesToDrive: {
          label: 'How often do you drive at night?',
          required: true,
          sort: 7,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              frequently: {
                label: 'Frequently',
                sort: 0
              },
              infrequently: {
                label: 'Infrequently',
                sort: 2
              },
              occasionally: {
                label: 'Occasionally',
                sort: 1
              }
            }
          },
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'yes',
                  key: 'driving'
                }
              ],
              type: 'or'
            }
          ]
        },
        eyeSurgeryPerformed,
        listOfSymptoms: {
          label: 'Do you currently have any of the following symptoms? (Select all that apply)',
          sort: 1,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              currentContactLensDiscomfort: {
                label: 'Contact lens discomfort',
                sort: 6
              },
              crustyOrDischarge: {
                label: 'Crusty eyes ot eye discharge',
                sort: 7
              },
              enlargedGlands: {
                label: 'Enlarged glands',
                sort: 8
              },
              eyeDryness: {
                label: 'Eye dryness',
                sort: 0
              },
              eyeFatigue: {
                label: 'Eye fatigue',
                sort: 2
              },
              eyeItching: {
                label: 'Eye itching',
                sort: 3
              },
              eyeRedness: {
                label: 'Eye redness',
                sort: 1
              },
              fluctuatingVision: {
                label: 'Fluctuating vision',
                sort: 5
              },
              stringMucous: {
                label: 'Stringy mucous in or around eyes',
                sort: 4
              },
              swollenEyelids: {
                label: 'Swollen eyelids',
                sort: 9
              },
              symptomsRednessGreater: {
                label: 'Symptoms/redness greater in one eye',
                sort: 10
              }
            }
          }
        },
        ocularHistoryList,
        autoImmuneConditions
      },
      nextLabel: 'Next',
      sort: 2,
      title: 'Health Questions'
    }
  }
};

export const oliver_combined = {
  id: 'oliver_combined',
  abbreviation: 'VLQ',
  availableLanguages: [
    {
      id: 'en',
      label: 'English'
    },
    {
      id: 'fr',
      label: 'Français'
    }
  ],
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Vision Lifestyle Questionnaire',
  supportingFields: {
    bellsPalsySide: {
      label: 'Which side is affected?',
      required: true,
      typeConfig: {
        type: 'checkbox',
        valuesConfig: {
          left: {
            label: 'Left side',
            sort: 100
          },
          right: {
            label: 'Right side',
            sort: 200
          }
        }
      }
    },
    cigarettesPerWeek: {
      label: 'Cigarettes per week',
      required: true,
      typeConfig: {
        max: 100,
        maxLabel: '',
        min: 0,
        minLabel: '',
        showPlusSign: true,
        step: 5,
        type: 'slider'
      }
    },
    contactLensDailyDisposable: {
      label: 'Are your contacts daily disposables?',
      required: true,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          no: {
            label: 'No',
            sort: 100
          },
          yes: {
            label: 'Yes',
            sort: 0
          }
        }
      }
    },
    contactLensdaysPerWeek: {
      label: 'Wearing days per week',
      required: true,
      typeConfig: {
        max: 7,
        maxLabel: '',
        min: 1,
        minLabel: '',
        step: 1,
        type: 'slider'
      }
    },
    contactType: {
      label: 'Contact lens type:',
      required: true,
      sort: 0,
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          hard: {
            label: 'Hard',
            sort: 0
          },
          soft: {
            label: 'Soft',
            sort: 100,
            subFieldIdList: [
              {
                id: 'contactLensDailyDisposable',
                required: true
              }
            ]
          }
        }
      }
    },
    describe: {
      label: 'Please describe',
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    describeAutoImmuneConditions: {
      label: 'Auto-immune conditions',
      typeConfig: {
        multiLine: true,
        type: 'input'
      }
    },
    diabetesType: {
      label: 'Type',
      typeConfig: {
        type: 'radio',
        valuesConfig: {
          type1: {
            label: 'Type 1',
            sort: 100
          },
          type2: {
            label: 'Type 2',
            sort: 200
          }
        }
      }
    },
    eyeSurgeriesList: {
      label: 'Select all that apply',
      typeConfig: {
        required: true,
        type: 'checkbox',
        valuesConfig: {
          cataractSurgery: {
            label: 'Cataract surgery',
            sort: 100
          },
          eyelidSurgery: {
            label: 'Eyelid surgery',
            sort: 600
          },
          glaucomaSurgery: {
            label: 'Glaucoma surgery',
            sort: 0
          },
          ocularSurfaceSurgery: {
            label: 'Ocular surface surgery',
            sort: 700
          },
          ptosisSurgery: {
            label: 'Ptosis surgery',
            sort: 300
          },
          refractiveSurgery: {
            label: 'Refractive surgery (laser eye surgery)',
            sort: 500
          },
          retinalSurgery: {
            label: 'Retinal surgery',
            sort: 400
          },
          strabismusSurgery: {
            label: 'Strabismus surgery',
            sort: 200
          },
          surgeryOther: {
            label: 'Other',
            sort: 800,
            subFieldIdList: [
              {
                id: 'describe',
                required: true
              }
            ]
          }
        }
      }
    },
    meanBloodGlucose: {
      label: 'Mean blood glucose (mmol/L)',
      required: true,
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    specify: {
      label: 'Please specify',
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    },
    vapesPerWeek: {
      label: 'Vape puffs per week',
      required: true,
      typeConfig: {
        max: 1000,
        maxLabel: '',
        min: 0,
        minLabel: '',
        showPlusSign: true,
        step: 100,
        type: 'slider'
      }
    },
    yearOfDiagnosis: {
      label: 'Year of diagnosis',
      typeConfig: {
        multiLine: false,
        type: 'input'
      }
    }
  },
  templateGroups: {
    healthHistory: {
      formFields: {
        autoImmuneConditions: {
          label: 'Do you have any auto-immune conditions?',
          required: true,
          sort: 1200,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 0
              },
              yes: {
                label: 'Yes',
                sort: 100,
                subFieldIdList: [
                  {
                    id: 'describeAutoImmuneConditions',
                    required: true
                  }
                ],
                subFieldTitle: 'Please list them:'
              }
            }
          }
        },
        contactLenses: {
          label: 'Do you wear contact lenses?',
          required: true,
          sort: 100,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No, I never have',
                sort: 0
              },
              past: {
                label: 'No, but I have in the past',
                sort: 100,
                subFieldIdList: [
                  {
                    id: 'contactLensdaysPerWeek',
                    required: true
                  },
                  {
                    id: 'contactType',
                    required: true
                  }
                ]
              },
              yes: {
                label: 'Yes',
                sort: 200,
                subFieldIdList: [
                  {
                    id: 'contactLensdaysPerWeek',
                    required: true
                  },
                  {
                    id: 'contactType',
                    required: true
                  }
                ]
              }
            }
          }
        },
        driving: {
          label: 'Do you drive?',
          required: true,
          sort: 600,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        eyeSurgeryPerformed: {
          label: 'Have you had any eye surgery?',
          required: true,
          sort: 900,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0,
                subFieldIdList: [
                  {
                    id: 'eyeSurgeriesList',
                    required: true
                  }
                ]
              }
            }
          }
        },
        glassesToDrive: {
          label: 'Do you wear glasses to drive?',
          required: true,
          sort: 601,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'yes',
                  key: 'driving'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        glassesToReadPhone: {
          label: 'Do you wear glasses to read or use your phone?',
          required: true,
          sort: 500,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        glassesToWatchTV: {
          label: 'Do you wear glasses to watch TV?',
          required: true,
          sort: 550,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        listOfSymptoms: {
          label: 'Do you currently have any of the following symptoms? (Select all that apply)',
          sort: 1000,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              crustyOrDischarge: {
                label: 'Crusty eyes ot eye discharge',
                sort: 100
              },
              currentContactLensDiscomfort: {
                label: 'Contact lens discomfort',
                sort: 0
              },
              enlargedGlands: {
                label: 'Enlarged glands',
                sort: 200
              },
              eyeDryness: {
                label: 'Eye dryness',
                sort: 300
              },
              eyeFatigue: {
                label: 'Eye fatigue',
                sort: 400
              },
              eyeItching: {
                label: 'Eye itching',
                sort: 500
              },
              eyeRedness: {
                label: 'Eye redness',
                sort: 600
              },
              fluctuatingVision: {
                label: 'Fluctuating vision',
                sort: 700
              },
              stringMucous: {
                label: 'Stringy mucous in or around eyes',
                sort: 800
              },
              swollenEyelids: {
                label: 'Swollen eyelids',
                sort: 900
              },
              symptomsRednessGreater: {
                label: 'Symptoms/redness greater in one eye',
                sort: 1000
              }
            }
          }
        },
        nightDriving: {
          label:
            'Are you experiencing vision problems when driving at night, such as halos, glare or blurriness?',
          required: true,
          sort: 700,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'yes',
                  key: 'driving'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        nightGlassesToDrive: {
          label: 'How often do you drive at night?',
          required: true,
          sort: 800,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'yes',
                  key: 'driving'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              frequently: {
                label: 'Frequently',
                sort: 0
              },
              infrequently: {
                label: 'Infrequently',
                sort: 100
              },
              occasionally: {
                label: 'Occasionally',
                sort: 200
              }
            }
          }
        },
        ocularHistoryList: {
          label: 'Please select all that applies to your eye health history:',
          sort: 1100,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              amblyopia: {
                label: 'I have amblyopia, or lazy eye',
                sort: 700
              },
              cataracts: {
                label: 'Cataracts',
                sort: 1800
              },
              diplopia: {
                label: 'I have diplopia, or double vision',
                sort: 800
              },
              dryEyes: {
                label: 'Dry eyes (burning, gritty, tearing, etc.)',
                sort: 1400
              },
              eyeTrauma: {
                label: 'I had trauma or injury to the eyes',
                sort: 1000
              },
              hyperopia: {
                label:
                  'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects',
                sort: 500
              },
              keratoconus: {
                label: 'Keratoconus',
                sort: 1600
              },
              lasikOrPRK: {
                label: 'I had Lasik or PRK',
                sort: 200
              },
              myopia: {
                label:
                  'I have myopia (near-sightedness), or I need prescription lenses to see distant objects',
                sort: 400
              },
              nystagmus: {
                label: 'Nystagmus',
                sort: 1200
              },
              orthokeratology: {
                label: 'I had orthokeratology',
                sort: 300
              },
              otherPastOcularHistory: {
                label: 'Other',
                sort: 1900,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  }
                ]
              },
              pastContactLensDiscomfort: {
                label: 'I have contact lens discomfort or intolerance',
                sort: 100
              },
              presbyopia: {
                label:
                  'I have presbyopia, or I cannot see the computer screen without reading glasses',
                sort: 600
              },
              previousEyeInfections: {
                label: 'Previous eye infections',
                sort: 1700,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  }
                ]
              },
              prisim: {
                label: 'Prism',
                sort: 1500
              },
              pterygium: {
                label: "I have pterygium, or surfer's Eye",
                sort: 1100
              },
              retinalConditions: {
                label: 'Retinal conditions (e.g. previous eye infections)',
                sort: 1300,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  }
                ]
              },
              strabismus: {
                label: 'I have or had strabismus, or my eyes are not aligned',
                sort: 900
              },
              wearsGlasses: {
                label: 'I wear glasses',
                sort: 0
              }
            }
          }
        },
        problemWithoutGlassesOrLens: {
          label: 'Do you have problem seeing with your glasses/contact lenses?',
          required: true,
          sort: 300,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: 'Yes',
                  key: 'wearGlasses'
                },
                {
                  compare: 'equals',
                  compareValue: 'Yes',
                  key: 'contactLenses'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        },
        smokeOrVape: {
          label: 'Do you smoke, vape, or have done either in the last 6 months?',
          required: true,
          sort: 0,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No, I never have',
                sort: 0
              },
              past: {
                label: 'No, but I have in the past',
                sort: 100,
                subFieldIdList: [
                  {
                    id: 'cigarettesPerWeek',
                    required: true
                  },
                  {
                    id: 'vapesPerWeek',
                    required: true
                  }
                ]
              },
              yes: {
                label: 'Yes',
                sort: 200,
                subFieldIdList: [
                  {
                    id: 'cigarettesPerWeek',
                    required: true
                  },
                  {
                    id: 'vapesPerWeek',
                    required: true
                  }
                ]
              }
            }
          }
        },
        wearGlasses: {
          label: 'Do you wear glasess?',
          required: true,
          sort: 200,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 100
              },
              yes: {
                label: 'Yes',
                sort: 0
              }
            }
          }
        }
      },
      nextLabel: 'Next',
      sort: 200,
      title: 'Health Questions'
    },
    preferredLanguage: {
      formFields: {
        language: {
          label: 'Which language do you prefer?',
          required: true,
          sort: 0,
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              english: {
                label: 'English',
                sort: 0
              },
              french: {
                label: 'French',
                sort: 100
              },
              bilingual: {
                label: 'Bilingual',
                sort: 200
              }
            }
          }
        }
      },
      nextLabel: 'Next',
      sort: 10,
      title: 'Preferred Language'
    },
    patientInformation: {
      formFields: {
        address: {
          label: 'Address',
          required: true,
          sort: 400,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        childBirth: {
          label: 'Have you given birth to a child?',
          required: true,
          sort: 1200,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: true,
                  key: 'gender.female'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              no: {
                label: 'No',
                sort: 0
              },
              yes: {
                label: 'Yes',
                sort: 100
              }
            }
          }
        },
        email: {
          label: 'Email',
          required: true,
          sort: 200,
          typeConfig: {
            type: 'email'
          }
        },
        emergencyContact: {
          label: 'Emergency contact number',
          required: true,
          sort: 700,
          typeConfig: {
            type: 'phone'
          }
        },
        familyHistoryConditionList: {
          label: 'Do you have a family history of any of the following:',
          sort: 1400,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              autoImmuneDiseaseHistory: {
                label: 'Autoimmune disease',
                sort: 400
              },
              cancerHistory: {
                label: 'Cancer',
                sort: 300
              },
              cataractsHistory: {
                label: 'Cataracts',
                sort: 800
              },
              diabetesHistory: {
                label: 'Diabetes',
                sort: 0
              },
              glaucomaHistory: {
                label: 'Glaucoma',
                sort: 600
              },
              highBloodPressureHistory: {
                label: 'High blood pressure',
                sort: 100
              },
              highCholestrolHistory: {
                label: 'High cholesterol',
                sort: 200
              },
              retinalDetachmentHistory: {
                label: 'Detached retina',
                sort: 700
              },
              rosaceaHistory: {
                label: 'Rosacea',
                sort: 500
              }
            }
          }
        },
        firstName: {
          label: 'First name',
          required: true,
          sort: 0,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        gender: {
          label: 'Which of the following best describes you? (Select all that apply)',
          required: true,
          sort: 1000,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              female: {
                label: 'Female',
                sort: 100
              },
              intersex: {
                label: 'Intersex',
                sort: 400
              },
              male: {
                label: 'Male',
                sort: 0
              },
              nonBinary: {
                label: 'Non-binary/Non-conforming',
                sort: 300
              },
              preferNotToSayGender: {
                label: 'Prefer not to say',
                sort: 600
              },
              preferToDescribeGender: {
                label: 'Prefer to describe',
                sort: 500,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  }
                ]
              },
              transgender: {
                label: 'Transgender',
                sort: 200
              }
            }
          }
        },
        hobbies: {
          label: 'Hobbies',
          sort: 500,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        lastName: {
          label: 'Last name',
          required: true,
          sort: 100,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        medicalConditionList: {
          label:
            'Do you have any of the following conditions, or have had them in the past? (Select all that apply)',
          required: true,
          sort: 1500,
          typeConfig: {
            type: 'checkbox',
            valuesConfig: {
              abnormalBloodClotting: {
                label: 'Abnormal blood clotting',
                sort: 800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              accutaneTreatment: {
                label: 'Treatment with accutane',
                sort: 5800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              acne: {
                label: 'Acne',
                sort: 2400,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              androgenDeficiency: {
                label: 'Androgen deficiency',
                sort: 5600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              anxiety: {
                label: 'Anxiety',
                sort: 0,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              arthritis: {
                label: 'Arthritis',
                sort: 2800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              asthma: {
                label: 'Asthma',
                sort: 200,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              bellsPalsy: {
                label: 'Bell’s palsy',
                showSubField: true,
                sort: 3700,
                subFieldIdList: [
                  {
                    id: 'bellsPalsySide',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis',
                    required: true
                  }
                ]
              },
              boneMarrow: {
                label: 'Bone marrow transplantation',
                sort: 4900,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              cancer: {
                label: 'Cancer',
                sort: 4000,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              chronicOBstructivePulmonaryDisease: {
                label: 'Chronic Obstructive Pulmonary Disease (COPD)',
                sort: 700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              chronicPainSyndrome: {
                label: 'Chronic widespread pain syndrome',
                sort: 3300,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              coldSoresOrGentialHerpesOrShingles: {
                label: 'Cold sores, genital herpes, and/or shingles',
                sort: 6500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              conditionOther: {
                label: 'Other conditions',
                sort: 6800,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              coronaryArteryDisease: {
                label: 'Coronary artery disease',
                sort: 6600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              dandruff: {
                label: 'Dandruff',
                sort: 6000,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              demodexSkinInfection: {
                label: 'Demodex skin infection',
                sort: 5500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              diabetes: {
                label: 'Diabetes',
                sort: 2100,
                subFieldIdList: [
                  {
                    id: 'diabetesType',
                    required: true
                  },
                  {
                    id: 'meanBloodGlucose',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis',
                    required: true
                  }
                ]
              },
              drugAllergies: {
                label: 'Drug allergies',
                sort: 400,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              eczema: {
                label: 'Eczema',
                sort: 2300,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              environmentalAllergies: {
                label: 'Environmental allergies',
                sort: 500,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              epilepsy: {
                label: 'Epilepsy',
                sort: 6300,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              fertility: {
                label: 'Fertility problems',
                sort: 5200,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              fibromyalgia: {
                label: 'Fibromyalgia',
                sort: 1400,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              geneticConditions: {
                label: 'Genetic condition(s)',
                sort: 1000,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              glaucoma: {
                label: 'Glaucoma',
                sort: 2000,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              gout: {
                label: 'Gout',
                sort: 4300,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              graftHostDisease: {
                label: 'Graft versus host disease (GVHD)',
                sort: 1200,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              heartDisease: {
                label: 'Heart disease(s)',
                sort: 5900,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              heaterDermatitis: {
                label: 'Heater dermatitis',
                sort: 1700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              heatIllness: {
                label: 'Heat illness',
                sort: 1600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              hepatitis: {
                label: 'Hepatitis C',
                sort: 3400,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              hivOrAids: {
                label: 'HIV/AIDS or immunosuppressive disease',
                sort: 3500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              htlv: {
                label: 'Human T-cell lymphotropic virus-1 (HTLV-1)',
                sort: 3600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              hypercholesterolemia: {
                label: 'Hypercholesterolemia',
                sort: 2200,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              hypertension: {
                label: 'Hypertension',
                sort: 1900,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              irritableBowelSyndrome: {
                label: 'Irritable bowel syndrome (IBS)',
                sort: 5000,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              keloidScars: {
                label: 'History of keloid scars (difficult and blistered healing)',
                sort: 1800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              liverDisease: {
                label: 'Liver disease',
                sort: 4500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              lungDisease: {
                label: 'Lung disease',
                sort: 6700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              lupus: {
                label: 'Lupus erythematosus',
                sort: 2700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              migraine: {
                label: 'Migraine',
                sort: 100,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              osteoporosis: {
                label: 'Osteoporosis',
                sort: 3000,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              otherSkinConditions: {
                label: 'Other skin condition(s)',
                sort: 900,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              pacemaker: {
                label: 'Pacemaker or implantable cardioverter defibrillator (ICD)',
                sort: 3800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              pelvicPain: {
                label: 'Pelvic pain',
                sort: 5300,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              pigmentationChangeInFace: {
                label: 'Pigmentation change in the skin of the face.',
                sort: 1300,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              polycysticOvary: {
                label: 'Polycystic ovary syndrome',
                sort: 5100,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              psoriasis: {
                label: 'Psoriasis',
                sort: 2600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              psychiatricConditions: {
                label: 'Psychiatric conditions (e.g. depression, bipolar, schizophrenia)',
                sort: 5400,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              radiotherapy: {
                label: 'Radiotherapy',
                sort: 4800,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              renalFailure: {
                label: 'Renal failure',
                sort: 4600,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              rheumaticFever: {
                label: 'Rheumatic fever',
                sort: 6400,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              rheumatoidArthritis: {
                label: 'Rheumatoid arthritis',
                sort: 2900,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              rosacea: {
                label: 'Rosacea',
                sort: 2500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sarcoidosis: {
                label: 'Sarcoidosis',
                sort: 5700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              scleroderma: {
                label: 'Scleroderma',
                sort: 6100,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sclerosis: {
                label: 'Sclerosis',
                sort: 3100,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              seasonalAllergies: {
                label: 'Seasonal allergies',
                sort: 300,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              seborrheicDermatitis: {
                label: 'Seborrheic dermatitis',
                sort: 6200,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              seizureDisorder: {
                label: 'Seizure disorder',
                sort: 1100,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sensationOfHeatCondition: {
                label: 'Condition affecting sensation of heat',
                sort: 1500,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sexSteroidDeficiency: {
                label: 'Sex steroid deficiency',
                sort: 4200,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sjorgenSyndrome: {
                label: 'Sjögren syndrome',
                sort: 4700,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              stroke: {
                label: 'Stroke',
                sort: 3900,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              sunAllergy: {
                label: 'Sun allergy, or rash on sun exposure',
                sort: 600,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              thyroid: {
                label: 'Thyroid disease',
                sort: 3200,
                subFieldIdList: [
                  {
                    id: 'describe',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              vitaminDeficiency: {
                label: 'Vitamin deficiency',
                sort: 4100,
                subFieldIdList: [
                  {
                    id: 'specify',
                    required: true
                  },
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              },
              vitiligo: {
                label: 'Vitiligo',
                sort: 4400,
                subFieldIdList: [
                  {
                    id: 'yearOfDiagnosis'
                  }
                ]
              }
            }
          }
        },
        menstruation: {
          label: 'Which of the following are you currently experiencing:',
          required: true,
          sort: 1100,
          triggerGroups: [
            {
              triggers: [
                {
                  compare: 'equals',
                  compareValue: true,
                  key: 'gender.female'
                }
              ],
              type: 'or'
            }
          ],
          typeConfig: {
            type: 'radio',
            valuesConfig: {
              irregularMenstruation: {
                label: 'Irregular menstruation',
                sort: 200
              },
              menopause: {
                label: 'Menopause',
                sort: 0
              },
              postmenopause: {
                label: 'Postmenopause',
                sort: 400
              },
              pregnancy: {
                label: 'Pregnancy',
                sort: 300
              },
              regularMenstruation: {
                label: 'Regular menstruation',
                sort: 100
              }
            }
          }
        },
        occupation: {
          label: 'Occupations',
          required: true,
          sort: 600,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        optometristName: {
          label: "Optometrist's Name",
          sort: 900,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        patientMedications: {
          label: 'Please list any medications you are currently taking',
          sort: 1300,
          typeConfig: {
            itemName: 'medication',
            type: 'list'
          }
        },
        phone: {
          label: 'Phone number',
          required: true,
          sort: 300,
          typeConfig: {
            type: 'phone'
          }
        },
        preferredPharmacy: {
          label: 'Preferred Pharmacy',
          required: true,
          sort: 1000,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        },
        primaryCareProviderName: {
          label: "Primary care provider's name",
          sort: 800,
          typeConfig: {
            multiLine: false,
            type: 'input'
          }
        }
      },
      nextLabel: 'Next',
      sort: 100,
      title: 'General Questions'
    },
    speedFrequencyTemplateGroup: {
      formFields: {
        burningWateringFrequency: {
          label: 'Burning or Watering',
          required: true,
          sort: 200,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        drynessGrittinessScratchinessFrequency: {
          label: 'Dryness, Grittiness or Scratchiness',
          required: true,
          sort: 0,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        eyeFatigueFrequency: {
          label: 'Eye Fatigue',
          required: true,
          sort: 300,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        sorenessIrritationFrequency: {
          label: 'Soreness or Irritation',
          required: true,
          sort: 100,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        }
      },
      nextLabel: 'Next',
      sort: 300,
      title: 'Report the FREQUENCY of your symptoms'
    },
    speedSeverityTemplateGroup: {
      formFields: {
        burningWateringSeverity: {
          label: 'Burning or Watering',
          required: true,
          sort: 200,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        drynessGrittinessScratchinessSeverity: {
          label: 'Dryness, Grittiness or Scratchiness',
          required: true,
          sort: 0,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        eyeFatigueSeverity: {
          label: 'Eye Fatigue',
          required: true,
          sort: 300,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        },
        sorenessIrritationSeverity: {
          label: 'Soreness or Irritation',
          required: true,
          sort: 100,
          triggers: [],
          typeConfig: {
            bottomLabels: ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'],
            max: 4,
            min: 0,
            step: 1,
            type: 'slider'
          }
        }
      },
      nextLabel: 'Next',
      sort: 400,
      title: 'Report the SEVERITY of your symptoms'
    }
  },
  type: 'questionnaire'
};

export const standard_intakeForm = {
  name: 'Standard Intake Form',
  id: 'standard_intakeForm',
  type: 'intake',
  message: '',
  abbreviation: 'INTK',
  supportingFields: {
    describe,
    specify,
    contactLensdaysPerWeek,
    contactLensDailyDisposable,
    contactType,
    specifyOptometrist,
    familyDoctorName,
    yearOfDiagnosis,
    diabetesType,
    bellsPalsySide,
    meanBloodGlucose
  },
  templateGroups: {
    patientInformation: {
      formFields: {
        firstName,
        lastName,
        address,
        dateOfBirth,
        phone,
        email,
        healthCard,
        gender: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        genderOther: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        country: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        province: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        city: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        postalCode: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        entryMethod: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        }
      },
      customComponent: 'PatientForm',
      nextLabel: 'Next',
      title: 'Patient Information',
      sort: 0
    },
    patientGeneralHealthQuestion: {
      formFields: {
        occupation,
        hobbies,
        wearGlasses,
        contactLenses,
        lastEyeExam,
        issueInEyeExam,
        generalMedications
      },
      nextLabel: 'Next',
      sort: 1,
      title: 'General Questions'
    },
    ocularHealthQuestion: {
      formFields: {
        ocularHistoryList
      },
      nextLabel: 'Next',
      sort: 2,
      title: 'Ocular Health Questions'
    },
    medicalConditions: {
      formFields: {
        medicalConditionList
      },
      nextLabel: 'Next',
      sort: 3,
      title: 'General Health Questions'
    },
    speedFrequencyTemplateGroup: { ...speedFrequencyTemplateGroup, sort: 5 },
    speedSeverityTemplateGroup: { ...speedSeverityTemplateGroup, sort: 6 },
    osdiGeneralSymptomsTemplateGroup: { ...osdiGeneralSymptomsTemplateGroup, sort: 7 },
    osdiLimitedActivitiesTemplateGroup: { ...osdiLimitedActivitiesTemplateGroup, sort: 8 },
    osdiWeatherExacerbationsTemplateGroup: { ...osdiWeatherExacerbationsTemplateGroup, sort: 9 }
  },

  availableLanguages: [
    { id: 'en', label: 'English' },
    { id: 'fr', label: 'Français' }
  ]
};

export const clarity_intakeForm = {
  name: 'Intake Form',
  id: 'clarity_intakeForm',
  type: 'intake',
  message: '',
  abbreviation: 'INTK',
  supportingFields: {
    describe,
    specify,
    describeFrequency,
    contactLensdaysPerWeek,
    contactLensDailyDisposable,
    cigarettesPerWeek,
    vapesPerWeek,
    contactType,
    specifyOptometrist,
    familyDoctorName,
    yearOfDiagnosis,
    diabetesType,
    bellsPalsySide,
    meanBloodGlucose
  },
  templateGroups: {
    patientInformation: {
      formFields: {
        firstName,
        lastName,
        address,
        dateOfBirth,
        phone,
        email,
        healthCard,
        gender: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        genderOther: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        country: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        province: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        city: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        postalCode: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        entryMethod: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        }
      },
      customComponent: 'PatientForm',
      nextLabel: 'Next',
      title: 'Patient Information',
      sort: 0
    },
    patientGeneralHealthQuestion: {
      formFields: {
        occupation,
        hobbies,
        wearGlasses,
        problemsWithGlassesOrContactLenses,
        spectacleRxChanged,
        contactLenses,
        smokeOrVape,
        lastEyeExam,
        issueInEyeExam,
        eyeDropsForLubrication,
        generalMedications
      },
      nextLabel: 'Next',
      sort: 1,
      title: 'General Questions'
    },
    ocularHealthQuestion: {
      formFields: {
        ocularHistoryList
      },
      nextLabel: 'Next',
      sort: 2,
      title: 'Ocular Health Questions'
    },
    eyeSurgeryQuestion: {
      formFields: {
        eyeSurgeriesList
      },
      nextLabel: 'Next',
      sort: 3,
      title: 'Past Eye Surgeries'
    },
    medicalConditions: {
      formFields: {
        medicalConditionList
      },
      nextLabel: 'Next',
      sort: 4,
      title: 'General Health Details - Do you have or have you ever had?'
    },
    otherGeneralHealthDetails: {
      formFields: {
        otherGeneralHealthDetailsList
      },
      nextLabel: 'Next',
      sort: 5,
      title: 'General Health Details (II) - Do you have or have you ever had?'
    },
    patientScreening: {
      formFields: {
        referredBy,
        lookingForRefractiveSurgery,
        describeRefractiveSurgery,
        refractiveSurgeryRelatedConditions,
        slowHealing,
        refractiveError,
        levelOfNearsightedness,
        astigmatism,
        prescriptionChanged,
        visionProblemsAtNight,
        willingnessToSurgery,
        enforcement
      },
      nextLabel: 'Next',
      sort: 6,
      title: 'Pre-screening Questions'
    },
    speedFrequencyTemplateGroup: { ...speedFrequencyTemplateGroup, sort: 7 },
    speedSeverityTemplateGroup: { ...speedSeverityTemplateGroup, sort: 8 },
    osdiGeneralSymptomsTemplateGroup: { ...osdiGeneralSymptomsTemplateGroup, sort: 9 },
    osdiLimitedActivitiesTemplateGroup: { ...osdiLimitedActivitiesTemplateGroup, sort: 10 },
    osdiWeatherExacerbationsTemplateGroup: { ...osdiWeatherExacerbationsTemplateGroup, sort: 11 }
  },

  availableLanguages: [
    { id: 'en', label: 'English' },
    { id: 'fr', label: 'Français' }
  ]
};

export const donnelly_intakeForm = {
  name: 'Intake Form',
  id: 'donnelly_intakeForm',
  type: 'intake',
  message: '',
  abbreviation: 'INTK',
  supportingFields: {
    describe,
    specify,
    contactLensdaysPerWeek,
    contactLensDailyDisposable,
    contactType,
    specifyOptometrist,
    familyDoctorName,
    yearOfDiagnosis,
    diabetesType,
    bellsPalsySide,
    meanBloodGlucose
  },
  templateGroups: {
    patientInformation: {
      formFields: {
        firstName,
        lastName,
        address,
        dateOfBirth,
        phone,
        email,
        healthCard,
        gender: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        genderOther: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        country: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        province: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        city: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        postalCode: {
          label: '',
          required: true,
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        },
        entryMethod: {
          label: '',
          typeConfig: {
            type: 'input',
            multiLine: false
          }
        }
      },
      customComponent: 'PatientForm',
      nextLabel: 'Next',
      title: 'Patient Information',
      sort: 0
    },
    patientGeneralHealthQuestion: {
      formFields: {
        wearGlasses,
        contactLenses,
        lastEyeExam,
        issueInEyeExam,
        generalMedications
      },
      nextLabel: 'Next',
      sort: 1,
      title: 'General Questions'
    },
    speedFrequencyTemplateGroup: { ...speedFrequencyTemplateGroup, sort: 5 },
    speedSeverityTemplateGroup: { ...speedSeverityTemplateGroup, sort: 6 },
    osdiGeneralSymptomsTemplateGroup: { ...osdiGeneralSymptomsTemplateGroup, sort: 7 },
    osdiLimitedActivitiesTemplateGroup: { ...osdiLimitedActivitiesTemplateGroup, sort: 8 },
    osdiWeatherExacerbationsTemplateGroup: { ...osdiWeatherExacerbationsTemplateGroup, sort: 9 }
  },

  availableLanguages: [
    { id: 'en', label: 'English' },
    { id: 'fr', label: 'Français' }
  ]
};

export const oliver_welcomeIntakeForm = {
  id: 'oliver_welcomeIntakeForm',
  abbreviation: 'INTK',
  message:
    'By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing  management options. You will have two weeks to complete the questionnaire. Please note that certain fields in this questionnaire may be disabled as they have been pre-populated based on your previous submission. If you notice any incorrect information, please kindly contact your clinic, and provide the correct details to ensure accurate record keeping. Thank you for your attention to this matter.',
  name: 'Welcome Intake Form',
  type: 'intake',
  supportingFields: {},
  templateGroups: {
    patientInformation: {
      formFields: {
        firstName,
        lastName,
        email,
        phone
      },
      nextLabel: 'Next',
      sort: 1,
      title: 'Contact Information'
    }
  }
};

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'csi-form-template-group',
  templateUrl: './form-template-group.component.html',
  styleUrls: ['./form-template-group.component.scss']
})
export class FormTemplateGroupComponent implements OnInit {
  @Input() formTemplateGroups: FormGroup;

  public currentFormGroup: FormGroup;

  noSort = noSort;

  constructor() {}

  ngOnInit() {}

  addTemplateGroup() {
    if (!this.currentFormGroup || this.currentFormGroup.valid) {
      const formGroupToAdd = new FormGroup({
        title: new FormControl(null, Validators.required),
        friendlyId: new FormControl(null, Validators.required),
        formFields: new FormGroup({})
      });

      this.formTemplateGroups.addControl(uuid(), formGroupToAdd);

      this.currentFormGroup = formGroupToAdd;
    }
  }

  addTemplateField() {
    if (this.currentFormGroup.controls.formFields.valid) {
      (this.currentFormGroup.controls.formFields as FormGroup).addControl(
        uuid(),
        new FormGroup({
          id: new FormControl(),
          label: new FormControl(null, Validators.required),
          typeConfig: new FormGroup({ type: new FormControl(null, Validators.required) }),
          triggers: new FormControl(null),
          required: new FormControl(null)
        })
      );
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import { noSort } from 'src/app/core/api/model-utils';
import { signGroups } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments-schema';
import { Attachment } from 'src/app/shared/attachments/attachments.component';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { SignInstructionsModalComponent } from '../sign-instructions-modal/sign-instructions-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'csi-mobile-image-wizard',
  templateUrl: './mobile-image-wizard.component.html',
  styleUrls: ['./mobile-image-wizard.component.scss']
})
export class MobileImageWizardComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper;
  public signGroups = signGroups;
  public formGroup: FormGroup = new FormGroup({});
  public selectedIndex = 0;
  public metadata: { [key: string]: { [key: string]: string } };
  public otherAttachments: Attachment[] = [];
  public groupKeyToIndexMap: { [key: string]: number } = {};

  constructor(
    public dataSrcService: DataSrcService,
    public dialog: MatDialog,
    private loadingSpinnerService: LoadingSpinnerService,
    private assessmentService: AssessmentService,
    private router: Router
  ) {}
  returnToHomePage = location.search.includes('return=true');

  ngOnInit() {
    const id = location.pathname.substring(1, location.pathname.indexOf('/', 2));

    let i = 0;
    Object.keys(this.signGroups).forEach(groupKey => {
      this.groupKeyToIndexMap[groupKey] = i;
      i++;
      this.formGroup.addControl('RIGHT_' + groupKey, new FormControl([]));
      this.formGroup.addControl('LEFT_' + groupKey, new FormControl([]));
    });

    this.assessmentService.getAssessment(id, 'no-cache').subscribe(data => {
      const attachments = data.attachments;
      if (!attachments) return;
      this.loadingSpinnerService.show();

      for (const attachment of attachments) {
        if (attachment['metadata']) {
          const metadata = JSON.parse(attachment['metadata']);
          if (metadata && metadata['sign']) {
            const formControl = this.formGroup.controls[metadata['sign']];
            if (formControl) {
              formControl.patchValue([attachment]);
            } else {
              this.otherAttachments.push(attachment);
            }
          }
        }
      }
      this.loadingSpinnerService.hide();
    });
  }

  viewInstructions(key: string) {
    SignInstructionsModalComponent.open(this.dialog, this.signGroups[key].instructions);
  }

  editImages(groupKey: string) {
    this.stepper.selectedIndex = this.groupKeyToIndexMap[groupKey];
  }

  public getFileName(attachment: Attachment): string {
    return this.dataSrcService.getFileName(attachment);
  }

  submit() {
    let attachments = [];
    Object.keys(this.formGroup.controls).forEach(key => {
      attachments = [...attachments, ...this.formGroup.controls[key].value];
    });

    const id = location.pathname.substring(1, location.pathname.indexOf('/', 2));

    this.loadingSpinnerService.show();
    this.assessmentService
      .getAssessment(id, 'no-cache')
      .pipe(
        mergeMap(data => {
          return this.assessmentService.updateAssessment(
            data.id,
            data.type,
            JSON.parse(data.body),
            [...this.otherAttachments, ...attachments],
            data.patient.id,
            data.doctor.id,
            data.locked
          );
        })
      )
      .subscribe(() => {
        this.loadingSpinnerService.hide();
        const dialogRef = SuccessModalComponent.open(
          this.dialog,
          'Success! Media has been submitted.' +
            ((this.returnToHomePage && '\nRedirecting....') || '')
        );

        if (this.returnToHomePage) {
          setTimeout(() => {
            dialogRef.close();
            this.router.navigateByUrl('/');
          }, 1500);
        }
      });
  }

  noSort = noSort;
}

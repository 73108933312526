import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { Flag, Notification, NotificationStatus, NotificationType } from '../../../../API';
import { NotificationContent, NotificationPriority } from '../inbox.model';
import { InboxService } from '../inbox.service';

@Component({
  selector: 'csi-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: Notification;
  @Input() notificationType: NotificationType;
  @Input() notificationSelected$: Subject<string>;

  public notificationStatus: NotificationStatus;

  public selected = false;
  public priority: NotificationPriority = 'low';
  public doublePriority: boolean;
  public Flag = Flag;
  constructor(
    private inboxService: InboxService,
    public questionnaireRequestService: QuestionnaireRequestService
  ) {}

  public notificationContent: NotificationContent;

  ngOnInit() {
    if (this.notification.content) {
      this.notificationContent = JSON.parse(this.notification.content);
    }

    this.notificationStatus = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
      this.notification.properties,
      this.inboxService.loggedInDoctor
    );

    this.notificationSelected$.subscribe(id => {
      if (this.notification.id === id) {
        this.selected = true;
        if (!this.notificationStatus.read) {
          this.inboxService.setNotificationReadTrue(this.notification);
          // this.updateNotificationReadStatus();
        }
      } else {
        if (this.selected && !this.notificationStatus.read) {
          this.notificationStatus.read = true;
          this.inboxService.decrementUnreadNotificationCount(this.notificationType);
        }
        this.selected = false;
      }
    });
    this.setPriority();
    this.setDoublePriority();
  }

  ngOnDestroy() {
    // if (this.notificationSelected$.closed === false || this.notificationSelected$.isStopped) {
    //   this.notificationSelected$.unsubscribe();
    // }
  }

  setPriority() {
    switch (this.notificationType) {
      case NotificationType.referral:
        const referralPriority = this.notificationContent.referralPriority;
        if (
          referralPriority &&
          (referralPriority === 'URGENT' || referralPriority === 'SEMI_URGENT')
        ) {
          this.priority = 'high';
        }
        break;
      case NotificationType.questionnaire:
        if (this.notificationContent.questionnaireScores) {
          const isAtRisk = this.questionnaireRequestService.isAtRisk(
            this.notificationContent.questionnaireScores
          );
          if (isAtRisk) {
            this.priority = 'high';
          }
        }
        break;
    }
  }

  setDoublePriority() {
    this.doublePriority = this.questionnaireRequestService.customSpeedThreshold(
      JSON.parse((this.notificationContent && this.notificationContent.questionnaireScores) || '{}')
        .SPEED
    );
  }

  onClick() {
    this.notificationSelected$.next(this.notification.id);
  }

  deleteNotification(event: Event) {
    event.stopPropagation();
    this.inboxService.deleteNotification(this.notification, this.notificationStatus.read);
  }

  flagNotification(event: Event) {
    event.stopPropagation();

    switch (this.notificationStatus.flag) {
      case Flag.none:
        this.notificationStatus.flag = Flag.important;
        break;
      case Flag.important:
        this.notificationStatus.flag = Flag.complete;
        break;
      case Flag.complete:
        this.notificationStatus.flag = Flag.important;
    }

    const notificationPropertiesInput = this.notification.properties.map(property => {
      return {
        doctorId: property.doctorId,
        status: {
          read: property.status.read,
          flag:
            this.inboxService.loggedInDoctor === property.doctorId
              ? this.notificationStatus.flag
              : property.status.flag
        }
      };
    });

    this.inboxService
      .updateNotification({
        id: this.notification.id,
        properties: notificationPropertiesInput
      })
      .subscribe(() => {});
  }
}

import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  FormFieldConfig,
  FormFieldTypeConfig,
  FormTemplateGroup,
  FormTemplateSchema,
  Trigger,
  TriggerGroup,
  ValueConfig
} from './form-template-model';

class FormFieldValueConfigFormGroup extends FormGroup {
  constructor(valueConfig: ValueConfig) {
    super({
      label: new FormControl(valueConfig.label),
      showSubField: new FormControl(valueConfig.showSubField),
      subFieldIdList: new FormArray(
        valueConfig.subFieldIdList.map(subFieldIdConfig => {
          return new FormGroup({
            id: new FormControl(subFieldIdConfig.id),
            required: new FormControl(subFieldIdConfig.required)
          });
        })
      )
    });
  }
}

class FormFieldTypeConfigFormGroup extends FormGroup {
  constructor(fieldTypeConfig: FormFieldTypeConfig) {
    super(
      Object.entries(fieldTypeConfig).reduce((acc, [key, value]) => {
        if (key !== 'valuesConfig') {
          acc[key] = new FormControl(value);
        } else {
          acc['valuesConfig'] = new FormGroup(
            Object.entries(value).reduce(
              (valueConfigAcc, [valueKey, valueConfig]: [string, ValueConfig]) => {
                valueConfigAcc[valueKey] = new FormFieldValueConfigFormGroup(valueConfig);
                return valueConfigAcc;
              },
              {}
            )
          );
        }
        return acc;
      }, {})
    );
  }
}

class TriggerFormGroup extends FormGroup {
  constructor(trigger: Trigger) {
    super({
      key: new FormControl(trigger.key),
      compareValue: new FormControl(trigger.compareValue),
      compare: new FormControl(trigger.compare)
    });
  }
}

class TriggerGroupFormGroup extends FormGroup {
  constructor(triggerGroup: TriggerGroup) {
    super({
      type: new FormControl(triggerGroup),
      triggers: new FormArray(triggerGroup.triggers.map(trigger => new TriggerFormGroup(trigger)))
    });
  }
}

export class FormTemplateFieldFormGroup extends FormGroup {
  constructor(templateField: FormFieldConfig) {
    super({
      label: new FormControl(templateField.label),
      typeConfig: new FormFieldTypeConfigFormGroup(templateField.typeConfig),
      required: new FormControl(templateField.required),
      triggerGroups: new FormArray(
        templateField.triggerGroups.map(triggerGroup => {
          return new TriggerGroupFormGroup(triggerGroup);
        })
      )
    });
  }
}

export class FormTemplateGroupFormGroup extends FormGroup {
  constructor(templateGroup: FormTemplateGroup) {
    super({
      title: new FormControl(templateGroup.title),
      nextLabel: new FormControl(templateGroup.nextLabel),
      formFields: new FormGroup(
        Object.entries(templateGroup.formFields).reduce((acc, [key, value]) => {
          acc[key] = new FormTemplateFieldFormGroup(value);
          return acc;
        }, {})
      )
    });
  }
}

export class FormTemplateFormGroup extends FormGroup {
  constructor(formTemplateSchema: FormTemplateSchema) {
    super({
      name: new FormControl(formTemplateSchema.name),
      id: new FormControl(formTemplateSchema.id),
      message: new FormControl(formTemplateSchema.message),
      abbreviation: new FormControl(formTemplateSchema.abbreviation),
      templateGroups: new FormGroup(
        Object.entries(formTemplateSchema.templateGroups).reduce((acc, [key, value]) => {
          acc[key] = new FormTemplateGroupFormGroup(value);
          return acc;
        }, {})
      ),
      supportingFields: new FormGroup(
        Object.entries(formTemplateSchema.supportingFields).reduce((acc, [key, value]) => {
          acc[key] = new FormTemplateFieldFormGroup(value);
          return acc;
        }, {})
      )
    });
  }
}

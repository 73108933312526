import { Injectable } from '@angular/core';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { AUTH_TYPE } from 'aws-appsync-auth-link';
import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync/lib/';
import { CredentialsOptions } from 'aws-sdk/lib/credentials';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { AssessmentBodyLink } from './apollo-links/assessment-body-link';
import { ClinicIdLink } from './apollo-links/clinic-id-link';

@Injectable({
  providedIn: 'root'
})
export class AppSyncService {
  private userpoolClient: AWSAppSyncClient<{}>;
  private iamClient: AWSAppSyncClient<{}>;

  constructor(
    private authService: AuthenticationService,
    private amplifyService: AmplifyService,
    private assessmentBodyLink: AssessmentBodyLink,
    private clinicIdLink: ClinicIdLink
  ) {
    this.userpoolClient = this.instantiateAppSyncClient();
    this.iamClient = this.instantiateAppSyncClient(true);
    this.setupCacheResetTriggers();
  }

  public hydrated(limitToPublicAPIs: boolean = false): Observable<AWSAppSyncClient<{}>> {
    return from((limitToPublicAPIs ? this.iamClient : this.userpoolClient).hydrated());
  }

  public dataIdFromObject(result: { __typename: string; id: string }): string | null {
    // TODO: Perhaps get this from the apollo client cache directly rather than assume the
    // default id generator is used
    return defaultDataIdFromObject(result);
  }

  private instantiateAppSyncClient(limitToPublicAPIs: boolean = false): AWSAppSyncClient<{}> {
    const appSyncLink = createAppSyncLink({
      url: environment.appSyncGraphQLEndpoint,
      region: environment.appSyncRegion,
      auth: limitToPublicAPIs
        ? {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => Auth.currentCredentials()
          }
        : {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: () => this.authService.getJwtToken()
          },
      complexObjectsCredentials: () =>
        this.authService.getCurrentCredentials() as Promise<CredentialsOptions>
    });

    const link = ApolloLink.from([this.assessmentBodyLink, this.clinicIdLink, appSyncLink]);

    // @ts-ignore: 2345
    return new AWSAppSyncClient({ disableOffline: true }, { link });
  }

  private setupCacheResetTriggers(): void {
    this.amplifyService.authStateChange$.subscribe(authState => {
      switch (authState.state) {
        case 'signedIn':
          // Uncomment once https://github.com/apollographql/apollo-client/issues/2919 is resolved
          // this.client.resetStore();
          break;
        case 'signedOut':
          this.userpoolClient.resetStore();
          break;
        default:
          break;
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { PreopReportBranchSchema } from './../pre-op-report.component';

@Component({
  selector: 'csi-pre-op-report-branch',
  templateUrl: './pre-op-report-branch.component.html',
  styleUrls: ['./pre-op-report-branch.component.css']
})
export class PreOpReportBranchComponent implements OnInit {
  @Input() schema: PreopReportBranchSchema;
  @Input() key: string;
  @Input() formGroup: FormGroup;
  @Input() signKeys: string[];

  public schemaKeys: string[];

  constructor(public schemaService: SchemaService) {}

  ngOnInit() {
    this.schemaKeys = Object.keys(this.schema);
  }
}

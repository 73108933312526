import { Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { Symptom } from '../symptom.model';
import { DryEyeFormGroup } from './../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';

export interface MultiKeySymptomControl {
  getAllPossibleSymptomControlKeys(): string[];
}

export abstract class AbstractSymptomControlComponent
  implements OnChanges, OnDestroy, MultiKeySymptomControl {
  @Input() symptom: Symptom;
  @Input() formKey: string;
  @Input() formGroup: FormGroup;

  protected symptomValidatorsService: SymptomValidatorsService;

  public static getAllPossibleSymptomControlKeysForFormKey(formKey: string): string[] {
    return [formKey];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.formControlName && this.formGroup) || (changes.formGroup && this.formKey)) {
      if (
        (changes.formControlName && changes.formControlName.previousValue) ||
        (changes.formGroup && changes.formGroup.previousValue)
      ) {
        const attachedFormGroup =
          changes.formGroup && changes.formGroup.previousValue
            ? changes.formGroup.previousValue
            : this.formGroup;

        attachedFormGroup.removeControl(this.formKey);
      }

      if (this.formKey && this.formGroup) {
        this.formGroup.addControl(this.formKey, new FormControl());

        // Skip validator override if a formcontrol from pre op is sent
        if (this.formGroup instanceof DryEyeFormGroup) {
          setTimeout(() => this.setValidators());
        }
      }
    }
  }

  ngOnDestroy() {
    // if (this.formGroup && this.formKey) {
    //   this.formGroup.removeControl(this.formKey);
    // }
  }

  get value() {
    return this.formGroup.get(this.formKey) ? this.formGroup.get(this.formKey).value : null;
  }

  public getAllPossibleSymptomControlKeys(): string[] {
    return [this.formKey];
  }

  get isDisabled(): boolean {
    return (
      !this.formGroup.get(this.formKey) || this.formGroup.get(this.formKey).status === 'DISABLED'
    );
  }

  private setValidators() {
    const formControl = this.formGroup.get(this.formKey);

    if (this.symptomValidatorsService && formControl) {
      const validators = [
        this.symptomValidatorsService.requiredValidatorFactory(this.symptom, this.formKey),
        this.symptomValidatorsService.rangeValidatorFactory(this.symptom)
      ].filter(val => !!val);

      formControl.setValidators(validators);
      formControl.updateValueAndValidity();
    }
  }
}

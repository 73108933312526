import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import invert from 'lodash-es/invert';
import { normalizeToSymptomKey } from '../../form.model';
import { KeyValueMap } from '../services/schema/schema.model';
import { SchemaService } from '../services/schema/schema.service';
import { Symptom, SymptomMethod, SymptomTypes } from '../symptom.model';
import { MethodSymptom } from './../symptom.model';

@Pipe({
  name: 'symptomMethods'
})

// Returns list of method names and their display values for a symptom
// or if passed method, then just the display name for that method
export class SymptomMethodsPipe implements PipeTransform {
  constructor(private schemaService: SchemaService, private translocoService: TranslocoService) {}

  transform(key: string, method?: string, lang: string = 'en'): string | KeyValueMap {
    key = normalizeToSymptomKey(key);
    const symptom: Symptom = this.schemaService.symptomMap[key];
    if (symptom && symptom.type === SymptomTypes.Method) {
      const methodSymptom = <MethodSymptom>symptom;

      const translationObject = this.translocoService.getTranslation(lang);

      const customSignsKey = 'signs.' + key + '.methodNames';
      const defaultSignsKey = 'signs.defaultMethodNames';

      const translationKeyPrefix = methodSymptom.methodNames ? customSignsKey : defaultSignsKey;

      const translationQualitativeKey = translationKeyPrefix + '.' + SymptomMethod.Qualitative;
      const translationQuantitativeKey = translationKeyPrefix + '.' + SymptomMethod.Quantitative;

      const translationKey =
        (methodSymptom.methodNames ? customSignsKey : defaultSignsKey) +
        (method ? '.' + method : '');

      if (method && translationObject[translationKey]) {
        return translationObject[translationKey];
      }

      if (
        !method &&
        translationObject[translationQualitativeKey] &&
        translationObject[translationQuantitativeKey]
      ) {
        return {
          [SymptomMethod.Qualitative]: translationObject[translationQualitativeKey],
          [SymptomMethod.Quantitative]: translationObject[translationQualitativeKey]
        };
      }

      // Default Value
      const methodNames = methodSymptom.methodNames
        ? methodSymptom.methodNames
        : invert(SymptomMethod);
      const methodName = method ? methodNames[method] : methodNames;

      return methodName;
    }
    return null;
  }
}

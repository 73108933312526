import { Pipe, PipeTransform } from '@angular/core';
import { SchemaService } from '../services/schema/schema.service';

@Pipe({
  name: 'radioConditionKey'
})
export class RadioConditionKeyPipe implements PipeTransform {
  constructor(private schemaService: SchemaService) {}

  transform(key: string, value?: string): string {
    if (key in this.schemaService.radioConditionMap) {
      return value
        ? this.schemaService.radioConditionMap[key].values[value]
        : this.schemaService.radioConditionMap[key].name;
    }

    return null;
  }
}

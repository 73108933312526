import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LandingPageComponent } from './landing-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [LandingPageComponent, PrivacyPolicyComponent],
  imports: [SharedModule]
})
export class LandingPageModule {}

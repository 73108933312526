import { Injectable } from '@angular/core';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Logger } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { saveAs } from 'file-saver';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { DummyS3ObjectQueryQuery } from '../../../API';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { ContentType, ImageToDataUriPipe } from '../shared-pipes/image-to-data-uri.pipe';
import { S3ObjectInput } from './../../../API';
import { Attachment } from './attachments.component';

export type S3Object = DummyS3ObjectQueryQuery['dummyS3ObjectQuery'];
export type UrlWithContentType = {
  url: string | SafeResourceUrl | SafeUrl;
  contentType: ContentType;
  fileName?: string;
};

@Injectable({
  providedIn: 'root'
})
export class S3AttachmentService {
  private logger: Logger = new Logger('S3AttachmentService');
  constructor(
    private amplifyService: AmplifyService,
    private loadingSpinnerService: LoadingSpinnerService,
    private imageToDataUriPipe: ImageToDataUriPipe
  ) {}

  public getPresignedUrl(s3Object: S3Object): Promise<string> {
    // e.g. protected/us-east-1:bbe8a7e4-f083-469b-bd59-a44e0c8421f5/a5f4446d-5d79-44ce-8ca1-d87c671d2ff6.png
    const options = this.getS3DownloadOptions(s3Object.key);

    // See https://github.com/aws-amplify/amplify-js/issues/1912 for why I'm doing
    // typecast
    return this.amplifyService.storage().get(options.key, {
      bucket: s3Object.bucket,
      region: s3Object.region,
      ...options
    }) as Promise<string>;
  }

  public getObject(s3Object: S3Object, download = true): Promise<any> {
    // e.g. protected/us-east-1:bbe8a7e4-f083-469b-bd59-a44e0c8421f5/a5f4446d-5d79-44ce-8ca1-d87c671d2ff6.png
    const options = this.getS3DownloadOptions(s3Object.key);
    return this.amplifyService.storage().get(options.key, {
      bucket: s3Object.bucket,
      region: s3Object.region,
      download: download,
      ...options
    });
  }

  // Should probably use ContentDisposition instead of FileSaver. But that's not supported out of the box.
  // See https://github.com/aws-amplify/amplify-js/issues/2052 and https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/287
  public download(s3Object: S3Object) {
    this.loadingSpinnerService.show();
    this.getObject(s3Object).then(value => {
      this.loadingSpinnerService.hide();
      saveAs(new Blob([value.Body], { type: value.ContentType }), this.getFileName(s3Object));
    });
  }

  public s3ObjectToUri(s3Object: S3Object): Observable<UrlWithContentType> {
    this.logger.debug('getS3ImageDataUri');

    return s3Object
      ? from(this.getObject(s3Object)).pipe(
          catchError(e => {
            console.log(e);
            return of(null);
          }),
          switchMap(imageData =>
            imageData
              ? this.imageToDataUriPipe.transform(imageData.Body, imageData.ContentType, true).pipe(
                  map(url => {
                    return { url: url, contentType: imageData.ContentType };
                  })
                )
              : of(null)
          )
        )
      : of(null);
  }

  private getS3DownloadOptions(key: string) {
    const match: RegExpMatchArray = /([^\/]+)\/([^\/]+)\/(.*)/.exec(key);

    if (match.length === 4) {
      return {
        level: match[1],
        identityId: match[2],
        key: match[3],
        expires: 5 * 60
      };
    } else {
      throw new Error('S3Object key does not match expected pattern');
    }
  }

  public getFileName(s3Object: S3Object): string {
    if (s3Object) {
      return s3Object.fileName;
    } else {
      const match: RegExpMatchArray = /.*\/(.*)/.exec(s3Object.key);
      return match.length === 2 ? match[1] : null;
    }
  }

  public convertToS3ObjectInput(identityId: string, attachment: Attachment) {
    if (attachment instanceof File) {
      const file: File = attachment as File;
      const extension: string = /(?:\.([^.]+))?$/.exec(file.name)[0];

      const s3ObjectInput = {
        bucket: environment.userUploads.bucket,
        region: environment.userUploads.region,
        // @ts-ignore - appsync client accepts localUri as type of "File" but GraphQL does not have "File" scalar
        localUri: file as string,
        mimeType: file.type,
        fileName: file.name,
        key: 'protected/' + identityId + '/' + uuid() + extension
      };

      if (attachment['metadata']) {
        s3ObjectInput['metadata'] = attachment['metadata'];
      }

      return s3ObjectInput;
    } else {
      const s3object: S3Object = attachment as S3Object;
      delete s3object.__typename;
      return s3object as S3ObjectInput;
    }
  }
}

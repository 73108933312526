import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuestionnaireType } from 'src/API';
import { PatientService } from 'src/app/core/api/patient.service';
import { QuestionnaireScorePipe } from 'src/app/shared/shared-pipes/questionnaire-score.pipe';
import { QuestionnaireStatusPipe } from 'src/app/shared/shared-pipes/questionnaire-status.pipe';
import {
  QuestionnaireRequest,
  QuestionnaireRequestService
} from './../../../core/api/questionnaire-request.service';
import { QuestionnaireResponseModalComponent } from './../../../shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';
import { QrCodeModalComponent } from './../../../shared/qr-code-modal/qr-code-modal.component';
import { LinkQuestionnaireToAssessmentModalComponent } from './../../link-questionnaire-to-assessment-modal/link-questionnaire-to-assessment-modal.component';

@Component({
  selector: 'csi-questionnaires-table',
  templateUrl: './questionnaires-table.component.html',
  styleUrls: ['./questionnaires-table.component.css'],
  providers: [QuestionnaireStatusPipe, QuestionnaireScorePipe]
})
export class QuestionnairesTableComponent implements OnInit {
  public QuestionnaireType = QuestionnaireType;

  public questionnaireTypeToFriendlyNameMap: { [key in QuestionnaireType]?: string } = {
    [QuestionnaireType.DEQ]: 'DEQ',
    [QuestionnaireType.OSDI]: 'OSDI',
    [QuestionnaireType.SPEED]: 'SPEED',
    [QuestionnaireType.SPEEDII]: 'SPEED II',
    [QuestionnaireType.OSDIAndSPEED]: 'OSDI and SPEED',
    [QuestionnaireType.DERFS]: 'DERFS',
    [QuestionnaireType.CDERFS]: 'CDERFS'
  };

  public readonly questionnaireHeaderMap = {
    createdAt: 'Sent On',
    type: 'Type',
    completedAt: 'Completed At',
    status: 'Status',
    score: 'Score',
    assessment: 'Linked Assessment Date'
  };

  public readonly questionnaireHeaderKeysWithoutActionKey = Object.keys(
    this.questionnaireHeaderMap
  );
  public readonly questionnaireHeaderKeysWithActionsKey = [
    ...this.questionnaireHeaderKeysWithoutActionKey,
    'actions'
  ];

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public questionnaireRequestService: QuestionnaireRequestService,
    public patientService: PatientService,
    private questionnaireStatusPipe: QuestionnaireStatusPipe,
    private questionnaireScorePipe: QuestionnaireScorePipe
  ) {}

  ngOnInit() {
    this.patientService.questionnaires = this.patientService.questionnaires.map(questionnaire => ({
      ...questionnaire,
      config:
        (questionnaire.config as any) instanceof Object
          ? questionnaire.config
          : JSON.parse(questionnaire.config)
    }));
  }

  showQuestionnaireResponse(questionnaire: QuestionnaireRequest) {
    QuestionnaireResponseModalComponent.open(this.dialog, questionnaire);
  }

  openQRCode(questionnaire: QuestionnaireRequest) {
    const path = `${location.origin}/questionnaires/${questionnaire.id}`;
    const questionnaireType: string = this.questionnaireRequestService
      .questionnaireTypeToFriendlyNameMap[questionnaire.type];

    this.dialog.open(QrCodeModalComponent, {
      data: {
        qrCodePath: path,
        modalTitle: `Scan the QR Code to open the ${questionnaireType} questionnaire`
      }
    });
  }

  openLinkQuestionnaireToAssessmentModal(questionnaire: QuestionnaireRequest) {
    this.dialog.open(LinkQuestionnaireToAssessmentModalComponent, {
      data: {
        questionnaire
      },
      width: '80vw'
    });
  }

  announceSortChange(sort: Sort) {
    this.patientService.questionnaires = [
      ...this.patientService.questionnaires.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'createdAt':
            return this.compare(
              new Date(a.createdAt).getTime(),
              new Date(b.createdAt).getTime(),
              isAsc
            );
          case 'completedAt':
            return this.compare(
              new Date(a.completedAt).getTime(),
              new Date(b.completedAt).getTime(),
              isAsc
            );
          case 'assessment':
            return this.compare(
              a.assessment && new Date(a.assessment.createdAt).getTime(),
              b.assessment && new Date(b.assessment.createdAt).getTime(),
              isAsc
            );
          case 'status':
            const questionnaireStatusA = this.questionnaireStatusPipe.transform(a);
            const questionnaireStatusB = this.questionnaireStatusPipe.transform(b);
            return this.compare(questionnaireStatusA, questionnaireStatusB, isAsc);
          case 'score':
            const questionnaireScoreA = this.questionnaireScorePipe.transform(a);
            const questionnaireScoreB = this.questionnaireScorePipe.transform(b);
            return this.compare(
              questionnaireScoreA === 'N/A'
                ? isAsc
                  ? 3
                  : 0
                : questionnaireScoreA.includes('Not At Risk')
                ? 1
                : 2,
              questionnaireScoreB === 'N/A'
                ? isAsc
                  ? 3
                  : 0
                : questionnaireScoreB.includes('Not At Risk')
                ? 1
                : 2,
              isAsc
            );
        }
      })
    ];
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

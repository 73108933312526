import { Injectable } from '@angular/core';
import { clamp } from 'lodash-es';
import invert from 'lodash-es/invert';
import { ErrorHandlerService } from '../../../core/api/error-handler.service';
import {
  BuckitizedThermalScaleConfig,
  LinearThermalScaleConfig,
  ThermalScaleConfig
} from './thermal-scale.model';

export enum ThermalScaleType {
  Discrete,
  Numeric
}

export enum ThermalScaleColor {
  green = 'green',
  yellow = 'yellow',
  yelloworange = 'yelloworange',
  orangeyellow = 'orangeyellow',
  orange = 'orange',
  orangered = 'orangered',
  redorange = 'redorange',
  red = 'red'
}

export const minThermalScaleColor = ThermalScaleColor.green;
export const maxThermalScaleColor = ThermalScaleColor.red;

@Injectable({
  providedIn: 'root'
})
export class ThermalScaleService {
  // Values within each color should fall correctly into normalizedValueToColor.
  // Ex: 'yellow' is a value from 0.24 to 0.333 in discreteColorRangeMap, and 'yellow' is a value from 0.167 to 0.333 in normalizedValue,
  // therefore any value from 0.24 to 0.333 should map correctly to normalizedValue
  private readonly discreteColorRangeMap = {
    green: [0, 0.0],
    yellow: [0.24, 0.333],
    yelloworange: [0.333, 0.5],
    orangeyellow: [0.333, 0.5],
    orange: [0.5, 0.667],
    orangered: [0.667, 0.833],
    redorange: [0.667, 0.833],
    red: [1.0, 1.0]
  };
  private readonly evenColorRangeMap = {
    green: [0, 0.167],
    yellow: [0.21, 0.333],
    yelloworange: [0.333, 0.5],
    orangeyellow: [0.333, 0.5],
    orange: [0.5, 0.667],
    orangered: [0.667, 0.833],
    redorange: [0.667, 0.833],
    red: [0.833, 1.0]
  };
  public readonly normalizedValueToColor = [
    { color: ThermalScaleColor.green, value: 0.167 },
    { color: ThermalScaleColor.yellow, value: 0.333 },
    { color: ThermalScaleColor.yelloworange, value: 0.5 },
    { color: ThermalScaleColor.orange, value: 0.667 },
    { color: ThermalScaleColor.orangered, value: 0.833 },
    { color: ThermalScaleColor.red, value: 1 }
  ];

  private readonly operatorRegex = /^(<|>|≤|≥|<=|>=|=?)([-+]?[0-9]*\.?[0-9]+)$/;
  private readonly rangeRegex = /^([-+]?[0-9]*\.?[0-9]+)-([-+]?[0-9]*\.?[0-9]+)$/;
  private readonly labels = {
    green: 'Normal',
    yellow: 'Mild',
    yelloworange: 'Mild',
    orangeyellow: 'Mild',
    orange: 'Moderate',
    orangered: 'Moderate',
    redorange: 'Moderate',
    red: 'Severe'
  };

  constructor(private errorHandlerService: ErrorHandlerService) {}

  public getLabel(internalValue: number | string, config: ThermalScaleConfig): string {
    if (config) {
      const colorOfValue: string = this.getColor(internalValue, config);
      return !!colorOfValue ? this.getLabelForColor(colorOfValue, config) : null;
    } else {
      return null;
    }
  }

  public getLabelForColor(color: string, config: ThermalScaleConfig): string {
    if (config.labels && !!config.labels[color]) {
      return config.labels[color];
    } else {
      return this.labels[color.replace(/\s/g, '')];
    }
  }

  /**
   * Every color is associated with a range (Red is between 0.83 and 1 for example).
   * This function returns the normalized value associated with the normalized color mapping
   * @param internalValue The value as input by the user
   * @param config May contain a map linking the unmodified value with a color
   */
  public getColorValue(internalValue: number | string, config: ThermalScaleConfig): number {
    if (config) {
      const asLinearConfig = config as LinearThermalScaleConfig;
      const asBucketizedConfig = config as BuckitizedThermalScaleConfig;
      if (this.getType(internalValue) === ThermalScaleType.Numeric && asLinearConfig.linear) {
        const clampedVal = this.clampForCondition(internalValue as number, asLinearConfig.linear);
        return this.normalizeValue(clampedVal, asLinearConfig.linear);
      } else if (this.getType(internalValue) === ThermalScaleType.Discrete) {
        const inverseMap = invert(asBucketizedConfig);
        const color = inverseMap[internalValue as string];

        const colorValue = this.colorAndOffsetToNormalizedValue(color, 0.5, true);
        return colorValue;
      } else {
        const [color, offset] = this.calculateColorAndOffset(
          internalValue as number,
          asBucketizedConfig
        );
        const normalizedValue = this.colorAndOffsetToNormalizedValue(
          color,
          offset,
          offset === null
        );
        return normalizedValue;
      }
    }
  }

  public normalizeValue(internalValue: number, condition: string): number {
    if (this.rangeRegex.test(condition)) {
      const matches = condition.match(this.rangeRegex);

      const testValue1 = parseFloat(matches[1]);
      const testValue2 = parseFloat(matches[2]);

      const min = Math.min(testValue1, testValue2);
      const max = Math.max(testValue1, testValue2);

      if (min <= internalValue && internalValue <= max) {
        let normalized = (internalValue - min) / (max - min);
        if (testValue1 > testValue2) {
          normalized = 1 - normalized;
        }
        return Number.parseFloat(normalized.toPrecision(6));
      } else {
        return null;
      }
    } else {
      console.error('Cannot normalize value with condition: ' + condition);
      return -1;
    }
  }

  /**
   * Returns 0 <= position <= 1 where [0,1] is the range of a color scale, or null if either
   * color is not defined or given color param is not found in the rangeMap
   * @param color The color that the offset is supposed to lie in
   * @param offset An offset with a range of [0,1]. The color param has a range, and offset determines where in the range a value falls under
   * @param discrete Whether to use discreteColorRangeMap or evenColorRangeMap
   */
  public colorAndOffsetToNormalizedValue(color: string, offset: number, discrete: boolean): number {
    if (!color) {
      return null;
    }
    offset = offset === null ? 0.5 : offset;
    const colorRangeMap = discrete ? this.discreteColorRangeMap : this.evenColorRangeMap;
    const rangeForColor = colorRangeMap[color.replace(/\s/g, '')];

    if (rangeForColor) {
      return rangeForColor[0] * (1.0 - offset) + rangeForColor[1] * offset;
    } else {
      return null;
    }
  }

  /**
   * Takes a score and returns [score, color, satisfiesInequality],
   * where color is the color that the score corresponds to based off bucketizedConfig,
   * 0 <= score <= 1 is the offset of the color range which is null if score satisfied some inequality `x (> | ≥ | < | ≤) value`,
   * @param bucketizedConfig Data which contains color mappings for ranges of possible scores
   * @param numericValue The score
   */
  public calculateColorAndOffset(
    numericValue: number,
    bucketizedConfig: BuckitizedThermalScaleConfig
  ): [string, number | null] {
    let colorOffsetFinal: number | null = null;
    let colorOfValue: string;
    colorOfValue = Object.keys(bucketizedConfig).find(color => {
      const colorKey = color.replace(/\s/g, '');
      if (!!this.discreteColorRangeMap[colorKey]) {
        const condition = bucketizedConfig[color].replace(/\s/g, '');

        if (this.operatorRegex.test(condition)) {
          const matches = condition.match(this.operatorRegex);

          const operator = matches[1];
          const testValue = parseFloat(matches[2]);

          if (this.testOperator(numericValue, operator, testValue)) {
            return true;
          }
        } else if (this.rangeRegex.test(condition)) {
          const potentialOffset = this.normalizeValue(numericValue, condition);

          if (potentialOffset !== null) {
            colorOffsetFinal = potentialOffset;
            return true;
          }
        } else {
          this.errorHandlerService.handleError(
            `Thermal scale config condition not understood '${condition}'`
          );
        }
      }
    });

    if (!colorOfValue) {
      console.warn(
        `No color was found corresponding to value ${numericValue} with config ${JSON.stringify(
          bucketizedConfig
        )}`
      );
    }

    return [colorOfValue, colorOffsetFinal];
  }

  public isValueWithinConfigRange(
    numericValue: number,
    config: BuckitizedThermalScaleConfig | LinearThermalScaleConfig
  ): boolean {
    // TODO Create a method that uses max and min values from thermalScale, so we can display
    // what the ranges are in the UI
    const asBucketizedConfig = config as BuckitizedThermalScaleConfig;
    const asLinearConfig = config as LinearThermalScaleConfig;
    if (asLinearConfig.linear) {
      return true;
    } else {
      const [color, range] = this.calculateColorAndOffset(numericValue, asBucketizedConfig);
      return range != null || color != null;
    }
  }

  public clampForCondition(value: number, condition: string): number {
    if (this.operatorRegex.test(condition)) {
      console.warn('Cannot clamp value with an inequality sign: ' + condition);
      return value;
    }

    const [bound1String, bound2String] = condition.match(this.rangeRegex).slice(1, 3);
    const bound1 = parseFloat(bound1String);
    const bound2 = parseFloat(bound2String);
    const min = Math.min(bound1, bound2);
    const max = Math.max(bound1, bound2);

    return clamp(value, min, max);
  }

  public isNumericThermalScale(thermalScale: any): boolean {
    return Object.keys(thermalScale).every(key => {
      const colorKey = key.replace(/\s/g, '');

      return (
        !this.discreteColorRangeMap[colorKey] ||
        (thermalScale[key] as string).search(this.operatorRegex) !== -1 ||
        (thermalScale[key] as string).search(this.rangeRegex) !== -1
      );
    });
  }

  private getColor(internalValue: number | string, config: ThermalScaleConfig): string {
    if (config) {
      const asLinearConfig = config as LinearThermalScaleConfig;
      const asBucketizedConfig = config as BuckitizedThermalScaleConfig;
      if (this.getType(internalValue) === ThermalScaleType.Numeric && asLinearConfig.linear) {
        const clampedVal = this.clampForCondition(internalValue as number, asLinearConfig.linear);
        const normalizedValue = this.normalizeValue(clampedVal, asLinearConfig.linear);
        return this.normalizedValueToColor.find(({ value, color }) => normalizedValue <= value)
          .color; // Uses normalizedValueToColor object
      } else if (this.getType(internalValue) === ThermalScaleType.Discrete) {
        const inverseMap = invert(asBucketizedConfig);
        return inverseMap[internalValue as string]; // Uses the config as its color map, has discrete values
      } else {
        return this.getColorOfBucketizedConfig(internalValue as number, asBucketizedConfig); // Uses the config as its color map, has ranges
      }
    } else {
      return null;
    }
  }

  private getType(value: string | number): ThermalScaleType {
    return typeof value === 'number' ? ThermalScaleType.Numeric : ThermalScaleType.Discrete;
  }

  private getColorOfBucketizedConfig(
    value: number,
    bucketizedConfig: BuckitizedThermalScaleConfig
  ): string {
    return Object.keys(bucketizedConfig).find(key =>
      this.isWithinRange(value, bucketizedConfig[key])
    );
  }

  private isWithinRange(value: number, condition: string): boolean {
    if (this.rangeRegex.test(condition)) {
      const matches = condition.match(this.rangeRegex);

      const testValue1 = parseFloat(matches[1]);
      const testValue2 = parseFloat(matches[2]);

      const min = Math.min(testValue1, testValue2);
      const max = Math.max(testValue1, testValue2);

      return min <= value && value <= max;
    } else if (this.operatorRegex.test(condition)) {
      const matches = condition.match(this.operatorRegex);

      const operator = matches[1];
      const testValue = parseFloat(matches[2]);

      return this.testOperator(value, operator, testValue);
    } else {
      console.error('Cannot understand condition: ' + condition);
      return null;
    }
  }

  private testOperator(value: number, operator: string, testValue: number): boolean {
    switch (operator) {
      case '<':
        return value < testValue;

      case '>':
        return value > testValue;

      case '≤':
      case '<=':
        return value <= testValue;

      case '≥':
      case '>=':
        return value >= testValue;

      default:
        return value === testValue;
    }
  }
}

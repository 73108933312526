import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthenticatorModalComponent,
  AuthenticatorMode
} from './authenticator-modal/authenticator-modal.component';
import { AuthenticatorGuard } from './guards/authenticator.guard';

const authenticationRoutes: Routes = [
  {
    path: 'login',
    component: AuthenticatorModalComponent,
    data: { authenticatorMode: AuthenticatorMode.Login },
    canActivate: [AuthenticatorGuard]
  },
  {
    path: 'signup',
    component: AuthenticatorModalComponent,
    data: { authenticatorMode: AuthenticatorMode.Signup },
    canActivate: [AuthenticatorGuard]
  },
  {
    path: 'verifySignUp',
    component: AuthenticatorModalComponent,
    data: { authenticatorMode: AuthenticatorMode.VerifyUser },
    canActivate: [AuthenticatorGuard]
  },
  {
    path: 'resetPassword',
    component: AuthenticatorModalComponent,
    data: { authenticatorMode: AuthenticatorMode.ResetPassword },
    canActivate: [AuthenticatorGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(authenticationRoutes)]
})
export class AuthenticationRoutingModule {}

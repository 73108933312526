import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import cleanDeep from 'clean-deep';
import gql from 'graphql-tag';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { createReferral, updateReferral } from 'src/graphql/mutations';
import {
  CreateReferralInput,
  Referral,
  UpdateReferralInput,
  UpdateReferralMutation
} from './../../../API';
import { AssessmentService } from './../../core/api/assessment.service';
import { PatientService } from './../../core/api/patient.service';
import { StaffService } from './../../core/api/staff.service';
import { AppSyncService } from './../../core/appsync.service';
import { ReferralService } from './../referral.service';
import { ReferralFormGroup } from './referral-form.model';

@Injectable({
  providedIn: 'root'
})
export class ReferralFormService {
  constructor(
    private assessmentService: AssessmentService,
    private staffService: StaffService,
    private referralService: ReferralService,
    private patientService: PatientService,
    private appSyncService: AppSyncService
  ) {}

  createReferralFromReferralFormGroup(referralFormGroup: ReferralFormGroup) {
    const body = {
      ...referralFormGroup.value,
      diseaseArea: referralFormGroup.controls.diseaseArea.value
    };
    if (body.referralLetters) {
      delete body.referralLetters;
    }
    if (body.assessment && body.assessment.attachments) {
      delete body.assessment.attachments;
    }
    delete body.priority;

    const input: CreateReferralInput = {
      referringClinicId: this.staffService.staff.clinic.id,
      referralCenterClinicId: this.referralService.linkedReferralCenter
        ? this.referralService.linkedReferralCenter.referralCenterClinicId
        : this.referralService.referralCenter.id,
      referralReferralCenterId: this.referralService.linkedReferralCenter
        ? this.referralService.linkedReferralCenter.id
        : this.referralService.referralCenter.id,
      referralPatientId: this.patientService.patient.id,
      body: JSON.stringify(body),
      priority: referralFormGroup.controls.priority.value,
      status: referralFormGroup.controls.status.value,
      parentReferralId: referralFormGroup.controls.parentReferralId.value,
      type: referralFormGroup.controls.type.value
    };

    referralFormGroup.disable();
    return (referralFormGroup.getAttachmentControl() &&
    referralFormGroup.getAttachmentControl().value.length > 0 &&
    referralFormGroup.getAttachmentControl().value
      ? this.assessmentService.prepAttachmentsForUpload(
          referralFormGroup.getAttachmentControl().value
        )
      : of(null)
    ).pipe(
      switchMap(attachments => {
        if (attachments) input.attachments = attachments;
        return this.appSyncService.hydrated();
      }),
      switchMap(client => {
        return client.mutate({
          mutation: gql(createReferral),
          variables: { input: cleanDeep(input) }
        });
      })
    );
  }

  public createReferralFromInput(input: CreateReferralInput): Observable<Referral> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(createReferral),
          variables: { input: cleanDeep(input) }      
        });
      })
    );
  }

  updateReferral(input: UpdateReferralInput): Observable<Referral> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(updateReferral),
          variables: { input }
        });
      }),
      map((result: ApolloQueryResult<UpdateReferralMutation>) => result.data.updateReferral)
    );
  }

  setPatientInformation(referralFormGroup: ReferralFormGroup) {
    const {
      firstName,
      lastName,
      address,
      gender,
      dateOfBirth,
      email,
      phone,
      healthCard
    } = this.patientService.patient;
    referralFormGroup.controls.patient.setValue({
      firstName,
      lastName,
      address,
      gender,
      dateOfBirth,
      email,
      phone,
      healthCard
    });
  }
  setClinicInformation(referralFormGroup: ReferralFormGroup) {
    const {
      id,
      name,
      address,
      province,
      country,
      city,
      postalCode,
      phone,
      fax
    } = this.staffService.staff.clinic;

    referralFormGroup.controls.clinic.setValue({
      id,
      name,
      address,
      province,
      country,
      city,
      postalCode,
      phone,
      fax
    });
  }

  setDoctorInformation(
    referralFormGroup: ReferralFormGroup,
    { firstName, lastName, id, practitionerId }: Doctor
  ) {
    referralFormGroup.controls.doctor.setValue({ firstName, lastName, id, practitionerId });
  }
}

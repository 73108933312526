import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'csi-mat-form-field-group-input',
  templateUrl: './mat-form-field-group-input.component.html',
  styleUrls: ['./mat-form-field-group-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: MatFormFieldGroupInputComponent }]
})
export class MatFormFieldGroupInputComponent<T = any>
  implements MatFormFieldControl<T>, OnChanges, OnDestroy {
  private static nextId = 0;

  public readonly stateChanges = new Subject<void>();
  public readonly placeholder = '';
  public readonly ngControl = null;
  public readonly shouldLabelFloat = false;

  @Input() control: AbstractControl = new FormControl();

  public focused = false;

  @HostBinding() id = `csi-mat-form-field-group-${MatFormFieldGroupInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  private statusChangesSubscription: Subscription;

  constructor(private focusMonitor: FocusMonitor, private hostElementRef: ElementRef) {
    this.focusMonitor.monitor(this.hostElementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.controls && this.control) {
      if (this.statusChangesSubscription) {
        this.statusChangesSubscription.unsubscribe();
      }

      this.statusChangesSubscription = this.control.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => this.stateChanges.next());
    }
  }

  get value(): T {
    return this.control.value;
  }

  get disabled(): boolean {
    return this.control.disabled || this.allFieldsDisabled(this.control);
  }

  get required(): boolean {
    return this.hasRequiredField(this.control);
  }

  get empty(): boolean {
    return !this.control.value || this.allFieldsEmpty(this.control);
  }

  get errorState(): boolean {
    return this.control.invalid && !this.focused;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.hostElementRef.nativeElement);
  }

  onContainerClick(event: MouseEvent) {}

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  private hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      try {
        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      } catch (e) {
        // Do nothing
      }
    }

    if (abstractControl instanceof FormArray || abstractControl instanceof FormGroup) {
      return Object.values(abstractControl.controls).some(control =>
        this.hasRequiredField(control)
      );
    }

    return false;
  }

  private allFieldsDisabled(abstractControl: AbstractControl): boolean {
    if (abstractControl instanceof FormArray || abstractControl instanceof FormGroup) {
      return Object.values(abstractControl.controls).every(control =>
        this.allFieldsDisabled(control)
      );
    } else {
      return abstractControl.disabled;
    }
  }

  private allFieldsEmpty(abstractControl: AbstractControl): boolean {
    if (abstractControl instanceof FormArray || abstractControl instanceof FormGroup) {
      return Object.values(abstractControl.controls).every(control => this.allFieldsEmpty(control));
    } else {
      return !abstractControl.value;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormFieldTypeConfig, FormInputTypeEnum } from '../symptoms/modals/form-fields/form-field.models';

@Component({
  selector: 'csi-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})
export class FormFieldComponent implements OnInit {
  @Input() control!: FormControl;
  @Input() typeConfig!: FormFieldTypeConfig;
  @Input() label!: string;
  formGroup!: FormGroup;

  public formTypeMap: { [keys in FormInputTypeEnum]?: string } = {
    [FormInputTypeEnum.input]: 'input',
    [FormInputTypeEnum.email]: 'email',
    [FormInputTypeEnum.radio]: 'radio',
    [FormInputTypeEnum.dropdown]: 'dropdown',
    [FormInputTypeEnum.phone]: 'phone',

  }

  constructor() { }

  ngOnInit() {

  }

}

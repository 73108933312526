import { Injectable } from '@angular/core';
import { Operator, RiskFactorDisplay, riskLevelToNumberMap } from './risk-factors.model';

@Injectable({
  providedIn: 'root'
})
export class RiskFactorsService {
  constructor() {}

  public compareValues(patientData, conditionData, operator: Operator): boolean {
    // Can't do !patientData as patientData can be 0
    if (patientData === null || patientData === undefined) {
      return false;
    }
    switch (operator) {
      case '>': {
        return patientData > conditionData;
      }
      case '>=': {
        return patientData >= conditionData;
      }
      case '<': {
        return patientData < conditionData;
      }
      case '<=': {
        return patientData <= conditionData;
      }
      case '=': {
        return patientData === conditionData;
      }
      case '!=': {
        return patientData !== conditionData;
      }
      case '!empty': {
        return patientData && patientData !== '';
      }
      case 'includes': {
        return patientData.includes(conditionData);
      }
    }
  }

  compareRiskFactorDisplay(
    riskFactorDisplay: RiskFactorDisplay,
    otherRiskFactorDisplay: RiskFactorDisplay
  ) {
    return (
      riskLevelToNumberMap[otherRiskFactorDisplay.riskLevel] -
      riskLevelToNumberMap[riskFactorDisplay.riskLevel]
    );
  }
}

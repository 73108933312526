import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import { Logger } from 'aws-amplify';
import { Observable } from 'rxjs';
import {
  CreateQuestionnaireRequestInput,
  MassClinicPatient,
  NameFormat,
  QuestionnaireType
} from 'src/API';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { QuestionnaireRequestUtils } from 'src/app/core/api/questionnaire-request-utils';
import {
  QuestionnaireRequest,
  QuestionnaireRequestService,
  SendQuestionnaireDialogData
} from 'src/app/core/api/questionnaire-request.service';
import { v4 as uuid } from 'uuid';
import { Patient } from '../../core/api/patient.service';
import {
  CheckboxAndInputComponent,
  CheckboxAndInputError
} from '../checkbox-and-input/checkbox-and-input.component';
import { UpdateMassClinicPatientInput } from './../../../API';
import { ClinicSetupService } from './../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';

export type SendQuestionnaireMatDialogRef = MatDialogRef<SendQuestionnaireDialogComponent>;

@Component({
  selector: 'csi-send-questionnaire-dialog',
  templateUrl: './send-questionnaire-dialog.component.html',
  styleUrls: ['./send-questionnaire-dialog.component.css']
})
export class SendQuestionnaireDialogComponent implements OnInit {
  readonly NameFormat = NameFormat;
  public questionnareTypes = Object.keys(QuestionnaireType);
  public formGroup: FormGroup;
  public questionnaireTypesFormGroup: FormGroup;
  public sendClicked = false;
  public sending = false;
  public atLeastOneCheckboxSelected = false;
  public questionnairesToBeSent = [];
  private questionnaireRequestsSent = [];
  public questionnaireTypeToFriendlyNameMap = {};

  public languageFormControl = new FormControl();

  readonly emailErrors: CheckboxAndInputError[] = [
    { errorType: 'email', displayMessage: 'Invalid Email Address' },
    { errorType: 'required', displayMessage: 'Email is required' }
  ];

  readonly mobileErrors: CheckboxAndInputError[] = [
    { errorType: 'required', displayMessage: 'Mobile phone is required' }
  ];

  @ViewChild('emailInputs', { static: true }) emailInputs: CheckboxAndInputComponent;
  @ViewChild('mobileInputs', { static: true }) mobileInputs: CheckboxAndInputComponent;

  private logger: Logger = new Logger('SendQuestionnaireDialogComponent');

  public customQuestionnaireKeyFriendlyName = {};
  public customQuestionnaireKeySchema = {};
  constructor(
    public dialogRef: MatDialogRef<SendQuestionnaireDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SendQuestionnaireDialogData,
    public questionnaireRequestService: QuestionnaireRequestService,
    private errorHandlerService: ErrorHandlerService,
    private snackBar: MatSnackBar,
    public translocoService: TranslocoService,
    public clinicSetupService: ClinicSetupService
  ) {
    this.formGroup = this.questionnaireRequestService.createFormGroup(data);
    if (data.multipleQuestionnairesSelectable) {
      this.questionnaireTypesFormGroup = this.questionnaireRequestService.createFormGroupForQuestionnaireTypes();
    }

    const languageToSend =
      this.clinicSetupService.clinicSetupFormGroup.controls.defaultQuestionnaireLanguage.value ||
      this.translocoService.getDefaultLang();

    this.languageFormControl.setValue(languageToSend);

    (this.clinicSetupService.formTemplateKeyToTemplatesMap['questionnaire'] || []).forEach(
      formTemplate => {
        this.customQuestionnaireKeyFriendlyName[formTemplate.id] = formTemplate.schema.name;
        this.customQuestionnaireKeySchema[formTemplate.id] = formTemplate.schema;
      }
    );

    const { CUSTOM, ...rest } = this.questionnaireRequestService.questionnaireTypeToFriendlyNameMap;
    this.questionnaireTypeToFriendlyNameMap = rest;
  }

  public static open(
    matDialogService: MatDialog,
    questionnaireType: QuestionnaireType,
    patient: Partial<Patient>,
    modalConfig: MatDialogConfig<never> = {},
    massClinicPatient: MassClinicPatient = null,
    multipleQuestionnairesSelectable = false
  ): SendQuestionnaireMatDialogRef {
    return matDialogService.open<SendQuestionnaireDialogComponent, SendQuestionnaireDialogData>(
      SendQuestionnaireDialogComponent,
      {
        maxWidth: '95vw',
        height: 'auto',
        ...modalConfig,
        data: {
          questionnaireType,
          patient,
          massClinicPatient,
          questionnaireRequest:
            massClinicPatient && (massClinicPatient.questionnaireRequest as QuestionnaireRequest),
          multipleQuestionnairesSelectable
        }
      }
    );
  }

  ngOnInit() {
    this.mobileInputs.checkboxControl.setValue(!!this.mobileFormControl.value);
    this.emailInputs.checkboxControl.setValue(!!this.emailFormControl.value);
  }

  onQuestionnaireSelectChange(atLeastOneCheckboxSelected: boolean) {
    this.atLeastOneCheckboxSelected = atLeastOneCheckboxSelected;
  }

  onSubmit() {
    this.sendClicked = true;

    if (this.formGroup.valid) {
      this.sending = true;
      if (this.data.multipleQuestionnairesSelectable && this.atLeastOneCheckboxSelected) {
        Object.keys(this.questionnaireRequestService.questionnaireTypeToFriendlyNameMap).forEach(
          key => {
            if (
              this.questionnaireTypesFormGroup.controls[key] &&
              this.questionnaireTypesFormGroup.controls[key].value === true
            ) {
              this.questionnairesToBeSent.push(key);
            }
          }
        );

        Object.keys(this.customQuestionnaireKeyFriendlyName).forEach(key => {
          if (
            this.questionnaireTypesFormGroup.controls[key] &&
            this.questionnaireTypesFormGroup.controls[key].value === true
          ) {
            this.questionnairesToBeSent.push(key);
          }
        });

        if (!this.data.questionnaireRequest) {
          this.createQuestionnaireRequest(this.questionnairesToBeSent.pop());
          return;
        }
        this.updateQuestionnaireType(this.questionnairesToBeSent.pop());
      } else if (!this.data.multipleQuestionnairesSelectable) {
        if (!this.data.questionnaireRequest) {
          this.createQuestionnaireRequest();
          return;
        }
        this.updateQuestionnaireType();
      } else {
        this.sending = false;
      }
    }
  }

  public get mobileFormControl(): AbstractControl {
    return this.formGroup.controls.mobile;
  }

  public get emailFormControl(): AbstractControl {
    return this.formGroup.controls.email;
  }

  private createQuestionnaireRequest(questionnaireType?: string) {
    if (questionnaireType) {
      this.formGroup.controls['type'].patchValue(questionnaireType);
    }
    const questionnaireRequestInput: CreateQuestionnaireRequestInput = {
      ...this.formGroup.value,
      language: this.languageFormControl.value,
      config: {
        questionnaireExpiry: this.clinicSetupService.clinicSetupFormGroup.value.questionnaireExpiry
      }
    };

    if (
      this.questionnaireTypesFormGroup.controls[questionnaireType + '_includeMedication'] &&
      this.questionnaireTypesFormGroup.controls[questionnaireType + '_includeMedication'].value
    ) {
      questionnaireRequestInput.config = {
        ...(questionnaireRequestInput.config as any),
        includeMedication: true
      };
    }

    if (this.customQuestionnaireKeySchema[questionnaireType]) {
      (questionnaireRequestInput.type = QuestionnaireType.CUSTOM),
        (questionnaireRequestInput.config = {
          ...(questionnaireRequestInput.config as any),
          schema: this.customQuestionnaireKeySchema[questionnaireType]
        });
    }
    questionnaireRequestInput.config = JSON.stringify(questionnaireRequestInput.config as any);
    let questionnaireRequest$: Observable<QuestionnaireRequest>;

    if (this.data.massClinicPatient) {
      const questionnaireId = uuid();
      const { id, massClinicPatientClinicId, ...massClinicPatient } = this.data.massClinicPatient;

      const updateMassClinicPatient: UpdateMassClinicPatientInput = {
        id,
        massClinicPatientClinicId,
        massClinicPatientMassClinicId: massClinicPatient.massClinic.id,
        massClinicPatientPatientId: massClinicPatient.patient.id,
        massClinicPatientQuestionnaireRequestId: questionnaireId
      };

      questionnaireRequest$ = this.questionnaireRequestService.createQuestionnaireRequestAndUpdateMassClinicPatient(
        {
          id: questionnaireId,
          questionnaireRequestMassClinicPatientId: id,
          ...questionnaireRequestInput
        },
        updateMassClinicPatient
      );
    } else {
      questionnaireRequest$ = this.questionnaireRequestService.createQuestionnaireRequest(
        questionnaireRequestInput
      );
    }

    questionnaireRequest$.subscribe(
      (questionnaireRequest: QuestionnaireRequest) => {
        this.logger.debug('Submitted Questionnaire Request: ', questionnaireRequest);
        this.questionnaireRequestsSent.push(questionnaireRequest);
        if (this.questionnairesToBeSent.length === 0) {
          this.sending = false;
          this.dialogRef.close(
            this.questionnaireRequestsSent.length > 1
              ? this.questionnaireRequestsSent
              : this.questionnaireRequestsSent[0]
          );
          this.openSuccessSnackbar();
        } else {
          this.createQuestionnaireRequest(this.questionnairesToBeSent.pop());
        }
      },
      (error: ApolloError) => {
        this.sending = false;
        this.errorHandlerService.handleGraphQLError(error);
      }
    );
  }

  public updateQuestionnaireType(questionnaireType?: string) {
    if (questionnaireType) {
      this.formGroup.controls['type'].patchValue(questionnaireType);
    }
    this.questionnaireRequestService
      .updateQuestionnaireType(this.data.questionnaireRequest, this.formGroup.controls.type.value)
      .subscribe(
        (questionnaireRequest: QuestionnaireRequest) => {
          this.logger.debug('Submitted Questionnaire Request: ', questionnaireRequest);
          this.questionnaireRequestsSent.push(questionnaireRequest);
          if (this.questionnairesToBeSent.length === 0) {
            this.sending = false;
            this.dialogRef.close(
              this.questionnaireRequestsSent.length > 1
                ? this.questionnaireRequestsSent
                : this.questionnaireRequestsSent[0]
            );
            this.openSuccessSnackbar();
          } else {
            this.createQuestionnaireRequest(this.questionnairesToBeSent.pop());
          }
        },
        (error: ApolloError) => {
          this.sending = false;
          this.errorHandlerService.handleGraphQLError(error);
        }
      );
  }

  private openSuccessSnackbar() {
    if (this.questionnaireRequestsSent.length > 1) {
      let message = 'The following questionnaires have been sent:';
      this.questionnaireRequestsSent.forEach(questionnaireRequest => {
        message = `${message} [${QuestionnaireRequestUtils.getQuestionnaireType(
          questionnaireRequest.type
        )}]`;
      });
      this.snackBar.open(message, 'Dismiss', {
        duration: 7000
      });
    } else {
      const questionnaireType: string = QuestionnaireRequestUtils.getQuestionnaireType(
        this.questionnaireRequestsSent[0].type
      );
      this.snackBar.open(`Sent ${questionnaireType} Questionnaire`, 'Dismiss', {
        duration: 2000
      });
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckboxAndInputError } from '../../checkbox-and-input/checkbox-and-input.component';
import { EMRIntegrationFormGroup, FaxFormGroup } from './emr-integration-form.model';

@Component({
  selector: 'csi-emr-integration-form',
  templateUrl: './emr-integration-form.component.html',
  styleUrls: ['./emr-integration-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmrIntegrationFormComponent {
  public readonly phoneErrors: CheckboxAndInputError[] = [
    {
      errorType: 'invalidPhoneNumber',
      displayMessage: 'Fax # is invalid'
    },
    {
      errorType: 'required',
      displayMessage: 'Fax # is required'
    }
  ];
  public readonly faxLockedEncountersInfo =
    // tslint:disable-next-line: quotemark
    "If enabled, users will have the option to fax locked assessments to your EMR for filing under the patient's EMR record.";

  @Input() formGroup: EMRIntegrationFormGroup;

  get faxFormGroup(): FaxFormGroup {
    return this.formGroup.controls.fax;
  }

  constructor() {}
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { DataSrcService, FileType } from './data-src.service';

@Component({
  selector: 'csi-enlargeable-media',
  templateUrl: './enlargeable-media.component.html',
  styleUrls: ['./enlargeable-media.component.css']
})
export class EnlargeableMediaComponent {
  @ViewChild('image', { static: false }) image: ElementRef<HTMLImageElement>;
  @ViewChild('expandedTemplate', { static: false }) expandedTemplate: TemplateRef<any>;

  public readonly faFileDownload = faFileDownload;
  public pdfPreviewUrl: string;
  public pdfRendered: boolean;

  private modal: MatDialogRef<any>;
  private _src: string | SafeUrl | SafeResourceUrl;

  @Output() downloadFile = new EventEmitter<boolean>();
  downloadFileEmit() {
    this.downloadFile.emit(true);
  }

  @Input() fileType: FileType;
  @Input() showEnlargeButton = true;
  @Input() autoPlay = false;

  @Input() isDownloadable: boolean;

  get src() {
    return this._src;
  }
  @Input('src')
  set src(value: string | SafeUrl | SafeResourceUrl) {
    this._src = value;
    if (this._src) {
      this.setType(this._src);
    }
  }

  public pageRendered: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private dataSrcService: DataSrcService,
    private dialog: MatDialog
  ) {}

  private setType(dataSrc: string | SafeUrl | SafeResourceUrl): void {
    if (!this.fileType) {
      this.fileType = this.dataSrcService.getFileTypeFromExtension(<string>dataSrc);
    }
    if (this.fileType === 'pdf') {
      const urlString = dataSrc['changingThisBreaksApplicationSecurity'];
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(urlString);
      this.pdfPreviewUrl = urlString;
    }
  }

  expandImage(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.modal = this.dialog.open(this.expandedTemplate, {
      height: '90%',
      width: '90%',
      panelClass: 'disable-container-background'
    });
  }

  closeExpandedImage() {
    this.modal.close();
  }
  get sizeClass(): string {
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    return this.image.nativeElement.width / this.image.nativeElement.height >
      windowWidth / windowHeight
      ? 'full-width'
      : 'full-height';
  }
}

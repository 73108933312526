import { Component, Input, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { LocationSelectService } from '../../location-select/location-select.service';
import { ClinicSetupService } from './../../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { Attachment } from './../../attachments/attachments.component';
import { S3AttachmentService } from './../../attachments/s3-attachment.service';
import { AttachmentToUriPipe } from './../../shared-pipes/attachment-to-uri.pipe';
import { ClinicFormGroup } from './clinic-form.model';

type PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'csi-clinic-form',
  templateUrl: './clinic-form.component.html',
  styleUrls: ['./clinic-form.component.scss'],
  providers: [AttachmentToUriPipe]
})
export class ClinicFormComponent implements OnDestroy {
  private _formGroup: ClinicFormGroup;
  public isClinicAdmin: boolean;
  public readonly FILE_SIZE_LIMIT = 4194304;
  public isInvalidFileType: boolean;
  public fileSizeIsLargerThanLimit = false;

  @Input() set formGroup(formGroup: ClinicFormGroup) {
    this._formGroup = formGroup;
    this.logo = formGroup.controls.logo.value;
    this.formGroup.submitted$.pipe(untilDestroyed(this)).subscribe(() => {
      this._formGroup.controls.country.markAsTouched();
      this._formGroup.controls.province.markAsTouched();
    });
  }
  @Input() showRequiredFields = false;
  @Input() hideTitle = false;
  @Input() showInCard = true;

  ngOnDestroy() {}

  get formGroup(): ClinicFormGroup {
    return this._formGroup;
  }

  public logo: Attachment;

  public readonly countryWithProvincesOrStates$: Observable<
    string[]
  > = this.locationSelectService.getCountriesWithStateOrProvince();

  constructor(
    private locationSelectService: LocationSelectService,
    private authService: AuthenticationService,
    private clinicSetupService: ClinicSetupService,
    public s3AttachmentService: S3AttachmentService
  ) {
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;
  }

  onAddressSelected(event: PlaceResult, countryWithProvincesOrStates: string[]) {
    let country = '';
    let province = '';
    let postal_code = '';
    let city = '';

    event.address_components.forEach(address_component => {
      if (address_component.types.includes('country')) {
        country = address_component.short_name;
      } else if (address_component.types.includes('postal_code')) {
        postal_code = address_component.long_name;
      } else if (address_component.types.includes('administrative_area_level_1')) {
        province = address_component.short_name;
      } else if (
        address_component.types.includes('locality') &&
        address_component.types.includes('political')
      ) {
        city = address_component.long_name;
      }
    });

    const clinicFormControls = this.formGroup.controls;
    clinicFormControls.address.setValue(event.name);
    clinicFormControls.country.setValue(country);
    clinicFormControls.city.setValue(city);

    clinicFormControls.province.setValue(province);

    clinicFormControls.postalCode.setValue(postal_code);
    this.formGroup.markAsDirty();
    this.formGroup.markAllAsTouched();
  }

  onCountrylocationChange() {
    this.formGroup.controls.province.setValue(null);
  }

  onFileSelected(event: Event) {
    this.fileSizeIsLargerThanLimit = false;
    const fileInput = (event.target as HTMLInputElement).files[0];

    if (!fileInput) {
      return;
    }
    if (!fileInput.type.includes('image')) {
      this.isInvalidFileType = true;
      return;
    }

    if (fileInput.size > this.FILE_SIZE_LIMIT) {
      this.fileSizeIsLargerThanLimit = true;
      return;
    }
    this.logo = fileInput;

    this.authService
      .getIdentityId()
      .pipe(
        map(identityId => this.s3AttachmentService.convertToS3ObjectInput(identityId, this.logo))
      )
      .subscribe(logoS3Input => {
        this.formGroup.controls.logo.setValue(logoS3Input);
        this.formGroup.controls.logo.markAsDirty();
      });
  }

  onRemoveLogo(event: Attachment) {
    this.formGroup.controls.logo.setValue(null);
    this.logo = null;
    this.formGroup.controls.logo.markAsDirty();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/core/api/patient.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { TabletQuestionnaireModalComponent } from '../../shared/tablet-questionnaire-modal/tablet-questionnaire-modal.component';
import { EncountersRoutes } from '../encounters.routes';
import { AssessmentService } from './../../core/api/assessment.service';
import { SendQuestionnaireDialogComponent } from './../../shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import {
  EncountersCompareModalComponent,
  EncountersToCompare
} from './../encounters-compare-modal/encounters-compare-modal.component';

@Component({
  selector: 'csi-encounter-dashboard',
  templateUrl: './encounter-dashboard.component.html',
  styleUrls: ['./encounter-dashboard.component.scss']
})
export class EncounterDashboardComponent implements OnInit {
  public EncountersRoutes = EncountersRoutes;

  public assessmentIdToExpand: string;

  public assessmentRowHoveredId: string;

  public encountersToCompare: EncountersToCompare = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public assessmentService: AssessmentService,
    public patientService: PatientService,
    public loadingSpinnerService: LoadingSpinnerService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  openTabletQuestionnaire() {
    TabletQuestionnaireModalComponent.open(this.dialog, null, this.patientService.patient)
      .afterClosed()
      .subscribe(questionnaireRequestGenerated => {
        if (questionnaireRequestGenerated) {
          this.patientService.questionnaires = [
            questionnaireRequestGenerated,
            ...this.patientService.questionnaires
          ];
        }
      });
  }

  openSendQuestionnaire() {
    SendQuestionnaireDialogComponent.open(
      this.dialog,
      null,
      this.patientService.patient,
      {},
      null,
      true
    )
      .afterClosed()
      .subscribe(questionnaireRequestsSent => {
        if (questionnaireRequestsSent) {
          if (!Array.isArray(questionnaireRequestsSent)) {
            questionnaireRequestsSent = [questionnaireRequestsSent];
          }
          this.patientService.questionnaires = [
            ...questionnaireRequestsSent,
            ...this.patientService.questionnaires
          ];
        }
      });
  }

  openEncountersCompare() {
    EncountersCompareModalComponent.open(this.dialog, this.encountersToCompare);
  }

  routeToNewEncounter() {
    this.router.navigate([{ outlets: { encounters: EncountersRoutes.New } }], {
      relativeTo: this.route.parent
    });
  }
}

import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { Assessment } from 'src/API';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import { noSort } from 'src/app/core/api/model-utils';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { Attachment } from 'src/app/shared/attachments/attachments.component';
import { DryEyeFormGroup } from 'src/app/shared/consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import { signGroups } from '../../signs-attachments-modal/signs-attachments-schema';
import { SignsAttachmentsService } from '../../signs-attachments-modal/signs-attachments.service';

@Component({
  selector: 'csi-sign-image-wizard-modal',
  templateUrl: './sign-image-wizard-modal.component.html',
  styleUrls: ['./sign-image-wizard-modal.component.scss']
})
export class SignImageWizardModalComponent implements OnInit {
  public signGroups = signGroups;
  public formGroup: DryEyeFormGroup;
  public formGroupCopy: DryEyeFormGroup;
  public selectedTabIndex = 0;
  public stepperList: any[];

  public disablePreviousButton = true;
  public disableNextButton = false;
  public saving = false;

  public reload$: Subject<void> = new Subject<void>();

  public numSignGroups = 0;
  public show: { [key: string]: string } = {};
  public simpleSignKeys = [];

  @ViewChildren('stepper') steppers: QueryList<ElementRef>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SignImageWizardModalComponent>,
    private assessmentService: AssessmentService,
    public signsAttachmentsService: SignsAttachmentsService,
    private loadingSpinnerService: LoadingSpinnerService,
    public layoutsService: LayoutsService,
    public schemaService: SchemaService
  ) {
    this.formGroup = this.data.formGroup;
    this.formGroupCopy = _.cloneDeep(this.formGroup); // Prevents multiple dialogs from opening due to ngOnChanges
  }
  ngOnInit() {
    this.numSignGroups = Object.keys(this.signGroups).length;
    this.updateAttachments();

    this.dialogRef.afterClosed().subscribe(() => {
      this.formGroup.patchValue(this.formGroupCopy.value);
    });
    this.simpleSignKeys = this.schemaService.symptomKeys.filter(symptomMethodKey =>
      this.isSimpleSymptom(symptomMethodKey)
    );

    this.filterByLayoutShowFields();
  }

  filterByLayoutShowFields() {
    if (this.assessmentMethod === 'simple') {
      this.show = this.simpleSignKeys.reduce((acc, sign) => {
        return { ...acc, ...{ [sign]: sign } };
      }, {});
    }
    if (!this.layoutsService.allLayoutsById[this.assessmentMethod]) {
      return;
    }
    if (this.assessmentMethod === 'preop') {
      Object.keys(this.layoutsService.allLayoutsById[this.assessmentMethod].customLayout).forEach(
        key => {
          for (let sign of this.layoutsService.allLayoutsById[this.assessmentMethod].customLayout[
            key
          ].show) {
            this.show = { ...this.show, ...{ [sign]: sign } };
          }
        }
      );
    } else {
      for (let sign of this.layoutsService.allLayoutsById[this.assessmentMethod].signsLayout.show) {
        this.show = { ...this.show, ...{ [sign]: sign } };
      }
    }
  }

  ngAfterViewInit() {
    if (this.steppers) {
      this.stepperList = this.steppers.toArray();
    }
  }

  static open(
    matDialog: MatDialog,
    formGroup: DryEyeFormGroup,
    assessmentId: string,
    saveFn: () => Observable<Assessment>
  ) {
    return matDialog.open(SignImageWizardModalComponent, {
      width: '1200px',
      disableClose: true,
      data: { formGroup: formGroup, assessmentId: assessmentId, saveFn: saveFn }
    });
  }

  updateAttachments() {
    this.assessmentService.getAssessment(this.data.assessmentId, 'no-cache').subscribe(data => {
      const incomingAttachments = data.attachments;
      const incomingMainAttachments = [];
      const incomingSignAttachments = [];
      if (!incomingAttachments) return;
      this.loadingSpinnerService.show();

      for (let attachment of incomingAttachments) {
        if (
          this.signsAttachmentsService.checkMetadata(
            'sign',
            'main',
            JSON.parse(attachment['metadata'])
          )
        ) {
          incomingMainAttachments.push(attachment);
        } else {
          incomingSignAttachments.push(attachment);
        }
      }
      const localMainAttachments = [...this.getLocalMainAttachments()];

      this.signsAttachmentsService.attachmentsFormControl.patchValue([
        ...(incomingMainAttachments || []),
        ...(localMainAttachments || []),
        ...(incomingSignAttachments || [])
      ]);

      this.reload$.next();
      this.loadingSpinnerService.hide();
    });
  }

  getLocalMainAttachments() {
    const localAttachments = this.signsAttachmentsService.attachmentsFormControl.value.filter(
      (a: Attachment) => a instanceof File
    );

    const localMainAttachments = [];
    for (let attachment of localAttachments) {
      if (
        this.signsAttachmentsService.checkMetadata(
          'sign',
          'main',
          JSON.parse(attachment['metadata'])
        )
      ) {
        localMainAttachments.push(attachment);
      }
    }
    return localMainAttachments;
  }

  get assessmentMethod(): SymptomModes {
    return this.formGroup.controls.assessmentMethod.value;
  }

  public save() {
    this.formGroup.patchValue(this.formGroupCopy.value);
    this.saving = true;
    this.data.saveFn().subscribe(() => {
      this.saving = false;
      this.dialogRef.close();
    });
  }

  public previousStep() {
    this.selectedTabIndex--;
    this.toggleDisable();
  }
  public nextStep() {
    this.selectedTabIndex++;
    this.toggleDisable();
  }

  public toggleDisable() {
    if (this.selectedTabIndex === 0) {
      this.disablePreviousButton = true;
      this.disableNextButton = false;
    } else if (this.selectedTabIndex === this.numSignGroups - 1) {
      this.disableNextButton = true;
      this.disablePreviousButton = false;
    } else {
      this.disablePreviousButton = false;
      this.disableNextButton = false;
    }
    return;
  }

  public isSimpleSymptom(symptomKey: string) {
    return this.schemaService.symptomMap[symptomKey].modes.includes(SymptomModes.Simple);
  }

  public noSort = noSort;
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomValidator } from 'src/app/intake-form/intake-form-validators';

@Component({
  selector: 'csi-questionnaire-setup',
  templateUrl: './questionnaire-setup.component.html',
  styleUrls: ['./questionnaire-setup.component.css']
})
export class QuestionnaireSetupComponent implements OnInit {
  @Input() control: FormControl;
  label = 'Default Label';
  public readonly minimumDays: number = 3;
  public readonly maximumDays: number = 90;
  constructor() {}

  ngOnInit() {
    if (!this.control) {
      this.control = new FormControl(14);
    }

    if (!this.control.value) {
      this.control = new FormControl(14);
    }

    this.control.setValidators([
      CustomValidator.rangeInBetween(this.minimumDays, this.maximumDays),
      CustomValidator.required
    ]);
  }
}

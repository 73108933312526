import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import cleanDeep from 'clean-deep';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  CreateIntakeFormInput,
  GetFormTemplateQuery,
  IntakeForm,
  UpdateIntakeFormInput
} from 'src/API';
import { AppSyncService } from 'src/app/core/appsync.service';
import {
  oliver_welcomeIntakeForm,
  standard_intakeForm
} from '../form-template/form-field-template-library';
import {
  GetIntakeFormQuery,
  ListIntakeFormByClinicIdSortedByCreatedAtQuery,
  ModelSortDirection
} from './../../API';
import { updateIntakeForm } from './../../graphql/mutations';
import { getIntakeForm, listIntakeFormByClinicIdSortedByCreatedAt } from './../../graphql/queries';
import {
  generalButtonMap,
  IntakeFormSchema,
  medicalConditions,
  ocularHealthQuestion,
  patientGeneralHealthQuestion,
  patientScreening,
  standardIntakeFormSchema
} from './intake-form-schema';

function atLeastOneCheckboxRequired(form: FormGroup): ValidationErrors | null {
  const checkbox = Object.values(form.controls);
  const isAnyChecked = checkbox.some(control => control.value);
  return isAnyChecked ? null : { atLeastOneRequired: true };
}

@Injectable({
  providedIn: 'root'
})
export class IntakeFormService {
  public ocularHealthQuestion = ocularHealthQuestion;
  public generalHealthQuestion = medicalConditions;
  public patientPreScreeningQuestion = patientScreening;
  public patientGeneralHealthQuestion = patientGeneralHealthQuestion;
  public buttonMap = generalButtonMap;

  public currentIntakeForm: IntakeForm;
  public intakeFormList: IntakeForm[];
  public standardIntakeFormSchema = standardIntakeFormSchema;
  public defaultIntakeFormTemplateSchemas = {
    standard: standard_intakeForm,
    welcome: oliver_welcomeIntakeForm
  };

  private createIntakeForm = /* GraphQL */ `
    mutation CreateIntakeForm($input: CreateIntakeFormInput!) {
      CreateIntakeForm(input: $input) {
        id
        response
        createdAt
        intakeFormPatientId
        intakeFormClinicId
        statusId
        status
        lastRead
        updatedAt
      }
    }
  `;

  constructor(private appSyncService: AppSyncService) {}

  public createIntakeFromInput(input: CreateIntakeFormInput): Observable<IntakeForm> {
    return this.appSyncService.hydrated(true).pipe(
      switchMap(intakeForm => {
        return intakeForm.mutate({
          mutation: gql(this.createIntakeForm),
          variables: { input: cleanDeep(input) }
        });
      })
    );
  }

  public getIntakeForms(
    intakeFormClinicId: string,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<Partial<IntakeForm[]>> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listIntakeFormByClinicIdSortedByCreatedAt),
          variables: { intakeFormClinicId, sortDirection: ModelSortDirection.DESC },
          fetchPolicy: fetchPolicy
        })
      ),

      map((result: ApolloQueryResult<ListIntakeFormByClinicIdSortedByCreatedAtQuery>) => {
        return result.data.listIntakeFormByClinicIdSortedByCreatedAt.items.map(item => ({
          ...item,
          response: JSON.parse(item.response)
        }));
      }),
      tap(intakeForms => {
        this.intakeFormList = intakeForms;
      })
    );
  }

  public getFormTemplate(formTemplateId: string) {
    return this.appSyncService.hydrated(true).pipe(
      switchMap(client =>
        client.query({
          query: gql(this.getFormTemplateQuery),
          variables: { id: formTemplateId },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<GetFormTemplateQuery>) => {
        return {
          ...result.data.getFormTemplate,
          response: JSON.parse(result.data.getFormTemplate.schema)
        };
      }),
      tap(formTemplates => {
        // console.log('form templates', formTemplates);
      })
    );
  }

  public getIntakeForm(
    id: string,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<IntakeForm> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(getIntakeForm),
          variables: { id },
          fetchPolicy: fetchPolicy
        })
      ),
      map((result: ApolloQueryResult<GetIntakeFormQuery>) => {
        if (result.data && result.data.getIntakeForm) {
          this.currentIntakeForm = result.data.getIntakeForm;
          return {
            ...result.data.getIntakeForm,
            response: JSON.parse(result.data.getIntakeForm.response)
          };
        }
        return null;
      })
    );
  }

  // After switching to standardIntakeFormSchema, responses with depracted keys need to be mapped to the correct schema key
  public transformDeprecatedResponseKeys(
    response: { [key: string]: any },
    intakeFormSchema: IntakeFormSchema
  ) {
    Object.keys(intakeFormSchema.groups).forEach(key => {
      const depractedKey = intakeFormSchema.groups[key].deprecatedResponseGroupKey;
      if (depractedKey && response[depractedKey] && response[key]) {
        response[key] = response[depractedKey];
        delete response[depractedKey];
      }
    });
  }

  buildFormGroup(questionList: any, formGroup: FormGroup) {
    Object.keys(questionList).forEach(question => {
      const validators = questionList[question].validators
        ? questionList[question].validators
        : null;
      const questions = questionList[question];
      if (questions.inputType.type === 'checkbox') {
        formGroup.addControl(question, new FormControl(false, validators));

        formGroup.setValidators(atLeastOneCheckboxRequired);
        formGroup.updateValueAndValidity();
      }
      if (questions.inputType.type === 'checkboxGroup') {
        formGroup.addControl(
          question,
          new FormGroup(
            Object.keys(questions.inputType.checkboxes).reduce(
              (group, key) => ({ ...group, [key]: new FormControl() }),
              {}
            ),
            [atLeastOneCheckboxRequired]
          )
        );
      }
      if (
        questionList[question].inputType.type === 'input' ||
        questionList[question].inputType.type === 'radio' ||
        questionList[question].inputType.type === 'date' ||
        questionList[question].inputType.type === 'medication' ||
        questionList[question].inputType.type === 'slider'
      ) {
        formGroup.addControl(question, new FormControl(null, validators));
      }
    });
  }

  public updateIntakeFormWithPatientId(input: UpdateIntakeFormInput): Observable<IntakeForm> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(updateIntakeForm),
          variables: { input: input }
        })
      ),
      map(result => result.data.UpdateIntakeForm)
    );
  }

  private readonly getFormTemplateQuery = `
  query GetFormTemplate($id: ID!) {
    getFormTemplate(id: $id) {
      id
      schema
    }
  }
`;
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-form-template-slider-field-config',
  templateUrl: './form-template-slider-field-config.component.html',
  styleUrls: ['./form-template-slider-field-config.component.css']
})
export class FormTemplateSliderFieldConfigComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;

  public numericFieldKeyFriendlyNameMap = { min: 'Minimum', max: 'Maximum', step: 'Step' };
  public labelFieldKeyFriendlyNameMap = { minLabel: 'Minimum Label', maxLabel: 'Maximum Label' };

  public typeConfigFormGroup: FormGroup;

  public noSort = noSort;
  constructor() {}

  ngOnInit() {
    this.typeConfigFormGroup = this.templateFieldFormGroup.controls['typeConfig'] as FormGroup;
    // Numeric Config
    Object.keys(this.numericFieldKeyFriendlyNameMap).forEach(controlKey => {
      this.typeConfigFormGroup.addControl(
        controlKey,
        new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$')])
      );
    });

    // Label Config
    Object.keys(this.labelFieldKeyFriendlyNameMap).forEach(controlKey => {
      this.typeConfigFormGroup.addControl(controlKey, new FormControl(null));
    });

  }
}

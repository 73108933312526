import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { RadioSymptom } from '../symptom.model';

@Component({
  selector: 'csi-toggle-group-symptom-control',
  templateUrl: './toggle-group-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './toggle-group-symptom-control.component.css'
  ]
})
export class ToggleGroupSymptomControlComponent extends AbstractSymptomControlComponent
  implements OnChanges {
  @Input() symptom: RadioSymptom;
  @Input() formKey: string;
  @Input() formGroup: FormGroup;

  valueKeys = [];

  constructor(protected symptomValidatorsService: SymptomValidatorsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.symptom && this.symptom && this.symptom.values) {
      this.valueKeys = Object.keys(this.symptom.values);
    }
  }

  get shouldShowThermalScale(): boolean {
    return this.symptom.thermalScale && this.value;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatStepper,
  MAT_DIALOG_DATA
} from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { defer, merge, Observable, of } from 'rxjs';
import { delay, shareReplay } from 'rxjs/operators';
import { CSVPatientsFormGroup } from './import-wizard-file-received/csv-patients-form-group.model';

export type PatientImportWizardMatDialogRef = MatDialogRef<PatientImportWizardModalComponent>;

@Component({
  selector: 'csi-patient-import-wizard-modal',
  templateUrl: './patient-import-wizard-modal.component.html',
  styleUrls: ['./patient-import-wizard-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientImportWizardModalComponent implements OnDestroy, AfterViewInit {
  public readonly fileControl: FormControl;
  public readonly fileChanges$: Observable<File[]>;
  public csvPatientsFormGroup: CSVPatientsFormGroup;
  public isEditable = true;
  public isMassClinic: boolean;
  @ViewChild('stepper', { static: true }) matStepper: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public patientImportData: {
      importedFile?: File;
      isMassClinic?: boolean;
    },
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialogService: MatDialog
  ) {
    this.fileControl = this.fb.control(
      this.patientImportData.importedFile !== null ? [this.patientImportData.importedFile] : null,
      Validators.required
    );
    this.fileChanges$ = merge(
      this.fileControl.valueChanges,
      defer(() => of(this.fileControl.value))
    ).pipe(shareReplay(1));

    this.fileControl.valueChanges.pipe(untilDestroyed(this), delay(0)).subscribe(() => {
      if (this.fileControl.valid) {
        this.matStepper.selectedIndex = 1;
      }
      this.changeDetectorRef.markForCheck();
      this.csvPatientsFormGroup = new CSVPatientsFormGroup();
    });
    this.csvPatientsFormGroup = new CSVPatientsFormGroup();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    if (this.patientImportData.importedFile !== null) {
      this.allowUserToContinueToNextStep();
    }
  }

  public static open(
    matDialogService: MatDialog,
    importedFile: File = null,
    isMassClinic?: boolean,
    modalConfig: MatDialogConfig<never> = {}
  ): PatientImportWizardMatDialogRef {
    return matDialogService.open<PatientImportWizardModalComponent>(
      PatientImportWizardModalComponent,
      {
        maxHeight: '90vh',
        minWidth: '60vw',
        disableClose: true,
        data: { importedFile, isMassClinic },
        ...modalConfig
      }
    );
  }

  protected goBack() {
    this.matStepper.selectedIndex = this.matStepper.selectedIndex - 1;
  }

  public goNext() {
    this.matStepper.selectedIndex = this.matStepper.selectedIndex + 1;
  }

  protected toSendQuestionnaires() {
    this.matStepper.selectedIndex = 3;
    setTimeout(() => (this.matStepper.selectedIndex = 3), 10000);
    this.isEditable = false;
  }

  public exitModal() {
    this.matDialogService.closeAll();
  }

  protected endImportProcess() {
    window.location.reload();
  }
  private allowUserToContinueToNextStep() {
    // Forces the first step to be interacted, so that mat-step allows the user to go to the next step
    this.matStepper._steps.toArray()[0].interacted = true;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { faFileDownload, faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { DataSrcService } from '../../enlargeable-media/data-src.service';
import { AttachmentToUriPipe } from '../../shared-pipes/attachment-to-uri.pipe';
import { Attachment } from '../attachments.component';
import { S3AttachmentService, UrlWithContentType } from '../s3-attachment.service';
@Component({
  selector: 'csi-attachment-enlarged',
  templateUrl: './attachment-enlarged.component.html',
  styleUrls: ['./attachment-enlarged.component.scss'],
  providers: [AttachmentToUriPipe]
})
export class AttachmentEnlargedComponent implements OnInit {
  @Input() attachment: Attachment;
  @Input() preloadedAttachments: UrlWithContentType[] = [];
  @Input() preloadedAttachment: UrlWithContentType;

  public attachmentUrlAndMime: UrlWithContentType;

  readonly faFileVideo = faFileVideo;
  public readonly faFileDownload = faFileDownload;

  constructor(
    public dataSrcService: DataSrcService,
    public s3AttachmentService: S3AttachmentService,
    private attachmentToUri: AttachmentToUriPipe
  ) { }

  ngOnInit(): void {
    if (!this.preloadedAttachment) {
      this.attachmentToUri.transform(this.attachment).subscribe(data => {
        this.attachmentUrlAndMime = data;

        this.preloadedAttachments.push({
          ...data,
          fileName: this.dataSrcService.getFileName(this.attachment)
        });
      });
    } else {
      this.attachmentUrlAndMime = this.preloadedAttachment;
    }
  }

}
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { CognitoUser } from '@aws-amplify/auth';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { PasswordMatchValidator } from 'src/app/authentication/PasswordMatchValidator';
import { LoginError } from 'src/app/core/authentication/models/error-models/LoginError';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

interface PasswordChangeError {
  name: string;
  message: string;
}

@Component({
  selector: 'csi-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {
  public submitted = false;
  public formGroup: FormGroup;
  public passwordChangeError: PasswordChangeError;
  public passwordChangeSuccessful = false;
  public LoginError: LoginError;

  public matSpinnerOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Change My Password',
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  constructor(
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>
  ) {
    const passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

    this.formGroup = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: passwordControl,
      confirmNewPassword: new FormControl('', [
        Validators.required,
        PasswordMatchValidator.validatorForPasswordControl(passwordControl)
      ])
    });
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;

      this.authenticationService.getLoggedInUser().subscribe((cognitoUser: CognitoUser) => {
        cognitoUser.changePassword(
          this.formGroup.controls.currentPassword.value,
          this.formGroup.controls.newPassword.value,
          error => {
            if (error) {
              this.passwordChangeError = error;
              if (error.name === 'NotAuthorizedException') {
                this.passwordChangeError.message = 'Current password is invalid';
                this.matSpinnerOptions.active = false;
              }
            } else {
              this.passwordChangeError = null;
              this.passwordChangeSuccessful = true;
              this.matSpinnerOptions.active = false;
              this.snackBar.open('You have successfully changed your password.', null, {
                duration: 3000
              });
              this.dialogRef.close();
            }
          }
        );
      });
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Symptom } from 'src/app/shared/symptoms/symptom.model';

@Pipe({
  name: 'canHideSign'
})

/**
 * ManualTrigger is needed for the HTML disabled
 */
export class CanHideSignPipe implements PipeTransform {
  transform(
    currentSymptom: Symptom,
    currentSymptomKey: string,
    showSignKeys: string[],
    preventHide?: boolean
  ): boolean {
    if (preventHide) {
      return true;
    }

    preventHide = currentSymptom.required && currentSymptom.required.preventHide;

    let canBeHidden = !preventHide;

    if (
      preventHide &&
      currentSymptom.required.advanced &&
      currentSymptom.required.advanced.oneOfSigns
    ) {
      canBeHidden =
        currentSymptom.required.advanced.oneOfSigns.filter(oneOfSignKey =>
          oneOfSignKey !== currentSymptomKey
            ? showSignKeys.some(showSignKey => showSignKey === oneOfSignKey)
            : false
        ).length >= 1;
    }
    return canBeHidden;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { TreatmentTemplateActionsModalComponent } from '../treatment-template-actions-modal/treatment-template-actions-modal.component';
import { TreatmentTemplate } from './../../../../../API';

interface TreatmentTemplateViewData {
  treatmentTemplates: TreatmentTemplate[];
  isClickable?: boolean;
  filterHidden?: boolean;
}
interface TreatmentTemplateHeader {
  columnDef: string;
  label: string;
  displayLength?: boolean;
}
@Component({
  selector: 'csi-treatment-template-view-modal',
  templateUrl: './treatment-template-view-modal.component.html',
  styleUrls: ['./treatment-template-view-modal.component.scss']
})
export class TreatmentTemplateViewModalComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TreatmentTemplateViewModalComponent>,
    private clinicSetupService: ClinicSetupService,
    @Inject(MAT_DIALOG_DATA) public data: TreatmentTemplateViewData
  ) {}

  public filterControl: FormControl = new FormControl();
  private treatmentTemplateFormGroups = [];
  public filteredTreatmentTemplateFormGroups = [];

  public tableHeaders: TreatmentTemplateHeader[] = [
    { columnDef: 'friendlyId', label: 'Treatment Template Id' },
    { columnDef: 'otcEyeDrops', label: 'OTC' },
    { columnDef: 'rxEyeDrops', label: 'RX' },
    { columnDef: 'exercises', label: 'Exercises' },
    { columnDef: 'procedures', label: 'Procedures' }
  ];
  public displayedColumns = this.tableHeaders.map(tableHeader => tableHeader.label);

  ngOnInit() {
    this.treatmentTemplateFormGroups = this.data.treatmentTemplates
      .filter(
        treatmentTemplate =>
          !this.data.filterHidden ||
          !(this.clinicSetupService.hiddenTreatmentTemplates || []).includes(treatmentTemplate.id)
      )
      .map(treatmentTemplate => this.getTreatmentTemplateFormGroup(treatmentTemplate));

    this.filteredTreatmentTemplateFormGroups = this.treatmentTemplateFormGroups;

    this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe((filter: string) => {
      if (!filter.trim()) {
        this.filteredTreatmentTemplateFormGroups = this.treatmentTemplateFormGroups;
      }
      const lowerCase = filter.toLocaleLowerCase();

      this.filteredTreatmentTemplateFormGroups = this.treatmentTemplateFormGroups.filter(item => {
        return item.value.friendlyId.toLowerCase().includes(lowerCase);
      });
    });
  }

  selectedItem(row) {
    if (this.data.isClickable) {
      this.dialogRef.close(row);
    }
  }

  openTreatmentTemplateActionsModal(
    action: 'add',
    treatmentTemplate: TreatmentTemplate = null,
    isView = false
  ) {
    this.dialog
      .open(TreatmentTemplateActionsModalComponent, {
        width: '75vw',
        data: { action, treatmentTemplate, isView }
      })
      .afterClosed()
      .subscribe(updatedTreatmentTemplate => {
        if (updatedTreatmentTemplate) {
          this.treatmentTemplateFormGroups = [
            this.getTreatmentTemplateFormGroup(updatedTreatmentTemplate),
            ...this.treatmentTemplateFormGroups
          ];
          this.filteredTreatmentTemplateFormGroups = [...this.treatmentTemplateFormGroups];
        }
      });
  }

  private getTreatmentTemplateFormGroup(treatmentTemplate: TreatmentTemplate) {
    return new FormGroup({
      id: new FormControl(treatmentTemplate.id),
      friendlyId: new FormControl(treatmentTemplate.friendlyId),
      otcEyeDrops: new FormControl(treatmentTemplate.otcEyeDrops),
      rxEyeDrops: new FormControl(treatmentTemplate.rxEyeDrops),
      procedures: new FormControl(treatmentTemplate.procedures),
      exercises: new FormControl(treatmentTemplate.exercises)
    });
  }
}

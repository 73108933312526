import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterEvent
} from '@angular/router';
import { capitalize } from 'lodash-es';
import { filter } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { StaffService } from 'src/app/core/api/staff.service';
import { AppRoutePath } from '../app-routing.module';
import { Patient } from '../core/api/patient.service';
import { DomainOption, HOSTING_DOMAIN_ACCESSOR } from '../core/domain-accessor';
import { PatientModalComponent } from '../shared/consult-forms/patient-modal/patient-modal.component';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { PatientLookupComponent } from '../shared/patient-lookup/patient-lookup.component';
import { ImageToDataUriPipe } from '../shared/shared-pipes/image-to-data-uri.pipe';
import { SvgPipe } from '../shared/shared-pipes/svg.pipe';
import { InboxModalComponent } from './inbox/inbox-modal/inbox-modal.component';
import { InboxService } from './inbox/inbox.service';

interface RouteData {
  loggedInDoctor: Doctor;
  hideNavbar: boolean;
}

@Component({
  selector: 'csi-logged-in-navbar',
  templateUrl: './logged-in-navbar.component.html',
  styleUrls: ['./logged-in-navbar.component.css'],
  providers: [ImageToDataUriPipe, SvgPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoggedInNavbarComponent {
  @ViewChild('patientLookup', { static: false }) patientLookupComponent: PatientLookupComponent;

  public hideNavbar = true;

  public siteLogo: string;
  public siteLogoSmall: string;

  public isRefer: boolean;

  public shouldShowPatientLookup;
  public shouldShowDryEyePortalButton;
  public shouldShowReferralButton;

  public loggedInDoctor: Doctor;

  private readonly IMAGE_LOCATIONS = {
    [DomainOption.Seema]: '../../../assets/images/landing-page/seema-logo.png',
    [DomainOption.CSI]: '../../../assets/images/landing-page/logo-here.png',
    [DomainOption.Other]: '../../../assets/images/landing-page/logo-here.png'
  };
  private readonly IMAGE_LOCATIONS_SMALL = {
    [DomainOption.Seema]: '../../../assets/images/landing-page/seema-logo-small.png',
    [DomainOption.CSI]: '../../../assets/images/landing-page/logo-small.png',
    [DomainOption.Other]: '../../../assets/images/landing-page/logo-here.png'
  };
  public showViewAllPatientsButton = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private loadingSpinner: LoadingSpinnerService,
    private dialog: MatDialog,
    public inboxService: InboxService,
    public staffService: StaffService,
    @Inject(HOSTING_DOMAIN_ACCESSOR) private domain: DomainOption
  ) {
    this.initializeSiteLogo();
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(routerEvent => {
        const data = this.findRouteData(this.activatedRoute.snapshot);
        this.hideNavbar = !!data.hideNavbar;

        this.loggedInDoctor = data.loggedInDoctor;

        this.isRefer = routerEvent.url.includes('refer');
        this.showViewAllPatientsButton =
          routerEvent.url !== '/patients' && !this.isRefer && domain !== DomainOption.Seema;

        if (this.loggedInDoctor) {
          this.shouldShowPatientLookup = domain !== DomainOption.Seema;

          this.shouldShowDryEyePortalButton =
            this.loggedInDoctor &&
            this.staffService.isDoctorApprovedForDryEyeSpecializedForm(this.loggedInDoctor);

          this.shouldShowReferralButton =
            this.shouldShowDryEyePortalButton &&
            (this.staffService.staff.clinic.referralCenter ||
              this.staffService.staff.clinic.linkedReferralCenter);

          this.changeDetector.markForCheck();
        }

        if (this.loggedInDoctor) {
          this.inboxService.initInbox(
            this.loggedInDoctor.clinic.id,
            this.loggedInDoctor.id,
            this.loggedInDoctor.clinic.doctors.items
          );
        }
      });

    setInterval(() => {
      this.inboxService.loadNotifications().subscribe(() => {
        this.changeDetector.detectChanges();
      });
    }, 300000); // check every 5 minutes
  }

  public viewPatient(patient: Patient) {
    this.loadingSpinner.show();
    this.router
      .navigateByUrl(`/${this.isRefer ? '/refer/patient' : AppRoutePath.patients}/${patient.id}`)
      .finally(() => this.loadingSpinner.hide());
  }

  public createNewPatient(loggedInDoctor: Doctor, patientFullName: string) {
    let [firstName, lastName] = patientFullName.split(/\s+/);
    firstName = capitalize(firstName);
    lastName = capitalize(lastName);

    PatientModalComponent.open(
      this.dialog,
      loggedInDoctor,
      {
        firstName,
        lastName
      },
      this.isRefer && !this.loggedInDoctor.clinic.referralCenter ? 'refer' : 'dry-eye'
    );
  }

  private findRouteData(route: ActivatedRouteSnapshot): RouteData {
    const data: RouteData = {
      loggedInDoctor: undefined,
      hideNavbar: undefined
    };

    for (; route; route = route.firstChild) {
      const currentRouteData = route.data as RouteData;

      if (currentRouteData.loggedInDoctor) {
        data.loggedInDoctor = currentRouteData.loggedInDoctor;
      }
      if (currentRouteData.hideNavbar !== undefined) {
        data.hideNavbar = currentRouteData.hideNavbar;
      }
    }

    return data;
  }

  private initializeSiteLogo() {
    this.siteLogo = this.IMAGE_LOCATIONS[this.domain];
    this.siteLogoSmall = this.IMAGE_LOCATIONS_SMALL[this.domain];
  }

  public openInbox() {
    InboxModalComponent.open(this.dialog)
      .afterClosed()
      .subscribe(() => this.changeDetector.detectChanges());
  }
}

import { Injectable } from '@angular/core';
import { Patient } from 'src/API';
import { PatientService } from 'src/app/core/api/patient.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';

@Injectable({
  providedIn: 'root'
})
export class PatientSummaryPaginatorService {
  public patientSummaryPages: { [key: number]: Patient[] };
  public totalPatientSummaries: number;
  public currentPage = 0;
  public nextToken = null;
  public isLoading = true;
  public currentPatientSummaries: Patient[] = [];
  public maxPatientSummariesPerPage = 10;

  constructor(
    private patientService: PatientService,
    private clinicSetupService: ClinicSetupService
  ) {}

  reset() {
    this.patientSummaryPages = {};
    this.totalPatientSummaries = 0;
    this.currentPage = 0;
    this.nextToken = null;
    this.isLoading = true;
  }

  pageChange(pageNumber: number = 0) {
    this.currentPage = pageNumber;

    if (this.patientSummaryPages[pageNumber]) {
      this.currentPatientSummaries = [...this.patientSummaryPages[pageNumber]];
      return;
    }

    this.isLoading = true;
    this.patientService
      .listPatientsSortedByLastInteraction(
        this.clinicSetupService.clinicId,
        this.nextToken,
        this.maxPatientSummariesPerPage
      )
      .subscribe(patientsWithToken => {
        this.nextToken = patientsWithToken.nextToken;
        this.isLoading = false;

        if (patientsWithToken.items.length > 0 || this.currentPage === 0) {
          this.currentPatientSummaries = patientsWithToken.items;
          this.patientSummaryPages[pageNumber] = this.currentPatientSummaries;
          this.totalPatientSummaries += this.currentPatientSummaries.length;
        }

        // Need to add one if there is more to load
        this.totalPatientSummaries +=
          this.totalPatientSummaries % this.maxPatientSummariesPerPage !== 1 && this.nextToken
            ? 1
            : pageNumber !== 0 && !this.nextToken
            ? -1
            : 0;
      });
  }
}

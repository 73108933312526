export type Operator = '<' | '>' | '=' | '<=>';

export interface Condition {
  operator: Operator;
  value: string | number | number[];
}
export interface SignsSchema {
  [key: string]: {
    group: number | string;
    optional?: boolean;
    normalCondition?: Condition | ((p: any[]) => boolean);
  };
}

export enum PreOPExpansionPanel {
  symptom = 'symptom',
  preOperativeVisit = 'preOperativeVisit',
  preOperativeKeratometryMeasurements = 'preOperativeKeratometryMeasurements',
  osdScreen = 'osdScreen',
  osdScreeningSigns = 'osdScreeningSigns',
  optionalNoninvasiveOSDTests = 'optionalNoninvasiveOSDTests',
  clinicalExams = 'clinicalExams',
  impressions = 'impressions',
  analysis = 'analysis',
  verdict = 'verdict',
  treatmentsPlan = 'treatmentsPlan'
}

export enum OSDFactors {
  symptom = 'symptom',
  signs = 'signs',
  stains = 'stains',
  exams = 'exams'
}

export enum KeratometryMeasurements {
  steepk = 'SteepK',
  steepdegree = 'SteepDegree',
  flatk = 'FlatK',
  flatdegree = 'FlatDegree',
  diopter = 'Diopter',
  classification = 'Classification'
}

export interface AnalysisStateItem {
  abnormal: boolean;
  message: string;
  simpleMessage?: string;
  technicianNote?: string;
  significant: boolean;
  required?: boolean;
  group: string;
}

export interface AnalysisState {
  [key: string]: AnalysisStateItem;
}

export const preOperativeKeratometryKeys = {
  AutoRefraction: 'Auto Refraction',
  Manual: 'Manual',
  Topography: 'Topography',
  OpticalBiometry: 'Optical Biometry',
  Aberrometry: 'Aberrometry'
};

export const preOperativeKeratometryValues = {
  AutoRefraction: {
    right: {
      diopter: 0,
      classification: ''
    },
    left: {
      diopter: 0,
      classification: ''
    }
  },
  Manual: {
    right: {
      diopter: 0,
      classification: ''
    },
    left: {
      diopter: 0,
      classification: ''
    }
  },
  Topography: {
    right: {
      diopter: 0,
      classification: ''
    },
    left: {
      diopter: 0,
      classification: ''
    }
  },
  OpticalBiometry: {
    right: {
      diopter: 0,
      classification: ''
    },
    left: {
      diopter: 0,
      classification: ''
    }
  },
  Aberrometry: {
    right: {
      diopter: 0,
      classification: ''
    },
    left: {
      diopter: 0,
      classification: ''
    }
  }
};

export const signsSchema: SignsSchema = {
  OSMOLARITY: {
    group: 1,
    normalCondition: ([value1, value2]) => {
      if (!value1) {
        return true;
      }

      if (value1 && value2) {
        return Math.abs(value1 - value2) <= 8;
      }

      return value1 < 308;
    },
    optional: true
  },
  MMP9: {
    group: 1,
    normalCondition: {
      operator: '=',
      value: 'negative'
    },
    optional: true
  },
  ITBUT: {
    group: 1,
    normalCondition: {
      operator: '>',
      value: 8
    }
  },
  SCHIRMERS_TEST: {
    group: 1,
    normalCondition: {
      operator: '>',
      value: '10'
    },
    optional: true
  },
  NITBUT: {
    group: 1,
    normalCondition: {
      operator: '>',
      value: '8'
    },
    optional: true
  },
  LIPID_LAYER: {
    group: 1,
    normalCondition: ([value, ...rest]) => {
      return !value || value === 'grade0' || value > 89;
    },
    optional: true
  },
  MEIBOGRAPHY: {
    group: 1,
    normalCondition: ([value, ...rest]) => {
      return !value || value === 'grade0' || value < 25;
    },
    optional: true
  },
  EPITHELIAL_THICKNESS: {
    group: 1,
    normalCondition: {
      operator: '>',
      value: '60'
    },
    optional: true
  },
  BLINK: {
    group: 'look'
  },
  CORNEAL_FINDINGS: {
    group: 'look'
  },
  TEAR_MENISCUS_HEIGHT: {
    group: 'look',
    optional: true
  },
  TEAR_MENISCUS_HEIGHT_IMAGING: {
    group: 'look',
    optional: true
  },
  CORNEAL_STAINING_PATTERN: {
    group: 'look',
    normalCondition: {
      operator: '=',
      value: 'noStaining'
    }
  },
  CONJUNCTIVAL_CHALASIS: {
    group: 'look'
  },
  COLLARETTES: {
    group: 'look'
  },
  MG_ORIFICE_NOTCHES: {
    group: 'look'
  },
  OTHER: {
    group: 'look'
  },
  LID_REDNESS: {
    group: 'lift'
  },
  EYELID_TELANGIECTASIA: {
    group: 'lift'
  },
  MG_ORIFICE_CAPPED_GLANDS: {
    group: 'lift'
  },
  SUPERIOR_CONJUNCTIVA_CORNEAL_FINDINGS: {
    group: 'lift'
  },
  LID_APOSITION: {
    group: 'pull'
  },
  FLOPPY_EYELID_SYNDROME_GRADING: {
    group: 'pull'
  },
  BULBAR_REDNESS: {
    group: 'pull'
  },
  MGE_EXPRESSIBILITY: {
    group: 'push'
  }
};

export const clincalExamFriendlyNames = {
  BLINK: 'Blink',
  CORNEAL_FINDINGS: 'Corneal Findings',
  TEAR_MENISCUS_HEIGHT: 'Tear Meniscus Height',
  TEAR_MENISCUS_HEIGHT_IMAGING: 'Tear Meniscus Height Imaging',
  CORNEAL_STAINING_PATTERN: 'Corneal Staining Pattern',
  CONJUNCTIVAL_CHALASIS: 'Conjunctival Chalasis',
  COLLARETTES: 'Collarettes',
  MG_ORIFICE_NOTCHES: 'MG Orifice Notches',
  LID_REDNESS: 'Lid Redness',
  EYELID_TELANGIECTASIA: 'Eyelid Telangiectasia',
  MG_ORIFICE_CAPPED_GLANDS: 'MG Orifice Capped Glands',
  SUPERIOR_CONJUNCTIVA_CORNEAL_FINDINGS: 'Superior Conjunctiva Corneal Findings',
  LID_APOSITION: 'Lid Aposition',
  FLOPPY_EYELID_SYNDROME_GRADING: 'Floppy Eyelid Syndrome Grading',
  BULBAR_REDNESS: 'Bulbar Redness',
  MGE_EXPRESSIBILITY: 'MGE Expressibility',
  OTHER: 'Other'
};

export const preOperativeKeys = [
  'clHoliday',
  'clHolidayNotes',
  'eyeDropsUsedPrior',
  'eyeDropsUsedPriorNotes'
];

export const preopImpressionKeys = [
  'visuallySignificantOSD',
  'osdRelevance',
  'neuropathicPain',
  'neurotrophicCornea'
];

export const speedIIkey = 'speedii';

export const preopTreatmentKeys = ['treatmentsPlan'];

export const preopOSDSubtypes = {
  exposure: 'Exposure/Lid Malposition',
  dryEyeDisease: 'Dry Eye Disease',
  lumps: 'Lumps and Bumps',
  conjunctivitis: 'Conjunctivitis'
};

import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateClinicInput,
  CreateClinicMutation,
  Doctor,
  GetClinicQuery,
  GetClinicQueryVariables,
  ModelPatientFilterInput,
  UpdateClinicInput,
  UpdateClinicMutation
} from '../../../API';
import { getAllClinic, maxAWSItemLimit } from '../../../graphql/custom_queries';
import { createClinic, updateClinic } from '../../../graphql/mutations';
import { S3Object } from '../../shared/attachments/s3-attachment.service';
import { AppSyncService } from '../appsync.service';
import {
  CreateClinicSetupInput,
  CreateClinicSetupMutation,
  UpdateClinicSetupInput
} from './../../../API';
import { createClinicSetup } from './../../../graphql/mutations';
import { ErrorHandlerService } from './error-handler.service';
import { PatientList } from './patient.service';
import { StaffService } from './staff.service';

export type Clinic = CreateClinicMutation['createClinic'];
export type EMRIntegrationSettings = Clinic['emrIntegration'];

interface PatientsConnectionVariables {
  limit?: number;
  skipAssessments?: boolean;
  assessmentsLimit?: number;
  nextToken?: string;
  patientsSortDirection?: 'ASC' | 'DESC';
  patientsFilter?: ModelPatientFilterInput;
}

export type GetClinicNestedQueryVariables = GetClinicQueryVariables & PatientsConnectionVariables;

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  private readonly updateClinicWithClinicSetupMutation = `
  mutation UpdateClinicWithClinicSetup($updateClinicInput: UpdateClinicInput!, $updateClinicSetupInput: UpdateClinicSetupInput!){
    updateClinic(input: $updateClinicInput){
      id
      address
      province
      country
      city
      postalCode
      fax
      name
      phone
    }
    updateClinicSetup(input: $updateClinicSetupInput){
      id
    }
  }
`;

  private readonly getClinicStaffQuery = `
    query GetStaffForClinic($id: ID!) {
      getClinic(id: $id) {
        id
        doctors(limit: 20) {
          items {
            id
            firstName
            lastName
            email
            practitionerId
            createdAt
            updatedAt
            deactivated
            staffType
            clinic {
              id
              address
              province
              country
              city
              postalCode
              fax
              name
              phone
            }
          }
          nextToken
        }
      }
    }
  `;
  private readonly getClinicLogoQuery = `
    query GetClinicLogo($id: ID!) {
      getClinic(id: $id) {
        id
        logo {
          bucket
          key
          region
          fileName
        }
      }
    }
  `;

  private readonly getPatientsForClinicQuery = `
    query GetPatientsForClinic(
      $id: ID!,
      ${this.staffService.patientsConnectionVariables}
    ) {
      getClinic(id: $id) {
        id
        ${this.staffService.patientsConnection}
      }
    }
    ${this.staffService.patientFieldsForDisplayFragment}`;

  private readonly getClinicEMRSettingsQuery = `
    query GetEMRSettings($id: ID!) {
        getClinic(id: $id) {
          id
          emrIntegration {
            fax {
              enabled
              faxNumber
            }
          }
        }
      }
  `;

  constructor(
    private appSyncService: AppSyncService,
    private errorHandlerService: ErrorHandlerService,
    private staffService: StaffService
  ) {}

  public createClinic(input: CreateClinicInput): Observable<Clinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createClinic),
          variables: {
            input: input
          }
        })
      ),
      map((result: ApolloQueryResult<CreateClinicMutation>) => result.data.createClinic)
    );
  }

  public updateClinic(input: UpdateClinicInput): Observable<Clinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(updateClinic),
          variables: { input: input }
        })
      ),
      map((result: ApolloQueryResult<UpdateClinicMutation>) => result.data.updateClinic)
    );
  }

  public updateClinicWithClinicSetup(
    updateClinicInput: UpdateClinicInput,
    updateClinicSetupInput: UpdateClinicSetupInput
  ): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(this.updateClinicWithClinicSetupMutation),
          variables: { updateClinicInput, updateClinicSetupInput }
        })
      ),
      map((result: ApolloQueryResult<UpdateClinicMutation>) => result.data)
    );
  }

  public updateClinicAndCreateClinicSetup(
    updateClinicInput: UpdateClinicInput,
    createClinicSetupInput?: CreateClinicSetupInput
  ): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createClinicSetup),
          variables: { input: createClinicSetupInput }
        })
      ),
      mergeMap((createClinicSetupMutation: { data: CreateClinicSetupMutation }) =>
        this.updateClinic({
          ...updateClinicInput,
          clinicClinicSetupId: createClinicSetupMutation.data.createClinicSetup.id
        })
      )
    );
  }

  public getClinic(
    queryVariables: GetClinicNestedQueryVariables,
    query = getAllClinic,
    fetchPolicy: FetchPolicy = 'cache-first',
    watch: boolean = false
  ): Observable<Clinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const queryOptions = {
          query: gql(query),
          variables: queryVariables,
          fetchPolicy: fetchPolicy
        };
        return watch ? client.watchQuery(queryOptions) : client.query(queryOptions);
      }),
      filter((result: ApolloQueryResult<GetClinicQuery>) => !!result.data),
      map((result: ApolloQueryResult<GetClinicQuery>) => result.data.getClinic)
    );
  }

  public getClinicLogo(id: string): Observable<S3Object> {
    return this.getClinic({ id: id }, this.getClinicLogoQuery).pipe(
      map(clinic => (clinic ? clinic.logo : null))
    );
  }

  public getClinicStaff(
    id: string,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<Partial<Doctor>[]> {
    return this.getClinic(
      { id: id },
      this.getClinicStaffQuery,
      fetchPolicy,
      fetchPolicy === 'cache-and-network'
    ).pipe(
      map(clinicWithDoctors => {
        if (clinicWithDoctors.doctors.nextToken) {
          this.errorHandlerService.handleError(
            'There are more doctors than were loaded. Consider increasing query limit or implementing pagination.'
          );
        }
        return clinicWithDoctors.doctors.items;
      })
    );
  }

  public getEMRIntegrationSettings(clinicId: string): Observable<EMRIntegrationSettings | null> {
    return this.getClinic({ id: clinicId }, this.getClinicEMRSettingsQuery).pipe(
      map((clinic: Clinic) => clinic.emrIntegration)
    );
  }

  public getEMRIntegrationFaxNumber(clinicId: string): Observable<string | null> {
    return this.getEMRIntegrationSettings(clinicId).pipe(
      map((emrIntegration: EMRIntegrationSettings | null) => {
        if (emrIntegration && emrIntegration.fax && emrIntegration.fax.enabled) {
          return emrIntegration.fax.faxNumber;
        }
        return null;
      })
    );
  }

  public getLoggedInClinicCountry(): Observable<string> {
    return this.staffService
      .getLoggedInStaff()
      .pipe(map(doctor => (doctor.clinic ? doctor.clinic.country : null)));
  }

  public isClinicMissingInformation(clinic: Clinic | Doctor['clinic']): boolean {
    if (!clinic) {
      return true;
    }
    const requiredFields = [
      clinic.phone,
      clinic.name,
      clinic.address,
      clinic.country,
      clinic.postalCode,
      clinic.city,
      clinic.agreedToTermsOfService
    ];

    return (
      requiredFields.includes(null) ||
      requiredFields.includes(undefined) ||
      requiredFields.includes(false)
    );
  }

  public getPatientsForClinic(
    id: string,
    watch: boolean,
    assessmentsLimit: number,
    fetchPolicy: FetchPolicy,
    nextToken?: string,
    limit = Number.POSITIVE_INFINITY,
    patientsFilter?: ModelPatientFilterInput
  ): Observable<PatientList> {
    limit = limit > maxAWSItemLimit && limit !== Number.POSITIVE_INFINITY ? maxAWSItemLimit : limit;
    return this.getClinic(
      {
        id: id,
        assessmentsLimit: assessmentsLimit,
        skipAssessments: assessmentsLimit === 0,
        limit: limit === Number.POSITIVE_INFINITY ? maxAWSItemLimit : limit,
        nextToken: nextToken,
        patientsFilter: patientsFilter
      },
      this.getPatientsForClinicQuery,
      fetchPolicy,
      watch
    ).pipe(
      switchMap((clinic: Clinic) => {
        const nextNextToken: string = clinic.patients.nextToken;
        if (!!nextNextToken && limit === Number.POSITIVE_INFINITY) {
          return this.getPatientsForClinic(
            id,
            watch,
            assessmentsLimit,
            fetchPolicy,
            nextNextToken,
            limit,
            patientsFilter
          ).pipe(
            map((patients: PatientList) => {
              clinic.patients.items = [...clinic.patients.items, ...patients.items] as any;
              clinic.patients.nextToken = null;
              return clinic;
            })
          );
        } else {
          return of(clinic);
        }
      }),
      map((clinic: Clinic) => {
        return {
          items: clinic.patients.items,
          nextToken: clinic.patients.nextToken
        } as PatientList;
      })
    );
  }
}

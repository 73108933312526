import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClinicSetupService } from './../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { Location, Locations } from './location.model';
@Component({
  selector: 'csi-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.css']
})
export class LocationSelectComponent {
  @Input() locationControl: FormControl;

  private _locationOptions: Locations;
  @Input() set locationOptions(locations: Locations) {
    this._locationOptions = locations;

    if (locations.list.length === 0) {
      setTimeout(() => {
        this.locationControl.setValue(null);
        this.locationControl.disable();
      });
    } else {
      if (this.clinicSetupService.isClinicAdmin && this.locationControl.parent.enabled) {
        setTimeout(() => this.locationControl.enable());
      }
      this.initLocationOptions();
    }
  }

  @Output() locationChange = new EventEmitter();

  get locationOptions(): Locations {
    return this._locationOptions;
  }

  public locationOptions$ = new Observable<Location[]>();
  public typeahead = new Subject<string>();

  constructor(private clinicSetupService: ClinicSetupService) {}

  public onEnter(event: Event) {
    event.stopPropagation();
  }

  public onChange() {
    this.locationChange.emit();
  }

  initLocationOptions() {
    this.locationOptions$ = merge(
      this.typeahead.pipe(map(query => this.searchRegion(query))),
      of(this.locationOptions.list)
    );
  }

  private searchRegion(userInput: string | null): Location[] {
    if (!userInput || !userInput.trim()) {
      return this.locationOptions.list;
    }

    userInput = userInput.toLowerCase().trim();

    const matches = this.locationOptions.list.filter(location => {
      const synonymMatch = synonym => {
        if (synonym.toLowerCase().includes(userInput)) {
          return true;
        }
      };
      return (
        location.synonyms.some(synonymMatch) || location.name.toLowerCase().includes(userInput)
      );
    });

    matches.sort(
      (a, b) => a.name.toLowerCase().indexOf(userInput) - b.name.toLowerCase().indexOf(userInput)
    );
    return matches;
  }
}

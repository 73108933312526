import { Component, Input, OnInit } from '@angular/core';
import { FormTemplateInputFormGroup } from './../form-template-input-form-group';
import { formTemplateGenericSort, FormTemplateSchema } from './../form-template-model';
import { FormTemplateGeneratorService } from './form-template-generator.service';

@Component({
  selector: 'csi-form-template-generator',
  templateUrl: './form-template-generator.component.html',
  styleUrls: ['./form-template-generator.component.css']
})
export class FormTemplateGeneratorComponent implements OnInit {
  @Input() formTemplateSchema: FormTemplateSchema;
  @Input() formTemplateInputFormGroup: FormTemplateInputFormGroup;
  @Input() readOnly: boolean = false;
  @Input() formatForPDF: boolean = false;

  sortFormGroups = formTemplateGenericSort;

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    this.formTemplateGeneratorService.fieldsHiddenForPDFExportMap = {};
    if (this.readOnly || this.formatForPDF) {
      this.formTemplateInputFormGroup.disable();
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocationSelectService } from '../location-select.service';
import { Locations } from '../location.model';

@Component({
  selector: 'csi-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.css']
})
export class CountrySelectComponent {
  @Input() control: FormControl;
  @Output() locationChange = new EventEmitter();

  public readonly countries$: Observable<Locations> = this.locationSelectService.getCountries();

  onChange(value: string) {
    this.locationChange.emit();
  }

  constructor(private locationSelectService: LocationSelectService) {}
}

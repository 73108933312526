import { AfterViewInit, Directive } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[csiNgSelectHighlightFirst]'
})

/*
  See https://github.com/ng-select/ng-select/issues/1123
  When ng-select uses typeahead and <ng-option> elements, it does not call
  `itemsList.markSelectedOrDefault(hostNgSelectComponent.markFirst);`, and will not select
  the first item that gets produced in the dropdown.
 */
export class NgSelectHighlightFirstDirective implements AfterViewInit {
  constructor(private hostNgSelectComponent: NgSelectComponent) {}

  ngAfterViewInit() {
    // Code partly taken from items-list.ts' markSelectedOrDefault(markDefault? : boolean)
    // and ng-select.component.ts's _setItemsFromNgOptions()
    this.hostNgSelectComponent.ngOptions.changes
      .pipe(
        startWith(this.hostNgSelectComponent.ngOptions),
        takeUntil((this.hostNgSelectComponent as any)._destroy$)
      )
      .subscribe(() => {
        const itemsList = this.hostNgSelectComponent.itemsList as any;
        itemsList._markedIndex = itemsList.filteredItems.findIndex(option => !option.disabled);
      });
  }
}

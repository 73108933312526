import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

export class CustomValidator {
  static required(control: AbstractControl): ValidationErrors | null {
    return CustomValidator.customMessages(Validators.required, 'Required')(control);
  }

  static customMessages(validator: ValidatorFn, msg: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (validator(control)) {
        return { msg };
      }
      return null;
    };
  }

  static number(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return null; // don't validate empty values to allow required validator to handle them
      }
      const num = parseFloat(value);
      if (isNaN(num)) {
        return { message: 'Value must be a number' };
      }
      return null;
    };
  }

  static minRange(minRange: number): ValidatorFn {
    return CustomValidator.customMessages(
      Validators.min(minRange),
      `Value must be greater than this ${minRange}`
    );
  }

  static maxRange(maxRange: number): ValidatorFn {
    return CustomValidator.customMessages(
      Validators.min(maxRange),
      `Value must be greater than this ${maxRange}`
    );
  }

  static atLeastOneCheckBoxRequired(form: FormGroup): ValidationErrors | null {
    const checkbox = Object.values(form.controls);
    const isAnyChecked = checkbox.some(control => control.value);
    return isAnyChecked ? null : { atLeastOneRequired: true };
  }
  static rangeInBetween(minRange: number, maxRange: number): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const controlValue = parseInt((control.value + '').trim());
      if (isNaN(controlValue)) {
        return null;
      }
      if (!isNaN(controlValue)) {
        return controlValue >= minRange && controlValue <= maxRange
          ? null
          : { msg: `Invalid selection` };
      }
      return null;
    };
  }
}

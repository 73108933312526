import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SUPPORT_EMAIL_ACCESSOR } from '../../../core/domain-accessor';
import { phoneNumberAndCountryCodeValidator } from '../../shared-validators/phone-number-validator';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { DoctorFormGroup } from './doctor-form.model';
import { mfaValidator } from './select-mfa-method/mfa-validator';
import { type } from 'os';

@Component({
  selector: 'csi-doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.css']
})
export class DoctorFormComponent implements OnInit {
  @Input() doctorFormGroup: DoctorFormGroup;
  @Input() showRequiredFields = false;
  @Input() hideTitle = false;
  @Input() showEmail = false;
  @Input() showInCard = true;
  @Input() showStaffType = false;

  @Input() showPasswordChange = true;

  public dummyPasswordFormControl: FormControl = new FormControl({
    value: '********',
    disabled: true
  });
  public combinedCountryCodePhoneNumberControl: FormControl = new FormControl({
    value: 'N/A',
    disabled: true
  });

  constructor(
    @Inject(SUPPORT_EMAIL_ACCESSOR) public supportEmail: string,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.doctorFormGroup.setValidators([
      phoneNumberAndCountryCodeValidator('phone', 'countryCode'),
      mfaValidator
    ]);
    this.doctorFormGroup.updateValueAndValidity();

    if (this.doctorFormGroup.controls.phone.value) {
      this.combinedCountryCodePhoneNumberControl.setValue(
        this.doctorFormGroup.controls.countryCode.value + this.doctorFormGroup.controls.phone.value
      );
    }
  }

  onChangePassword() {
    this.dialog.open(ChangePasswordModalComponent);
  }
}

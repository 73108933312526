import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { Medication } from '../medication.model';
import { DryEyeSideEffectFlaggingService } from './dry-eye-side-effect-flagging.service';

@Pipe({
  name: 'hasDryEyeSideEffect'
})
@Injectable({
  providedIn: 'root'
})
export class HasDryEyeSideEffect implements PipeTransform {
  constructor(private service: DryEyeSideEffectFlaggingService) {}

  transform(medication: Medication | Medication[]): Observable<boolean> {
    if (Array.isArray(medication)) {
      if (medication.length <= 0) {
        return of(false);
      }
      return from(medication as Medication[]).pipe(
        mergeMap(medicine => this.transform(medicine)),
        first(causesDryEyes => causesDryEyes, false)
      );
    } else {
      return this.service.canMedicationCauseDryEyes(medication as Medication);
    }
  }
}

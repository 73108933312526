// tslint:disable
// add manually written custom queries that amplify codegen does not generate here

// This cannot be larger than 1000, see https://github.com/aws-amplify/amplify-cli/issues/1496
export const maxAWSItemLimit = 1000;
export const getAssessmentRequestWithDoctor = `query GetAssessmentRequestWithDoctor($id: ID!) {
  getAssessmentRequest(id: $id) {
    id
    type
    doctor {
      id
      firstName
      lastName
      email
      emailVerified
      cognitoGroups
      practitionerId
      clinic {
        id
      }
    }
    recipient {
      id
      firstName
      lastName
      email
      emailVerified
      practitionerId
    }
    assessment {
      id
      patient {
        id
      }
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        practitionerId
        clinic {
          id
        }
      }
      type
      body
      attachments {
        bucket
        key
        region
        fileName
      }
    }
    response
    createdAt
    updatedAt
  }
}
`;

export const getAllInfo = /* GraphQL */ `
  query GetAllInfo {
    me {
      id
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      staffType
      allowMultipleClinics
      ownedClinics
      clinic {
        id
        name
        address
        province
        country
        postalCode
        city
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
        }
        doctors {
          items {
            id
            email
            practitionerId
            firstName
            lastName
          }
          nextToken
        }
        emrIntegration {
          fax {
            enabled
            faxNumber
          }
        }
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          logo {
            bucket
            key
            region
            fileName
          }
          referralForms {
            name
            file {
              bucket
              key
              region
              fileName
            }
            type
          }
          agreement
          educationEmbedLink
          advancedReferralCenter
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          logo {
            bucket
            key
            region
            fileName
          }
          referralForms {
            name
            file {
              bucket
              key
              region
              fileName
            }
            type
          }
          agreement
          educationEmbedLink
          advancedReferralCenter
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          createdAt
          updatedAt
          layouts {
            id
            name
            alwaysExpand
            signsLayout {
              show
              hide
            }
            medicalHistoryLayout {
              show
              hide
            }
            symptomSettings
            customLayout
          }
          hiddenTextFavorites
          hiddenTreatmentTemplates
          interactionsSetup
          defaultQuestionnaireLanguage
          textFavoriteConfig
          defaultPdfLanguage
          temperatureUnit
          welcomeMessageConfig
          emailNotificationsConfig
          questionnaireExpiry
          questionnaireScoreConfig
          questionnaireNotificationsConfig
        }
        clinicFormTemplates {
          items {
            id
            schema
            formTemplateClinicId
            createdAt
            updatedAt
          }
          nextToken
        }
        agreedToTermsOfService
        createdAt
        updatedAt
      }
      cognitoGroups
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          dateOfBirth
          gender
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
        }
        nextToken
      }
      practitionerId
      assessments {
        items {
          id
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      assessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      inboundAssessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const lockAssessment = `mutation LockAssessment($input: UpdateAssessmentInput!) {
  updateAssessment(input: $input) {
    id
    locked
  }
}`;

export const medicationLookup = `query MedicationLookupCustom($query: String!) {
  searchMedications(query: $query) {
    products {
      _id
      ndc_product_codes
      dpd_codes
      ema_product_codes
      name
      rx_norm_prescribable_name
      country
      route
      dosage_form
      ingredients {
        drugbank_id
        name
        strength {
          number
          unit
        }
      }
    }
  }
}
`;

export const getQuestionnaireRequestWithResponse = `
  query getQuestionnaireRequestWithResponseAndCompletedAt($id: ID!) {
    getQuestionnaireRequest(id: $id) {
      completedAt
      response {
        answers
        scores
      }
      config
    }
  }
`;

export const getAllClinic = /* GraphQL */ `
  query GetAllClinic($id: ID!) {
    getClinic(id: $id) {
      id
      name
      address
      province
      country
      city
      postalCode
      phone
      fax
      logo {
        bucket
        key
        region
        fileName
      }
      doctors {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
      }
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          dateOfBirth
          gender
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
        }
        nextToken
      }
      emrIntegration {
        fax {
          enabled
          faxNumber
        }
      }
      clinicSetup {
        id
        clinicSetupClinicId
        createdAt
        updatedAt
        layouts {
          id
          name
          alwaysExpand
          signsLayout {
            show
            hide
          }
          medicalHistoryLayout {
            show
            hide
          }
          symptomSettings
        }
        defaultQuestionnaireLanguage
        defaultPdfLanguage
        welcomeMessageConfig
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAllAssessmentInfo = /* GraphQL */ `
  query GetAllAssessmentInfo($id: ID!) {
    getAssessment(id: $id) {
      id
      doctor {
        id
      }
      clinic {
        id
      }
      patient {
        id
      }
      assessmentClinicId
      type
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      locked
      createdAt
      updatedAt
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          config
          response {
            answers
            scores
            consent {
              consentsToPrivacyForm
              consentsToResearch
              agreedToTerms
              consentSource
            }
          }
        }
        nextToken
      }
    }
  }
`;

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[csiCopyToClipboard]',
  exportAs: 'csiCopyToClipboard'
})
export class CopyToClipboardDirective implements OnInit {
  @Input() copyToClipboardSuccessMessage = 'Copied to Clipboard!';

  constructor(private elementRef: ElementRef, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.enableUserSelect();
  }

  copyToClipboard() {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(this.elementRef.nativeElement);
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');

    this.showSnackbar();
  }

  showSnackbar() {
    if (!!this.copyToClipboardSuccessMessage) {
      this.snackBar.open(this.copyToClipboardSuccessMessage, '', {
        duration: 2000
      });
    }
  }

  private enableUserSelect() {
    this.elementRef.nativeElement.style.userSelect = 'all';
  }
}

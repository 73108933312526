import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { NumericSymptom } from '../symptom.model';

@Component({
  selector: 'csi-numeric-symptom-control',
  templateUrl: './numeric-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './numeric-symptom-control.component.css'
  ],
  providers: [
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => NumericSymptomControlComponent)
    }
  ]
})
export class NumericSymptomControlComponent extends AbstractSymptomControlComponent
  implements OnInit {
  @Input() symptom: NumericSymptom;
  @Input() formKey: string;
  @Input() formGroup: DryEyeFormGroup;

  public showRequiredStar;

  constructor(protected symptomValidatorsService: SymptomValidatorsService) {
    super();
  }

  ngOnInit() {
    if (this.symptom.min || this.symptom.max) {
      setTimeout(() => {
        if (this.formGroup.controls[this.formKey]) {
          this.formGroup.controls[this.formKey].setValidators(
            [
              this.symptom.min !== null && this.symptom.min !== undefined
                ? Validators.min(this.symptom.min)
                : null,
              this.symptom.max !== null && this.symptom.max !== undefined
                ? Validators.max(this.symptom.max)
                : null
            ].filter(minmax => minmax)
          );
          this.formGroup.controls[this.formKey].updateValueAndValidity();
        }
      });
    }
  }

  public isErrorBeingDisplayed() {
    return (
      this.formGroup.get(this.formKey).invalid &&
      (this.formGroup.get(this.formKey).touched || this.formGroup.submitted)
    );
  }

  get hasValue(): boolean {
    return !this.isNullOrUndefined(this.value) && !isNaN(parseFloat(this.value));
  }

  get shouldShowThermalScale(): boolean {
    return this.symptom.thermalScale && this.hasValue;
  }

  get step(): number {
    return this.isNullOrUndefined(this.symptom.step) ? 1.0 : this.symptom.step;
  }

  get min(): number {
    return this.isNullOrUndefined(this.symptom.min) ? 0.0 : this.symptom.min;
  }

  get max(): number {
    return this.isNullOrUndefined(this.symptom.max) ? Number.MAX_VALUE : this.symptom.max;
  }

  private setRequired() {
    const formControl = this.formGroup.get(this.formKey);
    this.showRequiredStar =
      !this.symptomValidatorsService.isOptionalFormControl(formControl) && this.symptom.name;
  }

  private isNullOrUndefined(value: number) {
    return value === null || value === undefined;
  }
}

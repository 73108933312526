import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  darkerAfterSunExposure,
  eyeColor,
  faceSunSensitivity,
  freckles,
  longSunExposure,
  naturalHairColor,
  skinColor
} from 'src/app/form-template/form-field-template-library';
import { ConfigType } from 'src/app/form-template/form-template-model';
import { DerfsService } from './../../../questionnaires/derfs/derfs.service';
import { MatCardQuestionDisplay } from './patient-file.model';

@Injectable({
  providedIn: 'root'
})
export class PatientFileService {
  constructor(public derfsService: DerfsService) {}

  public patientFileSchema: { [key: string]: MatCardQuestionDisplay } = {
    gender: {
      title: 'Gender',
      controls: Object.keys(this.derfsService.genderList),
      config: {
        type: ConfigType.checkbox,
        schema: this.convertDerfsCheckboxListToschema(this.derfsService.genderList)
      }
    },
    childBirth: {
      title: 'Given birth to child',
      controls: ['childBirth'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'childBirth',
          radioMap: this.derfsService.defaultRadioMap
        }
      },
      displayCondition: { controlKey: 'female', displayOnValues: [false, null] }
    },
    eyeColor: {
      title: 'Eye color',
      controls: ['eyeColor'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'eyeColor',
          radioMap: Object.entries(eyeColor.typeConfig.valuesConfig).reduce((acc, [key, value]) => {
            return { ...acc, [key]: value.label };
          }, {})
        }
      }
    },
    longSunExposure: {
      title: 'Skin reaction after long sun exposure',
      controls: ['longSunExposure'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'longSunExposure',
          radioMap: Object.entries(longSunExposure.typeConfig.valuesConfig).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.label };
            },
            {}
          )
        }
      }
    },
    naturalHairColor: {
      title: 'Natural hair color',
      controls: ['naturalHairColor'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'naturalHairColor',
          radioMap: Object.entries(naturalHairColor.typeConfig.valuesConfig).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.label };
            },
            {}
          )
        }
      }
    },
    darkerAfterSunExposure: {
      title: 'Skin tan after sun exposure',
      controls: ['darkerAfterSunExposure'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'darkerAfterSunExposure',
          radioMap: Object.entries(darkerAfterSunExposure.typeConfig.valuesConfig).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.label };
            },
            {}
          )
        }
      }
    },
    skinColor: {
      title: 'Skin color',
      controls: ['skinColor'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'skinColor',
          radioMap: Object.entries(skinColor.typeConfig.valuesConfig).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.label };
            },
            {}
          )
        }
      }
    },
    faceSunSensitivity: {
      title: 'Face sensitivity to sun',
      controls: ['faceSunSensitivity'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'faceSunSensitivity',
          radioMap: Object.entries(faceSunSensitivity.typeConfig.valuesConfig).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.label };
            },
            {}
          )
        }
      }
    },
    freckles: {
      title: 'Quantity of freckles on face',
      controls: ['freckles'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'freckles',
          radioMap: Object.entries(freckles.typeConfig.valuesConfig).reduce((acc, [key, value]) => {
            return { ...acc, [key]: value.label };
          }, {})
        }
      }
    },
    ethnicity: {
      title: 'Ethnicity',
      controls: ['ethnicity', 'ethnicityOtherValue'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'ethnicity',
          radioMap: this.derfsService.ethnicityRadioMap,
          toggleableFieldConfig: {
            fieldType: 'textarea',
            formControlKey: 'ethnicityOtherValue',
            triggeredByValue: 'others',
            required: true
          }
        }
      }
    },
    education: {
      title: 'Education level',
      controls: ['education'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'education',
          radioMap: this.derfsService.educationRadioMap
        }
      }
    },
    familyHistory: {
      title: 'Family history',
      controls: Object.keys(this.derfsService.familyHistoryConditionsList),
      config: {
        type: ConfigType.checkbox,
        schema: this.convertDerfsCheckboxListToschema(this.derfsService.familyHistoryConditionsList)
      }
    },
    eyelashModification: {
      title: 'Used eyelash growth serum or eyelash extensions',
      controls: ['eyelashModification', 'eyelashModificationBrands'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'eyelashModification',
          radioMap: this.derfsService.defaultRadioMap,
          toggleableFieldConfig: {
            fieldType: 'textarea',
            fieldTitle: 'Brands',
            formControlKey: 'eyelashModificationBrands',
            triggeredByValue: 'yes',
            required: true
          }
        }
      }
    },
    sickBuildingSyndrome: {
      title: 'Diagnosed with Sick building syndrome in the past',
      controls: ['sickBuildingSyndrome'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'sickBuildingSyndrome',
          radioMap: this.derfsService.sickBuildingSyndromeRadioMap
        }
      }
    },
    hematopoieticStemCellTransplantation: {
      title: 'Transplanted hematopoietic stem cells',
      controls: ['hematopoieticStemCellTransplantation'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'hematopoieticStemCellTransplantation',
          radioMap: this.derfsService.defaultRadioMap
        }
      }
    },
    styeOrChalazion: {
      title: 'Has had a stye/chalazion in the past',
      controls: ['styeOrChalazion'],
      config: {
        type: ConfigType.radio,
        schema: {
          formControlKey: 'styeOrChalazion',
          radioMap: this.derfsService.styeOrChalazionRadioMap
        }
      }
    },
    pastOcularHistory: {
      title: 'Past ocular history',
      controls: Object.keys(this.derfsService.pastOcularHistoryList),
      config: {
        type: ConfigType.checkbox,
        schema: this.convertDerfsCheckboxListToschema(this.derfsService.pastOcularHistoryList)
      }
    },
    pastSurgeries: {
      title: 'Past surgeries',
      controls: Object.keys(this.derfsService.surgeriesList),
      config: {
        type: ConfigType.checkbox,
        schema: this.convertDerfsCheckboxListToschema(this.derfsService.surgeriesList)
      }
    },
    medicalConditions: {
      title: 'Medical conditions',
      controls: Object.keys(this.derfsService.conditionsList),
      config: {
        type: ConfigType.checkbox,
        schema: this.convertDerfsCheckboxListToschema(this.derfsService.conditionsList)
      }
    }
  };

  public readonly flattenedPatientFileSchema = this.buildflattenedPatientFileSchema();

  public buildflattenedPatientFileSchema() {
    let flattenedSchema = {};
    Object.keys(this.patientFileSchema).forEach(key => {
      if (this.patientFileSchema[key].config.type === ConfigType.checkbox) {
        Object.keys(this.patientFileSchema[key].config.schema).forEach(checkboxKey => {
          flattenedSchema = {
            ...flattenedSchema,
            ...{ [checkboxKey]: this.patientFileSchema[key].config.schema[checkboxKey] }
          };
        });
      } else {
        flattenedSchema = { ...flattenedSchema, ...{ [key]: this.patientFileSchema[key] } };
      }
    });
    return flattenedSchema;
  }

  buildFormGroup() {
    const tempFormGroup = new FormGroup({});
    Object.keys(this.patientFileSchema).forEach(patientFileKey => {
      const cardConfig = this.patientFileSchema[patientFileKey];
      const fieldConfig = cardConfig.config;
      if (cardConfig.controls) {
        for (const controlKey of cardConfig.controls) {
          if (
            fieldConfig.schema &&
            fieldConfig.schema.toggleableFieldConfig &&
            fieldConfig.schema.toggleableFieldConfig.formControlKey &&
            fieldConfig.schema.toggleableFieldConfig.formControlKey === controlKey
          ) {
            const toggleableFieldConfig = fieldConfig.schema.toggleableFieldConfig;
            tempFormGroup.addControl(
              controlKey,
              new FormControl(
                this.getDefaultValue(cardConfig),
                toggleableFieldConfig.required ? [Validators.required] : []
              )
            );
          } else {
            tempFormGroup.addControl(controlKey, new FormControl(this.getDefaultValue(cardConfig)));
          }
          if (
            fieldConfig.schema &&
            fieldConfig.schema[controlKey] &&
            fieldConfig.schema[controlKey].toggleableField
          ) {
            const required = fieldConfig.schema[controlKey].required;
            tempFormGroup.addControl(
              controlKey + 'Value',
              new FormControl('', required ? [Validators.required] : [])
            );
          }
        }
      }
    });
    return tempFormGroup;
  }

  getDefaultValue(fieldConfig: MatCardQuestionDisplay) {
    switch (fieldConfig.config.type) {
      case ConfigType.radio:
        return null;
      case ConfigType.checkbox:
        return false;
      case ConfigType.slider:
        return -1;
      default:
        return null;
    }
  }

  public convertDerfsCheckboxListToschema(list: any) {
    return Object.keys(list).reduce((acc, key) => {
      if (list[key].nonModifiableConfig && list[key].nonModifiableConfig.nonModifiable) {
        return {
          ...acc,
          [key]: {
            isCheckbox: true, // For autopopulate purposes
            description: list[key].description,
            toggleableField: list[key].inputType ? true : false,
            required: list[key].required ? list[key].required : false
          }
        };
      } else {
        return acc;
      }
    }, {});
  }

  public transformPastOcularHistoryValue(
    currentMedicalHistoryValue: any,
    nonModifiableValue: any,
    appendValue: boolean
  ) {
    if (appendValue) {
      return currentMedicalHistoryValue
        ? currentMedicalHistoryValue + '\n' + nonModifiableValue
        : nonModifiableValue;
    }
    return currentMedicalHistoryValue;
  }
}

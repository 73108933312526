import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { SignsAttachmentsDialogData } from './signs-attachments-schema';
import { SignsAttachmentsService } from './signs-attachments.service';

@Component({
  selector: 'csi-signs-attachments-modal',
  templateUrl: './signs-attachments-modal.component.html',
  styleUrls: ['./signs-attachments-modal.component.css']
})
export class SignsAttachmentsModalComponent implements OnInit {
  public signToFriendlyNameMap = this.signsAttachmentsService.signToFriendlyNameMap;
  public selectedTabIndex = 0;

  constructor(
    private signsAttachmentsService: SignsAttachmentsService,
    @Inject(MAT_DIALOG_DATA) public data: SignsAttachmentsDialogData,
    public dialogRef: MatDialogRef<SignsAttachmentsModalComponent>,
    public loadingSpinnerService: LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.selectedTabIndex = this.data.selectedSignIndex;
  }

  public static open(matDialog: MatDialog, selectedSignIndex: number) {
    return matDialog.open<SignsAttachmentsModalComponent, SignsAttachmentsDialogData>(
      SignsAttachmentsModalComponent,
      {
        width: '1200px',
        maxWidth: '95vw',
        data: {
          selectedSignIndex
        }
      }
    );
  }

  public onSave() {
    this.loadingSpinnerService.show();
    this.signsAttachmentsService.updateAttachments();
    this.signsAttachmentsService.saveFn().subscribe(assessment => {
      this.loadingSpinnerService.hide();
    });
  }
}

export enum DryEyeSeverity {
  Mild = 'mild',
  Moderate = 'moderate',
  Severe = 'severe'
}

export enum DryEyeType {
  Aqueous = 'aqueous',
  Mixed = 'mixed',
  Evaporative = 'evaporative'
}

export interface DryEyeCategory {
  type: DryEyeType;
  severity: DryEyeSeverity;
}

export function isDryEyeCategoryEqual(value1: DryEyeCategory, value2: DryEyeCategory): boolean {
  return value1.severity === value2.severity && value1.type === value2.type;
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { WebcamImage } from 'ngx-webcam';
import { WebcamModalComponent } from './../webcam-modal/webcam-modal.component';

@Component({
  selector: 'csi-webcam-button',
  templateUrl: './webcam-button.component.html',
  styleUrls: ['./webcam-button.component.css']
})
export class WebcamButtonComponent implements OnInit {
  @Output() public imageCapture: EventEmitter<WebcamImage> = new EventEmitter<WebcamImage>();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openModal() {
    const dialogRef = this.dialog.open(WebcamModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.imageCapture.emit(result);
      }
    });
  }
}

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Referral } from './../../../API';

interface ReferralMedicalHistoryFormControls {
  medicalHistory: FormControl;
  medications: FormControl;
  drugAllergies: FormControl;
  seasonalAllergies: FormControl;
  clTimeBeforeExam: FormControl;
  contactLenses: FormControl;
  prisms: FormControl;
  eyeDominance: FormControl;
}
interface ResponseFormControls {
  comment: FormControl;
  // , comment: new FormControl(null, [Validators.required]),
  attachments: FormControl;
}

interface ReferralAssessmentFormControls {
  examFindings: FormControl;
  attachments: FormControl;
  impressions: FormControl;
  recommendedPlanByDoctor: FormControl;
}

interface ReferralFormControls {
  diseaseArea: FormControl;
  otherDiseaseArea: FormControl;
  priority: FormControl;
  informationEntryMethod: FormControl;
  patient: FormControl;
  clinic: FormControl;
  doctor: FormControl;
  medicalHistory: ReferralMedicalHistoryFormGroup;
  assessment: ReferralAssessmentFormGroup;
  referralResponses: ResponseFormGroup;
  signs: FormGroup;
  referralLetters: FormControl;
  status: FormControl;
  comment: FormControl;
  updateNotificationRecipients: FormControl;
  receiveUpdateNotifications: FormControl;
  createdAt: FormControl;
  feedbackHistory: FormControl;
  parentReferralId: FormControl;
  type: FormControl;
}

export enum InformationEntryMethod {
  Attachment = 'attachment',
  Manual = 'manual'
}

export class ReferralMedicalHistoryFormGroup extends FormGroup {
  controls: ReferralMedicalHistoryFormControls & { [key: string]: AbstractControl };

  constructor() {
    super({
      medicalHistory: new FormControl('', Validators.required),
      medications: new FormControl('', Validators.required),
      drugAllergies: new FormControl('', Validators.required),
      seasonalAllergies: new FormControl('', Validators.required),
      clTimeBeforeExam: new FormControl('', Validators.required),
      contactLenses: new FormControl('', Validators.required),
      prisms: new FormControl('', Validators.required),
      eyeDominance: new FormControl('', Validators.required)
    });
  }
}

export class ResponseFormGroup extends FormGroup {
  controls: ResponseFormControls & { [key: string]: AbstractControl };
  constructor() {
    super({
      comment: new FormControl(null, [Validators.required]),
      attachments: new FormControl(null, [Validators.required])
    });
  }
}

export class ReferralAssessmentFormGroup extends FormGroup {
  controls: ReferralAssessmentFormControls & { [key: string]: AbstractControl };

  constructor() {
    super({
      examFindings: new FormControl(''),
      attachments: new FormControl([]),
      impressions: new FormControl('', Validators.required),
      recommendedPlanByDoctor: new FormControl('')
    });
  }
}

export class ReferralFormGroup extends FormGroup {
  controls: ReferralFormControls & { [key: string]: AbstractControl };

  constructor() {
    super({
      diseaseArea: new FormControl(),
      otherDiseaseArea: new FormControl(),
      priority: new FormControl(),
      informationEntryMethod: new FormControl(),
      patient: new FormControl(),
      clinic: new FormControl(),
      doctor: new FormControl(),
      medicalHistory: new ReferralMedicalHistoryFormGroup(),
      assessment: new ReferralAssessmentFormGroup(),
      referralResponses: new ResponseFormGroup(),
      referralResponseHistory: new FormControl(),
      referralLetters: new FormControl([], [Validators.required]),
      status: new FormControl('New Referral', [Validators.required]),
      comment: new FormControl(),
      updateNotificationRecipients: new FormControl([]),
      receiveUpdateNotifications: new FormControl(),
      createdAt: new FormControl(),
      feedbackHistory: new FormControl(),
      signs: new FormGroup({}),
      parentReferralId: new FormControl(),
      type: new FormControl()
    });
  }

  patchFromReferral(referral: Referral) {
    this.patchValue(JSON.parse(referral.body));
    this.patchValue(referral);
    if (referral.attachments) {
      this.getAttachmentControl().setValue(referral.attachments);
    }
  }

  getAttachmentControl() {
    if (this.controls.informationEntryMethod.value === InformationEntryMethod.Attachment) {
      return this.controls.referralLetters;
    } else if (this.controls.informationEntryMethod.value === InformationEntryMethod.Manual) {
      return this.controls.assessment.controls.attachments;
    }
  }
}

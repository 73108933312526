import { Interaction } from 'src/API';
import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';

@Component({
  selector: 'csi-interaction-table',
  templateUrl: './interaction-table.component.html',
  styleUrls: ['./interaction-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void' ,style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InteractionTableComponent implements OnInit {
  @Input() dataSource: Interaction[];
  @Input() columnsKeys: string[];
  @Input() noDefaultColumns: boolean;
  public interactionsHeaderKey: string[] = [];
  public statuses = this.clinicSetupService.interactionsSetup.statuses;
  public columnNameMap = {
    type: 'Type',
    status: 'Status',
    comments: 'Comments',
    createdAt: 'Last Interaction',
    updatedAt: 'Updated',
    // action:'Action',
    surgeryType: 'Surgery Type',
    appointmentDate: 'Appointment',
    attemptFailed: 'Attempt Failed',
    color:'color',
    communicationMethod:"Communication"
  }
  public expandedElement: Interaction | null;
  constructor(private clinicSetupService: ClinicSetupService) { }

  ngOnInit() {
    if (!this.noDefaultColumns) {
      this.columnsKeys.push('color');
    }

  }

}

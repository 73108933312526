import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatRadioChange } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { QuestionnaireRequest } from 'src/app/core/api/questionnaire-request.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { DryEyePredictionService } from '../dry-eye-prediction.service';
import { Assessment, QuestionnaireType, TextFavoriteType } from './../../../../../API';
import {
  LEFT_EYE_KEY_PREFIX,
  RIGHT_EYE_KEY_PREFIX,
  SYMPTOM_METHOD_KEY_SUFFIX
} from './../../../form.model';
import { SchemaService } from './../../../symptoms/services/schema/schema.service';
import { SymptomModes } from './../../../symptoms/symptom.model';
import { DryEyeFormGroup } from './../dry-eye-form/dry-eye-form.model';
import { DryEyeExpansionPanel, DryEyeFormsService } from './../dry-eye-forms.service';
import { PreOpAnalysisService } from './pre-op-analysis/pre-op-analysis.service';
import { PreOpReportComponent } from './pre-op-report/pre-op-report.component';
import {
  AnalysisStateItem,
  KeratometryMeasurements,
  Operator,
  preOperativeKeys,
  PreOPExpansionPanel,
  preopImpressionKeys,
  preopOSDSubtypes,
  preopTreatmentKeys,
  speedIIkey
} from './pre-op.model';
import { PreOpService } from './pre-op.service';

@Component({
  selector: 'csi-pre-op',
  templateUrl: './pre-op.component.html',
  styleUrls: ['./pre-op.component.scss']
})
export class PreOpComponent implements OnInit, AfterViewInit {
  @ViewChild('firstExpansionPanel', { static: false, read: ElementRef })
  private firstExpansionPanel: { nativeElement: { offsetParent: any } };
  public readonly SymptomModes = SymptomModes;
  public dryEyePrediction: string;
  public requiredErrors: string[];
  public normalizedErrors: string[];
  public TextFavoriteType = TextFavoriteType;

  @Input() preOpFormGroup: DryEyeFormGroup;
  @Input() assessmentFormGroup: FormGroup;
  @Input() assessment: Assessment;
  @Input() expansionStateFormControl: FormControl;
  @Input() showImpressionsWarning$: Observable<boolean>;
  @Input() enabledStateFormControl: FormControl;
  @Input() onQuestionnaireLinked$: Subject<QuestionnaireRequest[]>;

  @Output() sendQuestionnaire = new EventEmitter<QuestionnaireType>();

  showFloatingAnalysis = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const offsetParent = this.firstExpansionPanel.nativeElement.offsetParent;
    if (offsetParent) {
      this.showFloatingAnalysis =
        offsetParent.offsetTop - 100 <= event.target['scrollingElement'].scrollTop;
    }
  }

  public readonly PreOPExpansionPanel = PreOPExpansionPanel;
  public readonly DryEyeExpansionPanel = DryEyeExpansionPanel;
  public keratometryFormGroup = new FormGroup({});
  public showQuestionnaireRequestButton: boolean;
  public showSendQuestionnaireButton: boolean;
  public safeToProceed: boolean;
  public visuallySignificantOSD: Boolean = false;
  public isFormGroupValid: boolean;

  public preopOSDSubtypes = preopOSDSubtypes;

  public numberOfNotifiers: number;

  public matSpinnerOptions: MatProgressButtonOptions = {
    text: 'Predict Dry Eye Type And Severity',
    active: false,
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  public KeratometryMeasurements = KeratometryMeasurements;
  public readonly RIGHT_EYE_KEY_PREFIX = RIGHT_EYE_KEY_PREFIX;
  public readonly LEFT_EYE_KEY_PREFIX = LEFT_EYE_KEY_PREFIX;

  public eyePrefixList = [RIGHT_EYE_KEY_PREFIX, LEFT_EYE_KEY_PREFIX];

  public readonly SPEED = QuestionnaireType.SPEED;
  public readonly SPEEDII = QuestionnaireType.SPEEDII;
  public showOptionalNonInvasiveTest: boolean;

  public impressionsFormGroup = new FormGroup({});

  public preOperativeKeratometryKeys = this.layoutsService.allLayoutsById[SymptomModes.PreOp]
    .customLayout['keratometry'].show;

  constructor(
    public schemaService: SchemaService,
    public dryEyeFormsService: DryEyeFormsService,
    public preOpService: PreOpService,
    public preOpAnalysisService: PreOpAnalysisService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private dryEyePredictionService: DryEyePredictionService,
    private layoutsService: LayoutsService,
    public staffService: StaffService
  ) {
    this.showOptionalNonInvasiveTest = false;
  }

  ngOnInit() {
    this.addControlsToPreOpFormGroupFromArray([speedIIkey]);
    this.addControlsToPreOpFormGroupFromArray(preOperativeKeys);
    this.addControlsToPreOpFormGroupFromArray(preopImpressionKeys, true, this.impressionsFormGroup);

    this.addControlsToPreOpFormGroupFromArray([
      ...Object.keys(preopOSDSubtypes),
      ...Object.keys(preopOSDSubtypes).map(key => key + '-description')
    ]);

    this.addControlsToPreOpFormGroupFromArray(preopTreatmentKeys);

    this.addKeratometryControls();

    this.expansionStateFormControl.value
      ? (this.preOpService.expansionMap = this.expansionStateFormControl.value)
      : this.preOpService.stateMapsInit();

    this.enabledStateFormControl.value
      ? (this.preOpService.enabledMap = this.enabledStateFormControl.value)
      : this.preOpService.stateMapsInit();
  }

  ngAfterViewInit(): void {
    this.handlePreOpValueChanges();
    this.handleKeratometryValueChanges();

    setTimeout(() => {
      setTimeout(() => {
        this.disablePreOpControlsFromKeys(preopImpressionKeys, ['osdRelevance']);
      });
    });
  }

  calculateNumberOfNotifiers() {
    this.numberOfNotifiers = 0;
    Object.values(this.preOpService.analysisState).forEach(
      (analysisStateItem: AnalysisStateItem) => {
        if (analysisStateItem.abnormal) {
          this.numberOfNotifiers++;
        }
      }
    );
  }

  syncNewControlWithOriginal(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([signKey, formControl]: [string, FormControl]) => {
      if (!this.preOpFormGroup.controls[signKey]) {
        this.preOpFormGroup.addControl(signKey, new FormControl(null, [Validators.required]));
      } else {
        formControl.setValue(this.preOpFormGroup.controls[signKey].value);
      }

      formControl.valueChanges.subscribe(value => {
        this.preOpFormGroup.controls[signKey].setValue(value);
      });
    });
  }

  public calculateAstigmatismValue(
    steepkControl: FormControl,
    flatkControl: FormControl,
    eyePrefix: string,
    keratometryKey: string
  ) {
    let value = 0;
    if (steepkControl && steepkControl.value && flatkControl && flatkControl.value) {
      value = Math.abs(steepkControl.value - flatkControl.value);
    }

    if (value) {
      if (eyePrefix === RIGHT_EYE_KEY_PREFIX) {
        this.preOpService.preOperativeKeratometryValues[keratometryKey].right.diopter = value;
      } else {
        this.preOpService.preOperativeKeratometryValues[keratometryKey].left.diopter = value;
      }
    }
    this.calculateKeratometryAbnormality();
    return value;
  }

  public calculateAstigmatismClassification(
    steepDegreeControl: FormControl,
    flatDegreeControl: FormControl,
    eyePrefix: string,
    keratometryKey: string
  ) {
    let classification = '';

    if (flatDegreeControl && flatDegreeControl.value) {
      if (flatDegreeControl.value <= 29 || flatDegreeControl.value >= 151) classification = 'WTR';
      if (flatDegreeControl.value >= 30 && flatDegreeControl.value <= 60) {
        classification = 'Oblique';
      }
      if (flatDegreeControl.value >= 61 && flatDegreeControl.value <= 120) classification = 'ATR';
      if (flatDegreeControl.value >= 121 && flatDegreeControl.value <= 150) {
        classification = 'Oblique';
      }
    }
    if (
      steepDegreeControl &&
      steepDegreeControl.value &&
      flatDegreeControl &&
      flatDegreeControl.value &&
      Math.abs(steepDegreeControl.value - flatDegreeControl.value) !== 90
    ) {
      classification = 'Irregular';
    }

    if (eyePrefix === RIGHT_EYE_KEY_PREFIX) {
      this.preOpService.preOperativeKeratometryValues[
        keratometryKey
      ].right.classification = classification;
    } else {
      this.preOpService.preOperativeKeratometryValues[
        keratometryKey
      ].left.classification = classification;
    }
    this.calculateKeratometryAbnormality();
    return classification;
  }

  handleAbnormalityOfSigns() {
    this.preOpService.isOSDFactorsAbnormal.signs = this.isAnyValueNotNormal(this.preOpFormGroup);
    this.preOpService.checkForAbnormality();
    this.preOpService.checkForOSDLikeliness();
  }

  isAnyValueNotNormal(formGroup: FormGroup): boolean {
    return Object.entries(formGroup.controls).some(
      ([signKey, formControl]: [string, FormControl]) => {
        // Only optional values can be null when this function is called so we can just return false
        // We can also ignore method keys

        if (
          signKey.includes(SYMPTOM_METHOD_KEY_SUFFIX) ||
          formControl.value === null ||
          formControl.value === undefined
        ) {
          return false;
        }
        const keyWithoutEye = this.getSignKeyWithoutEyeOrMethod(signKey);
        const normalCondition =
          this.preOpService.signsSchema[keyWithoutEye] &&
          this.preOpService.signsSchema[keyWithoutEye].normalCondition;

        if (normalCondition instanceof Function) {
          return !(
            normalCondition([formControl.value]) &&
            normalCondition([
              formControl.value,
              this.preOpFormGroup.controls[
                (signKey.includes('RIGHT') ? 'LEFT_' : 'RIGHT_') + keyWithoutEye
              ].value
            ])
          );
        }
        return (
          normalCondition &&
          !this.compareValue(normalCondition.operator, normalCondition.value, formControl.value)
        );
      }
    );
  }

  private compareValue(operator: Operator, conditionValue: any, inputValue: any): boolean {
    switch (operator) {
      case '>': {
        return inputValue > conditionValue;
      }
      case '<': {
        return inputValue < conditionValue;
      }
      case '=': {
        return inputValue === conditionValue;
      }
      case '<=>': {
        return inputValue <= conditionValue[0] && inputValue > conditionValue[0];
      }
    }
  }

  getSignKeyWithoutEyeOrMethod(signKey: string): string {
    return signKey.replace(/(RIGHT_|LEFT_|_METHOD)/g, '');
  }

  addControlsToPreOpFormGroupFromArray(keys: any[], required = false, formGroup?: FormGroup) {
    keys.forEach(key => {
      if (!this.preOpFormGroup.controls[key]) {
        this.preOpFormGroup.addControl(key, new FormControl());
      }
      if (formGroup) {
        formGroup.addControl(
          key,
          new FormControl(
            (this.preOpFormGroup.controls[key] && this.preOpFormGroup.controls[key].value) || null,
            required ? [Validators.required] : null
          )
        );
        this.preOpFormGroup.controls[key].valueChanges.subscribe(value => {
          formGroup.controls[key].setValue(value);
        });
      }
    });
  }

  addKeratometryControls() {
    this.preOperativeKeratometryKeys.forEach(key => {
      this.eyePrefixList.forEach(prefix => {
        Object.values(KeratometryMeasurements).forEach(suffix => {
          this.keratometryFormGroup.addControl(
            prefix + key + suffix,
            new FormControl(
              null,
              suffix === 'Diopter' || suffix === 'Classification' ? null : [Validators.required]
            )
          );

          if (suffix === 'SteepK' || suffix === 'FlatK') {
            this.keratometryFormGroup.controls[prefix + key + suffix].setValidators([
              Validators.min(30.0),
              Validators.max(60.0)
            ]);
          }
          if (suffix === 'SteepDegree' || suffix === 'FlatDegree') {
            this.keratometryFormGroup.controls[prefix + key + suffix].setValidators([
              Validators.min(0),
              Validators.max(180)
            ]);
          }
        });
      });
    });
    this.syncNewControlWithOriginal(this.keratometryFormGroup);
  }

  onOSDRelevanceChange(event: MatRadioChange) {
    this.preOpService.finalizedSurgeryProceeds = event.value === 'no';
    preopImpressionKeys.forEach(key => {
      if (key !== 'osdRelevance') {
        if (event.value === 'no') {
          this.preOpFormGroup.controls[key].disable();
          this.preOpFormGroup.controls[key].setValue('no');
        } else {
          this.preOpFormGroup.controls[key].enable();
          this.preOpFormGroup.controls[key].setValue(null);
        }
      }
    });

    if (!this.preOpService.neuropathicPain) {
      this.preOpFormGroup.controls['neuropathicPain'].setValue('no');
    }
    if (!this.preOpService.neurotrophicCornea) {
      this.preOpFormGroup.controls['neurotrophicCornea'].setValue('no');
    }
  }

  onVisuallySignificantOSDChange(event: MatRadioChange) {
    this.preOpService.finalizedSurgeryFails = event.value === 'yes';

    if (event.value === 'yes') {
      if (
        this.preOpService.neuropathicPain === true &&
        !this.preOpFormGroup.controls['neuropathicPain'].value
      ) {
        this.preOpFormGroup.controls['neuropathicPain'].setValue('no');
      }

      if (
        this.preOpService.neurotrophicCornea === true &&
        !this.preOpFormGroup.controls['neurotrophicCornea'].value
      ) {
        this.preOpFormGroup.controls['neurotrophicCornea'].setValue('no');
      }
    }
  }

  onNeurotrophicCorneaChange(event: MatRadioChange) {
    this.preOpService.finalizedSurgeryFails = event.value === 'yes';

    if (event.value === 'yes') {
      this.preOpFormGroup.controls['visuallySignificantOSD'].setValue('yes');

      return;
    }

    if (this.preOpFormGroup.controls['visuallySignificantOSD'].value === 'yes') {
      this.preOpFormGroup.controls['visuallySignificantOSD'].setValue(null);
    }
    this.preOpFormGroup.controls['osdRelevance'].enable();
    this.preOpFormGroup.controls['visuallySignificantOSD'].enable();
  }

  onNeuropathicPainChange(event: MatRadioChange) {
    this.preOpService.finalizedSurgeryProceeds = false;

    if (event.value === 'yes') {
      this.preOpFormGroup.controls['visuallySignificantOSD'].setValue('no');

      return;
    }

    this.preOpFormGroup.controls['visuallySignificantOSD'].setValue(null);
    this.preOpFormGroup.controls['osdRelevance'].enable();
    this.preOpFormGroup.controls['visuallySignificantOSD'].enable();
  }

  handlePreOpValueChanges() {
    // Have a control change schema rather then have each section of the code running for any change
    this.preOpFormGroup.valueChanges.subscribe(() => {
      const speedAbnormal =
        this.preOpFormGroup.controls.speed && this.preOpFormGroup.controls.speed.value > 5;
      this.preOpService.isOSDFactorsAbnormal.symptom = speedAbnormal;
      this.preOpService.analysisState.speed.abnormal = speedAbnormal;

      const speedIIAbnormal =
        this.preOpFormGroup.controls.speedii && this.preOpFormGroup.controls.speedii.value > 4;
      this.preOpService.isOSDFactorsAbnormal.symptom = speedIIAbnormal;
      this.preOpService.analysisState.speedii.abnormal = speedIIAbnormal;

      this.preOpService.analysisState.noToContactLensHoliday.abnormal =
        this.preOpFormGroup.controls.clHoliday.value === 'no';

      this.preOpService.analysisState.noToContactLensHoliday.technicianNote = this.preOpFormGroup.controls.clHolidayNotes.value;

      this.preOpService.analysisState.yesToEyeDrops.abnormal =
        this.preOpFormGroup.controls.eyeDropsUsedPrior.value === 'yes';

      this.preOpService.analysisState.yesToEyeDrops.technicianNote = this.preOpFormGroup.controls.eyeDropsUsedPriorNotes.value;

      this.preOpService.analysisState.osmolarity.abnormal =
        this.preOpFormGroup.controls[`LEFT_OSMOLARITY`] &&
        this.preOpFormGroup.controls[`RIGHT_OSMOLARITY`] &&
        !(this.preOpService.signsSchema.OSMOLARITY.normalCondition as Function)([
          this.preOpFormGroup.controls[`LEFT_OSMOLARITY`].value,
          this.preOpFormGroup.controls[`RIGHT_OSMOLARITY`].value
        ]);

      ['LEFT', 'RIGHT'].forEach(side => {
        const lowerCaseSide = side.toLowerCase();
        [
          [lowerCaseSide + 'Osmolarity', 'OSMOLARITY'],
          [lowerCaseSide + 'MMP9', 'MMP9'],
          [lowerCaseSide + 'SchirmerTest', 'SCHIRMERS_TEST'],
          [lowerCaseSide + 'Meibography', 'MEIBOGRAPHY'],
          [lowerCaseSide + 'NITBUT', 'NITBUT'],
          [lowerCaseSide + 'LipidLayer', 'LIPID_LAYER'],
          [lowerCaseSide + 'EpithelialThickness', 'EPITHELIAL_THICKNESS']
        ].forEach(([stateKey, schemaKey]) => {
          if (!this.preOpFormGroup.controls[`${side}_${schemaKey}`]) {
            return;
          }

          const normalCondition = this.preOpService.signsSchema[schemaKey].normalCondition;
          if (normalCondition instanceof Function) {
            this.preOpService.analysisState[stateKey].abnormal = !normalCondition([
              this.preOpFormGroup.controls[`${side}_${schemaKey}`].value
            ]);
            return;
          }

          if (this.preOpFormGroup.controls[side + '_' + schemaKey].value) {
            this.preOpService.analysisState[stateKey].abnormal = !this.compareValue(
              normalCondition.operator,
              normalCondition.value,
              this.preOpFormGroup.controls[side + '_' + schemaKey].value
            );
          }
        });
      });

      this.visuallySignificantOSD =
        this.preOpFormGroup.controls.visuallySignificantOSD.value === 'yes' ? true : false;

      this.preOpService.finalizedSurgeryFails =
        this.preOpFormGroup.controls.visuallySignificantOSD &&
        this.preOpFormGroup.controls.visuallySignificantOSD.value === 'yes';

      this.preOpService.finalizedSurgeryProceeds =
        this.preOpFormGroup.controls.osdRelevance &&
        this.preOpFormGroup.controls.osdRelevance.value === 'no';

      this.showOptionalNonInvasiveTest =
        this.preOpService.isOSDFactorsAbnormal.signs ||
        this.preOpService.isOSDFactorsAbnormal.symptom;

      this.preOpService.showOptionalNonInvasiveTest = this.showOptionalNonInvasiveTest;

      this.calculateNumberOfNotifiers();

      this.handleAbnormalityOfSigns();

      if (
        this.preOpService.neuropathicPain === false &&
        this.preOpFormGroup.controls['neuropathicPain'].value === 'yes'
      ) {
        this.preOpFormGroup.controls['neuropathicPain'].setValue('no');
      }

      if (
        this.preOpService.neurotrophicCornea === false &&
        this.preOpFormGroup.controls['neurotrophicCornea'].value === 'yes'
      ) {
        this.preOpFormGroup.controls['neurotrophicCornea'].setValue('no');
      }

      this.preOpService.analysisStateUpdate$.next();
    });
  }

  handleKeratometryValueChanges() {
    this.keratometryFormGroup.valueChanges.pipe(debounceTime(800)).subscribe(() => {
      this.calculateKeratometryAbnormality();
    });
  }

  calculateKeratometryAbnormality() {
    const rightEyeKeratometryDiopters = [];
    const leftEyeKeratometryDiopters = [];
    const rightEyeKeratometryClassification = new Set<string>();
    const leftEyeKeratometryClassification = new Set<string>();

    Object.values(this.preOpService.preOperativeKeratometryValues).forEach(keratometryValues => {
      rightEyeKeratometryDiopters.push(keratometryValues.right.diopter);
      if (keratometryValues.right.classification) {
        rightEyeKeratometryClassification.add(keratometryValues.right.classification);
      }
      leftEyeKeratometryDiopters.push(keratometryValues.left.diopter);
      if (keratometryValues.left.classification) {
        leftEyeKeratometryClassification.add(keratometryValues.left.classification);
      }
    });

    if (rightEyeKeratometryDiopters.length > 1) {
      this.preOpService.analysisState.rightEyeKeratometryDValueDifferences.abnormal = rightEyeKeratometryDiopters.some(
        rightDiopter => {
          return rightEyeKeratometryDiopters.some(secondRightDiopter => {
            if (rightDiopter !== 0 && secondRightDiopter !== 0) {
              return Math.abs(rightDiopter - secondRightDiopter) > 1;
            }
          });
        }
      );
    } else {
      this.preOpService.analysisState.rightEyeKeratometryDValueDifferences.abnormal = false;
    }

    this.preOpService.analysisState.rightEyeKeratometryClassificationDifferences.abnormal =
      rightEyeKeratometryClassification.size > 1;

    if (leftEyeKeratometryDiopters.length > 1) {
      this.preOpService.analysisState.leftEyeKeratometryDValueDifferences.abnormal = leftEyeKeratometryDiopters.some(
        leftDiopter => {
          return leftEyeKeratometryDiopters.some(secondLeftDiopter => {
            if (leftDiopter !== 0 && secondLeftDiopter !== 0) {
              return Math.abs(leftDiopter - secondLeftDiopter) > 1;
            }
          });
        }
      );
    } else {
      this.preOpService.analysisState.leftEyeKeratometryDValueDifferences.abnormal = false;
    }

    this.preOpService.analysisState.leftEyeKeratometryClassificationDifferences.abnormal =
      leftEyeKeratometryClassification.size > 1;

    this.calculateNumberOfNotifiers();
    this.preOpService.analysisStateUpdate$.next();
  }

  disablePreOpControlsFromKeys(keys, keysToExclude = []) {
    keys.forEach(key => {
      if (!keysToExclude.includes(key)) {
        this.preOpFormGroup.controls[key].disable();
      }
    });
  }

  predictDryEye() {
    this.requiredErrors = this.dryEyePredictionService.getInputErrors();

    if (!this.requiredErrors) {
      this.matSpinnerOptions.active = true;
      this.dryEyePredictionService
        .predictDryEyeCategoryAndSeverity()
        .pipe(
          finalize(() => (this.matSpinnerOptions.active = false)),
          untilDestroyed(this)
        )
        .subscribe((result: string) => {
          this.dryEyePrediction = result;

          this.changeDetector.markForCheck();
        });
    }
  }

  openReportModal() {
    this.dialog.open(PreOpReportComponent, {
      maxWidth: '90vw',
      height: '85vh',
      data: this.preOpFormGroup
    });
  }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(
    phone: string,
    format: 'national' | 'international' = 'national',
    defaultCountry: CountryCode = 'CA'
  ): string {
    if (phone) {
      const phoneNumber = parsePhoneNumberFromString(phone, defaultCountry);
      return format === 'national'
        ? phoneNumber.formatNational()
        : phoneNumber.formatInternational();
    }
    return phone;
  }
}

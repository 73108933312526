import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Renderer2
} from '@angular/core';
import { FormControlDirective, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { defer, EMPTY, merge, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AttachmentsComponent } from '../attachments.component';

@Component({
  selector: 'csi-attachments-form-field',
  templateUrl: './attachments-form-field.component.html',
  styleUrls: ['./attachments-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsFormFieldComponent implements AfterContentInit, OnDestroy {
  @Input() @HostBinding('class.attachments-form-field-error-state') errorState: boolean;
  public readonly invalidState$ = new Subject<boolean>();

  @ContentChild(AttachmentsComponent, { read: FormControlDirective, static: false })
  private formControlDirective: FormControlDirective;

  constructor(
    @Optional() private formGroupDirective: FormGroupDirective,
    private errorStateMatcher: ErrorStateMatcher,
    private renderer: Renderer2,
    private hostElementRef: ElementRef<HTMLElement>
  ) {}

  ngAfterContentInit() {
    if (this.formControlDirective) {
      if (this.errorState === undefined) {
        this.invalidState$.next(
          this.errorStateMatcher.isErrorState(
            this.formControlDirective.control,
            this.formGroupDirective
          )
        );
      }

      merge(
        this.formControlDirective.control.statusChanges,
        defer(() => (!!this.formGroupDirective ? this.formGroupDirective.ngSubmit : EMPTY))
      )
        .pipe(
          untilDestroyed(this),
          map(
            () =>
              this.errorStateMatcher.isErrorState(
                this.formControlDirective.control,
                this.formGroupDirective
              ) && this.errorState === undefined
          ),
          tap(invalid => {
            if (invalid) {
              this.renderer.addClass(this.hostElement, 'attachments-form-field-error-state');
            } else {
              this.renderer.removeClass(this.hostElement, 'attachments-form-field-error-state');
            }
          })
        )
        .subscribe(this.invalidState$);
    }
  }

  ngOnDestroy() {}

  private get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }
}

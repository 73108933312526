import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csi-account-access-instructions-modal',
  templateUrl: './account-access-instructions-modal.component.html',
  styleUrls: ['./account-access-instructions-modal.component.css']
})
export class AccountAccessInstructionsModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

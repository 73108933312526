import { FormControl } from '@angular/forms';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

export interface SignAttachmentsConfig {
  name: string;
  instructions: SignInstruction;
}

export type Eye = 'right' | 'left';

export interface SignsAttachmentsDialogData {
  selectedSignIndex: number;
}

export interface SignMediaSelectionDialogData {
  metadata: { [key: string]: string };
  signFormControl: FormControl;
}

export interface SignInstruction {
  media: string | SafeUrl | SafeResourceUrl;
  steps: string[];
}

export interface SignGroup {
  name: string;
  signs: SignWithAttachemnts[];
  instructions: SignInstruction;
  slitLampRecommended?: boolean;
}

export enum SignWithAttachemnts {
  BLINKING_FREQUENCY_PER_MIN = 'BLINKING_FREQUENCY_PER_MIN',
  BULBAR_REDNESS = 'BULBAR_REDNESS',
  COLLARETTES = 'COLLARETTES',
  CONJUNCTIVAL_CHALASIS = 'CONJUNCTIVAL_CHALASIS',
  CORNEAL_STAINING_PATTERN = 'CORNEAL_STAINING_PATTERN',
  EYELID_TELANGIECTASIA = 'EYELID_TELANGIECTASIA',
  FLUORESCEIN_STAINING = 'FLUORESCEIN_STAINING',
  FLOPPY_EYELID_SYNDROME_GRADING = 'FLOPPY_EYELID_SYNDROME_GRADING',
  ITBUT = 'ITBUT',
  LAGOPHTHALMUS = 'LAGOPHTHALMUS',
  LID_REDNESS = 'LID_REDNESS',
  LIMBAL_REDNESS = 'LIMBAL_REDNESS',
  LWE = 'LWE',
  MGE_EXPRESSIBILITY = 'MGE_EXPRESSIBILITY',
  MGE_SECRETION = 'MGE_SECRETION',
  PARTIAL_BLINKING_FREQUENCY = 'PARTIAL_BLINKING_FREQUENCY',
  TEAR_MENISCUS_HEIGHT = 'TEAR_MENISCUS_HEIGHT',
  TEAR_MENISCUS_HEIGHT_IMAGING = 'TEAR_MENISCUS_HEIGHT_IMAGING',
  MG_ORIFICE_CAPPED_GLANDS = 'MG_ORIFICE_CAPPED_GLANDS',
  MG_ORIFICE_NOTCHES = 'MG_ORIFICE_NOTCHES'
}

export const signGroups: { [key: string]: SignGroup } = {
  FLOPPY_EYELID: {
    name: 'Floppy Eyelid',
    signs: [SignWithAttachemnts.FLOPPY_EYELID_SYNDROME_GRADING],
    instructions: {
      media: 'assets/images/modal-images/floppy-eyelid/grade0.png',
      steps: [
        'Instruct the patient to look down.',
        'Pull upper lid until you can no longer stretch the eyelid.',
        'Take picture.'
      ]
    }
  },
  LAGOPHTHALMUS: {
    name: 'Lagophthalmus',
    signs: [SignWithAttachemnts.LAGOPHTHALMUS],
    instructions: {
      media: 'assets/images/image-wizard/eye-closed.jpg',
      steps: [
        'Instruct the patient to close eyes gently - do not squeeze.',
        'Use diffuse illumination.',
        'Take picture.'
      ]
    }
  },
  BLINKING: {
    name: 'Blinking',
    signs: [
      SignWithAttachemnts.BLINKING_FREQUENCY_PER_MIN,
      SignWithAttachemnts.PARTIAL_BLINKING_FREQUENCY
    ],
    instructions: {
      media: 'assets/images/image-wizard/blinking.mp4',
      steps: [
        'Instruct the patient to look straight ahead and "blink normally".',
        "Take a 10 second video of the patient's eye under diffuse illumination."
      ]
    }
  },
  WIDE: {
    name: 'Wide',
    slitLampRecommended: true,
    signs: [
      SignWithAttachemnts.LID_REDNESS,
      SignWithAttachemnts.BULBAR_REDNESS,
      SignWithAttachemnts.LIMBAL_REDNESS,
      SignWithAttachemnts.EYELID_TELANGIECTASIA
    ],
    instructions: {
      media: 'assets/images/image-wizard/look-straight-eyelid-pulled-down.jpg',
      steps: [
        'Instruct the patient to look straight.',
        'Use diffuse light.',
        'Pull lower lid down.',
        'Make sure the lid margin is clear.',
        'Take picture.'
      ]
    }
  },
  EYELID_DEBRIS: {
    name: 'Eyelid Debris',
    slitLampRecommended: true,
    signs: [SignWithAttachemnts.COLLARETTES],
    instructions: {
      media: 'assets/images/image-wizard/look-down.jpg',
      steps: [
        'Instruct the patient to look down.',
        'Use diffuse light.',
        'Focus on eyelash margin.',
        'Take picture.'
      ]
    }
  },
  FLUORESCEIN: {
    name: 'Fluorescein',
    slitLampRecommended: true,
    signs: [
      SignWithAttachemnts.CORNEAL_STAINING_PATTERN,
      SignWithAttachemnts.FLUORESCEIN_STAINING,
      SignWithAttachemnts.TEAR_MENISCUS_HEIGHT_IMAGING,
      SignWithAttachemnts.CONJUNCTIVAL_CHALASIS,
      SignWithAttachemnts.MG_ORIFICE_CAPPED_GLANDS,
      SignWithAttachemnts.MG_ORIFICE_NOTCHES
    ],
    instructions: {
      media: 'assets/images/image-wizard/fluorescein.jpg',
      steps: [
        'Instill dye.',
        'Instruct the patient to look straight.',
        'Use diffuse light with blue light filter and additional filters if available.',
        'Make sure conjunctival vessels clear.',
        'Take picture.'
      ]
    }
  },
  MGD_ANALYSIS: {
    name: 'MGD Analysis',
    slitLampRecommended: true,
    signs: [SignWithAttachemnts.MGE_SECRETION, SignWithAttachemnts.MGE_EXPRESSIBILITY],
    instructions: {
      media: 'assets/images/image-wizard/mgd-analysis.mp4',
      steps: [
        'Position camera on central lower eye lid',
        'Start recording.',
        'Gently press against lower eyelid just under lid margin, keeping the camera focus on the meibomian gland openings.',
        'End recording.'
      ]
    }
  }
};

export const signToFriendlyNameMap: { [keys in SignWithAttachemnts]: string } = {
  [SignWithAttachemnts.MG_ORIFICE_CAPPED_GLANDS]: 'MG Orifice Capped Glands',
  [SignWithAttachemnts.MG_ORIFICE_NOTCHES]: 'MG Orifice Notches',
  [SignWithAttachemnts.MGE_SECRETION]: 'MGE Secretion',
  [SignWithAttachemnts.FLUORESCEIN_STAINING]: 'Fluorescein staining',
  [SignWithAttachemnts.TEAR_MENISCUS_HEIGHT_IMAGING]: 'Tear Meniscus Height',
  [SignWithAttachemnts.TEAR_MENISCUS_HEIGHT]: 'Tear Meniscus Height Slit Lamp',
  [SignWithAttachemnts.FLOPPY_EYELID_SYNDROME_GRADING]: 'Floppy Eyelid Syndrome Grading',
  [SignWithAttachemnts.LID_REDNESS]: 'Lid Redness',
  [SignWithAttachemnts.COLLARETTES]: 'Eyelid Debris (Collarettes)',
  [SignWithAttachemnts.ITBUT]: 'Invasive Tear Breakup Time',
  [SignWithAttachemnts.CORNEAL_STAINING_PATTERN]: 'Corneal Staining Pattern',
  [SignWithAttachemnts.PARTIAL_BLINKING_FREQUENCY]: 'Partial Blinking Frequency',
  [SignWithAttachemnts.BLINKING_FREQUENCY_PER_MIN]: 'Blinking Frequency',
  [SignWithAttachemnts.LAGOPHTHALMUS]: 'Lagophthalmos',
  [SignWithAttachemnts.EYELID_TELANGIECTASIA]: 'Eyelid Telangiectasia',
  [SignWithAttachemnts.CONJUNCTIVAL_CHALASIS]: 'Conjuctival Chalasis',
  [SignWithAttachemnts.LWE]: 'Lid Wiper Epitheliopathy (LWE)',
  [SignWithAttachemnts.MGE_EXPRESSIBILITY]: 'MGE Expressibility',
  [SignWithAttachemnts.BULBAR_REDNESS]: 'Bulbar Redness',
  [SignWithAttachemnts.LIMBAL_REDNESS]: 'Limbal Redness'
};

import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[csiForceSelectableText]'
})
export class ForceSelectableTextDirective {
  @HostBinding('style.cursor') private cursor = 'text';

  @HostListener('click', ['$event']) private ignoreClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

import { Injectable } from '@angular/core';

function checkIfBrowserSupportsPreload(): boolean {
  const tokenList = (<any>document.createElement('link')).relList;
  const token = 'preload';

  if (!tokenList || !tokenList.supports) {
    return false;
  }

  try {
    return tokenList.supports(token);
  } catch (e) {
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImagePreloadingService {
  private readonly doesBrowserSupportPreload = checkIfBrowserSupportsPreload();
  private readonly preloadedURLs = new Set<string>();

  public preloadImage(url: string) {
    if (!this.preloadedURLs.has(url)) {
      if (this.doesBrowserSupportPreload) {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'preload';
        link.href = url;
        link.setAttribute('as', 'image');
        document.head.appendChild(link);
      } else {
        const image = new Image();
        image.src = url;
        image.onload = () => {
          image.style.display = 'none';
        };
        document.body.appendChild(image);
      }

      this.preloadedURLs.add(url);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UpdateOnBlurModule } from './../directives/update-on-blur/update-on-blur.module';
import { SharedModule } from './../shared.module';
import { PatientFormComponent } from './patient-form.component';

@NgModule({
  declarations: [PatientFormComponent],
  imports: [CommonModule, UpdateOnBlurModule, SharedModule],
  exports: [PatientFormComponent]
})
export class PatientFormModule {}

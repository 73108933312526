import { ValidatorFn } from "@angular/forms";


export enum FormInputTypeEnum {
  input = 'input',
  email = 'email',
  phone = 'phone',
  radio = 'radio',
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  toggles = 'toggles',
}

export interface FormFieldGroupSchema {
  fieldSchema: FormFieldGroup | {};
  style?: Record<string, Partial<CSSStyleDeclaration>>;
  actions?: FormFieldGroupAction[];
}

export interface FormFieldGroupAction  {
  buttonConfig: ButtonConfig;
  submit?: boolean;
  error?: string;
}

export interface FormFieldGroup {
  [key: string]: FormFieldConfig;
}

export interface FormFieldConfig {
  label: string;
  typeConfig: FormFieldTypeConfig;
  formControlConfig?: FormControlConfig;
  hide?: boolean;
  unit?: Units;
}

export type FormFieldTypeConfig =
  | TextConfig
  | DropDownConfig
  | CheckboxConfig
  | RadioConfig
  | PhoneConfig
  | EmailConfig
export interface FormControlGenerationConfig {
  prefixes: string[];
  //  suffixes?: string[];
}
export interface FormControlConfig {
  validators?: ValidatorFn[];
  generationConfig?: {
    prefixes?: string[];
    suffixes?: string[];
  };
}

export type TextConfig = {
  type: FormInputTypeEnum.input;
  inputType: 'text' | 'password' | 'number' | 'email' | 'tel';
};

export type DropDownConfig = {
  type: FormInputTypeEnum.dropdown;
  values: { [key: string]: string };
};

export type CheckboxConfig = {
  type: FormInputTypeEnum.checkbox;
  required: boolean;
  config: { [key: string]: { label: string } };
};

export type PhoneConfig = {
  type: FormInputTypeEnum.phone;
};

export type EmailConfig = {
  type: FormInputTypeEnum.email;
  inputType: 'email';
};

export type RadioConfig = {
  type: FormInputTypeEnum.email;
  value: { [key: string]: string };
};

export type Units =
  | 'nn'
  | 'sec'
  | 'mmHg'
  | 'mm'
  | 'mOsms/L'
  | '%'
  | 'μm'
  | 'cm';


 interface ButtonConfig {
    label: string,
    type?: 'raised',
    color?: 'primary',
    disable?:boolean,
  }


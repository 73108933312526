import { TextFavorite, TextFavoriteType, TreatmentTemplate } from 'src/API';

export interface TextFavoriteDefault
  extends Omit<Partial<TextFavorite & { showByDefault?: boolean }>, 'treatmentTemplate'> {
  treatmentTemplate?: Partial<TreatmentTemplate & { csiDefault?: boolean }>;
}

export const defaultTextFavoriteMap: { [key: string]: TextFavoriteDefault } = {
  gsx: {
    id: 'gsx',
    shortcut: 'gsx',
    text: 'Prepare ocular surface for upcoming surgery',
    type: TextFavoriteType.Goals
  },
  ros: {
    id: 'ros',
    shortcut: 'ros',
    text: 'Ocular rosacea',
    type: TextFavoriteType.Impressions
  },
  ['mixed' + TextFavoriteType.Impressions]: {
    id: 'mixed' + TextFavoriteType.Impressions,
    shortcut: 'mixed',
    text: 'Mixed deficiency lipid > aqueous',
    type: TextFavoriteType.Impressions
  },
  mgd1: {
    id: 'mgd1',
    shortcut: 'mgd1',
    text: `1) Home care: warm compresses twice a day x 15 minutes each followed by lid massage, blinking exercises several times a day, lid scrubs once a day
  2) Omega 3 supplements 3000-4000 mg/day (as long as not on any blood thinners such as aspirin)
  3) Lipiflow treatment to heat and massage the glands to remove obstructions`,

    type: TextFavoriteType.Recommendations
  },
  gsymptoms: {
    id: 'gsymptoms',
    shortcut: 'gsymptoms',
    text: 'Improve patient symptoms',
    type: TextFavoriteType.Goals
  },
  aqueous: {
    id: 'aqueous',
    shortcut: 'aqueous',
    text: `1) Home care: warm compresses twice a day x 15 minutes, lid massage, blinking exercises, lid scrubs once a day
  2) Omega 3 supplements 3000-4000 mg/day (as long as not on blood thinners such as aspirin)
  3) Autologous serum once every 2 hours
  4) Artificial tears four times a day (preservative free if using more than four times a day)
  5) Punctal plugs to retain tear volume- start with temporary first on lower lids then add permanent if helpful
  6) Tear care twice a day`,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'aque-mod',
      id: 'aque-mod',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'systane-ultra-high-performance'],
      rxEyeDrops: ['restasis-and-restasis-multidose'],
      procedures: ['punctal-plugs'],
      exercises: ['blinking-exercises', 'warm-compresses']
    }
  },
  gpre: {
    id: 'gpre',
    shortcut: 'gpre',
    text: 'Preserve remaining meibomian gland structure',
    type: TextFavoriteType.Goals
  },
  gmgd: {
    id: 'gmgd',
    shortcut: 'gmgd',
    text:
      'Preserve remaining meibomian gland structure, prepare ocular surface for surgery, and improve patient symptoms of dry eye',
    type: TextFavoriteType.Goals
  },
  meib: {
    id: 'meib',
    shortcut: 'meib',
    text: 'Meibomian gland disease',
    type: TextFavoriteType.Impressions
  },
  ['mix a']: {
    id: 'mix a',
    shortcut: 'mix a',
    text: 'Mixed deficiency aqueous > lipid',
    type: TextFavoriteType.Impressions
  },
  ['rosacea']: {
    id: 'rosacea',
    shortcut: 'rosacea',
    text: `1) IPL treatment with expressions, 4 treatments, 2 weeks apart
  2) I-drop MGD four times a day (or similar oil based preservative free artificial tear) to help protect the ocular surface and reduce symptoms
  3) Home care: warm compresses twice a day x 15 minutes followed by light lid massage, blinking exercises throughout the day`,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'evap-mild',
      id: 'evap-mild',
      otcEyeDrops: ['systane-balance-restorative', 'hylo-dual-dry-eye-and-allergy-relief'],
      procedures: ['lipiflow-thermal-pulsation-treatment', 'miboflo'],
      exercises: ['warm-compresses', 'blinking-exercises']
    }
  },
  ['mixed']: {
    id: 'mixed',
    shortcut: 'mixed',
    text: `1) Home care: warm compresses twice a day x 15 minutes followed by lid massage, blinking exercises, lid scrubs once a day
    2) Omega 3 supplements 3000-4000 mg/day (as long as not on blood thinners such as aspirin)
    3) Autologous serum once every 2 hours
    4) Artificial tears four times a day (preservative free if using more than four times a day)
    5) IPL/RF/Lipiflow as a treatment to help generate oil flow and remove meibomian gland obstructions
    6) Consider punctal plugs at a later date once inflammation has subsided`,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'mix-mod'
    }
  },
  ['gcl']: {
    id: 'gcl',
    shortcut: 'gcl',
    text: 'Improve contact lens comfort',
    type: TextFavoriteType.Goals
  },
  ['mild-aqueous']: {
    id: 'mild-aqueous',
    shortcut: 'mild-aqueous',
    text:
      'Mild aqueous generally not exhibiting inflammatory response so extrinsic modifications usually sufficient. Environmental modifications such as computer ergonomics/avoid vents in eyes etc. Increase hydration/lubrication. Warm compresses to optimize the oil layer/improve frictional component to avoid inflammation.',
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'mild-aqueous',
      id: 'mild-aqueous',
      otcEyeDrops: ['i-drop-pur-gel'],
      rxEyeDrops: [],
      procedures: ['punctal-plugs'],
      exercises: ['blinking-exercises']
    }
  },
  ['moderate-aqueous']: {
    id: 'moderate-aqueous',
    shortcut: 'moderate-aqueous',
    text: `Consider the intrinsic factors causing the low tear production. Using an Immunomodulator will help protect the cornea against secondary damage from cytokines. Environmental modifications such as computer ergonomics/avoid vents in eyes etc. Increase hydration/lubrication. History screening for autoimmune conditions such as Sjogren's or thyroid conditions, with consideration of blood work.`,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'moderate-aqueous',
      id: 'moderate-aqueous',
      otcEyeDrops: ['i-drop-pur-gel'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
      procedures: ['punctal-plugs', 'boston-sight-scleral', 'scleral-contact-lens', 'itear'],
      exercises: ['blinking-exercises']
    }
  },
  ['severe-aqueous']: {
    id: 'severe-aqueous',
    shortcut: 'severe-aqueous',
    text: `Consider the intrinsic factors causing the low tear production. Using an Immunomodulator will help protect the cornea against secondary damage from cytokines. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Increase hydration/lubrication. History screening and bloodwork for autoimmune conditions such as Sjogren's. Consider Moisture goggles for symptom relief. Consider Bandage contact lens. Consider Boston Scleral contact lens. Consider Amniotic Membrane tissue application
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'severe-aqueous',
      id: 'severe-aqueous',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'tranquileyes'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
      procedures: [
        'punctal-plugs',
        'amniotic-membrane-transplantation',
        'scleral-lenses',
        'boston-sight-scleral'
      ],
      exercises: ['blinking-exercises', 'warm-compresses']
    }
  },
  ['mild-mixed']: {
    id: 'mild-mixed',
    shortcut: 'mild-mixed',
    text: `Treat the evaporative dry eye as priority, because obtaining a better QUALITY tear layer is more important than QUANTITY. Consider omega 3 if it does not interfere with blood thinning for a patient. Environmental modifications such as computer ergonomics/avoid vents in eyes etc. Visual hygiene (20/20/20 rule). Increase hydration/lubrication
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'mild-mixed',
      id: 'mild-mixed',
      otcEyeDrops: ['mgd', 'prn-omega-softgel'],
      rxEyeDrops: [''],
      procedures: ['miboflo'],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['moderate-mixed']: {
    id: 'moderate-mixed',
    shortcut: 'moderate-mixed',
    text: `Don't use a punctal plug as first line because of the risk of cytokine collection in tear film sitting against the cornea causing collateral damage. Treat the inflammation first. Consider omega 3 if it does not interfere with blood thinning for a patient.Environmental modifications such as computer ergonomics/avoid vents in eyes etc,  visual hygiene (20/20/20 rule). Increase hydration/lubrication. History screening/Blood work for autoimmune conditions such as Sjogren's or thyroid conditions, with consideration of bloodwork. Lifestyle modifications such as probiotics/dietary assessment potentially helpful.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'moderate-mixed',
      id: 'moderate-mixed',
      otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
      procedures: [
        'blephex-(microblepharoexfoliation)',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'punctal-plugs',
        'itear'
      ],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },

  ['severe-mixed']: {
    id: `severe-mixed`,
    shortcut: `severe-mixed`,
    text: `Don't use a punctal plug as first line because of the risk of cytokine collection in tear film sitting against the cornea causing collateral damage. Treat the inflammation first. Consider alternating IPL and RF for a combination of anti-inflammatory and neurostimulatory mechanisms. Maximize interdisciplinary cooperation to evaluate overall systems such as autoimmune conditions, and SIBO (small intestine bacterial overgrowth). Consider omega 3 if it does not interfere with blood thinning for a patient. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc,  Visual hygiene (20/20/20 rule). Increase hydration/lubrication. History screening and bloodwork for autoimmune conditions such as Sjogren's. Moisture goggles for symptom relief.  Lifestyle modifications such as probiotics/dietary assessment potentially helpful.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'severe-mixed',
      id: 'severe-mixed',
      otcEyeDrops: ['mgd', 'hylo-dual', 'ocunox', 'prn-omega-softgel', 'tranquileyes'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
      procedures: [
        'blephex-(microblepharoexfoliation)',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'punctal-plugs',
        'amniotic-membrane-transplantation',
        'boston-sight-scleral',
        'intraductal-probing',
        'itear'
      ],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['mild-evaporative']: {
    id: 'mild-evaporative',
    shortcut: 'mild-evaporative',
    text: `Usually sufficient to treat with home care and environmental modifications but  go into more aggressive treatments if no symptom relief within 2-3 months. Consider omega 3 if it does not interfere with blood thinning for a patient. Increase hydration/lubrication. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Visual hygiene (20/20/20 rule). Increase hydration/lubrication. History screening for autoimmune conditions such as Sjogren's or thyroid conditions, with consideration of bloodwork. Consider Lifestyle modifications such as probiotics/dietary assessment.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'mild-evaporative',
      id: 'mild-evaporative',
      otcEyeDrops: ['mgd', 'hylo-dual', 'systane-balance-restorative', 'prn-omega-softgel'],
      rxEyeDrops: [''],
      procedures: ['miboflo'],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['moderate-evaporative']: {
    id: 'moderate-evaporative',
    shortcut: 'moderate-evaporative',
    text: `Don't use a punctal plug as first line because of the risk of cytokine collection in tear film sitting against the cornea causing collateral damage. Treat the inflammation first. Consider omega 3 if it does not interfere with blood thinning for a patient. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Visual hygiene (20/20/20 rule). Increase hydration/lubrication. History screening for autoimmune conditions such as Sjogren's or thyroid conditions, with consideration of bloodwork. Consider Lifestyle modifications such as probiotics/dietary assessment.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'moderate-evaporative',
      id: 'moderate-evaporative',
      otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
      procedures: [
        'blephex-(microblepharoexfoliation)',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)'
      ],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['severe-evaporative']: {
    id: 'severe-evaporative',
    shortcut: 'severe-evaporative',
    text: `Don't use a punctal plug as first line because of the risk of cytokine collection in tear film sitting against the cornea causing collateral damage. Treat the inflammation first. Consider alternating IPL and RF for a combination of anti-inflammatory and neurostimulatory mechanisms. Maximize interdisciplinary cooperation to evaluate overall systems such as autoimmune conditions, and SIBO (small intestine bacterial overgrowth). Consider omega 3 if it does not interfere with blood thinning for a patient.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'severe-evaporative',
      id: 'severe-evaporative',
      otcEyeDrops: ['mgd', 'hylo-dual', 'ocunox', 'prn-omega-softgel', 'tranquileyes'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
      procedures: [
        'blephex-(microblepharoexfoliation)',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'punctal-plugs',
        'amniotic-membrane-transplantation',
        'boston-sight-scleral',
        'intraductal-probing',
        'itear'
      ],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['neurotrophic-stage-1']: {
    id: 'neurotrophic-stage-1',
    shortcut: 'neurotrophic-stage-1',
    text: `Maximize interdisciplinary cooperation for systemic evaluation to etiology of the condition. As an Optometrist,refer stage 2 and beyond to the corneal specialist to manage.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'neurotrophic-stage-1',
      id: 'neurotrophic-stage-1',
      otcEyeDrops: ['i-drop-pur-gel'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
      procedures: [''],
      exercises: ['blinking-exercises', 'glad-press-seal']
    }
  },
  ['neurotrophic-stage-2']: {
    id: 'neurotrophic-stage-2',
    shortcut: 'neurotrophic-stage-2',
    text: `Maximize interdisciplinary cooperation for systemic evaluation to etiology of the condition. As an Optometrist,refer stage 2 and beyond to the corneal specialist to manage.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'neurotrophic-stage-2',
      id: 'neurotrophic-stage-2',
      otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes'],
      rxEyeDrops: [
        'restasis-and-restasis-multidose',
        'cequa',
        'xiidra',
        'autologous-serum',
        'bandage-contact-lens',
        'amniotic-membrane-transplantation',
        'vigamox',
        'prp'
      ],
      procedures: ['tarsorraphy', 'tape-tarsorrhaphy'],
      exercises: ['blinking-exercises', 'tape-tarsorrhaphy']
    }
  },
  ['neurotrophic-stage-3']: {
    id: 'neurotrophic-stage-3',
    shortcut: 'neurotrophic-stage-3',
    text: `Maximize interdisciplinary cooperation for systemic evaluation to etiology of the condition. As an Optometrist, refer stage 2 and beyond to the corneal specialist to manage.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'neurotrophic-stage-3',
      id: 'neurotrophic-stage-3',
      otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes', 'ocunox'],
      rxEyeDrops: [
        'restasis-and-restasis-multidose',
        'cequa',
        'xiidra',
        'autologous-serum',
        'bandage-contact-lens',
        'amniotic-membrane-transplantation',
        'vigamox',
        'topical-n-acetylcysteine',
        'doxycycline',
        'prp'
      ],
      procedures: ['tarsorraphy', 'tape-tarsorrhaphy'],
      exercises: ['blinking-exercises', 'tape-tarsorrhaphy']
    }
  },
  ['contact-lens-induced-keratopathy']: {
    id: 'contact-lens-induced-keratopathy',
    shortcut: 'contact-lens-induced-keratopathy',
    text: `Discontinue Contact lens use. Anything with central ulceration and/or non responsive to initial therapy with visual decline should be triaged to ophthalmology. When in doubt- treat as infectious. If not responding to treatment, triage to ophthalmology.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'contact-lens-induced-keratopathy',
      id: 'contact-lens-induced-keratopathy',
      otcEyeDrops: ['hylo'],
      rxEyeDrops: ['lotemax'],
      procedures: [''],
      exercises: ['']
    }
  },
  ['slk']: {
    id: 'slk',
    shortcut: 'slk',
    text: `Discontinue Contact lens use. Consider moisture goggles if associated proptosis. Testing for thyroid disease is suggested. Maximize interdisciplinary cooperation for systemic evaluation i.e. thyroid conditions. Triage to Ophthalmology if recalcitrant or visual decline to consider surgical management.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'slk',
      id: 'slk',
      otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes'],
      rxEyeDrops: ['lotemax', 'cequa'],
      procedures: ['bandage-contact-lens'],
      exercises: ['']
    }
  },
  ['corneal-toxicity']: {
    id: 'corneal-toxicity',
    shortcut: 'corneal-toxicity',
    text: `Investigation into etiology a must in order to understand treatment plan. Maximize interdisciplinary cooperation for evaluation and adjustment of ocular medication use i.e. glaucoma drops as well as systemic medication and supplement review. Removal of the offending cause is ideal. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc, Visual hygiene (20/20/20 rule).  Increase hydration/lubrication.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'corneal-toxicity',
      id: 'corneal-toxicity',
      otcEyeDrops: ['refresh-optive-pf', 'systane-bion-tears'],
      rxEyeDrops: ['autologous-serum', 'prednisolone-sodium-phosphate-minums', 'prp'],
      procedures: ['bandage-contact-lens'],
      exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
    }
  },
  ['trichiasis']: {
    id: 'trichiasis',
    shortcut: 'trichiasis',
    text: `Periodic visits for lash removal as necessary. Triage to ophthalmology for surgical management as necessary
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'trichiasis',
      id: 'trichiasis',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel'],
      rxEyeDrops: ['vigamox'],
      procedures: ['eyelash-apilation-cauterization'],
      exercises: ['']
    }
  },
  ['lagophthalmos']: {
    id: 'lagophthalmos',
    shortcut: 'lagophthalmos',
    text: `Not usually seen in isolation; likely concurrent MGD present in which case treat as such. Rule out floppy iris syndrome. During the day consider lubricating drops that are preservative free. For night time, consider Lubricating ointment. Moisture goggles could be helpful throughout the day and night. Humidifier in the bedroom. No fans directed at the eyes. Look for leaks in CPAP machine and try to improve fit if possible
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'lagophthalmos',
      id: 'lagophthalmos',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'ocunox', 'tranquileyes'],
      rxEyeDrops: [''],
      procedures: ['tarsorraphy'],
      exercises: ['glad-press-seal', 'tape-tarsorrhaphy']
    }
  },
  ['bacterial-blepharokeratoconjunctivitis-(bkc)']: {
    id: 'bacterial-blepharokeratoconjunctivitis-(bkc)',
    shortcut: 'bacterial-blepharokeratoconjunctivitis-(bkc)',
    text: `Consider omega 3 if it does not interfere with blood thinning for a patient. If Demodex component, use Lid n Lash Plus or equivalent Tea Tree oil based wipe.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'bacterial-blepharokeratoconjunctivitis-(bkc)',
      id: 'bacterial-blepharokeratoconjunctivitis-(bkc)',
      otcEyeDrops: [
        'i-drop-pur-gel',
        'hylo',
        'prn-omega-softgel',
        'systane-lid-wipes',
        'i-lid-n-lash-plus'
      ],
      rxEyeDrops: ['tobradex-drops', 'vigamox', 'fucithalmic-viscous'],
      procedures: ['blephex-(microblepharoexfoliation)'],
      exercises: ['warm-compresses']
    }
  },
  ['exposure-keratopathy']: {
    id: 'exposure-keratopathy',
    shortcut: 'exposure-keratopathy',
    text: `Look for the cause of the exposure keratopathy- careful examination of lid position, blink rate, proptosis, and consideration of systemic exacerbators such as Parkinsons. Look for neurotrophic cornea which may help elucidate etiology. Look at the patient overall facial structure- can help with facial scaffolding reconstruction with fillers. Consider Omega 3 if it does not interfere with blood thinning for a patient. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Visual hygiene (20/20/20 rule). Increase hydration/lubrication. Moisture goggles for symptom relief. Triage to ophthalmology for surgical management as necessary.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'exposure-keratopathy',
      id: 'exposure-keratopathy',
      otcEyeDrops: [
        'i-drop-pur-gel',
        'hylo-gel',
        'ocunox',
        'prn-omega-softgel',
        'tranquileyes',
        'sleeptite-sleeprite'
      ],
      rxEyeDrops: ['erythromycin-ointment'],
      procedures: [
        'punctal-plugs',
        'bandage-contact-lens',
        'boston-sight-scleral',
        'tape-tarsorrhaphy',
        'tarsorraphy'
      ],
      exercises: ['visual-hygiene', 'glad-press-seal']
    }
  },

  ['gpc/vkc']: {
    id: 'gpc/vkc',
    shortcut: 'gpc/vkc',
    text: `If recalcitrant, refer to Ophthalmology who can consider corticosteroid injection and/or immunotherapy.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'gpc/vkc',
      id: 'gpc/vkc',
      otcEyeDrops: ['hylo-dual', 'thealoz-duo'],
      rxEyeDrops: [
        'pataday',
        'prednisolone-sodium-phosphate-minums',
        'alrex',
        'restasis-and-restasis-multidose',
        'cequa',
        'xiidra'
      ], // no Zaditor,
      procedures: [''],
      exercises: ['cool-compresses']
    }
  },

  ['allergic-conjunctivitis']: {
    id: 'allergic-conjunctivitis',
    shortcut: 'allergic-conjunctivitis',
    text: `Focusing on prevention helps once the acute phase is over. Discontinue contact lenses  during acute phase. If must wear contact lenses, consider daily disposable lens or change the contact lens solution daily. Remove/avoid allergen source. Air purifiers in house/open windows (if it's not environmental)
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'allergic-conjunctivitis',
      id: 'allergic-conjunctivitis',
      otcEyeDrops: ['hylo-dual', 'thealoz-duo'],
      rxEyeDrops: [
        'pataday',
        'prednisolone-sodium-phosphate-minums',
        'alrex',
        'restasis-and-restasis-multidose',
        'cequa',
        'xiidra'
      ], // no Zaditor,
      procedures: [''],
      exercises: ['cool-compresses']
    }
  },
  ['viral/bacterial-conjunctivitis']: {
    id: 'viral/bacterial-conjunctivitis',
    shortcut: 'viral/bacterial-conjunctivitis',
    text: `Managing symptoms and preventing sequelae are the goal of viral conjunctivitis treatment. In addition, consideration now needs to be given to potential COVID etiology and appropriate PPE precautions are recommended during patient care. Preservative free artificial tears q1h to flush viral/bacteria. Avoid sharing towels/sheets/anything near face.  Frequent hand washing. Avoid cross contamination with adjacent eye by using a separate artificial tear bottle/medication bottle for each eye. Wash clothes and sheets on hot cycle.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'viral/bacterial-conjunctivitis',
      id: 'viral/bacterial-conjunctivitis',
      otcEyeDrops: ['systane-bion-tears'],
      rxEyeDrops: ['vigamox', 'erythromycin-ointment'],
      procedures: [''],
      exercises: ['cool-compresses']
    }
  },
  ['bacterial-blepharitis']: {
    id: 'bacterial-blepharitis',
    shortcut: 'bacterial-blepharitis',
    text: `Discontinue Contact lens use. Consider moisture goggles if associated proptosis. Testing for thyroid disease is suggested. Maximize interdisciplinary cooperation for systemic evaluation i.e. thyroid conditions. Triage to Ophthalmology if recalcitrant or visual decline to consider surgical management.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'bacterial-blepharitis',
      id: 'bacterial-blepharitis',
      otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],
      rxEyeDrops: [
        'erythromycin-ointment',
        'fucithalmic-viscous',
        'blephamide-drop',
        'blephamide-ointment'
      ],
      procedures: ['blephex-(microblepharoexfoliation)', 'low-level-light-therapy'],
      exercises: ['warm-compresses']
    }
  },
  ['demodex-blepharitis']: {
    id: 'demodex-blepharitis',
    shortcut: 'demodex-blepharitis',
    text: `Recommend aggressive treatment as it can be difficult to know if Demodex infiltration of the meibomian glands has occurred. Topical and home treatments may only provide a short term relief, so initial thermotherapeutic management may be warranted. Consider Omega 3 if it does not interfere with blood thinning for a patient. Encourage using the lid wipe on the eyebrows. Consider tea trea oil based face wash for surrounding skin on cheeks. Wash clothing/sheets on high heat cycle.  Replace makeup. Discontinue contact lens wear for the duration of the condition. Immunomodulator (Xiidra, Restasis, Cequa) to help with concurrent inflammation and may be helpful for more chronic management. If Demodex appears to be the cause of concurrent rosacea, oral treatments such as doxycyline may be indicated.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'demodex-blepharitis',
      id: 'demodex-blepharitis',
      otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],
      rxEyeDrops: ['restasis-and-restasis-multidose', 'xiidra', 'cequa', 'doxycycline'],
      procedures: [
        'blephex-(microblepharoexfoliation)',
        'low-level-light-therapy',
        'miboflo',
        'lipiflow-thermal-pulsation-treatment',
        'intense-pulsed-light-therapy-(ipl)'
      ],
      exercises: ['warm-compresses']
    }
  },
  'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion': {
    id: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    shortcut: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    text: `Focus on dry eye management for prevention of sequelae especially if concurrent conditions.`,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
      id: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
      otcEyeDrops: ['thealoz-duo', 'sleeptite-sleeprite'],
      rxEyeDrops: [''],
      procedures: ['bandage-contact-lens', 'epithelial-debridement', 'phototheraputic-keratectomy'],
      exercises: ['glad-press-seal', 'warm-compresses']
    }
  },
  ['medication-induced']: {
    id: 'medication-induced',
    shortcut: 'medication-induced',
    text: `Investigation into etiology a must in order to understand treatment plan. Maximize interdisciplinary cooperation for evaluation and adjustment of ocular medication use i.e. glaucoma drops as well as systemic medication and supplement review. Removal of the offending cause is ideal. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Suggest Visual hygiene (20/20/20 rule). Increase hydration/lubrication.
      `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'medication-induced',
      id: 'medication-induced',
      otcEyeDrops: ['systane-bion-tears'],
      rxEyeDrops: ['autologous-serum', 'prp'],
      procedures: [''],
      exercises: ['warm-compresses', 'blinking-exercises']
    }
  },
  ['corneal-neuralgia']: {
    id: 'corneal-neuralgia',
    shortcut: 'corneal-neuralgia',
    text: `Keep in mind the psychological status of the patient. Care with wording of your education is important as well as establishing trust that you are there to manage the condition. Have a suicide intervention protocol established in office and be prepared to check in with the patient if you sense they are struggling psychologically. Alternative treatments such as acupuncture may play a positive adjunctive role. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Increase hydration/lubrication. History screening for understanding of precipitating event to better understand management. Consider Blue filter glasses may help with associated photophobia. Optimize sleep program (no screens before bed). Consider Meditation. Test for peripheral vs central pain by doing an alcaine test. Bandage contact lenses for short term relief,  Therapeutic scleral lenses for a longer term plan. There is a role that thermotherapeutic treatment can play in this condition if there is concurrent dry eyes but keep in mind the tolerability of the actual procedure with the patient.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'corneal-neuralgia',
      id: 'corneal-neuralgia',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'ocunox'],
      rxEyeDrops: [
        'lotemax',
        'restasis-and-restasis-multidose',
        'cequa',
        'xiidra',
        'autologous-serum',
        'prp'
      ], // still missing gabapentin
      procedures: [
        'bandage-contact-lens',
        'boston-sight-scleral',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'lipiflow-thermal-pulsation-treatment',
        'miboflo'
      ],
      exercises: ['warm-compresses', 'blinking-exercises']
    }
  },

  ['conjunctivochalasis-mild']: {
    id: 'conjunctivochalasis-mild',
    shortcut: 'conjunctivochalasis-mild',
    text: `Mild conjunctivhochalasis is often an incidental finding and requires little intervention. Consider Omega 3 if it does not interfere with blood thinning for a patient. Evaluate the etiology of the conjunctivochalasis- if age related, maybe no treatments. However if frictional related due to primary dry eye condition, manage as appropriate.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'conjunctivochalasis-mild',
      id: 'conjunctivochalasis-mild',
      otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
      rxEyeDrops: [''],
      procedures: [''],
      exercises: ['']
    }
  },
  ['conjunctivochalasis-moderate']: {
    id: 'conjunctivochalasis-moderate',
    shortcut: 'conjunctivochalasis-moderate',
    text: `Moderate conjunctivhochalasis may or may not require intervention depending on symptoms of patient. Consider Omega 3 if it does not interfere with blood thinning for a patient. Evaluate the etiology of the conjunctivochalasis- if age related, maybe no treatments. However if frictional related due to primary dry eye condition, manage as appropriate.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'conjunctivochalasis-moderate',
      id: 'conjunctivochalasis-moderate',
      otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
      rxEyeDrops: [''],
      procedures: [''],
      exercises: ['warm-compresses']
    }
  },

  ['conjunctivochalasis-severe']: {
    id: 'conjunctivochalasis-severe',
    shortcut: 'conjunctivochalasis-severe',
    text: `Consideration for surgical resection should be made. Consider Omega 3 if it does not interfere with blood thinning for a patient. Evaluate the etiology of the conjunctivochalasis- if age related, maybe no treatments. However if frictional related due to primary dry eye condition, manage as appropriate.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'conjunctivochalasis-severe',
      id: 'conjunctivochalasis-severe',
      otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
      rxEyeDrops: ['alrex', 'prednisolone-sodium-phosphate-minums'],
      procedures: [''],
      exercises: ['warm-compresses']
    }
  },

  ['floppy-eyelid-syndrome-mild']: {
    id: 'floppy-eyelid-syndrome-mild',
    shortcut: 'floppy-eyelid-syndrome-mild',
    text: `Not usually seen in isolation; likely concurrent MGD present in which case treat as such. Maximize interdisciplinary cooperation to manage sleep apnea and its potential sequelae. Look for leaks in CPAP machine and try to improve fit if possible. Consider Weight management. Avoid sleeping on stomach.Evaluate for sleep apnea.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'floppy-eyelid-syndrome-mild',
      id: 'floppy-eyelid-syndrome-mild',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
      rxEyeDrops: [''],
      procedures: [''],
      exercises: ['']
    }
  },

  ['floppy-eyelid-syndrome-moderate']: {
    id: 'floppy-eyelid-syndrome-moderate',
    shortcut: 'floppy-eyelid-syndrome-moderate',
    text: `Not usually seen in isolation; likely concurrent MGD present in which case treat as such. Maximize interdisciplinary cooperation to manage sleep apnea and its potential sequelae. Consider humidifier in the bedroom. No fans directed at the eyes. Look for leaks in CPAP machine and try to improve fit if possible. Consider Weight management. Avoid sleeping on stomach. Evaluate for sleep apnea.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'floppy-eyelid-syndrome-moderate',
      id: 'floppy-eyelid-syndrome-moderate',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox', 'sleeptite-sleeprite'],
      rxEyeDrops: [''],
      procedures: [''],
      exercises: ['glad-press-seal']
    }
  },

  ['floppy-eyelid-syndrome-severe']: {
    id: 'floppy-eyelid-syndrome-severe',
    shortcut: 'floppy-eyelid-syndrome-severe',
    text: `Not usually seen in isolation; likely concurrent MGD present in which case treat as such. Maximize interdisciplinary cooperation to manage sleep apnea and its potential sequelae. Consider humidifier in the bedroom. No fans directed at the eyes. Look for leaks in CPAP machine and try to improve fit if possible. Consider Weight management. Avoid sleeping on stomach. Evaluate for sleep apnea.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'floppy-eyelid-syndrome-severe',
      id: 'floppy-eyelid-syndrome-severe',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
      rxEyeDrops: [
        'restasis-and-restasis-multidose',
        'xiidra',
        'cequa',
        'prednisolone-sodium-phosphate-minums',
        'tobradex-ointment'
      ],
      procedures: [''], // eyelid-surgery(other)
      exercises: ['glad-press-seal']
    }
  },

  ['mechanical-(entropion,distichiasis,tarsal-entropion)']: {
    id: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    shortcut: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    text: ` Lid scrubs as needed to ensure bacterial load is acceptable. Refer to ophthalmology for surgical management
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
      id: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
      otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
      rxEyeDrops: ['erythromycin-ointment'],
      procedures: ['bandage-contact-lens', 'eyelash-apilation-cauterization'],
      exercises: ['']
    }
  },

  ['lid-wiper-epitheliopathy']: {
    id: 'lid-wiper-epitheliopathy',
    shortcut: 'lid-wiper-epitheliopathy',
    text: `Warm compresses (to improve oil layer/frictional component). Consider the intrinsic factors causing the low tear production/friction. Contact lenses can be a large culprit for causing this condition.  Evaluate the lid aposition. Evaluate the osmolarity of the tears. Using an Immunomodulator will help protect the cornea against secondary damage from cytokines. Consider environmental modifications such as computer ergonomics/avoid vents in eyes etc. Increase hydration/lubrication. History screening and bloodwork for autoimmune conditions such as Sjogren's. Consider moisture goggles for symptom relief. Consider impact of current contact lens fit on the wiper epitheliopathy and adjust accordingly. Consider discontinuing the contact lenses if necessary. Punctal plugs only in the absence of inflammation.  iTear device (not available in Canada). Thermotherapeutic treatments such as IPL, RF, Miboflo, Lipiflow have the benefit of improving oil layer/reducing frictional component and possible inflammation.
    `,
    type: TextFavoriteType.Recommendations,
    treatmentTemplate: {
      friendlyId: 'lid-wiper-epitheliopathy',
      id: 'lid-wiper-epitheliopathy',
      otcEyeDrops: ['mgd', 'hylo-dual', 'tranquileyes'],
      rxEyeDrops: [
        'alrex',
        'prednisolone-sodium-phosphate-minums',
        'lotemax',
        'cequa',
        'autologous-serum',
        'prp'
      ],
      procedures: [
        'punctal-plugs',
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'miboflo',
        'lipiflow-thermal-pulsation-treatment',
        'itear'
      ],
      exercises: ['warm-compresses', 'blinking-exercises']
    }
  },
  'mild-aqueous-1': {
    id: 'mild-aqueous-1',
    shortcut: 'mild aqueous',
    text: `Diagnosis: Mild Aqueous Deficient Dry Eye (ADDE). Aqueous deficient dry eye occurs when the tear glands do not produce enough of the watery component of tears, leading to insufficient tear volume. This results in a poorly hydrated ocular surface, causing symptoms like dryness, irritation, and a gritty sensation. Without adequate tear production, the eyes cannot maintain proper lubrication, leading to discomfort and potential damage to the corneal surface. Prescribed Treatment: Initial treatment recommendations are provided below. Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below. Additional treatments/therapies may be needed based on your eye’s response to treatment.`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'mild-aqueous-1',
      exercises: ['visual-hygiene', 'warm-compresses', 'blinking-exercises'],
      friendlyId: 'mild aqueous',
      otcEyeDrops: ['hydroeye', 'avenova', 'oasis-tears', 'bruder-mask', 'zocushield', 'eztears'],
      procedures: ['zest'],
      rxEyeDrops: ['vevye']
    }
  },
  'moderate-aqueous-1': {
    id: 'moderate-aqueous-1',
    shortcut: 'moderate aqueous',
    text: `Diagnosis:  Moderate Aqueous Deficient Dry Eye (ADDE).  Aqueous deficient dry eye occurs when the tear glands do not produce enough of the watery component of tears, leading to insufficient tear volume. This results in a poorly hydrated ocular surface, causing symptoms like dryness, irritation, and a gritty sensation. Without adequate tear production, the eyes cannot maintain proper lubrication, leading to discomfort and potential damage to the corneal surface.\n\nPrescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.   \n`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'moderate-aqueous-1',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      friendlyId: 'moderate aqueous',
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: ['low-level-light-therapy', 'zest'],
      rxEyeDrops: ['vevye', 'tyrvaya']
    }
  },
  'severe-aqueous-1': {
    id: 'severe-aqueous-1',
    shortcut: 'severe aqueous',
    text: `Diagnosis:  Severe Aqueous Deficient Dry Eye (ADDE).  Aqueous deficient dry eye occurs when the tear glands do not produce enough of the watery component of tears, leading to insufficient tear volume. This results in a poorly hydrated ocular surface, causing symptoms like dryness, irritation, and a gritty sensation. Without adequate tear production, the eyes cannot maintain proper lubrication, leading to discomfort and potential damage to the corneal surface.\n\nPrescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.   \n`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'severe-aqueous-1',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      friendlyId: 'severe aqueous',
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: ['low-level-light-therapy', 'zest'],
      rxEyeDrops: ['prp', 'autologous-serum', 'vevye', 'tyrvaya']
    }
  },
  'mild-evaporative-1': {
    id: 'mild-evaporative-1',
    shortcut: 'mild evaporative',
    text: `Diagnosis:  Mild Evaporative Dry Eye:  Evaporative dry eye occurs when tears evaporate too quickly from the surface of the eye, often due to meibomian gland dysfunction, which affects the oily layer of the tear film that slows evaporation. This leads to an unstable tear film and inadequate eye lubrication, causing symptoms like dryness, irritation, and a gritty sensation. Even with normal tear production, the rapid loss of tears results in discomfort and potential damage to the ocular surface.\n\nPrescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.   \n`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'mild-evaporative-1',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      friendlyId: 'mild evaporative',
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: ['low-level-light-therapy', 'zest'],
      rxEyeDrops: ['miebo']
    }
  },
  'moderate-evaporative-1': {
    id: 'moderate-evaporative-1',
    shortcut: 'moderate evaporative',
    text: `Diagnosis:  Moderate Evaporative Dry Eye:  Evaporative dry eye occurs when tears evaporate too quickly from the surface of the eye, often due to meibomian gland dysfunction, which affects the oily layer of the tear film that slows evaporation. This leads to an unstable tear film and inadequate eye lubrication, causing symptoms like dryness, irritation, and a gritty sensation. Even with normal tear production, the rapid loss of tears results in discomfort and potential damage to the ocular surface. Prescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.  `,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'moderate-evaporative-1',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      friendlyId: 'moderate evaporative',
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: [
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'low-level-light-therapy',
        'zest'
      ],
      rxEyeDrops: ['miebo', 'tyrvaya', 'eysuvis', 'vevye']
    }
  },
  'severe-evaporative-1': {
    id: 'severe-evaporative-1',
    shortcut: 'severe evaporative',
    text: `Diagnosis: Severe Evaporative Dry Eye: Evaporative dry eye occurs when tears evaporate too quickly from the surface of the eye, often due to meibomian gland dysfunction, which affects the oily layer of the tear film that slows evaporation. This leads to an unstable tear film and inadequate eye lubrication, causing symptoms like dryness, irritation, and a gritty sensation. Even with normal tear production, the rapid loss of tears results in discomfort and potential damage to the ocular surface. Prescribed Treatment: Initial treatment recommendations are provided below. Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below. Additional treatments/therapies may be needed based on your eye’s response to treatment.
    `,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'severe-evaporative-1',
      friendlyId: 'severe evaporative',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: [
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'low-level-light-therapy',
        'zest'
      ],
      rxEyeDrops: ['miebo', 'vevye', 'tyrvaya', 'eysuvis', 'prp', 'autologous-serum']
    }
  },
  'mild-mixed-1': {
    id: 'mild-mixed-1',
    shortcut: 'mild mixed',
    text: `Diagnosis:  Mild Mixed Mechanism Dry Eye:  Mixed dry eye is a condition where both aqueous deficient dry eye and evaporative dry eye occur simultaneously. In this scenario, the eyes suffer from insufficient tear production, leading to inadequate hydration of the ocular surface, while also experiencing rapid tear evaporation due to issues like meibomian gland dysfunction or an unstable tear film. This combination results in persistent dryness, irritation, and discomfort, as the eyes are unable to maintain proper lubrication and protection. Mixed dry eye requires a comprehensive approach to treatment, addressing both the lack of tear production and the factors contributing to tear evaporation. Prescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'mild-mixed-1',
      friendlyId: 'mild mixed',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: ['low-level-light-therapy', 'zest'],
      rxEyeDrops: ['miebo', 'vevye', 'tyrvaya']
    }
  },
  'moderate-mixed-1': {
    id: 'moderate-mixed-1',
    shortcut: 'moderate mixed',
    text: `Diagnosis:  Moderate Mixed Mechanism Dry Eye:  Mixed dry eye is a condition where both aqueous deficient dry eye and evaporative dry eye occur simultaneously. In this scenario, the eyes suffer from insufficient tear production, leading to inadequate hydration of the ocular surface, while also experiencing rapid tear evaporation due to issues like meibomian gland dysfunction or an unstable tear film. This combination results in persistent dryness, irritation, and discomfort, as the eyes are unable to maintain proper lubrication and protection. Mixed dry eye requires a comprehensive approach to treatment, addressing both the lack of tear production and the factors contributing to tear evaporation. Prescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'moderate-mixed-1',
      friendlyId: 'moderate mixed',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: [
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'low-level-light-therapy',
        'zest'
      ],
      rxEyeDrops: ['miebo', 'eysuvis', 'tyrvaya', 'vevye']
    }
  },
  'severe-mixed-1': {
    id: 'severe-mixed-1',
    shortcut: 'severe mixed',
    text: `Diagnosis:  Moderate Mixed Mechanism Dry Eye:  Mixed dry eye is a condition where both aqueous deficient dry eye and evaporative dry eye occur simultaneously. In this scenario, the eyes suffer from insufficient tear production, leading to inadequate hydration of the ocular surface, while also experiencing rapid tear evaporation due to issues like meibomian gland dysfunction or an unstable tear film. This combination results in persistent dryness, irritation, and discomfort, as the eyes are unable to maintain proper lubrication and protection. Mixed dry eye requires a comprehensive approach to treatment, addressing both the lack of tear production and the factors contributing to tear evaporation. Prescribed Treatment:  Initial treatment recommendations are provided below.  Relief from your symptoms and addressing the root cause of your issues won't come from any single treatment alone, but rather from the combination of therapies prescribed below.  Additional treatments/therapies may be needed based on your eye’s response to treatment.`,
    type: TextFavoriteType.Recommendations,
    showByDefault: true,
    treatmentTemplate: {
      id: 'severe-mixed-1',
      friendlyId: 'severe mixed',
      exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
      otcEyeDrops: [
        'oasis-tears-plus',
        'zocushield',
        'avenova',
        'hydroeye',
        'eztears',
        'bruder-mask'
      ],
      procedures: [
        'intense-pulsed-light-therapy-(ipl)',
        'inmode-radiofrequency-therapy-(rf)',
        'low-level-light-therapy',
        'zest'
      ],
      rxEyeDrops: ['miebo', 'tyrvaya', 'vevye', 'eysuvis', 'prp', 'autologous-serum']
    }
  }
};

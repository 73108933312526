import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { map, mergeMap } from 'rxjs/operators';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';
import { Assessment, AssessmentService } from 'src/app/core/api/assessment.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { DerfsService } from 'src/app/questionnaires/derfs/derfs.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { QuestionnaireRequestService } from './../../core/api/questionnaire-request.service';
import { QuestionnaireResponseModalComponent } from './../../shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';

interface LinkQuestionnaireToAssessmentModalData {
  questionnaire: QuestionnaireRequest;
}

@Component({
  selector: 'csi-link-questionnaire-to-assessment-modal',
  templateUrl: './link-questionnaire-to-assessment-modal.component.html',
  styleUrls: ['./link-questionnaire-to-assessment-modal.component.css']
})
export class LinkQuestionnaireToAssessmentModalComponent implements OnInit {
  public isSaving = false;

  public readonly questionnaire = this.data.questionnaire;
  public readonly assessments = this.filterAssessmentsToThreeWeeksFromCompletion(
    this.patientService.assessments
  );

  assessmentSelectFormControl = new FormControl(null, [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinkQuestionnaireToAssessmentModalData,
    private dialog: MatDialog,
    public questionnaireRequestService: QuestionnaireRequestService,
    private patientService: PatientService,
    private matDialogRef: MatDialogRef<LinkQuestionnaireToAssessmentModalComponent>,
    public derfsService: DerfsService,
    private assessmentService: AssessmentService,
    private confirmModalService: ConfirmModalService,
    public datePipe: DatePipe
  ) {}

  ngOnInit() {
    if (this.questionnaire.assessment) {
      this.assessmentSelectFormControl.setValue(this.questionnaire.assessment.id);
    }

    if (this.assessments.length === 0) {
      this.assessmentSelectFormControl.disable();
    }
  }

  showQuestionnaireResponse() {
    QuestionnaireResponseModalComponent.open(
      this.dialog,
      this.questionnaire,
      null,
      this.patientService.patient.id
    );
  }

  onLink() {
    const selectedAssessment = this.assessments.find(
      assessment => assessment.id === this.assessmentSelectFormControl.value
    );
    let confirmButtonLabel = 'Continue linking';

    let questionnaireSpecificMessage =
      'This will overwrite any existing score in the ' +
      this.questionnaire.type +
      ' symtpom field.';
    if (
      this.questionnaire.type === QuestionnaireType.CDERFS ||
      this.questionnaire.type === QuestionnaireType.DERFS
    ) {
      questionnaireSpecificMessage =
        'This will overwrite all existing information filled out in Medical History for this assessment. ';
    } else if (this.questionnaire.type === QuestionnaireType.OSDIAndSPEED) {
      questionnaireSpecificMessage =
        'This will overwrite any existing score in the OSDI and SPEED symtpom fields for this assessment. ';
    }

    this.confirmModalService
      .show(
        'Link Assessment',
        `Linking to assessment created on ${this.datePipe.transform(
          selectedAssessment.createdAt
        )}. ${questionnaireSpecificMessage} Do you still wish to continue?`,
        confirmButtonLabel,
        'Cancel'
      )
      .subscribe(continueLinking => {
        if (continueLinking) {
          this.linkAssessment();
        }
      });
  }

  linkAssessment() {
    this.assessmentSelectFormControl.markAsTouched();
    if (this.assessmentSelectFormControl.valid) {
      this.isSaving = true;

      const previousLinkedAssessment =
        this.questionnaire.assessment &&
        this.questionnaire.assessment.id &&
        this.patientService.assessments.find(
          assessment => this.questionnaire.assessment.id === assessment.id
        );
      const selectedAssessment = this.assessments.find(
        assessment => assessment.id === this.assessmentSelectFormControl.value
      );

      this.questionnaireRequestService
        .updateQuestionnaireRequest(
          {
            id: this.questionnaire.id,
            questionnaireRequestAssessmentId: selectedAssessment.id
          },
          false
        )
        .pipe(
          mergeMap(questionnaireResult => {
            const assessmentBody = selectedAssessment.body
              ? JSON.parse(selectedAssessment.body)
              : { medicalHistoryForm: {} };

            if (!assessmentBody.medicalHistoryForm) {
              assessmentBody.medicalHistoryForm = {};
            }

            this.derfsService.getDerfDataToCopy(questionnaireResult);
            assessmentBody.medicalHistoryForm = {
              ...assessmentBody.medicalHistoryForm,
              ...this.derfsService.getDerfDataToCopy(questionnaireResult)
            };

            return this.assessmentService
              .updateAssessment(
                selectedAssessment.id,
                selectedAssessment.type,
                assessmentBody,
                selectedAssessment.attachments,
                selectedAssessment.patient.id,
                selectedAssessment.doctor.id,
                selectedAssessment.locked
              )
              .pipe(map(() => questionnaireResult));
          })
        )
        .subscribe(questionnaireResult => {
          selectedAssessment.questionnaireRequests = {
            ...selectedAssessment.questionnaireRequests,
            items: selectedAssessment.questionnaireRequests
              ? [...selectedAssessment.questionnaireRequests.items, questionnaireResult]
              : [questionnaireResult]
          };

          this.questionnaire.assessment = selectedAssessment;

          if (
            previousLinkedAssessment &&
            previousLinkedAssessment.questionnaireRequests &&
            previousLinkedAssessment.questionnaireRequests.items
          ) {
            previousLinkedAssessment.questionnaireRequests = {
              ...previousLinkedAssessment.questionnaireRequests,
              items: previousLinkedAssessment.questionnaireRequests.items.filter(
                questionnaire => questionnaire.id !== questionnaireResult.id
              )
            };
          }

          this.isSaving = false;

          this.matDialogRef.close();
        });
    }
  }

  filterAssessmentsToThreeWeeksFromCompletion(assessments: Assessment[]): Assessment[] {
    const threeWeeksInMilliSeconds = 1814400000;
    const questionnaireDate = new Date(this.questionnaire.completedAt);
    questionnaireDate.setHours(0, 0, 0, 0);

    const questionnaireTime = questionnaireDate.getTime();

    const maxAssessmentDate = questionnaireTime + threeWeeksInMilliSeconds;
    const minAssessmentDate = questionnaireTime - threeWeeksInMilliSeconds;

    return assessments.filter(assessment => {
      const assessmentDate = new Date(assessment.createdAt);
      assessmentDate.setHours(0, 0, 0, 0);

      const assessmentTime = assessmentDate.getTime();

      return assessmentTime <= maxAssessmentDate && assessmentTime >= minAssessmentDate;
    });
  }
}

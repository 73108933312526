import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Assessment } from 'src/app/core/api/assessment.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { LayoutsService } from './../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { LEFT_EYE_KEY_PREFIX, RIGHT_EYE_KEY_PREFIX } from './../../shared/form.model';

export type EncountersToCompare = Assessment[];

type StatusRelativeToFirst = 'added' | 'removed' | 'changed' | 'same';
interface ComparedFields {
  [key: string]: {
    [key: string]: {
      value: any;
      statusRelativeToFirst?:
        | { left: StatusRelativeToFirst; right: StatusRelativeToFirst }
        | StatusRelativeToFirst;
    };
  };
}

interface ActionEvent {
  index: number;
  direction: 'left' | 'right';
  action: 'add' | 'remove' | 'move';
  encounter?: Assessment;
}

@Component({
  selector: 'csi-encounters-compare-modal',
  templateUrl: './encounters-compare-modal.component.html',
  styleUrls: ['./encounters-compare-modal.component.scss']
})
export class EncountersCompareModalComponent implements OnInit {
  /**
   * {
   *  medicalHistoryForm: {[fieldName]: {[date]: {value: any, statusRelativeToFirst: {value: added | removed | changed} }}},
   *  dryEyeForm: {[fieldName]: {[date]: {value: any, statusRelativeToFirst: {left: added | removed | changed, right: added | removed | changed}  }}}
   * }
   */
  public normalizedValues: {
    medicalHistoryForm: ComparedFields;
    dryEyeForm: ComparedFields;
  } = { medicalHistoryForm: {}, dryEyeForm: {} };

  public LEFT_EYE_KEY_PREFIX = LEFT_EYE_KEY_PREFIX;
  public RIGHT_EYE_KEY_PREFIX = RIGHT_EYE_KEY_PREFIX;

  public combinedMedicalHistorySet: Set<any>;
  public combinedSignsSet: Set<any>;

  public encounterDateOrder;

  public checkboxKeyValueMap = {
    ...this.schemaService.checkboxConditionMap,
    ...this.schemaService.medicalConditionMap
  };

  public fieldsToSkip = ['schemaVersion', 'EYE_MARKUP'];

  public encountersNotSelectedForComparison: Assessment[];

  public actionEvent: ActionEvent = { index: null, action: null, direction: null };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EncountersToCompare,
    public layoutsService: LayoutsService,
    public schemaService: SchemaService,
    public patientService: PatientService
  ) {
    this.normalize();
  }

  ngOnInit() {}

  public static open(dialog: MatDialog, encountersToCompare: EncountersToCompare) {
    dialog.open(EncountersCompareModalComponent, {
      data: [...encountersToCompare],
      width: '100vw',
      maxWidth: '95vw'
    });
  }

  normalize() {
    this.normalizedValues = { medicalHistoryForm: {}, dryEyeForm: {} };
    this.encounterDateOrder = this.data.map(encounter => encounter.createdAt);
    const combinedFieldsForAllEncounters = this.getCombinedFieldsForAllEncounters();

    const encounterIdSet = new Set(this.data.map(encounter => encounter.id));
    this.encountersNotSelectedForComparison = this.patientService.assessments.filter(
      assessment => !encounterIdSet.has(assessment.id)
    );

    this.combinedMedicalHistorySet = combinedFieldsForAllEncounters[0];
    this.combinedSignsSet = combinedFieldsForAllEncounters[1];

    const encountersToCompare = this.data;

    let isFirst = true;
    const firstComparedEncounterValueMap = {};

    encountersToCompare.forEach(encounter => {
      if (encounter.body) {
        this.normalizeValueForMedicalHistoryForm(
          isFirst,
          encounter,
          firstComparedEncounterValueMap
        );
        this.normalizeValueForDryEyeForm(isFirst, encounter, firstComparedEncounterValueMap);
      }

      isFirst = false;
    });
  }

  normalizeValueForMedicalHistoryForm(
    isFirst: boolean,
    encounter: Assessment,
    firstComparedEncounterValueMap = {}
  ) {
    const parsedBody = JSON.parse(encounter.body);

    this.combinedMedicalHistorySet.forEach(formKey => {
      const currentFieldValue = this.schemaService.getFriendlyValueName(
        parsedBody['medicalHistoryForm'] && parsedBody['medicalHistoryForm'][formKey],
        formKey
      );

      const comparedField = {
        value: currentFieldValue,
        statusRelativeToFirst: 'same' as StatusRelativeToFirst
      };
      if (isFirst) {
        firstComparedEncounterValueMap[formKey] =
          parsedBody['medicalHistoryForm'] && parsedBody['medicalHistoryForm'][formKey];
      } else {
        comparedField['statusRelativeToFirst'] = this.getStatusBasedOnFirstEncounter(
          firstComparedEncounterValueMap[formKey],
          currentFieldValue
        );
      }
      this.normalizedValues['medicalHistoryForm'][formKey] = {
        [encounter.createdAt]: comparedField,
        ...this.normalizedValues['medicalHistoryForm'][formKey]
      };
    });
  }

  normalizeValueForDryEyeForm(
    isFirst: boolean,
    encounter: Assessment,
    firstComparedEncounterValueMap = {}
  ) {
    const parsedBody = JSON.parse(encounter.body);

    this.combinedSignsSet.forEach(formKey => {
      const rightKey = RIGHT_EYE_KEY_PREFIX + formKey;
      const leftKey = LEFT_EYE_KEY_PREFIX + formKey;

      const [rightValue, leftValue] = [rightKey, leftKey].map(key =>
        this.schemaService.getFriendlyValueName(
          parsedBody['dryEyeForm'] && parsedBody['dryEyeForm'][key],
          formKey
        )
      );

      const comparedField = {
        value: { right: rightValue, left: leftValue },
        statusRelativeToFirst: {
          right: 'same' as StatusRelativeToFirst,
          left: 'same' as StatusRelativeToFirst
        }
      };

      if (isFirst) {
        firstComparedEncounterValueMap[rightKey] = rightValue;
        firstComparedEncounterValueMap[leftKey] = leftValue;
      } else {
        comparedField['statusRelativeToFirst'] = {
          right: this.getStatusBasedOnFirstEncounter(
            firstComparedEncounterValueMap[rightKey],
            rightValue
          ),
          left: this.getStatusBasedOnFirstEncounter(
            firstComparedEncounterValueMap[leftKey],
            leftValue
          )
        };
      }
      this.normalizedValues['dryEyeForm'][formKey] = {
        [encounter.createdAt]: comparedField,
        ...this.normalizedValues['dryEyeForm'][formKey]
      };
    });
  }

  getCombinedFieldsForAllEncounters(): [Set<any>, Set<any>] {
    let combinedMedicalHistorySet = new Set();
    let combinedSignsSetNotNormalized = new Set();

    Object.values(this.data).forEach(encounter => {
      if (encounter.body) {
        const parsedBody = JSON.parse(encounter.body);
        if (parsedBody.medicalHistoryForm) {
          combinedMedicalHistorySet = new Set([
            ...combinedMedicalHistorySet,
            ...Object.keys(parsedBody.medicalHistoryForm)
          ]);
        }
        if (parsedBody.dryEyeForm) {
          combinedSignsSetNotNormalized = new Set([
            ...combinedSignsSetNotNormalized,
            ...Object.keys(parsedBody.dryEyeForm)
          ]);
        }
      }
    });

    // Normalize Sign Keys

    const combinedSignsSet = new Set();

    this.schemaService.symptomKeys.forEach(symptomKey => {
      if (
        combinedSignsSetNotNormalized.has(LEFT_EYE_KEY_PREFIX + symptomKey) ||
        combinedSignsSetNotNormalized.has(RIGHT_EYE_KEY_PREFIX + symptomKey)
      ) {
        combinedSignsSet.add(symptomKey);
      }
    });

    this.fieldsToSkip.forEach(fieldToSkip => {
      combinedMedicalHistorySet.delete(fieldToSkip);
      combinedSignsSet.delete(fieldToSkip);
    });

    return [combinedMedicalHistorySet, combinedSignsSet];
  }

  getStatusBasedOnFirstEncounter(firstValue, currentValue): StatusRelativeToFirst {
    if (firstValue === currentValue) {
      return 'same';
    } else if (
      (firstValue === null || firstValue === undefined) &&
      (!!currentValue || currentValue === 0)
    ) {
      return 'added';
    } else if (
      (!!firstValue || firstValue === 0) &&
      (currentValue === null || currentValue === undefined)
    ) {
      return 'removed';
    } else if ((!!firstValue || firstValue === 0) && (!!currentValue || currentValue === 0)) {
      return 'changed';
    }
  }

  noSort() {
    return 0;
  }

  handleActionEvent() {
    if (this.actionEvent.action) {
      switch (this.actionEvent.action) {
        case 'add':
          this.addEncounter();
          break;
        case 'remove':
          this.removeEncounterFromCompare();
          break;
        case 'move':
          this.moveEncounter();
          break;
      }

      this.actionEvent = { index: null, action: null, direction: null };
      this.normalize();
    }
  }

  addEncounter() {
    this.data.splice(
      this.actionEvent.index + (this.actionEvent.direction === 'right' ? 1 : 0),
      0,
      this.actionEvent.encounter
    );
  }

  moveEncounter() {
    const removedEncounter = this.data.splice(this.actionEvent.index, 1)[0];

    this.data.splice(
      this.actionEvent.index + (this.actionEvent.direction === 'right' ? 1 : -1),
      0,
      removedEncounter
    );
  }

  removeEncounterFromCompare() {
    this.data.splice(this.actionEvent.index, 1);
  }
}

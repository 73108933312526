export interface NotificationMapEntry {
  title: string;
  description: string;
  insertTextConfig: { items: Array<{ label: string; textToInsert: string }> };
  default: any;
}

export const notificationMap: Record<string, NotificationMapEntry> = {
  welcomeMessage: {
    title: 'Welcome Message',
    description:
      'Sends a welcome message (email and/or text message) to new patients when they are added to the system.',
    default: {
      enabled: false,
      message: ''
    },
    insertTextConfig: {
      items: [
        { label: 'Patient first name', textToInsert: '#[patient_first_name]' },
        { label: 'Clinic name', textToInsert: '#[clinic_name]' },
        { label: 'Clinic address', textToInsert: '#[clinic_address]' },
        { label: 'Clinic phone number', textToInsert: '#[clinic_phone_number]' }
      ]
    }
  },
  questionnaireMessage: {
    title: 'Questionnaire Notification',
    description:
      'Sends a notification to patients to complete a questionnaire before their appointment.',
    default: {
      en: `Hi #[patient_first_name],

For your upcoming appointment, the doctor at #[clinic_name] has requested you fill out this #[questionnaire_type] questionnaire:
#[questionnaire_link]

By answering these questions, you are providing your eye care professional with valuable information which will allow them to spend more time communicating their findings and discussing management options. You will also expedite your check-in process. You will have two weeks to complete the questionnaire, please fill it out before your appointment.

Thank you,

#[clinic_name]
#[clinic_address]
#[clinic_phone_number]`,
      es: `Hola #[patient_first_name],

Para su próxima cita, el médico de #[clinic_name] le ha solicitado que complete este cuestionario #[questionnaire_type]:
#[questionnaire_link]

Al responder estas preguntas, está proporcionando a su profesional del cuidado de los ojos información valiosa que le permitirá dedicar más tiempo a comunicar sus hallazgos y discutir las opciones de tratamiento. También agilizará su proceso de registro. Tendrá dos semanas para completar el cuestionario, por favor complételo antes de su cita.

Gracias,

#[clinic_name]
#[clinic_address]
#[clinic_phone_number]`,
      fr: `Bonjour #[patient_first_name],

Pour votre prochain rendez-vous, le médecin de #[clinic_name] vous a demandé de remplir ce questionnaire #[questionnaire_type]:
#[questionnaire_link]

En répondant à ces questions, vous fournissez à votre professionnel des soins oculaires des informations précieuses qui lui permettront de consacrer plus de temps à communiquer ses conclusions et à discuter des options de traitement. Vous accélérerez également votre processus d'enregistrement. Vous aurez deux semaines pour remplir le questionnaire, veuillez le faire avant votre rendez-vous.

Merci,

#[clinic_name]
#[clinic_address]
#[clinic_phone_number]`
    },
    insertTextConfig: {
      items: [
        { label: 'Patient first name', textToInsert: '#[patient_first_name]' },
        { label: 'Clinic name', textToInsert: '#[clinic_name]' },
        { label: 'Clinic address', textToInsert: '#[clinic_address]' },
        { label: 'Clinic phone number', textToInsert: '#[clinic_phone_number]' },
        { label: 'Questionnaire type', textToInsert: '#[questionnaire_type]' },
        { label: 'Questionnaire link', textToInsert: '#[questionnaire_link]' }
      ]
    }
  }
};

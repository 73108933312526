import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
  selector: 'csi-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPopoverComponent {
  @Input() content: string;
  insidePopover = false;
  insideIcon = false;
  @ViewChild('popover', { static: false }) popover: SatPopover;

  constructor() {}

  onLeave(isFromPopover = false) {
    if (isFromPopover) {
      this.insidePopover = false;
    } else {
      this.insideIcon = false;
    }
    this.tryClosingPopover();
  }

  onEnter(isIntoPopover = false) {
    if (isIntoPopover) {
      this.insidePopover = true;
    } else {
      this.insideIcon = true;
    }
    this.popover.open();
  }

  private tryClosingPopover() {
    setTimeout(() => {
      if (!this.insidePopover && !this.insideIcon) {
        this.popover.close();
      }
    }, 150);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatButtonToggleModule, MatDialogModule } from '@angular/material';
import { SharedModule } from 'src/app/shared/shared.module';
import { DryEyeMimickersComponent } from './dry-eye-mimickers/dry-eye-mimickers.component';
import { FalseOcularSurfaceDiseaseComponent } from './false-ocular-surface-disease/false-ocular-surface-disease.component';
import { InterpretationComponent } from './interpretation.component';
import { TrueOcularSurfaceDiseaseComponent } from './true-ocular-surface-disease/true-ocular-surface-disease.component';

@NgModule({
  declarations: [
    InterpretationComponent,
    TrueOcularSurfaceDiseaseComponent,
    FalseOcularSurfaceDiseaseComponent,
    DryEyeMimickersComponent
  ],
  imports: [CommonModule, SharedModule, MatDialogModule, MatButtonModule, MatButtonToggleModule],
  exports: [InterpretationComponent],
  entryComponents: [InterpretationComponent]
})
export class InterpretationModule {}

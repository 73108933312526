import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export interface DryEyeAssessmentFormControls {
  images: FormControl;
  impressions: FormControl;
  category: FormControl;
  recommendedPlanByDoctor: FormControl;
  recommendedEyeDrops: FormControl;
  recommendedRxEyeDrops: FormControl;
  recommendedExercises: FormControl;
  recommendedProcedures: FormControl;
  goalsForPatient: FormControl;
  treatmentPerformed: FormControl;
}

export class DryEyeAssessmentFormGroup extends FormGroup {
  controls: DryEyeAssessmentFormControls & { [key: string]: AbstractControl };
  submitted = false;

  constructor() {
    super({
      impressions: new FormControl('', Validators.required),
      category: new FormControl(''),
      images: new FormControl([]),
      recommendedPlanByDoctor: new FormControl('', Validators.required),
      recommendedEyeDrops: new FormControl([]),
      recommendedRxEyeDrops: new FormControl([]),
      recommendedExercises: new FormControl([]),
      recommendedProcedures: new FormControl([]),
      goalsForPatient: new FormControl(''),
      treatmentPerformed: new FormControl('')
    });
  }
}

import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Logger } from 'aws-amplify';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { Clinic } from 'src/API';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { FormTemplateInputFormGroup } from 'src/app/form-template/form-template-input-form-group';
import { S3AttachmentService } from 'src/app/shared/attachments/s3-attachment.service';
import { defaultLogoUri } from 'src/app/shared/dry-eye-pdf-export/default-logo-uri.model';

const componentStyle = require('./questionnaire-pdf-export.component.css');

@Component({
  selector: 'csi-questionnaire-pdf-export',
  templateUrl: './questionnaire-pdf-export.component.html',
  styleUrls: ['./questionnaire-pdf-export.component.css']
})
export class QuestionnairePdfExportComponent implements OnInit {
  @Input() data;
  @Input() clinic: Clinic;
  @Input() clinicLogo;

  public clinicLogoDataUri: SafeUrl;
  public defaultLogo = defaultLogoUri;

  private logger: Logger = new Logger('PDFExportService');
  public formGroup: FormTemplateInputFormGroup;

  constructor(
    private s3AttachmentService: S3AttachmentService,
    private hostElementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    public questionnaireRequestService: QuestionnaireRequestService
  ) {}

  ngOnInit() {
    this.formGroup = new FormTemplateInputFormGroup(this.data.questionnaireResponseData.schema);
    this.formGroup.patchValue(this.data.questionnaireResponseData.formGroupWithValues.value);
  }

  get html(): Observable<string> {
    this.logger.debug('rendering pdf html');
    return this.render().pipe(
      tap(() => this.changeDetector.detectChanges()),
      delay(0),
      map(() => {
        this.changeDetector.detectChanges();
        this.logger.debug('calling innerHTMLWithStyle');
        const html = this.innerHTMLWithStyle;
        return html;
      })
    );
  }

  private render(): Observable<boolean> {
    this.logger.debug('render() called');
    return this.clinicLogoDataUri ? of(true) : this.renderClinicLogo();
  }
  private renderClinicLogo(): Observable<boolean> {
    this.logger.debug('rendering clinic logo');
    return of(this.clinicLogo).pipe(
      switchMap(logo => (logo ? this.s3AttachmentService.s3ObjectToUri(logo) : of(null))),
      map(logoDataUri => {
        this.logger.debug('setting logo data uri');
        this.clinicLogoDataUri = logoDataUri;
        return true;
      })
    );
  }

  private get innerHTMLWithStyle(): string {
    this.logger.debug('setting inner html');
    return this.hostElementRef.nativeElement.innerHTML.replace(
      '</head>',
      `<style>${componentStyle.default}</style></head>`
    );
  }
  noSort() {
    return 0;
  }
}

import { startCase } from 'lodash-es';
import { merge, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  DryEyeCategory,
  isDryEyeCategoryEqual
} from '../../../../../../core/remote-json/treatments/dry-eye-category.model';
import { Treatment } from '../../../../../../core/remote-json/treatments/treatments.service';
import { EConsultFormGroup } from '../../../../../../econsult/econsult-form.model';
import { leftEyeKey, rightEyeKey } from '../../../../../form.model';
import {
  SymptomKey,
  TelangiectasiaSeverity
} from '../../../../../symptoms/services/schema/schema.model';
import { FilterShouldActivate, TreatmentFilter } from '../treatment-filter.model';

export class ByDryEyeCategory implements TreatmentFilter {
  private category: DryEyeCategory = null;
  private isTelangiectasiaActive: boolean;

  public selectedByDefault = false;

  listensTo(formGroup: EConsultFormGroup): Observable<FilterShouldActivate> {
    const telangiectasia$ = formGroup.dryEyeFormControls.dryEyeForm.valueChanges.pipe(
      debounceTime(500),
      map(value => {
        this.setTelangiectasia(value);
        return new FilterShouldActivate(this, this.selectedByDefault);
      })
    );
    const category$ = formGroup.dryEyeFormControls.assessmentForm.controls.category.valueChanges.pipe(
      map((value: DryEyeCategory) => {
        if (value.severity && value.type) {
          this.setCategory(value);
        }
        return new FilterShouldActivate(this, !!value);
      })
    );
    return merge(category$, telangiectasia$);
  }

  get name(): string {
    return this.category
      ? `${startCase(`${this.category.severity} ${this.category.type}`)}${
          this.isTelangiectasiaActive ? ' with Telangiectasia' : ''
        }`
      : '';
  }

  get hidden(): boolean {
    return !this.category;
  }

  filter(treatment: Treatment): boolean {
    if (!this.category) {
      return true;
    } else if (treatment.indicationsRule) {
      const matchesIndications =
        treatment.indicationsRule.categories &&
        treatment.indicationsRule.categories.some(category =>
          isDryEyeCategoryEqual(category, this.category)
        );

      const passesTelangiectasia = treatment.indicationsRule.telangiectasia
        ? this.isTelangiectasiaActive
        : true;
      return matchesIndications && passesTelangiectasia;
    } else {
      return false;
    }
  }

  isPopularFor(treatment: Treatment): string {
    if (
      treatment.indicationsRule &&
      this.category &&
      treatment.indicationsRule.categories &&
      treatment.indicationsRule.categories.some(
        category => category.popularChoice && isDryEyeCategoryEqual(category, this.category)
      )
    ) {
      return `${this.category.severity} ${this.category.type}`;
    } else {
      return null;
    }
  }

  private setCategory(newCategory: DryEyeCategory) {
    this.category = newCategory;
  }

  private setTelangiectasia(control: { [key: string]: string }) {
    const leftEye = control[leftEyeKey(SymptomKey.TELANGIECTASIA)];
    const rightEye = control[rightEyeKey(SymptomKey.TELANGIECTASIA)];
    this.isTelangiectasiaActive =
      leftEye === TelangiectasiaSeverity.MODERATE ||
      leftEye === TelangiectasiaSeverity.SEVERE ||
      rightEye === TelangiectasiaSeverity.MODERATE ||
      rightEye === TelangiectasiaSeverity.SEVERE;
  }
}

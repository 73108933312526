import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFieldConfig } from 'src/app/form-template/form-template-model';
import { phoneNumberValidator } from 'src/app/shared/shared-validators/phone-number-validator';
import { HealthCardFormGroup } from '../shared/patient-form/patient-form.model';
import { CustomValidator } from './../intake-form/intake-form-validators';
import {
  ConfigType,
  FormTemplateGroup,
  FormTemplateSchema,
  ValueConfig
} from './form-template-model';

let translocoGroup = {};
class FormTemplateCheckboxFieldFormGroup extends FormGroup {
  constructor(formFieldConfig: FormFieldConfig, formFieldKey: string) {
    super(
      Object.keys(formFieldConfig.typeConfig['valuesConfig']).reduce((acc, valueConfigKey) => {
        translocoGroup[valueConfigKey] =
          formFieldConfig.typeConfig['valuesConfig'][valueConfigKey].label;
        acc[valueConfigKey] = new FormControl();
        return acc;
      }, {}),
      formFieldConfig.required ? CustomValidator.atLeastOneCheckBoxRequired : null
    );
  }
}

class FormTemplateGroupFormGroup extends FormGroup {
  constructor(formTemplateGroup: FormTemplateGroup, formTemplateSchema: FormTemplateSchema) {
    super(
      Object.entries(formTemplateGroup.formFields).reduce(
        (formFieldsAcc, [formFieldKey, formFieldValue]) => {
          // Checkbox
          translocoGroup[formFieldKey] = formFieldValue.label;

          formFieldsAcc[formFieldKey] = createFormTemplateControl(formFieldValue, formFieldKey);

          // typeConfig have valuesConfig
          if (formFieldValue.typeConfig['valuesConfig']) {
            Object.entries(formFieldValue.typeConfig['valuesConfig']).forEach(
              ([valueKey, valueConfig]: [string, ValueConfig]) => {
                translocoGroup[valueKey] = valueConfig.label;
                if (valueConfig.subFieldTitle) {
                  translocoGroup[formFieldKey + '-' + valueKey + '-subFieldTitle'] =
                    valueConfig.subFieldTitle;
                }

                if (valueConfig.subFieldIdList && valueConfig.subFieldIdList.length > 0) {
                  valueConfig.subFieldIdList.forEach(subFieldIdConfig => {
                    const newSubFieldControl = createFormTemplateControl(
                      formTemplateSchema.supportingFields[subFieldIdConfig.id],
                      subFieldIdConfig.id
                    );

                    if (formFieldValue.typeConfig.type === ConfigType.checkbox) {
                      // console.log('CHECKBOX', valueKey + '-' + subFieldIdConfig.id);
                      translocoGroup[subFieldIdConfig.id] =
                        formTemplateSchema.supportingFields[subFieldIdConfig.id].label;
                      (formFieldsAcc[formFieldKey] as FormGroup).addControl(
                        valueKey + '-' + subFieldIdConfig.id,
                        newSubFieldControl
                      );
                      generateSubFields(
                        subFieldIdConfig.id,
                        valueKey + '-' + subFieldIdConfig.id,
                        formTemplateSchema.supportingFields[subFieldIdConfig.id],
                        formFieldsAcc[formFieldKey],
                        formTemplateSchema
                      );
                      return;
                    }
                    formFieldsAcc[
                      formFieldKey + '-' + valueKey + '-' + subFieldIdConfig.id
                    ] = newSubFieldControl;
                    translocoGroup[subFieldIdConfig.id] =
                      formTemplateSchema.supportingFields[subFieldIdConfig.id].label;
                    generateSubFields(
                      subFieldIdConfig.id,
                      formFieldKey + '-' + valueKey + '-' + subFieldIdConfig.id,
                      formTemplateSchema.supportingFields[subFieldIdConfig.id],
                      formFieldsAcc,
                      formTemplateSchema
                    );
                  });
                }
              }
            );
          }
          // console.log('CUSTOM FORM CONTROLS: ', formFieldsAcc);
          return formFieldsAcc;
        },
        {}
      )
    );
  }
}

function generateSubFields(
  formFieldKey: string,
  parentFormFieldKey: string,
  formFieldValue: any,
  formFieldsAcc: any,
  formTemplateSchema: FormTemplateSchema
) {
  if (formFieldValue.typeConfig['valuesConfig']) {
    Object.entries(formFieldValue.typeConfig['valuesConfig']).forEach(
      ([valueKey, valueConfig]: [string, ValueConfig]) => {
        if (valueConfig.subFieldTitle) {
          translocoGroup[formFieldKey + '-' + valueKey + '-subFieldTitle'] =
            valueConfig.subFieldTitle;
        }
        if (valueConfig.subFieldIdList && valueConfig.subFieldIdList.length > 0) {
          valueConfig.subFieldIdList.forEach(subFieldIdConfig => {
            const newSubFieldControl = createFormTemplateControl(
              formTemplateSchema.supportingFields[subFieldIdConfig.id],
              subFieldIdConfig.id
            );

            if (formFieldValue.typeConfig.type === ConfigType.checkbox) {
              (formFieldsAcc[parentFormFieldKey] as FormGroup).addControl(
                valueKey + '-' + subFieldIdConfig.id,
                newSubFieldControl
              );
              translocoGroup[subFieldIdConfig.id] =
                formTemplateSchema.supportingFields[subFieldIdConfig.id].label;
              generateSubFields(
                subFieldIdConfig.id,
                valueKey + '-' + subFieldIdConfig.id,
                formTemplateSchema.supportingFields[subFieldIdConfig.id],
                formFieldsAcc[formFieldKey],
                formTemplateSchema
              );
              return;
            }
            generateSubFields(
              subFieldIdConfig.id,
              formFieldKey + '-' + valueKey + '-' + subFieldIdConfig.id,
              formTemplateSchema.supportingFields[subFieldIdConfig.id],
              formFieldsAcc,
              formTemplateSchema
            );
            formFieldsAcc[
              formFieldKey + '-' + valueKey + '-' + subFieldIdConfig.id
            ] = newSubFieldControl;
            translocoGroup[subFieldIdConfig.id] =
              formTemplateSchema.supportingFields[subFieldIdConfig.id].label;
          });
        }
      }
    );
  }
}

export class FormTemplateInputFormGroup extends FormGroup {
  constructor(formTemplateSchema: FormTemplateSchema) {
    super(
      Object.entries(formTemplateSchema.templateGroups).reduce(
        (formTemplateGroupsAcc, [formTemplateGroupKey, formTemplateGroupValue]) => {
          translocoGroup['questionnaire-id'] = {
            ...translocoGroup['questionnaire-id'],
            ...{
              formTemplateGroupKey: {
                title: formTemplateGroupValue.title,
                nextLabel: formTemplateGroupValue.nextLabel || 'Next'
              }
            }
          };
          formTemplateGroupsAcc[formTemplateGroupKey] = new FormTemplateGroupFormGroup(
            formTemplateGroupValue,
            formTemplateSchema
          );
          return formTemplateGroupsAcc;
        },
        {}
      )
    );
  }
}

function createFormTemplateControl(formFieldConfig: FormFieldConfig, formFieldKey: string) {
  switch (formFieldConfig.typeConfig.type) {
    case ConfigType.checkbox:
      return new FormTemplateCheckboxFieldFormGroup(formFieldConfig, formFieldKey);
    case ConfigType.healthcard:
      return new HealthCardFormGroup();
    case ConfigType.slider:
      return new FormControl(formFieldConfig.typeConfig.min - formFieldConfig.typeConfig.step, [
        formFieldConfig.required ? Validators.required : null,
        Validators.min(formFieldConfig.typeConfig.min)
      ]);
    case ConfigType.email:
      return new FormControl(
        null,
        [(formFieldConfig.required && Validators.required) || null, Validators.email].filter(
          Boolean
        )
      );
    case ConfigType.phone:
      return new FormControl(
        null,
        [(formFieldConfig.required && Validators.required) || null, phoneNumberValidator].filter(
          Boolean
        )
      );
    default:
      return new FormControl(null, formFieldConfig.required ? Validators.required : null);
  }
}

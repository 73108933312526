import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { AppModule } from './app/app.module';
import awsmobile from './aws-exports';
import { environment } from './environments/environment';

awsmobile['aws_appsync_graphqlEndpoint'] = environment.appSyncGraphQLEndpoint;

Amplify.configure(awsmobile);
Auth.configure({
  // Configure auth storage to use window.sessionStorage instead of window.localStorage
  // using window.localStorage we get "Remember Me" like behavior, using sessionStorage
  // gives us the opposite
  cookieStorage: environment.cookieStorageConfig
});

// Disable pinpoint analytics to resolve https://github.com/aws-amplify/amplify-js/issues/3484
Analytics.configure({
  disabled: true
});

if (environment.production) {
  enableProdMode();
} else {
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';

@Component({
  selector: 'csi-media-modal',
  templateUrl: './media-modal.component.html',
  styleUrls: ['./media-modal.component.scss']
})
export class MediaModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MediaModalComponent>,
    public dataSrcService: DataSrcService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  public static open(
    dialog: MatDialog,
    mediaSrc: string,
    fileType: string,
    autoPlay?: boolean,
    reference?: string
  ): MatDialogRef<MediaModalComponent> {
    return dialog.open(MediaModalComponent, {
      data: {
        mediaSrc: mediaSrc,
        fileType: fileType,
        autoPlay: autoPlay,
        reference: reference
      }
    });
  }
}

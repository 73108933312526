import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { StaffService } from 'src/app/core/api/staff.service';
import { Attachment, AttachmentsComponent } from 'src/app/shared/attachments/attachments.component';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { MediaSelectionModalComponent } from 'src/app/shared/media-selection-modal/media-selection-modal.component';
import { SignMediaSelectionModalComponent } from '../sign-media-selection-modal/sign-media-selection-modal.component';
import { SignsAttachmentsService } from '../signs-attachments-modal/signs-attachments.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SignAttachmentsComponent),
  multi: true
};

@Component({
  selector: 'csi-sign-attachments',
  templateUrl: './sign-attachments.component.html',
  styleUrls: ['./sign-attachments.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SignAttachmentsComponent extends AttachmentsComponent
  implements OnInit, ControlValueAccessor {
  @Input() signName: string;
  @Input() imageGalleryEnabled = true;

  constructor(
    datePipe: DatePipe,
    dialog: MatDialog,
    staffService: StaffService,
    dataSrcService: DataSrcService,
    signsAttachmentsService: SignsAttachmentsService,
    loadingSpinnerService: LoadingSpinnerService
  ) {
    super(
      datePipe,
      dialog,
      staffService,
      dataSrcService,
      signsAttachmentsService,
      loadingSpinnerService
    );
  }

  ngOnInit() {
    if (this.maxAttachments && this.maxAttachments === 1) {
      this.formControl.valueChanges.subscribe(attachments => {
        this.checkAttachmentsLimit(attachments);
      });
    }
  }

  checkAttachmentsLimit(attachments: Attachment[]) {
    // console.log('dialog', this.dialog);
    if (attachments && attachments.length > this.maxAttachments) {
      this.openMediaSelectionModal(attachments);
      // console.log('open dialog', this.dialog.openDialogs);
      // this.matDialogService.joinQueue().subscribe(() => {
      //   this.openMediaSelectionModal(attachments);
      // });
      // setTimeout(() => {
      //   if (this.dialog.openDialogs.length >= 2) {
      //     this.dialog.openDialogs[this.dialog.openDialogs.length - 1]
      //       .afterClosed()
      //       .subscribe(() => {
      //         this.subscribeToDialogCloseEvent(attachments);
      //       });
      //   } else {
      //     this.openMediaSelectionModal(attachments);
      //   }
      // });
    }
  }

  subscribeToDialogCloseEvent(attachments: Attachment[]) {
    if (this.dialog.openDialogs.length >= 2) {
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].afterClosed().subscribe(() => {
        this.openMediaSelectionModal(attachments);
      });
    } else {
      this.openMediaSelectionModal(attachments);
    }
  }

  openMediaSelectionModal(attachments: Attachment[]) {
    const title =
      'Maximum number of attachments exceeded for ' +
      this.signsAttachmentsService.getEyeLocationFromMetadata(this.metadata) +
      ' ' +
      this.signName;
    const mediaDialogRef = this.dialog.open(MediaSelectionModalComponent, {
      width: '1200px',
      maxWidth: '75vw',
      data: {
        attachments,
        title
      },
      disableClose: true
    });
    mediaDialogRef.afterClosed().subscribe(selectedAttachment => {
      if (selectedAttachment) {
        this.attachments = [selectedAttachment];
        this.updateControlValue();
      }
    });
  }

  selectMedia() {
    const dialogRef = SignMediaSelectionModalComponent.open(
      this.dialog,
      this.metadata,
      this.formControl
    );

    dialogRef.afterClosed().subscribe(attachment => {
      if (attachment) {
        if (attachment instanceof File) {
          this.dataSrcService.copyFile(attachment).then(file => {
            this.addAttachment(file);
          });
        } else {
          this.addAttachment({ ...attachment });
        }
      }
    });
  }

  private addAttachment(attachment: Attachment) {
    attachment['metadata'] = JSON.stringify(this.metadata);
    this.attachments = [...this.attachments, attachment];
    this.updateControlValue();
  }

  protected updateControlValue() {
    this.onChangeCallback(this.attachments);
    this.signsAttachmentsService.updateAttachments();
  }
}

import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import capitalize from 'lodash-es/capitalize';
import { MultiKeySymptomControl } from '../../../abstract-symptom-control/abstract-symptom-control.component';
import { ModalSymptomCustomModalOptions, ModalSymptomTypes } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'csi-quantitative-corneal-staining-modal',
  templateUrl: './quantitative-corneal-staining-modal.component.html',
  styleUrls: ['./quantitative-corneal-staining-modal.component.css']
})
export class QuantitativeCornealStainingModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements AfterViewInit, AfterViewChecked, MultiKeySymptomControl {
  @ViewChild('additionalFluoresceinStainingModal', { static: true }) additionalFluoresceinStainingModal: {
    showModal: () => void;
  };

  public readonly internalFormGroup = new FormGroup({});

  public readonly corneaKey = 'CORNEA';
  public readonly corneaSymptom = {
    modal: {
      type: ModalSymptomTypes.Simple,
      title: 'Fluorescein Staining',
      values: {
        grade0: '0 Dots (Grade 0)',
        grade1: '1-5 Dots (Grade 1)',
        grade2: '6-30 Dots (Grade 2)',
        grade3: '> 30 Dots (Grade 3)'
      },
      media: {
        grade0: 'assets/images/modal-images/fluorescein-staining/grade0.jpg',
        grade1: 'assets/images/modal-images/fluorescein-staining/grade1.jpg',
        grade2: 'assets/images/modal-images/fluorescein-staining/grade2.jpg',
        grade3: 'assets/images/modal-images/fluorescein-staining/grade3.jpg'
      }
    }
  };

  public readonly leftConjunctivaKey = 'LEFT_CONJUNCTIVA';
  public readonly rightConjunctivaKey = 'RIGHT_CONJUNCTIVA';

  public readonly conjunctivaSymptom = {
    modal: {
      type: ModalSymptomTypes.Simple,
      title: 'Lissamine Green Staining',
      values: {
        grade0: '0-9 Dots (Grade 0)',
        grade1: '10-32 Dots (Grade 1)',
        grade2: '33-100 Dots (Grade 2)',
        grade3: '> 100 Dots (Grade 3)'
      },
      media: {
        grade0: 'assets/images/modal-images/lissamine-green-staining/grade0.png',
        grade1: 'assets/images/modal-images/lissamine-green-staining/grade1.png',
        grade2: 'assets/images/modal-images/lissamine-green-staining/grade2.png',
        grade3: 'assets/images/modal-images/lissamine-green-staining/grade3.png'
      }
    }
  };

  public readonly additionalFluoresceinGradingKey = 'ADDITIONAL_FLUORESCEIN_GRADING';
  public readonly additionalFluoresceinSymptom = {
    modal: {
      type: ModalSymptomTypes.Custom,
      values: {
        patches: 'Patches of Confluent Staining',
        pupillary: 'Staining in Pupillary Area',
        filaments: 'One or More Filaments'
      },
      valueMap: function(value: { [key: string]: boolean }): string {
        const trueKeys = Object.keys(value).filter(valueKey => value[valueKey]);
        if (trueKeys.length === 0) {
          return 'None';
        } else if (trueKeys.length === 1) {
          return this.values[trueKeys[0]];
        } else {
          return trueKeys.map(valueKey => `- ${this.values[valueKey]}`).join('\n');
        }
      },
      componentName: 'AdditionalFluoresceinGradingModalComponent'
    }
  };

  private loading = false;

  constructor(
    private dialogRef: MatDialogRef<QuantitativeCornealStainingModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngAfterViewInit() {
    this.loading = true;

    this.loadInternalControl(this.rightConjunctivaKey);
    this.loadInternalControl(this.leftConjunctivaKey);
    this.loadInternalControl(this.corneaKey);
    this.loadInternalControl(this.additionalFluoresceinGradingKey);
  }

  ngAfterViewChecked() {
    this.loading = false;
  }

  submit() {
    if (this.allValuesFilled) {
      this.saveInternalControl(this.rightConjunctivaKey);
      this.saveInternalControl(this.leftConjunctivaKey);
      this.saveInternalControl(this.corneaKey);
      this.saveInternalControl(this.additionalFluoresceinGradingKey);

      this.formGroup.get(this.formKey).setValue(this.value);

      this.dialogRef.close();
    }
  }

  getAllPossibleSymptomControlKeys(): string[] {
    return [
      this.formKey,
      this.getExternalKeyFromInternalKey(this.rightConjunctivaKey),
      this.getExternalKeyFromInternalKey(this.leftConjunctivaKey),
      this.getExternalKeyFromInternalKey(this.corneaKey),
      this.getExternalKeyFromInternalKey(this.additionalFluoresceinGradingKey)
    ];
  }

  showAdditionalFluoresceinStainingModal() {
    if (!this.loading) {
      this.additionalFluoresceinStainingModal.showModal();
    }
  }

  public get totalScore(): number {
    const lissamineScoreTable = {
      grade0: 0,
      grade1: 1,
      grade2: 2,
      grade3: 3
    };
    const fluoresceinStainingScoreTable = {
      grade0: 0,
      grade1: 1,
      grade2: 2,
      grade3: 3
    };

    const rightLissamineValue = this.internalFormGroup.get(this.rightConjunctivaKey).value;
    const leftLissamineValue = this.internalFormGroup.get(this.leftConjunctivaKey).value;

    const rightLissamineScore = lissamineScoreTable[rightLissamineValue];
    const leftLissamineScore = lissamineScoreTable[leftLissamineValue];

    const additionalFluoresceinValues = this.internalFormGroup.get(
      this.additionalFluoresceinGradingKey
    ).value;
    const additionalFluoresceinScore = Object.keys(additionalFluoresceinValues).filter(
      valueKey => additionalFluoresceinValues[valueKey]
    ).length;

    const fluoresceinValue = this.internalFormGroup.get(this.corneaKey).value;
    const fluoresceinScore =
      fluoresceinStainingScoreTable[fluoresceinValue] + additionalFluoresceinScore;

    return rightLissamineScore + leftLissamineScore + fluoresceinScore;
  }

  public get scoreClassDisplay(): string {
    return capitalize(this.modalConfig.values[this.value].split(' ')[1]);
  }

  public get value() {
    const score = this.totalScore;
    if (score < 3) {
      return 'normal';
    } else if (score < 6) {
      return 'mild';
    } else if (score < 9) {
      return 'moderate';
    } else {
      return 'severe';
    }
  }

  public get hasDoneCornealStaining(): boolean {
    return !!this.internalFormGroup.get(this.corneaKey).value;
  }

  public get hasAdditionalFluoresceinStaining(): boolean {
    const additionalFluoresceinStainingControl = this.internalFormGroup.get(
      this.additionalFluoresceinGradingKey
    );

    return (
      additionalFluoresceinStainingControl &&
      additionalFluoresceinStainingControl.value &&
      Object.keys(additionalFluoresceinStainingControl.value).some(
        valueKey => additionalFluoresceinStainingControl.value[valueKey]
      )
    );
  }

  public get additionalFluoresceinStainingCount(): number {
    const additionalFluoresceinStainingControl = this.internalFormGroup.get(
      this.additionalFluoresceinGradingKey
    );

    return additionalFluoresceinStainingControl && additionalFluoresceinStainingControl.value
      ? Object.keys(additionalFluoresceinStainingControl.value).filter(
          valueKey => additionalFluoresceinStainingControl.value[valueKey]
        ).length
      : 0;
  }

  public get allValuesFilled(): boolean {
    return Object.keys(this.internalFormGroup.controls)
      .map(controlKey => this.internalFormGroup.controls[controlKey])
      .every(control => !!control.value);
  }

  private saveInternalControl(key: string) {
    const internalControl = this.internalFormGroup.get(key);

    const externalKey = this.getExternalKeyFromInternalKey(key);
    const externalControl = this.formGroup.get(externalKey);

    if (externalControl) {
      externalControl.setValue(internalControl.value);
    } else {
      this.formGroup.addControl(externalKey, new FormControl(internalControl.value));
    }
  }

  private loadInternalControl(key: string) {
    const internalControl = this.internalFormGroup.get(key);

    const externalKey = this.getExternalKeyFromInternalKey(key);
    const externalControl = this.formGroup.get(externalKey);

    if (externalControl) {
      internalControl.setValue(externalControl.value);
    }

    if (this.isDisabled) {
      internalControl.disable();
    }
  }
}

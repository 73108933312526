import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssessmentBody, DryEyeAssessmentForm } from 'src/app/econsult/assessment-body/assessment-body.model';
import { AssessmentUtils } from '../api/assessment-utils';
import { Assessment, AssessmentRequest } from '../api/assessment.service';
import { Clinic } from '../api/clinic.service';
import {
  EyeDrop,
  Therapy,
  Treatment,
  TreatmentsService
} from '../remote-json/treatments/treatments.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentBodyParserService {
  constructor(private treatmentsService: TreatmentsService) {}

  transform(value: string | Assessment | AssessmentRequest): Observable<AssessmentBody> {
    switch ((<Assessment | AssessmentRequest>value).__typename) {
      case 'Assessment':
        return this.parseBody(
          (value as Assessment).body,
          ...this.getParseBodyOptionalArgs(<Assessment>value)
        );

      case 'AssessmentRequest':
        return this.parseBody(
          (value as AssessmentRequest).assessment.body,
          ...this.getParseBodyOptionalArgs(<AssessmentRequest>value)
        );
      default:
        return of(JSON.parse(value as string));
    }
  }

  private getParseBodyOptionalArgs(value: Assessment | AssessmentRequest): any[] {
    if (AssessmentUtils.isAssessment(value)) {
      const assessment: Assessment = <Assessment>value;
      return [
        assessment.id,
        assessment.doctor ? assessment.doctor.id : undefined,
        assessment.patient ? assessment.patient.id : undefined
      ];
    } else {
      const assessmentRequest: AssessmentRequest = <AssessmentRequest>value;
      return [
        assessmentRequest.assessment.id,
        assessmentRequest.doctor ? assessmentRequest.doctor.id : undefined,
        assessmentRequest.assessment.patient ? assessmentRequest.assessment.patient : undefined
      ];
    }
  }

  private parseBody(
    value: string,
    assessmentId?: string,
    doctorId?: string,
    patientId?: string
  ): Observable<AssessmentBody> {
    const body: AssessmentBody = JSON.parse(value);

    // older versions of body did not have ids set in it - so add them manually
    if (body.patientInformationForm) {
      body.patientInformationForm.id = body.patientInformationForm.id || patientId;
      body.patientInformationForm.patientDoctorId =
        body.patientInformationForm.patientDoctorId || doctorId;
    }
    body.id = body.id || assessmentId;
    // 05/30/2019 - Chris Kinzel: Migrate clinic information attached to doctor to standalone field
    //                            in assessment body
    if (!body.clinicInformationForm && body.doctorInformationForm) {
      body.clinicInformationForm = body.doctorInformationForm.clinic as Clinic;
    }

    const newBody$ = this.updateRecommendedTreatments(body);

    return newBody$;
  }

  private updateRecommendedTreatments(body: AssessmentBody): Observable<AssessmentBody> {
    const assessment: DryEyeAssessmentForm = body.assessmentForm;
    if (assessment) {
      const recommendedRx$ = this.updateTreatment(assessment.recommendedRxEyeDrops as Treatment[]);
      const recommendedOtc$ = this.updateTreatment(assessment.recommendedEyeDrops as Treatment[]);
      const recommendedProc$ = this.updateTreatment(
        assessment.recommendedProcedures as Treatment[]
      );
      const recommendedExer$ = this.updateTreatment(assessment.recommendedExercises as Treatment[]);

      return of(body).pipe(
        switchMap(value =>
          recommendedRx$.pipe(
            map(val => {
              value.assessmentForm.recommendedRxEyeDrops = val as EyeDrop[];
              return value;
            })
          )
        ),
        switchMap((value: AssessmentBody) =>
          recommendedOtc$.pipe(
            map(val => {
              value.assessmentForm.recommendedEyeDrops = val as EyeDrop[];
              return value;
            })
          )
        ),
        switchMap((value: AssessmentBody) =>
          recommendedProc$.pipe(
            map(val => {
              value.assessmentForm.recommendedProcedures = val as Therapy[];
              return value;
            })
          )
        ),
        switchMap((value: AssessmentBody) =>
          recommendedExer$.pipe(
            map(val => {
              value.assessmentForm.recommendedExercises = val as Therapy[];
              return value;
            })
          )
        )
      );
    }
    return of(body);
  }

  private updateTreatment(treatment: Treatment[]): Observable<Treatment[]> {
    return treatment && treatment.length > 0
      ? forkJoin(treatment.map(eyeDrop => this.treatmentsService.getTreatmentById(eyeDrop.id)))
      : of([]);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { StaffService } from 'src/app/core/api/staff.service';
import { LoggedInUserResolver } from 'src/app/core/route-resolvers/logged-in-user.resolver';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root'
})
export class DryEyeExpertPanelGuard implements CanActivate {
  constructor(
    private loggedInUserResolver: LoggedInUserResolver,
    private authGuard: AuthGuard,
    private staffService: StaffService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(next, state).pipe(
      switchMap(loggedIn => {
        if (loggedIn) {
          const loggedInUser$: Observable<Doctor> = this.loggedInUserResolver.resolve(next, state);

          return loggedInUser$.pipe(
            map(doctor => {
              const approved = this.staffService.isDoctorApprovedForDryEyeSpecializedForm(doctor);

              if (!approved) {
                if (state.url.includes('refer')) {
                  return true;
                } else {
                  this.router.navigateByUrl('/refer');
                }
              }
              return approved;
            })
          );
        }
      })
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { gql } from 'graphql-tag';
import { switchMap } from 'rxjs/operators';
import { Interaction, UpdatePatientInput } from 'src/API';
import { PatientService } from 'src/app/core/api/patient.service';
import { SendQuestionnaireDialogComponent } from 'src/app/shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import { CreateInteractionInput, CreateInteractionMutation } from './../../../../API';
import { AppSyncService } from './../../../core/appsync.service';
import { ClinicSetupService } from './../../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { InteractionActionsModalComponent } from './interaction-actions-modal/interaction-actions-modal.component';

@Component({
  selector: 'csi-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.css']
})
export class InteractionsComponent implements OnInit {
  @Input() showInteractionButton: boolean = true;
  private __interactions: Interaction[];
  @Input() set interactions(interactions: Interaction[]) {
    this.__interactions = interactions;
    this.sortInteractionByCategories();
    this.numberOfLifeLeft();
  }
  get interactions() {
    return this.__interactions;
  }

  public statuses = this.clinicSetupService.interactionsSetup.statuses;
  public recentCommunicationMethod: string;
  public numberOfLifeLine: string;
  public daysLeft: number;

  public interactionsSortedByCategories: {
    communication: {
      failedCommunications: number;
      interactions: Interaction[];
    };
    surgery: {
      lastSurgeryBookedDate: string;
      nextSurgeryBookedDate: string;
      lastSurgeryType: string;
      nextSurgeryType: string;
      lastSurgeryStatus: any;
      interactions: Interaction[];
    };
  };

  private createInteractionAndUpdatePatient = /* GraphQL */ `
    mutation CreateInteractionAndUpdatePatient(
      $interactionInput: CreateInteractionInput!
      $patientInput: UpdatePatientInput!
    ) {
      createInteraction(input: $interactionInput) {
        id
        type
        status
        comments
        createdAt
        updatedAt
        surgeryType
        appointmentDate
        attemptFailed
        communicationMethod
        interactionClinicId
      }
      updatePatient(input: $patientInput) {
        id
      }
    }
  `;

  constructor(
    private dialog: MatDialog,
    private clinicSetupService: ClinicSetupService,
    private appSyncService: AppSyncService,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.sortInteractionByCategories();
    this.numberOfLifeLeft();
    this.recentCommunicationMethod =
      this.interactions.length > 0 ? this.interactions[this.interactions.length - 1].status : '';
  }

  sortInteractionByCategories() {
    this.interactionsSortedByCategoriesInit();
    this.interactions.forEach(interaction => {
      if (interaction.type === 'Communication') {
        if (interaction.attemptFailed) {
          this.interactionsSortedByCategories.communication.failedCommunications += 1;
        }
        this.interactionsSortedByCategories.communication.interactions.push(interaction);
      }

      if (interaction.type.toLowerCase().includes('surgery')) {
        const dateToday = new Date();
        const bookedSurgery = new Date(interaction.appointmentDate);
        this.interactionsSortedByCategories.surgery.interactions.push(interaction);
        if (
          interaction.appointmentDate &&
          dateToday.getTime() > bookedSurgery.getTime() &&
          !this.interactionsSortedByCategories.surgery.lastSurgeryBookedDate
        ) {
          this.interactionsSortedByCategories.surgery.lastSurgeryBookedDate = bookedSurgery.toString();
          this.interactionsSortedByCategories.surgery.lastSurgeryType = interaction.surgeryType;
        }
        if (interaction.appointmentDate && dateToday.getTime() < bookedSurgery.getTime()) {
          this.daysLeft = this.differenceOfDays(dateToday, bookedSurgery);
          this.interactionsSortedByCategories.surgery.nextSurgeryBookedDate = bookedSurgery.toString();
          this.interactionsSortedByCategories.surgery.nextSurgeryType = interaction.surgeryType;
        }

        if (
          this.statuses[interaction.status] &&
          (this.statuses[interaction.status].failSurgery ||
            this.statuses[interaction.status].notInterested) &&
          !this.interactionsSortedByCategories.surgery.lastSurgeryStatus &&
          !this.interactionsSortedByCategories.surgery.lastSurgeryBookedDate
        ) {
          this.interactionsSortedByCategories.surgery.lastSurgeryStatus =
            (this.statuses[interaction.status].notInterested && 'Not Interested') ||
            (this.statuses[interaction.status].failSurgery &&
              'Failed With Status ' + interaction.status) ||
            'Succeeded';
        }
      }

      this.interactionsSortedByCategories = { ...this.interactionsSortedByCategories };

      // if (interaction.surgeryType) {
      //   const dateToday = new Date();
      //   const interactionCreatedDate = new Date(interaction.createdAt);

      //   this.interactionsSortedByCategories.surgery.interactions.push(interaction);

      //   if (dateToday.getTime() > interactionCreatedDate.getTime()) {
      //     const lastSurgeryBookedDate = this.interactionsSortedByCategories.surgery
      //       .lastSurgeryBookedDate;
      //     if (
      //       !lastSurgeryBookedDate ||
      //       new Date(lastSurgeryBookedDate).getTime() < new Date(interaction.createdAt).getTime()
      //     ) {
      //       this.interactionsSortedByCategories.surgery.lastSurgeryBookedDate =
      //         interaction.createdAt;
      //       this.interactionsSortedByCategories.surgery.lastSurgeryType = interaction.surgeryType;
      //       this.interactionsSortedByCategories.surgery.lastSurgeryStatus =
      //         (this.statuses.notInterested && 'Not Interested') ||
      //         (this.statuses.failedSurgery && 'Failed due to ' + this.statuses.friendlyName) ||
      //         'Succeeded';
      //     }
      //   }

      //   if (dateToday.getTime() < interactionCreatedDate.getTime()) {
      //     const nextSurgeryBookedDate = this.interactionsSortedByCategories.surgery
      //       .nextSurgeryBookedDate;

      //     if (
      //       !nextSurgeryBookedDate ||
      //       new Date(nextSurgeryBookedDate).getTime() > new Date(interaction.createdAt).getTime()
      //     ) {
      //       this.interactionsSortedByCategories.surgery.nextSurgeryBookedDate =
      //         interaction.createdAt;
      //       this.interactionsSortedByCategories.surgery.nextSurgeryBookedDate =
      //         interaction.surgeryType;
      //     }
      //   }
      // }
    });
  }

  interactionsSortedByCategoriesInit() {
    this.interactionsSortedByCategories = {
      communication: {
        failedCommunications: 0,
        interactions: []
      },
      surgery: {
        lastSurgeryBookedDate: '',
        nextSurgeryBookedDate: '',
        lastSurgeryType: '',
        nextSurgeryType: '',
        lastSurgeryStatus: '',
        interactions: []
      }
    };
  }
  openCreateInteractionsModal() {
    this.dialog
      .open(InteractionActionsModalComponent, {
        data: 'add',
        width: '60vw'
      })
      .afterClosed()
      .subscribe((interactionFormGroup: FormGroup) => {
        if (interactionFormGroup) {
          const interactionInput = {
            ...interactionFormGroup.value,
            attemptFailed: interactionFormGroup.controls.attemptFailed.value,
            interactionPatientId: this.patientService.patient.id,
            interactionClinicId: this.clinicSetupService.clinicId
          } as CreateInteractionInput;

          const patientInput: UpdatePatientInput = {
            id: this.patientService.patient.id,
            lastInteractionAt: new Date().toISOString()
          };

          this.appSyncService
            .hydrated()
            .pipe(
              switchMap(client => {
                return client.mutate({
                  mutation: gql(this.createInteractionAndUpdatePatient),
                  variables: { interactionInput, patientInput }
                });
              })
            )
            .subscribe((createInteractionMutation: { data: CreateInteractionMutation }) => {
              if (createInteractionMutation) {
                this.interactions = [
                  ...this.interactions,
                  createInteractionMutation.data.createInteraction
                ];
                this.recentCommunicationMethod =
                  createInteractionMutation.data.createInteraction.status;
                this.sortInteractionByCategories();
              }
            });
        }
      });
  }
  openSendQuestionnaire($event: Event) {
    $event.stopPropagation();
    SendQuestionnaireDialogComponent.open(this.dialog, null, this.patientService.patient);
  }
  getHearts(isFilled) {
    return `<span class="material-life-icon">${isFilled ? 'favorite' : 'favorite_border'}</span>`;
  }
  numberOfLifeLeft() {
    const failedAttempt = Math.min(
      this.interactionsSortedByCategories.communication.failedCommunications,
      3
    );
    const filledHearts = this.getHearts(true).repeat(3 - failedAttempt);
    const emptyHearts = failedAttempt > 0 ? this.getHearts(false).repeat(failedAttempt) : '';
    this.numberOfLifeLine = filledHearts + emptyHearts;
  }
  getKeysFromObjectArray() {
    return Object.keys(
      this.interactionsSortedByCategories.communication.interactions[
        this.interactionsSortedByCategories.communication.interactions.length - 1
      ]
    );
  }

  differenceOfDays(date1: Date, date2: Date) {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailLocalPart'
})
export class EmailLocalPartPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return (value && value.split('@')[0]) || '';
  }
}

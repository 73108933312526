import { DatePipe } from '@angular/common';
import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { WebcamImage } from 'ngx-webcam';
import { Observable, of } from 'rxjs';
import { Assessment } from 'src/app/core/api/assessment.service';
import { SignsAttachmentsService } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { DataSrcService } from '../enlargeable-media/data-src.service';
import { CognitoGroup } from './../../../API';
import { StaffService } from './../../core/api/staff.service';
import { AttachmentQrCodeModalComponent } from './attachment-qr-code-modal/attachment-qr-code-modal.component';
import { S3Object, UrlWithContentType } from './s3-attachment.service';
export type Attachment = File | S3Object;

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AttachmentsComponent),
  multi: true
};

@Component({
  selector: 'csi-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AttachmentsComponent implements ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() accept = '';
  @Input() expandAttachments: boolean;
  @Input() maxAttachments: number;
  @Input() addFilesMessage: string = null;
  @Input() shouldStaySameHeight = false;
  @Input() cameraBtnEnabled = false;
  @Input() mobileUploadEnabled = false;
  @Input() saveFn: () => Observable<Assessment>;
  @Input() metadata: { [key: string]: string } = { default: 'none' };
  @Input() linkToSigns: boolean;
  @Input() preloadedAttachments: UrlWithContentType[] = [];
  @Input() usePreloadedAttachments: boolean;

  public isReadOnly = false;

  attachments: Attachment[] = [];

  private onTouchedCallback: () => void = noop;
  protected onChangeCallback: (_: any) => void = noop;
  public cameraAccessEnabled = this.staffService.isStaffPartOfGroup(
    this.staffService.staff,
    CognitoGroup.CameraAccess
  );

  private id: string;

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  @HostListener('drop', ['$event'])
  onFileDrop(event) {
    event.preventDefault();

    if (event.dataTransfer.items) {
      this.addFiles(
        Array.from(event.dataTransfer.items).map((item: any) => item.getAsFile()) as File[]
      );
      event.dataTransfer.items.clear();
    } else {
      this.addFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }

  constructor(
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected staffService: StaffService,
    protected dataSrcService: DataSrcService,
    protected signsAttachmentsService: SignsAttachmentsService,
    protected loadingSpinnerService: LoadingSpinnerService
  ) {
    const pathname = location.pathname;
    this.id = pathname.substring(pathname.indexOf(':') + 1, pathname.length - 1);
  }

  ngOnInit() {
    if (this.linkToSigns) {
      this.signsAttachmentsService.initializeAttachmentsLink(this.formControl, this.saveFn);
    }
  }

  generateQrCode() {
    this.loadingSpinnerService.show();
    this.updateControlValue();
    const metadataKey = Object.keys(this.metadata)[0];
    (this.id === 'new' ? this.saveFn() : of({ id: this.id })).subscribe(data => {
      this.id = data.id;
      const localAttachments = this.attachments.filter((a: Attachment) => a instanceof File);
      this.loadingSpinnerService.hide();
      this.dialog
        .open(AttachmentQrCodeModalComponent, {
          disableClose: true,
          data: { id: this.id, metadata: this.metadata, metadataKey: metadataKey }
        })
        .afterClosed()
        .subscribe((newAttachments: Attachment[] = []) => {
          let filteredAttachments = [];
          if (this.metadata.sign && this.metadata.sign !== 'main') {
            for (let attachment of newAttachments) {
              let attachmentMetadata = JSON.parse(attachment['metadata']);
              if (attachmentMetadata.sign === this.metadata.sign) {
                filteredAttachments.push(attachment);
              }
            }
          } else {
            filteredAttachments = [...newAttachments];
          }

          this.attachments = [...filteredAttachments, ...localAttachments];
          this.updateControlValue();
          if (this.saveFn) {
            this.saveFn().subscribe(assessmentData => {
              // Update attachments so that local attachments are all S3Objects
              this.attachments = assessmentData.attachments || [];
            });
          }
        });
    });
  }

  addImage(image: WebcamImage) {
    if (image) this.addFiles([this.dataURLtoFile(image)]);
  }

  dataURLtoFile(image: WebcamImage) {
    const filename = 'image_' + this.datePipe.transform(new Date(), 'yyyyMMdd_mm_ss');
    const arr = image.imageAsDataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  addFiles(files: File[] | FileList) {
    const filteredFiles = Array.from(files).filter(file => {
      return (
        file !== null &&
        (this.accept === '' ||
          this.accept.includes(file.type) ||
          this.accept.includes(file.name.match(/[a-z0-9]*$/i)[0]))
      );
    });

    Promise.all(this.convertMovToMp4(filteredFiles))
      .then(updatedFiles => {
        if (this.metadata) {
          updatedFiles.forEach(file => (file['metadata'] = JSON.stringify(this.metadata)));
        }
        this.updateAttachments(updatedFiles);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  updateAttachments(files: File[]) {
    this.attachments = this.attachments.concat(files).filter(attachment => attachment !== null);
    if (this.attachments.length > this.maxAttachments) {
      this.attachments.splice(0, this.attachments.length - this.maxAttachments);
    }
    this.updateControlValue();
  }

  removeAttachment(attachmentToRemove: Attachment) {
    this.attachments = this.attachments.filter(attachment => attachment !== attachmentToRemove);
    this.updateControlValue();
  }

  convertMovToMp4(files: File[]): (File | Promise<File>)[] {
    return files.map(file => {
      if (file.type === 'video/quicktime') {
        return this.dataSrcService.copyFile(file, true).then(fileAsMP4 => {
          return fileAsMP4;
        });
      } else {
        return file;
      }
    });
  }

  public writeValue(value: any) {
    this.attachments = value ? value : new Array<Attachment>();
    if (this.linkToSigns) {
      this.signsAttachmentsService.updateMetadataToAttachmentsMap(null, this.attachments);
    }
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  protected updateControlValue() {
    this.onChangeCallback(this.attachments);
    if (this.linkToSigns) {
      this.signsAttachmentsService.updateMetadataToAttachmentsMap(null, this.attachments);
    }
  }
}

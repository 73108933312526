import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { noSort } from 'src/app/core/api/model-utils';
import { LEFT_EYE_KEY_PREFIX, RIGHT_EYE_KEY_PREFIX } from 'src/app/shared/form.model';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { PreOpFormGroup } from '../pre-op-form.model';
import { AnalysisStateItem, clincalExamFriendlyNames } from '../pre-op.model';
import { PreOpService } from '../pre-op.service';

interface MessageAndTechicianNote {
  left?: string;
  right?: string;
  message?: string;
  technicianNote?: string;
}

interface PreopReportBranch {
  label: string;
  color: string;
  disableHover?: boolean;
  messagesAndTechicianNotes?: { [key: string]: MessageAndTechicianNote };
  messageForBothEyes?: boolean;
}

export interface PreopReportBranchSchema {
  [key: string]: PreopReportBranch;
}

@Component({
  selector: 'csi-pre-op-report',
  templateUrl: './pre-op-report.component.html',
  styleUrls: ['./pre-op-report.component.scss']
})
export class PreOpReportComponent implements AfterViewInit {
  @ViewChildren('branch', { read: ElementRef }) branches: QueryList<ElementRef>;

  private startEndColor = '#c7686c';
  private essentialDataColor = '#79478e';
  private diagnosisColor = '#43956a';
  private optionalColor = '#a54086';
  private decisionColor = '#649ad5';

  public preOpReportBranchSchema: PreopReportBranchSchema = {
    preOperativeVisit: {
      label: 'PREOPERATIVE VISIT',
      color: this.startEndColor,
      messagesAndTechicianNotes: this.getmessagesAndTechicianNotesForBranch('preoperativeVisit')
    },
    nonInvasiveRefractivePreopMeasurements: {
      label: 'NON INVASIVE REFRACTIVE PREOP MEASUREMENTS',
      color: this.essentialDataColor,
      messagesAndTechicianNotes: this.getmessagesAndTechicianNotesForBranch(
        'keratometryMeasurements'
      )
    },
    osdScreen: {
      label: 'OSD SCREEN',
      color: this.essentialDataColor,
      messagesAndTechicianNotes: this.getmessagesAndTechicianNotesForBranch('osdScreenSigns')
    },
    negativeScreen: {
      label: 'Negative Screen',
      color: this.diagnosisColor,
      disableHover: true
    },
    positiveScreen: {
      label: 'Positive Screen',
      color: this.diagnosisColor,
      disableHover: true
    },
    optionalNonInvasiveOsdTests: {
      label: 'Optional NonInvasive OSD Tests',
      color: this.optionalColor,
      messagesAndTechicianNotes: this.getmessagesAndTechicianNotesForBranch(
        'optionalNonInvasiveOsdTests'
      )
    },
    clinicalExam: {
      label: 'Clinical Exam (LLPP)',
      color: this.essentialDataColor,
      messagesAndTechicianNotes: this.getmessagesAndTechicianNotesForBranch(
        'clinicalExam',
        Object.keys(clincalExamFriendlyNames)
      ),
      messageForBothEyes: true
    },
    osdRuledOut: {
      label: 'OSD Ruled Out',
      color: this.diagnosisColor,
      disableHover: true
    },
    osdRuledIn: {
      label: 'OSD Ruled In',
      color: this.diagnosisColor,
      disableHover: true
    },
    neuropathic: {
      label: 'Neuropathic Pain vs. Early OSD/DED',
      color: this.diagnosisColor,
      disableHover: true
    },
    neurotrophic: {
      label: 'Neurotrophic Cornea',
      color: this.diagnosisColor,
      disableHover: true
    },
    nonVisuallySignificant: {
      label: 'Non Visually Significant OSD (NVS-OSD)',
      color: this.diagnosisColor,
      disableHover: true
    },
    visuallySignificant: {
      label: 'Visually Significant OSD (VS-OSD)',
      color: this.diagnosisColor,
      disableHover: true
    },
    surgeryProceeds: {
      label: 'Surgery Proceeds',
      color: this.decisionColor,
      disableHover: true
    },
    surgeryDelayed: {
      label: 'Surgery Delayed',
      color: this.decisionColor,
      disableHover: true
    },
    exposure: {
      label: 'Exposure/Lid Malposition',
      color: this.diagnosisColor,
      disableHover: true
    },
    dryEyeDisease: {
      label: 'Dry Eye Disease',
      color: this.diagnosisColor,
      disableHover: true
    },
    lumpsBumps: {
      label: 'Lumps & Bumps',
      color: this.diagnosisColor,
      disableHover: true
    },
    conjuctivitis: {
      label: 'Conjunctivitis',
      color: this.diagnosisColor,
      disableHover: true
    },
    treatmentPlan: {
      label: 'Treatment Plan',
      color: this.decisionColor,
      disableHover: true
    },
    optionalInvasiveRefractiveTests: {
      label: 'Optional Invasive Refractive Tests',
      color: this.optionalColor
    },
    surgeryNextVisit: {
      label: 'Next Visit Is Surgery',
      color: this.startEndColor,
      disableHover: true
    }
  };

  public noSort = noSort;

  public branchMap = {};

  constructor(
    public preOpService: PreOpService,
    public schemaService: SchemaService,
    public loadingSpinnerService: LoadingSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: PreOpFormGroup
  ) {}

  ngAfterViewInit() {
    setTimeout(() =>
      this.branches.forEach(branch => {
        this.branchMap[branch.nativeElement.id] = branch;
      })
    );
  }

  getmessagesAndTechicianNotesForBranch(
    key: string,
    formGroupKeys: string[] = null
  ): { [key: string]: MessageAndTechicianNote } {
    const messageAndTechicianNote = {};

    if (formGroupKeys) {
      formGroupKeys.forEach(formGroupKey => {
        const rightEyeKey = RIGHT_EYE_KEY_PREFIX + formGroupKey;
        const leftEyeKey = LEFT_EYE_KEY_PREFIX + formGroupKey;

        const valueRight = this.data.controls[rightEyeKey] && this.data.controls[rightEyeKey].value;
        const valueLeft = this.data.controls[leftEyeKey] && this.data.controls[leftEyeKey].value;

        if (valueRight || valueLeft) {
          messageAndTechicianNote[formGroupKey] = {
            left: this.schemaService.getFriendlyValueName(valueLeft, formGroupKey),
            right: this.schemaService.getFriendlyValueName(valueRight, formGroupKey)
          };
        }
      });

      return Object.keys(messageAndTechicianNote).length > 0 ? messageAndTechicianNote : null;
    }

    Object.entries(this.preOpService.analysisState).forEach(
      ([analysisStateKey, analysisStateItem]: [string, AnalysisStateItem]) => {
        if (analysisStateItem.abnormal && analysisStateItem.group === key) {
          messageAndTechicianNote[analysisStateKey] = {
            message: analysisStateItem.simpleMessage || analysisStateItem.message,
            technicianNote: analysisStateItem.technicianNote || ''
          };
        }
      }
    );
    return Object.keys(messageAndTechicianNote).length > 0 ? messageAndTechicianNote : null;
  }

  downloadPDF() {
    const node = document.getElementById('report');

    let img;
    let filename;
    let newImage;
    domtoimage.toPng(node, { bgcolor: '#fff' }).then(function(dataUrl) {
      img = new Image();
      img.src = dataUrl;
      newImage = img.src;

      img.onload = function() {
        const pdfWidth = img.width;
        const pdfHeight = img.height;

        let doc;

        doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        doc.addImage(newImage, 'PNG', 0, 0, pdfWidth, pdfHeight);
        filename = 'preop_report.pdf';
        doc.save(filename);
      };
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { VerifyPhoneModalComponent } from './verify-phone-modal/verify-phone-modal.component';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'csi-update-phone',
  templateUrl: './update-phone.component.html',
  styleUrls: ['./update-phone.component.css']
})
export class SaveVerifyPhoneComponent implements OnInit {
  @Input() phoneControl: FormControl;
  @Input() countryCodeControl: FormControl;
  @Input() phoneVerifiedControl: FormControl;

  public actionButtonLabel = 'Save and Verify';
  public currentPhoneNumber: string;
  public showActionButton: boolean;
  public isLoading = false;

  constructor(private authenticationService: AuthenticationService, private dialog: MatDialog) {}

  ngOnInit() {
    this.currentPhoneNumber = this.countryCodeControl.value + this.phoneControl.value;
  }

  onSaveAndVerify() {
    if (this.phoneControl.valid && this.countryCodeControl.valid) {
      this.isLoading = true;
      this.authenticationService
        .updatePhoneNumber(this.countryCodeControl.value + this.phoneControl.value)
        .subscribe(() => {
          this.currentPhoneNumber = this.countryCodeControl.value + this.phoneControl.value;
          this.isLoading = false;
          this.dialog
            .open(VerifyPhoneModalComponent, { width: '30rem' })
            .afterClosed()
            .subscribe(verified => {
              if (verified) {
                this.phoneVerifiedControl.setValue(true);
              }
            });
        });
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { SignsAttachmentsService } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments.service';
import { Attachment } from '../attachments/attachments.component';
import { DataSrcService } from '../enlargeable-media/data-src.service';

@Component({
  selector: 'csi-media-selection-modal',
  templateUrl: './media-selection-modal.component.html',
  styleUrls: ['./media-selection-modal.component.scss']
})
export class MediaSelectionModalComponent implements OnInit {
  public checkboxControls: FormControl[];
  public mediaFormControl: FormControl = new FormControl();
  public numSelected: number;
  public showMaxSelectedError: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MediaSelectionModalComponent>,
    public dataSrcService: DataSrcService,
    public signsAttachmentsService: SignsAttachmentsService
  ) {}

  ngOnInit() {}

  onCheckboxChanged(index: number) {
    if (this.checkboxControls[index].value === true) {
      if (this.numSelected === this.data.maxSelected) {
        this.showMaxSelectedError = true;
      } else {
        this.showMaxSelectedError = false;
      }
    } else {
    }
  }

  closeDialog(attachment: Attachment) {
    // this.dialogRef.disableClose = false;
    this.dialogRef.close(attachment);
  }

  public static open(matDialog: MatDialog, attachments: Attachment[], title: string) {
    return matDialog.open<MediaSelectionModalComponent>(MediaSelectionModalComponent, {
      width: '1200px',
      maxWidth: '75vw',
      data: {
        attachments,
        title
      },
      disableClose: true
    });
  }

  public getFileName(attachment: Attachment): string {
    return this.dataSrcService.getFileName(attachment);
  }
}

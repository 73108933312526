import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsultFormsModule } from 'src/app/shared/consult-forms/consult-forms.module';
import { FormTemplateModule } from '../form-template/form-template.module';
import { SpeedIIComponent } from '../questionnaires/speedII/speed-ii.component';
import { SharedModule } from '../shared/shared.module';
import { IntakeFormViewModule } from './intake-form-view/intake-form-view/intake-form-view.module';
import { IntakeFormRoutingModule } from './intake-form.routing.module';
import { IntakeFormsViewComponent } from './intake-forms-view/intake-forms-view.component';
import { LinkWithSimilarPatientModalComponent } from './link-with-similar-patient-modal/link-with-similar-patient-modal.component';

@NgModule({
  declarations: [IntakeFormsViewComponent, LinkWithSimilarPatientModalComponent],
  imports: [
    CommonModule,
    ConsultFormsModule,
    IntakeFormRoutingModule,
    FormTemplateModule,
    SharedModule,
    IntakeFormViewModule
  ],
  exports: [],
  entryComponents: [SpeedIIComponent, LinkWithSimilarPatientModalComponent]
})
export class IntakeFormModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.css']
})
export class MultipleChoiceQuestionComponent implements OnInit {
  @Input() radioMap: { [key: string]: string };
  @Input() formControlKey: string;
  @Input() formGroup: FormGroup;
  @Input() otherFormControlKey?: string;
  @Input() otherDescriptionLabel = 'Please describe';
  @Input() toggleableFieldConfig?;
  @Input() viewOnly = false;

  @Output() change = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.formGroup.controls[this.formControlKey].valueChanges.subscribe(val => {
      this.change.emit(val);
    });

    if (!this.toggleableFieldConfig) return;
    this.addToFormGroupIfKeyDoesNotExist(
      this.toggleableFieldConfig.formControlKey,
      this.formGroup,
      true
    );
    if (
      this.toggleableFieldConfig.required &&
      this.formGroup.controls[this.toggleableFieldConfig.formControlKey].value === null
    ) {
      this.formGroup.controls[this.toggleableFieldConfig.formControlKey].disable();
    }
    this.formGroup.controls[this.formControlKey].valueChanges.subscribe(val => {
      val === this.toggleableFieldConfig.triggeredByValue
        ? this.formGroup.controls[this.toggleableFieldConfig.formControlKey].enable()
        : this.formGroup.controls[this.toggleableFieldConfig.formControlKey].disable();
    });
  }

  noSort = noSort;

  addToFormGroupIfKeyDoesNotExist(key, formGroup: FormGroup, required?: boolean) {
    if (!formGroup.controls[key]) {
      formGroup.addControl(key, new FormControl(null, required ? Validators.required : null));
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object, isStringEnum = true) {
    const keys = Object.keys(data);
    return keys.slice(isStringEnum ? 0 : keys.length / 2);
  }
}

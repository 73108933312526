import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Patient, PatientService } from '../../core/api/patient.service';
import { AssessmentComponent } from '../../encounters/assessment/assessment.component';
import { EncounterDashboardComponent } from './../../encounters/encounter-dashboard/encounter-dashboard.component';
import { EyePosition } from './patient-chart/patient-chart.component';

@Component({
  selector: 'csi-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit, OnDestroy {
  public onPatientFileSaved$: Subject<Object> = new Subject<Object>();
  public patient$: Observable<Patient>;

  public showSendQuestionnaireButton = true;

  public readonly EyePosition = EyePosition;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.patient$ = this.route.data.pipe(
      map((data: any) => data.patient),
      switchMap(patient => {
        const fetchPolicy = 'cache-only';
        return this.patientService.getPatient(
          patient.id,
          true,
          fetchPolicy,
          this.patientService.maxAssessmentsPerPatient
        );
      }),
      untilDestroyed(this)
    );
  }

  ngOnDestroy() {}

  public onEncountersOutletActivate(
    activeComponent: EncounterDashboardComponent | AssessmentComponent
  ) {
    this.showSendQuestionnaireButton = activeComponent instanceof EncounterDashboardComponent;

    if (activeComponent instanceof AssessmentComponent) {
      activeComponent.onPatientFileSaved$ = this.onPatientFileSaved$;
    }
    // Needed to prevent changedAfterChecked error
    this.changeDetector.detectChanges();
  }
}

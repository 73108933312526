import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { SymptomTypes, TextSymptom } from '../symptom.model';

@Component({
  selector: 'csi-text-symptom-control',
  templateUrl: './text-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './text-symptom-control.component.css'
  ],
  providers: [
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => TextSymptomControlComponent)
    }
  ]
})
export class TextSymptomControlComponent extends AbstractSymptomControlComponent {
  @Input() symptom: TextSymptom;
  @Input() formKey: string;
  @Input() formGroup: FormGroup;

  public readonly SymptomTypes = SymptomTypes;
}

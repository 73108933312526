import { FormControl, FormGroup } from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { AbstractControlsMap } from '../shared/dynamic-form-group/dynamic-form-group';

interface LoggedInUserFormControls extends AbstractControlsMap {
  phone: FormControl;
  countryCode: FormControl;
  phoneVerified: FormControl;
  preferredMFAType: FormControl;
  totpVerified: FormControl;
}

export class AccountSettingsFormGroup extends FormGroup {
  controls: LoggedInUserFormControls;
  formGroup: AccountSettingsFormGroup;

  constructor() {
    super({
      phone: new FormControl(''),
      countryCode: new FormControl(''),
      phoneVerified: new FormControl(''),
      preferredMFAType: new FormControl(''),
      totpVerified: new FormControl('')
    });
  }

  patchValue(loggedInUser: CognitoUser) {
    if (loggedInUser['attributes']) {
      this.setPhoneAndCountryCodeAndDisable(
        this.controls.phone,
        this.controls.countryCode,
        loggedInUser['attributes']['phone_number']
      );
      this.setValueAndDisable(
        this.controls.phoneVerified,
        loggedInUser['attributes']['phone_number_verified']
      );
      this.setTotpVerifiedAndDisable(
        this.controls.totpVerified,
        loggedInUser['attributes']['custom:totp_verified']
      );
    }
    this.setPreferredMFAAndDisable(this.controls.preferredMFAType, loggedInUser['preferredMFA']);
  }

  setValueAndDisable(control: FormControl, value: string) {
    control.setValue(value);
    if (value !== undefined && value !== null) {
      control.disable();
    }
  }

  setTotpVerifiedAndDisable(control: FormControl, value: string) {
    control.setValue(value === 'true');
    if (value !== undefined && value !== null) {
      control.disable();
    }
  }

  setPreferredMFAAndDisable(control: FormControl, cognitoPreferredMFA: string) {
    control.setValue(
      {
        SMS_MFA: 'SMS',
        SOFTWARE_TOKEN_MFA: 'TOTP',
        NOMFA: 'NOMFA'
      }[cognitoPreferredMFA]
    );
    if (cognitoPreferredMFA !== undefined && cognitoPreferredMFA !== null) {
      control.disable();
    }
  }

  setPhoneAndCountryCodeAndDisable(
    phoneControl: FormControl,
    countryCodeControl: FormControl,
    phoneNumber: string
  ) {
    if (phoneNumber) {
      const parsedPhoneNumber: PhoneNumber = parsePhoneNumberFromString(phoneNumber);
      phoneControl.setValue(parsedPhoneNumber.nationalNumber);
      countryCodeControl.setValue('+' + parsedPhoneNumber.countryCallingCode);
    }
  }
}

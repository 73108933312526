import { Injectable, Optional } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { flatten, get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { EConsultFormGroup } from '../../../../econsult/econsult-form.model';
import { SchemaService } from '../../../symptoms/services/schema/schema.service';
import { Requirements, Symptom, SymptomModes } from '../../../symptoms/symptom.model';
import { ThermalScaleService } from '../../../symptoms/thermal-scale/thermal-scale.service';
import { DryEyeFormGroup } from '../../dry-eye-forms/dry-eye-form/dry-eye-form.model';

export class CheckAttachedValidators {
  checkIfRequiredValidatorAttached?: boolean;
  checkIfOtherValidatorsAttached?: boolean;
}

@Injectable()
export class SymptomValidatorsService {
  private formGroup: DryEyeFormGroup;
  private readonly assessmentMethod: BehaviorSubject<SymptomModes>;

  private currentlyUpdating = false;
  private readonly requiredValidatorResponse: ValidationErrors = { required: true };
  private readonly rangeValidatorResponse: ValidationErrors = {
    outOfBounds: 'Value out of bounds'
  };

  constructor(
    private thermalScaleService: ThermalScaleService,
    private schemaService: SchemaService,
    @Optional() private econsultFormGroup: EConsultFormGroup
  ) {
    if (!!econsultFormGroup) {
      this.formGroup = this.econsultFormGroup.dryEyeFormControls.dryEyeForm;
      this.assessmentMethod = this.econsultFormGroup.assessmentMethod;
    }
  }

  public isOptionalFormControl(control: AbstractControl): boolean {
    const validatorFunction = control && control.validator;
    const validatorCheck: CheckAttachedValidators = { checkIfRequiredValidatorAttached: true };

    return (
      !validatorFunction || !get(validatorFunction(validatorCheck as AbstractControl), 'required')
    );
  }

  public rangeValidatorFactory(symptom: Symptom): ValidatorFn {
    if (!this.hasNumericThermalScale(symptom)) {
      return null;
    }
    return ((control: AbstractControl | CheckAttachedValidators): ValidationErrors | null => {
      if (this.isRangeValidatorOptional(control, symptom)) {
        return this.rangeValidatorResponse;
      }
      if ((control as AbstractControl).value != null) {
        return this.thermalScaleService.isValueWithinConfigRange(
          (control as AbstractControl).value,
          symptom.thermalScale
        )
          ? null
          : this.rangeValidatorResponse;
      }
    }) as ValidatorFn;
  }

  public requiredValidatorFactory(symptom: Symptom, formKey: string): ValidatorFn {
    if (!this.hasRequiredAttribute(symptom)) {
      return null;
    }
    return ((checkValidator?: CheckAttachedValidators): ValidationErrors | null => {
      if (this.isRequiredValidatorOptional(checkValidator, symptom)) {
        return this.requiredValidatorResponse;
      }
      if (this.hasNoValidatorForCurrentMode(symptom)) {
        return null;
      }

      return this.requiredValidatorLogic(symptom, formKey);
    }) as ValidatorFn;
  }

  private isRangeValidatorOptional(
    control: AbstractControl | CheckAttachedValidators,
    symptom: Symptom
  ): boolean {
    return (
      control instanceof CheckAttachedValidators &&
      control.checkIfOtherValidatorsAttached === true &&
      !this.hasNoValidatorForCurrentMode(symptom)
    );
  }

  private isRequiredValidatorOptional(
    checkValidator: CheckAttachedValidators,
    symptom: Symptom
  ): boolean {
    return (
      checkValidator &&
      checkValidator.checkIfRequiredValidatorAttached === true &&
      !this.hasNoValidatorForCurrentMode(symptom)
    );
  }

  private requiredValidatorLogic(symptom: Symptom, formKey: string): ValidationErrors | null {
    const requiredSymptoms: Requirements =
      this.assessmentMethod.value === SymptomModes.Simple
        ? symptom.required.simple
        : symptom.required.advanced;
    const requiredFormControlsGroups = requiredSymptoms.oneOfSigns
      .filter(key => key !== formKey)
      .reduce((previous: AbstractControl[][], key: string) => {
        const formControlKeys = this.schemaService.getFormControlKeys(key);
        return [
          ...previous,
          formControlKeys.map(formKey1 => this.formGroup.get(formKey1)).filter(control => !!control)
        ];
      }, []);
    if (!this.currentlyUpdating) {
      this.currentlyUpdating = true;
      this.updateFormControls(flatten(requiredFormControlsGroups));
      this.currentlyUpdating = false;
    }

    if (
      this.formGroup.get(formKey).value == null &&
      requiredFormControlsGroups.every(requiredFormControlGroup => {
        return requiredFormControlGroup.some(
          requiredFormControl => requiredFormControl.value == null
        );
      })
    ) {
      return this.requiredValidatorResponse;
    } else {
      return null;
    }
  }

  private hasNoValidatorForCurrentMode(symptom: Symptom) {
    return !this.assessmentMethod || !symptom.required[this.assessmentMethod.value];
  }

  private hasRequiredAttribute(symptom: Symptom): boolean {
    return !!symptom.required;
  }

  private hasNumericThermalScale(symptom: Symptom): boolean {
    return (
      !!symptom.thermalScale && this.thermalScaleService.isNumericThermalScale(symptom.thermalScale)
    );
  }

  private updateFormControls(controls: AbstractControl[]) {
    controls.forEach(control => control.updateValueAndValidity({ onlySelf: true }));
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { SharedModule } from '../shared.module';
import { HasDryEyeSideEffect } from './dry-eye-side-effect-flagging/has-dry-eye-side-effect.pipe';
import { MedicationLookupComponent } from './medication-lookup.component';
import { SideEffectsPipe } from './side-effects.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedAngularMaterialModule
  ],
  declarations: [MedicationLookupComponent, HasDryEyeSideEffect],
  exports: [MedicationLookupComponent, HasDryEyeSideEffect],
  providers: [SideEffectsPipe]
})
export class MedicationLookupModule {}

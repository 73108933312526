import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { notificationMap } from './../customizable-welcome-notification/notification-map';

@Component({
  selector: 'csi-questionnaire-notification',
  templateUrl: './questionnaire-notification.component.html',
  styleUrls: ['./questionnaire-notification.component.css']
})
export class QuestionnaireNotificationComponent {
  @Input() control: FormControl;

  notificationConfig = notificationMap.questionnaireMessage;
}

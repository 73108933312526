import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  FormFieldConfig,
  RadioConfig,
  formTemplateGenericSort
} from './../../../form-template-model';
import { FormTemplateGeneratorService } from './../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-radio-field-generator',
  templateUrl: './form-template-radio-field-generator.component.html',
  styleUrls: ['./form-template-radio-field-generator.component.css']
})
export class FormTemplateRadioFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: RadioConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  @Input() formTemplateGroupFormGroup: FormGroup;

  sortValues = formTemplateGenericSort;
  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    // console.log(this.formTemplateFieldKey, this.formTemplateGroupFormGroup.valueChanges);
    // console.log(this.config);
  }
}

export const ScaleToNumberMap = {
  no: 0,
  grade0: 0,
  grade1: 1,
  grade2: 2,
  grade3: 3,
  grade4: 4,
  normal: 0,
  minimal: 0,
  mild: 1,
  moderate: 2,
  severe: 3,
  group1: 0,
  group2: 1,
  group3: 2,
  group4: 3,
  negative: 0,
  trace: 1,
  weak: 2,
  positive: 3,
  strong: 4,
  reduced: 1,
  absent: 2
};

export interface BothEyes {
  right: number | string;
  left: number | string;
}

export type Operator = '>' | '<' | '=' | '>=' | '<=' | '!=';

export interface Condition {
  condition: any;
  operator: Operator;
  specialCondition?: boolean;
  evidence?: string;
}

export type InterpretationView = 'text' | 'table';

export type FalseOcularSurfaceDiseaseCondition =
  | 'Signs without Symptoms'
  | 'Symptoms without Signs';

export type ContributionLevel = 'significant' | 'probable' | 'possible';

export interface ContributorBasedOnEvidence {
  contributor: string;
  evidence: string[];
  contributionLevel: ContributionLevel;
  plural?: boolean;
  specialNote?: string;
}

export type PropertyForSignAndSymptom =
  | 'singleValueForBothEyes'
  | 'convertToNumericValue'
  | 'qualitativeOrQuantitative';

// The interpretation logic is derived from https://docs.google.com/document/d/1gYysz976T9zTNaIkACq2mlOL6rRJLMCQsWFmEqEjapk/edit
export const InterpretationConditions = {
  trueOcularSurfaceDisease: {
    LOGICAL_OR_GROUP_1: {
      deq: { condition: 6, operator: '>=', evidence: 'DEQ is 6 or greater' },
      osdi: { condition: 13, operator: '>=', evidence: 'OSDI is 13 or greater' },
      speed: { condition: 5, operator: '>=', evidence: 'SPEED is 5 or greater' }
    },
    LOGICAL_OR_GROUP_2: {
      MEIBOGRAPHY: {
        condition: ScaleToNumberMap.grade1,
        operator: '>=',
        evidence: 'Meibography is grade 1 or higher'
      },
      MGE_EXPRESSIBILITY: {
        condition: ScaleToNumberMap.mild,
        operator: '>=',
        evidence: 'MGE Expressibility is mild or worse'
      },
      MGE_SECRETION: {
        condition: ScaleToNumberMap.mild,
        operator: '>=',
        evidence: 'MGE Secretion is mild or worse'
      },
      MMP9: {
        condition: ScaleToNumberMap.trace,
        operator: '>=',
        evidence: 'MMP9 is mild or worse'
      },
      NITBUT: { condition: 10, operator: '<', evidence: 'NITBUT is 10 or smaller' },
      OSMOLARITY: {
        condition: (patientData: BothEyes) => {
          return (
            patientData.left >= 308 ||
            patientData.right >= 308 ||
            Math.abs((patientData.left as number) - (patientData.right as number)) >= 9
          );
        },
        specialCondition: true,
        evidence: 'Osmolarity >= 308 or the difference between Osmolarity is >= 9'
      },
      ITBUT: { condition: 10, operator: '<', evidence: 'ITBUT is 10 or smaller' },
      CORNEAL_STAINING_EXTENT: {
        qualitative: {
          condition: ScaleToNumberMap.grade1,
          operator: '>='
        },
        quantitative: {
          condition: ScaleToNumberMap.mild,
          operator: '>='
        },
        evidence:
          'Corneal Staining Pattern is grade 1 or greater (qualitative) or it is mild or worse (quantitative)'
      },
      LWE: {
        condition: ScaleToNumberMap.grade1,
        operator: '>=',
        evidence: 'Lid Wiper Epitheliopathy (LWE) is Grade-1 or greater'
      }
    }
  },
  falseOcularSurfaceDisease: {
    symptomsWithoutSigns: {
      LOGICAL_OR_GROUP_1: {
        deq: { condition: 6, operator: '>=', evidence: 'DEQ is 6 or greater' },
        osdi: { condition: 13, operator: '>=', evidence: 'OSDI is 13 or greater' },
        speed: { condition: 5, operator: '>=', evidence: 'SPEED is 5 or greater' }
      }
    },
    preClinicalorNeuropathic: {
      LOGICAL_OR_GROUP_1: {
        osdi: {
          condition: 32,
          operator: '>=',
          evidence: 'OSDI is 32 or greater'
        },
        previousOcularSurgery: {
          condition: true,
          operator: '=',
          evidence: 'Previous Ocular Surgery'
        }
      }
    },
    signsWithoutSymptoms: {
      LOGICAL_OR_GROUP_1: {
        deq: { condition: 6, operator: '<', evidence: 'DEQ is less than 6' },
        osdi: { condition: 13, operator: '<', evidence: 'OSDI is less than 13' },
        speed: { condition: 5, operator: '<', evidence: 'SPEED is less than 5' }
      },
      LOGICAL_OR_GROUP_2: {
        MEIBOGRAPHY: {
          condition: ScaleToNumberMap.grade1,
          operator: '>=',
          evidence: 'Meibography is grade 1 or higher'
        },
        MGE_EXPRESSIBILITY: {
          condition: ScaleToNumberMap.mild,
          operator: '>=',
          evidence: 'MGE Expressibility is mild or worse'
        },
        MGE_SECRETION: {
          condition: ScaleToNumberMap.mild,
          operator: '>=',
          evidence: 'MGE Secretion is mild or worse'
        },
        MMP9: {
          condition: ScaleToNumberMap.trace,
          operator: '>=',
          evidence: 'MMP9 is mild or worse'
        },
        NITBUT: { condition: 10, operator: '<', evidence: 'NITBUT is 10 or smaller' },
        OSMOLARITY: {
          condition: (patientData: BothEyes) => {
            return (
              patientData.left >= 308 ||
              patientData.right >= 308 ||
              Math.abs((patientData.left as number) - (patientData.right as number)) >= 9
            );
          },
          specialCondition: true,
          evidence: 'Osmolarity >= 308 or the difference between Osmolarity is >= 9'
        },
        ITBUT: { condition: 10, operator: '<', evidence: 'ITBUT is 10 or smaller' },
        CORNEAL_STAINING_EXTENT: {
          qualitative: {
            condition: ScaleToNumberMap.grade1,
            operator: '>='
          },
          quantitative: {
            condition: ScaleToNumberMap.mild,
            operator: '>='
          },
          evidence:
            'Corneal Staining Pattern is grade 1 or greater (qualitative) or it is mild or worse (quantitative)'
        },
        LWE: {
          condition: ScaleToNumberMap.grade1,
          operator: '>=',
          evidence: 'Lid Wiper Epitheliopathy (LWE) is Grade-1 or greater'
        }
      }
    },
    cornealSensationNormal: {
      LOGICAL_OR_GROUP_1: {
        CORNEAL_SENSATION: {
          qualitative: {
            condition: ScaleToNumberMap.normal,
            operator: '='
          },
          quantitative: {
            condition: ScaleToNumberMap.normal,
            operator: '<='
          },
          evidence:
            'Corneal Sensation is normal (qualitative) or it is 6cm or greater (quantitative)'
        }
      }
    }
  },
  dryEyeMimickersBasedOnSigns: {
    MESSAGE_GROUP_1: {
      significant: {
        LOGICAL_OR_GROUP_1: {
          COLLARETTES: {
            condition: ScaleToNumberMap.severe,
            operator: '=',
            evidence: 'Collarettes is severe'
          }
        },
        contributor: 'Blepharitis'
      },
      possible: {
        LOGICAL_OR_GROUP_1: {
          COLLARETTES: {
            condition: ScaleToNumberMap.moderate,
            operator: '=',
            evidence: 'Collaretes is moderate'
          }
        },
        contributor: 'Blepharitis'
      }
    },
    MESSAGE_GROUP_2: {
      probable: {
        LOGICAL_OR_GROUP_1: {
          CONJUNCTIVAL_CHALASIS: {
            condition: ScaleToNumberMap.grade2,
            operator: '>=',
            evidence: 'Conjunctival chalasis is grade 2 or higher'
          }
        },
        contributor: 'Conjunctivochalasis'
      }
    },
    MESSAGE_GROUP_3: {
      probable: {
        LOGICAL_OR_GROUP_1: {
          FLOPPY_EYELID_SYNDROME_GRADING: {
            condition: ScaleToNumberMap.grade1,
            operator: '>=',
            evidence: 'Floppy Eyelid Syndrome is moderate or worse'
          }
        },
        contributor: 'Floppy Eyelid Syndrome'
      }
    },
    MESSAGE_GROUP_4: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          LAGOPHTHALMUS: {
            condition: 'positive',
            operator: '=',
            evidence: 'Lagophthalmos is positive'
          }
        }
      },
      significant: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: (patientData: BothEyes) => {
              return (
                patientData.left === 'inferior' ||
                patientData.left === 'lowerConjunctivitis' ||
                patientData.right === 'inferior' ||
                patientData.right === 'lowerConjunctivitis'
              );
            },
            specialCondition: true,
            evidence: 'Inferior or Lower Conjunctivitis Corneal Staining Pattern'
          }
        },
        LOGICAL_OR_GROUP_2: {
          symptomSeverityOccurrence: {
            condition: 'dayStart',
            operator: '=',
            evidence: 'Symptoms are worse at the start of the day'
          }
        },
        contributor: 'Lagophthalmos with secondary Exposure Keratopathy'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: (patientData: BothEyes) => {
              return (
                patientData.left === 'inferior' ||
                patientData.left === 'lowerConjunctivitis' ||
                patientData.right === 'inferior' ||
                patientData.right === 'lowerConjunctivitis'
              );
            },
            specialCondition: true,
            evidence: 'Inferior or Lower Conjunctivitis Corneal Staining Pattern'
          },
          symptomSeverityOccurrence: {
            condition: 'dayStart',
            operator: '=',
            evidence: 'Symptoms are worse at the start of the day'
          }
        },
        contributor: 'Lagophthalmos with secondary Exposure Keratopathy'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Lagophthalmos with secondary Exposure Keratopathy'
      }
    },
    MESSAGE_GROUP_5: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          LID_REDNESS: {
            condition: ScaleToNumberMap.moderate,
            operator: '>=',
            evidence: 'Lid Redness is moderate or worse'
          }
        }
      },
      significant: {
        LOGICAL_OR_GROUP_1: {
          COLLARETTES: {
            condition: ScaleToNumberMap.moderate,
            operator: '>=',
            evidence: 'Collarettes is moderate or worse'
          }
        },
        LOGICAL_OR_GROUP_2: {
          eyeItchiness: {
            condition: 50,
            operator: '>=',
            evidence: 'Eye Itchiness is 5 or greater'
          }
        },
        LOGICAL_OR_GROUP_3: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'inferior',
            operator: '=',
            evidence: 'Inferior Corneal Staining pattern'
          }
        },
        contributor: 'Blepharitis'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          COLLARETTES: {
            condition: ScaleToNumberMap.moderate,
            operator: '>=',
            evidence: 'Collarettes is moderate or worse'
          },
          eyeItchiness: {
            condition: 50,
            operator: '>=',
            evidence: 'Eye Itchiness is 5 or greater'
          },
          CORNEAL_STAINING_PATTERN: {
            condition: 'inferior',
            operator: '=',
            evidence: 'Inferior Corneal Staining pattern'
          }
        },
        contributor: 'Blepharitis'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Blepharitis'
      }
    },
    MESSAGE_GROUP_6: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'diffuse',
            operator: '=',
            evidence: 'Diffuse Corneal Staining Pattern'
          }
        }
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          medications: {
            singleValueForBothEyes: true,
            specialCondition: true,
            evidence: 'Ophthalmic medication is used',
            condition: medications => {
              return medications.some(medication =>
                medication['routes'].some(
                  route => route['route_of_administration'] === 'Ophthalmic'
                )
              );
            }
          }
        },
        contributor: 'toxicity'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'toxicity or history of trauma',
        plural: true
      },

      specialNote: 'Please inquire about history of eye drops used.'
    },
    MESSAGE_GROUP_7: {
      possible: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'inferior',
            operator: '=',
            evidence: 'Inferior Corneal Staining Pattern'
          }
        },
        contributor: 'Trichiasis, Lagophthalmos or Blepharitis',
        plural: true
      }
    },
    MESSAGE_GROUP_8: {
      possible: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'interpalpebral',
            operator: '=',
            evidence: 'Interpalpebral Corneal Staining Pattern'
          }
        },
        LOGICAL_OR_GROUP_2: {
          CORNEAL_SENSATION: {
            specialCondition: true,
            condition: (patientData: BothEyes, qualitativeOrQuantitative: BothEyes) => {
              return (
                (qualitativeOrQuantitative.left === 'qualitative' &&
                  ScaleToNumberMap[patientData.left] === ScaleToNumberMap.normal) ||
                (qualitativeOrQuantitative.left === 'quantitative' && patientData.left >= 6) ||
                (qualitativeOrQuantitative.right === 'qualitative' &&
                  ScaleToNumberMap[patientData.right] === ScaleToNumberMap.normal) ||
                (qualitativeOrQuantitative.right === 'quantitative' && patientData.right >= 6)
              );
            },
            evidence: 'Corneal Sensation is normal'
          }
        },
        contributor: 'Exposure Keratopathy'
      }
    },
    MESSAGE_GROUP_10: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'superior',
            operator: '=',
            evidence: 'Superior Corneal Staining Pattern'
          }
        }
      },
      possible: {
        LOGICAL_OR_GROUP_1: {
          thyroid: {
            condition: true,
            operator: '=',
            evidence: 'History of Thyriod Disease'
          }
        },
        contributor: 'Superior limbic keratoconjunctivitis(SLK)'
      }
    },
    MESSAGE_GROUP_11: {
      possible: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'superior',
            operator: '=',
            evidence: 'Superior Corneal Staining Pattern'
          }
        },
        LOGICAL_OR_GROUP_2: {
          thyroid: {
            condition: thyroid => {
              return !thyroid;
            },
            specialCondition: true,
            evidence: 'No History of Thyriod Disease'
          }
        },

        LOGICAL_OR_GROUP_3: {
          contactLens: {
            condition: contactLensWear => {
              return !contactLensWear;
            },
            specialCondition: true,
            evidence: 'No contact lens wear'
          }
        },
        LOGICAL_OR_GROUP_4: {
          EPITHELIAL_THICKNESS: {
            condition: (epithelialThickness: BothEyes) => {
              return (
                (!epithelialThickness.left || epithelialThickness.left >= 48) &&
                (!epithelialThickness.right || epithelialThickness.right >= 48)
              );
            },
            evidence: 'Epithelial Thickness is not less than 48',
            specialCondition: true
          }
        },
        contributor:
          'Superior limbic keratoconjunctivitis(SLK), Foreign Body presence, Limbal Stem Cell Damage or Trichiasis'
      }
    },
    MESSAGE_GROUP_12: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'superiorConjunctivitis',
            operator: '=',
            evidence: 'Superior Conjunctivitis Corneal Staining Pattern'
          }
        }
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          thyroid: {
            condition: true,
            operator: '=',
            evidence: 'History of Thyriod Disease'
          }
        },
        contributor: 'Superior limbic keratoconjunctivitis(SLK)'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Superior limbic keratoconjunctivitis(SLK)'
      }
    },
    MESSAGE_GROUP_13: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'threeAndNineOClock',
            operator: '=',
            // tslint:disable-next-line:quotemark
            evidence: "3 and 9 O'Clock Corneal Staining Pattern"
          }
        }
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          contactLens: {
            condition: true,
            operator: '=',
            evidence: 'Contact lens wear'
          }
        },
        contributor: 'Contact Lens Usage'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Contact Lens Usage'
      }
    },
    MESSAGE_GROUP_14: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'lowerConjunctivitis',
            operator: '=',
            evidence: 'Lower Conjunctivitis Corneal Staining Pattern'
          }
        }
      },
      significant: {
        LOGICAL_OR_GROUP_1: {
          CONJUNCTIVAL_CHALASIS: {
            condition: ScaleToNumberMap.grade3,
            operator: '=',
            evidence: 'Conjunctival Chalasis is grade 3.'
          }
        },
        contributor: 'Conjunctivochalasis'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          CONJUNCTIVAL_CHALASIS: {
            condition: ScaleToNumberMap.grade2,
            operator: '=',
            evidence: 'Conjunctival Chalasis is grade 2.'
          }
        },
        contributor: 'Conjunctivochalasis'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Conjunctivochalasis'
      }
    }
  },
  dryEyeMimickersBasedOnSymptoms: {
    MESSAGE_GROUP_1: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          symptomSeverityOccurrence: {
            condition: 'dayStart',
            operator: '=',
            evidence: 'Symptoms are worse at the start of the day'
          }
        }
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          bothersomeEye: {
            condition: 'oneEyeOnly',
            operator: '=',
            evidence: 'Only one eye is bothersome'
          }
        },
        contributor: 'Anterior Basement Membrane Disease (ABMD) or Lagophthalmos',
        plural: true
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Anterior Basement Membrane Disease (ABMD) or Lagophthalmos',
        plural: true
      }
    },
    MESSAGE_GROUP_2: {
      possible: {
        LOGICAL_OR_GROUP_1: {
          bothersomeEye: {
            condition: 'oneEyeOnly',
            operator: '=',
            evidence: 'Only one eye is bothersome'
          }
        },
        LOGICAL_OR_GROUP_2: {
          symptomSeverityOccurrence: {
            condition: 'dayStart',
            operator: '!=',
            evidence: 'Symptoms are not worse at the start of the day'
          }
        },
        contributor:
          'Anterior Basement Membrane Disease (ABMD), Neurotrophic Keratopathy, Floppy Eyelid Syndrome, Trichiasis, Foreign Body presence, or Conjunctivitis',
        evidence: 'Which eye bothers you, Symptom severity occurrence'
      }
    },
    MESSAGE_GROUP_3: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          eyeItchiness: {
            condition: 70,
            operator: '>=',
            evidence: 'Eye Itchiness is 7 or greater'
          }
        }
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          seasonalAllergies: {
            specialCondition: true,
            evidence: 'Seasonal Allergies',
            condition: (seasonalAllergies: string) => {
              return !!seasonalAllergies && seasonalAllergies !== 'none';
            }
          }
        },
        LOGICAL_OR_GROUP_2: {
          bothersomeEye: {
            condition: 'both',
            operator: '=',
            evidence: 'Both eyes are bothersome'
          }
        },
        contributor: 'Allergic/Vernal/Atopic Conjunctivitis'
      },
      possible: {
        LOGICAL_OR_GROUP_1: {
          seasonalAllergies: {
            specialCondition: true,
            evidence: 'Seasonal Allergies',
            condition: (seasonalAllergies: string) => {
              return !!seasonalAllergies && seasonalAllergies !== 'none';
            }
          },
          bothersomeEye: {
            condition: 'both',
            operator: '=',
            evidence: 'Both eyes are bothersome'
          }
        },
        contributor: 'Allergic/Vernal/Atopic Conjunctivitis'
      },

      specialNote:
        'Please look for the following symptoms/signs to further validate concern: Conjunctival Chemosis, Eyelid Edema, Conjunctival Papillae, History of Allergic rhinitis, or Horner-Trantas Dots'
    },
    MESSAGE_GROUP_4: {
      significant: {
        LOGICAL_OR_GROUP_1: {
          screenTime: {
            condition: ScaleToNumberMap.group3,
            operator: '>=',
            evidence: 'Screen time per day is greater than 3 hours'
          }
        },
        LOGICAL_OR_GROUP_2: {
          PARTIAL_BLINKING_FREQUENCY: {
            condition: 40,
            operator: '>=',
            evidence: 'Partial Blinking Frequency is 40% or greater'
          }
        },
        LOGICAL_OR_GROUP_3: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'interpalpebral',
            operator: '=',
            evidence: 'Interpalpebral Corneal Staining Pattern'
          }
        },
        LOGICAL_OR_GROUP_4: {
          CORNEAL_STAINING_EXTENT: {
            qualitative: {
              condition: ScaleToNumberMap.grade2,
              operator: '>='
            },
            quantitative: {
              condition: ScaleToNumberMap.moderate,
              operator: '>='
            },
            evidence:
              'Vital Dye Staining is grade 2 or greater (qualitative) or it is moderate or worse (quantitative)'
          }
        },
        contributor: 'Partial Blinking'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          screenTime: {
            condition: ScaleToNumberMap.group3,
            operator: '>=',
            evidence: 'Screen time per day is greater than 3 hours'
          }
        },
        LOGICAL_OR_GROUP_2: {
          PARTIAL_BLINKING_FREQUENCY: {
            condition: 40,
            operator: '>=',
            evidence: 'Partial Blinking Frequency is 40% or greater'
          }
        },
        contributor: 'Partial Blinking'
      },
      possible: {
        LOGICAL_OR_GROUP_1: {
          screenTime: {
            condition: ScaleToNumberMap.group3,
            operator: '>=',
            evidence: 'Screen time per day is greater than 3 hours'
          },
          PARTIAL_BLINKING_FREQUENCY: {
            condition: 40,
            operator: '>=',
            evidence: 'Partial Blinking Frequency is 40% or greater'
          }
        },
        contributor: 'Partial Blinking'
      }
    },
    MESSAGE_GROUP_5: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          tearingQuality: {
            condition: 'indoorAndOutdoor',
            operator: '=',
            evidence: 'Eye tears both indoor and outdoors'
          }
        }
      },
      significant: {
        LOGICAL_OR_GROUP_1: {
          bothersomeEye: {
            specialCondition: true,
            evidence: 'Only one eye is bothersome or one eye is more bothersome than the other',
            condition: patientData => {
              return patientData === 'oneEyeOnly' || patientData === 'oneMoreThanOther';
            }
          }
        },
        LOGICAL_OR_GROUP_2: {
          eyeTearingQuantity: {
            condition: 80,
            operator: '>=',
            evidence: 'Eye Tearing Quantity is 8 or greater'
          }
        },
        contributor: 'Punctal Stenosis AND/OR Nasolacrimal duct obstruction'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          bothersomeEye: {
            specialCondition: true,
            evidence: 'Only one eye is bothersome or one eye is more bothersome than the other',
            condition: patientData => {
              return patientData === 'oneEyeOnly' || patientData === 'oneMoreThanOther';
            }
          },
          eyeTearingQuantity: {
            condition: 80,
            operator: '>=',
            evidence: 'Eye Tearing Quantity is 8 or greater'
          }
        },
        contributor: 'Punctal Stenosis AND/OR Nasolacrimal duct obstruction'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Punctal Stenosis AND/OR Nasolacrimal duct obstruction'
      }
    },
    MESSAGE_GROUP_6: {
      MAIN_CONDITION: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_SENSATION: {
            qualitative: {
              condition: ScaleToNumberMap.reduced,
              operator: '>='
            },
            quantitative: {
              condition: ScaleToNumberMap.mild,
              operator: '>='
            },
            evidence:
              'Corneal Sensation is reduced or absent (qualitative) or it is mild or worse (quantitative)'
          }
        }
      },
      significant: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'interpalpebral',
            operator: '=',
            evidence: 'Interpalpebral Corneal Staining Pattern'
          }
        },
        LOGICAL_OR_GROUP_2: {
          bothersomeEye: {
            condition: 'oneEyeOnly',
            operator: '=',
            evidence: 'Only one eye is bothersome'
          }
        },
        contributor: 'Neurotrophic Keratopathy'
      },
      probable: {
        LOGICAL_OR_GROUP_1: {
          CORNEAL_STAINING_PATTERN: {
            condition: 'interpalpebral',
            operator: '=',
            evidence: 'Interpalpebral Corneal Staining Pattern'
          },
          bothersomeEye: {
            condition: 'oneEyeOnly',
            operator: '=',
            evidence: 'Only one eye is bothersome'
          }
        },
        contributor: 'Neurotrophic Keratopathy'
      },
      otherwise: {
        contributionLevel: 'possible',
        contributor: 'Neurotrophic Keratopathy',
        plural: true
      }
    }
  }
};

export const PropertiesMapForSignsAndSymptoms = {
  bothersomeEye: { singleValueForBothEyes: true },
  COLLARETTES: { convertToNumericValue: true },
  CONJUNCTIVAL_CHALASIS: { convertToNumericValue: true },
  contactLens: { singleValueForBothEyes: true },
  CORNEAL_SENSATION: {
    qualitativeOrQuantitative: true,
    quantitative: { convertToNumericValue: true },
    qualitative: { convertToNumericValue: true }
  },
  CORNEAL_STAINING_PATTERN: {},
  deq: { singleValueForBothEyes: true },
  EPITHELIAL_THICKNESS: {},
  eyeItchiness: { singleValueForBothEyes: true },
  eyeTearingQuantity: { singleValueForBothEyes: true },
  FLOPPY_EYELID_SYNDROME_GRADING: { convertToNumericValue: true },
  LAGOPHTHALMUS: {},
  LID_REDNESS: { convertToNumericValue: true },
  medications: { singleValueForBothEyes: true },
  osdi: { singleValueForBothEyes: true },
  PARTIAL_BLINKING_FREQUENCY: {},
  screenTime: { singleValueForBothEyes: true, convertToNumericValue: true },
  seasonalAllergies: { singleValueForBothEyes: true },
  speed: { singleValueForBothEyes: true },
  symptomSeverityOccurrence: { singleValueForBothEyes: true },
  tearingQuality: { singleValueForBothEyes: true },
  thyroid: { singleValueForBothEyes: true },
  MEIBOGRAPHY: { convertToNumericValue: true },
  MGE_EXPRESSIBILITY: { convertToNumericValue: true },
  MGE_SECRETION: { convertToNumericValue: true },
  MMP9: { convertToNumericValue: true },
  CORNEAL_STAINING_EXTENT: {
    qualitativeOrQuantitative: true,
    qualitative: { convertToNumericValue: true },
    quantitative: { convertToNumericValue: true }
  },
  previousOcularSurgery: { singleValueForBothEyes: true },
  LWE: { convertToNumericValue: true }
};

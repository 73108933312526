import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';
import { ModalSymptomCustomModalOptions } from './../../../symptom.model';

@Component({
  selector: 'csi-bulbar-limbal-modal',
  templateUrl: './bulbar-limbal-modal.component.html',
  styleUrls: ['./bulbar-limbal-modal.component.scss']
})
export class BulbarLimbalModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit {
  isBulbar = this.config.formKey.includes('BULBAR');

  public formGroup = new FormGroup({
    temporal: new FormControl(),
    nasal: new FormControl()
  });

  constructor(
    public dialogRef: MatDialogRef<BulbarLimbalModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    const currentValue = this.config.formGroup.controls[this.config.formKey].value;
    if (currentValue && typeof currentValue === 'object') {
      this.formGroup.patchValue(currentValue);
    }
  }

  save() {
    this.config.formGroup.controls[this.config.formKey].setValue(this.formGroup.value);
    this.dialogRef.close();
  }

  static valueMap(value: any) {
    return `N: ${value.nasal}, T: ${value.temporal}`;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormTemplateGeneratorService } from '../../form-template-generator.service';
import { DateConfig, FormFieldConfig } from './../../../form-template-model';

@Component({
  selector: 'csi-form-template-date-field-generator',
  templateUrl: './form-template-date-field-generator.component.html',
  styleUrls: ['./form-template-date-field-generator.component.css']
})
export class FormTemplateDateFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: DateConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {}
}

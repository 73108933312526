import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { formatDate } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  LOCALE_ID,
  NgZone
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Logger } from 'aws-amplify';
import { EMPTY, Observable, Subject, iif, of, zip } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { FaxService, QueueFaxResult } from 'src/app/core/api/fax.service';
import { Clinic, ClinicService } from '../../core/api/clinic.service';
import { Html2PdfService } from '../../core/api/html-2-pdf.service';
import { AssessmentBody } from '../../econsult/assessment-body/assessment-body.model';
import { DryEyePdfExportComponent, PDFType } from './dry-eye-pdf-export.component';

@Injectable()
export class PDFExportService {
  private pdfExportComponentPortal: ComponentPortal<DryEyePdfExportComponent>;
  private bodyPortalHost: DomPortalHost;

  private debugWindow: Window;

  private logger: Logger = new Logger('PDFExportService');

  public chartReady$: Subject<boolean> = new Subject();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private html2PdfService: Html2PdfService,
    private clinicService: ClinicService,
    private faxService: FaxService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private ngZone: NgZone,
    private translocoService: TranslocoService
  ) {
    this.setupPortals();
  }

  public downloadPDFForAssessmentBody(
    assessmentBody: AssessmentBody,
    clinic: Clinic | Doctor['clinic'],
    type: PDFType,
    debug: boolean = false,
    lang: string = 'en'
  ): Observable<boolean> {
    const filename = `DryEye_${this.getDateForPDFFilename()}.pdf`;
    return this.getHtmlForAssessmentBody(assessmentBody, clinic, type, false, debug, lang).pipe(
      switchMap(html => this.html2PdfService.saveHtmlAsPdf(html, filename))
    );
  }

  public faxAssessment(
    to: string,
    body: AssessmentBody,
    clinic: Clinic,
    debug: boolean = false,
    lang: string = 'en'
  ): Observable<QueueFaxResult> {
    return this.getHtmlForAssessmentBody(body, clinic, 'detailed', true, debug, lang).pipe(
      switchMap((html: string) =>
        this.faxService.queueFax({
          htmlInBase64: this.faxService.htmlToBase64(html),
          title: body.id,
          to: to
        })
      )
    );
  }

  public getHtmlForAssessmentBody(
    assessmentBody: AssessmentBody,
    clinic: Clinic | Doctor['clinic'],
    type: PDFType,
    hideDataForFaxing: boolean = false,
    debug: boolean = false,
    lang: string
  ): Observable<string> {
    if (debug) {
      // Open the debug window here since this spot is the only spot that won't trigger browser
      // popup blocking if this method is called from a click handler.
      this.openDebugWindow();
    }

    return zip(
      iif(() => !!clinic, this.clinicService.getClinicLogo(clinic.id), of(null)),
      this.translocoService.load(lang)
    ).pipe(
      switchMap(([clinicLogo, translateObject]) => {
        const componentRef = this.bodyPortalHost.attach(this.pdfExportComponentPortal);
        (componentRef.location.nativeElement as HTMLElement).style.display = 'none';

        componentRef.instance.data = assessmentBody;
        componentRef.instance.pdfType = type;
        componentRef.instance.clinicLogo = clinicLogo;
        componentRef.instance.hideDataForFaxing = hideDataForFaxing;
        componentRef.instance.translateObject = translateObject;

        this.logger.debug('here is my data', componentRef.instance.data);

        return iif(() => type === 'summary', this.chartReady$, of(null)).pipe(
          mergeMap(() => {
            return componentRef.instance.html.pipe(
              tap(html => {
                this.bodyPortalHost.detach();

                if (debug) {
                  this.writeHTMLToDebugWindow(html);
                }
              }),
              catchError((err, caught) => {
                console.log(err, caught);
                return EMPTY;
              })
            );
          })
        );
      })
    );
  }

  public preloadClinicLogo(clinic: Clinic | Doctor['clinic']) {
    if (clinic) {
      this.clinicService.getClinicLogo(clinic.id).subscribe();
    }
  }

  private getDateForPDFFilename(): string {
    return formatDate(new Date(), 'yyyy-mm-ddThh-mm-ss', this.locale);
  }

  private openDebugWindow() {
    this.debugWindow = window.open('', '_blank');
    this.debugWindow.document.title = 'CSI PDF Debug Loading...';
  }

  private writeHTMLToDebugWindow(html: string) {
    this.debugWindow.document.write(html);
    this.debugWindow.document.title = 'CSI PDF Debug';

    // For some reason the dry eye category component renders weird without this.
    // The 227px is the minimum but it can be set higher (lower values also cause
    // weird rendering). After 1 cycle we can clear the height (we do this
    // otherwise the body has no padding at the bottom).
    this.debugWindow.document.body.style.height = '227px';
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.debugWindow.document.body.style.height = '';
      });
    });
  }

  private setupPortals() {
    this.pdfExportComponentPortal = new ComponentPortal(DryEyePdfExportComponent);

    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }
}

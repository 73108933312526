import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { StaffService } from 'src/app/core/api/staff.service';
import { Attachment, AttachmentsComponent } from 'src/app/shared/attachments/attachments.component';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { MediaSelectionModalComponent } from 'src/app/shared/media-selection-modal/media-selection-modal.component';
import { SignsAttachmentsService } from '../signs-attachments-modal/signs-attachments.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MobileImageWizardAttachmentsComponent),
  multi: true
};

@Component({
  selector: 'csi-mobile-image-wizard-attachments',
  templateUrl: './mobile-image-wizard-attachments.component.html',
  styleUrls: ['./mobile-image-wizard-attachments.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MobileImageWizardAttachmentsComponent extends AttachmentsComponent
  implements OnInit, ControlValueAccessor {
  @Input() signName: string;
  @Input() imageGalleryEnabled = true;

  constructor(
    datePipe: DatePipe,
    dialog: MatDialog,
    staffService: StaffService,
    dataSrcService: DataSrcService,
    signsAttachmentsService: SignsAttachmentsService,
    loadingSpinnerService: LoadingSpinnerService
  ) {
    super(
      datePipe,
      dialog,
      staffService,
      dataSrcService,
      signsAttachmentsService,
      loadingSpinnerService
    );
  }

  ngOnInit() {
    if (this.maxAttachments && this.maxAttachments === 1) {
      this.formControl.valueChanges.subscribe(attachments => {
        if (attachments && attachments.length > this.maxAttachments) {
          const title =
            'Maximum number of attachments exceeded for ' +
            this.signsAttachmentsService.getEyeLocationFromMetadata(this.metadata) +
            ' ' +
            this.signName;
          const mediaDialogRef = MediaSelectionModalComponent.open(this.dialog, attachments, title);
          mediaDialogRef.afterClosed().subscribe(selectedAttachment => {
            if (selectedAttachment) {
              this.attachments = [selectedAttachment];
              this.updateControlValue();
            }
          });
        }
      });
    }
  }

  private addAttachment(attachment: Attachment) {
    attachment['metadata'] = JSON.stringify(this.metadata);
    this.attachments = [...this.attachments, attachment];
    this.updateControlValue();
  }

  protected updateControlValue() {
    this.onChangeCallback(this.attachments);
  }
}

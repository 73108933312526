import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {
  transform(
    array: string[] | { [key: string]: any }[],
    keyValueMap: { [key: string]: string },
    sortKeys: string[] = null,
    keysToMoveLast: string[] = [],
    keyAccessor: string = null
  ): any {
    if (keyValueMap) {
      return array.sort((a, b) => {
        const aKey = keyAccessor ? a[keyAccessor] : a;
        const bKey = keyAccessor ? b[keyAccessor] : b;
        if (keysToMoveLast.includes(aKey)) {
          return 1;
        }
        if (keysToMoveLast.includes(bKey)) {
          return -1;
        }
        return (sortKeys ? this.getNestedValue(a, sortKeys) : keyValueMap[a])
          .trim()
          .localeCompare((sortKeys ? this.getNestedValue(b, sortKeys) : keyValueMap[b]).trim());
      });
    }
    return array.sort();
  }

  getNestedValue(obj: any, keys: string[]) {
    for (let i = 0; i < keys.length; i++) {
      if (obj[keys[i]]) {
        obj = obj[keys[i]];
      } else {
        return '';
      }
    }

    return obj;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DryEyeCategoryComponent } from 'src/app/shared/consult-forms/dry-eye-forms/dry-eye-category/dry-eye-category.component';
import { DryEyeCategory } from '../../../core/remote-json/treatments/dry-eye-category.model';
import { PDFType } from '../dry-eye-pdf-export.component';



@Component({
  selector: 'csi-pdf-export-dry-eye-category',
  templateUrl: './pdf-export-dry-eye-category.component.html',
  styleUrls: ['./pdf-export-dry-eye-category.component.css']
})
export class PdfExportDryEyeCategoryComponent implements OnInit {
  @Input() selectedCategory: DryEyeCategory;
  @Input() pdfType: PDFType;

  readonly allCategories = DryEyeCategoryComponent.allCategories;

  constructor() {}

  ngOnInit() {}
}

import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  EyeDrop,
  Therapy,
  Treatment,
  TreatmentsService
} from '../core/remote-json/treatments/treatments.service';

export type EyeDropPart =
  | 'starIngredients'
  | 'preservatives'
  | 'indication'
  | 'all'
  | 'specificFields';

export interface TreatmentRow {
  field: string;
  value: string | string[];
}

export enum EyeDropField {
  name = 'name',
  type = 'type',
  manufacturer = 'manufacturer',
  class = 'class',
  indication = 'indication',
  preservatives = 'preservatives',
  starIngredients = 'starIngredients',
  contactLensCompatible = 'contactLensCompatible',
  comments = 'comments'
}

@Pipe({
  name: 'treatment'
})
export class TreatmentPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe, private treatmentsService: TreatmentsService) {}

  transform(
    value: Treatment,
    pick: EyeDropPart = 'all',
    fieldsToTransform: EyeDropField[] = null
  ): string | TreatmentRow[] {
    if (value.class === 'rx' || value.class === 'otc') {
      const eyeDrop = value as EyeDrop;
      switch (pick) {
        case 'indication':
          return this.treatmentsService.getIndications(eyeDrop);
        case 'preservatives':
          return this.treatmentsService.getPreservatives(eyeDrop);
        case 'all':
          return this.transformEyeDrop(eyeDrop);
        case 'starIngredients':
          return this.treatmentsService.getStarIngredients(eyeDrop);
        case 'specificFields':
          return this.transformEyeDrop(eyeDrop, fieldsToTransform);
        default:
          return null;
      }
    } else {
      const therapy = value as Therapy;
      return this.transformAllTherapy(therapy);
    }
  }

  private transformEyeDrop(
    eyeDrop: EyeDrop,
    fieldsToTransform: EyeDropField[] = null
  ): TreatmentRow[] {
    let rows: TreatmentRow[] = [];

    if (!fieldsToTransform) {
      Object.values(EyeDropField).map(field => rows.push(this.getEyeDropField(eyeDrop, field)));
    } else {
      fieldsToTransform.map(field => rows.push(this.getEyeDropField(eyeDrop, field)));
    }

    rows = rows.filter(treatmentRow => !!treatmentRow.value);

    return rows;
  }

  private getEyeDropField(
    eyeDrop: EyeDrop,
    fieldToGet: EyeDropField
  ): { field: string; value: string } {
    switch (fieldToGet) {
      case EyeDropField.name:
        return { field: 'Name', value: eyeDrop.name };
      case EyeDropField.type:
        return { field: 'Type', value: this.titleCasePipe.transform(eyeDrop.type) };
      case EyeDropField.manufacturer:
        return { field: 'Manufacturer', value: this.titleCasePipe.transform(eyeDrop.manufacturer) };
      case EyeDropField.indication:
        return { field: 'For', value: this.transform(eyeDrop, 'indication') as string };
      case EyeDropField.class:
        return {
          field: 'Requires Prescription',
          value: eyeDrop.class === 'rx' ? 'Yes' : 'No'
        };
      case EyeDropField.preservatives:
        let preservatives = this.transform(eyeDrop, 'preservatives') as string;
        preservatives = preservatives || 'None';
        return {
          field: 'Preservatives',
          value: this.titleCasePipe.transform(preservatives)
        };
      case EyeDropField.starIngredients:
        return {
          field: 'Star Ingredients',
          value: eyeDrop.starIngredients
            ? eyeDrop.starIngredients
                .map(starIngredient => {
                  let result = starIngredient.name;
                  if (starIngredient.description) {
                    result = `${result}: ${starIngredient.description}`;
                  }
                  return result;
                })
                .join('\n\n')
            : ''
        };
      case EyeDropField.contactLensCompatible:
        return {
          field: 'Contact Lens Compatible',
          value: eyeDrop.contactLensCompatible ? 'Yes' : 'No'
        };
      case EyeDropField.comments:
        return { field: 'Comments', value: eyeDrop.comments };
    }
  }

  private transformAllTherapy(treatment: Therapy): TreatmentRow[] {
    const rows: TreatmentRow[] = [];

    rows.push(
      { field: 'Name', value: treatment.name },
      { field: 'Description', value: treatment.description }
    );
    return rows;
  }
}

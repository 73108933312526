import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { Symptom } from 'src/app/shared/symptoms/symptom.model';
import { ClinicSetupService } from '../../clinic-setup.service';
import { Layout } from './../../../../../API';
import { LayoutsService } from './../layouts.service';
import { CanHideSignPipe } from './can-hide-sign.pipe';

@Component({
  selector: 'csi-signs-layout',
  templateUrl: './signs-layout.component.html',
  styleUrls: ['./signs-layout.component.scss']
})
export class SignsLayoutComponent implements OnInit {
  @Input() signsLayoutFormControl: FormControl;
  @Input() isSaving: boolean;
  @Input() layout: Layout;
  @Input() layoutSetting: string;
  @Input() preventHide: boolean;

  public isClinicAdmin: boolean;

  // Signs have been named as symptoms
  public signsMap = this.schemaService.symptomMap;

  // Needed for html disabled check
  public manualTrigger: number;
  public touchEnabled = false;

  hideSignKeys: string[] = [];
  showSignKeys: string[] = [];

  constructor(
    private schemaService: SchemaService,
    public clinicSetupService: ClinicSetupService,
    public canHideSignPipe: CanHideSignPipe,
    private layoutsService: LayoutsService
  ) {}

  ngOnInit() {
    this.layout = { ...this.layout };
    this.signsLayoutFormControl.setValue(this.layout);
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;

    this.showSignKeys = [...this.layout[this.layoutSetting].show];
    this.hideSignKeys = this.schemaService.symptomKeys.filter(
      symptomKey => !this.showSignKeys.includes(symptomKey)
    );

    this.touchEnabled = this.layoutsService.isTouchEnabled();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.setFormControl();
  }

  canBeHiddenPredicate(item: CdkDrag<[string, Symptom, boolean]>, list: CdkDropList) {
    const canHideSignPipe = new CanHideSignPipe();
    return canHideSignPipe.transform(
      item.data[1],
      item.data[0],
      list.connectedTo[0].data,
      item.data[2]
    );
  }

  setVisibility(previousIndex, action: 'show' | 'hide') {
    const [previousArray, currentArray] =
      action === 'hide'
        ? [this.showSignKeys, this.hideSignKeys]
        : [this.hideSignKeys, this.showSignKeys];
    transferArrayItem(previousArray, currentArray, previousIndex, currentArray.length);
    this.manualTrigger = Math.random();
    this.setFormControl();
  }

  reorder(previousIndex, direction: 'up' | 'down') {
    moveItemInArray(
      this.showSignKeys,
      previousIndex,
      direction === 'up' ? previousIndex - 1 : previousIndex + 1
    );
    this.setFormControl();
  }

  setFormControl() {
    this.layoutsService.updateLayoutFormControlValue(
      this.signsLayoutFormControl,
      this.layoutSetting,
      {
        show: this.showSignKeys,
        hide: this.hideSignKeys
      }
    );
  }
}

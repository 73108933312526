import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

interface AssessmentFormControls {
  examFindings: FormControl;
  images: FormControl;
  impressions: FormControl;
  recommendedPlanByDoctor: FormControl;
}

export class AssessmentFormGroup extends FormGroup {
  controls: AssessmentFormControls & { [key: string]: AbstractControl };
  submitted = false;

  constructor() {
    super({
      examFindings: new FormControl('', Validators.required),
      images: new FormControl([]),
      impressions: new FormControl('', Validators.required),
      recommendedPlanByDoctor: new FormControl('', Validators.required)
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'csi-form-template-field-config',
  templateUrl: './form-template-field-config.component.html',
  styleUrls: ['./form-template-field-config.component.css']
})
export class FormTemplateFieldConfigComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;
  constructor() {}

  ngOnInit() {
    Object.keys(this.templateFieldFormGroup.controls.typeConfig['controls']).forEach(
      formControlKey => {
        if (formControlKey !== 'type') {
          this.templateFieldFormGroup.removeControl(formControlKey);
        }
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntakeFormViewComponent } from './intake-form-view/intake-form-view.component';
import { IntakeFormsViewComponent } from './intake-forms-view/intake-forms-view.component';

const routes: Routes = [
  {
    path: '',
    component: IntakeFormsViewComponent,
    children: [
      {
        path: ':id',
        component: IntakeFormViewComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntakeFormRoutingModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';
import { QuestionnaireType } from './../../../API';

@Component({
  selector: 'csi-questionnaire-select',
  templateUrl: './questionnaire-select.component.html',
  styleUrls: ['./questionnaire-select.component.css']
})
export class QuestionnaireSelectComponent implements OnInit {
  @Input() questionnaireTypeFormControl: FormControl;
  // @Input() questionnaireTypesFormGroup: FormGroup;
  public questionnaireTypes = Object.keys(QuestionnaireType);

  constructor() {}

  ngOnInit() {}

  noSort = noSort;
}

import { Component, Input, OnInit } from '@angular/core';
import { SignInstruction } from '../signs-attachments-modal/signs-attachments-schema';

@Component({
  selector: 'csi-sign-instruction',
  templateUrl: './sign-instruction.component.html',
  styleUrls: ['./sign-instruction.component.scss']
})
export class SignInstructionComponent implements OnInit {
  @Input() instructionsConfig: SignInstruction;

  constructor() {}

  ngOnInit() {}
}

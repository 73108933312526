import { QuestionnaireType } from 'src/API';

export const QuestionnaireRequestUtils = {
  getQuestionnaireType(value: QuestionnaireType): string {
    if (value === QuestionnaireType.OSDIAndSPEED) {
      return 'OSDI and SPEED Combined';
    } else if (value === QuestionnaireType.DEQ) {
      return 'DEQ-5';
    } else {
      return QuestionnaireType[value];
    }
  }
};

export const getQuestionnaireKeys = () =>
  Object.values(QuestionnaireType)
    .filter(item => item !== QuestionnaireType.OSDIAndSPEED)
    .map(item => item.toLowerCase()) as string[];

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Assessment } from 'src/API';
import { Attachment } from 'src/app/shared/attachments/attachments.component';
import { Eye, signGroups, signToFriendlyNameMap } from './signs-attachments-schema';

@Injectable({
  providedIn: 'root'
})
export class SignsAttachmentsService {
  public metadataToAttachmentsMap: { [key: string]: Attachment[] } = {};
  public attachmentsFormControl: FormControl = new FormControl();
  public saveFn: () => Observable<Assessment>;
  public signGroupComplete: { [key: string]: boolean } = Object.keys(signGroups).reduce(
    (acc, key) => {
      acc[key] = false;
      return acc;
    },
    {}
  );
  public signToFriendlyNameMap: { [key: string]: string } = Object.keys(signToFriendlyNameMap)
    .sort()
    .reduce((sorted, key) => {
      sorted[key] = signToFriendlyNameMap[key];
      return sorted;
    }, {});

  constructor() {}

  public initializeAttachmentsLink(
    formControl: FormControl,
    saveFn?: () => Observable<Assessment>
  ) {
    this.attachmentsFormControl = formControl;
    this.loadMetadataToAttachmentsMap();
    if (saveFn) {
      this.saveFn = saveFn;
    }
  }

  public updateMetadataToAttachmentsMap(
    metadata: { [key: string]: string },
    files: File[] | Attachment[]
  ) {
    if (!files) return;
    if (metadata) {
      this.metadataToAttachmentsMap[metadata['sign']] = [...files];
    } else {
      this.loadMetadataToAttachmentsMap();
    }
  }

  public loadMetadataToAttachmentsMap() {
    if (!this.attachmentsFormControl) return;
    this.metadataToAttachmentsMap = {};
    for (const attachment of this.attachmentsFormControl.value) {
      if (attachment['metadata']) {
        const metadata = JSON.parse(attachment['metadata']).sign;
        this.metadataToAttachmentsMap[metadata] = [
          ...(this.metadataToAttachmentsMap[metadata] || []),
          attachment
        ] || [attachment];
      }
    }
  }

  checkMetadata(key: string, value: string, compareTo: { [key: string]: string }) {
    if (compareTo && compareTo[key] && compareTo[key] === value) {
      return true;
    }
    return false;
  }

  public loadSignAttachmentsToControl(
    rightEyeControl: FormControl,
    rightEyeMetadata: { [key: string]: string },
    leftEyeControl: FormControl,
    leftEyeMetadata: { [key: string]: string }
  ) {
    rightEyeControl.setValue(this.metadataToAttachmentsMap[rightEyeMetadata.sign] || []);
    leftEyeControl.setValue(this.metadataToAttachmentsMap[leftEyeMetadata.sign] || []);
  }

  public updateAttachments() {
    let updatedAttachments = [];
    for (const attachment of this.attachmentsFormControl.value) {
      if (!attachment['metadata']) {
        updatedAttachments.push(attachment);
      }
    }
    let newAttachments = [];
    Object.values(this.metadataToAttachmentsMap).forEach(attachments => {
      newAttachments = [...newAttachments, ...attachments];
    });

    updatedAttachments = [...updatedAttachments, ...newAttachments];
    if (this.attachmentsFormControl) {
      this.attachmentsFormControl.setValue(updatedAttachments);
    }
  }

  generateMetadata(sign: string, eye?: Eye) {
    if (eye === 'right') {
      return { sign: 'RIGHT_' + sign, uploadDate: '' + new Date().getTime() };
    } else if (eye === 'left') {
      return { sign: 'LEFT_' + sign, uploadDate: '' + new Date().getTime() };
    }
  }

  getSignFromMetadata(metadata: { [key: string]: string }) {
    if (!metadata.sign) return '';
    return metadata.sign.substring(metadata.sign.indexOf('_') + 1);
  }

  getEyeLocationFromMetadata(metadata: { [key: string]: string }) {
    if (!metadata.sign) return '';
    return metadata.sign.substring(0, metadata.sign.indexOf('_'));
  }
}

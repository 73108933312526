import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import cleanDeep from 'clean-deep';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import {
  AssessmentType,
  CreateAssessmentInput,
  CreateAssessmentMutation,
  GetAssessmentQuery,
  GetAssessmentRequestWithDoctorQuery,
  ListAssessmentsQuery,
  UpdateAssessmentInput,
  UpdateAssessmentMutation,
  UpdateAssessmentRequestInput
} from '../../../API';
import {
  getAllAssessmentInfo,
  getAssessmentRequestWithDoctor,
  lockAssessment
} from '../../../graphql/custom_queries';
import {
  createAssessment,
  createAssessmentRequest,
  updateAssessment,
  updateAssessmentRequest
} from '../../../graphql/mutations';
import { Attachment } from '../../shared/attachments/attachments.component';
import { AppSyncService } from '../appsync.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {
  CreateAssessmentRequestInput,
  ListAssessmentsByClinicIdCreatedAtQuery,
  S3ObjectInput
} from './../../../API';
import { S3AttachmentService } from './../../shared/attachments/s3-attachment.service';
import { PatientService } from './patient.service';
import { StaffService } from './staff.service';

export type Assessment = GetAssessmentQuery['getAssessment'];
export type AssessmentRequest = GetAssessmentRequestWithDoctorQuery['getAssessmentRequest'];
export type AssessmentList = ListAssessmentsQuery['listAssessments'];

export interface CameraAssessmentRequest {
  assessmentId?: string;
  patientName?: string;
  patientId?: string;
  uploadDate?: number;
  requestDate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  public currentAssessment: Assessment;
  private listAssessmentsByClinicIdCreatedAt = /* GraphQL */ `
    query ListAssessmentsByClinicIdCreatedAt(
      $assessmentClinicId: ID
      $createdAt: ModelStringKeyConditionInput
      $nextToken: String
    ) {
      listAssessmentsByClinicIdCreatedAt(
        assessmentClinicId: $assessmentClinicId
        createdAt: $createdAt
        nextToken: $nextToken
      ) {
        items {
          id
          patient {
            id
            firstName
            lastName
            email
            phone
            agreedToTerms
            dateOfBirth
            gender
            genderOther
            address
            createdAt
            updatedAt
            nonModifiableData
          }
          assessmentClinicId
          type
          body
          attachments {
            bucket
            key
            region
            fileName
            metadata
          }
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

  public isViewOnly: boolean;

  constructor(
    private appSyncService: AppSyncService,
    private authService: AuthenticationService,
    private patientService: PatientService,
    private staffService: StaffService,
    private s3AttachmentService: S3AttachmentService,
    private clinicSetupService: ClinicSetupService
  ) {
    this.isViewOnly = !(
      this.staffService.isDoctor ||
      this.staffService.isTech ||
      this.staffService.isClinicOwner
    );
  }

  public createAssessment(
    type: AssessmentType,
    body: any,
    attachments: Attachment[],
    patientId?: string,
    doctorId?: string,
    isLocked = false
  ): Observable<Assessment> {
    const input: CreateAssessmentInput = {
      type: type,
      // Dynamo does not accept empty fields so remove them via cleanDeep
      // see https://forums.aws.amazon.com/thread.jspa?messageID=870962&#870962
      body: JSON.stringify(cleanDeep(body)),
      assessmentPatientId: patientId,
      assessmentDoctorId: doctorId,
      locked: isLocked
    };

    return this.prepAttachmentsForUpload(attachments).pipe(
      switchMap(s3ObjectInputs => {
        if (s3ObjectInputs) {
          input.attachments = s3ObjectInputs;
        }
        return this.appSyncService.hydrated();
      }),
      switchMap(client => {
        return client.mutate({
          mutation: gql(createAssessment),
          variables: { input: input },
          update: (proxy, mutationResult) => {
            if (!!patientId) {
              this.patientService.updateAssessmentsForPatient(
                proxy,
                mutationResult.data as CreateAssessmentMutation,
                patientId
              );
            }
          }
        });
      }),
      map(result => result.data.createAssessment)
    );
  }

  public updateAssessment(
    id: string,
    type: AssessmentType,
    body: any,
    attachments: Attachment[],
    patientId: string,
    doctorId?: string,
    isLocked?: boolean
  ): Observable<Assessment> {
    const input: UpdateAssessmentInput = {
      id: id,
      type: type,
      body: JSON.stringify(cleanDeep(body)),
      assessmentPatientId: patientId,
      assessmentDoctorId: doctorId,
      locked: isLocked
    };
    return this.prepAttachmentsForUpload(attachments).pipe(
      switchMap(s3ObjectInputs => {
        input.attachments = s3ObjectInputs;
        return this.appSyncService.hydrated();
      }),
      switchMap(client => {
        return client.mutate({
          mutation: gql(updateAssessment),
          variables: { input: input }
        });
      }),
      map((result: ApolloQueryResult<UpdateAssessmentMutation>) => result.data.updateAssessment)
    );
  }

  public getAssessment(
    id: string,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<Assessment> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<GetAssessmentQuery>({
          query: gql(getAllAssessmentInfo),
          variables: { id: id },
          fetchPolicy
        })
      ),
      map((result: ApolloQueryResult<GetAssessmentQuery>) => result.data.getAssessment)
    );
  }

  public getAttachments(id: string): Observable<Attachment[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<GetAssessmentQuery>({
          query: gql(/* GraphQL */ `
            query GetAssessment($id: ID!) {
              getAssessment(id: $id) {
                id
                attachments {
                  bucket
                  key
                  region
                  fileName
                  metadata
                }
              }
            }
          `),
          variables: { id: id },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<GetAssessmentQuery>) => result.data.getAssessment.attachments)
    );
  }

  public unlockAssessment(id: string): Observable<Partial<Assessment>> {
    const input: UpdateAssessmentInput = {
      id: id,
      locked: false
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(lockAssessment),
          variables: { input: input }
        });
      }),
      map((result: ApolloQueryResult<UpdateAssessmentMutation>) => result.data.updateAssessment)
    );
  }

  public prepAttachmentsForUpload(attachments: Attachment[]): Observable<S3ObjectInput[]> {
    return of(attachments).pipe(
      switchMap(attachmentsToUpload => {
        if (attachmentsToUpload && attachmentsToUpload.length > 0) {
          return this.authService.getIdentityId();
        } else {
          return of(null);
        }
      }),
      map(identityId =>
        identityId
          ? attachments.map(attachment =>
              this.s3AttachmentService.convertToS3ObjectInput(identityId, attachment)
            )
          : null
      )
    );
  }

  public createAssessmentRequest(
    input: CreateAssessmentRequestInput
  ): Observable<AssessmentRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(createAssessmentRequest),
          variables: { input: input }
        });
      }),
      map(result => result.data.createAssessmentRequest)
    );
  }

  public getAssessmentRequest(id: string): Observable<AssessmentRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.query({
          query: gql(getAssessmentRequestWithDoctor),
          variables: { id: id }
        });
      }),
      map(result => result.data['getAssessmentRequest'])
    );
  }

  public respondToAssessmentRequest(
    response: string,
    assessmentRequestId: string
  ): Observable<any> {
    const input: UpdateAssessmentRequestInput = {
      id: assessmentRequestId,
      response: response
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(updateAssessmentRequest),
          variables: { input: input }
        });
      }),
      map(result => result.data.updateAssessmentRequest)
    );
  }

  public isAssessment(value: any) {
    return (<Assessment>value).__typename && (<Assessment>value).__typename === 'Assessment';
  }

  public listAssessmentsByDateRange(dateRange: [string, string]): Observable<Assessment[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(this.listAssessmentsByClinicIdCreatedAt),
          variables: {
            assessmentClinicId: this.clinicSetupService.clinicId,
            createdAt: { between: dateRange }
          }
        })
      ),
      map(
        (assessmentQuery: ApolloQueryResult<ListAssessmentsByClinicIdCreatedAtQuery>) =>
          assessmentQuery.data.listAssessmentsByClinicIdCreatedAt.items as Assessment[]
      )
    );
  }

  groupAssessmentsByPatient(assessments: Assessment[]) {
    const patientAssessmentGroup = {};

    assessments.forEach(assessment => {
      patientAssessmentGroup[assessment.patient.id] = {
        patient: assessment.patient,
        assessments: [
          ...((patientAssessmentGroup[assessment.patient.id] &&
            patientAssessmentGroup[assessment.patient.id].assessments) ||
            []),
          assessment
        ]
      };
    });

    return patientAssessmentGroup;
  }
}

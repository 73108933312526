import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

interface SubmitTrackingFormGroup extends FormGroup {
  submitted: boolean;
}

export class ConsultFormsErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted =
      form && ((form.form && (form.form as SubmitTrackingFormGroup).submitted) || form.submitted);
    return control.invalid && (control.touched || isSubmitted);
  }
}

import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { LocationSelectComponent } from './location-select.component';
import { RegionSelectComponent } from './region-select/region-select.component';

@NgModule({
  declarations: [
    CountrySelectComponent,
    RegionSelectComponent,
    LocationSelectComponent,
    AddressAutocompleteComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedAngularMaterialModule,
    ReactiveFormsModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAgbBsjZPUoBkLLj6w7FGZKtL_-rhNuOf8',
      libraries: ['places']
    })
  ],
  exports: [
    CountrySelectComponent,
    RegionSelectComponent,
    LocationSelectComponent,
    AddressAutocompleteComponent
  ]
})
export class LocationSelectModule {}

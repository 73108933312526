import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HealthCard } from 'src/API';
import { FormFieldConfig } from 'src/app/form-template/form-template-model';

@Component({
  selector: 'csi-form-template-healthcard-field-generator',
  templateUrl: './form-template-healthcard-field-generator.component.html',
  styleUrls: ['./form-template-healthcard-field-generator.component.css']
})
export class FormTemplateHealthcardFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: HealthCard };
  @Input() control: FormGroup;
  @Input() formTemplateFieldKey: string;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  public country: string = '';
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.country = this.route.snapshot.queryParams.country || 'Canada';
  }
}

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractControlsMap } from '../../dynamic-form-group/dynamic-form-group';
import { phoneNumberValidator } from '../../shared-validators/phone-number-validator';

interface EMRIntegrationFormControls extends AbstractControlsMap {
  fax: FaxFormGroup;
}

export class FaxFormGroup extends FormGroup {
  controls: FaxFormControls;

  constructor() {
    super(
      {
        enabled: new FormControl(false),
        faxNumber: new FormControl(null, [phoneNumberValidator])
      },
      FaxFormGroup.faxGroupValidator
    );
  }

  private static faxGroupValidator = (control: FaxFormGroup) => {
    if (control) {
      if (control.controls.enabled.value) {
        return Validators.required(control.controls.faxNumber);
      }
    }
    return null;
  }
}

interface FaxFormControls extends AbstractControlsMap {
  enabled: FormControl;
  faxNumber: FormControl;
}

export class EMRIntegrationFormGroup extends FormGroup {
  controls: EMRIntegrationFormControls;

  constructor() {
    super({
      fax: new FaxFormGroup()
    });
  }

  patchValue(
    value: {
      [key: string]: any;
    },
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      defaultFax?: string;
    }
  ) {
    if (!value) {
      value = {};
    }
    if (!value.fax) {
      value.fax = {};
    }
    if (!value.fax.faxNumber) {
      value.fax.faxNumber = options.defaultFax;
    }

    super.patchValue(value, options);
  }
}

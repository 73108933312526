import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange, MatSelect, MatSelectChange } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionnaireType } from '../../../../API';
import { Patient } from '../../../core/api/patient.service';
import { PatientFormControlType } from '../../../shared/patient-form/patient-form.model';
import { CSVPatientsFormGroup } from '../import-wizard-file-received/csv-patients-form-group.model';
import { ImportWizardSendQuestionnairesService } from './import-wizard-send-questionnaires.service';

class SendMethodsFormGroup extends FormGroup {
  controls: { byPhone: FormArray; byEmail: FormArray };

  constructor(fb: FormBuilder, initialValues: boolean[]) {
    super({
      byPhone: fb.array(initialValues),
      byEmail: fb.array(initialValues)
    });
  }
}

@Component({
  selector: 'csi-import-wizard-send-questionnaires',
  templateUrl: './import-wizard-send-questionnaires.component.html',
  styleUrls: ['./import-wizard-send-questionnaires.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportWizardSendQuestionnairesComponent implements OnInit, OnDestroy {
  @Input() csvPatientsFormGroup: CSVPatientsFormGroup;
  @Output() completed: EventEmitter<null> = new EventEmitter();
  @ViewChild('matSelect', { static: false }) masterMatSelect: MatSelect;

  public patientsToSendTo: Patient[];
  public readonly columnsToDisplay = [
    PatientFormControlType.FirstName,
    PatientFormControlType.LastName,
    PatientFormControlType.Email,
    PatientFormControlType.Phone,
    PatientFormControlType.DateOfBirth,
    'questionnaireType',
    'sendToPhone',
    'sendToEmail'
  ];
  protected typesToSend: FormArray;
  protected sendMethods: SendMethodsFormGroup;
  protected sendToPhoneIndeterminate = false;
  protected sendToEmailIndeterminate = false;
  public matSpinnerOptions: MatProgressButtonOptions;
  protected readonly sendToPhoneControl = this.fb.control(true);
  protected readonly sendToEmailControl = this.fb.control(true);
  protected readonly QuestionnaireType = Object.keys(QuestionnaireType).map(
    key => QuestionnaireType[key]
  );

  constructor(
    private fb: FormBuilder,
    private importWizardSendQuestionnairesService: ImportWizardSendQuestionnairesService
  ) {}

  ngOnInit() {
    this.patientsToSendTo = this.csvPatientsFormGroup.controls.patients.value as Patient[];
    this.typesToSend = this.fb.array([
      ...this.patientsToSendTo.map(() => QuestionnaireType.DEQ as string)
    ]);
    this.sendMethods = new SendMethodsFormGroup(
      this.fb,
      this.patientsToSendTo.map(() => true)
    );

    this.matSpinnerOptions = {
      active: false,
      text:
        'Send Questionnaires to ' +
        this.numberOfQuestionnairesToSend +
        ' Patient' +
        (this.numberOfQuestionnairesToSend === 1 ? '' : 's'),
      raised: true,
      buttonColor: 'primary',
      spinnerSize: 19,
      mode: 'indeterminate'
    };

    this.sendMethods.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.matSpinnerOptions.text =
        'Send Questionnaires to ' +
        this.numberOfQuestionnairesToSend +
        ' Patient' +
        (this.numberOfQuestionnairesToSend === 1 ? '' : 's');
    });
  }

  ngOnDestroy() {}

  protected get numberOfQuestionnairesToSend(): number {
    return this.importWizardSendQuestionnairesService.questionnaireInputs(
      this.patientsToSendTo,
      this.typesToSend.value,
      this.sendMethods.controls.byEmail.value,
      this.sendMethods.controls.byPhone.value
    ).length;
  }

  protected setAllQuestionnaireTypesTo(change: MatSelectChange) {
    this.masterMatSelect.value = undefined;
    this.typesToSend.controls.forEach(control => control.setValue(change.value));
  }

  protected setAllSendMethodsTo(change: MatCheckboxChange) {
    if (change.source.name === 'phone') {
      this.sendMethods.controls.byPhone.controls.forEach(control =>
        control.setValue(change.checked)
      );
    } else if (change.source.name === 'email') {
      this.sendMethods.controls.byEmail.controls.forEach(control =>
        control.setValue(change.checked)
      );
    }
  }

  protected updateMasterCheckboxes(checkboxType: 'phone' | 'email') {
    let formValues: boolean[], checkbox: FormControl;
    if (checkboxType === 'phone') {
      formValues = this.sendMethods.controls.byPhone.value;
      checkbox = this.sendToPhoneControl;
      this.sendToPhoneIndeterminate = formValues.some(val => !!val) && formValues.some(val => !val);
    } else {
      formValues = this.sendMethods.controls.byEmail.value;
      checkbox = this.sendToEmailControl;
      this.sendToEmailIndeterminate = formValues.some(val => !!val) && formValues.some(val => !val);
    }

    if (formValues.every(val => !!val)) {
      checkbox.setValue(true);
    } else if (formValues.every(val => !val)) {
      checkbox.setValue(false);
    }
  }

  public sendQuestionnaires() {
    this.disableAllButtons();
    this.importWizardSendQuestionnairesService
      .sendQuestionnaires(
        this.patientsToSendTo,
        this.typesToSend.value,
        this.sendMethods.controls.byEmail.value,
        this.sendMethods.controls.byPhone.value
      )
      .subscribe(() => {
        this.completed.emit(null);
      });
  }

  private disableAllButtons() {
    this.matSpinnerOptions.active = true;
    this.sendMethods.disable();
    this.typesToSend.disable();
    this.sendToEmailControl.disable();
    this.sendToPhoneControl.disable();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { Layout } from '../layouts.service';

@Component({
  selector: 'csi-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.css']
})
export class CustomLayoutComponent implements OnInit {
  @Input() layout: Layout;
  @Input() isSaving: boolean;

  layoutFormGroup: FormGroup;
  private customLayoutCopy = {};

  constructor(private layoutsService: LayoutsService) {}

  ngOnInit() {
    this.customLayoutCopy = { ...this.layout.customLayout as {} };
    this.layoutFormGroup = new FormGroup(
      Object.keys(this.layout.customLayout).reduce(
        (formGroup: { [key: string]: FormControl }, key) => {
          const formControl = new FormControl();
          formGroup[key] = formControl;
          formControl.valueChanges.subscribe(value => {
            this.customLayoutCopy[key] = value;
            this.layoutsService.updateLayoutFormControlValue(
              this.layoutsService.layoutsFormGroup.controls[this.layout.id] as FormControl,
              'customLayout',
              JSON.stringify({
                ...(this.customLayoutCopy as {}),
                [key]: value
              })
            );
          });
          return formGroup;
        },
        {}
      )
    );
  }

  noSort() {
    return 0;
  }
}

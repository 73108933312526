import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemperatureUnit } from 'src/API';
import { Weather } from 'src/app/econsult/assessment-body/assessment-body.model';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { StaffService } from './../../../../core/api/staff.service';
import { WeatherFormGroup } from './weather-form.model';

@Component({
  selector: 'csi-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  @Input() formGroup: WeatherFormGroup;
  @Input() isNewAssessment: boolean;
  public disableSync: boolean;

  constructor(
    private http: HttpClient,
    public staffService: StaffService,
    private route: ActivatedRoute,
    private clinicSetupService: ClinicSetupService
  ) {}

  public temperatureUnit = '°C';
  public apiPath = `https://api.openweathermap.org/data/2.5/weather?q=${this.staffService.staff.clinic.city},${this.staffService.staff.clinic.country}&appid=52a55a24b33d989a7d20f82dba427381&units=metric`;
  private currentTemperatureUnit: TemperatureUnit = TemperatureUnit.celsius;
  private clinicSetupTemperatureUnit: TemperatureUnit = this.clinicSetupService
    .clinicSetupTemperatureUnit;

  ngOnInit() {
    this.setTemperatureUnit();
    if (this.route.snapshot.routeConfig.path === 'new') {
      this.fetchAndDisplayNewTemperature();
    } else {
      this.displayTemperature();
    }

    this.formGroup.valueChanges.subscribe(() => {
      const tempValue = this.formGroup.controls.temp.value;
      const humidityValue = this.formGroup.controls.humidity.value;

      this.disableSync = !!(tempValue || humidityValue);
    });
  }

  private setTemperatureUnit() {
    if (this.clinicSetupTemperatureUnit === TemperatureUnit.fahrenheit) {
      this.temperatureUnit = '°F';
    } else {
      this.temperatureUnit = '°C';
    }
    this.currentTemperatureUnit =
      this.formGroup.controls.tempUnit && this.formGroup.controls.tempUnit.value
        ? this.formGroup.controls.tempUnit.value
        : TemperatureUnit.celsius;

    this.formGroup.controls.temp.setValue(
      this.formGroup.controls.temp.value
        ? (Number.parseFloat(this.formGroup.controls.temp.value) || 0).toFixed(2)
        : ''
    );
  }

  private fetchAndDisplayNewTemperature() {
    this.http.get(this.apiPath).subscribe(({ main }: { [key: string]: Weather }) => {
      let temperature = main.temp;
      if (this.clinicSetupTemperatureUnit === TemperatureUnit.fahrenheit) {
        temperature = this.convertCelsiusToFahrenheit(temperature);
      }
      this.formGroup.patchValue({
        temp: temperature,
        tempUnit: this.clinicSetupTemperatureUnit,
        humidity: main.humidity
      });
      this.clinicSetupService.temperatureInCelsius = main.temp;
    });
  }

  private displayTemperature() {
    setTimeout(() => {
      setTimeout(() => {
        this.updateTemperatureUnit();
      });
    });
  }

  private updateTemperatureUnit() {
    let temperature = this.formGroup.controls.temp.value;
    const desiredTemperatureUnit = this.clinicSetupService.clinicSetupTemperatureUnit;

    if (this.currentTemperatureUnit !== desiredTemperatureUnit) {
      if (desiredTemperatureUnit === TemperatureUnit.fahrenheit) {
        temperature = this.convertCelsiusToFahrenheit(temperature);
      } else {
        temperature = this.convertFahrenheitToCelsius(temperature);
      }
      this.formGroup.patchValue({ temp: temperature, tempUnit: desiredTemperatureUnit });
      this.currentTemperatureUnit = desiredTemperatureUnit;
    }

    this.setTemperatureUnit();
  }

  private convertCelsiusToFahrenheit(celsius: number): number {
    if (!celsius) return null;

    const fahrenheit = (celsius * 9) / 5 + 32;
    return parseFloat(fahrenheit.toFixed(2));
  }

  private convertFahrenheitToCelsius(fahrenheit: number): number {
    if (!fahrenheit) return null;

    const celsius = ((fahrenheit - 32) * 5) / 9;
    return parseFloat(celsius.toFixed(2));
  }

  public onSyncWeather() {
    this.fetchAndDisplayNewTemperature();
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Interaction } from 'src/API';

interface interactionsSortedByCategories {
  communication: {
    failedCommunications: number;
    interactions: Interaction[];
  };
  surgery: {
      lastSurgeryBookedDate: string;
      nextSurgeryBookedDate: string;
      lastSurgeryType: string;
      nextSurgeryType: string;
      lastSurgeryStatus: any;
      interactions: Interaction[];
    };

};
@Component({
  selector: 'csi-interaction-expansion-panel[category]',
  templateUrl: './interaction-expansion-panel.component.html',
  styleUrls: ['./interaction-expansion-panel.component.css']
})
export class InteractionExpansionPanelComponent implements OnInit, OnChanges {
  @Input() interactionByCategory: interactionsSortedByCategories;
  @Input() category: string;
  @Input() recentCommunicationMethod: string;
  @Input() numberOfTimeContacted: string;

  public interactionKeys: string[];



  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.getNumberOfTimesContacted();
  }

  ngOnInit() {
    this.getNumberOfTimesContacted();
  }

  getMatIcon(isFilled) {
    return `<span class="material-life-icon">${isFilled ? 'favorite' : 'favorite_border'}</span>`;
  }
  getNumberOfTimesContacted() {
    const failedAttempt = Math.min(
      this.interactionByCategory.communication.failedCommunications,
      3
    );
    const filledHearts = this.getMatIcon(true).repeat(3 - failedAttempt);
    const emptyHearts = failedAttempt > 0 ? this.getMatIcon(false).repeat(failedAttempt) : '';
    this.numberOfTimeContacted = filledHearts + emptyHearts;


  }

}

import { QuestionnaireScoringConfig } from './custom-questionnaire-scoring.model';

export const deqQuestionnaireScoringConfig: QuestionnaireScoringConfig = {
  questionnaireName: 'DEQ',
  scoringCalculator: (formGroup: JSON, questionnaireKeys: string[]) => {
    let score = 0;
    questionnaireKeys.forEach(key => {
      score += formGroup[key];
    });
    return score;
  },
  questionnaireKeys: [
    'eyeDiscomfortFrequency',
    'eyeDiscomfortIntensity',
    'eyeDrynessFrequency',
    'eyeDrynessIntensity',
    'wateryEyes'
  ]
};

export const speedQuestionnaireScoringConfig: QuestionnaireScoringConfig = {
  questionnaireName: 'SPEED',
  scoringCalculator: (formGroup: JSON, questionnaireKeys: string[]) => {
    let score = 0;
    questionnaireKeys.forEach(key => {
      score += formGroup[key];
    });
    return score;
  },
  questionnaireKeys: [
    'drynessGrittinessScratchinessFrequency',
    'sorenessIrritationFrequency',
    'burningWateringFrequency',
    'eyeFatigueFrequency',
    'drynessGrittinessScratchinessSeverity',
    'sorenessIrritationSeverity',
    'burningWateringSeverity',
    'eyeFatigueSeverity'
  ]
};

export const osdiQuestionnaireScoringConfig: QuestionnaireScoringConfig = {
  questionnaireName: 'OSDI',
  scoringCalculator: (formGroup: JSON, questionnaireKeys: string[]) => {
    let score = 0;
    questionnaireKeys.forEach(key => {
      score += formGroup[key];
    });
    return score;
  },
  questionnaireKeys: [
    'lightSensitivity',
    'grittyFeeling',
    'painOrSoreness',
    'blurredVision',
    'poorVision',
    'reading',
    'drivingAtNight',
    'workingWithScreens',
    'watchingTV',
    'windyConditions',
    'lowHumidity',
    'airConditioned'
  ]
};

export const fitzpatrickQuestionnaireScoringConfig: QuestionnaireScoringConfig = {
  questionnaireName: 'FITZPATRICK',
  scoringCalculator: (formGroup: JSON, questionnaireKeys: string[]) => {
    let score = 0;
    questionnaireKeys.forEach(key => {
      score += fitzpatrickKeyToScoreMap[formGroup[key]];
    });
    return score;
  },
  questionnaireKeys: [
    'eyeColor',
    'naturalHairColor',
    'skinColor',
    'freckles',
    'longSunExposure',
    'darkerAfterSunExposure',
    'tanIntensity',
    'faceSunSensitivity',
    'sunTanExposureFrequency',
    'lastSunExposure'
  ]
};

export const fitzpatrickKeyToScoreMap = {
  hairColorRedhead: 0,
  hairColorBlond: 1,
  hairColorBrownDarkBlond: 2,
  hairColorDarkBrowns: 3,
  hairColorBlack: 4,

  pink: 0,
  veryPale: 1,
  paleBrownOlive: 2,
  brown: 3,
  darkBrunette: 4,

  bluesGreensPaleGrays: 0,
  bluesGreensGrays: 1,
  bluesGreensDarkGraysPers: 2,
  darkBrowns: 3,
  brownishBlacks: 4,

  aLot: 0,
  several: 1,
  some: 2,
  veryLittle: 3,
  none: 4,

  severeBurns: 0,
  moderateBurns: 1,
  sometimesBurning: 2,
  rareBurns: 3,
  noBurns: 4,

  never: 0,
  rarely: 1,
  sometimes: 2,
  often: 3,
  always: 4,

  slightNone: 0,
  lightTan: 1,
  mediumTan: 2,
  darkTan: 3,
  veryDarkTan: 4,

  verySensitive: 0,
  sensitive: 1,
  moderatelySensitive: 2,
  resistant: 3,
  veryResistant: 4,

  neverTan: 0,
  rarelyTan: 1,
  sometimesTan: 2,
  oftenTan: 3,
  alwaysTan: 4,

  moreThanThreeMonths: 0,
  lastTwoToThreeMonths: 1,
  lastOneToTwoMonths: 2,
  lastWeek: 3,
  lastDay: 4
};

export const allQuestionnairesScoringConfig: QuestionnaireScoringConfig[] = [
  deqQuestionnaireScoringConfig,
  speedQuestionnaireScoringConfig,
  osdiQuestionnaireScoringConfig,
  fitzpatrickQuestionnaireScoringConfig
];

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PatientList } from 'src/app/core/api/patient.service';
import { maxAWSItemLimit } from 'src/graphql/custom_queries';
import { ClinicService } from '../../core/api/clinic.service';
import { StaffService } from '../../core/api/staff.service';

@Injectable()
export class PatientsPaginatorService {
  private nextToken: string;
  private previousNextTokens: string[] = [];
  private pageSize: number;

  private _totalPatientsLoaded = 0;

  public get totalPatientsLoaded(): number {
    return this._totalPatientsLoaded;
  }
  public get hasMorePatientsToLoad(): boolean {
    return this.previousNextTokens[this.previousNextTokens.length - 1] != null;
  }

  constructor(private clinicService: ClinicService, private staffService: StaffService) {}

  public
  setPageSize(itemsPerPage: number) {
    this.pageSize = itemsPerPage;
  }

  public loadNextPatients(currentPage: number, loadAssessments = true): Observable<PatientList> {
    this.setNextTokenAtPage(currentPage);

    return this.staffService.getLoggedInStaff().pipe(
      switchMap(loggedInDoctor =>
        this.clinicService.getPatientsForClinic(
          loggedInDoctor.clinic.id,
          false,
          loadAssessments ? maxAWSItemLimit : 0,
          'network-only',
          this.nextToken,
          this.pageSize
        )
      ),
      tap((patients: PatientList) => {
        this.nextToken = patients.nextToken;
        this.tryInsertingNextToken(currentPage, this.nextToken, patients.items.length);
      })
    );
  }

  private tryInsertingNextToken(currentPage: number, nextToken: string, newPatientsLoaded: number) {
    if (this.previousNextTokens.length === currentPage) {
      this.previousNextTokens.push(nextToken);
      this._totalPatientsLoaded += newPatientsLoaded;
    }
  }

  private   setNextTokenAtPage(desiredPage: number) {
    if (this.previousNextTokens.length >= desiredPage) {
      if (desiredPage === 0) {
        this.nextToken = undefined;
      } else {
        this.nextToken = this.previousNextTokens[desiredPage - 1];
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { HealthCard } from '../../core/api/patient.service';

@Pipe({
  name: 'healthCard'
})
export class HealthCardPipe implements PipeTransform {
  transform(value: HealthCard, args?: any): string {
    if (value && value.number) {
      return value.province ? `${value.province} ${value.number}` : `${value.number}`;
    }
    return null;
  }
}

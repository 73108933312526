import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
import { defer, iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { ClinicService } from '../../core/api/clinic.service';
import { StaffService } from '../../core/api/staff.service';
import { LoggedInUserResolver } from '../../core/route-resolvers/logged-in-user.resolver';
import { DoctorAndClinicInfoWizardModalComponent } from '../../shared/consult-forms/doctor-and-clinic-info-wizard-modal/doctor-and-clinic-info-wizard-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MissingDoctorAndClinicInfoGuard implements CanActivate, CanActivateChild {
  constructor(
    private dialog: MatDialog,
    private loggedInUserResolver: LoggedInUserResolver,
    private staffService: StaffService,
    private clinicService: ClinicService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return defer(() => {
      const loggedInUser$: Observable<Doctor> = this.loggedInUserResolver.resolve(next, state);
      return loggedInUser$.pipe(
        switchMap(loggedInUser => {
          return iif(
            () => this.isUserMissingDoctorOrClinicInformation(loggedInUser, state),
            defer(() => this.collectMissingDoctorAndClinicInfoFromUser(loggedInUser, false)),
            of(true)
          );
        })
      );
    });
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  private collectMissingDoctorAndClinicInfoFromUser(
    user: Doctor,
    allowDismissable: boolean
  ): Observable<boolean> {
    const refreshPage = !user.clinic;
    const modalRef = DoctorAndClinicInfoWizardModalComponent.open(this.dialog, user, {
      disableClose: !allowDismissable
    });
    // Refresh to reset the token on clinic profile creation
    return modalRef.afterClosed().pipe(
      tap(() => {
        if (refreshPage) {
          window.location.reload();
        }
      })
    );
  }

  private isUserMissingDoctorOrClinicInformation(user: Doctor, state): boolean {
    return (
      this.staffService.isDoctorMissingInformation(user) ||
      this.clinicService.isClinicMissingInformation(user.clinic) ||
      (!this.staffService.isProfileAccessComplete(user) && !this.isLinkingRoute(state.url))
    );
  }

  // If user is trying to link to a clinic, they should be allowed to access this route
  private isLinkingRoute(url: string) {
    const splitUrl = url.split('/');
    return splitUrl[1] === 'refer' && splitUrl[2] === 'link';
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Drug, DrugActiveIngredients } from 'src/API';
import { RemoteJsonFileService } from 'src/app/core/remote-json/remote-json-file.service';

interface SideEffectIngredients {
  dryEyes: ActiveIngredientList;
  glaucoma: ActiveIngredientList;
  maculopathy: ActiveIngredientList;
  opticNeuritis: ActiveIngredientList;
}

export interface SideEffects {
  dryEyes: boolean;
  glaucoma: boolean;
  maculopathy: boolean;
  opticNeuritis: boolean;
}

type ActiveIngredientList = {
  name: string;
  health_canada_report_number?: string;
  active_ingredient_code?: number;
  drug_brand_name?: string;
  drugbank_id?: string;
}[];

@Injectable({
  providedIn: 'root'
})
export class SideEffectFlaggingService extends RemoteJsonFileService {
  private activeIngredientCodesThatCanCauseDryEyes: Set<number> = new Set<number>();
  private activeIngredientNamesThatCanCauseDryEyes: Set<string> = new Set<string>();
  private activeIngredientNamesThatCanCauseGlaucoma: Set<string> = new Set<string>();
  private activeIngredientNamesThatCanCauseMaculopathy: Set<string> = new Set<string>();
  private activeIngredientNamesThatCanCauseOpticNeuritis: Set<string> = new Set<string>();

  constructor(httpClient: HttpClient) {
    super(httpClient, 'assets/bad-ingredients.json');
    this.loadBadIngredients().subscribe();
  }

  public getSideEffects(drug: Drug): Observable<SideEffects> {
    return this.loadBadIngredients().pipe(
      map(wasProperlyLoaded => {
        const sideEffects = {
          dryEyes: false,
          glaucoma: false,
          maculopathy: false,
          opticNeuritis: false
        };
        if (!wasProperlyLoaded) {
          console.error('Error, failed to load bad ingredients.');
        } else {
          sideEffects.dryEyes = drug.active_ingredients.some(ingredient =>
            this.canActiveIngredientCauseDryEyes(ingredient)
          );
          sideEffects.glaucoma = drug.active_ingredients.some(ingredient =>
            this.canActiveIngredientCauseGlaucoma(ingredient)
          );
          sideEffects.maculopathy = drug.active_ingredients.some(ingredient =>
            this.canActiveIngredientCauseMaculopathy(ingredient)
          );
          sideEffects.opticNeuritis = drug.active_ingredients.some(ingredient =>
            this.canActiveIngredientCauseOpticNeuritis(ingredient)
          );
        }
        return sideEffects;
      })
    );
  }

  private loadBadIngredients(): Observable<boolean> {
    return this.load<SideEffectIngredients>(data => this.setActiveIngredients(data));
  }

  private setActiveIngredients(data: SideEffectIngredients) {
    data.dryEyes.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseDryEyes.add(ingredient.name.toLowerCase());
      if (ingredient.active_ingredient_code) {
        this.activeIngredientCodesThatCanCauseDryEyes.add(ingredient.active_ingredient_code);
      }
    });

    data.glaucoma.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseGlaucoma.add(ingredient.name.toLowerCase());
    });

    data.maculopathy.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseMaculopathy.add(ingredient.name.toLowerCase());
    });

    data.maculopathy.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseMaculopathy.add(ingredient.name.toLowerCase());
    });

    data.opticNeuritis.forEach(ingredient => {
      this.activeIngredientNamesThatCanCauseOpticNeuritis.add(ingredient.name.toLowerCase());
    });
  }

  private canActiveIngredientCauseDryEyes(activeIngredient: DrugActiveIngredients): boolean {
    return this.activeIngredientNamesThatCanCauseDryEyes.has(
      activeIngredient.ingredient.toLowerCase()
    );
  }

  private canActiveIngredientCauseGlaucoma(activeIngredient: DrugActiveIngredients): boolean {
    return this.activeIngredientNamesThatCanCauseGlaucoma.has(
      activeIngredient.ingredient.toLowerCase()
    );
  }

  private canActiveIngredientCauseMaculopathy(activeIngredient: DrugActiveIngredients): boolean {
    return this.activeIngredientNamesThatCanCauseMaculopathy.has(
      activeIngredient.ingredient.toLowerCase()
    );
  }

  private canActiveIngredientCauseOpticNeuritis(activeIngredient: DrugActiveIngredients): boolean {
    return this.activeIngredientNamesThatCanCauseOpticNeuritis.has(
      activeIngredient.ingredient.toLowerCase()
    );
  }
}

import { Injectable } from '@angular/core';
import { UserIdleConfig, UserIdleService } from 'angular-user-idle';
import { AmplifyService } from 'aws-amplify-angular';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { LogoutService } from './logout/logout.service';
export const defaultTimeoutConfig: UserIdleConfig = { idle: 1800, timeout: 300, ping: 0 };

@Injectable({
  providedIn: 'root'
})
export class CsiUserIdleService {
  private idleTimeSubscription: Subscription;

  constructor(
    private logoutService: LogoutService,
    private userIdle: UserIdleService,
    private confirmModalService: ConfirmModalService,
    private amplifyService: AmplifyService,
    private cookieService: CookieService
  ) {}

  public startIdleListener(): void {
    if (!this.idleTimeSubscription) {
      this.idleTimeSubscription = this.amplifyService.authStateChange$.subscribe(authState => {
        authState.state === 'signedIn' ? this.startWatching() : this.stopTimer();
      });
    }

    this.userIdle.onIdleStatusChanged().subscribe(idle => {
      if (
        !!this.cookieService.get('user-idle-timeout') &&
        parseInt(this.cookieService.get('user-idle-timeout'), 10) < Date.now()
      ) {
        this.logout();
      } else if (idle) {
        const timeOut = Date.now() + 2100000;
        this.cookieService.set('user-idle-timeout', timeOut.toString(), null, '/');
      }
    });

    this.userIdle.onTimerStart().subscribe(count => {
      if (count === 1) {
        this.showSessionExpiryWarning();
      }
    });
    this.userIdle.onTimeout().subscribe(() => this.logout());
  }

  public showSessionExpiryWarning() {
    this.confirmModalService
      .show(
        'Your session is about to expire.',
        'Would you like to continue your session?',
        'Continue Session',
        'Logout'
      )
      .subscribe((result: boolean) => {
        !!result ? this.resetTimer() : this.logout();
      });
  }

  private startWatching() {
    this.cookieService.delete('user-idle-timeout');
    this.userIdle.startWatching();
  }

  private stopTimer() {
    this.userIdle.stopTimer();
  }

  private resetTimer() {
    this.userIdle.resetTimer();
  }

  private logout() {
    this.cookieService.delete('user-idle-timeout');
    this.logoutService.logout();
  }
}

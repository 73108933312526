import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalSymptomCustomModalOptions } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'csi-additional-fluorescein-grading-modal',
  templateUrl: './additional-fluorescein-grading-modal.component.html',
  styleUrls: ['./additional-fluorescein-grading-modal.component.scss']
})
export class AdditionalFluoresceinGradingModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit {
  public internalFormGroup = new FormGroup({
    patches: new FormControl(),
    pupillary: new FormControl(),
    filaments: new FormControl()
  });

  constructor(
    public dialogRef: MatDialogRef<AdditionalFluoresceinGradingModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    (this.hostElement.nativeElement as HTMLElement).parentElement.style.top = '-20px';

    const value = this.formGroup.get(this.formKey).value;
    if (value) {
      Object.keys(value).forEach(key => {
        this.internalFormGroup.get(key).setValue(value[key]);
      });
    }
    if (this.isDisabled) {
      this.internalFormGroup.disable();
    }
  }

  submit() {
    this.formGroup.get(this.formKey).setValue(
      Object.keys(this.internalFormGroup.controls).reduce((value, controlName) => {
        value[controlName] = this.internalFormGroup.get(controlName).value === true;
        return value;
      }, {})
    );

    this.dialogRef.close();
  }

  public simulateClick(event: KeyboardEvent) {
    (event.target as HTMLElement).click();
  }
}

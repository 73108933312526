import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { FieldStatus } from './../../../API';
import { ClinicSetupService } from './../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { preOperativeKeratometryKeys } from './../consult-forms/dry-eye-forms/pre-op/pre-op.model';
import { SchemaService } from './../symptoms/services/schema/schema.service';

@Component({
  selector: 'csi-show-hide-drag-drop',
  templateUrl: './show-hide-drag-drop.component.html',
  styleUrls: ['./show-hide-drag-drop.component.scss']
})
export class ShowHideDragDropComponent implements OnInit {
  @Input() isSaving: boolean;
  @Input() layout: FieldStatus;
  @Input() layoutFormControl: FormControl;

  public friendlyNameMap: { [key: string]: string } = {
    ...Object.keys(this.schemaService.symptomMap).reduce((friendlyNameMap, key) => {
      return { ...friendlyNameMap, [key]: this.schemaService.symptomMap[key].name };
    }, {}),
    ...Object.entries(preOperativeKeratometryKeys).reduce((friendlyNameMap, [key, name]) => {
      return { ...friendlyNameMap, [key]: name };
    }, {})
  };

  public isClinicAdmin: boolean;

  // Signs have been named as symptoms

  // Needed for html disabled check
  public manualTrigger: number;
  public touchEnabled = false;

  hideKeys: string[] = [];
  showKeys: string[] = [];

  constructor(
    public clinicSetupService: ClinicSetupService,
    private layoutsService: LayoutsService,
    private schemaService: SchemaService
  ) {}

  ngOnInit() {
    this.layout = JSON.parse(JSON.stringify(this.layout));
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;

    this.showKeys = this.layout.show;
    this.hideKeys = this.layout.hide;

    this.touchEnabled = this.layoutsService.isTouchEnabled();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.setFormControl();
  }

  setVisibility(previousIndex, action: 'show' | 'hide') {
    const [previousArray, currentArray] =
      action === 'hide' ? [this.showKeys, this.hideKeys] : [this.hideKeys, this.showKeys];
    transferArrayItem(previousArray, currentArray, previousIndex, currentArray.length);
    this.manualTrigger = Math.random();
    this.setFormControl();
  }

  reorder(previousIndex, direction: 'up' | 'down') {
    moveItemInArray(
      this.showKeys,
      previousIndex,
      direction === 'up' ? previousIndex - 1 : previousIndex + 1
    );
    this.setFormControl();
  }

  setFormControl() {
    this.layoutFormControl.setValue({
      ...this.layout,
      show: this.showKeys,
      hide: this.hideKeys
    });
  }
}

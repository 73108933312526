import { FactoryProvider, InjectionToken } from '@angular/core';

export const enum DomainOption {
  CSI,
  Seema,
  Other
}
export const HOSTING_DOMAIN_ACCESSOR = new InjectionToken('HOSTING_DOMAIN');
export const SUPPORT_EMAIL_ACCESSOR = new InjectionToken('SUPPORT_EMAIL');

export function domainFactoryProvider(): DomainOption {
  return window.document.location.hostname === 'econsults.seemaeye.com'
    ? DomainOption.Seema
    : window.document.location.hostname === 'www.csidryeye.com'
    ? DomainOption.CSI
    : DomainOption.Other;
}

export function supportEmailFactoryProvider(): string {
  return domainFactoryProvider() === DomainOption.Seema
    ? 'itsupport@seemaeye.com'
    : 'support@csidryeye.com';
}

export const HostingDomainProvider: FactoryProvider = {
  provide: HOSTING_DOMAIN_ACCESSOR,
  useFactory: domainFactoryProvider
};

export const SupportEmailProvider: FactoryProvider = {
  provide: SUPPORT_EMAIL_ACCESSOR,
  useFactory: supportEmailFactoryProvider
};

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

export abstract class RemoteJsonFileService {
  protected isJsonLoaded$: Observable<boolean>;

  constructor(protected httpClient: HttpClient, protected remoteJsonPath: string) {}

  protected load<T>(onLoad: (data: T) => void): Observable<boolean> {
    if (!this.isJsonLoaded$) {
      this.isJsonLoaded$ = this.get<T>().pipe(
        map(data => {
          onLoad(data);
          return true;
        }),
        catchError(err => {
          console.log(`Error loading remote json file ${this.remoteJsonPath}`);
          console.error(err);
          return of(false);
        }),
        shareReplay()
      );
    }
    return this.isJsonLoaded$;
  }

  private get<T>(): Observable<T> {
    return this.httpClient.get<T>(this.remoteJsonPath);
  }
}

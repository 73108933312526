import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Logger } from 'aws-amplify';

interface DryEyeMedicalHistoryFormControls {
  lastEyeExam: FormControl;
  historyOfPresentingIllness: FormControl;
  medications: FormControl;
  autoImmuneConditions: FormControl;
  changesToEyes: FormControl;
  drugAllergies: FormControl;
  seasonalAllergies: FormControl;
  eyeItchiness: FormControl;
  eyeTearingQuantity: FormControl;
  symptomFrequency: FormControl;
  symptomSeverity: FormControl;
  dietQuality: FormControl;
  healthQuality: FormControl;
  stressLevel: FormControl;
  cityHumidityLevel: FormControl;
  cityPollutionLevel: FormControl;
  sensitivityToLight: FormControl;
  sensitivityToWind: FormControl;
  impactOfSymptomsOnMood: FormControl;
  impactOfSymptomsOnLife: FormControl;
  otherMedications: FormControl;
  exercise: FormControl;
  symptomDuration: FormControl;
  otherConditionsFreeText: FormControl;
  pastDryEyeTreatmentsFreeText: FormControl;
  thyroidConditionFreeText: FormControl;
  chronicPainConditionFreeText: FormControl;
  vitaminDeficiencyConditionFreeText: FormControl;
  sexSteroidDeficiencyConditionFreeText: FormControl;
  previousOcularSurgeryFreeText: FormControl;
  psychiatricConditionsFreeText: FormControl;
  currentOcularMedicationsFreeText: FormControl;
  pastOcularMedicationsFreeText: FormControl;
  patientEnteredMedicationsFreeText: FormControl;
  workHoursPerDayFreeText: FormControl;
  dietFreeText: FormControl;
  caffeinatedDrinksPerDayFreeText: FormControl;
  waterPerDayFreeText: FormControl;
  urbanAreaTimePerWeekFreeText: FormControl;
  timeSpentOutdoorsFreeText: FormControl;
  timeSpentClosedEnvironmentsFreeText: FormControl;
  faceCareAndMakeUpProductsFreeText: FormControl;
}

const defaultSliderValue = 0;

export const ITCHINESS_FIELD = 'eyeItchiness';

export class DryEyeMedicalHistoryFormGroup extends FormGroup {
  controls: DryEyeMedicalHistoryFormControls & { [key: string]: AbstractControl };
  submitted = false;
  _optional = false;
  defaultValidators: { [controlKey: string]: ValidatorFn };
  logger: Logger = new Logger('DryEyeMedicalHistoryFormGroup');

  constructor() {
    super({
      lastEyeExam: new FormControl(),
      historyOfPresentingIllness: new FormControl('', Validators.required),
      medications: new FormControl([], Validators.required),
      currentOcularMedicationsFreeText: new FormControl(),
      pastOcularMedicationsFreeText: new FormControl(),
      patientEnteredMedicationsFreeText: new FormControl('', Validators.required),
      workHoursPerDayFreeText: new FormControl(),
      autoImmuneConditions: new FormControl('', Validators.required),
      changesToEyes: new FormControl('', Validators.required),
      drugAllergies: new FormControl('', Validators.required),
      seasonalAllergies: new FormControl('', Validators.required),
      eyeItchiness: new FormControl(defaultSliderValue),
      eyeTearingQuantity: new FormControl(defaultSliderValue),
      symptomFrequency: new FormControl(defaultSliderValue),
      symptomSeverity: new FormControl(defaultSliderValue),
      dietQuality: new FormControl(),
      healthQuality: new FormControl(),
      stressLevel: new FormControl(),
      cityHumidityLevel: new FormControl(),
      cityPollutionLevel: new FormControl(),
      sensitivityToLight: new FormControl(),
      sensitivityToWind: new FormControl(),
      impactOfSymptomsOnMood: new FormControl(),
      impactOfSymptomsOnLife: new FormControl(),
      exercise: new FormControl(null, [Validators.max(99), Validators.min(0)]),
      otherMedications: new FormControl(),
      symptomDuration: new FormControl(),
      sleepPerNight: new FormControl(null, [Validators.max(24), Validators.min(0)]),
      triggeringEvent: new FormControl(),
      otherConditionsFreeText: new FormControl(),
      pastDryEyeTreatmentsFreeText: new FormControl(),
      medicalConditionsFreeText: new FormControl(),
      medicalConditions: new FormControl(true),
      environmentalSymptoms: new FormControl(true),
      otherConditions: new FormControl(true),
      thyroidConditionFreeText: new FormControl(),
      chronicPainConditionFreeText: new FormControl(),
      vitaminDeficiencyConditionFreeText: new FormControl(),
      sexSteroidDeficiencyConditionFreeText: new FormControl(),
      previousOcularSurgeryFreeText: new FormControl(),
      psychiatricConditionsFreeText: new FormControl(),
      dietFreeText: new FormControl(),
      caffeinatedDrinksPerDayFreeText: new FormControl(),
      waterPerDayFreeText: new FormControl(),
      urbanAreaTimePerWeekFreeText: new FormControl(),
      timeSpentOutdoorsFreeText: new FormControl(),
      timeSpentClosedEnvironmentsFreeText: new FormControl(),
      faceCareAndMakeUpProductsFreeText: new FormControl()
    });

    console.assert(
      this.controls[ITCHINESS_FIELD],
      `'${ITCHINESS_FIELD}' is not defined. Either redefine ITCHINESS_FIELD or add '${ITCHINESS_FIELD}' back.`
    );
  }

  reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    if (!value) {
      value = {};
    }
    value.eyeItchiness = value.eyeItchiness || defaultSliderValue;
    value.eyeTearingQuantity = value.eyeTearingQuantity || defaultSliderValue;
    super.reset(value, options);
  }

  set optional(value: boolean) {
    if (this._optional !== value) {
      if (!this._optional) {
        this.logger.debug('Setting form group to optional');
        this.takeValidatorsSnapshot();
        this.removeValidators();
      } else {
        this.logger.debug('Setting form group to required');
        this.restoreValidators();
      }
      this._optional = value;

      // Remove when https://github.com/angular/angular/pull/19829 is merged
      this.updateValueAndValidityForSelfAndChildren();
    }
  }

  get optional(): boolean {
    return this._optional;
  }

  private takeValidatorsSnapshot(): void {
    this.defaultValidators = {};
    Object.keys(this.controls).forEach(controlKey => {
      this.defaultValidators[controlKey] = this.controls[controlKey].validator;
    });
  }

  private removeValidators(): void {
    Object.keys(this.controls).forEach(controlKey => this.controls[controlKey].setValidators(null));
  }

  private restoreValidators(): void {
    Object.keys(this.controls).forEach(controlKey =>
      this.controls[controlKey].setValidators(this.defaultValidators[controlKey])
    );
  }

  private updateValueAndValidityForSelfAndChildren() {
    Object.keys(this.controls).forEach(controlKey =>
      this.controls[controlKey].updateValueAndValidity()
    );
    this.updateValueAndValidity();
  }
}

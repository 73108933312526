import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClinicSetupService } from '../../clinic-setup.service';
import { Layout } from './../../../../../API';
import { LayoutsService } from './../layouts.service';

@Component({
  selector: 'csi-medical-history-layout',
  templateUrl: './medical-history-layout.component.html',
  styleUrls: ['./medical-history-layout.component.scss']
})
export class MedicalHistoryLayoutComponent implements OnInit {
  @Input() medicalHistoryLayoutFormControl: FormControl;
  @Input() isSaving: boolean;
  @Input() layout: Layout;
  @Input() layoutSetting: string;

  public isClinicAdmin: boolean;
  public touchEnabled = false;

  hideMedicalHistoryKeys: string[] = [];
  showMedicalHistoryKeys: string[] = [];

  constructor(
    public layoutsService: LayoutsService,
    public clinicSetupService: ClinicSetupService
  ) {}

  ngOnInit() {
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;

    this.layout = { ...this.layout };
    this.medicalHistoryLayoutFormControl.setValue(this.layout);
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;

    this.showMedicalHistoryKeys = [...this.layout[this.layoutSetting].show];
    this.hideMedicalHistoryKeys = Object.keys(this.layoutsService.medicalHistoryFields).filter(
      symptomKey => !this.showMedicalHistoryKeys.includes(symptomKey)
    );

    this.touchEnabled = this.layoutsService.isTouchEnabled();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.setFormControl();
  }

  setVisibility(previousIndex, action: 'show' | 'hide') {
    const [previousArray, currentArray] =
      action === 'hide'
        ? [this.showMedicalHistoryKeys, this.hideMedicalHistoryKeys]
        : [this.hideMedicalHistoryKeys, this.showMedicalHistoryKeys];
    transferArrayItem(previousArray, currentArray, previousIndex, currentArray.length);

    this.setFormControl();
  }

  reorder(previousIndex, direction: 'up' | 'down') {
    moveItemInArray(
      this.showMedicalHistoryKeys,
      previousIndex,
      direction === 'up' ? previousIndex - 1 : previousIndex + 1
    );
    this.setFormControl();
  }

  setFormControl() {
    this.layoutsService.updateLayoutFormControlValue(
      this.medicalHistoryLayoutFormControl,
      this.layoutSetting,
      {
        show: this.showMedicalHistoryKeys,
        hide: this.hideMedicalHistoryKeys
      }
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Patient } from 'src/app/core/api/patient.service';
import { IntakeFormService } from 'src/app/intake-form/intake-form.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { SearchablePatient } from '../intake-form-view/intake-form-view.component';

@Component({
  selector: 'csi-link-with-similar-patient-modal',
  templateUrl: './link-with-similar-patient-modal.component.html',
  styleUrls: ['./link-with-similar-patient-modal.component.css']
})
export class LinkWithSimilarPatientModalComponent implements OnInit {
  public displayColumns: string[];
  public columnNamesMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    action: ''
  };

  public linkedPatientId = this.intakeFormService.currentIntakeForm.intakeFormPatientId;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientList: SearchablePatient[];
      intakePatient: Partial<Patient>;
    },
    private router: Router,
    private intakeFormService: IntakeFormService,
    public dialogRef: MatDialogRef<LinkWithSimilarPatientModalComponent>,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.displayColumns = Object.keys(this.columnNamesMap);
  }

  public static openLinkWithSimilarPatientModel(
    matDialog: MatDialog,
    patientList?: Partial<SearchablePatient>[],
    intakePatient?: Partial<Patient>
  ) {
    return matDialog.open(LinkWithSimilarPatientModalComponent, {
      width: '80vw',
      minWidth: '54rem',
      maxWidth: '95vw',
      data: { patientList, intakePatient }
    });
  }

  public navigateToPatient() {
    this.router.navigate([
      `/patients/${this.intakeFormService.currentIntakeForm.intakeFormPatientId}`
    ]);
    this.dialogRef.close();
  }

  linkIntakeFormWithPatient(patientToLink: Patient) {
    this.confirmModalService
      .show(
        `${patientToLink.firstName} ` + `${patientToLink.lastName}`,
        `You are about to link to ${patientToLink.firstName} ` +
          `${patientToLink.lastName}. Do you wish to continue? Once linked, this action cannot be undone`,
        'Link',
        'Cancel'
      )
      .subscribe(linkConfirmed => {
        this.dialogRef.close(linkConfirmed ? patientToLink : null);
      });
  }
}

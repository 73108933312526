import {
  BuckitizedThermalScaleConfig,
  LinearThermalScaleConfig
} from './thermal-scale/thermal-scale.model';

export enum SymptomTypes {
  Numeric = 'numeric',
  Text = 'text',
  Textarea = 'textarea',
  Dropdown = 'select',
  Radio = 'radio',
  Method = 'method',
  Modal = 'modal',
  Draw = 'draw'
}

export enum SymptomModes {
  Simple = 'simple',
  PreOpScreening = 'preOpScreening',
  Advanced = 'advanced',
  FollowUp = 'followUp',
  Treatment = 'treatment',
  PreOp = 'preop',
  Silver = 'silver'
}

export enum SymptomMethod {
  Qualitative = 'qualitative',
  Quantitative = 'quantitative'
}

export interface Symptom {
  name: string;
  type: SymptomTypes;
  modes: SymptomModes[];
  infoPopoverContent?: string;
  required?: {
    simple?: Requirements;
    advanced?: Requirements;
    preventHide?: boolean;
  };
  thermalScale?: any;
  units?: string;
}

export interface Requirements {
  oneOfSigns: string[];
}

export interface NumericSymptom extends Symptom {
  type: SymptomTypes.Numeric;
  units?: string;
  step?: number;
  min?: number;
  max?: number;
  thermalScale?: LinearThermalScaleConfig | BuckitizedThermalScaleConfig;
}

export interface TextSymptom extends Symptom {
  type: SymptomTypes.Text | SymptomTypes.Textarea;
}

export interface DropdownSymptom extends Symptom {
  type: SymptomTypes.Dropdown;
  values: { [key: string]: string };
  thermalScale?: BuckitizedThermalScaleConfig;
}

export interface RadioSymptom extends Symptom {
  type: SymptomTypes.Radio;
  values: { [key: string]: string };
  thermalScale?: BuckitizedThermalScaleConfig;
}

export interface MethodSymptom extends Symptom {
  type: SymptomTypes.Method;

  qualitative: NumericSymptom | TextSymptom | DropdownSymptom | ModalSymptom;
  quantitative: NumericSymptom | TextSymptom | DropdownSymptom | ModalSymptom;

  methodNames?: { [key: string]: string };
}

export interface ModalSymptom extends Symptom {
  type: SymptomTypes.Modal;
  modal: ModalSymptomModalOptions;
  thermalScale?: BuckitizedThermalScaleConfig;
}

export interface ModalSymptomModalOptions {
  type: ModalSymptomTypes;
  values?: { [key: string]: string };
  title?: string;
  size?: ModalSymptomSizes;
}

export enum ModalSymptomTypes {
  Simple = 'simple',
  Custom = 'custom'
}

export enum ModalSymptomSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export interface ModalSymptomSimpleModalOptions extends ModalSymptomModalOptions {
  type: ModalSymptomTypes.Simple;
  values: { [key: string]: string };
  media: { [key: string]: string };
  media2?: { [key: string]: string };
  useTwoColumns: boolean;
  reference?: string;
  reference2?: string;
}

export interface ModalSymptomCustomModalOptions extends ModalSymptomModalOptions {
  type: ModalSymptomTypes.Custom;
  valueMap?: (this: ModalSymptomCustomModalOptions, value: any) => string;
  componentName: string;
  preloadImages?: string[];
}

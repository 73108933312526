import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { QuestionnaireRequest } from './../../../API';

@Pipe({
  name: 'questionnaireScore'
})
export class QuestionnaireScorePipe implements PipeTransform {
  constructor(private questionnaireRequestService: QuestionnaireRequestService) {}

  transform(questionnaire: Partial<QuestionnaireRequest>): string {
    if (!(questionnaire && questionnaire.response)) {
      return 'N/A';
    }

    const scoreJSON = JSON.parse(questionnaire.response.scores);
    if (
      Object.keys(scoreJSON).length === 0 ||
      scoreJSON['CUSTOM'] === 'N/A' ||
      questionnaire['type'] === 'DERFS' ||
      questionnaire['type'] === 'CDERFS'
    ) {
      return 'N/A';
    }
    let isAtRisk = this.questionnaireRequestService.isAtRisk(questionnaire.response.scores)
      ? '<span class="at-risk"><b>At Risk</b></span>'
      : '<span class="not-at-risk"><b>Not At Risk</b></span>';

    let res = '';
    Object.keys(scoreJSON).forEach(key => {
      res = res + (res !== '' ? ' | ' : '') + `<b>${key}:</b> ${scoreJSON[key]}`;
      const category = this.questionnaireRequestService.getCategorizedScore(key, scoreJSON[key]);
      if (category) {
        res += ` (${category})`;
      }
    });

    // Note: handle case where At Risk does not apply (e.g. if a questionnaire only has Fitzpatrick score)

    return res + '<br>' + isAtRisk;
  }
}

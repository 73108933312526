import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API } from 'aws-amplify';
import { S3Object } from 'src/API';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import Observable from 'zen-observable-ts';
import { UpdateDoctorInput } from './../../../../API';
import { PatientService } from './../../../core/api/patient.service';
import { StaffService } from './../../../core/api/staff.service';
import { AttachmentToUriPipe } from './../../shared-pipes/attachment-to-uri.pipe';

@Component({
  selector: 'csi-realtime-media-modal',
  templateUrl: './realtime-media-modal.component.html',
  styleUrls: ['./realtime-media-modal.component.scss'],
  providers: [AttachmentToUriPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealtimeMediaModalComponent implements OnInit, OnDestroy {
  public attachments = [];

  public latestAttachment: S3Object;

  private onUpdatedDoctor = /* GraphQL */ `
    subscription OnUpdatedDoctor($id: String) {
      onUpdatedDoctor(id: $id) {
        id
        cameraAssessmentRequest
      }
    }
  `;

  private readonly updateDoctor = `
mutation UpdateDoctor($input: UpdateDoctorInput!) {
  updateDoctor(input: $input) {
    id
    cameraAssessmentRequest
  }
}`;

  public listener: ZenObservable.Subscription;

  constructor(
    private staffService: StaffService,
    private assessmentService: AssessmentService,
    private patientService: PatientService,
    private changeDetectorRef: ChangeDetectorRef,
    public dataSrcService: DataSrcService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {}

  ngOnInit() {
    this.attachments = this.assessmentService.currentAssessment.attachments || [];

    this.sortByLastUploaded();
    this.latestAttachment = this.attachments[0];

    (API.graphql({
      query: this.updateDoctor,
      variables: {
        input: {
          id: this.staffService.staff.id,
          cameraAssessmentRequest: JSON.stringify({
            assessmentId: this.assessmentService.currentAssessment.id,
            patientName:
              this.patientService.patient.firstName + ' ' + this.patientService.patient.lastName,
            patientId: this.patientService.patient.id,
            requestDate: new Date().getTime()
          })
        } as UpdateDoctorInput
      }
    }) as Promise<any>).then(() => {
      this.listener = (API.graphql({
        query: this.onUpdatedDoctor,
        variables: { id: this.staffService.staff.id }
      }) as Observable<object>).subscribe((result: any) => {
        this.assessmentService
          .getAssessment(this.assessmentService.currentAssessment.id, 'network-only')
          .subscribe(assessment => {
            this.attachments = assessment.attachments || [];
            this.sortByLastUploaded();

            this.latestAttachment = this.attachments[0];

            this.changeDetectorRef.markForCheck();
          });
      });
    });
  }

  sortByLastUploaded() {
    this.attachments = this.attachments.sort((attachment1, attachment2) => {
      if (attachment1.metadata && !attachment2.metadata) {
        return -1;
      }
      if (!attachment1.metadata && attachment2.metadata) {
        return 1;
      }

      if (!attachment1.metadata && !attachment2.metadata) {
        return 0;
      }

      return (
        new Date(JSON.parse(attachment2.metadata).uploadDate || 0).getTime() -
        new Date(JSON.parse(attachment1.metadata).uploadDate || 0).getTime()
      );
    });
  }

  ngOnDestroy() {
    this.listener.unsubscribe();
    this.staffService
      .updateDoctor({
        id: this.staffService.staff.id,
        cameraAssessmentRequest: null
      })
      .subscribe(console.log);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';
import { AssessmentRequest, AssessmentService } from '../../core/api/assessment.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentRequestResolver implements Resolve<AssessmentRequest> {
  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AssessmentRequest> | Observable<never> {
    const id = route.paramMap.get('id');

    this.loadingSpinnerService.show();
    return this.assessmentService.getAssessmentRequest(id).pipe(
      take(1),
      mergeMap(assessmentRequest => {
        if (assessmentRequest) {
          return of(assessmentRequest);
        } else {
          this.rejectAndRedirect();
          return EMPTY;
        }
      }),
      catchError(() => {
        this.rejectAndRedirect();
        return EMPTY;
      }),
      finalize(() => this.loadingSpinnerService.hide())
    );
  }

  private rejectAndRedirect() {
    alert('You are not authorized to view this or it is not found.');
    this.router.navigateByUrl('/econsult');
  }
}

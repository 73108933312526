import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldConfig, SliderConfig } from './../../../form-template-model';
import { FormTemplateGeneratorService } from './../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-slider-field-generator',
  templateUrl: './form-template-slider-field-generator.component.html',
  styleUrls: ['./form-template-slider-field-generator.component.css']
})
export class FormTemplateSliderFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: SliderConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  bottomLabels: string[];
  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    const numberOfSteps =
      (this.config.typeConfig.min + this.config.typeConfig.max) / this.config.typeConfig.step;

    this.bottomLabels = new Array(numberOfSteps);

    if (this.config.typeConfig.bottomLabels) {
      this.bottomLabels = [
        '',
        ...this.config.typeConfig.bottomLabels.map(val => val.toUpperCase())
      ];
    } else if (this.config.typeConfig.minLabel && this.config.typeConfig.maxLabel) {
      this.bottomLabels.fill('');
      this.bottomLabels[1] = this.config.typeConfig.minLabel.toUpperCase();
      this.bottomLabels[
        this.bottomLabels.length + 1
      ] = this.config.typeConfig.maxLabel.toUpperCase();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClinicSetupService } from './../../../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { InteractionsSetup } from './../../../../logged-in-navbar/interactions-setup-modal/interactions-setup.model';
import { InteractionStatusWithKey, interactionStatus } from './../interactions.model';

@Component({
  selector: 'csi-interaction-actions-modal',
  templateUrl: './interaction-actions-modal.component.html',
  styleUrls: ['./interaction-actions-modal.component.css']
})
export class InteractionActionsModalComponent implements OnInit {
  public statusForInteractionType: InteractionStatusWithKey = {};

  public interactionsSetup: InteractionsSetup = this.clinicSetupService.interactionsSetup;

  public types = this.interactionsSetup.types;
  public surgeryTypes = this.interactionsSetup.surgeryTypes;
  public statuses = this.interactionsSetup.statuses;
  public communicationMethods = this.interactionsSetup.communicationMethods;

  public interactionFormGroup: FormGroup = new FormGroup({
    type: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
    comments: new FormControl(null),
    surgeryType: new FormControl(null),
    attemptFailed: new FormControl({ value: null, disabled: true }),
    appointmentDate: new FormControl(null),
    communicationMethod: new FormControl(null)
  });
  constructor(private clinicSetupService: ClinicSetupService) {}

  ngOnInit() {

    this.interactionFormGroup.controls.type.valueChanges.subscribe(interactionType => {
      this.statusForInteractionType = {};
      Object.values(this.statuses).forEach(status => {
        if (status.onlyShowFor && !status.onlyShowFor.includes(interactionType) && !status.onlyShowFor.includes('Show For All') ) {
          return;
        }
        this.statusForInteractionType[status.name] = status;
      });
    });

    this.interactionFormGroup.controls.status.valueChanges.subscribe(value => {
      this.interactionFormGroup.controls.attemptFailed.setValue(
        !!this.statuses[value].failCommunicationStatus
      );
    });
  }
}

export interface FormTemplateFieldAutopopulateConfig {
  incomingDataValuePath: string;
  mapConditionConfig: MapConditionConfig;
  transformValueConfig: TransformValueConfig;
  nonModifiable?: boolean;
}

export enum DescriptiveTextTypeEnum {
  pre, // prepends a string value to the incoming value
  post, // appends a string value to the incoming value
  preAndPost // prepends and appends string values to the incoming value
}

export interface DescriptiveTextTypeConfig {
  type: DescriptiveTextTypeEnum;
}

export type PreDescriptiveTextConfig = {
  type: DescriptiveTextTypeEnum.pre;
  preDescriptiveText: string;
};

export type PostDescriptiveTextConfig = {
  type: DescriptiveTextTypeEnum.post;
  postDescriptiveText: string;
};

export type PreAndPostDescriptiveTextConfig = {
  type: DescriptiveTextTypeEnum.preAndPost;
  preDescriptiveText: string;
  postDescriptiveText: string;
};

// Modify the autofilled value by adding text to it (e.g '8 hours')
export type DescriptiveTextConfig =
  | PreDescriptiveTextConfig
  | PostDescriptiveTextConfig
  | PreAndPostDescriptiveTextConfig;

/************************** TRANSFORM CONFIG **************************/
export enum TransformValueTypeEnum {
  sum, // form control value = form control value + incoming value
  map, // Map of incoming values to the values that will be set as the form control value
  specifyValue, // Specify the value which will be set as the form control value
  none // No transformation will be applied. The incoming value will set as the form control value
}

export enum DisplayFormatEnum {
  list, // form control value = form control value + '\n' + incoming value
  none // No display format needed
}

export type SpecifyValueTransformation = {
  type: TransformValueTypeEnum.specifyValue;
  value: any;
};

export type MapTransformation = {
  type: TransformValueTypeEnum.map;
  map: { [key: string]: any };
};

export type SumTransformation = {
  type: TransformValueTypeEnum.sum;
};

export type NoTransformation = {
  type: TransformValueTypeEnum.none;
};
export type TransformValueTypeConfig =
  | SpecifyValueTransformation
  | SumTransformation
  | MapTransformation
  | NoTransformation;

export interface TransformValueConfig {
  typeConfig: TransformValueTypeConfig;
  displayFormat: DisplayFormatEnum;
  descriptiveTextConfig?: DescriptiveTextConfig;
}

/******************************************** MAP CONDITION CONFIG ********************************************/

export interface MapConditionConfig {
  comparisonValue?: any;
  comparisonOperator: CompareOperator;
}

export enum CompareOperator {
  equals = '=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  notEmpty = '!empty',
  notEqual = '!=',
  none = 'none'
}

import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export interface AbstractControlsMap {
  [key: string]: AbstractControl;
}

export class DynamicFormGroup<T extends AbstractControlsMap> extends FormGroup {
  private _currentFormControlSet: T | undefined;

  public patchValue(
    value: { [p: string]: any },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
    complete?: () => void
  ): void {
    try {
      this.patchValueUntilFormStopsChanging(value, options, complete);
    } catch (e) {
      console.log(e);
    }
  }

  public resetSubset(include: (controlKey: string) => boolean) {
    Object.keys(this.controls).forEach(controlKey => {
      if (include && include(controlKey)) {
        this.controls[controlKey].reset();
      }
    });
  }

  protected set currentFormControlSet(newFormControls: T) {
    if (this.currentFormControlSet) {
      this.removeControls(this.currentFormControlSet);
    }
    this.addControls(newFormControls);
    this._currentFormControlSet = newFormControls;
  }

  protected get currentFormControlSet() {
    return this._currentFormControlSet;
  }

  public get activeFormControls(): T {
    return this._currentFormControlSet;
  }

  protected linkFormControls(formControl1: FormControl, formControl2: FormControl) {
    let cycleFlag = false;

    formControl1.valueChanges.subscribe(value => {
      if (!cycleFlag) {
        cycleFlag = true;
        formControl2.setValue(value);
      } else {
        cycleFlag = false;
      }
    });

    formControl2.valueChanges.subscribe(value => {
      if (!cycleFlag) {
        cycleFlag = true;
        formControl1.setValue(value);
      } else {
        cycleFlag = false;
      }
    });
  }

  protected disableControls(filterfn?: (controlKey: string) => boolean) {
    Object.keys(this.controls).forEach(controlKey => {
      if (!filterfn || filterfn(controlKey)) {
        this.controls[controlKey].disable();
      }
    });
  }

  protected enableControls() {
    Object.keys(this.controls).forEach(controlKey => {
      this.controls[controlKey].enable();
    });
  }

  protected removeControls(controls: AbstractControlsMap) {
    Object.keys(controls).forEach(controlKey => {
      this.removeControl(controlKey);
    });
  }

  protected addControls(controls: AbstractControlsMap) {
    Object.keys(controls).forEach(controlKey => {
      this.addControl(controlKey, controls[controlKey]);
    });
  }

  // This is needed due to the dynamic nature of our form - controls are asynchronously
  // added or removed depending on certain values. In the future we may want to consider making our forms static.
  // Also JSON.stringify and localeCompare probably not most performant way to do this but good enough for now.
  private patchValueUntilFormStopsChanging(
    value: { [p: string]: any },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
    complete?: () => void,
    previousJsonValue?: string
  ) {
    super.patchValue(value, options);
    const currentJsonValue = JSON.stringify(this.getRawValue());
    if (!previousJsonValue || currentJsonValue.localeCompare(previousJsonValue) !== 0) {
      setTimeout(() =>
        this.patchValueUntilFormStopsChanging(value, options, complete, currentJsonValue)
      );
    } else if (complete) {
      complete();
    }
  }
}

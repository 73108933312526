import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  QuestionAnswer,
  QuestionGroup,
  QuestionnaireFormBuilder
} from '../simple-questionnaire/questionnaire-form-builder';

const discomfortBottomLabels: string[] = [
  'UNKNOWN',
  'NEVER',
  'RARELY',
  'SOMETIMES',
  'FREQUENTLY',
  'CONSTANTLY'
];

const intensityBottomLabels: string[] = [
  'UNKNOWN',
  'NEVER HAD IT',
  'NOT AT ALL',
  'A LITTLE',
  'MODERATE',
  'INTENSE',
  'VERY INTENSE'
];

export const deq5QuestionGroups: QuestionGroup[] = [
  {
    id: 'eyeDiscomfort',
    title: 'Questions about EYE DISCOMFORT',
    questions: [
      {
        id: 'quantity',
        title:
          'During a typical day in the past month, **how often** did your eyes feel discomfort?',
        minValue: 0,
        maxValue: 4,
        labels: discomfortBottomLabels,
        showNotApplicable: false
      },
      {
        id: 'intensity',
        title:
          'When your eyes felt discomfort, **how intense was this feeling of discomfort** at the end of the day, within two hours of going to bed?',
        minValue: 0,
        maxValue: 5,
        labels: intensityBottomLabels,
        showNotApplicable: false
      }
    ]
  },
  {
    id: 'eyeDryness',
    title: 'Questions about EYE DRYNESS',
    questions: [
      {
        id: 'quantity',
        title: 'During a typical day in the past month, **how often** did your eyes feel dry?',
        minValue: 0,
        maxValue: 4,
        labels: discomfortBottomLabels,
        showNotApplicable: false
      },
      {
        id: 'intensity',
        title:
          'When your eyes felt dry, **how intense was this feeling of dryness** at the end of the day, within two hours of going to bed?',
        minValue: 0,
        maxValue: 5,
        labels: intensityBottomLabels,
        showNotApplicable: false
      }
    ]
  },
  {
    id: 'eyeWatery',
    title: 'Questions about WATERY EYES',
    questions: [
      {
        id: 'quantity',
        title:
          'During a typical day in the past month, **how often** did your eyes look or feel excessively watery?',
        minValue: 0,
        maxValue: 4,
        labels: discomfortBottomLabels,
        showNotApplicable: false
      }
    ]
  }
];

interface DEQAnswer {
  quantity: QuestionAnswer;
  intensity: QuestionAnswer;
}

interface DEQAnswers {
  eyeDiscomfort: DEQAnswer;
  eyeDryness: DEQAnswer;
  eyeWatery: DEQAnswer;
}

const logger = new Logger('DEQ5Service');

const deq5ScoreCalculator = (value: DEQAnswers): number => {
  const score =
    value.eyeDiscomfort.quantity.answer +
    value.eyeDiscomfort.intensity.answer +
    value.eyeDryness.quantity.answer +
    value.eyeDryness.intensity.answer +
    value.eyeWatery.quantity.answer;
  logger.debug(`Calculated DEQ5 Score of ${score} with:`, value);
  return score;
};

@Injectable()
export class DEQ5Service extends QuestionnaireFormBuilder {
  title = 'DEQ';
  questionnaireKey = 'deq';

  constructor(formBuilder: FormBuilder) {
    super(deq5QuestionGroups, formBuilder, deq5ScoreCalculator);
  }
}

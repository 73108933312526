import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange, MatInput } from '@angular/material';

export class CheckboxAndInputError {
  errorType: string;
  displayMessage: string;
}

@Component({
  selector: 'csi-checkbox-and-input',
  templateUrl: './checkbox-and-input.component.html',
  styleUrls: ['./checkbox-and-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxAndInputComponent implements AfterViewInit {
  @Input() placeholder?: string;
  @Input() checkboxText?: string;
  @Input() checkboxControl?: AbstractControl = new FormControl();
  @Input() type?: string;
  @Input() currentErrors?: CheckboxAndInputError[];
  @Input() infoText?: string;
  @Input() inputControl: AbstractControl;

  @ViewChild('stringInput', { static: true }) private input: MatInput;
  @ViewChild('checkboxInput', { static: true }) private checkbox: MatCheckbox;

  constructor() {}

  ngAfterViewInit() {
    this.tryEnablingInput();
  }

  enableCheckbox(event: MouseEvent) {
    if (!this.checkboxControl.value) {
      this.checkboxControl.setValue(true);
      this.onCheckboxChange({ checked: true, source: this.checkbox });
    }
    event.preventDefault();
  }

  onCheckboxChange(event: MatCheckboxChange) {
    if (event.source) {
      this.tryEnablingInput(true);
    }
  }

  get errorMessage(): string | null {
    if (this.inputControl.errors && this.currentErrors) {
      const error = this.currentErrors.find(
        errorTypeAndMessage => this.inputControl.errors[errorTypeAndMessage.errorType]
      );
      return error ? error.displayMessage : null;
    } else {
      return null;
    }
  }

  private tryEnablingInput(focusAfter = false) {
    setTimeout(() => {
      if (this.checkboxControl.value) {
        this.inputControl.enable({ emitEvent: true });
        if (!this.inputControl.value && focusAfter) {
          this.input.focus();
        }
      } else {
        this.inputControl.disable();
      }
    });
  }
}

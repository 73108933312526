import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApolloError } from 'apollo-client';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { iif } from 'rxjs';
import { CreatePatientInput, Doctor, UpdatePatientInput } from '../../../../API';
import { AppRoutePath } from '../../../app-routing.module';
import { ErrorHandlerService } from '../../../core/api/error-handler.service';
import { Patient, PatientService } from '../../../core/api/patient.service';
import { StaffService } from '../../../core/api/staff.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { PatientFormGroup } from '../patient-form/patient-form.model';
import { ReferralService } from './../../../referral/referral.service';

export type PatientMatDialogRef = MatDialogRef<PatientModalComponent, Patient>;

export type RerouteTo = 'refer' | 'dry-eye';

interface PatientModalInputData {
  doctor: Doctor;
  prefilledPatientInfo?: Partial<Patient>;
  rerouteTo?: RerouteTo;
  requirePatientContactInformation?: boolean;
  readOnlyPatient?: boolean;
}

@Component({
  selector: 'csi-patient-modal',
  templateUrl: './patient-modal.component.html',
  styleUrls: ['./patient-modal.component.css']
})
export class PatientModalComponent implements OnDestroy {
  public readonly patientFormGroup = new PatientFormGroup();

  public triedSave = false;
  public isSaving = false;

  public readonly doctor: Doctor;
  private readonly rerouteTo: RerouteTo;
  public readonly isNewPatient: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: PatientModalInputData,
    private dialogRef: MatDialogRef<PatientModalComponent, Patient>,
    private staffService: StaffService,
    public patientService: PatientService,
    private errorHandlerService: ErrorHandlerService,
    private loadingSpinnerService: LoadingSpinnerService,
    private router: Router,
    public referralService: ReferralService
  ) {
    if (!this.inputData.readOnlyPatient && inputData.requirePatientContactInformation) {
      this.patientFormGroup.setValidators(this.patientService.contactValidator);
      this.patientFormGroup.updateValueAndValidity();

      this.patientFormGroup.controls.email.markAsTouched();
      this.patientFormGroup.controls.phone.markAsTouched();
    }
    this.doctor = inputData.doctor;
    this.isNewPatient = !!!inputData.prefilledPatientInfo || !!!inputData.prefilledPatientInfo.id;
    this.patientFormGroup.patchValue(inputData.prefilledPatientInfo || {});
    this.inputData.readOnlyPatient ? this.patientFormGroup.disable() : '';
    this.rerouteTo = inputData.rerouteTo;
    this.watchFormValidity();
  }

  public static open(
    matDialogService: MatDialog,
    doctor: Doctor,
    prefilledPatientInfo?: Partial<Patient>,
    rerouteTo: RerouteTo = 'dry-eye',
    config: MatDialogConfig<never> = {},
    requirePatientContactInformation = false,
    readOnlyPatient = false
  ): PatientMatDialogRef {
    return matDialogService.open<PatientModalComponent, PatientModalInputData, Patient>(
      PatientModalComponent,
      {
        width: '850px',
        maxWidth: '95vw',
        height: 'auto',
        ...config,
        data: {
          doctor,
          prefilledPatientInfo,
          rerouteTo,
          requirePatientContactInformation,
          readOnlyPatient
        }
      }
    );
  }

  ngOnDestroy() {}

  public save() {
    this.patientFormGroup.submitted = true;
    this.patientFormGroup.markAllAsTouched();
    this.triedSave = true;

    if (this.patientFormGroup.valid) {
      this.isSaving = true;

      const patientInfo = this.patientFormGroup.toApiInput();
      patientInfo.patientDoctorId = this.doctor.id;

      this.disableInteraction();

      iif(
        () => this.isNewPatient,
        this.patientService.createPatient(patientInfo as CreatePatientInput),
        this.patientService.updatePatient(patientInfo as UpdatePatientInput)
      ).subscribe({
        next: (patient: Patient) => {
          this.closeModal(patient);
        },
        error: (error: ApolloError) => {
          this.onError(error);
        }
      });
    }
  }

  public get isDoctorApprovedForDryEyeSpecializedForm(): boolean {
    return this.staffService.isDoctorApprovedForDryEyeSpecializedForm(this.doctor);
  }

  private closeModal(patient: Patient) {
    this.isSaving = false;
    this.enableInteraction();

    this.dialogRef.close(patient);
    this.reroute(patient);
  }

  private onError(error: ApolloError) {
    this.isSaving = false;
    this.enableInteraction();

    this.errorHandlerService.handleGraphQLError(error);
  }

  private reroute(patient: Patient) {
    if (this.rerouteTo) {
      this.loadingSpinnerService.show();
      this.router
        .navigateByUrl(
          `/${this.rerouteTo === 'dry-eye' ? AppRoutePath.patients : '/refer/patient'}/${
            patient.id
          }`
        )
        .finally(() => this.loadingSpinnerService.hide());
    }
  }

  private disableInteraction() {
    this.dialogRef.disableClose = true;
    this.patientFormGroup.disable();
  }

  private enableInteraction() {
    this.dialogRef.disableClose = false;
    this.patientFormGroup.enable();
  }

  private watchFormValidity() {
    this.patientFormGroup.statusChanges.pipe(untilDestroyed(this)).subscribe((status: string) => {
      if (status !== 'INVALID') {
        this.triedSave = false;
      }
    });
  }
}

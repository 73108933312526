import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ApolloError } from 'apollo-client';
import { forkJoin, iif, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { AuthenticationService, MFAType } from 'src/app/core/authentication/authentication.service';
import { ErrorHandlerService } from '../../core/api/error-handler.service';
import { StaffService } from '../../core/api/staff.service';
import { DoctorFormGroup } from '../../shared/consult-forms/doctor-form/doctor-form.model';

export type MyProfileMatDialogRef = MatDialogRef<MyProfileModalComponent, undefined>;

@Component({
  selector: 'csi-my-profile-modal',
  templateUrl: './my-profile-modal.component.html',
  styleUrls: ['./my-profile-modal.component.css']
})
export class MyProfileModalComponent implements OnDestroy {
  public readonly doctorFormGroup = new DoctorFormGroup();

  public triedSave = false;
  public isSaving = false;
  public doctor: Doctor;
  public currentMFA: MFAType;

  @ViewChild('tabGroup', { static: true }) private tabGroup: MatTabGroup;

  constructor(
    private dialogRef: MatDialogRef<MyProfileModalComponent, undefined>,
    private staffService: StaffService,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) private data: { doctor: Doctor }
  ) {
    this.doctor = this.data.doctor;

    this.authenticationService.getLoggedInUser().subscribe(loggedInUser => {
      this.doctorFormGroup.patchCognitoData(loggedInUser);
      this.currentMFA = this.doctorFormGroup.controls.preferredMFAType.value;
    });

    this.doctorFormGroup.patchFromDoctor(this.doctor);

    this.doctorFormGroup.enable();

    this.doctorFormGroup.controls.email.disable();
    this.doctorFormGroup.controls.staffType.disable();
  }

  public static open(
    matDialogService: MatDialog,
    doctor: Doctor,
    config: MatDialogConfig<never> = {}
  ): MyProfileMatDialogRef {
    return matDialogService.open<MyProfileModalComponent, { doctor: Doctor }, undefined>(
      MyProfileModalComponent,
      {
        width: '54rem',
        maxWidth: '95vw',
        height: 'auto',
        ...config,
        data: { doctor: doctor }
      }
    );
  }

  ngOnDestroy() {}

  public save() {
    this.doctorFormGroup.markAllAsTouched();

    this.triedSave = true;

    if (this.doctorFormGroup.valid) {
      this.isSaving = true;
      this.disableInteraction();

      forkJoin([
        iif(
          () => this.doctorFormGroup.dirty,
          this.staffService.updateDoctor(
            this.doctorFormGroup.toUpdateDoctorInput(this.doctor.id, this.doctor.clinic.id)
          ),
          of(this.doctor)
        ),
        iif(
          () => this.doctorFormGroup.controls.preferredMFAType.value !== this.currentMFA,
          this.authenticationService.setPreferredMFA(
            this.doctorFormGroup.controls.preferredMFAType.value
          ),
          of(this.doctorFormGroup)
        )
      ])
        .pipe(
          finalize(() => {
            this.isSaving = false;
            this.enableInteraction();
          })
        )
        .subscribe({
          error: (error: ApolloError) => {
            this.errorHandlerService.handleGraphQLError(error);
          },
          complete: () => {
            this.dialogRef.close();
          }
        });
    }
  }

  private disableInteraction() {
    this.dialogRef.disableClose = true;
    this.doctorFormGroup.disable();
  }

  private enableInteraction() {
    this.dialogRef.disableClose = false;

    this.doctorFormGroup.enable();

    this.doctorFormGroup.controls.email.disable();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormTemplateBuilderService } from './form-template-builder.service';

@Component({
  selector: 'csi-form-template-builder',
  templateUrl: './form-template-builder.component.html',
  styleUrls: ['./form-template-builder.component.css']
})
export class FormTemplateBuilderComponent implements OnInit {
  public formTemplateFormGroup = this.formTemplateBuilderService.formTemplateFormGroup;

  constructor(private formTemplateBuilderService: FormTemplateBuilderService) {}

  ngOnInit() {}
}

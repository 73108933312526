import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalSymptomCustomModalOptions } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';

@Component({
  selector: 'csi-nitbut-modal',
  templateUrl: './nitbut-modal.component.html',
  styleUrls: ['./nitbut-modal.component.css']
})
export class NitbutModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit {
  private firstValueKey = this.config.formKey + '_value1';
  private secondValueKey = this.config.formKey + '_value2';
  private thirdValueKey = this.config.formKey + '_value3';

  public averageFormControl;

  public valueFormGroup = new FormGroup({
    [this.firstValueKey]: new FormControl(null, [Validators.required, Validators.min(0)]),
    [this.secondValueKey]: new FormControl(null, [Validators.required, Validators.min(0)]),
    [this.thirdValueKey]: new FormControl(null, [Validators.required, Validators.min(0)])
  });
  constructor(
    public dialogRef: MatDialogRef<NitbutModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    this.averageFormControl = this.config.formGroup.controls[this.config.formKey];
    this.valueFormGroup.patchValue({
      [this.firstValueKey]: this.config.formGroup.value[this.firstValueKey],
      [this.secondValueKey]: this.config.formGroup.value[this.secondValueKey],
      [this.thirdValueKey]: this.config.formGroup.value[this.thirdValueKey]
    });

    this.valueFormGroup.valueChanges.subscribe(values => {
      if (this.valueFormGroup.status === 'VALID') {
        let average = 0;

        const nitbutValues = Object.values(values);
        nitbutValues.forEach((value: number) => {
          average += value;
        });
        average = +(average / nitbutValues.length).toFixed(2);
        this.averageFormControl.setValue(average);
      }
    });
  }

  getAllPossibleSymptomControlKeys(): string[] {
    return [this.firstValueKey, this.secondValueKey, this.thirdValueKey];
  }

  save() {
    this.valueFormGroup.markAllAsTouched();
    if (this.valueFormGroup.valid) {
      Object.entries(this.valueFormGroup.controls).forEach(([key, value]) => {
        if (!this.config.formGroup.controls[key]) {
          this.config.formGroup.addControl(key, new FormControl());
        }
      });
      this.config.formGroup.patchValue(this.valueFormGroup.value);
      this.dialogRef.close();
    }
  }
}

import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../modals/abstract-symptom-modal.component';
import { SymptomSimpleModalComponent } from '../modals/symptom-simple-modal/symptom-simple-modal.component';
import {
  ModalSymptom,
  ModalSymptomCustomModalOptions,
  ModalSymptomSizes,
  ModalSymptomTypes
} from '../symptom.model';
import { ComponentLookupService } from './component-lookup/component-lookup.service';

@Component({
  selector: 'csi-modal-symptom-control',
  templateUrl: './modal-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './modal-symptom-control.component.css'
  ],
  providers: [
    ComponentLookupService,
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => ModalSymptomControlComponent)
    }
  ]
})
export class ModalSymptomControlComponent extends AbstractSymptomControlComponent
  implements OnChanges {
  @Input() symptom: ModalSymptom;
  @Input() formKey: string;
  @Input() formGroup: DryEyeFormGroup;
  @Input() methodFormControl: FormControl;

  @Output() modalWillShow = new EventEmitter();
  @Output() modalDidHide = new EventEmitter();
  @Output() valueSelected = new EventEmitter<string>();

  private readonly modalSizeMap = {
    [ModalSymptomSizes.Small]: '500px',
    [ModalSymptomSizes.Medium]: '800px',
    [ModalSymptomSizes.Large]: '100vw'
  };

  private previousFormValue: any = null;

  constructor(
    protected symptomValidatorsService: SymptomValidatorsService,
    private dialog: MatDialog,
    private componentFactory: ComponentFactoryResolver,
    private injector: Injector,
    private componentLookupService: ComponentLookupService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if ((changes.formKey || changes.formGroup) && this.formGroup && this.formKey) {
      const formControl = this.formGroup.get(this.formKey);
      if (formControl) {
        formControl.valueChanges.subscribe(value => {
          if (this.previousFormValue !== value) {
            this.valueSelected.emit(value);
            this.previousFormValue = value;
          }
        });
      }
    }

    if (
      changes.methodFormControl &&
      changes.methodFormControl.currentValue &&
      changes.methodFormControl.currentValue.touched
    ) {
      this.showModal();
    }
  }

  showModal() {
    this.modalWillShow.emit();

    const dialogRef = this.dialog.open<
      AbstractSymptomModalComponent,
      SymptomModalConfig,
      undefined
    >(this.modalComponentClass, {
      width: this.modalSizeMap[this.symptom.modal.size || ModalSymptomSizes.Medium],
      height: 'auto',
      maxWidth: '95vw',
      data: {
        modalConfig: this.symptom.modal,
        formKey: this.formKey,
        formGroup: this.formGroup
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.modalDidHide.emit();
    });
  }

  get modalComponentClass(): any {
    if (this.symptom.modal.type === ModalSymptomTypes.Simple) {
      return SymptomSimpleModalComponent;
    } else {
      const customComponentName = (<ModalSymptomCustomModalOptions>this.symptom.modal)
        .componentName;
      const customComponentClass = this.componentLookupService.findComponentByName(
        customComponentName
      );

      if (customComponentClass) {
        const isSymptomModal =
          customComponentClass.prototype instanceof AbstractSymptomModalComponent;

        if (isSymptomModal) {
          return customComponentClass;
        } else {
          console.error(
            `'${customComponentName}' is not an instance of AbstractSymptomModalComponent\n` +
              'Custom modals must derive from the AbstractSymptomModalComponent base class'
          );

          alert(
            `'${customComponentName}' is not an instance of AbstractSymptomModalComponent\n` +
              'Custom modals must derive from the AbstractSymptomModalComponent base class'
          );
        }
      } else {
        console.error(
          `Could not find custom modal component '${customComponentName}'\n` +
            'Are you sure you added the component to the switch in component-lookup.service.ts?'
        );
        alert(
          `Could not find custom modal component '${customComponentName}'\n` +
            'Are you sure you added the component to the switch in component-lookup.service.ts?'
        );
      }
    }
  }

  get displayTextForValue() {
    if (
      (this.symptom.modal.type === ModalSymptomTypes.Simple ||
        !(<ModalSymptomCustomModalOptions>this.symptom.modal).valueMap) &&
      this.symptom.modal.values &&
      this.value
    ) {
      return this.symptom.modal.values && this.value
        ? this.symptom.modal.values[this.value]
        : 'Select';
    } else if ((<ModalSymptomCustomModalOptions>this.symptom.modal).valueMap && this.value) {
      return (<ModalSymptomCustomModalOptions>this.symptom.modal).valueMap(this.value);
    } else if (this.value && this.modalComponentClass.valueMap) {
      return this.modalComponentClass.valueMap(this.value);
    } else if (this.value) {
      return this.value + ' ' + this.symptom.units;
    } else {
      return 'Select';
    }
  }

  get shouldShowThermalScale(): boolean {
    return this.symptom.thermalScale && this.value;
  }

  getAllPossibleSymptomControlKeys(): string[] {
    const modalComponentFactory = this.componentFactory.resolveComponentFactory<
      AbstractSymptomModalComponent
    >(this.modalComponentClass);

    const injector = Injector.create({
      providers: [
        {
          provide: MAT_DIALOG_DATA,
          useValue: { formKey: this.formKey } as Partial<SymptomModalConfig>
        },
        {
          provide: MatDialogRef,
          useValue: null
        }
      ],
      parent: this.injector
    });

    const modalComponentRef = modalComponentFactory.create(injector);
    return modalComponentRef.instance.getAllPossibleSymptomControlKeys();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MenuPositionY } from '@angular/material/menu';
import { TranslocoService } from '@ngneat/transloco';
import { PDFType } from '../dry-eye-pdf-export.component';

export type ButtonAppearance = ButtonAppearances | 'standard' | 'raised' | 'stroked';

export enum ButtonAppearances {
  Standard = 'standard',
  Raised = 'raised',
  Stroked = 'stroked'
}

export type DropdownArrowDirection = DropdownArrowDirections | 'up' | 'down';

export enum DropdownArrowDirections {
  Up = 'up',
  Down = 'down'
}

@Component({
  selector: 'csi-pdf-export-button',
  templateUrl: './pdf-export-button.component.html',
  styleUrls: ['./pdf-export-button.component.css']
})
export class PdfExportButtonComponent {
  @Input() appearance: ButtonAppearance = ButtonAppearances.Raised;
  @Input() color: ThemePalette;
  @Input() menuPositionY: MenuPositionY = 'below';
  @Input() dropdownArrowDirection: DropdownArrowDirection = DropdownArrowDirections.Up;
  @Input() disabled = false;

  @Output() generatePdf = new EventEmitter<{ pdfType: PDFType; lang: string }>();

  public readonly ButtonAppearances = ButtonAppearances;
  public readonly DropdownArrowDirections = DropdownArrowDirections;

  constructor(public translocoService: TranslocoService) {}

  public generatePDF(pdfType: PDFType, lang: string = 'en') {
    this.generatePdf.emit({ pdfType, lang });
  }
}

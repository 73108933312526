import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Doctor, Gender } from '../../../API';
import { Patient } from '../../core/api/patient.service';

export enum NameFormat {
  Fullname, // John Doe
  TitleWithAbbreviatedFirstAndFullLastName // Dr. J. Doe, Mr. J. Doe, etc..
}

@Pipe({
  name: 'personName'
})
export class PersonNamePipe implements PipeTransform {
  private titleCasePipe: TitleCasePipe;

  constructor() {
    this.titleCasePipe = new TitleCasePipe();
  }

  transform(
    user: Doctor | Patient,
    format: NameFormat,
    skipAutoCapitalization: boolean = false
  ): any {
    let result: string = null;
    if(!user){
      return
    }

    switch (format) {
      case NameFormat.TitleWithAbbreviatedFirstAndFullLastName:
        result = this.getTitleWithAbbreviatedFirstAndFullLastName(user);
        break;
      default:
        result = this.getFullName(user);
    }
    
    if (result) {
      return skipAutoCapitalization ? result : this.titleCasePipe.transform(result);
    } else if (user.email) {
      return user.email;
    } else {
      return null;
    }
  }

  private getTitleWithAbbreviatedFirstAndFullLastName(user: Doctor | Patient): string {
    let firstNameAcronym = '';
    let lastName = '';
    if (user.firstName) {
      firstNameAcronym = `${user.firstName.charAt(0)}.`;
    }
    if (user.lastName) {
      lastName = user.lastName;
    }

    if (user.firstName || user.lastName) {
      const name = [firstNameAcronym, lastName].join(' ');

      if (user.__typename === 'Doctor') {
        return `Dr. ${name}`;
      } else {
        return (<Patient>user).gender === Gender.male ? `Mr. ${name}` : `Ms. ${name}`;
      }
    } else {
      return undefined;
    }
  }

  private getFullName(user: Doctor | Patient): string {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else if (user.firstName) {
      return `${user.firstName}`;
    } else if (user.lastName) {
      return `${user.lastName}`;
    } else {
      return null;
    }
  }
}

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientFormGroup } from '../../../shared/patient-form/patient-form.model';

export class CSVPatientsFormGroup extends FormGroup {
  controls: {
    patients: FormArray;
    doesPatientExist: FormControl;
    shouldCreateDuplicates: FormControl;
  };

  constructor() {
    super({
      patients: new FormArray(
        [],
        [
          Validators.minLength(1),
          (patients: FormArray) => {
            return patients.controls.every((control: PatientFormGroup) => {
              const formGroupControls = Object.keys(control.controls).map(
                key => control.controls[key]
              );
              return formGroupControls.every(
                formGroupControl => formGroupControl.valid || formGroupControl.value === ''
              );
            })
              ? null
              : { invalidColumns: '' };
          }
        ]
      ),
      doesPatientExist: new FormControl(undefined, Validators.required),
      shouldCreateDuplicates: new FormControl(true)
    });
  }

  reset() {
    super.reset();
    this.controls.patients.controls = [];
    this.controls.shouldCreateDuplicates.setValue(false);
  }

  public get hasExistingPatients(): boolean {
    const possibleDuplicates = this.controls.doesPatientExist.value as boolean[];
    return (
      possibleDuplicates && possibleDuplicates.length !== 0 && possibleDuplicates.some(val => val)
    );
  }

  public updateThisAndDescendants(opts?: { emitEvent?: boolean; onlySelf?: boolean }) {
    this.controls.patients.updateValueAndValidity(opts);
    this.controls.doesPatientExist.updateValueAndValidity(opts);
    super.updateValueAndValidity(opts);
  }

  public get patientsToCreate(): PatientFormGroup[] {
    return (this.controls.shouldCreateDuplicates.value
      ? this.controls.patients.controls
      : this.controls.patients.controls.filter(
          (patient, index) =>
            !this.controls.doesPatientExist.value || !this.controls.doesPatientExist.value[index]
        )) as PatientFormGroup[];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldConfig, TextConfig } from './../../../form-template-model';
import { FormTemplateGeneratorService } from './../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-text-field-generator',
  templateUrl: './form-template-text-field-generator.component.html',
  styleUrls: ['./form-template-text-field-generator.component.css']
})
export class FormTemplateTextFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: TextConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {}
}

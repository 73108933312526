import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgSelectComponent } from '@ng-select/ng-select';
import { range } from 'lodash-es';
import { merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Birthday } from './birthday.model';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BirthdayPickerComponent),
  multi: true
};

@Component({
  selector: 'csi-birthday-picker',
  templateUrl: './birthday-picker.component.html',
  styleUrls: ['./birthday-picker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdayPickerComponent implements ControlValueAccessor {
  @ViewChild('picker', { static: true }) datePicker: MatDatepicker<any>;
  @ViewChild('yearSelect', { static: true }) yearSelect: NgSelectComponent;
  private readonly oldestAge = 123;
  public readonly today = new Date();
  public readonly oldestDatePossible = new Date(this.today.getFullYear() - this.oldestAge, 0, 1);

  public readonly months = range(1, 13);
  public readonly monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  public readonly years: number[] = range(
    this.today.getFullYear(),
    this.today.getFullYear() - this.oldestAge - 1,
    -1
  );

  public days = range(1, 32);
  public selectedBirthday = new Birthday();
  public isDisabled = false;
  public yearOptions$ = new Observable<number[]>();
  public typeahead = new Subject<string>();

  public defaultLoadedDate: Date;

  private _onChange: (_: any) => {};

  constructor(private changeDetector: ChangeDetectorRef) {
    this.yearOptions$ = merge(this.getYearOptionsFromTypeahead(), of(this.years));
  }

  public ngSelectSearchMonth = (userInput: string, month: number): boolean => {
    const monthAsString = (month < 9 ? '0' : '') + (month + 1).toString();
    const matchesNumber = monthAsString.includes(userInput);
    const matchesMonthName = this.monthNames[month].toLowerCase().includes(userInput.toLowerCase());
    const startsWithInput =
      monthAsString.startsWith(userInput) ||
      (month + 1).toString().startsWith(userInput) ||
      this.monthNames[month].toLowerCase().startsWith(userInput.toLowerCase());
    return (matchesNumber || matchesMonthName) && startsWithInput;
  };

  public ngSelectSearchDay = (userInput: string, day: number): boolean => {
    const dayString = (day < 10 ? '0' : '') + day.toString();
    const startsWithInput = dayString.startsWith(userInput) || day.toString().startsWith(userInput);
    return dayString.includes(userInput) && startsWithInput;
  };

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }

  registerOnTouched() {}

  writeValue(dateInput: Date | string) {
    if (dateInput) {
      if (typeof dateInput === 'string') {
        dateInput = this.dateStringToDate(dateInput);
      }
      this.selectedBirthday.setDate(dateInput);
      // We use this rather than this.datePicker.select() since this does trigger (dateChange) event
      this.defaultLoadedDate = dateInput;
      this.changeDetector.markForCheck();
    } else {
      // Date(undefined) = InvalidDateObject, but Date(null) = Jan 1 1970
      this.selectedBirthday.setDate(undefined);
      this.datePicker.select(undefined);
    }
  }

  onNgSelectUserChoice() {
    this.setNumberOfDaysInMonth();
    const newDate = this.selectedBirthday.toDate();
    this.datePicker.select(this.selectedBirthday.toDate());
    this._onChange(newDate);
  }

  onCalendarChange(dateEvent: MatDatepickerInputEvent<any>) {
    this.setNumberOfDaysInMonth();
    const newDate = dateEvent.value as Date;
    if (newDate) {
      this.selectedBirthday.setDate(newDate);
    }
    this._onChange(newDate);
  }

  isInFuture(year: number, month: number, day: number): boolean {
    const selectedDate = new Date(year, month, day);
    return selectedDate > this.today;
  }

  public onEnter(event: Event) {
    event.stopPropagation();
  }

  private setNumberOfDaysInMonth() {
    this.days = range(
      1,
      new Date(
        this.selectedBirthday.year || null,
        this.selectedBirthday.month + 1 || 1,
        0
      ).getDate() + 1
    );
  }

  // Must be of format YYYY-M(M)-D(D)(T...)
  private dateStringToDate(date: string): Date {
    const dateStrings = date.split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
    return dateStrings
      ? new Date(Number(dateStrings[1]), Number(dateStrings[2]) - 1, Number(dateStrings[3]))
      : null;
  }

  private getYearOptionsFromTypeahead(): Observable<number[]> {
    return this.typeahead.pipe(map(query => this.filterYears(query)));
  }
  private filterYears(query: string): number[] {
    if (query !== null) {
      const yearOptions: number[] = this.years.filter(year => year.toString().includes(query));
      yearOptions.sort((a, b) => {
        return a.toString().indexOf(query) - b.toString().indexOf(query);
      });
      return yearOptions;
    } else {
      return this.years;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateOnBlurDirective } from './update-on-blur.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [UpdateOnBlurDirective],
  exports: [UpdateOnBlurDirective]
})
export class UpdateOnBlurModule {}

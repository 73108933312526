/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const me = /* GraphQL */ `
  query Me {
    me {
      id
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      cognitoGroups
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      practitionerId
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      assessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      inboundAssessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deactivated
      staffType
      clinicLicenses
      cameraAssessmentRequest
      ownedClinics
      allowMultipleClinics
      displayedNameFormat
    }
  }
`;
export const htmlToPdf = /* GraphQL */ `
  query HtmlToPdf($htmlInBase64: String!) {
    htmlToPDF(htmlInBase64: $htmlInBase64) {
      base64
    }
  }
`;
export const queueFax = /* GraphQL */ `
  query QueueFax($to: AWSPhone!, $title: String!, $htmlInBase64: String!) {
    queueFax(to: $to, title: $title, htmlInBase64: $htmlInBase64) {
      Status
      Result
    }
  }
`;
export const searchMedications = /* GraphQL */ `
  query SearchMedications($query: String!) {
    searchMedications(query: $query) {
      products {
        _id
        ndc_product_codes
        dpd_codes
        ema_product_codes
        name
        rx_norm_prescribable_name
        country
        route
        dosage_form
        ingredients {
          drugbank_id
          name
        }
      }
    }
  }
`;
export const getDryEyePrediction = /* GraphQL */ `
  query GetDryEyePrediction($input: AWSJSON) {
    getDryEyePrediction(input: $input)
  }
`;
export const getFilteredData = /* GraphQL */ `
  query GetFilteredData($type: FilterType!, $clinicId: ID!, $filter: AWSJSON!) {
    getFilteredData(type: $type, clinicId: $clinicId, filter: $filter)
  }
`;
export const dummyS3ObjectQuery = /* GraphQL */ `
  query DummyS3ObjectQuery {
    dummyS3ObjectQuery {
      bucket
      key
      region
      fileName
      metadata
    }
  }
`;
export const dummyCognitoGroup = /* GraphQL */ `
  query DummyCognitoGroup {
    dummyCognitoGroup
  }
`;
export const drugLookup = /* GraphQL */ `
  query DrugLookup($query: String!) {
    drugLookup(query: $query) {
      id
      ndc_product_codes
      dpd_codes
      ema_product_codes
      name
      rx_norm_prescribable_name
      country
      routes {
        route_of_administration
      }
      dosage_form
      active_ingredients {
        ingredient
        strength
        strength_unit
      }
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      name
      address
      province
      country
      city
      postalCode
      phone
      fax
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      doctors {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      emrIntegration {
        fax {
          enabled
          faxNumber
        }
      }
      stripeInformation {
        subscriptionId
        customerId
        lastDigitsOfCard
        nextPaymentDate
        nameOnCard
        subscriptionStatus
      }
      clinicSetup {
        id
        clinicSetupClinicId
        textFavorites {
          nextToken
        }
        treatmentTemplates {
          nextToken
        }
        doctorLicenses
        layouts {
          id
          name
          alwaysExpand
          customLayout
          symptomSettings
        }
        interactionsSetup
        defaultQuestionnaireLanguage
        defaultPdfLanguage
        temperatureUnit
        questionnaireExpiry
        welcomeMessageConfig
        emailNotificationsConfig
        hiddenTextFavorites
        hiddenTreatmentTemplates
        textFavoriteConfig
        questionnaireScoreConfig
        questionnaireNotificationsConfig
        createdAt
        updatedAt
      }
      agreedToTermsOfService
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      linkedReferralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      clinicLinkedReferralCenterId
      hasDryEyePortalAccess
      createdAt
      updatedAt
      clinicFormTemplates {
        items {
          id
          schema
          formTemplateClinicId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      properties {
        doctorId
        status {
          read
          flag
        }
      }
      type
      typeId
      content
      notificationClinicId
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        properties {
          doctorId
        }
        type
        typeId
        content
        notificationClinicId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSystemNotifications = /* GraphQL */ `
  query ListSystemNotifications(
    $filter: ModelSystemNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIntakeForm = /* GraphQL */ `
  query GetIntakeForm($id: ID!) {
    getIntakeForm(id: $id) {
      id
      response
      createdAt
      intakeFormClinicId
      intakeFormPatientId
      statusId
      status
      lastRead
      config
      updatedAt
    }
  }
`;
export const listIntakeForms = /* GraphQL */ `
  query ListIntakeForms(
    $filter: ModelIntakeFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntakeForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        response
        createdAt
        intakeFormClinicId
        intakeFormPatientId
        statusId
        status
        lastRead
        config
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInteraction = /* GraphQL */ `
  query GetInteraction($id: ID!) {
    getInteraction(id: $id) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      type
      status
      comments
      createdAt
      updatedAt
      surgeryType
      appointmentDate
      attemptFailed
      communicationMethod
      interactionClinicId
    }
  }
`;
export const listInteractions = /* GraphQL */ `
  query ListInteractions(
    $filter: ModelInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInteractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        type
        status
        comments
        createdAt
        updatedAt
        surgeryType
        appointmentDate
        attemptFailed
        communicationMethod
        interactionClinicId
      }
      nextToken
    }
  }
`;
export const getMassClinic = /* GraphQL */ `
  query GetMassClinic($id: ID!) {
    getMassClinic(id: $id) {
      id
      massClinicClinicId
      date
      massClinicPatients {
        items {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        nextToken
      }
      isCancelled
      createdAt
      updatedAt
    }
  }
`;
export const getMassClinicPatient = /* GraphQL */ `
  query GetMassClinicPatient($id: ID!) {
    getMassClinicPatient(id: $id) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      massClinicPatientClinicId
      massClinic {
        id
        massClinicClinicId
        date
        massClinicPatients {
          nextToken
        }
        isCancelled
        createdAt
        updatedAt
      }
      isCancelled
      createdAt
      updatedAt
      questionnaireRequest {
        id
        email
        mobile
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        massClinicPatient {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        assessment {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        questionnaireRequestPatientId
        type
        response {
          answers
          scores
        }
        country
        consentsToPrivacyForm
        consentSource
        consentsToResearch
        completedAt
        createdAt
        updatedAt
        nonModifiableData
        language
        config
      }
    }
  }
`;
export const getStaffClinicReference = /* GraphQL */ `
  query GetStaffClinicReference($id: ID!) {
    getStaffClinicReference(id: $id) {
      id
      staffId
      clinicId
      clinicOwnerId
      staffTypes
      createdAt
      updatedAt
    }
  }
`;
export const listStaffClinicReferences = /* GraphQL */ `
  query ListStaffClinicReferences(
    $filter: ModelStaffClinicReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffClinicReferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        staffId
        clinicId
        clinicOwnerId
        staffTypes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      cognitoGroups
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      practitionerId
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      assessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      inboundAssessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deactivated
      staffType
      clinicLicenses
      cameraAssessmentRequest
      ownedClinics
      allowMultipleClinics
      displayedNameFormat
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      firstName
      lastName
      email
      emailVerified
      phone
      agreedToTerms
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      patientClinicId
      dateOfBirth
      healthCard {
        province
        number
        country
      }
      gender
      genderOther
      address
      province
      country
      city
      postalCode
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      consentsToResearch
      consentsToPrivacyForm
      consentSource
      createdAt
      updatedAt
      linkedReferralPatientId
      referralSource
      nonModifiableData
      interactions {
        items {
          id
          type
          status
          comments
          createdAt
          updatedAt
          surgeryType
          appointmentDate
          attemptFailed
          communicationMethod
          interactionClinicId
        }
        nextToken
      }
      lastInteractionAt
      isColdLead
      fitzpatrickImage {
        bucket
        key
        region
        fileName
        metadata
      }
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      assessmentClinicId
      requests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      locked
      createdAt
      updatedAt
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getReferralCenter = /* GraphQL */ `
  query GetReferralCenter($id: ID!) {
    getReferralCenter(id: $id) {
      id
      name
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      referralCenterClinicId
      diseaseAreas
      notificationRecipients
      referralForms {
        name
        file {
          bucket
          key
          region
          fileName
          metadata
        }
        type
      }
      agreement
      copaymentEmbedLink
      educationEmbedLink
      advancedReferralCenter
      createdAt
      updatedAt
    }
  }
`;
export const listReferralCenters = /* GraphQL */ `
  query ListReferralCenters(
    $filter: ModelReferralCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferral = /* GraphQL */ `
  query GetReferral($id: ID!) {
    getReferral(id: $id) {
      id
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      referringClinicId
      referralCenterClinicId
      referralReferralCenterId
      referralPatientId
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      priority
      openedAt
      status
      referralCenterResponse {
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        comment
        responseTime
      }
      comment
      feedbackHistory
      createdAt
      updatedAt
      type
      parentReferralId
    }
  }
`;
export const listReferrals = /* GraphQL */ `
  query ListReferrals(
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const getAssessmentRequest = /* GraphQL */ `
  query GetAssessmentRequest($id: ID!) {
    getAssessmentRequest(id: $id) {
      id
      type
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      recipient {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      response
      priority
      createdAt
      updatedAt
    }
  }
`;
export const listFormTemplateFieldLibrary = /* GraphQL */ `
  query ListFormTemplateFieldLibrary(
    $filter: ModelFormTemplateFieldLibraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormTemplateFieldLibrary(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        label
        typeConfig
        required
        triggerGroups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query GetReminder($id: ID!) {
    getReminder(id: $id) {
      id
      reminderPatientId
      reminderClinicId
      reminderStaffId
      clinicName
      doctorName
      patientName
      nextReminderDate
      expirationDate
      numberOfReminders
      email
      phone
      reminderType
      config
      createdAt
      updatedAt
    }
  }
`;
export const listReminders = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reminderPatientId
        reminderClinicId
        reminderStaffId
        clinicName
        doctorName
        patientName
        nextReminderDate
        expirationDate
        numberOfReminders
        email
        phone
        reminderType
        config
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listClinicsByLinkedReferralCenter = /* GraphQL */ `
  query ListClinicsByLinkedReferralCenter(
    $clinicLinkedReferralCenterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicsByLinkedReferralCenter(
      clinicLinkedReferralCenterId: $clinicLinkedReferralCenterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listNotificationsByTypeIdBasedOnCreatedAt = /* GraphQL */ `
  query ListNotificationsByTypeIdBasedOnCreatedAt(
    $typeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByTypeIdBasedOnCreatedAt(
      typeId: $typeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        properties {
          doctorId
        }
        type
        typeId
        content
        notificationClinicId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIntakeFormByClinicIdSortedByCreatedAt = /* GraphQL */ `
  query ListIntakeFormByClinicIdSortedByCreatedAt(
    $intakeFormClinicId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntakeFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntakeFormByClinicIdSortedByCreatedAt(
      intakeFormClinicId: $intakeFormClinicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        createdAt
        intakeFormClinicId
        intakeFormPatientId
        statusId
        status
        lastRead
        config
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIntakeFormByPatientIdSortedByCreatedAt = /* GraphQL */ `
  query ListIntakeFormByPatientIdSortedByCreatedAt(
    $intakeFormPatientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntakeFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntakeFormByPatientIdSortedByCreatedAt(
      intakeFormPatientId: $intakeFormPatientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        createdAt
        intakeFormClinicId
        intakeFormPatientId
        statusId
        status
        lastRead
        config
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTextFavorites = /* GraphQL */ `
  query ListTextFavorites(
    $textFavoriteClinicId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTextFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTextFavorites(
      textFavoriteClinicId: $textFavoriteClinicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shortcut
        text
        type
        treatmentTemplate {
          id
          friendlyId
          otcEyeDrops
          rxEyeDrops
          procedures
          exercises
          treatmentTemplateClinicId
          copiedTreatmentTemplateId
          createdAt
          updatedAt
        }
        textFavoriteTreatmentTemplateId
        textFavoriteClinicId
        copiedTextFavoriteId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTreatmentTemplate = /* GraphQL */ `
  query ListTreatmentTemplate(
    $treatmentTemplateClinicId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentTemplate(
      treatmentTemplateClinicId: $treatmentTemplateClinicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        otcEyeDrops
        rxEyeDrops
        procedures
        exercises
        treatmentTemplateClinicId
        copiedTreatmentTemplateId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMassClinicByClinicIdSortedByDate = /* GraphQL */ `
  query ListMassClinicByClinicIdSortedByDate(
    $massClinicClinicId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMassClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMassClinicByClinicIdSortedByDate(
      massClinicClinicId: $massClinicClinicId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        massClinicClinicId
        date
        massClinicPatients {
          nextToken
        }
        isCancelled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStaffClinicReferenceByStaffId = /* GraphQL */ `
  query ListStaffClinicReferenceByStaffId(
    $staffId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClinicReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffClinicReferenceByStaffId(
      staffId: $staffId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        staffId
        clinicId
        clinicOwnerId
        staffTypes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStaffClinicReferenceByClinicId = /* GraphQL */ `
  query ListStaffClinicReferenceByClinicId(
    $clinicId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffClinicReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffClinicReferenceByClinicId(
      clinicId: $clinicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        staffId
        clinicId
        clinicOwnerId
        staffTypes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPatientsByLinkedReferralPatientId = /* GraphQL */ `
  query ListPatientsByLinkedReferralPatientId(
    $linkedReferralPatientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientsByLinkedReferralPatientId(
      linkedReferralPatientId: $linkedReferralPatientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listPatientsByClinicIdSortedByLastInteractionAt = /* GraphQL */ `
  query ListPatientsByClinicIdSortedByLastInteractionAt(
    $patientClinicId: ID
    $lastInteractionAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientsByClinicIdSortedByLastInteractionAt(
      patientClinicId: $patientClinicId
      lastInteractionAt: $lastInteractionAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listAssessmentsByClinicIdCreatedAt = /* GraphQL */ `
  query ListAssessmentsByClinicIdCreatedAt(
    $assessmentClinicId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByClinicIdCreatedAt(
      assessmentClinicId: $assessmentClinicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReferralsByReferralCenterIdSortedByCreatedAt = /* GraphQL */ `
  query ListReferralsByReferralCenterIdSortedByCreatedAt(
    $referralReferralCenterId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByReferralCenterIdSortedByCreatedAt(
      referralReferralCenterId: $referralReferralCenterId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listReferralsByReferralCenterIdSortedByStatusAndCreatedAt = /* GraphQL */ `
  query ListReferralsByReferralCenterIdSortedByStatusAndCreatedAt(
    $referralReferralCenterId: ID
    $statusCreatedAt: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByReferralCenterIdSortedByStatusAndCreatedAt(
      referralReferralCenterId: $referralReferralCenterId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listReferralsByReferringClinicIdSortedByStatusAndCreatedAt = /* GraphQL */ `
  query ListReferralsByReferringClinicIdSortedByStatusAndCreatedAt(
    $referringClinicId: ID
    $statusCreatedAt: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByReferringClinicIdSortedByStatusAndCreatedAt(
      referringClinicId: $referringClinicId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listReferralsByReferringClinicIdSortedByCreatedAt = /* GraphQL */ `
  query ListReferralsByReferringClinicIdSortedByCreatedAt(
    $referringClinicId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByReferringClinicIdSortedByCreatedAt(
      referringClinicId: $referringClinicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listReferralsByPatientIdSortedByCreatedAt = /* GraphQL */ `
  query ListReferralsByPatientIdSortedByCreatedAt(
    $referralPatientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByPatientIdSortedByCreatedAt(
      referralPatientId: $referralPatientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listReferralsByParentIdSortedByCreatedAt = /* GraphQL */ `
  query ListReferralsByParentIdSortedByCreatedAt(
    $parentReferralId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralsByParentIdSortedByCreatedAt(
      parentReferralId: $parentReferralId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        referringClinicId
        referralCenterClinicId
        referralReferralCenterId
        referralPatientId
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        priority
        openedAt
        status
        referralCenterResponse {
          comment
          responseTime
        }
        comment
        feedbackHistory
        createdAt
        updatedAt
        type
        parentReferralId
      }
      nextToken
    }
  }
`;
export const listByTypeId = /* GraphQL */ `
  query ListByTypeId(
    $typeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDataCacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTypeId(
      typeId: $typeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        dataCount
        type
        dataCacheClinicId
        typeId
        dateAndTypeId
        updateId
        createdAt
        sizeFull
        updatedAt
      }
      nextToken
    }
  }
`;
export const listByDateAndTypeId = /* GraphQL */ `
  query ListByDateAndTypeId(
    $dateAndTypeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDataCacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByDateAndTypeId(
      dateAndTypeId: $dateAndTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        dataCount
        type
        dataCacheClinicId
        typeId
        dateAndTypeId
        updateId
        createdAt
        sizeFull
        updatedAt
      }
      nextToken
    }
  }
`;
export const listByUpdateId = /* GraphQL */ `
  query ListByUpdateId(
    $updateId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDataCacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByUpdateId(
      updateId: $updateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        dataCount
        type
        dataCacheClinicId
        typeId
        dateAndTypeId
        updateId
        createdAt
        sizeFull
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionnaireRequest = /* GraphQL */ `
  query GetQuestionnaireRequest($id: ID!) {
    getQuestionnaireRequest(id: $id) {
      id
      email
      mobile
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
        displayedNameFormat
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      massClinicPatient {
        id
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        massClinicPatientClinicId
        massClinic {
          id
          massClinicClinicId
          date
          isCancelled
          createdAt
          updatedAt
        }
        isCancelled
        createdAt
        updatedAt
        questionnaireRequest {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          province
          country
          city
          postalCode
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        province
        country
        city
        postalCode
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      questionnaireRequestPatientId
      type
      response {
        answers
        scores
        consent {
          consentsToPrivacyForm
          consentsToResearch
          agreedToTerms
          consentSource
        }
      }
      country
      consentsToPrivacyForm
      consentSource
      consentsToResearch
      completedAt
      createdAt
      updatedAt
      nonModifiableData
      language
      config
    }
  }
`;
export const publicDrugLookup = /* GraphQL */ `
  query PublicDrugLookup($query: String!) {
    publicDrugLookup(query: $query)
  }
`;
export const getFormTemplate = /* GraphQL */ `
  query GetFormTemplate($id: ID!) {
    getFormTemplate(id: $id) {
      id
      schema
      formTemplateClinicId
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
          displayedNameFormat
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          questionnaireScoreConfig
          questionnaireNotificationsConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        hasDryEyePortalAccess
        createdAt
        updatedAt
        clinicFormTemplates {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;

import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Eye,
  SignWithAttachemnts,
  signGroups
} from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments-schema';
import { SignsAttachmentsService } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments.service';
import { Attachment } from 'src/app/shared/attachments/attachments.component';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import { symptomKeyFromFormKey } from '../../../form.model';
import { SchemaService } from '../../services/schema/schema.service';
import { ModalSymptomSimpleModalOptions } from '../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../abstract-symptom-modal.component';
import { MediaModalComponent } from '../media-modal/media-modal.component';

@Component({
  selector: 'csi-symptom-simple-modal',
  templateUrl: './symptom-simple-modal.component.html',
  styleUrls: ['./symptom-simple-modal.component.scss']
})
export class SymptomSimpleModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomSimpleModalOptions>
  implements OnInit {
  valueKeys: string[] = [];
  severityLabels: string[];
  public urls: string[];
  public attachedImage: Attachment;

  public compareFormControl: FormControl;
  public disableCompare = false;
  public hideCompare = false;
  public isMeibography = false;

  public pultImagesToggleFormControl: FormControl;
  public displayPultImages = false;

  constructor(
    public dialogRef: MatDialogRef<SymptomSimpleModalComponent>,
    private schemaService: SchemaService,
    private signsAttachmentsService: SignsAttachmentsService,
    private dialog: MatDialog,
    public dataSrcService: DataSrcService,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomSimpleModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    if (this.title === 'Meibography Upper' || this.title === 'Meibography Lower') {
      this.isMeibography = true;
    }
    if (this.modalConfig && this.modalConfig.values) {
      this.valueKeys = Object.keys(this.modalConfig.values);
    } else {
      this.valueKeys = [];
    }

    this.severityLabels =
      this.valueKeys.length > 4
        ? ['Normal', 'Mild', 'Mild to Moderate', 'Moderate to Severe', 'Severe']
        : ['Normal', 'Mild', 'Moderate', 'Severe'];

    this.attachedImage = this.getAttachedImage();
    this.disableCompare = this.attachedImage ? false : true;
    this.compareFormControl = new FormControl(!this.disableCompare);
    if (this.modalConfig) {
      this.urls = Object.values(this.modalConfig.media);
      if (this.modalConfig.media2) {
        this.pultImagesToggleFormControl = new FormControl(false);
        this.pultImagesToggleFormControl.valueChanges.subscribe(displayPultImages => {
          this.displayPultImages = displayPultImages;
        });
      }
    }
  }

  public openMeiboscale() {
    let fileName =
      this.title === 'Meibography Upper' ? 'meiboscale-upper.png' : 'meiboscale-lower.png';
    MediaModalComponent.open(
      this.dialog,
      `assets/images/modal-images/meibography/${fileName}`,
      'image',
      false,
      'courtesy of Dr Heiko Pult (https://heiko-pult.de/downloads-links.html)'
    );
  }

  public getAttachedImage() {
    if (this.config.formKey.indexOf('_') === -1) {
      this.hideCompare = true;
      return null;
    }
    const eye = this.config.formKey.substring(0, this.config.formKey.indexOf('_')).toLowerCase();
    const sign = this.config.formKey.substring(eye.length + 1);
    const signMetadata = this.signsAttachmentsService.generateMetadata(sign, eye as Eye).sign;
    let attachments = this.signsAttachmentsService.metadataToAttachmentsMap[signMetadata];

    Object.keys(signGroups).forEach(groupKey => {
      if (signGroups[groupKey].signs.includes(sign as SignWithAttachemnts)) {
        attachments = this.signsAttachmentsService.metadataToAttachmentsMap[
          this.signsAttachmentsService.generateMetadata(groupKey, eye as Eye).sign
        ];
      }
    });

    if (!this.signsAttachmentsService.signToFriendlyNameMap[sign]) {
      this.hideCompare = true;
    }

    if (attachments) {
      return attachments[0];
    }
  }

  public hasMedia(valueKey: string): boolean {
    return this.modalConfig.media && !!this.modalConfig.media[valueKey];
  }

  public hasMedia2(valueKey: string): boolean {
    return this.modalConfig.media2 && !!this.modalConfig.media2[valueKey];
  }

  public setValue(value: string) {
    if (!this.isDisabled) {
      this.formGroup.get(this.formKey).setValue(value);
    }
    this.dialogRef.close();
  }

  public simulateClick(event: KeyboardEvent) {
    (event.target as HTMLElement).click();
  }

  get shouldUseSingleColumnLayout(): boolean {
    return this.valueKeys.filter(key => this.hasMedia(key)).length > 5;
  }

  get shouldUseThreeColumnLayout(): boolean {
    return (
      this.valueKeys.filter(key => this.hasMedia(key)).length === 5 &&
      !this.modalConfig.useTwoColumns
    );
  }

  get isMediaOnlyModal(): boolean {
    return this.valueKeys.every(key => this.hasMedia(key));
  }

  get title(): string {
    return this.modalConfig.title
      ? this.modalConfig.title
      : this.schemaService.symptomMap[symptomKeyFromFormKey(this.formKey)].name;
  }

  get selectedValueIndex(): number {
    const key = this.formGroup.get(this.formKey) ? this.formGroup.get(this.formKey).value : null;
    return key ? this.valueKeys.indexOf(key) : -1;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  FalseOcularSurfaceDiseaseCondition,
  InterpretationConditions
} from '../interpretation.model';
import { InterpretationService } from '../interpretation.service';

@Component({
  selector: 'csi-false-ocular-surface-disease',
  templateUrl: './false-ocular-surface-disease.component.html',
  styleUrls: ['./false-ocular-surface-disease.component.css']
})
export class FalseOcularSurfaceDiseaseComponent implements OnInit {
  @Input() patientData;

  private _falseOcularSurfaceDiseaseCondition: FalseOcularSurfaceDiseaseCondition;
  @Input() set falseOcularSurfaceDiseaseCondition(
    falseOcularSurfaceDiseaseCondition: FalseOcularSurfaceDiseaseCondition
  ) {
    this._falseOcularSurfaceDiseaseCondition = falseOcularSurfaceDiseaseCondition;
    if (falseOcularSurfaceDiseaseCondition === 'Symptoms without Signs') {
      this.neuropathicEvidenceList = this.interpretationService.getEvidenceForConditionsThatMatch(
        this.patientData,
        InterpretationConditions.falseOcularSurfaceDisease.preClinicalorNeuropathic
      );
      this.preClinicalorNeuropathic =
        this.neuropathicEvidenceList.length > 0
          ? 'Consider Neuropathic Pain more than Pre-Clinical'
          : 'Consider Pre-clinical more than Neuropathic';
    } else {
      this.cornealSensationNormalEvidence = this.interpretationService.getEvidenceForConditionsThatMatch(
        this.patientData,
        InterpretationConditions.falseOcularSurfaceDisease.cornealSensationNormal
      );
    }
  }
  get falseOcularSurfaceDiseaseCondition() {
    return this._falseOcularSurfaceDiseaseCondition;
  }

  @Input() evidenceList: string[];

  public preClinicalorNeuropathic: string;
  public neuropathicEvidenceList: string[];
  public cornealSensationNormalEvidence: string[];

  constructor(private interpretationService: InterpretationService) {}

  ngOnInit() {}
}

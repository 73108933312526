import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AttachmentsModule } from '../shared/attachments/attachments.module';
import { ConsultFormsModule } from '../shared/consult-forms/consult-forms.module';
import { UpdateOnBlurModule } from '../shared/directives/update-on-blur/update-on-blur.module';
import { DryEyePDFExportModule } from '../shared/dry-eye-pdf-export/dry-eye-pdf-export.module';
import { FormErrorScrollingModule } from '../shared/form-error-scrolling/form-error-scrolling.module';
import { SharedAngularMaterialModule } from '../shared/shared-angular-material/shared-angular-material.module';
import { SharedPipesModule } from '../shared/shared-pipes/shared-pipes.module';
import { SharedModule } from '../shared/shared.module';
import { SymptomsModule } from '../shared/symptoms/symptoms.module';
import { AssessmentFormService } from './assessment-form.service';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentsTableComponent } from './encounter-dashboard/assessments-table/assessments-table.component';
import { GetQuestionnairePipe } from './encounter-dashboard/assessments-table/get-questionnaire.pipe';
import { IsQuestionnaireLinkedPipe } from './encounter-dashboard/assessments-table/is-questionnaire-linked.pipe';
import { EncounterDashboardComponent } from './encounter-dashboard/encounter-dashboard.component';
import { QuestionnairesTableComponent } from './encounter-dashboard/questionnaires-table/questionnaires-table.component';
import { EncountersCompareModalComponent } from './encounters-compare-modal/encounters-compare-modal.component';
import { LinkQuestionnaireToAssessmentModalComponent } from './link-questionnaire-to-assessment-modal/link-questionnaire-to-assessment-modal.component';
import { MobileImageWizardAttachmentsComponent } from './signs/mobile-image-wizard-attachments/mobile-image-wizard-attachments.component';
import { MobileImageWizardComponent } from './signs/mobile-image-wizard/mobile-image-wizard.component';
import { SignAttachmentsComponent } from './signs/sign-attachments/sign-attachments.component';
import { SignImageWizardModalComponent } from './signs/sign-image-wizard/sign-image-wizard-modal/sign-image-wizard-modal.component';
import { SignInstructionComponent } from './signs/sign-instruction/sign-instruction.component';
import { SignInstructionsModalComponent } from './signs/sign-instructions-modal/sign-instructions-modal.component';
import { SignMediaSelectionModalComponent } from './signs/sign-media-selection-modal/sign-media-selection-modal.component';
import { SignMediaUploadComponent } from './signs/sign-media-upload/sign-media-upload.component';
import { SignsAttachmentsModalComponent } from './signs/signs-attachments-modal/signs-attachments-modal.component';
import { SuccessModalComponent } from './signs/success-modal/success-modal.component';

@NgModule({
  declarations: [
    AssessmentComponent,
    EncounterDashboardComponent,
    AssessmentsTableComponent,
    QuestionnairesTableComponent,
    LinkQuestionnaireToAssessmentModalComponent,
    GetQuestionnairePipe,
    IsQuestionnaireLinkedPipe,
    EncountersCompareModalComponent,
    SignsAttachmentsModalComponent,
    SignMediaUploadComponent,
    SignInstructionComponent,
    SignAttachmentsComponent,
    SignMediaSelectionModalComponent,
    SignImageWizardModalComponent,
    MobileImageWizardComponent,
    MobileImageWizardAttachmentsComponent,
    SignInstructionsModalComponent,
    SuccessModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConsultFormsModule,
    RouterModule,
    SharedAngularMaterialModule,
    SharedPipesModule,
    UpdateOnBlurModule,
    FormErrorScrollingModule,
    SharedModule,
    DryEyePDFExportModule,
    AttachmentsModule,
    SymptomsModule
  ],
  providers: [AssessmentFormService],
  entryComponents: [
    LinkQuestionnaireToAssessmentModalComponent,
    EncountersCompareModalComponent,
    SignsAttachmentsModalComponent,
    SignMediaSelectionModalComponent,
    SignImageWizardModalComponent,
    SignInstructionsModalComponent,
    SuccessModalComponent
  ]
})
export class EncountersModule {}

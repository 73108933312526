import { Component, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CognitoUser } from '@aws-amplify/auth';
import { Logger } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { LoginError } from '../../../core/authentication/models/error-models/LoginError';
import {
  AuthenticatorModalComponent,
  AuthenticatorMode,
  matSpinnerOptions
} from '../authenticator-modal.component';

@Component({
  selector: 'csi-login',
  templateUrl: './login.component.html',
  styleUrls: ['../authenticator-modal.component.css', './login.component.css']
})
export class LoginComponent {
  @Input() loggedInUser: EventEmitter<CognitoUser>;
  @Input() authenticatorMode$: BehaviorSubject<AuthenticatorMode>;
  @Input() formGroup: FormGroup;

  public rememberDeviceControl = new FormControl(false);

  public didSubmit = false;
  public errorResponse: LoginError;
  public LoginError = LoginError;
  public AuthenticatorMode = AuthenticatorMode;
  public matSpinnerOptions = { ...matSpinnerOptions };

  private logger = new Logger('LoginComponent');

  constructor(
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<AuthenticatorModalComponent>
  ) {
    this.matSpinnerOptions.text = 'Sign In';
  }

  clearServerErrors() {
    this.errorResponse = undefined;
  }
  public submitLogin() {
    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;
      this.authenticationService
        .login(this.formGroup.value.email, this.formGroup.value.password)
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          loggedInUser => {
            loggedInUser['rememberDevice'] = this.rememberDeviceControl.value;
            this.loggedInUser.emit(loggedInUser);
            this.loggedInUser.complete();
            this.dialogRef.close();
          },
          (error: LoginError) => {
            if (error === LoginError.UserNotConfirmed) {
              this.authenticatorMode$.next(AuthenticatorMode.VerifyUser);
            } else {
              this.logger.error('Login failed', error);
              this.errorResponse = error;
            }
          }
        );
    }
  }
}

import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';
import { symptomMethodKey } from '../../form.model';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { SymptomMethodsPipe } from '../pipes/symptom-methods.pipe';
import { KeyValueMap } from '../services/schema/schema.model';
import { SymptomControlAdapterComponent } from '../symptom-control-adapter/symptom-control-adapter.component';
import { MethodSymptom, RadioSymptom, SymptomMethod, SymptomTypes } from '../symptom.model';

@Component({
  selector: 'csi-method-symptom-control',
  templateUrl: './method-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './method-symptom-control.component.css'
  ],
  providers: [
    SymptomMethodsPipe,
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => MethodSymptomControlComponent)
    }
  ]
})
export class MethodSymptomControlComponent extends AbstractSymptomControlComponent
  implements OnChanges {
  public readonly SymptomMethod = SymptomMethod;

  public readonly methodSymptom: RadioSymptom = {
    name: undefined,
    modes: undefined,
    type: SymptomTypes.Radio,
    values: undefined
  };

  @Input() symptom: MethodSymptom;
  @Input() formKey: string;
  @Input() formGroup: DryEyeFormGroup;

  @ViewChild(SymptomControlAdapterComponent, { static: false })
  selectedSymptomControlAdapter: SymptomControlAdapterComponent;

  constructor(
    protected symptomValidatorsService: SymptomValidatorsService,
    private symptomMethodsPipe: SymptomMethodsPipe
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.symptom && this.symptom) {
      this.methodSymptom.values = this.symptomMethodsPipe.transform(this.formKey) as KeyValueMap;
    }
  }

  get evaluationMethod(): SymptomMethod {
    return this.formGroup.get(this.methodKey).value;
  }

  get methodKey(): string {
    return symptomMethodKey(this.formKey);
  }

  public getAllPossibleSymptomControlKeys(): string[] {
    let keys: string[] = [this.methodKey];
    keys = keys.concat(super.getAllPossibleSymptomControlKeys());
    if (this.selectedSymptomControlAdapter) {
      keys = keys.concat(this.selectedSymptomControlAdapter.allPossibleSymptomControlKeys);
    }

    return keys;
  }
}

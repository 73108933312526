import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { SvgPipe } from '../../shared-pipes/svg.pipe';
import { SchemaService } from '../../symptoms/services/schema/schema.service';
import { SymptomMethod } from '../../symptoms/symptom.model';
import { ThermalScaleRangeConstraints } from '../../symptoms/thermal-scale/thermal-scale.directive';
import { ThermalScaleConfig } from '../../symptoms/thermal-scale/thermal-scale.model';

@Component({
  selector: 'csi-pdf-export-thermal-scale',
  templateUrl: './pdf-export-thermal-scale.component.html',
  styleUrls: ['./pdf-export-thermal-scale.component.css']
})
export class PdfExportThermalScaleComponent {
  @Input() showLabel = true;
  @Input() value: string | number;
  @Input() method: SymptomMethod;
  @Input() units: string;
  @Input() symptomKey: string;
  @Input() config: ThermalScaleConfig;
  @Input() lang: string;

  // tslint:disable-next-line:max-line-length
  @Input()
  thermaSvg = `<svg width="228" height="6" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1=".258%" y1="49.75%" x2="97.814%" y2="49.75%" id="a"><stop stop-color="#CF0000" offset="0%"/><stop stop-color="#FDC953" offset="47.525%"/><stop stop-color="#79BE00" offset="100%"/></linearGradient></defs><rect transform="rotate(-180 114 3)" width="228" height="6" rx="3" fill="url(#a)" fill-rule="evenodd"/></svg>`;

  // tslint:disable-next-line:max-line-length
  public readonly noThermaImage = this.inlinedSvg(
    `<svg width="228" height="6" xmlns="http://www.w3.org/2000/svg"><rect transform="rotate(-180 114 3)" width="228" height="6" rx="3" fill="#9B9B9B" fill-rule="nonzero" opacity=".1"/></svg>`
  );

  public readonly thermalScaleRangeConstraints: ThermalScaleRangeConstraints = {
    min: 0,
    max: 0.96
  };

  constructor(public schemaService: SchemaService, private svgPipe: SvgPipe) {}

  get thermalScaleConfig(): ThermalScaleConfig {
    return this.config
      ? this.config
      : this.schemaService.getThermalScaleConfig(this.symptomKey, this.method);
  }

  get thermaImage(): SafeUrl {
    return this.inlinedSvg(this.thermaSvg);
  }

  get blueArrowImage(): SafeUrl {
    // tslint:disable-next-line:max-line-length
    const blueArrowSvg = `<svg width="13" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 0L13 10H0z" fill="#3EB7DF" fill-rule="nonzero"/></svg>`;
    return this.inlinedSvg(blueArrowSvg);
  }

  private inlinedSvg(svg: string): SafeUrl {
    return this.svgPipe.transform(svg, true) as SafeUrl;
  }
}

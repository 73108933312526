import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'csi-import-wizard-drag-and-drop',
  templateUrl: './import-wizard-drag-and-drop.component.html',
  styleUrls: ['./import-wizard-drag-and-drop.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportWizardDragAndDropComponent implements OnInit {
  @Input() fileFormControl: FormControl;
  @Output() goToNextStep: EventEmitter<null> = new EventEmitter();
  @Output() cancel: EventEmitter<null> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  goToNext() {
    this.fileFormControl.markAsTouched();
    if (this.fileFormControl.valid) {
      this.goToNextStep.emit(null);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'csi-verification-input',
  templateUrl: './verification-input.component.html',
  styleUrls: ['./verification-input.component.css']
})
export class VerificationInputComponent implements OnInit {
  @Input() verificationCodeControl: FormControl;

  constructor() {}

  ngOnInit() {}
}

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, interval, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  S3AttachmentService,
  S3Object,
  UrlWithContentType
} from 'src/app/shared/attachments/s3-attachment.service';
import { NameFormat } from 'src/app/shared/shared-pipes/person-name.pipe';
import { InformationEntryMethod, ReferralFormGroup } from '../referral-form/referral-form.model';
import {
  postProcedureSchema,
  preProcedureSchema
} from '../referral-form/referral-signs/referral-signs-schema';
import { ReferralResponse } from 'src/API';
import { ReferralService } from '../referral.service';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
const componentStyle = require('../referral-pdf-export/referral-pdf-export.component.scss');
const patientComponentStyle = require('src/app/patients/patient/patient-card/patient-card.component.scss');

@Component({
  selector: 'csi-referral-pdf-export',
  templateUrl: './referral-pdf-export.component.html',
  styleUrls: ['./referral-pdf-export.component.scss']
})
export class ReferralPdfExportComponent implements OnInit {
  public readonly NameFormat = NameFormat;
  private _referral: ReferralFormGroup;
  private _clinicLogo: S3Object;
  public procedureSchema = { ...postProcedureSchema, ...preProcedureSchema };
  public InformationEntryMethod = InformationEntryMethod;
  clinicLogoDataUri: SafeUrl;

  public preloadedAttachments: UrlWithContentType[];
  public referralResponses: UrlWithContentType[];

  public pdfPages = [];

  public eyeMarkup: { left: string; right: string };
  public latestResponse: ReferralResponse;


  @Input('data')
  set data(value) {
    this._referral = value;
  }

  get referralFormGroup() {
    return this._referral;
  }

  @Input('clinicLogo')
  set clinicLogo(value: S3Object) {
    if (this.clinicLogo !== value) {
      this._clinicLogo = value;
      this.renderClinicLogo().subscribe();
    }
  }
  @Input('referralCenterResponse')
  set referralCenterResponse(value: UrlWithContentType[]) {
    this.referralResponses = value;
  }

  get clinicLogo(): S3Object {
    return this._clinicLogo;
  }

  constructor(
    private s3AttachmentService: S3AttachmentService,
    private hostElementRef: ElementRef,
    private referralService: ReferralService,
    public dataSrcService: DataSrcService
  ) {}


  ngOnInit() {
    this.latestResponse = this.referralService.getLatestReferralResponseForCurrentReferral();

  }

  get html(): Observable<string> {
    return interval(5000)
      .pipe(take(1))
      .pipe(
        map(() => {
          return this.innerHTMLWithStyle;
        })
      );
  }
  private render(): Observable<boolean> {

    return of(true);
  }

  private renderClinicLogo(): Observable<boolean> {
    return of(this.clinicLogo).pipe(
      switchMap(logo => (logo ? this.s3AttachmentService.s3ObjectToUri(logo) : of(null))),
      map(logoDataUri => {
        this.clinicLogoDataUri = logoDataUri;

        return true;
      })
    );
  }

  private get innerHTMLWithStyle(): string {
    return this.hostElementRef.nativeElement.innerHTML.replace(
      '</head>',
      `<style>${componentStyle.default} ${patientComponentStyle.default} </style></head>`
    );
  }

  noSort() {
    return 0;
  }
}

import { Component, EventEmitter, Inject, OnDestroy, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { AttributeToVerify } from './user-verification/user-verification.component';

export const matSpinnerOptions: MatProgressButtonOptions = {
  active: false,
  text: '',
  raised: true,
  buttonColor: 'primary',
  spinnerSize: 19,
  mode: 'indeterminate',
  fullWidth: true
};

export enum AuthenticatorMode {
  CheckUser,
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  VerifyUser
}

export interface AuthenticatorModalData {
  authenticatorMode: AuthenticatorMode;
  attributeToVerify?: AttributeToVerify;
  loggedInUser?: CognitoUser;
}

@Component({
  selector: 'csi-authenticator-modal',
  templateUrl: './authenticator-modal.component.html',
  styleUrls: ['./authenticator-modal.component.css']
})
export class AuthenticatorModalComponent implements OnDestroy {
  @Output() loggedInUser = new EventEmitter<CognitoUser>();
  @Output() isUserVerified = new EventEmitter<boolean>();

  public authenticatorMode$ = new BehaviorSubject<AuthenticatorMode>(AuthenticatorMode.Login);
  public AuthenticatorMode = AuthenticatorMode;

  public formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)])
  });

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AuthenticatorModalData
  ) {
    this.route.data.pipe(untilDestroyed(this)).subscribe(routeData => {
      if (routeData.authenticatorMode) {
        AuthenticatorModalComponent.open(this.dialog, {
          authenticatorMode: routeData.authenticatorMode
        });
      } else {
        this.authenticatorMode$.next(data.authenticatorMode);
      }
    });
  }

  ngOnDestroy() {}

  public static open(
    dialog: MatDialog,
    data: AuthenticatorModalData
  ): MatDialogRef<AuthenticatorModalComponent> {
    return dialog.open(AuthenticatorModalComponent, {
      disableClose: true,
      data: data,
      maxWidth: '28rem',
      panelClass: 'authenticator-modal'
    });
  }
}

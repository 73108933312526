import { QuestionGroup } from './speed-ii.model';

export class SPEEDIIService {
  public defaultScores = {
    Yes: 1,
    Sometimes: 1,
    Frequently: 1,
    Always: 1,
    today: 1,
    threeDays: 1,
    threeMonths: 1,
    eyeLidRedness: 1,
    crusting: 1,
    lidIrritation: 1,
    No: 0,
    Never: 0
  };

  speedIISchema: QuestionGroup = {
    title: 'SPEED (Standard Patient Evaluation of Eye) II Questionnaire.',
    questions: [
      {
        id: 'symptomsExperienced',
        title: 'Please check when you have experienced above symptoms:',
        fieldConfig: {
          type: 'Checkbox',
          options: {
            today: 'Today',
            threeDays: 'Within Past 72 hours',
            threeMonths: 'Within Past 3 months'
          }
        },
        group: {
          title: 'Report when the above symptoms were experienced',
          number: 3
        }
      },
      {
        id: 'usesEyeDropOintment',
        title: 'Do you use eye drops and/or ointments for lubrication?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Lubrication and fluctuating vision',
          number: 4
        }
      },
      {
        id: 'usesEyeDropOintment-optional',
        title: 'If yes, how often?',
        fieldConfig: {
          type: 'Text'
        },
        group: {
          title: 'Lubrication and fluctuating vision',
          number: 4
        },
        conditionConfig: {
          controlKey: 'usesEyeDropOintment',
          value: 'Yes',
          condition: 'equals'
        }
      },
      {
        id: 'fluctuatingVisionProblems',
        title: 'Do you have fluctuating vision?',
        fieldConfig: {
          type: 'Radio',
          options: ['Never', 'Sometimes', 'Frequently', 'Always']
        },
        group: {
          title: 'Lubrication and fluctuating vision',
          number: 4
        }
      },
      {
        id: 'fluctuatingVisionProblems-optional',
        title:
          'If yes, does the fluctuating vision improve with blinking and/or lubricating drops?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Lubrication and fluctuating vision',
          number: 4
        },
        conditionConfig: {
          controlKey: 'fluctuatingVisionProblems',
          value: 'Never',
          condition: 'notEquals'
        }
      },
      {
        id: 'hadBlepharitis',
        title: 'Have you been told you have blepharitis?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        }
      },
      {
        id: 'hadStye',
        title: 'Have you been treated for a stye?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        }
      },
      {
        id: 'otherSymptoms',
        title: 'Have you had any of these symptoms recently?',
        fieldConfig: {
          type: 'Checkbox',
          options: {
            eyeLidRedness: 'Eyelid Redness',
            crusting: 'Crusting around lashes',
            lidIrritation: 'Lid Irritation'
          }
        },
        group: {
          title: 'Other symptoms',
          number: 5
        }
      },
      {
        id: 'contactLens',
        title: 'Do you wear contact lenses?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        }
      },
      {
        id: 'contactLens-optional1',
        title: 'If yes, when was the last time you wore them?',
        fieldConfig: {
          type: 'Text'
        },
        group: {
          title: 'Other symptoms',
          number: 5
        },
        conditionConfig: {
          controlKey: 'contactLens',
          value: 'Yes',
          condition: 'equals'
        }
      },
      {
        id: 'contactLens-optional2',
        title: "If yes, do your eyes feel worse when they're on?",
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        },
        conditionConfig: {
          controlKey: 'contactLens',
          value: 'Yes',
          condition: 'equals'
        }
      },
      {
        id: 'eyeItch',
        title: 'Do your eyes itch?',
        fieldConfig: {
          type: 'Radio',
          options: ['Never', 'Sometimes', 'Frequently', 'Always']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        }
      },
      {
        id: 'eyeItch-optional',
        title: 'If yes, do you have known enviromental allergies or allergic conjunctivitis?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        },
        conditionConfig: {
          controlKey: 'eyeItch',
          value: 'Never',
          condition: 'notEquals'
        }
      },
      {
        id: 'symptomsSymmetric',
        title: 'Are your ocular symptoms symmetric between both eyes?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        },
        customScore: value => {
          return value === 'No' ? 1 : 0;
        }
      },
      {
        id: 'symptomsSymmetric-optional',
        title: 'If no, which eye is the most symptomatic?',
        fieldConfig: {
          type: 'Radio',
          options: ['Right', 'Left']
        },
        group: {
          title: 'Other symptoms',
          number: 5
        },
        conditionConfig: {
          controlKey: 'symptomsSymmetric',
          value: 'No',
          condition: 'equals'
        }
      },
      {
        id: 'glasses',
        title: 'Do you mind wearing glasses and/or contact lenses for improving your vision?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Glasses/ contact lens and personality',
          number: 6
        }
      },
      {
        id: 'glasses-optional',
        title:
          'If yes, would you be willing to pay out-of-pocket costs to reduce or elimminate your dependence on them?',
        fieldConfig: {
          type: 'Radio',
          options: ['Yes', 'No']
        },
        group: {
          title: 'Glasses/ contact lens and personality',
          number: 6
        },
        conditionConfig: {
          controlKey: 'glasses',
          value: 'Yes',
          condition: 'equals'
        }
      },
      {
        id: 'personality',
        title: 'On the following scale please describe your personality as best as you can?',
        fieldConfig: {
          type: 'Slider',
          minValue: 1,
          labels: ['Easy-going', '', '', '', '', '', '', '', '', 'Perfectionist'],
          maxValue: 10
        },
        group: {
          title: 'Glasses/ contact lens and personality',
          number: 6
        },
        customScore: value => {
          return value > 5 ? 1 : 0;
        }
      }
    ]
  };

  public customQuestions(speedQuestionIndex:string[]) {
    return this.speedIISchema.questions.reduce(( (acc,current) => {
      if (speedQuestionIndex.includes(current.id)) {
        acc.push(current);
      }
      return acc
    }), [])
  }


  public groupThreeQuestions = this.speedIISchema.questions.filter(
    question => question.group.number === 3
  );
  public groupFourQuestions = this.speedIISchema.questions.filter(
    question => question.group.number === 4
  );
  public groupFiveQuestions = this.speedIISchema.questions.filter(
    question => question.group.number === 5
  );
  public groupSixQuestions = this.speedIISchema.questions.filter(
    question => question.group.number === 6
  );
}

import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { Patient, PatientService } from 'src/app/core/api/patient.service';

@Component({
  selector: 'csi-patient-delete-dialog',
  templateUrl: './patient-delete-dialog.component.html',
  styleUrls: ['./patient-delete-dialog.component.css']
})
export class PatientDeleteDialogComponent implements OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<PatientDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Patient,
    private router: Router,
    private patientService: PatientService
  ) {}

  public isLoading = false;

  ngOnDestroy() {}

  public onConfirmClick() {
    // this.isLoading = true;
    // this.patientService
    //   .deletePatient(this.data.id)
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => {
    //     this.isLoading = false;
    //     this.dialogRef.close();
    //     this.router.navigate(['patients']);
    //   });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {
  transform(jsonObject: string): any {
    return JSON.parse(jsonObject);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { ClinicService } from 'src/app/core/api/clinic.service';
import { RemoteJsonFileService } from 'src/app/core/remote-json/remote-json-file.service';
import {
  HealthCardMode,
  Location,
  LocationHealthCardModeMap,
  Locations,
  RegionLabel
} from './location.model';

export interface LocationGroupByCountry {
  country: Location;
  regionLabel: RegionLabel;
  regions: Location[];
}

@Injectable({
  providedIn: 'root'
})
export class LocationSelectService extends RemoteJsonFileService {
  private countries: Locations = { list: [], label: 'Country' };
  private countryRegionsMap = {};

  private readonly LocationHealthCardModeMap = LocationHealthCardModeMap;

  constructor(httpClient: HttpClient, private clinicService: ClinicService) {
    super(httpClient, 'assets/locations.json?v=1');
    this.loadLocations().subscribe();
  }

  getCountries(): Observable<Locations> {
    return this.loadLocations().pipe(map(() => this.countries));
  }

  getCountriesWithStateOrProvince(): Observable<string[]> {
    return this.loadLocations().pipe(
      map(() =>
        this.countries.list
          .filter(country => {
            return this.countryRegionsMap[country.synonyms[0]].list.length !== 0;
          })
          .map(country => country.synonyms[0])
      )
    );
  }

  getRegionsForCountry(country: string): Observable<Locations> {
    return this.loadLocations().pipe(
      map(() => {
        const regions = this.countryRegionsMap[country];
        return regions ? regions : { list: [], label: '' };
      })
    );
  }

  getHealthCardConfigForClinic(): Observable<HealthCardMode> {
    return this.clinicService.getLoggedInClinicCountry().pipe(
      map(clinicCountry => {
        return this.LocationHealthCardModeMap[clinicCountry];
      })
    );
  }

  private loadLocations(): Observable<boolean> {
    return this.load<LocationGroupByCountry[]>(locations => {
      this.setLocations(locations);
    });
  }

  setLocations(locations: LocationGroupByCountry[]) {
    locations.forEach(location => {
      const country = location.country;
      this.countries.list.push({
        synonyms: country.synonyms,
        name: country.name
      });
      this.countryRegionsMap[country.synonyms[0]] = {
        list: location.regions,
        label: location.regionLabel
      };
    });
  }
}

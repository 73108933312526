import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import { QuestionnaireType } from './../../../../API';
import { StaffService } from './../../../core/api/staff.service';
import { PreOpService } from './../../../shared/consult-forms/dry-eye-forms/pre-op/pre-op.service';
import { KeyValueMap } from './../../../shared/symptoms/services/schema/schema.model';
import { SchemaService } from './../../../shared/symptoms/services/schema/schema.service';
import { CanHideSignPipe } from './signs-layout/can-hide-sign.pipe';

type QuestionnaireKeys = Exclude<
  QuestionnaireType,
  | QuestionnaireType.CDERFS
  | QuestionnaireType.DERFS
  | QuestionnaireType.OSDIAndSPEED
  | QuestionnaireType.CUSTOM
>;
export type SymptomsSetting = { [K in QuestionnaireKeys]: boolean };
export interface Layout {
  __typename?: any;
  id?: string;
  name?: string;
  medicalHistoryLayout?: FieldStatus;
  signsLayout?: FieldStatus;
  alwaysExpand?: string;
  customLayout?: { [key: string]: FieldStatus | string } | string;
  symptomSettings?: SymptomsSetting | string;
}

export interface FieldStatus {
  show?: string[];
  hide?: string[];
}

type FormatType = 'slider' | 'text' | 'radioCondition' | 'list' | 'medications';

interface FieldFormat {
  type: FormatType;
  transform?: (value) => {};
  sliderConfig?: {
    green: string;
    red: string;
  };
  listConfig?: {
    keys: string[];
    map: KeyValueMap;
  };
  toggleableTextFields?: KeyValueMap;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {
  defaultSymptomSettings: SymptomsSetting = {
    DEQ: true,
    SPEED: true,
    SPEEDII: true,
    OSDI: true
  };

  public layoutTabToDisclaimerMap: { [layout: string]: { [tab: string]: string } } = {
    [SymptomModes.Advanced]: {
      Symptoms: 'OSDI and SPEED questionnaires are required to use the Dry Eye Category Prediction.'
    },
    [SymptomModes.Silver]: {
      Symptoms: 'OSDI and SPEED questionnaires are required to use the Dry Eye Category Prediction.'
    },
    [SymptomModes.PreOpScreening]: {
      Symptoms:
        'SPEED questionnaire is required to use the Dry Eye Category Prediction for Pre-Op Screening.'
    },
    [SymptomModes.FollowUp]: {
      Symptoms: 'OSDI and SPEED questionnaires are required to use the Dry Eye Category Prediction.'
    },
    [SymptomModes.Treatment]: {
      Symptoms: 'OSDI and SPEED questionnaires are required to use the Dry Eye Category Prediction.'
    }
  };

  public defaultLayoutFields: { [key: string]: Layout } = {
    [SymptomModes.PreOpScreening]: {
      id: SymptomModes.PreOpScreening,
      name: 'Pre-Operative Screening',
      signsLayout: {
        show: ['ITBUT', 'NITBUT', 'OSMOLARITY', 'MEIBOGRAPHY'],
        hide: [
          'VA',
          'PRESSURE',
          'FLUORESCEIN_STAINING',
          'TEAR_MENISCUS_HEIGHT',
          'TEAR_MENISCUS_HEIGHT_IMAGING',
          'MMP9',
          'PARTIAL_BLINKING_FREQUENCY',
          'BLINKING_FREQUENCY_PER_MIN',
          'BLINK',
          'LID_APOSITION',
          'MEIBOGRAPHY_UPPER',
          'MEIBOGRAPHY_LOWER',
          'EPITHELIAL_THICKNESS',
          'LIPID_LAYER',
          'LIPID_LAYER_NASAL',
          'LIPID_LAYER_TEMPORAL',
          'CORNEAL_SENSATION',
          'CORNEAL_FINDINGS',
          'SCHIRMERS_TEST',
          'SUPERIOR_CONJUNCTIVA_CORNEAL_FINDINGS',
          'PHENOL_RED',
          'SMTUBE',
          'FLOPPY_EYELID_SYNDROME_GRADING',
          'LAGOPHTHALMUS',
          'LID_REDNESS',
          'COLLARETTES',
          'EYELID_TELANGIECTASIA',
          'CONJUNCTIVAL_CHALASIS',
          'CORNEAL_STAINING_PATTERN',
          'CORNEAL_STAINING_EXTENT',
          'LWE',
          'MGE_SECRETION',
          'MGE_EXPRESSIBILITY',
          'OTHER',
          'EYE_MARKUP',
          'MG_ORIFICE_CAPPED_GLANDS',
          'MG_ORIFICE_NOTCHES',
          'BULBAR_REDNESS',
          'LIMBAL_REDNESS'
        ]
      },
      symptomSettings: this.toggleSymptomSettings(
        [QuestionnaireType.DEQ, QuestionnaireType.SPEEDII, QuestionnaireType.OSDI],
        this.defaultSymptomSettings
      )
    },
    [SymptomModes.PreOp]: { id: SymptomModes.PreOp, name: 'ASCRS Pre-Op' },
    [SymptomModes.Silver]: {
      id: SymptomModes.Silver,
      name: 'Accelerated Dry Eye Assessment',
      signsLayout: {
        show: [
          'FLOPPY_EYELID_SYNDROME_GRADING',
          'LAGOPHTHALMUS',
          'PARTIAL_BLINKING_FREQUENCY',
          'BLINKING_FREQUENCY_PER_MIN',
          'BULBAR_REDNESS',
          'LIMBAL_REDNESS',
          'LID_REDNESS',
          'EYELID_TELANGIECTASIA',
          'COLLARETTES',
          'CORNEAL_STAINING_PATTERN',
          'FLUORESCEIN_STAINING',
          'TEAR_MENISCUS_HEIGHT_IMAGING',
          'CONJUNCTIVAL_CHALASIS',
          'MG_ORIFICE_CAPPED_GLANDS',
          'MG_ORIFICE_NOTCHES',
          'MGE_SECRETION',
          'MGE_EXPRESSIBILITY'
        ],
        hide: [
          'ITBUT',
          'NITBUT',
          'OSMOLARITY',
          'MEIBOGRAPHY',
          'VA',
          'PRESSURE',
          'TEAR_MENISCUS_HEIGHT',
          'MMP9',
          'BLINK',
          'LID_APOSITION',
          'MEIBOGRAPHY_UPPER',
          'MEIBOGRAPHY_LOWER',
          'EPITHELIAL_THICKNESS',
          'LIPID_LAYER',
          'LIPID_LAYER_NASAL',
          'LIPID_LAYER_TEMPORAL',
          'CORNEAL_SENSATION',
          'CORNEAL_FINDINGS',
          'SCHIRMERS_TEST',
          'SUPERIOR_CONJUNCTIVA_CORNEAL_FINDINGS',
          'PHENOL_RED',
          'SMTUBE',
          'CORNEAL_STAINING_EXTENT',
          'LWE',
          'OTHER',
          'EYE_MARKUP'
        ]
      }
    },
    [SymptomModes.Advanced]: { id: SymptomModes.Advanced, name: 'Gold Standard' },
    [SymptomModes.Treatment]: { id: SymptomModes.Treatment, name: 'Treatment' },
    [SymptomModes.FollowUp]: { id: SymptomModes.FollowUp, name: 'Follow Up' }
  };

  public assessmentMethodFriendlyName = {
    [SymptomModes.PreOpScreening]: 'Pre-Operative Screening',
    [SymptomModes.PreOp]: 'ASCRS Pre-Op',
    [SymptomModes.Simple]: 'Screening',
    [SymptomModes.Silver]: 'Accelerated Dry Eye Assessment',
    [SymptomModes.Advanced]: 'Gold Standard',
    [SymptomModes.Treatment]: 'Treatment',
    [SymptomModes.FollowUp]: 'Follow Up'
  };

  public medicalHistoryFields = {
    lastEyeExam: 'Last eye exam',
    historyOfPresentingIllness: 'History of presenting illness and triggering event',
    changesToEyes: 'Changes to eyes since any previous assessment',
    symptomDuration: 'Symptoms duration',
    autoImmuneConditions: 'Auto-Immune conditions',
    seasonalAllergies: 'Seasonal allergies',
    drugAllergies: 'Drug allergies',
    medications: 'Verified medications',
    currentOcularMedicationsFreeText: 'Current ocular medications and drops',
    pastOcularMedicationsFreeText: 'Past ocular medications and drops',
    patientEnteredMedicationsFreeText: 'Patient entered medications',
    workHoursPerDayFreeText: 'Hours of work per day',
    previousOcularSurgeryFreeText: 'Past ocular history',
    otherMedications: 'Nutraceuticals',
    exercise: 'Exercise per week',
    otherConditions: 'Other conditions',
    pastDryEyeTreatmentsFreeText: 'Past dry eye treatments',
    medicalConditions: 'Medical conditions',
    environmentalSymptoms: 'Frequent symtpoms at work or home',
    eyeItchiness: 'Eye itchiness',
    eyeTearingQuantity: 'Eye tearing quantity',
    dietQuality: 'Diet quality',
    healthQuality: 'Health quality',
    stressLevel: 'Stress level',
    cityHumidityLevel: 'City humidity level',
    cityPollutionLevel: 'City pollution level',
    sensitivityToLight: 'Sensitivity to light',
    sensitivityToWind: 'Sensitivity to wind',
    impactOfSymptomsOnMood: 'Impact of symptoms on mood',
    impactOfSymptomsOnLife: 'Impact of symptoms on daily life',
    symptomFrequency: 'Frequency of symptoms',
    symptomSeverity: 'Severity of symptoms',
    tearingQuality: 'Eye tearing quality',
    screenTime: 'Screen time / day (smartphones, computers, etc..)',
    bothersomeEye: 'Which eye bothers you?',
    sleepingPosition: 'Sleeping position',
    eyeRubbingFrequency: 'Eye rubbing frequency',
    symptomSeverityOccurrence: 'Symptom severity occurrence',
    sleepPerNight: 'Sleep per night',
    triggeringEvent: 'Triggering events',
    dietFreeText: 'Diet',
    caffeinatedDrinksPerDayFreeText: 'Caffeinated drinks per day',
    waterPerDayFreeText: 'Glasses of water per day',
    urbanAreaTimePerWeekFreeText: 'Percentage of time spent in urban areas',
    timeSpentOutdoorsFreeText: 'Time spent outdoors',
    timeSpentClosedEnvironmentsFreeText: 'Time spent in closed environments',
    faceCareAndMakeUpProductsFreeText: 'Face care/Make up products'
  };

  public allMedicalHistoryKeys = Object.keys(this.medicalHistoryFields);

  public medicalHistoryNormalizedFields: { [key: string]: FieldFormat } = {
    symptomDuration: {
      type: 'text',
      transform: value => value.total + ' months'
    },
    cityHumidityLevel: {
      type: 'slider',
      sliderConfig: { green: 'Very dry', red: 'Very humid' }
    },
    cityPollutionLevel: {
      type: 'slider',
      sliderConfig: { green: 'Not polluted', red: 'Very polluted' }
    },
    sensitivityToLight: {
      type: 'slider',
      sliderConfig: { green: 'Not polluted', red: 'Severe' }
    },
    sensitivityToWind: {
      type: 'slider',
      sliderConfig: { green: 'Not polluted', red: 'Severe' }
    },
    impactOfSymptomsOnMood: {
      type: 'slider',
      sliderConfig: { green: 'Not polluted', red: 'Severe' }
    },
    impactOfSymptomsOnLife: {
      type: 'slider',
      sliderConfig: { green: 'Not polluted', red: 'Severe' }
    },
    healthQuality: {
      type: 'slider',
      sliderConfig: { green: 'Good', red: 'Poor' }
    },
    dietQuality: {
      type: 'slider',
      sliderConfig: { green: 'Good', red: 'Poor' }
    },
    eyeItchiness: {
      type: 'slider',
      sliderConfig: { green: 'None', red: 'Severe' }
    },
    eyeTearingQuantity: {
      type: 'slider',
      sliderConfig: { green: 'No tears at all', red: 'Falls on cheek all the time' }
    },
    stressLevel: {
      type: 'slider',
      sliderConfig: { green: 'Not stressful', red: 'Very stressful' }
    },
    symptomFrequency: {
      type: 'slider',
      sliderConfig: { green: 'Rarely', red: 'All the time' }
    },
    symptomSeverity: {
      type: 'slider',
      sliderConfig: { green: 'Very mild', red: 'Very severe' }
    },
    bothersomeEye: {
      type: 'radioCondition'
    },
    sleepingPosition: {
      type: 'radioCondition'
    },
    eyeRubbingFrequency: {
      type: 'radioCondition'
    },
    screenTime: {
      type: 'radioCondition'
    },
    tearingQuality: {
      type: 'radioCondition'
    },
    symptomSeverityOccurrence: {
      type: 'radioCondition'
    },
    medicalConditions: {
      type: 'list',
      listConfig: {
        keys: this.schemaService.medicalConditionKeys,
        map: this.schemaService.medicalConditionMap
      },
      toggleableTextFields: {
        psychiatricConditionsFreeText: 'Psychiatric conditions',
        thyroidConditionFreeText: 'Thyroid disease',
        chronicPainConditionFreeText: 'Chronic widespread pain syndrome',
        vitaminDeficiencyConditionFreeText: 'Vitamin deficiency',
        sexSteroidDeficiencyConditionFreeText: 'Sex steroid deficiency',
        medicalConditionsFreeText: 'Other medical conditions'
      }
    },
    environmentalSymptoms: {
      type: 'list',
      listConfig: {
        keys: this.schemaService.environmentalSymptomKeys,
        map: this.schemaService.environmentalSymptomMap
      }
    },
    otherConditions: {
      type: 'list',
      listConfig: {
        keys: this.schemaService.checkboxConditionKeys,
        map: this.schemaService.checkboxConditionMap
      },
      toggleableTextFields: {
        otherConditionsFreeText: 'Duration / Type of contact lens'
      }
    }
  };

  public allLayoutsById: { [key: string]: Layout } = {};

  public layoutsFormGroup = new FormGroup({});

  constructor(
    private schemaService: SchemaService,
    private canHideSignPipe: CanHideSignPipe,
    private staffService: StaffService,
    private preOpService: PreOpService
  ) {
    if (
      this.staffService.staff &&
      this.staffService.staff.clinic &&
      this.staffService.staff.clinic.clinicSetup
    ) {
      this.setLayouts(this.staffService.staff.clinic.clinicSetup.layouts);
    }
  }

  setLayouts(layouts: Layout[]) {
    const layoutWithMissingDefaultValues = [...(layouts || [])];

    const layoutSet = new Set(layoutWithMissingDefaultValues.map(layout => layout.id));

    Object.values(this.defaultLayoutFields).forEach(defaultValue => {
      if (!layoutSet.has(defaultValue.id)) {
        layoutWithMissingDefaultValues.push(defaultValue);
      }
    });

    layoutWithMissingDefaultValues.forEach(layout => {
      delete layout.__typename;
      this.layoutsFormGroup.setControl(layout.id, new FormControl(layout));

      let symptomSettings =
        layout.symptomSettings ||
        (this.defaultLayoutFields[layout.id] &&
          this.defaultLayoutFields[layout.id].symptomSettings) ||
        this.defaultSymptomSettings;

      symptomSettings =
        typeof symptomSettings === 'string' ? JSON.parse(symptomSettings) : symptomSettings;

      this.allLayoutsById[layout.id] =
        layout.id !== SymptomModes.PreOp
          ? {
              ...layout,
              ...{
                medicalHistoryLayout: this.getAllApplicableMedicalHistoryFields(
                  layout.medicalHistoryLayout
                ),
                signsLayout: this.getAllApplicableSignFields(layout.id, layout.signsLayout),
                symptomSettings
              }
            }
          : ({
              ...layout,
              customLayout: this.getAllApplicablePreopFields(layout.customLayout as string)
            } as Layout);
    });
  }

  private getAllApplicablePreopFields(layout: string) {
    layout = (layout && JSON.parse(layout)) || {};
    return Object.entries(this.preOpService.defaultKeys).reduce(
      (applicableLayout, [key, defaultConfig]) => {
        return {
          ...applicableLayout,
          [key]: {
            show: (layout[key] && layout[key].show) || defaultConfig.keys,
            label: defaultConfig.label,
            hide:
              layout[key] && layout[key].hide
                ? [
                    ...layout[key].hide,
                    ...defaultConfig.keys.filter(
                      defaultKey =>
                        !layout[key].show.includes(defaultKey) &&
                        !layout[key].hide.includes(defaultKey)
                    )
                  ]
                : []
          }
        };
      },
      {}
    );
  }

  public getAllApplicableSignFields(
    encounterMethod: string,
    signsLayout: FieldStatus
  ): FieldStatus {
    if (!signsLayout) {
      return { show: this.schemaService.symptomKeys, hide: [] };
    }

    const hiddenRequiredFields = this.schemaService.symptomKeys.filter(
      symptomKey =>
        // Check if it is prevented from hiding
        (!this.canHideSignPipe.transform(
          this.schemaService.symptomMap[symptomKey],
          symptomKey,
          signsLayout.show,
          encounterMethod !== SymptomModes.Advanced
        ) ||
          // Check if it isn't a part of hidden list
          !signsLayout.hide.includes(symptomKey)) &&
        // Check if it isnt already a part of the list
        !signsLayout.show.includes(symptomKey)
    );

    const filterRemovedSymptomsFromShow = [
      ...signsLayout.show,
      ...hiddenRequiredFields
    ].filter(showSymptoms => this.schemaService.symptomKeys.includes(showSymptoms));

    return { show: filterRemovedSymptomsFromShow, hide: signsLayout.hide };
  }

  private getAllApplicableMedicalHistoryFields(medicalHistoryLayout: FieldStatus): FieldStatus {
    if (!medicalHistoryLayout) {
      return { show: this.allMedicalHistoryKeys, hide: [] };
    }

    // Filter removed items from show
    medicalHistoryLayout.show = [
      ...medicalHistoryLayout.show.filter(medicalHistoryField =>
        Object.keys(this.medicalHistoryFields).includes(medicalHistoryField)
      )
    ];
    // Make sure all the new fields that are added show by default
    const hide = medicalHistoryLayout.hide;
    const show = [
      ...medicalHistoryLayout.show,
      ...Object.keys(this.medicalHistoryFields).filter(
        medicalHistoryField =>
          !medicalHistoryLayout.show.includes(medicalHistoryField) &&
          !hide.includes(medicalHistoryField)
      )
    ];
    return { show, hide };
  }

  updateLayoutFormControlValue(formControl: FormControl, keyToUpdate: string, value: any) {
    formControl.setValue({ ...formControl.value, [keyToUpdate]: value });
    this.layoutsFormGroup.markAsDirty();
  }

  isTouchEnabled() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  toggleSymptomSettings(
    symptomKeys: QuestionnaireKeys[],
    symptomSettings: SymptomsSetting
  ): SymptomsSetting {
    const symptomSettingsCopy = JSON.parse(JSON.stringify(symptomSettings));

    symptomKeys.forEach(symptomKey => {
      symptomSettingsCopy[symptomKey] = !symptomSettingsCopy[symptomKey];
    });

    return symptomSettingsCopy;
  }

  getDisclaimer(layout: string, tab: string): string {
    if (this.layoutTabToDisclaimerMap[layout] && this.layoutTabToDisclaimerMap[layout][tab]) {
      return this.layoutTabToDisclaimerMap[layout][tab];
    } else {
      return '';
    }
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TextFavoriteSelectComponent } from './text-favorite-select/text-favorite-select.component';
import { TextFavoriteSetupActionsModalComponent } from './text-favorite-setup-actions-modal/text-favorite-setup-actions-modal.component';
import { TextFavoriteListComponent } from './text-favorite-setup/text-favorite-list/text-favorite-list.component';
import { TextFavoriteSetupComponent } from './text-favorite-setup/text-favorite-setup.component';
import { TextInputWithFavoriteComponent } from './text-input-with-favorite/text-input-with-favorite.component';
import { TreatmentTemplateSelectComponent } from './treatment-template-select/treatment-template-select.component';

@NgModule({
  declarations: [
    TextFavoriteSetupComponent,
    TextFavoriteSelectComponent,
    TextInputWithFavoriteComponent,
    TextFavoriteSetupActionsModalComponent,
    TreatmentTemplateSelectComponent,
    TextFavoriteListComponent
  ],
  entryComponents: [TextFavoriteSelectComponent, TextFavoriteSetupActionsModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule
  ],
  exports: [TextFavoriteSetupComponent, TextFavoriteSelectComponent, TextInputWithFavoriteComponent]
})
export class TextFavoriteModule {}

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import { CreateIntakeFormInput, IntakeFormStatus, QuestionnaireType } from 'src/API';
import { PatientFormGroup } from 'src/app/shared/patient-form/patient-form.model';
import { PatientService } from '../core/api/patient.service';
import { QuestionnaireRequestService } from '../core/api/questionnaire-request.service';
import { standard_intakeForm } from '../form-template/form-field-template-library';
import { FormTemplateInputFormGroup } from '../form-template/form-template-input-form-group';
import { FormTemplateSchema } from '../form-template/form-template-model';
import { allQuestionnairesScoringConfig } from '../questionnaires/custom-questionnaire/custom-questionnaire-scoring/custom-questionnaire-scoring-schema';
import { OSDIService } from '../questionnaires/osdi/osdi.service';
import { QuestionnaireRoutePath } from '../questionnaires/questionnaires-routing.module';
import { ConsentFormComponent } from '../questionnaires/simple-questionnaire/consent-form/consent-form.component';
import { SPEEDService } from '../questionnaires/speed/speed.service';
import { AutopopulateService } from '../shared/autopopulate/autopopulate.service';
import { FormErrorScrollingService } from '../shared/form-error-scrolling/form-error-scrolling.service';
import {
  allIntakeFormSchemas,
  generalButtonMap,
  IntakeFormGroupType,
  IntakeFormSchema,
  IntakeFormType,
  standardIntakeFormSchema
} from './intake-form-schema';
import { IntakeFormService } from './intake-form.service';

@Component({
  selector: 'csi-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.css'],
  providers: [SPEEDService, OSDIService]
})
export class IntakeFormComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  public sampleDonnellyIntakeFormData = {
    ocularHealthQuestion: {
      ocularHistoryList: {
        orthokeratology: null,
        pastContactLensDiscomfort: null,
        lasikOrPRK: null,
        wearsGlasses: true,
        keratoconus: null,
        amblyopia: null,
        nystagmus: null,
        otherPastOcularHistory: null,
        prisim: null,
        retinalConditions: null,
        hyperopia: null,
        dryEyes: null,
        previousEyeInfections: null,
        diplopia: null,
        pterygium: null,
        strabismus: null,
        presbyopia: null,
        myopia: null,
        eyeTrauma: null,
        cataracts: null
      }
    },
    medicalConditions: {
      medicalConditionList: {
        heatIllness: null,
        sexSteroidDeficiency: null,
        epilepsy: null,
        glaucoma: null,
        fertility: null,
        pelvicPain: null,
        coronaryArteryDisease: null,
        hypercholesterolemia: null,
        sclerosis: null,
        sjorgenSyndrome: null,
        eczema: null,
        graftHostDisease: null,
        liverDisease: null,
        androgenDeficiency: null,
        scleroderma: null,
        otherSkinConditions: null,
        htlv: null,
        vitiligo: null,
        asthma: null,
        sarcoidosis: null,
        dandruff: null,
        osteoporosis: null,
        environmentalAllergies: null,
        rheumaticFever: null,
        anxiety: true,
        'anxiety-yearOfDiagnosis': '222',
        demodexSkinInfection: null,
        arthritis: null,
        thyroid: null,
        psoriasis: null,
        gout: null,
        radiotherapy: null,
        geneticConditions: null,
        seborrheicDermatitis: null,
        pacemaker: null,
        psychiatricConditions: null,
        chronicPainSyndrome: null,
        heartDisease: null,
        rheumatoidArthritis: null,
        chronicOBstructivePulmonaryDisease: null,
        pigmentationChangeInFace: null,
        seasonalAllergies: null,
        vitaminDeficiency: null,
        irritableBowelSyndrome: null,
        lupus: null,
        heaterDermatitis: null,
        hivOrAids: null,
        fibromyalgia: null,
        seizureDisorder: null,
        keloidScars: null,
        bellsPalsy: null,
        conditionOther: null,
        renalFailure: null,
        coldSoresOrGentialHerpesOrShingles: null,
        migraine: null,
        sensationOfHeatCondition: null,
        rosacea: null,
        polycysticOvary: null,
        hepatitis: null,
        stroke: null,
        boneMarrow: null,
        drugAllergies: null,
        sunAllergy: null,
        abnormalBloodClotting: null,
        acne: null,
        accutaneTreatment: null,
        cancer: null,
        hypertension: null,
        diabetes: null,
        lungDisease: null
      }
    },
    osdiGeneralSymptomsTemplateGroup: {
      lightSensitivity: 1,
      blurredVision: 2,
      grittyFeeling: 2,
      painOrSoreness: 1,
      poorVision: 1
    },
    speedSeverityTemplateGroup: {
      burningWateringSeverity: 1,
      drynessGrittinessScratchinessSeverity: 1,
      eyeFatigueSeverity: 2,
      sorenessIrritationSeverity: 3
    },
    patientInformation: {
      firstName: 'Gray',
      lastName: 'Mok',
      address: '1 Apple Park Way',
      gender: 'male',
      phone: '+18259948230',
      healthCard: {
        number: '12',
        country: 'FR'
      },
      dateOfBirth: '2025-02-04T07:00:00.000Z',
      email: 'surya@csidryeye.com'
    },
    speedFrequencyTemplateGroup: {
      sorenessIrritationFrequency: 2,
      burningWateringFrequency: 1,
      eyeFatigueFrequency: 2,
      drynessGrittinessScratchinessFrequency: 0
    },
    patientGeneralHealthQuestion: {
      generalMedications: ['aspirin'],
      occupation: 'Opto',
      wearGlasses: 'no',
      lastEyeExam: '2025-02-05T07:00:00.000Z',
      hobbies: 'Running',
      'contactLenses-yes-contactType': 'soft',
      'contactLenses-yes-contactLensdaysPerWeek': 3,
      contactLenses: 'yes',
      'contactType-soft-contactLensDailyDisposable': 'yes',
      issueInEyeExam: 'no'
    },
    patientScreening: {
      lookingForRefractiveSurgery: 'no',
      referredBy: 'existingPatient'
    },
    osdiLimitedActivitiesTemplateGroup: {
      watchingTV: 2,
      drivingAtNight: 0,
      reading: 2,
      workingWithScreens: -1
    },
    osdiWeatherExacerbationsTemplateGroup: {
      lowHumidity: -1,
      airConditioned: -1,
      windyConditions: -1
    }
  };

  public standardIntakeFormData = {
    ocularHealthQuestion: {
      ocularHistoryList: {
        orthokeratology: null,
        pastContactLensDiscomfort: true,
        lasikOrPRK: true,
        wearsGlasses: true,
        keratoconus: null,
        amblyopia: true,
        nystagmus: null,
        otherPastOcularHistory: null,
        prisim: null,
        retinalConditions: false,
        hyperopia: null,
        dryEyes: true,
        previousEyeInfections: null,
        diplopia: null,
        pterygium: true,
        strabismus: null,
        presbyopia: true,
        myopia: null,
        eyeTrauma: null,
        cataracts: null
      }
    },
    medicalConditions: {
      medicalConditionList: {
        heatIllness: null,
        sexSteroidDeficiency: null,
        epilepsy: null,
        glaucoma: null,
        fertility: true,
        pelvicPain: null,
        coronaryArteryDisease: null,
        'fertility-yearOfDiagnosis': '2022',
        hypercholesterolemia: null,
        sclerosis: null,
        sjorgenSyndrome: null,
        eczema: null,
        'cancer-yearOfDiagnosis': '2202',
        graftHostDisease: null,
        liverDisease: null,
        androgenDeficiency: null,
        scleroderma: null,
        otherSkinConditions: null,
        htlv: null,
        vitiligo: null,
        asthma: null,
        sarcoidosis: null,
        dandruff: null,
        osteoporosis: null,
        environmentalAllergies: null,
        rheumaticFever: null,
        anxiety: null,
        demodexSkinInfection: null,
        arthritis: null,
        thyroid: null,
        psoriasis: null,
        gout: null,
        radiotherapy: null,
        geneticConditions: null,
        seborrheicDermatitis: null,
        pacemaker: null,
        psychiatricConditions: null,
        chronicPainSyndrome: null,
        heartDisease: null,
        rheumatoidArthritis: null,
        chronicOBstructivePulmonaryDisease: null,
        pigmentationChangeInFace: null,
        seasonalAllergies: false,
        vitaminDeficiency: null,
        irritableBowelSyndrome: null,
        lupus: null,
        heaterDermatitis: null,
        hivOrAids: null,
        fibromyalgia: null,
        seizureDisorder: null,
        keloidScars: null,
        bellsPalsy: null,
        conditionOther: null,
        renalFailure: null,
        coldSoresOrGentialHerpesOrShingles: null,
        migraine: true,
        sensationOfHeatCondition: null,
        rosacea: null,
        polycysticOvary: null,
        'migraine-yearOfDiagnosis': '2022',
        hepatitis: null,
        stroke: null,
        boneMarrow: null,
        drugAllergies: null,
        sunAllergy: null,
        abnormalBloodClotting: null,
        acne: null,
        accutaneTreatment: null,
        cancer: true,
        hypertension: null,
        diabetes: null,
        lungDisease: null
      }
    },
    osdiGeneralSymptomsTemplateGroup: {
      lightSensitivity: 0,
      blurredVision: 1,
      grittyFeeling: 1,
      painOrSoreness: 1,
      poorVision: 2
    },
    speedSeverityTemplateGroup: {
      burningWateringSeverity: 2,
      drynessGrittinessScratchinessSeverity: 0,
      eyeFatigueSeverity: 1,
      sorenessIrritationSeverity: 1
    },
    patientInformation: {
      firstName: 'Ray',
      lastName: 'Gun',
      address: '1 Apple Park Way',
      gender: 'female',
      phone: '+18259948230',
      menstruation: 'menopause',
      healthCard: {
        number: '1212',
        country: 'FR'
      },
      dateOfBirth: '2025-02-05T07:00:00.000Z',
      childBirth: 'yes',
      email: 'skusjanto+21@gmail.com'
    },
    speedFrequencyTemplateGroup: {
      sorenessIrritationFrequency: 2,
      burningWateringFrequency: 2,
      eyeFatigueFrequency: 4,
      drynessGrittinessScratchinessFrequency: 0
    },
    patientGeneralHealthQuestion: {
      generalMedications: ['tylenol', 'sdfsdf'],
      occupation: 'sdfesd',
      wearGlasses: 'yes',
      lastEyeExam: '2025-02-06T07:00:00.000Z',
      hobbies: 'dfsd',
      'contactLenses-yes-contactType': 'soft',
      'contactLenses-yes-contactLensdaysPerWeek': 1,
      contactLenses: 'yes',
      'contactType-soft-contactLensDailyDisposable': 'yes',
      issueInEyeExam: 'no'
    },
    patientScreening: {
      lookingForRefractiveSurgery: 'yes',
      astigmatism: 'no',
      willingnessToSurgery: 'yes',
      describeRefractiveSurgery: 'reduceDependency',
      visionProblemsAtNight: 'yes',
      referredBy: 'existingPatient',
      refractiveSurgeryRelatedConditions: {
        collagenVascularDisease: null,
        immunocompromised: null,
        autoimmuneDisease: null,
        diabetes: true,
        noConditions: null
      },
      prescriptionChanged: 'yes',
      enforcement: 'yes',
      slowHealing: 'no',
      refractiveError: 'farsighted'
    },
    osdiLimitedActivitiesTemplateGroup: {
      watchingTV: 1,
      drivingAtNight: 1,
      reading: 0,
      workingWithScreens: -1
    },
    osdiWeatherExacerbationsTemplateGroup: {
      lowHumidity: 2,
      airConditioned: -1,
      windyConditions: 0
    }
  };

  public formTemplateInputFormGroup: FormTemplateInputFormGroup;
  public schema = standard_intakeForm;
  private allQuestionnairesScoringConfig = allQuestionnairesScoringConfig;

  public submitFormControl = new FormControl();
  private clinicID: string;
  public country: string;

  public intakeFormSchema: IntakeFormSchema;
  public standardIntakeFormSchema = standardIntakeFormSchema;
  public intakeFormGroup = new FormGroup({});
  public IntakeFormGroupType = IntakeFormGroupType;
  public QuestionnaireType = QuestionnaireType;

  public groupChanges;
  public buttonMaps = generalButtonMap;
  private activatedRoute: ActivatedRoute;
  public intakeFormType: string;
  public loading = true;

  constructor(
    private intakeFormService: IntakeFormService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public speedService: SPEEDService,
    public osdiService: OSDIService,
    private router: Router,
    private patientService: PatientService,
    private translocoService: TranslocoService,
    private hostElementRef: ElementRef,
    private formErrorScrollingService: FormErrorScrollingService,
    private changeDetectorRef: ChangeDetectorRef,
    private autopopulateService: AutopopulateService,
    private questionnaireRequestService: QuestionnaireRequestService
  ) {}

  ngOnInit() {
    this.clinicID = this.route.snapshot.params.id;
    this.country = this.route.snapshot.queryParams.country || 'Canada';
    this.intakeFormType = this.route.snapshot.queryParams.type;
    if (
      Object.keys(this.intakeFormService.defaultIntakeFormTemplateSchemas).includes(
        this.intakeFormType
      )
    ) {
      this.loading = false;
      this.schema = this.intakeFormService.defaultIntakeFormTemplateSchemas[this.intakeFormType];
      this.formTemplateInputFormGroup = new FormTemplateInputFormGroup(
        this.schema as FormTemplateSchema
      );
      this.formTemplateInputFormGroup.addControl(
        'consentsToPrivacyForm',
        new FormControl(null, Validators.requiredTrue)
      );
    } else {
      this.intakeFormService.getFormTemplate(this.intakeFormType).subscribe(data => {
        this.schema = JSON.parse(data.schema);
        this.loading = false;
        this.formTemplateInputFormGroup = new FormTemplateInputFormGroup(
          this.schema as FormTemplateSchema
        );
        this.formTemplateInputFormGroup.addControl(
          'consentsToPrivacyForm',
          new FormControl(null, Validators.requiredTrue)
        );
      });
    }

    this.intakeFormSchema =
      allIntakeFormSchemas[this.intakeFormType] || allIntakeFormSchemas[IntakeFormType.STANDARD];

    Object.keys(this.intakeFormSchema.groups).forEach(key => {
      const group = this.intakeFormSchema.groups[key];
      if (group.type === IntakeFormGroupType.PatientCard) {
        const pGroup = new PatientFormGroup();
        pGroup.setValidators(this.patientService.contactValidator);
        this.intakeFormGroup.addControl(key, pGroup);
      } else {
        this.intakeFormGroup.addControl(key, this.generateFormGroup(group.config));
      }
    });
  }

  generateFormGroup(questionList: any) {
    const formGroup = new FormGroup({});
    Object.keys(questionList).forEach(question => {
      const validators = questionList[question].validators || null;
      const questions = questionList[question];
      if (questions.inputType.type === 'checkbox') {
        formGroup.addControl(question, new FormControl(false, validators));
      } else if (questions.inputType.type === 'checkboxGroup') {
        formGroup.addControl(
          question,
          new FormGroup(
            Object.keys(questions.inputType.checkboxes).reduce(
              (group, key) => ({ ...group, [key]: new FormControl() }),
              {}
            ),
            validators
          )
        );
      } else if (questions.inputType.type === 'slider') {
        formGroup.addControl(question, new FormControl(questions.inputType.step, validators));
      } else {
        formGroup.addControl(question, new FormControl(null, validators));
      }
    });
    formGroup.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.groupChanges = new Date();
        setTimeout(() => {
          setTimeout(() => {
            setTimeout(() => {
              this.changeDetectorRef.markForCheck();
            });
          });
        });
      });
    });
    return formGroup;
  }

  noSort() {
    return 0;
  }

  // submit() {
  //   if (this.submitFormControl.valid || true) {
  //     let questionnaire: Partial<QuestionnaireRequest> = {};
  //     if (
  //       this.intakeFormSchema.insertedQuestionnaire &&
  //       this.intakeFormSchema.insertedQuestionnaire === QuestionnaireType.OSDIAndSPEED
  //     ) {
  //       questionnaire = {
  //         response: {
  //           answers: JSON.stringify({
  //             [QuestionnaireType.SPEED]: this.speedService.formGroup.value,
  //             [QuestionnaireType.OSDI]: this.osdiService.formGroup.value
  //           }),
  //           scores: JSON.stringify({
  //             [QuestionnaireType.SPEED]: this.speedService.score,
  //             [QuestionnaireType.OSDI]: this.osdiService.score
  //           }),
  //           __typename: 'QuestionnaireRequestResponse'
  //         },
  //         type: QuestionnaireType.OSDIAndSPEED
  //       };
  //     }
  //     console.log('intake form group', this.intakeFormGroup.value);
  //     const formResponse = { ...this.intakeFormGroup.value, questionnaire };
  //     console.log('form response', formResponse);
  //     return;

  //     const input: CreateIntakeFormInput = {
  //       status: IntakeFormStatus.none,
  //       response: JSON.stringify(formResponse),
  //       intakeFormClinicId: this.clinicID
  //     };

  //     this.intakeFormService.createIntakeFromInput(input).subscribe(
  //       () => this.redirectToSuccessPage(),
  //       (error: ApolloError) => {
  //         console.log(error);
  //         window.alert('We have encountered an error, please contact support@csidryeye.com.');
  //       }
  //     );
  //   }
  // }

  onSubmit() {
    this.formTemplateInputFormGroup.markAllAsTouched();
    if (this.formTemplateInputFormGroup.valid) {
      this.formTemplateInputFormGroup.controls['consentsToPrivacyForm'].disable();
      const response = {
        answers: this.formTemplateInputFormGroup.value,
        scores: this.getScores()
      };
      const input: CreateIntakeFormInput = {
        status: IntakeFormStatus.none,
        response: JSON.stringify(response),
        config: JSON.stringify({
          schema: this.schema
        }),
        intakeFormClinicId: this.clinicID
      };
      this.formTemplateInputFormGroup.disable();

      this.intakeFormService.createIntakeFromInput(input).subscribe(
        () => this.redirectToSuccessPage(),
        (error: ApolloError) => {
          console.log(error);
          window.alert('We have encountered an error, please contact support@csidryeye.com.');
        }
      );
    }
  }

  getScores() {
    const scores = this.allQuestionnairesScoringConfig.reduce((acc, questionnaireScoringConfig) => {
      // scoring calculator will return NaN if there are any missing keys
      const score = questionnaireScoringConfig.scoringCalculator(
        this.autopopulateService.flattenByOneLevel(this.formTemplateInputFormGroup.value),
        questionnaireScoringConfig.questionnaireKeys
      );
      // scores should not be negative
      if (score >= 0) {
        acc = {
          ...acc,
          [questionnaireScoringConfig.questionnaireName]: score
        };
      }
      return acc;
    }, {});

    return scores;
  }

  openPrivacyConsentForm(event) {
    event.preventDefault();
    this.dialog.open(ConsentFormComponent, {
      data: { country: this.country }
    });
  }

  private redirectToSuccessPage() {
    // let isPositive = false;
    // let hideVideo = true;
    // if (osdiScore && speedScore) {
    //   hideVideo = false;
    //   const scores = JSON.stringify({
    //     [QuestionnaireType.OSDI]: osdiScore,
    //     [QuestionnaireType.SPEED]: speedScore
    //   });

    //   isPositive = this.questionnaireRequestService.isAtRisk(scores);
    // }

    this.router.navigate([`questionnaires/${QuestionnaireRoutePath.ThankYou}`], {
      relativeTo: this.activatedRoute,
      queryParams: {
        isPositive: false,
        hideVideo: true,
        messageToShow: 'thankYouForSubmittingIntake',
        lang: this.translocoService.getActiveLang()
      }
    });
  }

  next(formGroup: FormGroup) {
    setTimeout(() => {
      formGroup.markAllAsTouched();
      formGroup.updateValueAndValidity();
      if (formGroup.valid) {
        this.stepper.next();
      } else {
        this.formErrorScrollingService.scrollToFirstInvalidControlInView(
          this.hostElementRef,
          true,
          {
            isHostScrollable: true,
            isHostMatStep: true
          }
        );
      }
    });
  }
}

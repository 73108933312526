import { HostListener } from '@angular/core';

export abstract class ComponentCanDeactivate {
  private debouncing = false;
  private readonly debounceTimer = 100;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  public canDeactivate(): boolean {
    if (!this.debouncing) {
      return this._canDeactivate();
    } else {
      return true;
    }
  }

  public activateDebounce() {
    this.debouncing = true;
    setTimeout(() => (this.debouncing = false), this.debounceTimer);
  }

  protected abstract _canDeactivate(): boolean;
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAccordion, MatSnackBar } from '@angular/material';
import { Assessment, Drug } from 'src/API';
import { AssessmentService } from '../core/api/assessment.service';
import { LEFT_EYE_KEY_PREFIX, RIGHT_EYE_KEY_PREFIX } from '../shared/form.model';
import { DrugFormat, DrugPipe } from '../shared/medication-lookup/drug.pipe';
import { SchemaService } from '../shared/symptoms/services/schema/schema.service';
import { SideEffectsPipe } from './../shared/medication-lookup/side-effects.pipe';

interface Sign {
  leftValue: string;
  rightValue: string;
  friendlyName: string;
}

@Component({
  selector: 'csi-copy-to-emr',
  templateUrl: './copy-to-emr.component.html',
  styleUrls: ['./copy-to-emr.component.scss'],
  providers: [DrugPipe]
})
export class CopyToEmrComponent implements OnInit {
  @ViewChild(MatAccordion, { static: false }) private accordion: MatAccordion;
  @ViewChild('impressionsId', { static: false }) public impressionsRef: ElementRef;
  @ViewChild('goalsId', { static: false }) public goalsRef: ElementRef;

  @Input() set assessment(assessment: Assessment) {
    this.selectedAssessment(assessment);
  }
  @Input() assessmentMethod: string;
  @Input() showAssessmentList: boolean;

  public readonly DrugFormat = DrugFormat;
  public parsedBody: { [x: string]: { [x: string]: any } };
  public normalizedValue = { dryEyeForm: {} };
  public drugs: Drug[];
  public allSelectFieldsString: string;
  public goals = '';
  public impressions = '';
  public expansionPanelClass = {
    'mat-expansion-wrapper-modal': {
      overflow: 'auto',
      height: '64vh'
    },
    'mat-expansion-wrapper': {
      overflow: 'auto',
      height: '74vh'
    }
  };
  public recommendationsFromDoctor: {
    plan: any[];
    exercises: string[];
    eyeDrops: string[];
    rx: string[];
    procedure: string[];
    treatmentPlan?: string[];
    _allTreatments?: string[];
  };

  public fieldsToSkip = ['EYE_MARKUP'];
  public assessmentCreatedAt: Date;

  public readonly encounterMethodFriendlyName = {
    advanced: 'Gold Standard',
    silver: ' Accelerated Dry Eye Assessment ',
    preOpScreening: 'Pre-Operative Screening',
    simple: 'Screening',
    preOp: 'ASCRS Pre-Op',
    preop: 'ASCRS Pre-Op',
    treatment: 'Treatment',
    followUp: 'Follow Up'
  };

  public selectedAssessmentFieldsToCopy = new FormGroup({
    all: new FormControl(''),
    medications: new FormControl(''),
    signs: new FormControl(''),
    impressions: new FormControl(''),
    goals: new FormControl(''),
    recommendations: new FormControl('')
  });

  public noSelectedAssessmentField = true;

  constructor(
    public schemaService: SchemaService,
    private _snackBar: MatSnackBar,
    public assessmentService: AssessmentService,
    private sideEffectsPipe: SideEffectsPipe,
    private drugPipe: DrugPipe
  ) {}

  ngOnInit() {
    this.selectedAssessmentFieldsToCopy.valueChanges.subscribe(() => {
      this.noSelectedAssessmentField = !Object.keys(
        this.selectedAssessmentFieldsToCopy.controls
      ).some(controlKey => {
        return this.selectedAssessmentFieldsToCopy.controls[controlKey].value === true;
      });
    });
  }

  accordionExpansion() {
    this.accordion.openAll();
  }

  accordionCollapse() {
    this.accordion.closeAll();
  }

  selectedAssessment(assessment: Assessment) {
    this.initializeAllCopyDataAndForms();

    this.assessmentCreatedAt = new Date(assessment.createdAt);

    this.parsedBody = JSON.parse(assessment.body);

    this.drugs = this.parsedBody.medicalHistoryForm.medications;

    this.assessmentMethod =
      this.parsedBody && this.parsedBody.dryEyeForm && this.parsedBody.dryEyeForm.assessmentMethod
        ? this.parsedBody.dryEyeForm.assessmentMethod
        : '';

    this.initializeGoals();
    this.initializeImpressions();
    this.initializeRecommendations();

    this.addNormalizedSignValues();
    this.addCheckboxesControls();
  }

  initializeAllCopyDataAndForms() {
    this.resetFormGroup();

    this.normalizedValue = { dryEyeForm: {} };
    this.recommendationsFromDoctor = {
      plan: [],
      exercises: [],
      eyeDrops: [],
      rx: [],
      procedure: [],
      treatmentPlan: [],
      _allTreatments: []
    };
    this.impressions = '';
    this.goals = '';
    this.allSelectFieldsString = '';
  }

  resetFormGroup() {
    Object.keys(this.selectedAssessmentFieldsToCopy.controls).forEach(controlKey => {
      this.selectedAssessmentFieldsToCopy.controls[controlKey].setValue(null);
      this.selectedAssessmentFieldsToCopy.controls[controlKey].enable();
    });
  }

  initializeGoals() {
    this.goals =
      this.parsedBody.assessmentForm &&
      this.parsedBody.assessmentForm.goalsForPatient &&
      this.parsedBody.assessmentForm.goalsForPatient.replaceAll('\n\n', '<br>').trim();
  }

  initializeImpressions() {
    if (this.assessmentMethod !== 'preop') {
      this.impressions =
        this.parsedBody.assessmentForm &&
        this.parsedBody.assessmentForm.impressions &&
        this.parsedBody.assessmentForm.impressions.replaceAll('\n\n', '<br>').trim();
    } else {
      this.impressions = this.getPreOpImpressions(this.parsedBody.dryEyeForm);
    }
  }

  initializeRecommendations() {
    if (this.parsedBody.assessmentForm) {
      this.recommendationsFromDoctor = {
        plan:
          this.parsedBody.assessmentForm &&
          this.parsedBody.assessmentForm.recommendedPlanByDoctor &&
          this.getPlans(this.parsedBody.assessmentForm.recommendedPlanByDoctor),

        exercises:
          this.parsedBody.assessmentForm.recommendedExercises &&
          this.getExercises(this.parsedBody.assessmentForm.recommendedExercises),
        eyeDrops:
          this.parsedBody.assessmentForm.recommendedEyeDrops &&
          this.getEyeDrops(this.parsedBody.assessmentForm.recommendedEyeDrops),
        rx:
          this.parsedBody.assessmentForm.recommendedRxEyeDrops &&
          this.getRx(this.parsedBody.assessmentForm.recommendedRxEyeDrops),

        procedure:
          this.parsedBody.assessmentForm.recommendedProcedures &&
          this.getProcedures(this.parsedBody.assessmentForm.recommendedProcedures),
        treatmentPlan: this.getTreatmentPlan()
      };

      this.recommendationsFromDoctor._allTreatments = Object.values(
        this.recommendationsFromDoctor
      ).reduce((acc, treatment) => {
        return [...acc, ...(treatment || [])];
      }, []);
    }
  }

  stripHtml(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  addCheckboxesControls() {
    Object.keys(this.normalizedValue.dryEyeForm).forEach(sign => {
      this.selectedAssessmentFieldsToCopy.addControl(sign, new FormControl(null));
    });

    Object.keys(this.recommendationsFromDoctor).forEach(recommendation => {
      this.selectedAssessmentFieldsToCopy.addControl(recommendation, new FormControl(null));
    });
  }

  addNormalizedSignValues() {
    this.schemaService.symptomKeys.forEach(sign => {
      const rightKey = RIGHT_EYE_KEY_PREFIX + sign;
      const leftKey = LEFT_EYE_KEY_PREFIX + sign;

      const [rightValue, leftValue] = [rightKey, leftKey].map(key =>
        this.schemaService.getFriendlyValueName(
          this.parsedBody['dryEyeForm'] && this.parsedBody['dryEyeForm'][key],
          sign
        )
      );
      if (rightValue !== 'N/A' || leftValue !== 'N/A') {
        this.normalizedValue.dryEyeForm = {
          ...this.normalizedValue.dryEyeForm,
          [sign]: {
            leftValue,
            rightValue,
            friendlyName: this.schemaService.symptomMap[sign].name
          }
        };
      }
    });
    this.fieldsToSkip.forEach(fieldToSkip => {
      delete this.normalizedValue.dryEyeForm[fieldToSkip];
    });
  }

  selectAll() {
    if (!this.selectedAssessmentFieldsToCopy.controls.all.value) {
      if (!this.selectedAssessmentFieldsToCopy.controls.signs.value) {
        this.selectAllSigns();
      }
      if (!this.selectedAssessmentFieldsToCopy.controls.recommendations.value) {
        this.selectAllRecommendations();
      }

      this.selectedAssessmentFieldsToCopy.controls.medications.setValue(true);
      this.selectedAssessmentFieldsToCopy.controls.signs.setValue(true);
      this.selectedAssessmentFieldsToCopy.controls.recommendations.setValue(true);
      this.selectedAssessmentFieldsToCopy.controls.impressions.setValue(true);
      this.selectedAssessmentFieldsToCopy.controls.goals.setValue(true);

      this.selectedAssessmentFieldsToCopy.controls.medications.disable();
      this.selectedAssessmentFieldsToCopy.controls.signs.disable();
      this.selectedAssessmentFieldsToCopy.controls.recommendations.disable();
      this.selectedAssessmentFieldsToCopy.controls.impressions.disable();
      this.selectedAssessmentFieldsToCopy.controls.goals.disable();
    }

    if (this.selectedAssessmentFieldsToCopy.controls.all.value) {
      this.selectedAssessmentFieldsToCopy.controls.signs.enable();
      this.selectedAssessmentFieldsToCopy.controls.recommendations.enable();

      this.selectAllSigns();
      this.selectAllRecommendations();
      this.selectedAssessmentFieldsToCopy.controls.medications.setValue(false);
      this.selectedAssessmentFieldsToCopy.controls.signs.setValue(false);
      this.selectedAssessmentFieldsToCopy.controls.recommendations.setValue(false);
      this.selectedAssessmentFieldsToCopy.controls.impressions.setValue(false);
      this.selectedAssessmentFieldsToCopy.controls.goals.setValue(false);

      this.selectedAssessmentFieldsToCopy.controls.medications.enable();
      this.selectedAssessmentFieldsToCopy.controls.impressions.enable();
      this.selectedAssessmentFieldsToCopy.controls.goals.enable();
    }
  }

  selectAllSigns() {
    if (this.selectedAssessmentFieldsToCopy.controls.signs.disabled) return;
    Object.entries(this.normalizedValue.dryEyeForm).forEach(([signKey, sign]: [string, Sign]) => {
      if (!this.selectedAssessmentFieldsToCopy.controls.signs.value) {
        this.selectedAssessmentFieldsToCopy.controls[signKey].setValue(true);
        this.selectedAssessmentFieldsToCopy.controls[signKey].disable();
      }

      if (this.selectedAssessmentFieldsToCopy.controls.signs.value) {
        this.selectedAssessmentFieldsToCopy.controls[signKey].setValue(false);
        this.selectedAssessmentFieldsToCopy.controls[signKey].enable();
      }
    });
  }

  selectAllRecommendations() {
    if (this.selectedAssessmentFieldsToCopy.controls.recommendations.disabled) return;
    Object.keys(this.recommendationsFromDoctor).forEach(recommendationKey => {
      if (!this.selectedAssessmentFieldsToCopy.controls.recommendations.value) {
        this.selectedAssessmentFieldsToCopy.controls[recommendationKey].setValue(true);
        this.selectedAssessmentFieldsToCopy.controls[recommendationKey].disable();
      }

      if (this.selectedAssessmentFieldsToCopy.controls.recommendations.value) {
        this.selectedAssessmentFieldsToCopy.controls[recommendationKey].setValue(false);
        this.selectedAssessmentFieldsToCopy.controls[recommendationKey].enable();
      }
    });
  }

  getMedicationsText() {
    let medicationText = '';
    if (this.drugs) {
      medicationText += 'MEDICATIONS: \n';

      this.drugs.forEach(drug => {
        let formattedMedication = `- ${this.drugPipe.transform(
          drug,
          DrugFormat.Name
        )} (${this.drugPipe.transform(drug, DrugFormat.ActiveIngredients)})`;

        const sideEffects$ = this.sideEffectsPipe.transform(drug);
        sideEffects$.subscribe(sideEffectsDetails => {
          if (sideEffectsDetails) {
            medicationText += `${formattedMedication}\n * May cause (${sideEffectsDetails})\n`;
          } else {
            medicationText += `${formattedMedication}\n`;
          }
        });
      });

      return medicationText;
    }
  }

  getPreOpImpressionsText() {
    let impressionsText = 'IMPRESSIONS: \n';
    const content = this.impressionsRef.nativeElement.textContent;

    // Process each line, and add '*' to each line that is not empty
    impressionsText += content
      .split('\n')
      .filter(line => line.trim()) // Filter out empty lines
      .map(line => `* ${line.trim()}`) // Add '*' to each line
      .join('\n');

    impressionsText += '\n\n';
    return impressionsText;
  }

  getImpressionsText() {
    let impressionsText = '';
    impressionsText += 'IMPRESSIONS: \n';
    const content = this.impressionsRef.nativeElement.textContent;

    if (!content) return '';
    // Process only the content after the "Goals" header
    impressionsText += content
      .split('*')
      .filter(Boolean)
      .map(item => `* ${item.trim()}`)
      .join('\n');
    impressionsText += '\n\n';
    return impressionsText;
  }

  getGoalsText() {
    let goalsText = '';
    if (this.goalsRef) {
      goalsText += 'GOALS:\n'; // Keep the header intact
      const content = this.goalsRef.nativeElement.textContent; // Get the actual content

      if (!content) return '';

      // Process only the content after the "Goals" header
      goalsText += content
        .split('*')
        .filter(Boolean)
        .map(item => `* ${item.trim()}`)
        .join('\n');

      goalsText += '\n\n';
      return goalsText;
    }
  }

  getRecommendationText(recommendationType: string, recommendationKey: string) {
    recommendationKey = recommendationKey === 'eyedrops' ? 'EYE DROPS' : recommendationKey;
    let recommendationText = `${recommendationKey}\n`;
    const recommendationData = this.recommendationsFromDoctor[recommendationType];

    if (recommendationData && recommendationData.length > 0) {
      recommendationData.forEach((recommendation: string) => {
        recommendationText += `• ${recommendation.trim()}\n`;
      });
    }

    return recommendationText.trim();
  }

  copySelected() {
    this.allSelectFieldsString = '';
    let signIsSelected = false;

    if (this.selectedAssessmentFieldsToCopy.controls.medications.value) {
      if (this.getMedicationsText()) {
        this.allSelectFieldsString += this.getMedicationsText() + '\n';
      }
    }

    // Check if signs or any specific sign field is selected
    if (this.selectedAssessmentFieldsToCopy.controls.signs.value) {
      this.allSelectFieldsString += 'SIGNS: \n';
      signIsSelected = true; // Set to true if signs exist
    }

    Object.entries(this.normalizedValue.dryEyeForm).forEach(([signKey, sign]: [string, Sign]) => {
      if (
        this.selectedAssessmentFieldsToCopy.controls[signKey] &&
        this.selectedAssessmentFieldsToCopy.controls[signKey].value
      ) {
        if (!signIsSelected) {
          // If signs weren't previously added, add "Signs: \n" here
          this.allSelectFieldsString += 'SIGNS: \n';
          signIsSelected = true;
        }
        this.allSelectFieldsString +=
          sign.friendlyName +
          ' Right Eye: ' +
          (sign.rightValue || 'N\\A') +
          ' Left Eye: ' +
          (sign.leftValue || 'N\\A') +
          '\n';
      }
    });

    // Add newline after all signs are processed if any sign was selected
    if (signIsSelected) {
      this.allSelectFieldsString += '\n';
    }

    if (this.selectedAssessmentFieldsToCopy.controls.impressions.value) {
      if (this.assessmentMethod !== 'preop') {
        this.allSelectFieldsString += this.getImpressionsText() || '';
      } else {
        this.allSelectFieldsString += this.getPreOpImpressionsText() || '';
      }
    }

    if (this.selectedAssessmentFieldsToCopy.controls.goals.value && this.getGoalsText()) {
      this.allSelectFieldsString += this.getGoalsText();
    }

    if (
      (this.recommendationsFromDoctor._allTreatments || []).length > 0 &&
      this.selectedAssessmentFieldsToCopy.controls.recommendations.value
    ) {
      this.allSelectFieldsString += 'RECOMMENDATIONS: ' + '\n';
    }
    let recommendationIsSelected = false;

    if (this.assessmentMethod !== 'preop') {
      Object.entries(this.recommendationsFromDoctor).forEach(
        ([recommendationKey, recommendationArray]: [string, string[]]) => {
          if (
            (recommendationArray || []).length > 0 &&
            this.selectedAssessmentFieldsToCopy.controls[recommendationKey].value &&
            !['treatmentPlan', '_allTreatments'].includes(recommendationKey)
          ) {
            recommendationIsSelected = true;
            this.allSelectFieldsString +=
              this.getRecommendationText(recommendationKey, recommendationKey.toUpperCase()) + '\n';
          }
        }
      );
    }
    if (
      this.recommendationsFromDoctor.treatmentPlan &&
      this.selectedAssessmentFieldsToCopy.controls.recommendations.value
    ) {
      this.allSelectFieldsString +=
        'TREATMENT PLAN' + '\n ' + this.recommendationsFromDoctor.treatmentPlan;
    }

    if (recommendationIsSelected || this.recommendationsFromDoctor.treatmentPlan) {
      this.allSelectFieldsString += '\n';
    } else {
      this.allSelectFieldsString = this.allSelectFieldsString.replace('RECOMMENDATIONS: ', '');
    }

    navigator.clipboard.writeText(this.allSelectFieldsString);
    this._snackBar.open('Copied All Selected Values.', '', { duration: 2500 });
  }

  getExercises(exercises) {
    const exercisesArray: string[] = [];
    exercises.forEach(exercise => {
      if (exercise) {
        exercisesArray.push(exercise.name + ' - ' + exercise.description);
      }
    });
    return exercisesArray;
  }

  getEyeDrops(eyeDrops) {
    const eyeDropsArray: string[] = [];
    eyeDrops.forEach(eyeDrop => {
      if (eyeDrop) {
        eyeDropsArray.push(eyeDrop.name + ' - ' + eyeDrop.comments);
      }
    });
    return eyeDropsArray;
  }

  getPlans(plans) {
    // Process the string to replace HTML with bullet points and clean it
    const processedPlans = plans
      .split(/(?:<br>|<div>|<\/div>|\n)+/) // Split by <br> or <div> tags or new lines
      .map(line => this.stripHtml(line.replace(/^\*\s*/, '').trim())); // Clean and format

    const plansArray: string[] = [];
    processedPlans.forEach(plan => {
      if (plan) {
        plansArray.push(plan); // Add cleaned plans to the array
      }
    });

    return plansArray;
  }

  getProcedures(procedures) {
    const proceduresArray: string[] = [];
    procedures.forEach(procedure => {
      if (procedure) {
        proceduresArray.push(procedure.name + ': ' + procedure.description);
      }
    });
    return proceduresArray;
  }

  getRx(rxEyeDrops) {
    const rxArray: string[] = [];
    rxEyeDrops.forEach(rxEyeDrop => {
      if (rxEyeDrop) {
        rxArray.push(
          rxEyeDrop.name +
            '\nComments: ' +
            (rxEyeDrop.comments ? rxEyeDrop.comments : 'N/A') +
            '\nDosage: ' +
            (rxEyeDrop.dosage ? rxEyeDrop.dosage : 'N/A')
        );
      }
    });
    return rxArray;
  }

  getPreOpImpressions(impressions) {
    const impressionsArray = [];
    if (impressions.osdRelevance === 'yes') {
      impressionsArray.push(' It was indicated that ocular surface disease is relevant\n');
    } else if (impressions.osdRelevance === 'no') {
      impressionsArray.push(' It was indicated that ocular surface disease is not relevant.\n');
    }
    if (impressions.neuropathicPain === 'yes') {
      impressionsArray.push('It was indicated that patient may have neuropathic pain.\n');
    }
    if (impressions.neurotrophicCornea === 'yes') {
      impressionsArray.push('It was indicated that patient may have neurotrophic cornea.\n');
    }
    if (impressions.visuallySignificantOSD === 'yes') {
      impressionsArray.push(
        'It was indicated that ocular surface disease is visually significant.\n'
      );
    } else if (impressions.visuallySignificantOSD === 'no') {
      impressionsArray.push(
        'It was indicated that ocular surface disease is not visually significant.\n'
      );
    }
    if (impressions.dryEyeDisease === true) {
      impressionsArray.push('It was indicated that dry eye disease is an applicable subtype.\n');
    }
    if (impressions['dryEyeDisease-description']) {
      impressionsArray.push(
        `Notes for dry eye disease: ${impressions['dryEyeDisease-description']}\n`
      );
    }
    if (impressions.conjunctivitis === true) {
      impressionsArray.push('It was indicated that conjunctivitis is an applicable subtype.\n');
    }
    if (impressions['conjunctivitis-description']) {
      impressionsArray.push(
        `Notes for conjunctivitis: ${impressions['conjunctivitis-description']}\n`
      );
    }
    if (impressions.exposure === true) {
      impressionsArray.push('It was indicated that exposure is an applicable subtype.\n');
    }
    if (impressions['exposure-description']) {
      impressionsArray.push(`Notes for exposure: ${impressions['exposure-description']}\n`);
    }
    if (impressions.lumps === true) {
      impressionsArray.push('It was indicated that lumps is an applicable subtype.\n');
    }

    if (impressions['lumps-description']) {
      impressionsArray.push(`Notes for lumps: ${impressions['lumps-description']}\n`);
    }

    return impressionsArray.join('<br>');
  }

  getTreatmentPlan() {
    let treatmentPlan: any;
    if (this.assessmentMethod === 'preop') {
      treatmentPlan =
        this.parsedBody.dryEyeForm.treatmentsPlan &&
        this.parsedBody.dryEyeForm.treatmentsPlan
          .split('\n\n')
          .map(line => line.replace(/^\*\s*/, '• ').trim());
    }

    if (this.assessmentMethod === 'treatment') {
      treatmentPlan =
        this.parsedBody.assessmentForm.treatmentPerformed &&
        this.parsedBody.assessmentForm.treatmentPerformed
          .split('\n\n')
          .map(line => line.replace(/^\*\s*/, '• ').trim());
    }

    return treatmentPlan;
  }

  onCopyMedications() {
    navigator.clipboard.writeText(this.getMedicationsText());
    this._snackBar.open('Copied Medications.', '', { duration: 2500 });
  }

  onCopySign(sign) {
    this._snackBar.open('Copied ' + sign.value.friendlyName + ' Values.', '', { duration: 2500 });
  }

  onCopyImpressions() {
    if (this.assessmentMethod !== 'preop') {
      navigator.clipboard.writeText(this.getImpressionsText());
    } else {
      navigator.clipboard.writeText(this.getPreOpImpressionsText());
    }
    this._snackBar.open('Copied Impressions.', '', { duration: 2500 });
  }

  onCopyGoals() {
    navigator.clipboard.writeText(this.getGoalsText());
    this._snackBar.open('Copied Goals.', '', { duration: 2500 });
  }

  onCopyRecommendationsType(recommendationType: string, recommendationTitle: string) {
    navigator.clipboard.writeText(
      this.getRecommendationText(recommendationType, recommendationTitle)
    );
    this._snackBar.open('Copied ' + recommendationTitle + '.', '', { duration: 2500 });
  }

  preventDefaultAndPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

import { DataCacheType } from 'src/API';
import { ModalSymptom } from '../shared/symptoms/symptom.model';
import { SymptomsSchema } from './../shared/symptoms/services/schema/schema.model';
import { DropdownSymptom, MethodSymptom } from './../shared/symptoms/symptom.model';
import { Filter } from './filter.model';

export enum FilterFieldType {
  calender = 'calender',
  dropdown = 'dropdown',
  number = 'number',
  text = 'text',
  checkbox = 'checkbox'
}

export enum Operator {
  equals = '=',
  contains = 'contains',
  notEqual = 'not equal',
  notContains = 'not contains',
  startsWith = 'starts with',
  endsWith = 'ends with',
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEqualTo = '>=',
  lessThanOrEqualTo = '<='
}

interface BasicFilterFieldConfig {
  type: Omit<FilterFieldType, 'dropdown' | 'checkbox'>;
}
interface OptionsFilterFieldConfig {
  type: 'dropdown' | 'checkbox';
  options: { [key: string]: string };
}

export interface FilterFieldConfig {
  label: string;
  operators: (keyof typeof Operator)[];
  typeConfig: BasicFilterFieldConfig | OptionsFilterFieldConfig;
}
export type FilterFieldSchema = {
  [key in DataCacheType]?: { [key: string]: FilterFieldConfig };
};

export enum MatchType {
  and = 'and',
  or = 'or'
}

export interface FilterField {
  field: string;
  operator: keyof typeof Operator;
  searchValue: any;
}

export interface SubFilter {
  type: MatchType;
  filters: FilterField[];
}

export type Filter = FilterField | SubFilter;

export interface FilterGroup {
  type: MatchType;
  filters: Filter[];
}

export type FilterOutput = {
  [key in MatchType]?: (FilterField | { [subKey in MatchType]?: FilterField[] })[];
};

const numericalOperators = [
  'greaterThan',
  'lessThan',
  'greaterThanOrEqualTo',
  'lessThanOrEqualTo',
  'equals',
  'notEqual'
] as (keyof typeof Operator)[];

const textOperators = [
  'equals',
  'endsWith',
  'startsWith',
  'contains',
  'notContains',
  'notEqual'
] as (keyof typeof Operator)[];

export const commonFilterFieldConfig = {
  calender: label => ({
    label,
    operators: numericalOperators,
    typeConfig: { type: FilterFieldType.calender }
  }),
  sign: (symptomMap: SymptomsSchema, symptomKey: string) => {
    const symptom = symptomMap[symptomKey];

    const label = symptom.name;
    let operators;
    let typeConfig: BasicFilterFieldConfig | OptionsFilterFieldConfig;

    if (symptom.type === 'numeric') {
      operators = numericalOperators;
      typeConfig = { type: FilterFieldType.number };
    } else if (symptom.type === 'modal') {
      operators = numericalOperators;
      typeConfig = {
        type: FilterFieldType.dropdown,
        options: (symptom as ModalSymptom).modal.values
      };
    } else if (symptom.type === 'select') {
      operators = numericalOperators;
      typeConfig = {
        type: FilterFieldType.dropdown,
        options: (symptom as DropdownSymptom).values
      };
    } else if (symptom.type === 'textarea' || symptom.type === 'text') {
      operators = textOperators;
      typeConfig = { type: FilterFieldType.text };
    } else if (symptom.type === 'method') {
      const qualitative = (symptom as MethodSymptom).qualitative;
      const quantitative = (symptom as MethodSymptom).quantitative;

      if (
        qualitative['modal'] ||
        qualitative['values'] ||
        quantitative['modal'] ||
        quantitative['values']
      ) {
        operators = Object.keys(Operator);

        typeConfig = {
          type: FilterFieldType.dropdown,
          options: {
            ...((qualitative['modal'] && qualitative['modal']['values']) || {}),
            ...((quantitative['modal'] && quantitative['modal']['values']) || {}),
            ...(qualitative['values'] || {}),
            ...(quantitative['values'] || {})
          }
        };
      } else {
        operators = Object.keys(Operator);
        typeConfig = { type: FilterFieldType.text };
      }
    }

    return {
      label,
      operators,
      typeConfig
    };
  },
  text: label => ({
    label,
    operators: textOperators,
    typeConfig: { type: FilterFieldType.text }
  })
};

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Logger } from 'aws-amplify';
import { Observable, fromEvent, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { TextDecoder } from 'text-encoding-utf-8';
import { SvgPipe } from './svg.pipe';

export enum ContentType {
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  JPG = 'image/jpeg',
  MP4 = 'video/mp4',
  PDF = 'application/pdf',
  WEBP = 'image/webp'
}

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'imageToDataUri'
})
export class ImageToDataUriPipe implements PipeTransform {
  private logger: Logger = new Logger('ImageToDataUriPipe');

  constructor(private svgPipe: SvgPipe, private sanitizer: DomSanitizer) {}

  transform(
    value: Uint8Array | Blob,
    contentType: ContentType,
    bypassSecurity: boolean = false
  ): Observable<string | SafeUrl> {
    switch (contentType) {
      case ContentType.SVG:
        return this.transformSvg(value, contentType, bypassSecurity);

      default:
        return this.readAsDataURL(
          value instanceof Blob ? value : new Blob([value], { type: contentType }),
          bypassSecurity
        );
    }
  }

  private transformSvg(
    value: Uint8Array | Blob,
    contentType: ContentType,
    bypassSecurity: boolean = false
  ): Observable<string | SafeUrl> {
    return of(value).pipe(
      switchMap(arrayOrBlob => {
        if (arrayOrBlob instanceof Blob) {
          const fileReader: FileReader = new FileReader();
          const fileReader$: Observable<ProgressEvent> = fromEvent<ProgressEvent>(
            fileReader,
            'load'
          );
          fileReader.readAsArrayBuffer(arrayOrBlob);

          return fileReader$.pipe(
            take(1),
            map(() => fileReader.result)
          );
        } else {
          return of(arrayOrBlob);
        }
      }),
      map((valueArray: Uint8Array) =>
        this.svgPipe.transform(TextDecoder('utf-8').decode(valueArray), bypassSecurity)
      )
    );
  }

  private readAsDataURL(blob: Blob, bypassSecurity: boolean): Observable<string | SafeUrl> {
    this.logger.debug('setting up file reader');
    const fileReader: FileReader = new FileReader();
    const fileReader$: Observable<ProgressEvent> = fromEvent<ProgressEvent>(fileReader, 'load');
    fileReader.readAsDataURL(blob);
    return fileReader$.pipe(
      take(1),
      map(() => {
        this.logger.debug('got filereader result');
        const dataUri = fileReader.result as string;
        return bypassSecurity ? this.sanitizer.bypassSecurityTrustUrl(dataUri) : dataUri;
      })
    );
  }
}

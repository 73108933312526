import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, merge } from 'rxjs';
import { debounceTime, first, mergeAll, switchMap } from 'rxjs/operators';
import { EConsultFormGroup } from '../../../../../econsult/econsult-form.model';
import { ByContactCompatibility } from './filters/by-contact-compatibility-filter';
import { ByDryEyeCategory } from './filters/by-dry-eye-category-filter';
import { ByItchiness } from './filters/by-itchiness-filter';
import { ByPreservatives } from './filters/by-preservatives-filter';
import { FilterShouldActivate, TreatmentFilter } from './treatment-filter.model';

interface FilterGroup {
  activeFilters: TreatmentFilter[];
  allFilters: TreatmentFilter[];
}

@Injectable()
export class TreatmentFiltersCDSService implements OnDestroy {
  private byPreservatives = new ByPreservatives();
  private byContactCompatibility = new ByContactCompatibility();
  private byItchiness = new ByItchiness();
  private byDryEyeCategory = new ByDryEyeCategory();
  private allFilters: TreatmentFilter[] = [
    this.byDryEyeCategory,
    this.byContactCompatibility,
    this.byItchiness,
    this.byPreservatives
  ];

  public otcFilters: FilterGroup = {
    allFilters: [
      this.byDryEyeCategory,
      this.byContactCompatibility,
      this.byItchiness,
      this.byPreservatives
    ],
    activeFilters: []
  };
  public rxFilters: FilterGroup = {
    allFilters: [this.byDryEyeCategory, this.byContactCompatibility, this.byPreservatives],
    activeFilters: []
  };
  public therapyFilters: FilterGroup = {
    allFilters: [this.byDryEyeCategory],
    activeFilters: []
  };

  private allFilterArrays: FilterGroup[] = [this.otcFilters, this.rxFilters, this.therapyFilters];

  constructor(private formGroup: EConsultFormGroup) {
    this.refreshFilters();

    const mergedFilters$ = from(
      this.allFilters
        .filter(filter => filter.listensTo)
        .map(filter => filter.listensTo(this.formGroup))
    ).pipe(mergeAll());

    const firstValueOnly$ = this.formGroup.valueChanges.pipe(first());
    const debounced$ = this.formGroup.valueChanges.pipe(debounceTime(500));
    merge(firstValueOnly$, debounced$)
      .pipe(
        switchMap(() => mergedFilters$),
        untilDestroyed(this)
      )
      .subscribe((value: FilterShouldActivate) => {
        this.setSelectedByDefault(value.filterToChange, value.changeToActive);
      });
  }

  ngOnDestroy() {
    // Empty function is required for untilDestroyed(this) to function properly
  }

  private getDefaultSelectedFilters(allFilters: TreatmentFilter[]) {
    return allFilters.filter(filter => filter.selectedByDefault);
  }

  private setSelectedByDefault(filter: TreatmentFilter, selectedByDefault: boolean) {
    filter.selectedByDefault = !!selectedByDefault;
    this.refreshFilters();
  }

  private refreshFilters() {
    this.allFilterArrays.forEach(filterArray => {
      filterArray.activeFilters = this.getDefaultSelectedFilters(filterArray.allFilters);
      filterArray.allFilters = [...filterArray.allFilters];
    });
  }
}

import { noSort } from 'src/app/core/api/model-utils';
import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ClinicSetupService } from './../clinic-setup.service';
import { LayoutsService } from './layouts.service';

@Component({
  selector: 'csi-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutsComponent implements OnInit {
  disclaimerMap: { [key: string]: string } = {};

  layoutFriendlyKeyValues: KeyValue<string, any>[] = [];

  public noSort = noSort;

  public layoutsCopy = JSON.parse(JSON.stringify(this.layoutsService.allLayoutsById));

  constructor(
    public layoutsService: LayoutsService,
    public clinicSetupService: ClinicSetupService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.layoutFriendlyKeyValues = Object.entries(this.layoutsCopy).map(([key, value]) => ({
      key,
      value
    }));

    Object.keys(this.layoutsCopy).forEach(layoutKey => {
      this.disclaimerMap[layoutKey] = this.layoutsService.getDisclaimer(layoutKey, 'Symptoms');
    });

    this.cdr.detectChanges();
  }

  sortKeyValues(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return 0;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { defer, merge, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import {
  AuthenticatorModalComponent,
  AuthenticatorMode
} from '../authenticator-modal/authenticator-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private dialog: MatDialog,
    private loadingSpinnerService: LoadingSpinnerService,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return defer(() => {
      this.loadingSpinnerService.show();
      const loggedIn$: Observable<CognitoUser> = this.authenticationService
        .getCurrentAuthenticatedUser()
        .pipe(
          filter(loggedInUser => !!loggedInUser),
          tap(() => this.loadingSpinnerService.hide())
        );

      const notLoggedIn$: Observable<CognitoUser> = this.authenticationService
        .getCurrentAuthenticatedUser()
        .pipe(
          filter(loggedInUser => !loggedInUser),
          switchMap(() => {
            this.loadingSpinnerService.hide();
            const authenticatorModalRef: MatDialogRef<AuthenticatorModalComponent> = AuthenticatorModalComponent.open(
              this.dialog,
              { authenticatorMode: AuthenticatorMode.Login }
            );

            const authenticatorModal: AuthenticatorModalComponent = authenticatorModalRef.componentInstance as AuthenticatorModalComponent;

            return authenticatorModal.loggedInUser.pipe(map(loggedInUser => loggedInUser));
          })
        );

      return merge(loggedIn$, notLoggedIn$).pipe(
        mergeMap(loggedInUser => {
          this.authenticationService.setLoggedInUser(loggedInUser);
          if (loggedInUser['signInUserSession'] || loggedInUser['challengeName'] === 'NOMFA') {
            return of(true);
          } else {
            const authenticatorInstance: AuthenticatorModalComponent = AuthenticatorModalComponent.open(
              this.dialog,
              {
                authenticatorMode: AuthenticatorMode.VerifyUser,
                attributeToVerify: loggedInUser['challengeName'] === 'SMS_MFA' ? 'Phone' : 'TOTP',
                loggedInUser: loggedInUser
              }
            ).componentInstance as AuthenticatorModalComponent;
            return authenticatorInstance.isUserVerified.pipe(map(() => true));
          }
        }),
        take(1)
      );
    });
  }
}

import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { mergeMap, tap, startWith } from 'rxjs/operators';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { DryEyeFormGroup } from './../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { DrawOverImageModalComponent } from './draw-over-image-modal/draw-over-image-modal.component';
import { DrawSymptomService } from './draw-symptom.service';

@Component({
  selector: 'csi-draw-symptom-control',
  templateUrl: './draw-symptom-control.component.html',
  styleUrls: ['./draw-symptom-control.component.css'],
  providers: [
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => DrawSymptomControlComponent)
    }
  ]
})
export class DrawSymptomControlComponent extends AbstractSymptomControlComponent
  implements AfterViewInit {
  @Input() drawFormControl: FormControl;
  @Input() formKey: string;
  @Input() formGroup: DryEyeFormGroup;

  public viewOnlyImage$: Observable<string>;

  constructor(private dialog: MatDialog, public drawSymptomService: DrawSymptomService) {
    super();
  }

  ngAfterViewInit() {

    this.drawFormControl =
      this.drawFormControl || (this.formGroup.controls[this.formKey] as FormControl);

    if (this.drawFormControl.value) {
      this.viewOnlyImage$ =
        this.drawSymptomService.getImageUrlFromJSON(this.drawFormControl.value);
    }

    this.drawFormControl.valueChanges.subscribe(value => {
      if (value) {

        this.viewOnlyImage$ = this.drawSymptomService.getImageUrlFromJSON(value);
      }
    });
  }

  openModal() {
    this.dialog.open(DrawOverImageModalComponent, {
      disableClose: true,
      data: {
        formControl: this.drawFormControl
      }
    });
  }
}

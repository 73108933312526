import {
  CompareOperator,
  DescriptiveTextTypeEnum,
  DisplayFormatEnum,
  FormTemplateFieldAutopopulateConfig,
  TransformValueTypeEnum
} from '../../shared/autopopulate/autopopulate.model';

export const medicalHistoryCustomAutopopulateConfig: {
  [key: string]: FormTemplateFieldAutopopulateConfig[];
} = {
  eyeColor: [
    {
      incomingDataValuePath: 'eyeColor',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sunTanExposureFrequency: [
    {
      incomingDataValuePath: 'sunTanExposureFrequency',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  lastSunExposure: [
    {
      incomingDataValuePath: 'lastSunExposure',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  naturalHairColor: [
    {
      incomingDataValuePath: 'naturalHairColor',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  skinColor: [
    {
      incomingDataValuePath: 'skinColor',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  freckles: [
    {
      incomingDataValuePath: 'freckles',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  longSunExposure: [
    {
      incomingDataValuePath: 'longSunExposure',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  darkerAfterSunExposure: [
    {
      incomingDataValuePath: 'darkerAfterSunExposure',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  tanIntensity: [
    {
      incomingDataValuePath: 'tanIntensity',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  faceSunSensitivity: [
    {
      incomingDataValuePath: 'faceSunSensitivity',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  patientEnteredMedicationsFreeText: [
    {
      incomingDataValuePath: 'patientMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: { type: TransformValueTypeEnum.none },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'generalMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: { type: TransformValueTypeEnum.none },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.allergyDrops-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Allergy drops:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.antidepressents-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Antidepressants:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.antihistamineDrugs-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Antihistamine drugs:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.antihistamineEyeDrops-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Antihistamine eye drops:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.bloodPressurePills-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Blood pressure pills:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.diuretics-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Diuretics (water pills for hypertension):\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.glaucomaDrops-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Glaucoma drops:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.hormoneTherapy-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Hormone therapy:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },

    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.oralContraceptives-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Oral contraceptives:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.oralSteroids-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Oral steroids:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.pillsForSkin-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Oral medications for skin problems and disorders:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.sleepingTablets-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Sleeping tablets:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.tranquilizers-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Tranquilizers:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'currentOrPastMedications-yes-medicationList.medicationOther-specifyMedications',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.list
      }
    }
  ],
  // workHoursPerDayFreeText: null,
  autoImmuneConditions: [
    {
      incomingDataValuePath: 'autoImmuneConditions-yes-describeAutoImmuneConditions',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // changesToEyes: null,
  drugAllergies: [
    {
      incomingDataValuePath: 'medicalConditionList.drugAllergies-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  seasonalAllergies: [
    {
      incomingDataValuePath: 'medicalConditionList.seasonalAllergies-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // 'symptomDuration.month': null,
  // 'symptomDuration.year': null
  // eyeItchiness: 0,
  // eyeTearingQuantity: 0,
  // symptomFrequency: 0,
  // symptomSeverity: 0,
  // dietQuality: null,
  // healthQuality: null,
  // stressLevel: null,
  // cityHumidityLevel: null,
  // cityPollutionLevel: null,
  // sensitivityToLight: null,
  // sensitivityToWind: null,
  // impactOfSymptomsOnMood: null,
  // impactOfSymptomsOnLife: null,
  // exercise: null,
  // otherMedications: null,
  // sleepPerNight: null,
  // triggeringEvent: null,
  pastDryEyeTreatmentsFreeText: [
    {
      incomingDataValuePath: 'pastDryEyeTreatments.artificialTears-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Artificial Tears:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.inOfficeTreatments-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'In Office Treatments:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.lidScrubs-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Lid scrubs / Lid hygiene:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.ointment-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Ointmentse:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.omega3Supplements-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Omega 3 supplements:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.pharmaceuticals-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Pharmaceuticals:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.punctalPlugs-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Punctal plugs:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.scleralLenses-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Scleral Lenses:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.topicalCorticosteroids-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Topical Corticosteroids:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'pastDryEyeTreatments.warmCompresses-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Warm Compresses:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath:
        'pastDryEyeTreatments.otherPastDryEyeTreatments-dryEyeTreatmentDetails',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Other dry eye treatments:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    }
  ],
  medicalConditionsFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.conditionOther-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // medicalConditions: true,
  // environmentalSymptoms: true,
  // otherConditions: true,
  thyroidConditionFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.thyroid-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  chronicPainConditionFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.chronicPainSyndrome-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  vitaminDeficiencyConditionFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.vitaminDeficiency-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sexSteroidDeficiencyConditionFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.sexSteroidDeficiency-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  previousOcularSurgeryFreeText: [
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.cataractSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Cataract surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.eyelidSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Eyelid surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.glaucomaSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Glaucoma surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.ocularSurfaceSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Ocular surface surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.ptosisSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Ptosis surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.refractiveSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Refractive surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.retinalSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Retinal surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.strabismusSurgery',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Strabismus surgery'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'eyeSurgeryPerformed-yes-eyeSurgeriesList.surgeryOther-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Other eye surgieries:\n'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.wearsGlasses',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I wear glasses'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.pastContactLensDiscomfort',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I have contact lens discomfort or intolerance'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.lasikOrPRK',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I had Lasik or PRK'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.orthokeratology',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I had orthokeratology'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.myopia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value:
            'I have myopia (near-sightedness), or I need prescription lenses to see distant objects'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.hyperopia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value:
            'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.presbyopia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I have presbyopia, or I cannot see the computer screen without reading glasses'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.amblyopia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I have amblyopia, or lazy eye'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.diplopia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I have diplopia, or double vision'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.strabismus',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I have or had strabismus, or my eyes are not aligned'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.eyeTrauma',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'I had trauma or injury to the eyes'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.pterygium',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: "I have pterygium, or surfer's Eye"
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.nystagmus',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Nystagmus'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.retinalConditions-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Retinal Conditions:'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.dryEyes',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Dry eyes (burning, gritty, tearing, etc.)'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.prisim',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Prism'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.keratoconus',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Keratoconus'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.previousEyeInfections-specify',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Previous eye infections: '
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.cataracts',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: 'Cataracts'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'ocularHistoryList.otherPastOcularHistory-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.pre,
          preDescriptiveText: 'Other past ocular history: '
        },
        displayFormat: DisplayFormatEnum.list
      }
    }
  ],
  psychiatricConditionsFreeText: [
    {
      incomingDataValuePath: 'medicalConditionList.psychiatricConditions-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // dietFreeText: null,
  // caffeinatedDrinksPerDayFreeText: null,
  // waterPerDayFreeText: null,
  // urbanAreaTimePerWeekFreeText: null,
  // timeSpentOutdoorsFreeText: null,
  // timeSpentClosedEnvironmentsFreeText: null,
  // faceCareAndMakeUpProductsFreeText: null,
  symptomsRednessGreaterInOneEye: [
    {
      incomingDataValuePath: 'listOfSymptoms.symptomsRednessGreater',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  botoxInjections: [
    {
      incomingDataValuePath: 'botoxInjectionsToFace',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            yes: true,
            no: false
          }
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  contactLens: [
    {
      incomingDataValuePath: 'contactLenses',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            yes: true,
            no: false,
            past: false
          }
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  otherConditionsFreeText: [
    {
      incomingDataValuePath: 'contactLenses-yes-contactLensdaysPerWeek',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.greaterThan,
        comparisonValue: 0
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        descriptiveTextConfig: {
          type: DescriptiveTextTypeEnum.preAndPost,
          preDescriptiveText: 'Usage frequency: ',
          postDescriptiveText: ' days per week'
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'contactLenses-yes-contactType',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            soft: 'Soft lens',
            hard: 'Hard lens'
          }
        },
        displayFormat: DisplayFormatEnum.list
      }
    },
    {
      incomingDataValuePath: 'contactType-soft-contactLensDailyDisposable',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            yes: 'Daily disposable',
            no: 'Not daily disposable'
          }
        },
        displayFormat: DisplayFormatEnum.list
      }
    }
    // {
    //   incomingDataValuePath: 'contactLensDailyDisposable',
    //   mapConditionConfig: {
    //     comparisonOperator: CompareOperator.greaterThan,
    //     comparisonValue: 0
    //   },
    //   transformValueConfig: {
    //     typeConfig: {
    //       type: TransformValueTypeEnum.list,
    //       value:
    //     },
    //     descriptiveTextConfig: {
    //       type: DescriptiveTextTypeEnum.post,
    //       postDescriptiveText: ' days per week'
    //     }
    //   }
    // },
  ],
  crustyOrDischarge: [
    {
      incomingDataValuePath: 'listOfSymptoms.crustyOrDischarge',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  menopausal: [
    {
      incomingDataValuePath: 'menstruation',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            menopause: true
          }
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  pregnant: [
    {
      incomingDataValuePath: 'menstruation',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            pregnancy: true
          }
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // alcohol: {
  //   incomingDataValuePath: 'smokeOrVape',
  //   mapConditionConfig: {
  //     comparisonValue: 1,
  //     comparisonOperator: CompareOperator.greaterThan
  //   },
  //   transformValueConfig: {
  //     typeConfig: {
  //       type: TransformValueTypeEnum.specifyValue,
  //       value: true
  //     }
  //   }
  // }

  difficultySwallowingFood: [
    {
      incomingDataValuePath: 'foodSwallowingAndDryMouth',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: 'yes'
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  enlargedGlands: [
    {
      incomingDataValuePath: 'listOfSymptoms.crustyOrDischarge',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  visionAffected: [
    {
      incomingDataValuePath: 'listOfSymptoms.fluctuatingVision',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // hematopoieticStemCellTransplantation: null,
  hormoneReplacementTherapy: [
    {
      incomingDataValuePath: 'currentOrPastMedications-yes-medicationList.hormoneTherapy',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],

  // previousOcularSurgery: null,
  postmenopausal: [
    {
      incomingDataValuePath: 'menstruation',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: 'postmenopause'
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.map,
          map: {
            postmenopause: true
          }
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  // skinProductEyeIrritation: null,
  smoking: [
    {
      incomingDataValuePath: 'smokeOrVape-yes-cigarettesPerWeek',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.greaterThan,
        comparisonValue: 0
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    },
    {
      incomingDataValuePath: 'smokeOrVape-past-cigarettesPerWeek',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.greaterThan,
        comparisonValue: 0
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  eyesSwollen: [
    {
      incomingDataValuePath: 'listOfSymptoms.swollenEyelids',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  vaping: [
    {
      incomingDataValuePath: 'smokeOrVape-yes-vapesPerWeek',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.greaterThan,
        comparisonValue: 0
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    },
    {
      incomingDataValuePath: 'smokeOrVape-past-vapesPerWeek',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.greaterThan,
        comparisonValue: 0
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  anxiety: [
    {
      incomingDataValuePath: 'medicalConditionList.anxiety',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  migraine: [
    {
      incomingDataValuePath: 'medicalConditionList.migraine',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  asthma: [
    {
      incomingDataValuePath: 'medicalConditionList.asthma',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  hypertension: [
    {
      incomingDataValuePath: 'medicalConditionList.hypertension',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  glaucoma: [
    {
      incomingDataValuePath: 'medicalConditionList.glaucoma',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  diabetes: [
    {
      incomingDataValuePath: 'medicalConditionList.diabetes',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  hypercholesterolemia: [
    {
      incomingDataValuePath: 'medicalConditionList.hypercholesterolemia',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  eczema: [
    {
      incomingDataValuePath: 'medicalConditionList.eczema',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  acne: [
    {
      incomingDataValuePath: 'medicalConditionList.acne',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  rosacea: [
    {
      incomingDataValuePath: 'medicalConditionList.rosacea',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  psoriasis: [
    {
      incomingDataValuePath: 'medicalConditionList.psoriasis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  lupus: [
    {
      incomingDataValuePath: 'medicalConditionList.lupus',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  arthritis: [
    {
      incomingDataValuePath: 'medicalConditionList.arthritis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  osteoporosis: [
    {
      incomingDataValuePath: 'medicalConditionList.osteoporosis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sclerosis: [
    {
      incomingDataValuePath: 'medicalConditionList.sclerosis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  thyroid: [
    {
      incomingDataValuePath: 'medicalConditionList.thyroid',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  chronic: [
    {
      incomingDataValuePath: 'medicalConditionList.chronicPainSyndrome',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  hepatitis: [
    {
      incomingDataValuePath: 'medicalConditionList.hepatitis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  hiv: [
    {
      incomingDataValuePath: 'medicalConditionList.hivOrAids',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  htlv: [
    {
      incomingDataValuePath: 'medicalConditionList.htlv',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  stroke: [
    {
      incomingDataValuePath: 'medicalConditionList.stroke',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  cancer: [
    {
      incomingDataValuePath: 'medicalConditionList.cancer',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  vitaminDeficiency: [
    {
      incomingDataValuePath: 'medicalConditionList.vitaminDeficiency',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sexSteroidDeficiency: [
    {
      incomingDataValuePath: 'medicalConditionList.sexSteroidDeficiency',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  gout: [
    {
      incomingDataValuePath: 'medicalConditionList.gout',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  vitiligo: [
    {
      incomingDataValuePath: 'medicalConditionList.vitiligo',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  liverDisease: [
    {
      incomingDataValuePath: 'medicalConditionList.liverDisease',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  renalFailure: [
    {
      incomingDataValuePath: 'medicalConditionList.renalFailure',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sjorgenSyndrome: [
    {
      incomingDataValuePath: 'medicalConditionList.sjorgenSyndrome',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  radiotherapy: [
    {
      incomingDataValuePath: 'medicalConditionList.radiotherapy',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  boneMarrow: [
    {
      incomingDataValuePath: 'medicalConditionList.boneMarrow',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  irritableBowelSyndrome: [
    {
      incomingDataValuePath: 'medicalConditionList.irritableBowelSyndrome',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  polycysticOvary: [
    {
      incomingDataValuePath: 'medicalConditionList.polycysticOvary',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  fertility: [
    {
      incomingDataValuePath: 'medicalConditionList.fertility',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  pelvicPain: [
    {
      incomingDataValuePath: 'medicalConditionList.pelvicPain',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  psychiatricConditions: [
    {
      incomingDataValuePath: 'medicalConditionList.psychiatricConditions',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  demodexSkinInfection: [
    {
      incomingDataValuePath: 'medicalConditionList.demodexSkinInfection',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  androgenDeficiency: [
    {
      incomingDataValuePath: 'medicalConditionList.androgenDeficiency',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  sarcoidosis: [
    {
      incomingDataValuePath: 'medicalConditionList.sarcoidosis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  accutaneTreatment: [
    {
      incomingDataValuePath: 'medicalConditionList.accutaneTreatment',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  dandruff: [
    {
      incomingDataValuePath: 'medicalConditionList.dandruff',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  scleroderma: [
    {
      incomingDataValuePath: 'medicalConditionList.scleroderma',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  seborrheicDermatitis: [
    {
      incomingDataValuePath: 'medicalConditionList.seborrheicDermatitis',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  conditionOther: [
    {
      incomingDataValuePath: 'medicalConditionList.conditionOther',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.none
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ]
  // allergySymptoms: null,
  // bodyAches: null,
  // breathingDifficulty: null,
  // noseBurningSensation: null,
  // chills: null,
  // difficultyConcentrating: null,
  // dizziness: null,
  // skinDrynessAndItchiness: null,
  // fatigue: null,
  // fever: null,
  // forgetfulness: null,
  // headaches: null,
  // irritability: null,
  // nausea: null,
  // runnyNose: null,
  // throatIrritation: null,
  // chestTightness: null,
  // tearingQuality: null,
  // screenTime: null,
  // bothersomeEye: null,
  // sleepingPosition: null,
  // eyeRubbingFrequency: null,
  // symptomSeverityOccurrence: null,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { FilterDashboardComponent } from './filter-dashboard/filter-dashboard.component';
import { FilterFieldComponent } from './filter-field/filter-field.component';
import { FilterComponent } from './filter/filter.component';

@NgModule({
  entryComponents: [FilterDashboardComponent],
  declarations: [FilterComponent, FilterFieldComponent, FilterDashboardComponent],
  imports: [CommonModule, SharedModule],
  exports: [FilterDashboardComponent]
})
export class FilterModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'csi-form-template-dropdown-field-config',
  templateUrl: './form-template-dropdown-field-config.component.html',
  styleUrls: ['./form-template-dropdown-field-config.component.css']
})
export class FormTemplateDropdownFieldConfigComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;
  constructor() {}

  ngOnInit() {
    (this.templateFieldFormGroup.controls['typeConfig'] as FormGroup).addControl(
      'valuesConfig',
      new FormGroup({})
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: string, searchKey: string): any {
    if (!value || !searchKey) {
      return value
      }
    if (!searchKey) {
      return value;
    }
    const escapedSearchKey = searchKey.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regexp = new RegExp(escapedSearchKey, 'gi');
    return value.replace(regexp, '<b><u>$&</u></b>');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayHasValue'
})
export class ArrayHasValuePipe implements PipeTransform {
  transform(value: any, array: any[]): any {
    return array.includes(value);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { Attachment } from '../attachments/attachments.component';
import {
  S3AttachmentService,
  S3Object,
  UrlWithContentType
} from '../attachments/s3-attachment.service';
import { ContentType } from './image-to-data-uri.pipe';

@Pipe({
  name: 'attachmentToUri'
})
export class AttachmentToUriPipe implements PipeTransform {
  constructor(private s3AttachmentService: S3AttachmentService, private sanitizer: DomSanitizer) {}

  transform(value: Attachment): Observable<UrlWithContentType> {
    if (value) {
      if (value instanceof File) {
        return this.getFileUrl(value);
      }
      return this.s3AttachmentService.s3ObjectToUri(<S3Object>value);
    }

    return of(null);
  }

  private getFileUrl(value: File): Observable<UrlWithContentType> {
    const reader = new FileReader();
    return new Observable(observer => {
      reader.onload = () => {
        observer.next({
          url: this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result),
          contentType: <ContentType>value.type
        });
      };
      reader.readAsDataURL(value);
    });
  }
}

/*
Inbox actions:
- Mark all as read
- Filter

Notification:
- Title
- Subject
- Description
- Date
- Properties:
  - Read/Unread

Notification actions:
- Read
- Delete
- Flag/favorite?
*/

import { NotificationStatus, NotificationType } from 'src/API';

export interface NotificationContent {
  title: string;
  subject: string;
  format: NotificationFormat;
  textBody: string;
  referralPriority?: string;
  questionnaireScores?: string;
  id?: string;
}

// export interface NotificationStatus {
//   read: boolean;
//   flag: Flag;
// }

export type NotificationPriority = 'low' | 'high';
export enum NotificationFormat {
  email = 'email',
  pdf = 'pdf'
}



export const listNotificationsByTypeIdBasedOnCreatedAt = /* GraphQL */ `
  query ListNotificationsByTypeIdBasedOnCreatedAt(
    $typeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByTypeIdBasedOnCreatedAt(
      typeId: $typeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        properties {
          doctorId
          status {
            read
            flag
          }
        }
        type
        typeId
        content
        notificationClinicId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


// export enum NotificationType {
//   questionnaire = 'Questionnaire',
//   referral = 'Referral',
//   intake = 'Intake',
//   system = 'System'
// }

// export enum Flag {
//   none,
//   important,
//   complete,
//   archive,
//   trash
// }

// const sampleReceivedNotification: Notification[] = [
//   {
//     id: 'received_1',
//     title: 'Weekly Newsletter',
//     subject: 'This Week’s Updates',
//     content: 'Stay updated with our latest news and announcements!',
//     date: '2023-12-15',
//     from: 'sender1@example.com',
//     to: 'recipient1@example.com',
//     properties: { read: true, flag: Flag.none }
//   },
//   {
//     id: 'received_2',
//     title: 'Invitation to Webinar',
//     subject: 'Join our Exclusive Webinar',
//     content: 'You are invited to attend our upcoming webinar on emerging trends.',
//     date: '2023-12-14',
//     from: 'sender2@example.com',
//     to: 'recipient2@example.com',
//     properties: { read: false, flag: Flag.important }
//   },
//   {
//     id: 'received_3',
//     title: 'Product Launch Event',
//     subject: 'Exciting Product Launch Announcement!',
//     content: 'Join us for the unveiling of our new product line.',
//     date: '2023-12-13',
//     from: 'sender3@example.com',
//     to: 'recipient3@example.com',
//     properties: { read: false, flag: Flag.none }
//   },
//   {
//     id: 'received_4',
//     title: 'Survey Invitation',
//     subject: 'Your Opinion Matters!',
//     content: 'Help us improve by participating in our quick survey.',
//     date: '2023-12-12',
//     from: 'sender4@example.com',
//     to: 'recipient4@example.com',
//     properties: { read: true, flag: Flag.none }
//   },
//   {
//     id: 'received_5',
//     title: 'Holiday Greetings',
//     subject: 'Season’s Greetings!',
//     content: 'Wishing you joy and happiness this holiday season.',
//     date: '2023-12-11',
//     from: 'sender5@example.com',
//     to: 'recipient5@example.com',
//     properties: { read: false, flag: Flag.important }
//   },
//   {
//     id: 'received_6',
//     title: 'Important Announcement',
//     subject: 'Company Update',
//     content: 'Please read for an important update about our company.',
//     date: '2023-12-10',
//     from: 'sender6@example.com',
//     to: 'recipient6@example.com',
//     properties: { read: true, flag: Flag.important }
//   },
//   {
//     id: 'received_7',
//     title: 'New Job Opening',
//     subject: 'Join Our Team!',
//     content: 'Exciting opportunity! We are hiring for a new position.',
//     date: '2023-12-09',
//     from: 'sender7@example.com',
//     to: 'recipient7@example.com',
//     properties: { read: false, flag: Flag.none }
//   },
//   {
//     id: 'received_8',
//     title: 'Exclusive Offer Inside',
//     subject: 'Special Discount for You!',
//     content: 'Open to reveal an exclusive offer just for you.',
//     date: '2023-12-08',
//     from: 'sender8@example.com',
//     to: 'recipient8@example.com',
//     properties: { read: false, flag: Flag.none }
//   },
//   {
//     id: 'received_9',
//     title: 'Reminder: Upcoming Event',
//     subject: 'Don’t Miss Out!',
//     content: 'A friendly reminder about our upcoming event.',
//     date: '2023-12-07',
//     from: 'sender9@example.com',
//     to: 'recipient9@example.com',
//     properties: { read: true, flag: Flag.important }
//   },
//   {
//     id: 'received_10',
//     title: 'Feedback Request',
//     subject: 'Your Feedback Matters!',
//     content: 'Please provide your valuable feedback on our services.',
//     date: '2023-12-06',
//     from: 'sender10@example.com',
//     to: 'recipient10@example.com',
//     properties: { read: false, flag: Flag.none }
//   },
//   {
//     id: 'received_11',
//     title: 'Seasonal Sale Announcement',
//     subject: 'Limited-time Offer!',
//     content: 'Discover amazing deals in our seasonal sale!',
//     date: '2023-12-05',
//     from: 'sender11@example.com',
//     to: 'recipient11@example.com',
//     properties: { read: true, flag: Flag.none }
//   },
//   {
//     id: 'received_12',
//     title: 'Account Verification',
//     subject: 'Action Required: Verify Your Account',
//     content: 'Verify your account for enhanced security measures.',
//     date: '2023-12-04',
//     from: 'sender12@example.com',
//     to: 'recipient12@example.com',
//     properties: { read: false, flag: Flag.important }
//   },
//   {
//     id: 'received_13',
//     title: 'New Feature Announcement',
//     subject: 'Introducing Exciting New Features!',
//     content: 'Explore the latest features we’ve just rolled out!',
//     date: '2023-12-03',
//     from: 'sender13@example.com',
//     to: 'recipient13@example.com',
//     properties: { read: true, flag: Flag.none }
//   },
//   {
//     id: 'received_14',
//     title: 'Upcoming Maintenance',
//     subject: 'Scheduled Maintenance Notice',
//     content: 'Notice regarding scheduled maintenance activities.',
//     date: '2023-12-02',
//     from: 'sender14@example.com',
//     to: 'recipient14@example.com',
//     properties: { read: false, flag: Flag.important }
//   },
//   {
//     id: 'received_15',
//     title: 'Welcome Aboard!',
//     subject: 'Welcome to Our Platform',
//     content: 'Welcome to our platform! Here’s a guide to get started.',
//     date: '2023-12-01',
//     from: 'sender15@example.com',
//     to: 'recipient15@example.com',
//     properties: { read: true, flag: Flag.none }
//   }
// ];

// const sampleSentNotification: Notification[] = [
//   {
//     id: 'sent_1',
//     title: 'Project Proposal',
//     subject: 'Proposal for Client X',
//     content: 'Attached is the proposal document for our client. Please review.',
//     date: '2023-12-13',
//     to: 'recipient15@example.com',
//     properties: { read: true, flag: Flag.none }
//   },
//   {
//     id: 'sent_2',
//     title: 'Follow-up on Meeting',
//     subject: 'Regarding our discussion',
//     content: 'Just following up on our recent meeting to ensure everything is on track.',
//     date: '2023-12-12',
//     to: 'recipient15@example.com',
//     properties: { read: false, flag: Flag.important }
//   }
//   // Add unique IDs to the rest of the sent Notifications similarly...
// ];

// const sampleSystemNotification: Notification[] = [
//   {
//     id: 'system_1',
//     title: 'Software Update',
//     subject: 'New Features Added!',
//     content: 'Explore the enhancements and new functionalities in the latest software update.',
//     date: '2023-12-11',
//     properties: { read: true, flag: Flag.none }
//   }
//   // Add unique IDs to the rest of the system Notifications similarly...
// ];

// export const sampleInboxContent: { [key in NotificationType]: Notification[] } = {
//   [NotificationType.questionnaire]: sampleReceivedNotification,
//   [NotificationType.referral]: sampleSentNotification,
//   [NotificationType.intake]: [],
//   [NotificationType.system]: sampleSystemNotification
// };

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'csi-birthday-picker-form-field',
  templateUrl: './birthday-picker-form-field.component.html',
  styleUrls: ['./birthday-picker-form-field.component.css']
})
export class BirthdayPickerFormFieldComponent {
  @Input() control: FormControl;
}

import { Component, Input } from '@angular/core';
import { ClinicFormGroup } from '../consult-forms/clinic-form/clinic-form.model';
import { DoctorFormGroup } from '../consult-forms/doctor-form/doctor-form.model';

@Component({
  selector: 'csi-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.css']
})
export class TermsOfServiceComponent {
  @Input() doctorFormGroup: DoctorFormGroup;
  @Input() clinicFormGroup: ClinicFormGroup;

  constructor() {}
}

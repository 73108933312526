import {
  CommunicationMethods,
  InteractionStatusWithKey,
  InteractionTypes,
  SurgeryTypes
} from './../../patients/patient/interactions/interactions.model';
export interface InteractionsSetup {
  types?: InteractionTypes[];
  statuses?: InteractionStatusWithKey;
  communicationMethods?: CommunicationMethods[];
  surgeryTypes?: SurgeryTypes[];
}


export const typeOfSurgeries = [
  'Implant Rotation',
  'INTACTS',
  'Corneal Glue',
  'RETINA',
  'RHEUM',
  'PRK/TSA',
  'LASIK SBK',
  'ICL',
  'RLE',
  'DLS',
  'PTK + CXL',
  'AK Incisions',
  'PTK only',
  'CXL only',
  'Remove & Replace',
  'PRK/TSA TU',
  'LASIK XTRA',
  'LASIK TU',
  'Gland Probing',
  'RLS',
  'Flap lift and reposition',
  'Canceled Sx'
];

export const defaultInteractionTypes = [
  'Pre-Op',
  'Initial',
];

export const requiredInteractionTypes: { [key: string]: boolean } = {
  'Communication': true,
  'Surgery': true,
};

import { Component, Input, OnInit } from '@angular/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

@Component({
  selector: 'csi-mat-spinner-button',
  templateUrl: './mat-spinner-button.component.html',
  styleUrls: ['./mat-spinner-button.component.scss'],
})
export class MatSpinnerButtonComponent implements OnInit {
  @Input() set text(text: string) {
    this.matSpinnerOptions.text = text;
  }
  @Input() set disabled(disabled: boolean) {
    this.matSpinnerOptions.disabled = disabled;
  }

  @Input() set active(active: boolean) {
    this.matSpinnerOptions.active = active;
  }

  @Input() set raised(raised: boolean) {
    this.matSpinnerOptions.raised = raised;
  }

  public matSpinnerOptions: MatProgressButtonOptions = {
    active: false,
    text: this.text,
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate',
    fullWidth: true,
  };

  constructor() {}

  ngOnInit(): void {}
}

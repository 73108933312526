import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'csi-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class LanguageSelectComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;

  constructor(public translocoService: TranslocoService) {}

  ngOnInit() {
    if (!this.control.value) {
      this.control.setValue(this.translocoService.getDefaultLang());
    }
  }
}

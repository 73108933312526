import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IntakeFormViewComponent } from 'src/app/intake-form/intake-form-view/intake-form-view.component';
import { IntakeFormService } from 'src/app/intake-form/intake-form.service';
import { ReferralService } from 'src/app/referral/referral.service';
import {
  HealthCardMode,
  LocationHealthCardModeMap
} from 'src/app/shared/location-select/location.model';
import { Doctor, QuestionnaireType } from '../../../../API';
import { Patient, PatientService } from '../../../core/api/patient.service';
import { StaffService } from '../../../core/api/staff.service';
import { PatientModalComponent } from '../../../shared/consult-forms/patient-modal/patient-modal.component';
import { SendQuestionnaireDialogComponent } from '../../../shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import { NameFormat } from '../../../shared/shared-pipes/person-name.pipe';
import { InteractionsComponent } from '../interactions/interactions.component';
import { PatientFileModalComponent } from './../patient-file/patient-file-modal/patient-file-modal.component';
import { PatientDeleteDialogComponent } from './patient-delete-dialog/patient-delete-dialog.component';

@Component({
  selector: 'csi-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit, OnDestroy {
  private _patient: Patient;
  @Input() set patient(patient: Patient) {
    this._patient = patient;

    if (this.patient.address) {
    const { address, city, province, country, postalCode } = this.patient;
      let isFirst = true;
      this.fullAddress = '';
      [address, city, province, country, postalCode]
        .filter(item => !!item)
        .forEach(item => {
          this.fullAddress += (isFirst ? '' : ', ') + item;
          isFirst = false;
        });
    }
  }
  get patient() {
    return this._patient;
  }
  @Input() showSendQuestionnaireButton = true;
  @Input() readOnly: boolean;
  @Input() printMode: boolean;

  @Output() patientFileSaved: EventEmitter<any> = new EventEmitter();

  public readonly NameFormat = NameFormat;
  public readonly HealthCardMode = HealthCardMode;
  public healthCardConfig: HealthCardMode;
  public isRefer: boolean;
  public intakeFormConfig: any = {};
  public showPatientFileButton = false;
  public fullAddress = '';

  private loggedInDoctor: Doctor;

  private readonly defaultQuestionnaireType = QuestionnaireType.DEQ;
  private readonly LocationHealthCardModeMap = LocationHealthCardModeMap;
  @ViewChild('interactions', { static: false }) patientInteraction: InteractionsComponent;

  constructor(
    private dialog: MatDialog,
    public staffService: StaffService,
    public patientService: PatientService,
    private intakeService: IntakeFormService,
    public referralService: ReferralService
  ) {}

  ngOnInit() {
    this.intakeFormConfig =
      this.patientService.intakeForms && this.patientService.intakeForms.length > 0
        ? this.patientService.intakeForms[0]
        : null;
    this.isRefer = window.location.href.includes('/refer');
    if (this.staffService.isDoctor || this.staffService.isClinicOwner) {
      this.showPatientFileButton = true;
    }
    this.staffService
      .getLoggedInStaff()
      .pipe(untilDestroyed(this))
      .subscribe(doctor => {
        this.loggedInDoctor = doctor;
        this.healthCardConfig = this.LocationHealthCardModeMap[doctor.clinic.country];
      });
  }

  ngOnDestroy() {}

  public onEditClick() {
    PatientModalComponent.open(this.dialog, this.loggedInDoctor, this.patient, null);
  }

  public onSendQuestionnaireClick() {
    SendQuestionnaireDialogComponent.open(
      this.dialog,
      this.defaultQuestionnaireType,
      this.patient,
      {},
      null,
      true
    );
  }

  public onOpenPatientFileClick() {
    setTimeout(() => {
      const patientFileDialogRef = this.dialog.open(PatientFileModalComponent, {
        width: '60rem',
        minWidth: '15rem',
        height: '80vh',
        data: this.patient
      });
      patientFileDialogRef.afterClosed().subscribe(nonModifiableData => {
        if (nonModifiableData) {
          this.patientFileSaved.emit(nonModifiableData);
        }
      });
    });
  }

  public onDeleteClick() {
    this.dialog.open(PatientDeleteDialogComponent, {
      width: '40rem',
      data: this.patient
    });
  }

  public openCreateInteractionsModal() {
    this.patientInteraction.openCreateInteractionsModal();
  }

  public openIntakeForm() {
    this.intakeService
      .getIntakeForm(this.intakeFormConfig.id)
      .pipe(
        mergeMap(patientIntakeForm => {
          if (patientIntakeForm) {
            IntakeFormViewComponent.openIntakeFormDialog(this.dialog, patientIntakeForm);
          }
          return of(null);
        }),
        catchError((err, caught) => {
          throw new Error('Error' + err + caught);
        })
      )
      .subscribe();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { get } from 'lodash-es';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { Assessment } from '../../../../../core/api/assessment.service';
import { ModelUtils } from '../../../../../core/api/model-utils';
import { PatientService } from '../../../../../core/api/patient.service';
import { AssessmentBodyParserService } from '../../../../../core/apollo-links/assessment-body-parser.service';
import { AssessmentBodyUtils } from '../../../../../econsult/assessment-body/assessment-body-utils';
import {
  AssessmentBody,
  DryEyeMedicalHistory
} from '../../../../../econsult/assessment-body/assessment-body.model';
import { ConfirmModalService } from '../../../../confirm-modal/confirm-modal.service';
import { SymptomModes } from '../../../../symptoms/symptom.model';

class MedicalHistoryAndDate {
  dryEyeMedicalHistory: Partial<DryEyeMedicalHistory>;
  date: Date;
  symptomMode: SymptomModes;

  constructor(assessment: Assessment, body: AssessmentBody) {
    this.dryEyeMedicalHistory = body.medicalHistoryForm ? body.medicalHistoryForm : null;
    this.date = new Date(assessment.createdAt);
    this.symptomMode = get(body, 'dryEyeForm.assessmentMethod');
  }
}

@Component({
  selector: 'csi-previous-history-dropdown',
  templateUrl: './previous-history-dropdown.component.html',
  styleUrls: ['./previous-history-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviousHistoryDropdownComponent implements OnInit, OnDestroy {
  @Input() promptModalBeforeSelection: boolean;
  @Input() patientId: string;
  @Input() currentAssessment: Assessment;
  @Output() selectedMedicalHistory: EventEmitter<Partial<DryEyeMedicalHistory>> = new EventEmitter<
    Partial<DryEyeMedicalHistory>
  >();
  public medicalHistoryAndDate$: Observable<MedicalHistoryAndDate[]>;

  private readonly modalInfo = {
    title: 'Overwrite the current information?',
    _body:
      // tslint:disable-next-line: quotemark
      "This will overwrite this assessment's existing medical history with the medical history from the assessment on ",
    getBody: (date: Date) => {
      return this.modalInfo._body + date.toDateString() + '.';
    },
    yesButton: 'Overwrite',
    noButton: 'Cancel'
  };

  constructor(
    private patientService: PatientService,
    private assessmentBodyParserService: AssessmentBodyParserService,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.medicalHistoryAndDate$ = this.patientService.getAssessmentsForPatient(this.patientId).pipe(
      map((assessments: any) =>
        ModelUtils.sortByDate(assessments.items, 'createdAt')
          .filter((assessment: Assessment) =>
            this.isAssessmentInRelativePast(this.currentAssessment, assessment)
          )
          .map((assessment: Assessment) =>
            this.assessmentBodyParserService
              .transform(assessment)
              .pipe(map(body => new MedicalHistoryAndDate(assessment, body)))
          )
      ),
      mergeMap((assessmentBodiesAndDates: Observable<MedicalHistoryAndDate>[]) =>
        iif(() => assessmentBodiesAndDates.length !== 0, forkJoin(assessmentBodiesAndDates), of([]))
      ),
      map((assessmentBodiesAndDates: MedicalHistoryAndDate[]) =>
        assessmentBodiesAndDates.filter(
          assessmentBodyAndDate =>
            assessmentBodyAndDate.symptomMode === SymptomModes.Advanced &&
            AssessmentBodyUtils.isHistoryNonEmpty(assessmentBodyAndDate.dryEyeMedicalHistory)
        )
      ),
      shareReplay(1),
      untilDestroyed(this)
    );
  }

  ngOnDestroy() {}

  public onClick(dryEyeMedicalHistory: Partial<DryEyeMedicalHistory>, date: Date) {
    if (this.promptModalBeforeSelection) {
      this.confirmModalService
        .show(
          this.modalInfo.title,
          this.modalInfo.getBody(date),
          this.modalInfo.yesButton,
          this.modalInfo.noButton
        )
        .pipe(untilDestroyed(this))
        .subscribe(isAccepted => {
          if (isAccepted) {
            this.selectedMedicalHistory.emit(dryEyeMedicalHistory);
          }
        });
    } else {
      this.selectedMedicalHistory.emit(dryEyeMedicalHistory);
    }
  }

  private isAssessmentInRelativePast(relativeTo: Assessment, toCompare: Assessment): boolean {
    return !relativeTo || toCompare.createdAt < relativeTo.createdAt;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QRCodeModule } from 'angularx-qrcode';
import { ConfirmModalModule } from '../confirm-modal/confirm-modal.module';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { SharedModule } from '../shared.module';
import { AttachmentCardComponent } from './attachment-card/attachment-card.component';
import { AttachmentEnlargedComponent } from './attachment-enlarged/attachment-enlarged.component';
import { AttachmentQrCodeModalComponent } from './attachment-qr-code-modal/attachment-qr-code-modal.component';
import { AttachmentsFormFieldComponent } from './attachments-form-field/attachments-form-field.component';
import { WebcamButtonComponent } from './attachments-form-field/webcam-button/webcam-button.component';
import { WebcamModalComponent } from './attachments-form-field/webcam-modal/webcam-modal.component';
import { AttachmentsComponent } from './attachments.component';
import { MobileUploadComponent } from './mobile-upload/mobile-upload.component';
@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    ConfirmModalModule,
    SharedPipesModule,
    SharedModule,
    QRCodeModule
  ],
  declarations: [
    AttachmentsComponent,
    AttachmentsFormFieldComponent,
    AttachmentCardComponent,
    AttachmentEnlargedComponent,
    WebcamButtonComponent,
    WebcamModalComponent,
    AttachmentQrCodeModalComponent,
    MobileUploadComponent
  ],
  exports: [
    AttachmentsComponent,
    AttachmentsFormFieldComponent,
    AttachmentCardComponent,
    MobileUploadComponent,
    WebcamButtonComponent,
    AttachmentEnlargedComponent
  ],
  entryComponents: [AttachmentQrCodeModalComponent, WebcamModalComponent]
})
export class AttachmentsModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigType } from './../../form-template-model';

@Component({
  selector: 'csi-form-template-field',
  templateUrl: './form-template-field.component.html',
  styleUrls: ['./form-template-field.component.css']
})
export class FormTemplateFieldComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;

  public ConfigType = ConfigType;
  constructor() {}

  ngOnInit() {}
}

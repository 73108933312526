import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatSliderChange } from '@angular/material';
import { fabric } from 'fabric';
import { DrawOverImageModalComponent } from '../draw-over-image-modal/draw-over-image-modal.component';
import { DrawSymptomService } from '../draw-symptom.service';

@Component({
  selector: 'csi-draw-over-canvas',
  templateUrl: './draw-over-canvas.component.html',
  styleUrls: ['./draw-over-canvas.component.css']
})
export class DrawOverCanvasComponent implements AfterViewInit {
  @Input() set save(save: boolean) {
    if (save) {
      setTimeout(() => {
        this.canvasFormControl.setValue(
          this.canUndo || this.commentFormControl.value
            ? {
                ...(this.canvas.toJSON() as Object),
                csiComments: this.commentFormControl.value
              }
            : null
        );
        this.dialogRef.close();
      });
    }
  }
  @Input() canvasFormControl: FormControl;

  @Input() public colorsMap = {
    blue: '#2196f3',
    darkgreen: '#006600',
    green: '#00ff00',
    red: '#f44336',
    yellow: '#ffeb3b'
  };

  @Input() commentFormControl: FormControl;

  @HostListener('document:keyup', ['$event'])
  deleteSelected(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.canvas.getActiveObjects().forEach(object => this.canvas.remove(object));
    }
  }

  public readonly BRUSH_WIDTH_MIN = 5;
  public readonly BRUSH_WIDTH_MAX = 15;

  public canUndo: boolean;
  public canRedo: boolean;
  public currentColor = Object.keys(this.colorsMap)[0];
  public isDrawingMode = true;

  private stack: fabric.Object[] = [];

  private canvas: fabric.Canvas;

  constructor(
    private dialogRef: MatDialogRef<DrawOverImageModalComponent>,
    public drawSymptomService: DrawSymptomService
  ) {}

  ngAfterViewInit() {
    this.canvas = new fabric.Canvas('fabric-surface', {
      hoverCursor: 'pointer',
      isDrawingMode: this.isDrawingMode
    });

    if (!this.canvasFormControl.value) {
      this.createBlankCanvas();
    } else this.createCanvasFromJson();

    this.canvas.on('path:created', () => {
      this.stack = [];
      this.setUndoRedo();
    });
  }

  createBlankCanvas() {
    const imageElement = new Image();
    imageElement.src = this.drawSymptomService.IMAGE_SOURCE;
    imageElement.onload = () => {
      const fabricImage = new fabric.Image(imageElement);
      fabricImage.scaleToWidth(this.drawSymptomService.CANVAS_WIDTH);
      fabricImage.scaleToHeight(this.drawSymptomService.CANVAS_HEIGHT);
      this.canvas.setBackgroundImage(fabricImage, this.canvas.renderAll.bind(this.canvas));

      this.setupDrawing();
    };
  }

  createCanvasFromJson() {
    const json = this.canvasFormControl.value;

    this.drawSymptomService.fixCanvasJSON(json);
    this.canvas.loadFromJSON(json, () => {
      this.canvas.renderAll();
      this.setupDrawing();
      this.setUndoRedo();
    });
  }

  setupDrawing() {
    this.canvas.freeDrawingBrush.width = this.BRUSH_WIDTH_MIN;
    this.canvas.freeDrawingBrush.color = this.currentColor;
    this.canvas.renderAll();
  }

  selectColor({ key, value }) {
    this.currentColor = key;
    this.canvas.freeDrawingBrush.color = value;
  }

  selectSize(slider: MatSliderChange) {
    this.canvas.freeDrawingBrush.width = slider.value;
  }

  public clearCanvas() {
    this.canvas.remove(...this.canvas.getObjects());
    this.setUndoRedo();
  }

  public undo() {
    if (this.canUndo) {
      const lastId = this.canvas.getObjects().length - 1;
      const lastObj = this.canvas.getObjects()[lastId];
      this.stack.push(lastObj);
      this.canvas.remove(lastObj);
      this.setUndoRedo();
    }
  }

  public redo() {
    if (this.canRedo) {
      const firstInStack = this.stack.splice(-1, 1)[0];
      if (firstInStack) {
        this.canvas.insertAt(firstInStack, this.canvas.getObjects().length - 1, false);
      }
      this.setUndoRedo();
    }
  }

  enableDrawing() {
    this.isDrawingMode = true;
    this.canvas.isDrawingMode = true;
  }

  selectMode() {
    this.isDrawingMode = false;
    this.canvas.isDrawingMode = false;
  }

  private setUndoRedo() {
    this.canUndo = this.canvas.getObjects().length > 0;
    this.canRedo = this.stack.length > 0;
  }
}

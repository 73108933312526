/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hyd5jtrrxjcebezc5dfoqt6k34.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:d8031acc-b0a0-4c2b-bfb0-0bd20179169b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7BCxCvqzT",
    "aws_user_pools_web_client_id": "4dcefalkm020kulkrdvff0rg53",
    "oauth": {},
    "aws_content_delivery_bucket": "dryeyefrontend-20180923143128--hostingbucket-hdev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://dryeyefrontend-20180923143128--hostingbucket-hdev.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "dryeyeamplifycliuploads-hdev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

import { Injectable } from '@angular/core';
import { AdditionalFluoresceinGradingModalComponent } from '../../modals/custom-modals/additional-fluorescein-grading-modal/additional-fluorescein-grading-modal.component';
import { MgeSecretionModalComponent } from '../../modals/custom-modals/mge-secretion-modal/mge-secretion-modal.component';
import { QuantitativeCornealSensationModalComponent } from '../../modals/custom-modals/quantitative-corneal-sensation-modal/quantitative-corneal-sensation-modal.component';
import { QuantitativeCornealStainingModalComponent } from '../../modals/custom-modals/quantitative-corneal-staining-modal/quantitative-corneal-staining-modal.component';
import { QuantitativeLidWiperComponent } from '../../modals/custom-modals/quantitative-lid-wiper/quantitative-lid-wiper.component';
import { BulbarLimbalModalComponent } from './../../modals/custom-modals/bulbar-limbal-modal/bulbar-limbal-modal.component';
import { NitbutModalComponent } from './../../modals/custom-modals/nitbut-modal/nitbut-modal.component';

@Injectable()
export class ComponentLookupService {
  public findComponentByName(name: string) {
    switch (name) {
      case 'MgeSecretionModalComponent':
        return MgeSecretionModalComponent;

      case 'QuantitativeCornealStainingModalComponent':
        return QuantitativeCornealStainingModalComponent;

      case 'AdditionalFluoresceinGradingModalComponent':
        return AdditionalFluoresceinGradingModalComponent;

      case 'QuantitativeCornealSensationModalComponent':
        return QuantitativeCornealSensationModalComponent;

      case 'NitbutModalComponent':
        return NitbutModalComponent;

      case 'QuantitativeLidWiperComponent':
        return QuantitativeLidWiperComponent;

      case 'BulbarLimbalModalComponent':
        return BulbarLimbalModalComponent;

      default:
        return undefined;
    }
  }
}

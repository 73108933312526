import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { VerificationError } from 'src/app/core/authentication/models/error-models/VerificationError';
import { AuthenticatorMode, matSpinnerOptions } from '../authenticator-modal.component';

export type AttributeToVerify = 'Email' | 'Phone' | 'TOTP';

@Component({
  selector: 'csi-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['../authenticator-modal.component.css', './user-verification.component.css']
})
export class UserVerificationComponent implements OnInit {
  @Input() email: string;
  @Input() password: string;
  @Input() loggedInUser: CognitoUser;
  @Input() authenticatorMode$: BehaviorSubject<AuthenticatorMode>;
  @Input() isUserVerified: EventEmitter<boolean>;
  @Input() rememberDeviceControl: FormControl;

  private _attributeToVerify: AttributeToVerify;
  @Input() set attributeToVerify(attributeToVerify: AttributeToVerify) {
    this._attributeToVerify = attributeToVerify || 'Email';

    if (this._attributeToVerify === 'TOTP') {
      this.verificationInstruction =
        'Please verify using the code provided by the TOTP generating app you used.';
      this.redirectionMessage = 'The code is valid. Redirecting you...';
    } else {
      this.verificationInstruction = `We ${
        this._attributeToVerify === 'Email' ? 'emailed' : 'texted'
      } you a code to verify your
      ${
        this._attributeToVerify === 'Email' ? 'email address' : 'phone number'
      }. Please enter the code.`;

      this.redirectionMessage = `Your ${
        this._attributeToVerify === 'Email' ? 'email address' : 'phone number'
      }is now verified. Redirecting you...`;
    }
  }
  get attributeToVerify(): AttributeToVerify {
    return this._attributeToVerify;
  }

  public readonly VerificationError = VerificationError;
  public readonly verificationCodeExpectedLength = 6;
  public AuthenticatorMode = AuthenticatorMode;
  public didSubmit = false;
  public errorResponse: VerificationError;
  public isResendingVerificationCode = false;
  public didSendVerificationCode = false;
  public matSpinnerOptions = { ...matSpinnerOptions };
  public verificationInstruction = '';
  public redirectionMessage = '';

  public formGroup = new FormGroup({
    verificationCode: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern('^[0-9]*$')
    ])
  });

  public isVerified = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialogRef: MatDialogRef<AuthenticatorMode>,
    private location: Location
  ) {
    this.matSpinnerOptions.text = 'Verify';

    this.formGroup.controls.verificationCode.valueChanges.subscribe(value => {
      if (value) {
        value = value.replace(/ /g, '');
        this.formGroup.controls.verificationCode.setValue(value, { emitEvent: false });
        if (value.length === this.verificationCodeExpectedLength) {
          setTimeout(() => this.submit());
        }
      }
    });
  }

  ngOnInit() {
    if (!this.email) {
      this.initFromUrl();
    }

    this.isUserVerified.subscribe(() => {
      this.isVerified = true;
      if (this.password) {
        this.authenticationService.login(this.email, this.password);
      }
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    });
  }

  protected resendVerification() {
    this.isResendingVerificationCode = true;
    this.didSendVerificationCode = false;
    this.formGroup.controls.verificationCode.disable();

    this.authenticationService.resendVerificationEmail(this.email).subscribe(
      () => {
        this.isResendingVerificationCode = false;
        this.didSendVerificationCode = true;
        this.formGroup.controls.verificationCode.enable();
      },
      error => {
        this.isResendingVerificationCode = false;
        this.errorResponse = error;
        this.formGroup.controls.verificationCode.enable();
      }
    );
  }

  public submit() {
    this.didSubmit = true;

    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;

      (this.attributeToVerify === 'Email'
        ? this.authenticationService.verifySignUp(
            this.email,
            this.formGroup.controls.verificationCode.value
          )
        : this.authenticationService.verifySignIn(
            this.loggedInUser,
            this.formGroup.controls.verificationCode.value,
            this.attributeToVerify === 'Phone' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA'
          )
      )
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          () => {
            if (this.loggedInUser && this.loggedInUser['rememberDevice']) {
              this.loggedInUser.setDeviceStatusRemembered({
                onSuccess: () => {},
                onFailure: () => {}
              });
            }
            this.isUserVerified.emit(true);
          },
          (error: VerificationError) => {
            if (error === VerificationError.ExpiredCode && this.attributeToVerify !== 'TOTP') {
              this.authenticationService.resendVerificationEmail(this.email).subscribe(
                () => {
                  this.errorResponse = VerificationError.ExpiredCode;
                },
                () => {
                  this.errorResponse = VerificationError.Unknown;
                }
              );
            } else if (error === VerificationError.AlreadyConfirmed) {
              this.isUserVerified.emit();
            } else {
              this.errorResponse = error;
            }
          }
        );
    }
  }

  protected clearServerErrors(): void {
    this.errorResponse = undefined;
  }

  get title(): string {
    return this.isVerified
      ? this.attributeToVerify + ' Verified'
      : 'Verify Your ' + this.attributeToVerify;
  }

  private initFromUrl(): void {
    this.email = this.activatedRoute.snapshot.queryParams['user'];
    const code = this.activatedRoute.snapshot.queryParams['code'];
    let redirectTo = this.activatedRoute.snapshot.queryParams['redirectTo'];

    redirectTo = redirectTo ? redirectTo : '/econsult';
    this.formGroup.controls.verificationCode.setValue(code);
  }
}

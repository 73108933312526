import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Patient } from 'src/app/core/api/patient.service';
import { CSVPatientsFormGroup } from '../import-wizard-file-received/csv-patients-form-group.model';
import { MassClinic } from './../../../../API';
import { MassClinicService } from './../../../mass-clinics/mass-clinic.service';

@Component({
  selector: 'csi-import-mass-clinic-patients',
  templateUrl: './import-mass-clinic-patients.component.html',
  styleUrls: ['./import-mass-clinic-patients.component.css']
})
export class ImportMassClinicPatientsComponent implements OnInit {
  @Input() csvPatientsFormGroup: CSVPatientsFormGroup;
  @Output() cancel: EventEmitter<null> = new EventEmitter();
  @Output() goBack: EventEmitter<null> = new EventEmitter();
  @Output() completed: EventEmitter<null> = new EventEmitter();
  private selectedMassClinic: MassClinic;
  public arePatientsMissingInformation: boolean;
  public doPatientsExistInMassClinic: boolean;
  public btnMessage: string;
  public patientMissingInformationMap = {};
  public numberOfMassClinicPatientsToCreate: number;
  public patientExistsInMassClinicMap: {
    [key: string]: boolean;
  } = {};

  public patientList: Patient[] = [];

  public displayColumns: string[];
  public columnNamesMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    action: ''
  };

  constructor(
    private massClinicService: MassClinicService,
    private changeRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit() {
    this.displayColumns = Object.keys(this.columnNamesMap);
    this.patientList = [...(this.csvPatientsFormGroup.controls.patients.value as Patient[])];
    this.selectedMassClinic = this.massClinicService.currentSelectedMassClinic;

    this.selectedMassClinic.massClinicPatients.items.forEach(massClinicPatient => {
      this.patientExistsInMassClinicMap[massClinicPatient.patient.id] = true;
    });

    this.patientList.forEach(patient => {
      this.patientMissingInformationMap[patient.id] = !(patient.email || patient.phone);
    });

    this.doPatientsExistInMassClinic = this.patientList.some(
      patient => this.patientExistsInMassClinicMap[patient.id]
    );

    this.arePatientsMissingInformation = Object.values(this.patientMissingInformationMap).some(
      isCurrentPatientMissingInformation => isCurrentPatientMissingInformation
    );

    this.updateButton();
    this.sortByMissingInformation();
  }

  public createMassClinicPatients() {
    if (this.arePatientsMissingInformation) {
      return;
    }

    const massClinicPatientCreateObservables = [];

    this.patientList.forEach(patient => {
      if (
        !this.patientMissingInformationMap[patient.id] &&
        !this.patientExistsInMassClinicMap[patient.id]
      ) {
        massClinicPatientCreateObservables.push(
          this.massClinicService
            .createMassClinicPatient(
              this.massClinicService.currentSelectedMassClinic.id,
              patient.id
            )
            .pipe(
              tap(massClinicPatientAdded =>
                this.selectedMassClinic.massClinicPatients.items.push(massClinicPatientAdded)
              )
            )
        );
      }
    });

    zip(...massClinicPatientCreateObservables).subscribe(() => this.dialogRef.close());
  }

  public removePatientFromAddingList(index: number) {
    this.updateMassClinicPatientStatus(this.patientList[index], 'DELETE');
    this.patientList.splice(index, 1);
    this.patientList = [...this.patientList];
  }

  public openPatientModal(index: number) {
    const patientInfo = this.patientList[index];
    this.massClinicService
      .openPatientModal(patientInfo)
      .afterClosed()
      .subscribe((modifiedPatient: Patient) => {
        if (modifiedPatient) {
          this.patientList[index] = modifiedPatient;
          this.updateMassClinicPatientStatus(modifiedPatient, 'UPDATE');
          this.patientMissingInformationMap[modifiedPatient.id] = !(
            modifiedPatient.phone || modifiedPatient.email
          );
          this.patientList = [...this.patientList];
          this.changeRef.markForCheck();
        }
      });
  }
  private updateButton() {
    this.numberOfMassClinicPatientsToCreate = 0;
    this.patientList.forEach((patient: Patient) => {
      if (!this.patientExistsInMassClinicMap[patient.id]) {
        this.numberOfMassClinicPatientsToCreate += 1;
      }
    });
  }

  private updateMassClinicPatientStatus(patient: Patient, action: 'DELETE' | 'UPDATE') {
    switch (action) {
      case 'DELETE':
        delete this.patientMissingInformationMap[patient.id];
        this.arePatientsMissingInformation = Object.values(this.patientMissingInformationMap).some(
          isCurrentPatientMissingInformation => isCurrentPatientMissingInformation
        );
        this.numberOfMassClinicPatientsToCreate -=
          this.numberOfMassClinicPatientsToCreate > 0 ? 1 : 0;
        break;
      case 'UPDATE':
        this.patientMissingInformationMap[patient.id] = !(patient.email || patient.phone);
        this.arePatientsMissingInformation = Object.values(this.patientMissingInformationMap).some(
          isCurrentPatientMissingInformation => isCurrentPatientMissingInformation
        );
        break;
    }
  }
  public onFinish() {
    this.dialogRef.close();
  }
  private sortByMissingInformation() {
    const patientMissingInformation = this.patientList.filter(
      patient => this.patientMissingInformationMap[patient.id]
    );
    const patientExistInMassClinic = this.patientList.filter(
      patient => this.patientExistsInMassClinicMap[patient.id]
    );
    const patientsToAdd = this.patientList.filter(patient => {
      if (
        !(
          this.patientMissingInformationMap[patient.id] ||
          this.patientExistsInMassClinicMap[patient.id]
        )
      ) {
        return patient;
      }
    });
    this.patientList = [
      ...patientMissingInformation,
      ...patientsToAdd,
      ...patientExistInMassClinic
    ];
  }
}

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import {
  Clinic,
  GetReferralQuery,
  ListReferralsByPatientIdSortedByCreatedAtQuery,
  ListReferralsByReferralCenterIdSortedByCreatedAtQuery,
  ListReferralsByReferralCenterIdSortedByStatusAndCreatedAtQuery,
  ListReferralsByReferringClinicIdSortedByCreatedAtQuery,
  ListReferralsByReferringClinicIdSortedByStatusAndCreatedAtQuery,
  ModelSortDirection,
  Referral,
  S3Object,
  S3ObjectInput,
  UpdateReferralCenterInput
} from 'src/API';
import { ClinicService } from 'src/app/core/api/clinic.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { AppSyncService } from 'src/app/core/appsync.service';
import { updateReferralCenter } from 'src/graphql/mutations';
import {
  getReferral,
  getReferralCenter,
  listReferralsByParentIdSortedByCreatedAt,
  listReferralsByReferralCenterIdSortedByCreatedAt,
  listReferralsByReferringClinicIdSortedByCreatedAt,
  listReferralsByReferringClinicIdSortedByStatusAndCreatedAt
} from 'src/graphql/queries';
import { GetReferralCenterQuery, ReferralCenter } from '../../API';
import {
  ListReferralsByParentIdSortedByCreatedAtQuery,
  ReferralFormType,
  ReferralType
} from './../../API';
import {
  listReferralsByPatientIdSortedByCreatedAt,
  listReferralsByReferralCenterIdSortedByStatusAndCreatedAt
} from './../../graphql/queries';

interface ReferralFilterRange {
  status?: string;
  createdAt: string;
}

export interface ReferralsWithNextToken {
  referrals: Referral[];
  nextToken: string;
}
export interface ReferralForm {
  name: string;
  type: ReferralFormType;
  file: S3Object | S3ObjectInput | File;
}
export const referralFormTypeKeyFriendlyNameMap: { [values in ReferralFormType]: string } = {
  [ReferralFormType.PREOP]: 'Referral',
  [ReferralFormType.POSTOP]: 'Post Op'
};

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  public referralStatus = [
    'Surgery - Pending',
    'Surgery - Cancelled',
    'Surgery - Booked',
    'Surgery - Completed',
    'Monovision Trail',
    'Clearance By Retinal',
    'Patient Not Candidate',
    'Refractive Consult Booked',
    'Patient Not Reachable',
    'Patient Pregnant / Nursing',
    'Patient Cancelled Consult',
    'No Showed Consult',
    'Candidate - Waiting For a Patient To Book Surgery',
    'Non-Candidate',
    'No Longer Interested',
    'Waiting For Implant Order',
    'Candidate',
    'New Referral',
    'Closed',
    'Contacted Patient',
    'Patient Cancelled',
    'Referral - Declined',
    'Appointment Confirmed',
    'Appointment Scheduled',
    'Referral - Accepted And Wait listed',
    'Other'
  ];

  public subNavigationButtonMap: { [key: string]: string } = {
    dashboard: 'Dashboard',
    reports: 'Reports'
  };

  public referralCenter: ReferralCenter;
  public linkedReferralCenter: ReferralCenter;

  public isAdvancedReferralCenter: boolean;
  public currentReferral: Referral;

  public dryEyeSpecializedDiseaseArea = 'Dry Eye Specialized';

  public defaultDiseaseAreas = [
    'Cornea',
    'Dry Eye',
    'Glaucoma',
    'Retina',
    'Other',
    'Lasik/SBK',
    'PRK/TSA',
    'ICL',
    'Refractive Lens Exchange'
  ];

  public referralTypeFriendlyMap: { [key in ReferralType]: string } = {
    PostProcedure: 'Post Procedure',
    Referral: 'Referral'
  };

  public diseaseAreasForReferralCenter = this.defaultDiseaseAreas;

  public notificationRecipients: string[];

  constructor(
    private staffService: StaffService,
    private appSyncService: AppSyncService,
    private clinicService: ClinicService
  ) {
    this.referralCenter = this.staffService.staff.clinic.referralCenter;
    this.linkedReferralCenter = this.staffService.staff.clinic.linkedReferralCenter;

    const referralCenterToGetDataFrom = this.referralCenter || this.linkedReferralCenter;

    if (referralCenterToGetDataFrom) {
      if (referralCenterToGetDataFrom.diseaseAreas) {
        this.diseaseAreasForReferralCenter = referralCenterToGetDataFrom.diseaseAreas;
      }

      if (referralCenterToGetDataFrom.notificationRecipients) {
        this.notificationRecipients = referralCenterToGetDataFrom.notificationRecipients;
      } else {
        this.notificationRecipients = this.staffService.staff.clinic.doctors.items.map(
          doctor => doctor.email
        );
      }
    }

    this.isAdvancedReferralCenter =
      this.referralCenter && this.referralCenter.advancedReferralCenter;

    const unsortedReferralStatus = this.referralStatus.splice(5).sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });
    this.referralStatus.splice(5, 0, ...unsortedReferralStatus);
    this.updateSubNavigationBar();
  }

  getReferralCenter(id: string): Observable<ReferralCenter> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(getReferralCenter),
          variables: { id }
        })
      ),
      map((result: ApolloQueryResult<GetReferralCenterQuery>) => {
        return result.data.getReferralCenter;
      })
    );
  }

  updateLinkedReferralService(referralCenterId: string): Observable<Clinic> {
    return this.clinicService.updateClinic({
      id: this.staffService.staff.clinic.id,
      clinicLinkedReferralCenterId: referralCenterId
    });
  }

  updateSubNavigationBar() {
    if (this.linkedReferralCenter && this.linkedReferralCenter.advancedReferralCenter) {
      this.subNavigationButtonMap['copayment'] = 'copayment';

      if (this.linkedReferralCenter.educationEmbedLink) {
        this.subNavigationButtonMap['education'] = 'Education';
      }
    }
  }

  getReferrals({
    referralPatientId = null,
    nextToken = null,
    status = '',
    dateRange = [],
    parentReferralId = '',
    limit = 10
  }): Observable<ReferralsWithNextToken> {
    if (referralPatientId) {
      return this.getReferralsForPatient(referralPatientId, nextToken, limit);
    } else if (status) {
      return this.getReferralsBasedOnStatus(status, dateRange, nextToken, limit);
    } else if (parentReferralId) {
      return this.getReferralsForParentReferralId(parentReferralId, null, limit);
    } else {
      return this.getReferralsBasedOnCreatedAt(nextToken, limit);
    }
  }

  public getReferralsBasedOnCreatedAt(
    nextToken: string,
    limit: number
  ): Observable<ReferralsWithNextToken> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(
            this.referralCenter
              ? listReferralsByReferralCenterIdSortedByCreatedAt
              : listReferralsByReferringClinicIdSortedByCreatedAt
          ),
          variables: {
            ...(this.referralCenter
              ? { referralReferralCenterId: this.referralCenter.id }
              : { referringClinicId: this.staffService.staff.clinic.id }),
            sortDirection: ModelSortDirection.DESC,
            limit,
            nextToken
          },
          fetchPolicy: 'network-only'
        })
      ),
      map(
        (
          result: ApolloQueryResult<
            | ListReferralsByReferralCenterIdSortedByCreatedAtQuery
            | ListReferralsByReferringClinicIdSortedByCreatedAtQuery
          >
        ) => {
          const data =
            result.data[
            this.referralCenter
              ? 'listReferralsByReferralCenterIdSortedByCreatedAt'
              : 'listReferralsByReferringClinicIdSortedByCreatedAt'
            ];
          return {
            referrals: data.items,
            nextToken: data.nextToken
          };
        }
      )
    );
  }

  private getReferralsBasedOnStatus(
    status: string = '',
    dateRange: string[] = [],
    nextToken: string = null,
    limit: number
  ): Observable<ReferralsWithNextToken> {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Default to tomorrows date so it covers all of the referrals if not specified
    const tomorrowDateString =
      tomorrow.getFullYear() + '-' + tomorrow.getMonth() + 1 + '-' + tomorrow.getDate();

    // Default to beginning of 2022 so it covers all of the referrals if not specified
    const getReferralsFromDateString = '2022-01-01';
    const firstRange: ReferralFilterRange = {
      createdAt: (dateRange && dateRange[0]) || getReferralsFromDateString
    };

    const secondRange: ReferralFilterRange = {
      createdAt: (dateRange && dateRange[1]) || tomorrowDateString
    };
    if (status) {
      firstRange.status = status;
      secondRange.status = status;
    }

    const statusCreatedAt = {
      between: [firstRange, secondRange]
    };
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(
            this.referralCenter
              ? listReferralsByReferralCenterIdSortedByStatusAndCreatedAt
              : listReferralsByReferringClinicIdSortedByStatusAndCreatedAt
          ),
          variables: {
            ...(this.referralCenter
              ? { referralReferralCenterId: this.referralCenter.id }
              : { referringClinicId: this.staffService.staff.clinic.id }),
            sortDirection: ModelSortDirection.DESC,
            statusCreatedAt,
            limit,
            nextToken
          },
          fetchPolicy: 'network-only'
        })
      ),
      map(
        (
          result: ApolloQueryResult<
            | ListReferralsByReferralCenterIdSortedByStatusAndCreatedAtQuery
            | ListReferralsByReferringClinicIdSortedByStatusAndCreatedAtQuery
          >
        ) => {
          const data =
            result.data[
            this.referralCenter
              ? 'listReferralsByReferralCenterIdSortedByStatusAndCreatedAt'
              : 'listReferralsByReferringClinicIdSortedByStatusAndCreatedAt'
            ];
          return {
            referrals: data.items,
            nextToken: data.nextToken
          };
        }
      )
    );
  }

  getReferralsForParentReferralId(
    parentReferralId: string,
    nextToken: string,
    limit: number
  ): Observable<ReferralsWithNextToken> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listReferralsByParentIdSortedByCreatedAt),
          variables: {
            parentReferralId,
            nextToken,
            limit
          },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<ListReferralsByParentIdSortedByCreatedAtQuery>) => ({
        referrals: result.data.listReferralsByParentIdSortedByCreatedAt.items,
        nextToken: result.data.listReferralsByParentIdSortedByCreatedAt.nextToken
      }))
    );
  }

  getReferralsForPatient(
    referralPatientId: string,
    nextToken: string,
    limit: number
  ): Observable<ReferralsWithNextToken> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listReferralsByPatientIdSortedByCreatedAt),
          variables: {
            referralPatientId,
            nextToken,
            limit
          },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<ListReferralsByPatientIdSortedByCreatedAtQuery>) => ({
        referrals: result.data.listReferralsByPatientIdSortedByCreatedAt.items,
        nextToken: result.data.listReferralsByPatientIdSortedByCreatedAt.nextToken
      }))
    );
  }

  getReferral(id: string): Observable<Referral> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => client.query({ query: gql(getReferral), variables: { id } })),
      map((result: ApolloQueryResult<GetReferralQuery>) => result.data.getReferral)
    );
  }

  updateReferralCenter(input: UpdateReferralCenterInput): Observable<ReferralCenter> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(updateReferralCenter),
          variables: { input }
        })
      ),
      map(result => result.data.updateReferralCenter)
    );
  }

  getLatestReferralResponseForCurrentReferral() {
    if (this.currentReferral && this.currentReferral.referralCenterResponse) {
      return this.currentReferral.referralCenterResponse.sort(
        (a, b) => new Date(b.responseTime).getTime() - new Date(a.responseTime).getTime()
      )[0];
    }
    return null;
  }

  clearCurrentReferralCache() {
    this.currentReferral = null;
  }
}

import { AbstractControl, FormGroup } from '@angular/forms';

interface EssentialData {
  clHoliday: AbstractControl;
  clHolidayNotes: AbstractControl;
  eyeDropsUsedPrior: AbstractControl;
  eyeDropsUsedPriorNotes: AbstractControl;
}

export interface PreOpControls extends Partial<EssentialData> {
  [key: string]: AbstractControl;
}
export class PreOpFormGroup extends FormGroup {
  controls: PreOpControls;
  submitted = false;
  constructor(controlsMap: PreOpControls) {
    super(controlsMap);
  }
}

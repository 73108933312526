import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { countryCodes } from './country-codes';

@Component({
  selector: 'csi-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.css']
})
export class PhoneNumberInputComponent implements OnInit {
  @Input() phoneControl: FormControl;
  @Input() countryCodeControl: FormControl;
  @Input() set actionButtonLabel(actionButtonLabel: string) {
    this.matSpinnerOptions = {
      active: false,
      text: actionButtonLabel,
      spinnerSize: 19,
      mode: 'indeterminate'
    };
  }
  @Input() showActionButton: boolean;
  @Input() set isLoading(isLoading: boolean) {
    this.matSpinnerOptions.active = isLoading;
  }

  @Output() actionButtonPressed = new EventEmitter();

  public countryCodes = countryCodes;
  public matSpinnerOptions: MatProgressButtonOptions = null;

  constructor() {}

  ngOnInit() {
    if (!this.countryCodeControl.value) {
      this.countryCodeControl.setValue(countryCodes[0]);
    }
  }
}

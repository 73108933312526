import { AccountSettingsFormGroup } from 'src/app/authentication/account-settings-form.model';

export function mfaValidator(formGroup: AccountSettingsFormGroup) {
  const error =
    formGroup.controls.preferredMFAType.value === 'SMS' && !formGroup.controls.phone.value
      ? { noPhoneNumber: true }
      : formGroup.controls.preferredMFAType.value === 'SMS' &&
        !formGroup.controls.phoneVerified.value
      ? {
          phoneNotVerified: true
        }
      : formGroup.controls.preferredMFAType.value === 'TOTP' &&
        !formGroup.controls.totpVerified.value
      ? {
          totpNotVerified: true
        }
      : null;

  formGroup.controls.preferredMFAType.setErrors(error);

  return error;
}

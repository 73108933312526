import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { SignInstruction } from '../signs-attachments-modal/signs-attachments-schema';

@Component({
  selector: 'csi-sign-instructions-modal',
  templateUrl: './sign-instructions-modal.component.html',
  styleUrls: ['./sign-instructions-modal.component.css']
})
export class SignInstructionsModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SignInstructionsModalComponent>
  ) {}

  ngOnInit() {}

  public static open(dialog: MatDialog, instructionsConfig: SignInstruction) {
    return dialog.open(SignInstructionsModalComponent, {
      data: { instructionsConfig: instructionsConfig }
    });
  }
}

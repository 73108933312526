import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { phoneNumberValidator } from 'src/app/shared/shared-validators/phone-number-validator';
import { FormTemplateGeneratorService } from '../../form-template-generator.service';
import { FormFieldConfig, PhoneConfig } from './../../../form-template-model';

@Component({
  selector: 'csi-form-template-phone-field-generator',
  templateUrl: './form-template-phone-field-generator.component.html',
  styleUrls: ['./form-template-phone-field-generator.component.css']
})
export class FormTemplatePhoneFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: PhoneConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    this.control.setValidators(
      [this.config.required ? Validators.required : null, phoneNumberValidator].filter(
        validator => !!validator
      )
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Assessment, Patient } from 'src/API';
import { AssessmentService } from '../core/api/assessment.service';
import { parseDateToString } from '../core/api/date.util';
import { SchemaService } from '../shared/symptoms/services/schema/schema.service';
import { StaffService } from './../core/api/staff.service';

interface PatientAndAssessment {
  fullName: string;
  assessments: Assessment[];
}

@Component({
  selector: 'csi-copy-to-emr-modal',
  templateUrl: './copy-to-emr-modal.component.html',
  styleUrls: ['./copy-to-emr-modal.component.scss']
})
export class CopyToEmrModalComponent implements OnInit {
  public isLoading = false;
  public dataSource: PatientAndAssessment[] = [];
  public selectedAssessment: Assessment;
  public selectedAssessmentMethod: string;
  public submitted: boolean = false;
  public clinicCreatedAt: Date;
  public dateToday: Date = new Date();
  public oneDayBeforeEndDate: Date = new Date();
  public oneDayAfterStart: Date = new Date();
  public oneDayBeforeToday: Date = new Date();
  public dateRange = new FormGroup({
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { showAssessmentList: boolean; assessment: Assessment; assessmentMethod: string },
    public schemaService: SchemaService,
    public assessmentService: AssessmentService,
    public staffService: StaffService
  ) {}

  ngOnInit() {
    this.clinicCreatedAt = new Date(this.staffService.staff.clinic.createdAt);

    this.oneDayBeforeToday.setDate(this.dateToday.getDate() - 1);

    this.dateRange.controls.startDate.valueChanges.subscribe(value => {
      if (value) {
        this.dateRange.controls.endDate.enable();
        this.dateRange.controls.endDate.setValue(null);
        this.oneDayAfterStart = value;
        this.oneDayAfterStart.setDate(new Date(value).getDate() + 1);
      } else {
        this.oneDayAfterStart = this.clinicCreatedAt;
        this.dateRange.controls.endDate.disable();
      }
    });

    this.initializeDate();
  }

  initializeDate() {
    this.dateRange.controls.endDate.disable();

    this.oneDayAfterStart.setDate(new Date(this.clinicCreatedAt).getDate() + 1);

    Object.values(this.dateRange.controls).forEach(control => {
      control.setValue(null, { onlySelf: true, emitEvent: false });
      control.markAsUntouched();
    });
  }

  onSubmit() {
    this.submitted = true;

    let oneDayBeforeStart = new Date(this.dateRange.controls.startDate.value);
    //To make date inclusive use a day after chosen start date
    oneDayBeforeStart.setDate(oneDayBeforeStart.getDate() - 1);

    let oneDayAfterEnd = new Date(this.dateRange.controls.endDate.value);
    //To make date inclusive use a day after chosen end date
    oneDayAfterEnd.setDate(oneDayAfterEnd.getDate() + 1);

    this.dataSource = [];
    this.selectedAssessment = null;
    this.selectedAssessmentMethod = null;

    if (!this.dateRange.valid) {
      this.dateRange.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    this.assessmentService
      .listAssessmentsByDateRange([
        parseDateToString(oneDayBeforeStart),
        parseDateToString(oneDayAfterEnd)
      ])
      .subscribe(assessments => {
        this.isLoading = false;

        assessments.sort((a, b) =>
          this.compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), false)
        );

        const groupedAssessments = this.assessmentService.groupAssessmentsByPatient(assessments);

        Object.values(groupedAssessments).forEach(
          (patientAndAssessment: { patient: Patient; assessments: Assessment[] }) => {
            this.dataSource.push({
              fullName:
                patientAndAssessment.patient.firstName.toUpperCase() +
                ' ' +
                patientAndAssessment.patient.lastName.toUpperCase(),
              assessments: patientAndAssessment.assessments
            });
          }
        );

        // Sort the dataSource by fullName
        this.dataSource.sort((a, b) => this.compare(a.fullName, b.fullName, true));
      });

    this.submitted = false;

    this.initializeDate();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  inputForCopyToEmrComponent(assessment: Assessment, assessmentMethod: string) {
    this.selectedAssessment = assessment;
    this.selectedAssessmentMethod = assessmentMethod;
  }
}

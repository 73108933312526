import { Component } from '@angular/core';
import { DataCacheType } from 'src/API';
import { FilterField, FilterGroup, MatchType, SubFilter } from './../filter.model';
import { FilterService } from './../filter.service';

@Component({
  selector: 'csi-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  public filterGroups: FilterGroup[] = [];

  public dataGroupType: DataCacheType;

  constructor(public filterService: FilterService) {}

  addFilterGroup() {
    this.filterGroups.push({
      type: MatchType.and,
      filters: []
    });
  }

  addFilterFieldToGroup(groupIndex: number) {
    this.filterGroups[groupIndex].filters.push({
      field: null,
      operator: null,
      searchValue: null
    } as FilterField);
  }

  addSubFilterFieldToGroup(groupIndex: number) {
    this.filterGroups[groupIndex].filters.push({ type: MatchType.and, filters: [] } as SubFilter);
  }

  addFilterFieldSubFilter(groupIndex: number, subFilterIndex: number) {
    (this.filterGroups[groupIndex].filters[subFilterIndex] as SubFilter).filters.push({
      field: null,
      operator: null,
      searchValue: null
    } as FilterField);
  }

  removeFilterField(index: number, filters) {
    filters.splice(index, 1);
  }

  removeFilterGroup(index) {
    this.filterGroups.splice(index, 1);
  }

  clearAll() {
    this.filterGroups = [];
  }

  valueC(a) {
    console.log(a);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { referralFormTypeKeyFriendlyNameMap } from 'src/app/referral/referral.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { ReferralForm, ReferralFormType } from './../../../../API';
import { ClinicSetupService } from './../../clinic-setup-modal/clinic-setup.service';
import { ReferralFormsSetupActionsModalComponent } from './referral-forms-setup-actions-modal/referral-forms-setup-actions-modal.component';

@Component({
  selector: 'csi-referral-forms-setup',
  templateUrl: './referral-forms-setup.component.html',
  styleUrls: ['./referral-forms-setup.component.css']
})
export class ReferralFormsSetupComponent implements OnInit {
  @Input() referralFormsFormControl: FormControl;
  @Input() isSaving: boolean;
  public currentSelectedFile: File;
  public displayedColumns = ['name', 'file', 'referralFormType','actions'];
  public isClinicAdmin: boolean;

  public referralFormTypeKeyFriendlyNameMap = referralFormTypeKeyFriendlyNameMap;
  public ReferralFormType = ReferralFormType;

  constructor(
    private dialog: MatDialog,
    private confirmModalService: ConfirmModalService,
    private clinicSetupService: ClinicSetupService
  ) {}

  ngOnInit() {
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;
  }

  onFileSelected(event: Event) {
    this.currentSelectedFile = (event.target as HTMLInputElement).files[0];
  }

  openActionsModal(action: 'add' | 'update', referralForm?: ReferralForm, index?: number) {
    this.dialog
      .open(ReferralFormsSetupActionsModalComponent, { data: { action, referralForm } })
      .afterClosed()
      .subscribe((newReferralForm: ReferralForm) => {
        if (newReferralForm) {
          if (action === 'add') {

            this.referralFormsFormControl.setValue([
              ...this.referralFormsFormControl.value,
              newReferralForm
            ]);
          } else {
            this.referralFormsFormControl.value.splice(index, 1, newReferralForm);
            this.referralFormsFormControl.setValue([...this.referralFormsFormControl.value]);
          }
        }
      });
  }

  deleteReferralForm(referralForm: ReferralForm, index: number) {
    this.confirmModalService
      .show(`Are you sure you want to delete ${referralForm.name}?`, '', 'Delete', 'Cancel')
      .subscribe(confirmed => {
        if (confirmed) {
          this.referralFormsFormControl.value.splice(index, 1);
          this.referralFormsFormControl.setValue([...this.referralFormsFormControl.value]);
        }
      });
  }
}

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloError } from 'apollo-client';
import { Logger } from 'aws-amplify';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  finalize,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { CopyToEmrModalComponent } from 'src/app/copy-to-emr-modal/copy-to-emr-modal.component';
import { Clinic, ClinicService } from 'src/app/core/api/clinic.service';
import { QueueFaxResult } from 'src/app/core/api/fax.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { PdfSummaryService } from 'src/app/shared/dry-eye-pdf-export/pdf-summary-export/pdf-summary.service';
import { PhonePipe } from 'src/app/shared/shared-pipes/phone.pipe';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import {
  AssessmentRequestType,
  AssessmentType,
  CognitoGroup,
  Doctor,
  SRFaxStatus
} from '../../../API';
import { AssessmentUtils } from '../../core/api/assessment-utils';
import {
  Assessment,
  AssessmentRequest,
  AssessmentService
} from '../../core/api/assessment.service';
import { ErrorHandlerService } from '../../core/api/error-handler.service';
import { Patient } from '../../core/api/patient.service';
import { StaffService } from '../../core/api/staff.service';
import { AssessmentBody } from '../../econsult/assessment-body/assessment-body.model';
import { EConsultFormGroup, EntryMethod } from '../../econsult/econsult-form.model';
import { ChooseDoctorFromClinicComponent } from '../../shared/choose-doctor-from-clinic/choose-doctor-from-clinic.component';
import { TreatmentFiltersCDSService } from '../../shared/consult-forms/dry-eye-forms/dry-eye-assessment-form/treatment-filters/treatment-filters-cds.service';
import { PreOpService } from '../../shared/consult-forms/dry-eye-forms/pre-op/pre-op.service';
import { SymptomValidatorsService } from '../../shared/consult-forms/validators/symptom-validators/symptom-validators.service';
import { ButtonAppearances } from '../../shared/dry-eye-pdf-export/pdf-export-button/pdf-export-button.component';
import { PDFExportService } from '../../shared/dry-eye-pdf-export/pdf-export.service';
import { FormErrorScrollingService } from '../../shared/form-error-scrolling/form-error-scrolling.service';
import { ComponentCanDeactivate } from '../../shared/guards/save-data/component-can-deactivate';
import { AssessmentFormService } from '../assessment-form.service';
import { EncountersOutletName, EncountersRoutes } from '../encounters.routes';
import { CreateReferralInput, Priority } from './../../../API';
import { ReferralFormService } from './../../referral/referral-form/referral-form.service';
import { ReferralService } from './../../referral/referral.service';
import { PDFType } from './../../shared/dry-eye-pdf-export/dry-eye-pdf-export.component';

interface RouteData {
  assessment: Assessment;
}

interface ParentRouteData {
  loggedInDoctor: Doctor;
  patient: Patient;
}

export type AssessmentRouteData = RouteData & ParentRouteData;

@Component({
  selector: 'csi-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  providers: [TreatmentFiltersCDSService, EConsultFormGroup, SymptomValidatorsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentComponent extends ComponentCanDeactivate implements OnDestroy {
  @ViewChild('doctorPicker', { static: true }) doctorPicker: ChooseDoctorFromClinicComponent;
  @Input() onPatientFileSaved$: Subject<Object>;

  public readonly AssessmentRequestType = AssessmentRequestType;
  public readonly ButtonAppearances = ButtonAppearances;
  public readonly symptomModes = SymptomModes;
  public assessment: Assessment;
  public patientSignDistribution: { [key: string]: number } = {};

  public readonly assessmentRouteData$: Observable<AssessmentRouteData> = combineLatest([
    this.route.data,
    this.route.parent.data
  ] as Observable<RouteData | ParentRouteData>[]).pipe(
    map(([routeData, parentRouteData]) => {
      return { ...(routeData as RouteData), ...(parentRouteData as ParentRouteData) };
    })
  );

  public clinicId: string;
  public chooseDoctorPrepended$: Observable<string> = this.route.url.pipe(
    map(() => (this.isOnNewEncounterRoute ? 'Assign to:' : 'Assigned to:'))
  );

  public title$: Observable<string> = this.route.url.pipe(
    map(() => (this.isOnNewEncounterRoute ? 'New Assessment' : 'Assessment'))
  );

  public assessment$: Observable<Assessment> = this.route.data.pipe(
    map((data: RouteData) => data.assessment),
    tap(assessment => {
      this.assessment = assessment;
      this.updateFromAssessment(assessment);
    }),
    shareReplay(1)
  );

  public loggedInDoctor$: Observable<Doctor> = this.route.parent.data.pipe(
    map((data: ParentRouteData) => data.loggedInDoctor),
    tap(loggedInDoctor => {
      this.doctor = loggedInDoctor;
      this.pdfExportService.preloadClinicLogo(this.doctor.clinic);
    }),
    shareReplay(1)
  );

  public doctor: Doctor;

  public isViewingMode = false;
  public shouldHideResponseForm = false;
  public isAssessmentFormPatched = false;
  public showSeemaButton$ = combineLatest([
    this.loggedInDoctor$,
    this.formGroup.assessmentMethod
  ]).pipe(
    map(() => this.staffService.isStaffPartOfGroup(this.doctor, CognitoGroup.CanReferToSeema))
  );

  public showImageRequiredError = false;
  public isFirstEncounter = true;
  public assessmentMethod = null;
  public assessmentMethodChange = null;

  private _isLocked: boolean;

  private _requestingDoctor: Doctor;
  private get requestingDoctor(): Doctor {
    return this._requestingDoctor ? this._requestingDoctor : this.doctor;
  }

  private _isLoadingRefCount = 0;
  get isLoading(): boolean {
    return this._isLoadingRefCount > 0;
  }
  set isLoading(value: boolean) {
    if (value || this._isLoadingRefCount > 0) {
      this._isLoadingRefCount += value ? 1 : -1;
    }
    this.changeRef.markForCheck();
  }
  public autoSaving = false;

  private lockedSnackBarRef: MatSnackBarRef<SimpleSnackBar> = null;

  private logger = new Logger('Assessment');

  constructor(
    public formGroup: EConsultFormGroup,
    public referralService: ReferralService,
    public referralFormService: ReferralFormService,
    private patientService: PatientService,
    private staffService: StaffService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private assessmentFormService: AssessmentFormService,
    private errorHandlerService: ErrorHandlerService,
    private pdfExportService: PDFExportService,
    private snackBar: MatSnackBar,
    private formErrorScrollingService: FormErrorScrollingService,
    private hostElementRef: ElementRef<HTMLElement>,
    private location: Location,
    private assessmentService: AssessmentService,
    private phonePipe: PhonePipe,
    private changeRef: ChangeDetectorRef,
    private preOpService: PreOpService,
    private pdfSummaryService: PdfSummaryService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup.assessmentMethod.subscribe(value =>
      setTimeout(() => {
        // this.pdfSummaryService.getSeverityLevel(this.formGroup.dryEyeFormControls.dryEyeForm);
        this.assessmentMethodChange = value;
      })
    );

    // this.formGroup.valueChanges.pipe(debounceTime(5000), untilDestroyed(this)).subscribe(() => {
    //   if (this.assessmentMethod !== this.assessmentMethodChange) {
    //     this.assessmentMethod = this.assessmentMethodChange;
    //     return;
    //   }
    //   this.formGroup.dirty &&
    //     this.assessmentMethod !== null &&
    //     this.assessmentMethod !== 'preop' &&
    //     this.autoSave();
    // });

    this.formGroup.valueChanges.subscribe(() => this.changeRef.markForCheck());

    this.formGroup.valueChanges
      .pipe(debounceTime(5000))
      .subscribe(
        () =>
          this.formGroup.dirty &&
          this.assessmentMethod !== null &&
          this.assessmentMethod !== 'preop' &&
          this.autoSave()
      );

    this.formGroup.valueChanges.subscribe(() => this.changeRef.markForCheck());

    this.preOpService.assessmentSaved$.subscribe(() => {
      this.onSave();
    });

    this.onPatientFileSaved$
      .pipe(debounceTime(2000), untilDestroyed(this))
      .subscribe(() => this.autoSave());
  }

  @HostListener('document:keydown.control.s', ['$event'])
  @HostListener('document:keydown.meta.s', ['$event'])
  onSaveKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.onSave();
  }

  _canDeactivate(): boolean {
    this.logger.debug(this.formGroup);
    return !this.formGroup.dirty;
  }

  ngOnDestroy() {
    this.snackBar.dismiss();
  }

  public goBack() {
    this.router.navigate(['.', { outlets: { [EncountersOutletName]: null } }], {
      relativeTo: this.route.parent
    });
  }

  public onSave() {
    this.save().subscribe(() => {
      this.openSnackBar('Assessment saved!', {
        duration: 2000
      });
    });
  }

  public autoSave() {
    this.autoSaving = true;
    this.save().subscribe(() => {
      this.autoSaving = false;
      this.openSnackBar('Assessment auto-saved!', {
        duration: 2000
      });
    });
  }

  public save = (): Observable<Assessment> => {
    this.logger.debug('saving assessment');
    this.isLoading = true;

    this.formGroup.controls.expansionStateFormControl.setValue(this.preOpService.expansionMap);
    this.formGroup.controls.enabledStateFormControl.setValue(this.preOpService.enabledMap);
    const formGroupAssessmentBody = this.formGroup.getRawValue() as AssessmentBody;

    const saveAssessment$ = this.assessmentFormService
      .saveAssessment(
        this.formGroup.controls.id.value,
        this.formGroup.assessmentType,
        formGroupAssessmentBody,
        this.formGroup.attachments,
        this.patient,
        (this.doctorPicker.getSelectedDoctor() as Doctor) || this.staffService.staff,
        this.isLocked
      )
      .pipe(shareReplay(1));

    saveAssessment$.subscribe(
      assessment => {
        if (!this.assessment) {
          const url = this.router
            .createUrlTree(['.', { outlets: { [EncountersOutletName]: assessment.id } }], {
              relativeTo: this.route.parent
            })
            .toString();
          this.patientService.assessments = [assessment, ...this.patientService.assessments];

          this.location.replaceState(url);
        } else {
          const index = this.patientService.assessments.findIndex(
            assessmentToFind => assessmentToFind.id === assessment.id
          );

          this.patientService.assessments[index] = {
            ...this.patientService.assessments[index],
            ...assessment
          };
        }
        this.assessment = assessment;
        this.assessmentService.currentAssessment = assessment;
        this.formGroup.controls.id.setValue(assessment.id);
        this.logger.debug('saved assessment');
        this.formGroup.markAsPristine();
        this.isLoading = false;
        this.isLocked = !!assessment.locked;
      },
      error => {
        this.isLoading = false;
        this.errorHandlerService.handleGraphQLError(error, true);
      }
    );

    return saveAssessment$;
  };
  referAssessment() {
    this.formGroup.submitted = true;

    if (this.formGroup.isAssessmentValid) {
      this.isLoading = true;

      this.save()
        .pipe(
          mergeMap(assessment => {
            this.isLoading = false;

            // Renaming the informationForms to match referral
            let body: any = assessment.body;
            ['patient', 'clinic', 'doctor'].forEach(key => {
              body = body.replace(`${key}InformationForm`, key);
            });

            body = JSON.parse(body);

            body.doctor = {
              firstName: body.doctor.firstName,
              lastName: body.doctor.lastName,
              id: body.doctor.id
            };
            body['diseaseArea'] = this.referralService.dryEyeSpecializedDiseaseArea;

            if (assessment.attachments && assessment.attachments.length > 0) {
              const attachments = [...assessment.attachments];
              attachments.forEach(attachment => delete attachment['__typename']);
              body['assessment'] = assessment;
            }

            body['receiveUpdateNotifications'] = true;
            body['updateNotificationRecipients'] = [this.doctor.email];

            const createReferralInput: CreateReferralInput = {
              referringClinicId: this.doctor.clinic.id,
              referralCenterClinicId: this.referralService.linkedReferralCenter
                .referralCenterClinicId,
              referralReferralCenterId: this.referralService.linkedReferralCenter.id,
              referralPatientId: this.patient.id,
              body: JSON.stringify(body),
              priority: Priority.ROUTINE,
              status: 'New Referral',
              createdAt: assessment.createdAt
            };

            return this.referralFormService.createReferralFromInput(createReferralInput);
          })
        )
        .subscribe(() => {
          this.openSnackBar(
            `Referral has been sent to ${this.referralService.linkedReferralCenter.name}`,
            {
              duration: 4500
            }
          );
          this.isLoading = false;
        });
    } else {
      this.formErrorScrollingService.scrollToFirstInvalidControlInView(this.hostElementRef);
    }
  }

  public onGeneratePDF({ pdfType, lang }: { pdfType: PDFType; lang: string }) {
    // Without this 1 cycle delay the mat-menu does not close
    // properly.
    setTimeout(() => {
      this.generatePDF(pdfType, lang);
    });
  }

  public generatePDF(typeOfPDF: PDFType, lang: string) {
    this.logger.debug('generating pdf');

    this.isLoading = true;

    (!this.isViewingMode ? this.save() : of(null)).subscribe(assessment => {
      this.pdfSummaryService.getSeverityLevel(
        this.getAssessmentBody().dryEyeForm,
        this.getAssessmentBody().dryEyeForm.assessmentMethod
      );

      this.pdfExportService
        .downloadPDFForAssessmentBody(
          this.getAssessmentBody(),
          this.requestingDoctor.clinic,
          typeOfPDF,
          this.isDebugMode,
          lang
        )

        .pipe(
          take(1),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          error: (error: ApolloError) => {
            this.errorHandlerService.handleGraphQLError(error, true);
          }
        });
    });
  }

  private getAssessmentBody(): AssessmentBody {
    const body: AssessmentBody = this.formGroup.getRawValue() as AssessmentBody;
    // TODO: Should probably do this when intial data is loaded in component rather than here
    // See https://gitlab.com/clarity-lv/dry-eye-frontend/issues/196#note_194505229 for more details
    const assignedDoctor = this.doctorPicker.getSelectedDoctor();
    this.assessmentFormService.patchModelsToBody(body, assignedDoctor as Doctor, this.patient);
    body.createdAt = this.assessment.createdAt;
    return body;
  }

  public get hasAssessmentMethod() {
    return !!this.formGroup.dryEyeFormControls.dryEyeForm.controls.assessmentMethod.value;
  }

  // @ts-ignore: public getter and private setter
  public get isLocked() {
    return this._isLocked;
  }

  // @ts-ignore: public getter and private setter
  private set isLocked(isLocked: boolean | null) {
    if (isLocked) {
      this._isLocked = true;

      this.openLockedSnackBar();

      this.formGroup.disable();
    } else {
      this.closeLockedSnackBar();
      this._isLocked = false;
      this.formGroup.enable();
    }
  }

  private openSnackBar(message: string, config: MatSnackBarConfig = {}, action: string = null) {
    this.closeLockedSnackBar();
    this.snackBar
      .open(message, action, config)
      .afterDismissed()
      .subscribe(() => {
        if (this.isLocked) {
          this.openLockedSnackBar();
        }
      });
  }

  private openLockedSnackBar() {
    if (!this.lockedSnackBarRef) {
      this.lockedSnackBarRef = this.snackBar.open('Assessment is locked.', 'Unlock');
      this.lockedSnackBarRef.onAction().subscribe(() => this.unlockAssessment());
    }
  }

  private closeLockedSnackBar() {
    if (this.lockedSnackBarRef) {
      this.lockedSnackBarRef.dismiss();
      this.lockedSnackBarRef = null;
    }
  }

  public lockAssessment() {
    const isCandidateForFaxing = !this.isLocked;
    this.isLocked = true;

    forkJoin({
      assessment: this.save(),
      faxNumber: isCandidateForFaxing
        ? this.clinicService.getEMRIntegrationFaxNumber(this.doctor.clinic.id)
        : of(null)
    })
      .pipe(
        switchMap((result: { assessment: Assessment; faxNumber?: string }) => {
          if (result.faxNumber) {
            return this.faxAssessment(result.faxNumber).pipe(
              switchMap(() => of(result.assessment))
            );
          } else {
            return of(result.assessment);
          }
        })
      )
      .subscribe();
  }

  public unlockAssessment() {
    this.assessmentService
      .unlockAssessment(this.formGroup.controls.id.value)
      .subscribe(assessment => {
        this.isLocked = assessment.locked;
      });
  }

  private faxAssessment(to: string): Observable<QueueFaxResult> {
    const formattedPhone: string = this.phonePipe.transform(to, 'international');
    this.snackBar.open(`Faxing encounter to ${formattedPhone}...`);
    return this.pdfExportService
      .faxAssessment(
        formattedPhone,
        this.getAssessmentBody(),
        this.requestingDoctor.clinic as Clinic,
        this.isDebugMode
      )
      .pipe(
        tap((faxResult: QueueFaxResult) => {
          if (faxResult.Status === SRFaxStatus.Success) {
            this.openSnackBar('Fax queued. Please allow several minutes for receipt.', {
              duration: 4000
            });
          } else {
            this.errorHandlerService.handleError(`Failed to queue fax to ${formattedPhone}`);
            alert(
              `Failed to enqueue fax to ${formattedPhone}. Issue has been reported to CSI Dry Eye support.`
            );
          }
        })
      );
  }

  private updateFromAssessment(assessment: Assessment) {
    if (assessment) {
      if (
        this.patient.assessments &&
        this.patient.assessments.items &&
        this.patient.assessments.items.length > 0
      ) {
        this.isFirstEncounter =
          this.patient.assessments.items.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
          )[this.patient.assessments.items.length - 1].id === assessment.id;
      }

      this.formGroup.assessmentType = assessment.type || AssessmentType.DryEyeSpecialized;

      const body: AssessmentBody = JSON.parse(assessment.body);
      if (body.medicalHistoryForm && body.medicalHistoryForm.medications)
        body.medicalHistoryForm.medications = this.formGroup.normalizeDrugs(
          body.medicalHistoryForm.medications
        );
      this.formGroup.patchValue(body, {}, () => {
        if (AssessmentUtils.hasAssessmentRequests(assessment)) {
          const assessmentRequest = assessment.requests.items[0] as AssessmentRequest;
          this.updateFromAssessmentRequest(assessmentRequest);
          this.changeToViewOnly(false);
        } else {
          this.isAssessmentFormPatched = true;
          if (this.assessmentService.isViewOnly) {
            this.changeToViewOnly(true);
          }
        }

        if (!this.formGroup.entryMethod) {
          this.formGroup.entryMethod = EntryMethod.Manual;
        }
        this.isLocked = assessment.locked;
      });
      this.formGroup.patchAssessmentAttachments(assessment.attachments);
      if (!this.formGroup.manualEntryControls.doctorInformationForm.controls.id.value) {
        this.formGroup.manualEntryControls.doctorInformationForm.controls.id.setValue(
          assessment.doctor.id
        );
      }
      this.clinicId = assessment.clinic.id;
    } else {
      this.isLocked = false;
      this.formGroup.assessmentType = AssessmentType.DryEyeSpecialized;
      this.formGroup.entryMethod = EntryMethod.Manual;
      this.isAssessmentFormPatched = true;

      this.staffService
        .getLoggedInStaff()
        .pipe(untilDestroyed(this))
        .subscribe(doctor => {
          this.formGroup.manualEntryControls.doctorInformationForm.controls.id.setValue(doctor.id);
          this.clinicId = doctor.clinic.id;
        });
    }
  }

  changeToViewOnly(shouldHideResponseForm: boolean) {
    this.isViewingMode = true;
    this.shouldHideResponseForm = shouldHideResponseForm;
    this.changeRef.markForCheck();

    setTimeout(() => {
      this.formGroup.responseFormControls.responseForm.disable();
      this.formGroup.disable();
    });
  }

  openCopyModal() {
    this.dialog.open(CopyToEmrModalComponent, {
      data: {
        showAssessmentList: false,
        assessment: this.assessment,
        assessmentMethod: this.formGroup.dryEyeFormControls.dryEyeForm.controls.assessmentMethod
          .value
      },
      width: '95vw',
      minWidth: '50rem',
      height: '92vh'
    });
  }

  private updateFromAssessmentRequest(assessmentRequest: AssessmentRequest) {
    this.formGroup.patchAssessmentRequestResponse(assessmentRequest);

    this._requestingDoctor = assessmentRequest.doctor as Doctor;
    if (this._requestingDoctor) {
      this.pdfExportService.preloadClinicLogo(this._requestingDoctor.clinic);
    }
    this.isAssessmentFormPatched = true;
  }

  private isFormGroupValid(type: AssessmentRequestType) {
    if (type === AssessmentRequestType.EConsult) {
      const hasImage = this.formGroup.attachments && this.formGroup.attachments.length > 0;
      this.showImageRequiredError = !hasImage;
      this.logger.debug(
        'Checking if E-Consult form has at least one image',
        hasImage,
        this.formGroup.attachments
      );
      return this.formGroup.isAssessmentValid && hasImage;
    } else {
      this.logger.debug('form group is valid - not a manual entry econsult');
      this.showImageRequiredError = false;
      return this.formGroup.isAssessmentValid;
    }
  }

  public get patient(): Patient {
    return (this.route.parent.snapshot.data as ParentRouteData).patient;
  }

  private get isOnNewEncounterRoute(): boolean {
    return this.route.snapshot.url[0].path === EncountersRoutes.New;
  }

  private get isDebugMode(): boolean {
    return this.route.snapshot.paramMap.has('debug');
  }
}

import {
  CompareOperator,
  DisplayFormatEnum,
  FormTemplateFieldAutopopulateConfig,
  TransformValueTypeEnum
} from 'src/app/shared/autopopulate/autopopulate.model';

/**
 * Intake form to patient non-modifiable data linking config.
 */
const medicalConditionKeys = [
  'abnormalBloodClotting',
  'accutaneTreatment',
  'acne',
  'androgenDeficiency',
  'anxiety',
  'arthritis',
  'asthma',
  'bellsPalsy',
  'boneMarrow',
  'cancer',
  'chronicOBstructivePulmonaryDisease',
  'chronicPainSyndrome',
  'coldSoresOrGentialHerpesOrShingles',
  'conditionOther',
  'coronaryArteryDisease',
  'dandruff',
  'demodexSkinInfection',
  'diabetes',
  'drugAllergies',
  'eczema',
  'environmentalAllergies',
  'epilepsy',
  'fertility',
  'fibromyalgia',
  'geneticConditions',
  'glaucoma',
  'gout',
  'graftHostDisease',
  'heartDisease',
  'heatIllness',
  'heaterDermatitis',
  'hepatitis',
  'hivOrAids',
  'htlv',
  'hypercholesterolemia',
  'hypertension',
  'irritableBowelSyndrome',
  'keloidScars',
  'liverDisease',
  'lungDisease',
  'lupus',
  'migraine',
  'osteoporosis',
  'otherSkinConditions',
  'pacemaker',
  'pelvicPain',
  'pigmentationChangeInFace',
  'polycysticOvary',
  'psoriasis',
  'psychiatricConditions',
  'radiotherapy',
  'renalFailure',
  'rheumaticFever',
  'rheumatoidArthritis',
  'rosacea',
  'sarcoidosis',
  'scleroderma',
  'sclerosis',
  'seasonalAllergies',
  'seborrheicDermatitis',
  'seizureDisorder',
  'sensationOfHeatCondition',
  'sexSteroidDeficiency',
  'sjorgenSyndrome',
  'stroke',
  'sunAllergy',
  'thyroid',
  'vitaminDeficiency',
  'vitiligo'
];
const medicalConditionsAutopopulateConfig: {
  [key: string]: FormTemplateFieldAutopopulateConfig[];
} = medicalConditionKeys.reduce((acc, condition) => {
  const config: FormTemplateFieldAutopopulateConfig = {
    incomingDataValuePath: `medicalConditionList.${condition}`,
    mapConditionConfig: {
      comparisonOperator: CompareOperator.equals,
      comparisonValue: true
    },
    transformValueConfig: {
      typeConfig: {
        type: TransformValueTypeEnum.none
      },
      displayFormat: DisplayFormatEnum.none
    }
  };
  acc[condition] = [config];
  return acc;
}, {});

const ocularHistoryKeys = [
  'amblyopia',
  'cataracts',
  'diplopia',
  'dryEyes',
  'eyeTrauma',
  'hyperopia',
  'keratoconus',
  'lasikOrPRK',
  'myopia',
  'nystagmus',
  'orthokeratology',
  'otherPastOcularHistory',
  'pastContactLensDiscomfort',
  'presbyopia',
  'previousEyeInfections',
  'pterygium',
  'retinalConditions',
  'strabismus'
];

const ocularHistoryAutopopulateConfig: {
  [key: string]: FormTemplateFieldAutopopulateConfig[];
} = ocularHistoryKeys.reduce((acc, condition) => {
  const config: FormTemplateFieldAutopopulateConfig = {
    incomingDataValuePath: `ocularHistoryList.${condition}`,
    mapConditionConfig: {
      comparisonOperator: CompareOperator.equals,
      comparisonValue: true
    },
    transformValueConfig: {
      typeConfig: {
        type: TransformValueTypeEnum.none
      },
      displayFormat: DisplayFormatEnum.none
    }
  };
  acc[condition] = [config];
  return acc;
}, {});

const eyeSurgeryKeys = [
  'glaucomaSurgery',
  'cataractSurgery',
  'strabismusSurgery',
  'ptosisSurgery',
  'retinalSurgery',
  'refractiveSurgery',
  'eyelidSurgery',
  'ocularSurfaceSurgery'
];

const eyeSurgeriesAutopopulateConfig: {
  [key: string]: FormTemplateFieldAutopopulateConfig[];
} = eyeSurgeryKeys.reduce((acc, condition) => {
  const config: FormTemplateFieldAutopopulateConfig = {
    incomingDataValuePath: `eyeSurgeriesList.${condition}`,
    mapConditionConfig: {
      comparisonOperator: CompareOperator.equals,
      comparisonValue: true
    },
    transformValueConfig: {
      typeConfig: {
        type: TransformValueTypeEnum.none
      },
      displayFormat: DisplayFormatEnum.none
    }
  };
  acc[condition] = [config];
  return acc;
}, {});

export const nonModifiableAutopopulateConfig: {
  [key: string]: FormTemplateFieldAutopopulateConfig[];
} = {
  ...medicalConditionsAutopopulateConfig,
  ...eyeSurgeriesAutopopulateConfig,
  diabetes: [
    ...medicalConditionsAutopopulateConfig.diabetes,
    {
      incomingDataValuePath: `refractiveSurgeryRelatedConditionKeys.diabetes`,
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  ...ocularHistoryAutopopulateConfig,
  female: [
    {
      incomingDataValuePath: 'gender',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: 'female'
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  male: [
    {
      incomingDataValuePath: 'gender',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: 'male'
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.specifyValue,
          value: true
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  childBirth: [
    {
      incomingDataValuePath: 'childBirth',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: 'yes'
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  otherSurgery: [
    {
      incomingDataValuePath: 'eyeSurgeriesList.surgeryOther',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.equals,
        comparisonValue: true
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ],
  otherSurgeryValue: [
    {
      incomingDataValuePath: 'eyeSurgeriesList.surgeryOther-describe',
      mapConditionConfig: {
        comparisonOperator: CompareOperator.notEmpty
      },
      transformValueConfig: {
        typeConfig: {
          type: TransformValueTypeEnum.none
        },
        displayFormat: DisplayFormatEnum.none
      }
    }
  ]
  // amblyopia: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.amblyopia',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // cataracts: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.cataracts',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // diplopia: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.diplopia',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // dryEyes: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.dryEyes',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // eyeTrauma: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.eyeTrauma',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // hyperopia: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.hyperopia',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // keratoconus: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.keratoconus',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // lasikOrPRK: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.lasikOrPRK',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // myopia: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.myopia',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // nystagmus: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.nystagmus',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // orthokeratology: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.orthokeratology',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // otherPastOcularHistory: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.otherPastOcularHistory',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // pastContactLensDiscomfort: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.pastContactLensDiscomfort',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // presbyopia: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.presbyopia',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // previousEyeInfections: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.previousEyeInfections',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // pterygium: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.pterygium',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // retinalConditions: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.retinalConditions',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // strabismus: [
  //   {
  //     incomingDataValuePath: 'ocularHistoryList.strabismus',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: true
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.none
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ]

  // *****************************************************************************/
  //   accutaneTreatment
  // :
  // false
  // acne
  // :
  // false
  // amblyopia
  // :
  // false
  // androgenDeficiency
  // :
  // false
  // anxiety
  // :
  // false
  // arthritis
  // :
  // false
  // asthma
  // :
  // false
  // autoImmuneDiseaseHistory
  // :
  // false
  // boneMarrow
  // :
  // false
  // cancer
  // :
  // false
  // cancerHistory
  // :
  // false
  // cataractSurgery
  // :
  // false
  // cataractsHistory
  // :
  // false

  // :
  // null
  // chronic
  // :
  // false
  // chronicValue
  // :
  // ""
  // conditionOther
  // :
  // false
  // dandruff
  // :
  // false
  // darkerAfterSunExposure
  // :
  // null
  // demodexSkinInfection
  // :
  // false
  // diabetes
  // :
  // false
  // diabetesHistory
  // :
  // false
  // drugAllergies
  // :
  // false
  // eczema
  // :
  // false
  // education
  // :
  // null
  // ethnicity
  // :
  // null
  // ethnicityOtherValue
  // :
  // null
  // eyeColor
  // :
  // null
  // eyeTrauma
  // :
  // false
  // eyelashModification
  // :
  // null
  // eyelashModificationBrands
  // :
  // null
  // faceSunSensitivity
  // :
  // false
  // fertility
  // :
  // false
  // freckles
  // :
  // null
  // genderOther: [
  //   {
  //     incomingDataValuePath: 'gender',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: 'gender-preferToDescribeGender-describe'
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.specifyValue,
  //         value: true
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // genderOtherValue: [
  //   {
  //     incomingDataValuePath: 'gender',
  //     mapConditionConfig: {
  //       comparisonOperator: CompareOperator.equals,
  //       comparisonValue: 'gender-preferToDescribeGender-describe'
  //     },
  //     transformValueConfig: {
  //       typeConfig: {
  //         type: TransformValueTypeEnum.specifyValue,
  //         value: true
  //       },
  //       displayFormat: DisplayFormatEnum.none
  //     }
  //   }
  // ],
  // :
  // ""
  // glaucoma
  // :
  // false
  // glaucomaHistory
  // :
  // false
  // glaucomaSurgery
  // :
  // false
  // gout
  // :
  // false
  // hematopoieticStemCellTransplantation
  // :
  // null
  // hepatitis
  // :
  // false
  // highBloodPressureHistory
  // :
  // false
  // highCholestrolHistory
  // :
  // false
  // hiv
  // :
  // false
  // htlv
  // :
  // false
  // hypercholesterolemia
  // :
  // false
  // hyperopia
  // :
  // false
  // hypertension
  // :
  // false
  // intersex
  // :
  // false
  // irritableBowelSyndrome
  // :
  // false
  // lasikOrPRK
  // :
  // false
  // liverDisease
  // :
  // false
  // longSunExposure
  // :
  // null
  // lupus
  // :
  // false
  // male
  // :
  // false
  // migraine
  // :
  // false
  // myopia
  // :
  // false
  // naturalHairColor
  // :
  // null
  // nonBinary
  // :
  // false
  // orthokeratology
  // :
  // false
  // osteoporosis
  // :
  // false
  // otherPastOcularHistory
  // :
  // false
  // otherSurgery
  // :
  // false
  // otherSurgeryValue
  // :
  // ""
  // pastContactLensDiscomfort
  // :
  // false
  // pelvicPain
  // :
  // false
  // polycysticOvary
  // :
  // false
  // preferNotToSay
  // :
  // false
  // presbyopia
  // :
  // false
  // psoriasis
  // :
  // false
  // psychiatricConditions
  // :
  // false
  // psychiatricConditionsValue
  // :
  // ""
  // pterygium
  // :
  // false
  // ptosisSurgery
  // :
  // false
  // radiotherapy
  // :
  // false
  // refractiveSurgery
  // :
  // false
  // renalFailure
  // :
  // false
  // retinalDetachmentHistory
  // :
  // false
  // retinalSurgery
  // :
  // false
  // rosacea
  // :
  // false
  // rosaceaHistory
  // :
  // false
  // sarcoidosis
  // :
  // false
  // scleroderma
  // :
  // false
  // sclerosis
  // :
  // false
  // seasonalAllergies
  // :
  // false
  // seborrheicDermatitis
  // :
  // false
  // sexSteroidDeficiency
  // :
  // false
  // sickBuildingSyndrome
  // :
  // null
  // sjorgenSyndrome
  // :
  // false
  // skinColor
  // :
  // null
  // strabismus
  // :
  // false
  // strabismusSurgery
  // :
  // false
  // stroke
  // :
  // false
  // styeOrChalazion
  // :
  // null
  // thyroid
  // :
  // false
  // thyroidValue
  // :
  // ""
  // transgender
  // :
  // false
  // vitaminDeficiency
  // :
  // false
  // vitiligo
  // :
  // false
  // wearsGlasses
  // :
  // false
};

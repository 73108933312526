import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import remove from 'lodash-es/remove';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Gender } from '../../../../API';
import { StaffService } from '../../../core/api/staff.service';
import {
  PatientFormControlType,
  PatientFormGroup
} from '../../../shared/patient-form/patient-form.model';

export enum PatientAttribute {
  'First_Name' = 'First Name',
  'Last_Name' = 'Last Name',
  'Email' = 'Email',
  'Date_Of_Birth' = 'Date Of Birth',
  'HealthCard' = 'Health Card',
  'Gender' = 'Gender',
  'Phone' = 'Phone',
  'Address' = 'Address',
  'Ignore' = 'Ignore'
}
@Injectable({
  providedIn: 'root'
})
export class ImportWizardFileReceivedService {
  private readonly requiredAttributes = [PatientAttribute.First_Name, PatientAttribute.Last_Name];
  private readonly oneOfAttributes = [
    PatientAttribute.Email,
    PatientAttribute.Date_Of_Birth,
    PatientAttribute.Phone
  ];
  public readonly patientAttributeMappings: Map<
    PatientAttribute,
    { formControlType: PatientFormControlType; synonyms: string[] }
  > = new Map();
  constructor(private staffService: StaffService, private datePipe: DatePipe) {
    this.patientAttributeMappings.set(PatientAttribute.First_Name, {
      formControlType: PatientFormControlType.FirstName,
      synonyms: ['firstname']
    });
    this.patientAttributeMappings.set(PatientAttribute.Last_Name, {
      formControlType: PatientFormControlType.LastName,
      synonyms: ['lastname', 'surname']
    });
    this.patientAttributeMappings.set(PatientAttribute.Email, {
      formControlType: PatientFormControlType.Email,
      synonyms: ['email', 'emailaddress']
    });
    this.patientAttributeMappings.set(PatientAttribute.Date_Of_Birth, {
      formControlType: PatientFormControlType.DateOfBirth,
      synonyms: ['birthday', 'dateofbirth', 'dob', 'birthdate']
    });
    this.patientAttributeMappings.set(PatientAttribute.HealthCard, {
      formControlType: PatientFormControlType.HealthCard,
      synonyms: [
        'patient Card',
        'patientHealthCard',
        'health Card',
        'Health Card',
        'healthCard',
        'health card',
        'healthcard'
      ]
    });
    this.patientAttributeMappings.set(PatientAttribute.Gender, {
      formControlType: PatientFormControlType.Gender,
      synonyms: ['gender', 'sex']
    });
    this.patientAttributeMappings.set(PatientAttribute.Phone, {
      formControlType: PatientFormControlType.Phone,
      synonyms: ['phone', 'cell', 'cellphone']
    });
    this.patientAttributeMappings.set(PatientAttribute.Address, {
      formControlType: PatientFormControlType.Address,
      synonyms: ['address']
    });
    this.patientAttributeMappings.set(PatientAttribute.Ignore, {
      formControlType: PatientFormControlType.Other,
      synonyms: ['Ignore']
    });
  }

  public csvDataToPatientFormGroups(
    csvPatientData: string[][],
    csvPatientColumns: string[],
    matSelectors: FormGroup
  ): Observable<PatientFormGroup[]> {
    // Default value for form group is '', so a formControl is set as null when a matSelect is assigned the attribute corresponding to the formControl,
    // but if no column correspond to a formControl then the formControl has the default value
    return this.staffService.getLoggedInStaff().pipe(
      map(loggedInDoctor =>
        csvPatientData.map(row => {
          const patient: PatientFormGroup = new PatientFormGroup();
          csvPatientColumns.forEach((columnName, index) => {
            const patientAttribute = matSelectors.controls[columnName].value as PatientAttribute;
            const patientAttributeValue = this.formatInputToDatabaseFormat(row[index]);
            const patientControlType = this.patientAttributeMappings.get(patientAttribute)
              .formControlType;
            const control = patient.getControl(patientControlType);
            this.assignValueToControl(patientAttribute, patientAttributeValue, control);
          });
          patient.controls.patientDoctorId.setValue(loggedInDoctor.id);
          return patient;
        })
      )
    );
  }

  // Should not use contains since we could have something like 'Doctor Last Name' as a column of the csv, and column matching should be strict rather than generous to prevent accidents
  public createControlsConfig(patientsColumns: string[]): any {
    const controlsConfig = {};
    const unusedPatientAttributes = [
      ...Object.keys(PatientAttribute).map(key => PatientAttribute[key])
    ];
    patientsColumns.forEach(originalCsvColumn => {
      const simplifiedColumn = originalCsvColumn.replace(/number|patient|\W/gi, '').toLowerCase();
      const closestMatch = Array.from(this.patientAttributeMappings.keys()).find(key =>
        this.patientAttributeMappings
          .get(key)
          .synonyms.some(synonym => synonym === simplifiedColumn)
      );
      controlsConfig[originalCsvColumn] =
        closestMatch && unusedPatientAttributes.find(val => val === closestMatch)
          ? closestMatch
          : this.patientAttributeMappings.get(PatientAttribute.Ignore).synonyms[0];
      remove(unusedPatientAttributes, val => val === closestMatch);
    });
    return controlsConfig;
  }

  public createControlsValidator(): ValidatorFn {
    return (selectors: FormGroup) => {
      const controls = Object.keys(selectors.controls).map(key => selectors.controls[key]);
      return this.requiredAttributes.every(attr =>
        controls.some(control => control.value === attr)
      ) && this.oneOfAttributes.some(attr => controls.some(control => control.value === attr))
        ? null
        : { missingRequired: '' };
    };
  }

  public getValidEntries(
    patientFormGroups: PatientFormGroup[],
    matSelectors: FormGroup
  ): boolean[][] {
    return patientFormGroups.map(patientFormGroup => {
      const validAttributes = Object.keys(matSelectors.controls).map(() => true);
      for (const patientAttribute of Object.keys(PatientAttribute).map(
        key => PatientAttribute[key]
      )) {
        const patientControlType = this.patientAttributeMappings.get(patientAttribute)
          .formControlType;
        const patientControl = patientFormGroup.getControl(patientControlType);
        if (patientControl && patientControl.invalid) {
          const columnIndex = Object.keys(matSelectors.controls).findIndex(
            key => matSelectors.controls[key].value === patientAttribute
          );
          validAttributes[columnIndex] = false;
        }
      }
      return validAttributes;
    });
  }

  public removeDuplicateOptions(
    value: PatientAttribute,
    indexToNotChange: number,
    matSelectors: FormGroup
  ) {
    let i = 0;
    for (const control of Object.keys(matSelectors.controls).map(
      key => matSelectors.controls[key]
    )) {
      if (i !== indexToNotChange && control.value === value) {
        control.setValue(PatientAttribute.Ignore);
      }
      i++;
    }
  }

  public csvValidator(csv: string[][], headers: string[]): string | null {
    if (csv.length === 0) {
      return 'No data in CSV File';
    }
    const rowLengths = csv.map(row => row.length).sort((a, b) => a - b);
    let median = { medianLength: -1, numberOfRowsWithLength: 0 };
    while (rowLengths.length !== 0) {
      const numberOfRowsOfLast = rowLengths.lastIndexOf(rowLengths[0]) + 1;
      if (numberOfRowsOfLast > median.numberOfRowsWithLength) {
        median = { medianLength: rowLengths[0], numberOfRowsWithLength: numberOfRowsOfLast };
      }
      rowLengths.splice(0, numberOfRowsOfLast);
    }

    if (median.numberOfRowsWithLength !== csv.length) {
      return `Most rows are ${median.medianLength} entries long, but rows (
      ${csv
        .map((row, index) => (row.length !== median.medianLength ? index : null))
        .filter(val => val !== null)
        .toString()}
      ) do not have the same amount of entries.`;
    }

    const duplicates = [];
    for (const header of headers) {
      if (duplicates[header]) {
        return `Cannot import CSV with duplicate headers. Please remove one of the '${header}' headers.`;
      }
      duplicates[header] = true;
    }

    return null;
  }

  public removeCharFromFile(file: File): Observable<File> {
    const fr = new FileReader();
    fr.readAsText(file);
    const done = new Subject<File>();
    fr.onloadend = this.onFileLoad(done, fr);

    return done.asObservable();
  }

  private assignValueToControl(
    patientAttribute: PatientAttribute,
    patientAttributeValue: string,
    control?: AbstractControl
  ) {
    if (!control) {
      return;
    }
    switch (patientAttribute) {
      case PatientAttribute.Date_Of_Birth:
        let date = '';
        try {
          date = this.datePipe.transform(patientAttributeValue, 'yyyy-MM-dd');
        } catch (e) {
          if ((e as Error).message.includes('InvalidPipeArgument')) {
            date = this.datePipe.transform(
              this.formatDateString(patientAttributeValue),
              'yyy-MM-dd'
            );
          } else {
            console.log(e);
          }
        }
        control.setValue(date);
        break;
      case PatientAttribute.Gender:
        control.setValue(
          patientAttributeValue === ''
            ? null
            : patientAttributeValue.indexOf('f') !== -1
            ? Gender.female
            : Gender.male
        );
        break;
      case PatientAttribute.Ignore:
        break;
      case PatientAttribute.HealthCard:
        control['controls'].number.setValue(patientAttributeValue);
        break;
      default:
        control.setValue(patientAttributeValue === '' ? null : patientAttributeValue);
    }
  }

  private onFileLoad(done: Subject<File>, fr: FileReader): () => void {
    return () => {
      // Replaces all '\r' with '\n' because window systems use \r\n as line breaks while unix uses \n, which messes with PapaParse library
      const newFile = new File([(fr.result as string).replace('\r', '\n')], 'modifiedFile.csv');
      done.next(newFile);
    };
  }

  private formatInputToDatabaseFormat(value: string): string {
    return value.trim().toLowerCase();
  }

  private formatDateString(date: string): string {
    const currentYear = new Date().getFullYear() % 100;

    // Could be split by /-, and space
    const dateSplitArray = date.split(/[\.\-\/\s]/);

    if (dateSplitArray.length > 3) {
      return null;
    }
    const dateCombinations = this.getDateCombinations(dateSplitArray);

    // Returning the possible valid date combination
    return dateCombinations.find(dateCombination =>
      // Add the full year because partial year is provided
      moment(
        Number(dateCombination.split(',')[0]) > currentYear
          ? 19 + dateCombination
          : 20 + dateCombination
      ).isValid()
    );
  }

  private getDateCombinations(dateSplitArray: string[]): string[] {
    const combinations: string[] = [];
    for (let i = 0; i < dateSplitArray.length; i++) {
      const firstElement = dateSplitArray[i];
      const remainingElements = dateSplitArray.slice(0, i).concat(dateSplitArray.slice(i + 1));
      combinations.push(`${firstElement}, ${remainingElements[0]}, ${remainingElements[1]}`);
      combinations.push(`${firstElement}, ${remainingElements[1]}, ${remainingElements[0]}`);
    }
    return combinations;
  }
}

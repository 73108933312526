import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import {
  ConsentSource,
  QuestionnaireRequest,
  QuestionnaireRequestResponse,
  QuestionnaireType,
  UpdateQuestionnaireRequestInput
} from 'src/API';
import { noSort } from 'src/app/core/api/model-utils';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { QuestionnaireRoutePath } from '../questionnaires-routing.module';
import { ConsentFormComponent } from '../simple-questionnaire/consent-form/consent-form.component';
import { SPEEDService } from '../speed/speed.service';
import { Question } from './speed-ii.model';
import { SPEEDIIService } from './speed-ii.service';

export const OPTIONAL_SUFFIX = '-optional';

@Component({
  selector: 'csi-speed-ii',
  templateUrl: './speed-ii.component.html',
  styleUrls: ['./speed-ii.component.scss'],
  providers: [SPEEDIIService, SPEEDService]
})
export class SpeedIIComponent implements OnInit {
  @Input() public readOnly = false;
  @Input() public questionnaireRequestData: QuestionnaireRequest;
  @Input() intakeForm: boolean = false;
  @Input() private customQuestions?: string[] = [];

  public noSort = noSort;
  public formGroupMap = {}

  public submitFormControl = new FormControl();

  public questionnaireBuilders = [this.speedService];
  constructor(
    public speedIIService: SPEEDIIService,
    public speedService: SPEEDService,
    private dialog: MatDialog,
    private questionnaireRequestService: QuestionnaireRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translocoService: TranslocoService
  ) {}

  ngOnInit() {
    (this.intakeForm && this.customQuestions)
      ? (this.formGroupMap = {
          custom: {
            formGroup: this.createFormGroupWithNewControls(
              this.speedIIService.customQuestions(this.customQuestions)
            ),
            schema: this.speedIIService.customQuestions(this.customQuestions)
          }
        })
      : (this.formGroupMap = {
          group3: {
            formGroup: this.createFormGroupWithNewControls(this.speedIIService.groupThreeQuestions),
            schema: this.speedIIService.groupThreeQuestions
          },
          group4: {
            formGroup: this.createFormGroupWithNewControls(this.speedIIService.groupFourQuestions),
            schema: this.speedIIService.groupFourQuestions
          },
          group5: {
            formGroup: this.createFormGroupWithNewControls(this.speedIIService.groupFiveQuestions),
            schema: this.speedIIService.groupFiveQuestions
          },
          group6: {
            formGroup: this.createFormGroupWithNewControls(this.speedIIService.groupSixQuestions),
            schema: this.speedIIService.groupSixQuestions
          }
        });
    this.submitFormControl.setValue(this.questionnaireRequestData.consentsToPrivacyForm);
  }

  createFormGroupWithNewControls(questionGroup: Question[]) {
    const formGroup = new FormGroup(
      questionGroup.reduce((acc, question: Question) => {
        if (question.fieldConfig.type === 'Checkbox') {
          const checkboxFormControls = {};

          Object.keys(question.fieldConfig.options).forEach(option => {
            checkboxFormControls[question.id + '-' + option] = new FormControl(null);
          });

          return { ...acc, ...checkboxFormControls };
        }

        return {
          ...acc,
          [question.id]: new FormControl(
            { value: null, disabled: question.conditionConfig || false },
            [Validators.required]
          )
        };
      }, {})
    );

    this.bindConditions(questionGroup, formGroup);

    return formGroup;
  }

  bindConditions(questionGroup: Question[], formGroup: FormGroup) {
    questionGroup.forEach(question => {
      if (question.conditionConfig) {
        const conditionConfig = question.conditionConfig;
        const compareControl = formGroup.controls[conditionConfig.controlKey];
        compareControl.valueChanges.subscribe(() => {
          if (
            (conditionConfig.condition === 'equals' &&
              compareControl.value === conditionConfig.value) ||
            (conditionConfig.condition === 'notEquals' &&
              compareControl.value !== conditionConfig.value)
          ) {
            formGroup.controls[question.id].enable();
          } else formGroup.controls[question.id].disable();
        });
      }
    });
  }

  openPrivacyConsentForm(event) {
    event.preventDefault();

    this.dialog.open(ConsentFormComponent, {
      data: { country: this.questionnaireRequestData.country }
    });
  }

  submit() {
    if (this.submitFormControl.value) {
      const input: UpdateQuestionnaireRequestInput = {
        id: this.questionnaireRequestData.id,
        completedAt: new Date().toISOString(),
        response: {
          answers: JSON.stringify({
            [QuestionnaireType.SPEED]: this.speedService.formGroup.value,
            [QuestionnaireType.SPEEDII]: Object.values(this.formGroupMap).reduce(
              (acc: any, formGroupSchema: any) => ({
                ...acc,
                ...formGroupSchema.formGroup.value
              }),
              {}
            )
          }),
          scores: JSON.stringify({
            [QuestionnaireType.SPEED]: this.speedService.score,
            [QuestionnaireType.SPEEDII]: this.calculateScores()
          }),
          consent: {
            agreedToTerms: true,
            consentsToResearch: true,
            consentSource: ConsentSource.Patient,
            consentsToPrivacyForm: true
          }
        }
      };

      this.questionnaireRequestService.updateQuestionnaireRequest(input).subscribe(
        () =>
          this.redirectToSuccessPage({
            __typename: 'QuestionnaireRequestResponse',
            answers: input.response.answers,
            scores: input.response.scores,
            consent: { __typename: 'QuestionnairePatientConsent', ...input.response.consent }
          }),
        (error: ApolloError) => {
          console.error(error);
          window.alert('We have encountered an error, please contact support@csidryeye.com.');
        }
      );
    }
  }

  private redirectToSuccessPage(response: QuestionnaireRequestResponse) {
    this.router.navigate([`../${QuestionnaireRoutePath.ThankYou}`], {
      relativeTo: this.activatedRoute,
      queryParams: {
        isPositive: this.questionnaireRequestService.isAtRisk(response.scores),
        lang: this.translocoService.getActiveLang()
      }
    });
  }

  calculateScores() {
    let score = 0;
    this.speedIIService.speedIISchema.questions.forEach(question => {
      if (question.fieldConfig.type === 'Checkbox') {
        Object.keys(question.fieldConfig.options).forEach(optionKey => {
          const controlValue = this.formGroupMap['group' + question.group.number].formGroup
            .controls[question.id + '-' + optionKey].value;

          score += (controlValue && (this.speedIIService.defaultScores[optionKey] || 0)) || 0;
        });
      } else {
        const controlValue = this.formGroupMap['group' + question.group.number].formGroup.controls[
          question.id
        ].value;
        score += this.getScoreForQuestion(controlValue, question);
      }
    });

    return score;
  }

  getScoreForQuestion(value, question: Question) {
    if (question.customScore) {
      return question.customScore(value);
    } else {
      return this.speedIIService.defaultScores[value] || 0;
    }
  }
}

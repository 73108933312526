import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import chunk from 'lodash-es/chunk';
import flatten from 'lodash-es/flatten';
import identity from 'lodash-es/identity';
import pickBy from 'lodash-es/pickBy';
import uniq from 'lodash-es/uniq';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CreatePatientInput, Doctor, ModelPatientFilterInput } from '../../../../API';
import { ClinicService } from '../../../core/api/clinic.service';
import { Patient, PatientList, PatientService } from '../../../core/api/patient.service';
import { StaffService } from '../../../core/api/staff.service';
import { PatientFormGroup } from '../../../shared/consult-forms/patient-form/patient-form.model';

@Injectable({
  providedIn: 'root'
})
export class ImportWizardCheckExistingPatientsService {
  constructor(
    private staffService: StaffService,
    private clinicService: ClinicService,
    private patientService: PatientService
  ) {}

  public reduceToUniqueKeys(array: Object[]): string[] {
    return array.map(val => Object.keys(val)).reduce((prev, curr) => uniq([...prev, ...curr]));
  }

  public getAndPatchDuplicates(patientFormGroups: PatientFormGroup[]): Observable<boolean[]> {
    return this.staffService.getLoggedInStaff().pipe(
      switchMap(doctor =>
        forkJoin(
          this.createFilterFindPatientDuplicates(patientFormGroups).map(filter =>
            this.clinicService
              .getPatientsForClinic(
                doctor.clinic.id,
                false,
                0,
                'network-only',
                undefined,
                undefined,
                filter
              )
              .pipe(
                map(patientList => {
                  return { list: patientList, doctor: doctor };
                })
              )
          )
        )
      ),
      map((listAndDoctors: { list: PatientList; doctor: Doctor }[]) => {
        const allPatients = flatten(listAndDoctors.map(lAndD => lAndD.list.items));
        const duplicates = patientFormGroups.map(() => false);
        patientFormGroups.forEach((patientFormGroup: PatientFormGroup, index: number) => {
          const foundPatient = allPatients.find(patient =>
            this.doesPatientFormGroupEqualPatientObject(patientFormGroup, patient)
          );
          if (foundPatient) {
            duplicates[index] = true;
            // Removing falsey values before patching, since we'd want to keep values from csv if database patient has no value for an attribute
            patientFormGroup.patchValue(pickBy(foundPatient, identity));
            if (!!foundPatient['createdAt']) {
              patientFormGroup.registerControl(
                'createdAt',
                new FormControl(new Date(foundPatient['createdAt']).toDateString())
              );
            }
            patientFormGroup.controls.patientDoctorId.setValue(listAndDoctors[0].doctor.id);
          }
        });
        return duplicates;
      })
    );
  }

  public createAndPatchNewPatients(patients: PatientFormGroup[]): Observable<Patient[]> {
    return patients.length !== 0
      ? forkJoin(
          ...patients.map(patient => {
            if (patient.controls['createdAt']) {
              patient.controls['createdAt'].setValue(undefined);
            }
            patient.controls.id.setValue(undefined);
            return this.patientService.createPatient(patient.toApiInput() as CreatePatientInput);
          })
        ).pipe(
          tap((newPatients: Patient[]) => {
            patients.map((patient, index) => patient.patchValue(newPatients[index]));
          })
        )
      : of(null);
  }

  public createFilterFindPatientDuplicates(
    patientFormGroups: PatientFormGroup[]
  ): ModelPatientFilterInput[] {
    // AWS limits the size of filters to 4kb, requiring multiple queries with smaller filters instead of one large filter.
    // See https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Limits.html#limits-expression-parameters
    const patientFilters = chunk(patientFormGroups, 50).map(formGroups => {
      return {
        or: formGroups.map(patientFormGroup => {
          /*
          For mandatory ones, we can assume that these values will already be filled (and will show errors if there are missing data for these columns)
          For optional ones, we won't require that these are all required
         */
          const filter: ModelPatientFilterInput = {};
          const fn = patientFormGroup.controls.firstName.value;
          const ln = patientFormGroup.controls.lastName.value;

          filter.firstName = { eq: fn };
          filter.lastName = { eq: ln };

          return filter;
        })
      };
    });

    return patientFilters;
  }

  // TODO: We should use patientFormGroup.value instead of patientFormGroup
  private doesPatientFormGroupEqualPatientObject(
    patientFormGroup: PatientFormGroup,
    patientObject: Patient
  ): boolean {
    // TODO Pujan https://gitlab.com/clarity-lv/dry-eye-frontend/-/issues/305#note_333049136
    const nameMatches =
      patientFormGroup.controls.firstName.value === patientObject.firstName &&
      patientFormGroup.controls.lastName.value === patientObject.lastName;

    const isEmptyOrNull = val => {
      return val === '' || val === null;
    };

    // We can assume that at least 1 of patientFormGroup's email, dob, phone will be assigned (therefore not be equal to '')
    // Though, if we had a patient that had null email but filled out dob/phone, and searched for null email, should that be considered a match?
    // Ideally, we try matching non-null strings, and if there are none defined (NOT none that are equal), then we try matching for nulls.

    // TODO: Use clinic country instead of defaulting to CA. See https://gitlab.com/clarity-lv/dry-eye-frontend/-/issues/311
    const formGroupPhone = parsePhoneNumberFromString(
      patientFormGroup.controls.phone.value || '',
      'CA'
    );
    const patientObjectPhone = parsePhoneNumberFromString(patientObject.phone || '', 'CA');

    const oneNonEmptyOrNullValueMatches =
      (patientFormGroup.controls.email.value === patientObject.email &&
        !isEmptyOrNull(patientFormGroup.controls.email.value)) ||
      (patientFormGroup.controls.dateOfBirth.value === patientObject.dateOfBirth &&
        !isEmptyOrNull(patientFormGroup.controls.dateOfBirth.value)) ||
      (formGroupPhone &&
        patientObjectPhone &&
        formGroupPhone.nationalNumber === patientObjectPhone.nationalNumber &&
        !isEmptyOrNull(patientFormGroup.controls.phone.value));

    const allOptionalValuesFalsey =
      !patientFormGroup.controls.email.value &&
      !patientFormGroup.controls.phone.value &&
      !patientFormGroup.controls.dateOfBirth.value &&
      !patientObject.email &&
      !patientObject.phone &&
      !patientObject.dateOfBirth;

    const oneNullValueMatches =
      (patientFormGroup.controls.email.value === null && patientObject.email === null) ||
      (patientFormGroup.controls.phone.value === null && patientObject.phone === null) ||
      (patientFormGroup.controls.dateOfBirth.value === null && patientObject.dateOfBirth === null);

    return (
      nameMatches &&
      (oneNonEmptyOrNullValueMatches || (allOptionalValuesFalsey && oneNullValueMatches))
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { S3Object, UrlWithContentType } from 'src/app/shared/attachments/s3-attachment.service';
import { ConfirmModalService } from '../../confirm-modal/confirm-modal.service';
import { DataSrcService } from '../../enlargeable-media/data-src.service';
import { EnlargeableMediaComponent } from '../../enlargeable-media/enlargeable-media.component';
import { ContentType } from '../../shared-pipes/image-to-data-uri.pipe';
import { Attachment } from '../attachments.component';
import { S3AttachmentService } from '../s3-attachment.service';
import { AttachmentToUriPipe } from './../../shared-pipes/attachment-to-uri.pipe';

@Component({
  selector: 'csi-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: ['./attachment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AttachmentToUriPipe]
})
export class AttachmentCardComponent implements OnInit {
  @Output() removedAttachment = new EventEmitter<Attachment>();
  @Input() attachment: Attachment;
  @Input() hideDownloadButton: boolean;
  @Input() hideAllButtons: boolean;
  @Input() hideTitle: boolean;
  @Input() isReadOnly: boolean;
  @Input() customCardTitle: string;
  @Input() preventExpand: boolean;
  @Input() preloadedAttachments: UrlWithContentType[] = [];
  @Input() preloadedAttachment: UrlWithContentType;

  @ViewChild('enlargeableImage', { static: false }) enlargeableImage: EnlargeableMediaComponent;

  public isS3Object: boolean;

  public attachmentUri;

  constructor(
    private confirmModalService: ConfirmModalService,
    public s3AttachmentService: S3AttachmentService,
    public dataSrcService: DataSrcService,
    private attachmentToUriPipe: AttachmentToUriPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.preloadedAttachment) {
      this.attachmentUri = this.preloadedAttachment;
    } else {
      this.isS3Object = !!(this.attachment as S3Object).bucket;

      this.attachmentToUriPipe.transform(this.attachment).subscribe(attachmentUri => {
        this.attachmentUri = attachmentUri;

        if (!attachmentUri) {
          this.removedAttachment.emit(this.attachment);
        } else {
          this.preloadedAttachments.push({
            ...attachmentUri,
            fileName: this.dataSrcService.getFileName(this.attachment)
          });
        }

        this.changeDetectorRef.markForCheck();
      });
    }
  }

  removeAttachment(attachmentToRemove: Attachment, event: MouseEvent) {
    event.stopImmediatePropagation();
    this.confirmModalService
      .show(
        'Confirm File Removal',
        `Are you sure you want to remove '${this.getFileName(attachmentToRemove)}'?`,
        'Remove File',
        'Cancel'
      )
      .subscribe(userConfirmedRemoval => {
        if (userConfirmedRemoval) {
          this.removedAttachment.emit(attachmentToRemove);
        }
      });
  }

  public getFileName(attachment: Attachment): string {
    return this.dataSrcService.getFileName(attachment);
  }

  public expandImage(event: Event, mime: ContentType) {
    if (this.canEnlarge(mime)) {
      this.enlargeableImage.expandImage(event);
    }
  }

  public canEnlarge(mime: ContentType): boolean {
    if (this.preventExpand) {
      return false;
    }
    return this.dataSrcService.canEnlarge(mime);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenderService {
  public genderMap: { [key: string]: string } = {
    male: 'Male',
    female: 'Female',
    transgender: 'Transgender',
    nonBinary: 'Non-binary/Non-conforming',
    intersex: 'Intersex',
    preferNotToSay: 'Prefer not to say',
    genderOther: 'Prefer to describe'
  };

  otherGenderKey = 'genderOther';

  otherGenderDescriptionLabel = 'Please describe';

  constructor() {}
}

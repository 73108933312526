import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[csiAlwaysShowTicks]'
})

/** By default MatSlider only shows ticks on hover, this directive forces ticks to always show. */
export class AlwaysShowTicksDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.showBackgroundTicks();
    this.showLastTick();

    // mat-slider-wrapper
    // ticks are updated on mouseenter, see _onMouseenter method
    // https://github.com/angular/material2/blob/master/src/lib/slider/slider.ts#L494
    this.dispatchMouseEnter();
  }

  private showBackgroundTicks() {
    const sliderTicks = this.elementRef.nativeElement.getElementsByClassName('mat-slider-ticks')[0];
    sliderTicks.style.opacity = 1;
  }

  private showLastTick() {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = '.mat-slider-has-ticks .mat-slider-wrapper::after { opacity: 1}';
    const sliderWrapper = this.elementRef.nativeElement.getElementsByClassName(
      'mat-slider-wrapper'
    )[0];
    sliderWrapper.insertAdjacentElement('beforebegin', styleElement);
  }

  private dispatchMouseEnter() {
    this.elementRef.nativeElement.dispatchEvent(
      new MouseEvent('mouseenter', {
        view: window,
        bubbles: true,
        cancelable: true
      })
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApolloError } from 'apollo-client';
import { finalize } from 'rxjs/operators';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { FormTemplateGeneratorService } from 'src/app/form-template/form-template-generator/form-template-generator.service';
import { Question } from 'src/app/questionnaires/speedII/speed-ii.model';
import { QuestionnaireScorePipe } from 'src/app/shared/shared-pipes/questionnaire-score.pipe';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { QuestionnaireRequestService } from './../../../core/api/questionnaire-request.service';
import { FormTemplateInputFormGroup } from './../../../form-template/form-template-input-form-group';
import { DEQ5Service } from './../../../questionnaires/deq5/deq5.service';
import { OSDIService } from './../../../questionnaires/osdi/osdi.service';
import { QuestionnairePdfExportService } from './../../../questionnaires/questionnaire-pdf-export.service';
import { SPEEDService } from './../../../questionnaires/speed/speed.service';
import { SPEEDIIService } from './../../../questionnaires/speedII/speed-ii.service';

interface QuestionnaireResponseModalData {
  questionnaire: QuestionnaireRequest;
  questionnaires: QuestionnaireRequest[];
  initialComponentQuestionnaireTypeToShow?: QuestionnaireType;
  patientId?: string;
}

interface QuestionAndResponse {
  title: string;
  response: any;
  label: string;
}

interface QuestionAndResponseGroup {
  questionsAndResponses: QuestionAndResponse[];
  title: string;
}

interface QuestionsResponseData {
  questionsAndResponsesGroup?: QuestionAndResponseGroup[];
  totalScore?: string;
  formGroupWithValues?: FormGroup;
  rawResponse?: any;
  schema?: any;
}

@Component({
  selector: 'csi-questionnaire-response-modal',
  templateUrl: './questionnaire-response-modal.component.html',
  styleUrls: ['./questionnaire-response-modal.component.css'],
  providers: [DEQ5Service, OSDIService, SPEEDService, SPEEDIIService, QuestionnaireScorePipe]
})
export class QuestionnaireResponseModalComponent implements OnInit {
  public selectedIndex = 0;
  public serviceTypeMap = {
    [QuestionnaireType.DEQ]: this.deq5Service,
    [QuestionnaireType.OSDI]: this.osdiService,
    [QuestionnaireType.SPEED]: this.speedService,
    [QuestionnaireType.SPEEDII]: this.speedIIService
  };

  public questionsResponseData: {
    [key in QuestionnaireType]?: QuestionsResponseData;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionnaireResponseModalData,
    public questionnaireRequestService: QuestionnaireRequestService,
    private formTemplateGeneratorService: FormTemplateGeneratorService,
    public deq5Service: DEQ5Service,
    public speedService: SPEEDService,
    public osdiService: OSDIService,
    public speedIIService: SPEEDIIService,
    public questionnaireScorePipe: QuestionnaireScorePipe,
    private questionnairePdfExportService: QuestionnairePdfExportService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  static open(
    dialog: MatDialog,
    questionnaire: QuestionnaireRequest,
    questionnaires: QuestionnaireRequest[] = null,
    patientId?: string,
    initialComponentQuestionnaireTypeToShow?: string
  ): MatDialogRef<QuestionnaireResponseModalComponent> {
    return dialog.open(QuestionnaireResponseModalComponent, {
      data: {
        questionnaire,
        questionnaires,
        patientId: patientId || null,
        initialComponentQuestionnaireTypeToShow
      },
      width: '80vw'
    });
  }
  ngOnInit() {
    // For singular questionnaires
    if (this.data.questionnaire) {
      const type = this.data.questionnaire.type;

      this.questionsResponseData = this.getQuestionAndResponseForQuestionnaireType(
        type,
        this.data.questionnaire
      );

      // For OSIDAndSPEED or SPEEDII to display the tab of the specified component questionnaire
      if (this.data.initialComponentQuestionnaireTypeToShow) {
        const componentQuestionnaireType = this.data.initialComponentQuestionnaireTypeToShow.toUpperCase();

        if (type === QuestionnaireType.OSDIAndSPEED) {
          if (componentQuestionnaireType === QuestionnaireType.SPEED) {
            this.selectedIndex = 1;
          }
        } else if (type === QuestionnaireType.SPEEDII) {
          if (componentQuestionnaireType === QuestionnaireType.SPEEDII) {
            this.selectedIndex = 1;
          }
        }
      }
    }

    // For multiple questionnaires
    if (this.data.questionnaires) {
      const latestQuestionnaireCompletedAt = {};
      this.questionsResponseData = {};
      this.data.questionnaires.forEach(currentQuestionnaire => {
        const currentQuestionnaireCompletedAt = new Date(
          currentQuestionnaire.completedAt
        ).getTime();

        let questionnaireType = currentQuestionnaire.type;
        if (questionnaireType === 'CUSTOM') {
          questionnaireType =
            (currentQuestionnaire.config as any) instanceof Object
              ? currentQuestionnaire.config['schema']['abbreviation']
              : JSON.parse(currentQuestionnaire.config).schema.abbreviation;
        }
        if (
          !latestQuestionnaireCompletedAt[questionnaireType] ||
          latestQuestionnaireCompletedAt[questionnaireType] < currentQuestionnaireCompletedAt
        ) {
          latestQuestionnaireCompletedAt[questionnaireType] = currentQuestionnaireCompletedAt;

          this.questionsResponseData = {
            ...this.questionsResponseData,
            ...this.getQuestionAndResponseForQuestionnaireType(
              currentQuestionnaire.type,
              currentQuestionnaire
            )
          };
        }
      });
    }
  }

  generatePDF({ key, value }: { key: string; value: any }) {
    // this.formTemplateGeneratorService.formatForPDF = true;
    this.loadingSpinnerService.show();
    const questionnaireKey = value.schema ? value.schema.abbreviation : key;
    this.questionnairePdfExportService
      .downloadQuestionnaireAsPDF(questionnaireKey, value, this.data.patientId, false)
      .pipe(
        finalize(() => {
          this.loadingSpinnerService.hide();
          // this.formTemplateGeneratorService.formatForPDF = false;
        })
      )
      .subscribe({
        error: (error: ApolloError) => {
          this.errorHandlerService.handleGraphQLError(error, true);
        }
      });
  }

  getQuestionAndResponseForQuestionnaireType(
    type: QuestionnaireType,
    questionnaire: QuestionnaireRequest
  ) {
    if (type === QuestionnaireType.DERFS || type === QuestionnaireType.CDERFS) {
      return { [type]: { rawResponse: questionnaire.response } };
    } else if (type === QuestionnaireType.CUSTOM) {
      const parsedConfig =
        (questionnaire.config as any) instanceof Object
          ? questionnaire.config
          : JSON.parse(questionnaire.config || '{}');
      const customQuestionnaireFormGroup = new FormTemplateInputFormGroup(parsedConfig.schema);
      customQuestionnaireFormGroup.patchValue(JSON.parse(questionnaire.response.answers));
      return {
        [parsedConfig.schema.abbreviation]: {
          formGroupWithValues: customQuestionnaireFormGroup,
          schema: parsedConfig.schema,
          totalScore: this.questionnaireScorePipe.transform(questionnaire)
        }
      };
    } else if (
      Object.keys(this.questionnaireRequestService.formTemplateLibraryQuestionnaires).includes(type)
    ) {
      const questionnaireSchema = this.questionnaireRequestService
        .formTemplateLibraryQuestionnaires[type];
      const customQuestionnaireFormGroup = new FormTemplateInputFormGroup(questionnaireSchema);
      customQuestionnaireFormGroup.patchValue(JSON.parse(questionnaire.response.answers));
      return {
        [questionnaireSchema.abbreviation]: {
          formGroupWithValues: customQuestionnaireFormGroup,
          schema: questionnaireSchema,
          totalScore: this.questionnaireScorePipe.transform(questionnaire)
        }
      };
    } else if (type !== QuestionnaireType.OSDIAndSPEED && type !== QuestionnaireType.SPEEDII) {
      return {
        [type]: this.getQuestionAndResponse(type, questionnaire)
      };
    } else if (type === QuestionnaireType.SPEEDII) {
      return {
        [QuestionnaireType.SPEED]: this.getQuestionAndResponse(
          QuestionnaireType.SPEED,
          questionnaire
        ),
        [QuestionnaireType.SPEEDII]: this.getQuestionAndResponse(
          QuestionnaireType.SPEEDII,
          questionnaire
        )
      };
    } else {
      return {
        [QuestionnaireType.OSDI]: this.getQuestionAndResponse(
          QuestionnaireType.OSDI,
          questionnaire
        ),
        [QuestionnaireType.SPEED]: this.getQuestionAndResponse(
          QuestionnaireType.SPEED,
          questionnaire
        )
      };
    }
  }

  getQuestionAndResponse(
    type: QuestionnaireType,
    questionnaire: QuestionnaireRequest
  ): QuestionsResponseData {
    const questionAndResponsesGroup: QuestionAndResponseGroup[] = [];
    const response = JSON.parse(questionnaire.response.answers)[type];
    const totalScore = this.questionnaireScorePipe.transform(questionnaire);
    const service = this.serviceTypeMap[type];

    if (type === QuestionnaireType.SPEEDII) {
      [
        this.speedIIService.groupThreeQuestions,
        this.speedIIService.groupFourQuestions,
        this.speedIIService.groupFiveQuestions,
        this.speedIIService.groupSixQuestions
      ].forEach((questions: Question[]) => {
        const groupQuestionAndResponse: QuestionAndResponse[] = [];

        let groupTitle = '';

        questions.forEach(question => {
          groupTitle = question.group.title;

          const questionAndResponse = {
            title: question.title,
            label: '',
            response: response[question.id] || 'N/A'
          };
          const checkedResponses = [];
          if (question.fieldConfig.type === 'Checkbox') {
            Object.entries(question.fieldConfig.options).forEach(([key, value]) => {
              if (response[question.id + '-' + key]) {
                checkedResponses.push(value);
              }
            });
            questionAndResponse['response'] = checkedResponses.join(', ') || 'N/A';
          }
          groupQuestionAndResponse.push(questionAndResponse);
        });

        questionAndResponsesGroup.push({
          title: groupTitle,
          questionsAndResponses: groupQuestionAndResponse
        });
      });
      return { questionsAndResponsesGroup: questionAndResponsesGroup, totalScore };
    }

    service.questionGroups.forEach(questionGroup => {
      const questionsAndResponses: QuestionAndResponse[] = [];
      questionGroup.questions.forEach(question => {
        const questionResponse = response[questionGroup.id][question.id];

        questionsAndResponses.push({
          title: question.title,
          response: questionResponse.notApplicable ? 'N/A' : questionResponse.answer,
          label: questionResponse.notApplicable
            ? 'N/A'
            : question.labels[questionResponse.answer + 1]
        });
      });
      questionAndResponsesGroup.push({
        title: questionGroup.title,
        questionsAndResponses
      });
    });

    return { questionsAndResponsesGroup: questionAndResponsesGroup, totalScore };
  }
}

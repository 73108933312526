import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'csi-symptom-duration',
  templateUrl: './symptom-duration.component.html',
  styleUrls: ['./symptom-duration.component.css']
})
export class SymptomDurationComponent implements OnInit {
  @Input() symptomDurationFormControl: FormControl;

  public monthsFormControl = new FormControl();
  public yearsFormControl = new FormControl();

  public arrayOfFifty: Array<number> = [...Array(51).keys()];
  public arrayOfTwelve: Array<number> = [...Array(13).keys()];

  constructor() {}

  ngOnInit() {
    this.symptomDurationFormControl.valueChanges.subscribe(() => {
      this.setValueBasedOnControlValue();
    });

    combineLatest([
      this.monthsFormControl.valueChanges.pipe(startWith(0)),
      this.yearsFormControl.valueChanges.pipe(startWith(0))
    ]).subscribe(() => {
      this.symptomDurationFormControl.patchValue(
        {
          month: this.monthsFormControl.value || 0,
          year: this.yearsFormControl.value || 0,
          total: (this.monthsFormControl.value || 0) + (this.yearsFormControl.value || 0) * 12
        },
        { emitEvent: false }
      );
    });
  }

  public setValueBasedOnControlValue() {
    if (this.symptomDurationFormControl.value) {
      this.monthsFormControl.patchValue(this.symptomDurationFormControl.value.month, {
        emitEvent: false
      });
      this.yearsFormControl.patchValue(this.symptomDurationFormControl.value.year);
    } else {
      this.yearsFormControl.patchValue(null);
      this.monthsFormControl.patchValue(null);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldConfig, ListConfig } from 'src/app/form-template/form-template-model';
import { FormTemplateGeneratorService } from '../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-list-field-generator',
  templateUrl: './form-template-list-field-generator.component.html',
  styleUrls: ['./form-template-list-field-generator.component.css']
})
export class FormTemplateListFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: ListConfig };
  @Input() control: FormGroup;
  @Input() formTemplateFieldKey: string;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {}
}

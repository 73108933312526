import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ResetPasswordError } from '../../../core/authentication/models/error-models/ResetPasswordError';
import { PasswordMatchValidator } from '../../PasswordMatchValidator';
import { AuthenticatorMode, matSpinnerOptions } from '../authenticator-modal.component';

@Component({
  selector: 'csi-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../authenticator-modal.component.css', './reset-password.component.css']
})
export class ResetPasswordComponent {
  @Input() authenticatorMode$: BehaviorSubject<AuthenticatorMode>;

  public readonly ResetPasswordError = ResetPasswordError;

  public readonly formGroup;
  public readonly faSpinner = faSpinner;

  public didSubmit = false;
  public didResetPassword = false;
  public matSpinnerOptions = { ...matSpinnerOptions };

  public errorResponse: ResetPasswordError;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private location: Location,
    private dialogRef: MatDialogRef<AuthenticatorMode>
  ) {
    this.matSpinnerOptions.text = 'Reset password';

    const passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
    this.formGroup = new FormGroup({
      password: passwordControl,
      confirmPassword: new FormControl('', [
        Validators.required,
        PasswordMatchValidator.validatorForPasswordControl(passwordControl)
      ])
    });
  }

  public submit() {
    this.didSubmit = true;

    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;

      this.authenticationService
        .resetPassword(this.userEmail, this.resetCode, this.formGroup.value.password)
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          () => {
            this.didResetPassword = true;
            setTimeout(() => this.returnToSignin(), 1000);
          },
          (error: ResetPasswordError) => {
            if (
              error === ResetPasswordError.ExpiredCode ||
              error === ResetPasswordError.CodeAlreadyUsed ||
              error === ResetPasswordError.InvalidCode
            ) {
              this.authenticationService.sendPasswordResetEmail(this.userEmail).subscribe(
                () => {
                  this.errorResponse = error;
                },
                () => {
                  this.errorResponse = ResetPasswordError.Unknown;
                }
              );
            } else {
              this.errorResponse = error;
            }
          }
        );
    }
  }

  public clearServerErrors() {
    this.errorResponse = undefined;
  }

  get invalidLink(): boolean {
    return !this.userEmail || !this.resetCode;
  }

  get userEmail(): string {
    return this.activatedRoute.snapshot.queryParams['user'];
  }

  get resetCode(): string {
    return this.activatedRoute.snapshot.queryParams['code'];
  }

  returnToSignin() {
    this.location.replaceState('');
    this.router.navigateByUrl('');
    this.activatedRoute.snapshot.queryParams = {};
    this.dialogRef.close();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'csi-true-ocular-surface-disease',
  templateUrl: './true-ocular-surface-disease.component.html',
  styleUrls: ['./true-ocular-surface-disease.component.css']
})
export class TrueOcularSurfaceDiseaseComponent {
  @Input() evidenceList: string[];
  @Input() lang: string;

  constructor() {}
}

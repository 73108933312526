import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AssessmentService } from './../../../core/api/assessment.service';

@Component({
  selector: 'csi-attachment-qr-code-modal',
  templateUrl: './attachment-qr-code-modal.component.html',
  styleUrls: ['./attachment-qr-code-modal.component.css']
})
export class AttachmentQrCodeModalComponent implements OnInit {
  public path = '';

  constructor(
    private dialogRef: MatDialogRef<AttachmentQrCodeModalComponent>,
    private assessmentService: AssessmentService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit() {
    this.path =
      location.origin +
      '/' +
      this.data.id +
      '/' +
      this.data.metadataKey +
      '/' +
      this.data.metadata[this.data.metadataKey] +
      '/upload';
  }

  onDone() {
    this.assessmentService
      .getAttachments(this.data.id)
      .subscribe(attachments => this.dialogRef.close(attachments || []));
  }
}

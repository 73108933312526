import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InteractionStatus } from 'src/app/patients/patient/interactions/interactions.model';
import {
  interactionStatus,
  interactionStatusKeys,
  InteractionTypes,
  SurgeryTypes
} from './../../../patients/patient/interactions/interactions.model';

@Component({
  selector: 'csi-interaction-status-setup-actions-modal',
  templateUrl: './interaction-status-setup-actions-modal.component.html',
  styleUrls: ['./interaction-status-setup-actions-modal.component.css']
})
export class InteractionStatusSetupActionsModalComponent implements OnInit {
  public interactionStatusKeyFriendlyName = {
    name: 'Name',
    bookAppointment: 'Show book appointment field',
    failCommunicationStatus: 'Mark communication status as failed',
    notInterested: 'Mark patient as not interested',
    selectSurgeryType: 'Show surgery type field',
    failSurgery: 'Mark surgery as failed',
    onlyShowFor: 'Interaction types to show this status for',
    color: 'Pick Color For Status'
  };
  public interactionStatus = interactionStatus;

  public interactionKeyAndType = Object.entries(interactionStatus).reduce((acc, [key, value]) => {
    return { ...acc, [key]: typeof value };
  }, {});

  public interactionStatusKeys = interactionStatusKeys;

  public status = this.data.status;
  public interactionTypes = this.data.interactionTypes;
  public surgeryTypes = this.data.surgeryTypes;
  public isNew = !this.status;

  public statusFormGroup = new FormGroup(
    interactionStatusKeys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: new FormControl(
          (this.status && this.status[key]) || null,
          ['onlyShowFor'].includes(key) ? null : Validators.required
        )
      };
    }, {})
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      status?: InteractionStatus;
      interactionTypes: InteractionTypes;
      surgeryTypes: SurgeryTypes;
    },
    private matDialogRef: MatDialogRef<InteractionStatusSetupActionsModalComponent>
  ) {}

  ngOnInit() {
    if (this.isNew) {
      this.onlyShowForInit();
    }
  }

  onlyShowForInit() {
    this.statusFormGroup.controls.onlyShowFor.setValue(['Show For All']);
  }

  updateOnlyShowFor(value) {
    if (this.statusFormGroup.controls.onlyShowFor.value.includes('Show For All')) {
      this.statusFormGroup.controls.onlyShowFor.setValue([value]);
      return;
    }

  }

  static open(
    dialog: MatDialog,
    interactionTypes: InteractionTypes,
    surgeryTypes: SurgeryTypes,
    status: InteractionStatus = null
  ) {
    return dialog.open(InteractionStatusSetupActionsModalComponent, {
      data: { status, interactionTypes, surgeryTypes },
      width: '80vw',
      minWidth: '54rem',
      maxWidth: '95vw',
    });
  }

  closeWithData() {
    if (!this.statusFormGroup.controls.color.value) {
      this.statusFormGroup.controls.color.setValue('#000000');
    }
    this.matDialogRef.close(this.statusFormGroup.value);
  }


}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  GetMassClinicQuery,
  ListMassClinicByClinicIdSortedByDateQuery,
  MassClinic,
  MassClinicPatient,
  UpdateMassClinicMutation,
  UpdateMassClinicPatientMutation
} from 'src/API';
import { StaffService } from 'src/app/core/api/staff.service';
import { PatientModalComponent } from 'src/app/shared/consult-forms/patient-modal/patient-modal.component';
import { createMassClinic, createMassClinicPatient } from 'src/graphql/mutations';
import { listMassClinicByClinicIdSortedByDate } from 'src/graphql/queries';
import { parseDateToString } from '../core/api/date.util';
import { Patient } from '../core/api/patient.service';
import { ConfirmModalService } from '../shared/confirm-modal/confirm-modal.service';
import {
  CreateMassClinicMutation,
  CreateMassClinicPatientInput,
  CreateMassClinicPatientMutation
} from './../../API';
import { AppSyncService } from './../core/appsync.service';
import { ClinicSetupService } from './../logged-in-navbar/clinic-setup-modal/clinic-setup.service';

export type MassClinicPatientImportStatus = 'cancelled' | 'exists' | 'new';

@Injectable({
  providedIn: 'root'
})
export class MassClinicService {
  public massClinics: MassClinic[] = [];
  public currentSelectedMassClinic: MassClinic;
  public monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  public currentSelectedMonthNumber: number = new Date().getMonth();
  public currentSelectedMonth: string = this.monthNames[this.currentSelectedMonthNumber];
  public currentSelectedYear: number = new Date().getFullYear();

  public datesToFetchFromNetwork: string[] = [];

  private getMassClinicByDateWithMassClinicPatient = /* GraphQL */ `
    query ListMassClinicByClinicIdSortedByDate(
      $massClinicClinicId: ID
      $date: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelMassClinicFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listMassClinicByClinicIdSortedByDate(
        massClinicClinicId: $massClinicClinicId
        date: $date
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          massClinicClinicId
          date
          massClinicPatients {
            items {
              id
              patient {
                id
                firstName
                lastName
                email
                phone
              }
              questionnaireRequest {
                id
                createdAt
                type
                completedAt
              }
              isCancelled
            }
          }
          isCancelled
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

  private getMassClinicByDateWithMassClinicPatientWithResponse = /* GraphQL */ `
    query ListMassClinicByClinicIdSortedByDate(
      $massClinicClinicId: ID
      $date: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelMassClinicFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listMassClinicByClinicIdSortedByDate(
        massClinicClinicId: $massClinicClinicId
        date: $date
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          massClinicClinicId
          date
          massClinicPatients {
            items {
              id
              patient {
                id
                firstName
                lastName
                email
                phone
              }
              questionnaireRequest {
                id
                createdAt
                type
                completedAt
                response {
                  answers
                  scores
                  consent {
                    consentsToPrivacyForm
                    consentsToResearch
                    agreedToTerms
                    consentSource
                  }
                }
              }
              isCancelled
            }
          }
          isCancelled
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

  private getMassClinic = /* GraphQL */ `
    query GetMassClinic($id: ID!) {
      getMassClinic(id: $id) {
        id
        massClinicClinicId
        date
        massClinicPatients {
          items {
            id
            patient {
              id
              firstName
              lastName
              email
              phone
              consentsToResearch
              consentsToPrivacyForm
              consentSource
            }
            questionnaireRequest {
              id
              createdAt
              type
              completedAt
              response {
                answers
                scores
                consent {
                  consentsToPrivacyForm
                  consentsToResearch
                  agreedToTerms
                  consentSource
                }
              }
            }
            isCancelled
            createdAt
            updatedAt
          }
          nextToken
        }
        isCancelled
        createdAt
        updatedAt
      }
    }
  `;

  private updateMassClinicPatientMutation = /* GraphQL */ `
    mutation UpdateMassClinicPatient($id: ID!, $isCancelled: Boolean) {
      updateMassClinicPatient(input: { id: $id, isCancelled: $isCancelled }) {
        id
        questionnaireRequest {
          id
          createdAt
          type
          completedAt
          response {
            answers
            scores
            consent {
              consentsToPrivacyForm
              consentsToResearch
              agreedToTerms
              consentSource
            }
          }
        }
      }
    }
  `;

  private updateMassClinicMutation = /* GraphQL */ `
    mutation UpdateMassClinic($id: ID!, $isCancelled: Boolean) {
      updateMassClinic(input: { id: $id, isCancelled: $isCancelled }) {
        id
      }
    }
  `;

  constructor(
    private appSyncService: AppSyncService,
    private clinicSetupService: ClinicSetupService,
    private confirmModalService: ConfirmModalService,
    private staffService: StaffService,
    private dialog: MatDialog
  ) {}

  listMassClinicByDateWithMassClinicPatient(
    dateRange: string[],
    getQuestionnaireResponse?: boolean,
    fetchFromNetwork?: boolean
  ): Observable<MassClinic[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(
            getQuestionnaireResponse
              ? this.getMassClinicByDateWithMassClinicPatientWithResponse
              : this.getMassClinicByDateWithMassClinicPatient
          ),
          variables: {
            massClinicClinicId: this.clinicSetupService.clinicId,
            date: { between: dateRange }
          },
          fetchPolicy: fetchFromNetwork ? 'network-only' : 'cache-first'
        })
      ),
      map(
        (result: ApolloQueryResult<ListMassClinicByClinicIdSortedByDateQuery>) =>
          result.data.listMassClinicByClinicIdSortedByDate.items
      )
    );
  }

  listMassClinicsForDateRange(dateRange: string[]): Observable<MassClinic[]> {
    const indexOfDateToFetchFromNetwork = this.datesToFetchFromNetwork.findIndex(
      dateToFetchFromNetwork => dateToFetchFromNetwork === dateRange[0]
    );
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listMassClinicByClinicIdSortedByDate),
          variables: {
            massClinicClinicId: this.clinicSetupService.clinicId,
            date: { between: dateRange }
          },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<ListMassClinicByClinicIdSortedByDateQuery>) => {
        if (indexOfDateToFetchFromNetwork >= 0) {
          this.datesToFetchFromNetwork.splice(indexOfDateToFetchFromNetwork, 1);
        }
        return result.data.listMassClinicByClinicIdSortedByDate.items;
      })
    );
  }

  getMassClinicById(id: string, fetchFromNetwork?: boolean): Observable<MassClinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(this.getMassClinic),
          variables: {
            id
          },
          fetchPolicy: fetchFromNetwork ? 'network-only' : 'cache-first'
        })
      ),
      map((result: ApolloQueryResult<GetMassClinicQuery>) => result.data.getMassClinic)
    );
  }

  getMassClinicByDate(date: Date): Observable<MassClinic> {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);

    return this.listMassClinicByDateWithMassClinicPatient(
      [parseDateToString(date), parseDateToString(nextDay)],
      true,
      true
    ).pipe(map(massClinics => massClinics[0])); // Because there is one mass clinic per month this is hardcoded as index 0
  }

  createMassClinic(date: Date): Observable<MassClinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createMassClinic),
          variables: { input: { date, massClinicClinicId: this.clinicSetupService.clinicId } }
        })
      ),
      map((result: ApolloQueryResult<CreateMassClinicMutation>) => {
        const data = result.data.createMassClinic;
        this.pushAndSortMassClinic(data);
        // this.fetchFromNetworkDatesHelper(date);

        return data;
      })
    );
  }

  public openPatientModal(
    patient: Partial<Patient>,
    requirePatientContactInformation: boolean = true
  ): MatDialogRef<PatientModalComponent> {
    return PatientModalComponent.open(
      this.dialog,
      this.staffService.staff,
      patient,
      null,
      null,
      requirePatientContactInformation
    );
  }

  public toggleMassClinic(massClinic: MassClinic, index?: number): Observable<MassClinic> {
    const massClinicDate = new Date(massClinic.date);

    const toCancel = !massClinic.isCancelled;

    return this.confirmModalService
      .show(
        `${toCancel ? 'Cancel' : 'Reschedule'} Mass Clinic`,
        `Are you sure you want to ${
          toCancel ? 'cancel' : 'reschedule'
        } the Mass Clinic for ${massClinicDate.toLocaleDateString('en-US')}?
        ${
          toCancel
            ? '<br> <b>All patients appointed to this mass clinic will be cancelled and notified.</b>'
            : ''
        }`,
        `${toCancel ? 'Yes' : 'Reschedule'}`,
        'No'
      )
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            return this.updateMassClinic(massClinic.id, toCancel);
          }

          return of(null);
        }),
        tap(updatedMassClinic => {
          if (updatedMassClinic) {
            massClinic.isCancelled = toCancel;
          }
        })
      );
  }

  pushAndSortMassClinic(massClinic: MassClinic) {
    this.massClinics.push(massClinic);
    this.massClinics = [...this.massClinics.sort((a, b) => a.date.localeCompare(b.date))];
  }

  createMassClinicPatient(
    massClinicPatientMassClinicId: string,
    massClinicPatientPatientId: string
  ): Observable<MassClinicPatient> {
    const input: CreateMassClinicPatientInput = {
      massClinicPatientMassClinicId,
      massClinicPatientClinicId: this.clinicSetupService.clinicId,
      massClinicPatientPatientId
    };
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createMassClinicPatient),
          variables: { input }
        })
      ),
      map((result: ApolloQueryResult<CreateMassClinicPatientMutation>) => {
        const data = result.data.createMassClinicPatient;
        return data;
      })
    );
  }

  updateMassClinicPatient(id: string, isCancelled: boolean): Observable<MassClinicPatient> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(this.updateMassClinicPatientMutation),
          variables: { id, isCancelled }
        })
      ),
      map((result: ApolloQueryResult<UpdateMassClinicPatientMutation>) => {
        const data = result.data.updateMassClinicPatient;
        return data;
      })
    );
  }

  updateMassClinic(id: string, isCancelled: boolean): Observable<MassClinic> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(this.updateMassClinicMutation),
          variables: { id, isCancelled }
        })
      ),
      map((result: ApolloQueryResult<UpdateMassClinicMutation>) => {
        const data = result.data.updateMassClinic;
        return data;
      })
    );
  }
  public getMassClinicPatientExistenceStatus(
    patientToCheck: Patient,
    massClinic: MassClinic
  ): { status: MassClinicPatientImportStatus; massClinicPatient?: MassClinicPatient } {
    if (
      massClinic &&
      massClinic.massClinicPatients &&
      massClinic.massClinicPatients.items.length > 0
    ) {
      const existingMassClinicPatient = massClinic.massClinicPatients.items.find(
        massClinicPatient => massClinicPatient.patient.id === patientToCheck.id
      );
      return {
        status: !existingMassClinicPatient
          ? 'new'
          : existingMassClinicPatient.isCancelled
          ? 'cancelled'
          : 'exists',
        massClinicPatient: existingMassClinicPatient
      };
    }
    return { status: 'new' };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreatmentPipe } from 'src/app/treatment-picker/treatment.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { DrugPipe } from '../medication-lookup/drug.pipe';
import { SideEffectsPipe } from '../medication-lookup/side-effects.pipe';
import { AddressPipe } from './address.pipe';
import { AgePipe } from './age.pipe';
import { ArrayHasValuePipe } from './array-has-value.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { AttachmentToUriPipe } from './attachment-to-uri.pipe';
import { CompareValueToConditionsPipe } from './compare-value-to-conditions.pipe';
import { DateBeforeTodayPipe } from './date-before-today.pipe';
import { DaysAgoPipe } from './days-ago.pipe';
import { EmailDomainPipe } from './email-domain.pipe';
import { EmailLocalPartPipe } from './email-local-part.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { FormGroupHasValuePipe } from './form-group-has-value.pipe';
import { FunctionResolverPipe } from './function-resolver.pipe';
import { HealthCardPipe } from './health-card.pipe';
import { ImageToDataUriPipe } from './image-to-data-uri.pipe';
import { JsonParsePipe } from './json-parse.pipe';
import { PersonNamePipe } from './person-name.pipe';
import { PhonePipe } from './phone.pipe';
import { QuestionnaireScorePipe } from './questionnaire-score.pipe';
import { QuestionnaireStatusPipe } from './questionnaire-status.pipe';
import { QuestionnaireTypePipe } from './questionnaire-type.pipe';
import { SafeStylePipe } from './safe-style.pipe';
import { SearchHighlightPipe } from './search-highlight.pipe';
import { StartCasePipe } from './start-case.pipe';
import { SvgPipe } from './svg.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AgePipe,
    PhonePipe,
    SvgPipe,
    ImageToDataUriPipe,
    AttachmentToUriPipe,
    StartCasePipe,
    QuestionnaireTypePipe,
    EmailDomainPipe,
    EmailLocalPartPipe,
    HealthCardPipe,
    PersonNamePipe,
    JsonParsePipe,
    SafeStylePipe,
    TimeAgoPipe,
    EnumToArrayPipe,
    SearchHighlightPipe,
    QuestionnaireStatusPipe,
    QuestionnaireScorePipe,
    DateBeforeTodayPipe,
    CompareValueToConditionsPipe,
    DaysAgoPipe,
    FunctionResolverPipe,
    FormGroupHasValuePipe,
    TreatmentPipe,
    DrugPipe,
    SideEffectsPipe,
    ArraySortPipe,
    ArrayHasValuePipe,
    AddressPipe
  ],
  exports: [
    AgePipe,
    PhonePipe,
    SvgPipe,
    ImageToDataUriPipe,
    AttachmentToUriPipe,
    StartCasePipe,
    QuestionnaireTypePipe,
    EmailDomainPipe,
    EmailLocalPartPipe,
    HealthCardPipe,
    PersonNamePipe,
    JsonParsePipe,
    SafeStylePipe,
    TimeAgoPipe,
    EnumToArrayPipe,
    SearchHighlightPipe,
    QuestionnaireStatusPipe,
    QuestionnaireScorePipe,
    DateBeforeTodayPipe,
    CompareValueToConditionsPipe,
    DaysAgoPipe,
    FunctionResolverPipe,
    FormGroupHasValuePipe,
    TreatmentPipe,
    DrugPipe,
    SideEffectsPipe,
    ArraySortPipe,
    ArrayHasValuePipe,
    AddressPipe
  ]
})
export class SharedPipesModule {}

import { AssessmentRequestType, AssessmentType } from 'src/API';
import { EntryMethod } from 'src/app/econsult/econsult-form.model';
import { Assessment, AssessmentRequest } from './assessment.service';
import { ModelUtils } from './model-utils';

export const AssessmentUtils = {
  isAssessment(value: any) {
    return (<Assessment>value).__typename && (<Assessment>value).__typename === 'Assessment';
  },

  hasAssessmentRequests(assessment: Assessment): boolean {
    return assessment && assessment.requests && assessment.requests.items.length > 0;
  },

  hasRespondedToAssessmentRequest(assessment: AssessmentRequest): boolean {
    return (
      assessment.type === AssessmentRequestType.EConsult &&
      assessment.response &&
      assessment.response.length > 0
    );
  },
  sortAndFilterAssessments(
    items: Assessment[],
    filterByType?: AssessmentType,
    entryMethod?: EntryMethod
  ): Assessment[] {
    if (!!filterByType) {
      items = items.filter(value => value.type === filterByType);
    }

    if (!!entryMethod) {
      items = items.filter(value => JSON.parse(value.body).informationEntryMethod === entryMethod);
    }

    return ModelUtils.sortByDate(items, 'createdAt') as Assessment[];
  }
};

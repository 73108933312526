import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  ViewContainerRef
} from '@angular/core';
import { PatientFormComponent } from '../../consult-forms/patient-form/patient-form.component';
import { SpeedIIComponent } from 'src/app/questionnaires/speedII/speed-ii.component';

@Directive({
  selector: '[csiDynamicBinding]'
})
export class DynamicBindingDirective {
  @Input() data: any;
  private componentRef: ComponentRef<any> | null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.loadComponent();
  }

  private loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SpeedIIComponent
    );
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    Object.keys(this.data).forEach(key => {
      this.componentRef.instance[key] = this.data[key];
    });
  }
}

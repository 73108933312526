import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject } from 'rxjs';

@Component({
  selector: 'csi-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.css']
})
export class WebcamModalComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  public trigger$ = new Subject();
  public initError = false;
  public errorMsg = '';
  public errorMsgClass = 'access-denied-error-msg';

  constructor(public dialogRef: MatDialogRef<WebcamModalComponent>) {}

  ngOnInit() {}

  public triggerSnapshot() {
    this.trigger$.next();
  }

  public handleImageCapture(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.dialogRef.close(webcamImage);
  }

  public handleInitError(error: WebcamInitError) {
    this.initError = true;
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.errorMsg = 'Camera access denied';
    } else {
      this.errorMsg = 'Camera not found';
      this.errorMsgClass = 'not-found-error-msg';
    }
  }
}

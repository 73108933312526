import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NiceDateInputDirective } from './nice-date-input.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [NiceDateInputDirective],
  exports: [NiceDateInputDirective]
})
export class NiceDateInputModule {}

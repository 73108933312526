import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Base64 } from 'js-base64';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { QueueFaxQuery, QueueFaxQueryVariables } from 'src/API';
import { queueFax } from 'src/graphql/queries';
import { AppSyncService } from '../appsync.service';

export type QueueFaxResult = QueueFaxQuery['queueFax'];

@Injectable({
  providedIn: 'root'
})
export class FaxService {
  constructor(private appSyncService: AppSyncService) {}

  public queueFax(input: QueueFaxQueryVariables): Observable<QueueFaxResult> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.query({
          query: gql(queueFax),
          variables: input,
          fetchPolicy: 'no-cache'
        });
      }),
      map(result => {
        return result.data['queueFax'];
      })
    );
  }

  public htmlToBase64(html: string): string {
    return Base64.encode(html);
  }
}

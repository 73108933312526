import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

export interface Question {
  id: string;
  title: string;
  minValue: number;
  maxValue: number;
  labels: string[];
  showNotApplicable: boolean;
}

export interface QuestionGroup {
  id: string;
  title: string;
  questions: Question[];
}

export interface QuestionAnswer {
  answer: number;
  notApplicable: boolean;
}

export abstract class QuestionnaireFormBuilder {
  formGroup: FormGroup;
  public abstract title: string;
  private _score: number;
  public questionnaireKey: string;

  constructor(
    public questionGroups: QuestionGroup[],
    private formBuilder: FormBuilder,
    private scoreCalculator: (formValue: { [key: string]: any }) => number
  ) {
    this.initFormGroup();
    this.setupScoreCalculator();
  }

  public get score(): number {
    return this._score;
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group(this.createFormGroups(this.questionGroups));
  }

  private createFormGroups(groups: QuestionGroup[]): { [key: string]: any } {
    const formGroup = {};
    groups.forEach(group => {
      formGroup[group.id] = this.formBuilder.group(this.createControls(...group.questions));
    });
    return formGroup;
  }

  private createControls(...questions: Question[]): { [key: string]: any } {
    const controls = {};
    questions.forEach(question => {
      controls[question.id] = this.formBuilder.group(
        {
          answer: [
            // Default value is UNKNOWN state which is 1 less than minValue
            question.minValue - 1
          ],
          notApplicable: [false]
        },
        {
          validators: [
            Validators.required,
            (control: AbstractControl) => {
              const notApplicable =
                question.showNotApplicable && control.get('notApplicable').value === true;
              const validRange = Validators.compose([
                Validators.min(question.minValue),
                Validators.max(question.maxValue)
              ])(control.get('answer'));
              if (notApplicable) {
                return null;
              } else {
                return validRange;
              }
            }
          ]
        }
      );
    });
    return controls;
  }

  private setupScoreCalculator() {
    this.formGroup.valueChanges
      .pipe(filter(() => this.formGroup.valid))
      .subscribe(value => this.updateScore(value));
  }

  private updateScore(value) {
    this._score = this.scoreCalculator(value);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA
} from '@angular/material';
import { ApolloError } from 'apollo-client';
import { Logger } from 'aws-amplify';
import { Observable } from 'rxjs';
import {
  CreateQuestionnaireRequestInput,
  Patient,
  QuestionnaireRequest,
  QuestionnaireType
} from 'src/API';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import {
  QuestionnaireRequestService,
  TabletQuestionnaireModalData
} from 'src/app/core/api/questionnaire-request.service';
import { QrCodeModalComponent } from '../qr-code-modal/qr-code-modal.component';
import { NameFormat } from '../shared-pipes/person-name.pipe';
export type TableQuestionnaireMatDialogRef = MatDialogRef<TabletQuestionnaireModalComponent>;

@Component({
  selector: 'csi-table-questionnaire-modal',
  templateUrl: './tablet-questionnaire-modal.component.html',
  styleUrls: ['./tablet-questionnaire-modal.component.css']
})
export class TabletQuestionnaireModalComponent implements OnInit {
  readonly NameFormat = NameFormat;
  public formGroup: FormGroup;
  public createClicked = false;
  public creatingQuestionnaire = false;
  private logger: Logger = new Logger('SendQuestionnaireDialogComponent');

  constructor(
    public dialogRef: MatDialogRef<TabletQuestionnaireModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TabletQuestionnaireModalData,
    private questionnaireRequestService: QuestionnaireRequestService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private snackBar: MatSnackBar
  ) {
    this.formGroup = this.questionnaireRequestService.createTabletQuestionnaireFormGroup(data);
  }

  ngOnInit() {}

  public static open(
    matDialogService: MatDialog,
    questionnaireType: QuestionnaireType,
    patient: Partial<Patient>,
    modalConfig: MatDialogConfig<never> = {}
  ): TableQuestionnaireMatDialogRef {
    return matDialogService.open<TabletQuestionnaireModalComponent, TabletQuestionnaireModalData>(
      TabletQuestionnaireModalComponent,
      {
        maxWidth: '95vw',
        height: 'auto',
        ...modalConfig,
        data: {
          patient,
          questionnaireType
        }
      }
    );
  }

  onSubmit() {
    this.createClicked = true;
    if (this.formGroup.invalid) return;

    this.creatingQuestionnaire = true;
    if (!this.data.questionnaireRequest) {
      this.createQuestionnaireRequest();
      return;
    }

    this.updateQuestionnaireType();
  }

  openQrCodeModal(questionnaireId: string) {
    const path = `${location.origin}/questionnaires/${questionnaireId}`;
    const questionnaireType: string = this.questionnaireRequestService
      .questionnaireTypeToFriendlyNameMap[this.formGroup.controls.type.value];

    this.dialog.open(QrCodeModalComponent, {
      data: {
        qrCodePath: path,
        modalTitle: `Scan the QR Code to open the ${questionnaireType} questionnaire`
      }
    });
  }

  private createQuestionnaireRequest() {
    const questionnaireRequestInput: CreateQuestionnaireRequestInput = this.formGroup.value;

    let questionnaireRequest$: Observable<QuestionnaireRequest>;

    questionnaireRequest$ = this.questionnaireRequestService.createQuestionnaireRequest(
      questionnaireRequestInput
    );

    questionnaireRequest$.subscribe(
      (questionnaireRequest: QuestionnaireRequest) => {
        this.logger.debug('Submitted Questionnaire Request: ', questionnaireRequest);
        this.creatingQuestionnaire = false;
        this.openQrCodeModal(questionnaireRequest.id);
        this.dialogRef.close(questionnaireRequest);
        this.openSuccessSnackbar();
      },
      (error: ApolloError) => {
        this.creatingQuestionnaire = false;
        this.errorHandlerService.handleGraphQLError(error);
      }
    );
  }

  public updateQuestionnaireType() {
    this.questionnaireRequestService
      .updateQuestionnaireType(this.data.questionnaireRequest, this.formGroup.controls.type.value)
      .subscribe(
        (questionnaireRequest: QuestionnaireRequest) => {
          this.logger.debug('Submitted Questionnaire Request: ', questionnaireRequest);
          this.creatingQuestionnaire = false;
          this.openQrCodeModal(questionnaireRequest.id);
          this.dialogRef.close(questionnaireRequest);
          this.openSuccessSnackbar();
        },
        (error: ApolloError) => {
          this.creatingQuestionnaire = false;
          this.errorHandlerService.handleGraphQLError(error);
        }
      );
  }

  private openSuccessSnackbar() {
    const questionnaireType: string = this.questionnaireRequestService
      .questionnaireTypeToFriendlyNameMap[this.formGroup.controls.type.value];

    this.snackBar.open(`${questionnaireType} questionnaire generated!`, 'Dismiss', {
      duration: 2000
    });
  }
}

import { Injectable } from '@angular/core';
import { omit, pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AssessmentRequestType, AssessmentType, Doctor } from '../../API';
import { Assessment, AssessmentRequest, AssessmentService } from '../core/api/assessment.service';
import { Patient } from '../core/api/patient.service';
import { AssessmentBody } from '../econsult/assessment-body/assessment-body.model';
import { Attachment } from '../shared/attachments/attachments.component';

@Injectable()
export class AssessmentFormService {
  constructor(private assessmentService: AssessmentService) {}

  public saveAssessment(
    id: string,
    assessmentType: AssessmentType,
    assessmentBody: AssessmentBody,
    attachments: Attachment[],
    patient: Patient,
    doctor: Doctor,
    isLocked?: boolean
  ): Observable<Assessment> {
    const body = omit(assessmentBody, 'assessmentForm.images');
    this.patchModelsToBody(body, doctor, patient);
    // create or update assessment
    if (!id) {
      return this.assessmentService.createAssessment(
        assessmentType,
        body,
        attachments,
        patient.id,
        doctor.id,
        isLocked
      );
    } else {
      return this.assessmentService.updateAssessment(
        id,
        assessmentType,
        body,
        attachments,
        patient.id,
        doctor.id,
        isLocked
      );
    }
  }

  public submitAssessmentRequest(
    type: AssessmentRequestType = AssessmentRequestType.EReferral,
    id: string,
    assessmentType: AssessmentType,
    assessmentBody: AssessmentBody,
    attachments: Attachment[],
    patient: Patient,
    doctor: Doctor
  ): Observable<AssessmentRequest> {
    return this.saveAssessment(
      id,
      assessmentType,
      assessmentBody,
      attachments,
      patient,
      doctor
    ).pipe(
      switchMap(assessment => {
        return this.assessmentService.createAssessmentRequest({
          assessmentRequestDoctorId: doctor.id,
          type: type,
          assessmentRequestAssessmentId: assessment.id
        });
      })
    );
  }

  public patchModelsToBody(body: Partial<AssessmentBody>, doctor: Doctor, patient: Patient) {
    body.doctorInformationForm = this.modelToEntity(doctor);
    body.clinicInformationForm = this.modelToEntity(doctor.clinic);
    body.patientInformationForm = this.modelToEntity(patient);
    body.patientInformationForm.patientDoctorId = doctor.id;
  }

  private modelToEntity<T extends object = any, R extends Partial<T> = Partial<T>>(model: T): R {
    const KEEP_FIELD = true;
    const REMOVE_FIELD = false;

    return pickBy(model, (value: any) => {
      // Delete any nested AWS types
      const isValueDefined = !!value;
      const isValueObjectField = typeof value === 'object';

      if (isValueDefined && isValueObjectField) {
        const doesValueHaveTypename = !!value.__typename;
        const doesValueHaveId = !!value.id;

        if (doesValueHaveTypename) {
          const isValueModelConnection = value.__typename.startsWith('Model');

          if (doesValueHaveId || isValueModelConnection) {
            // Object field that's a DB item from another table, remove it
            return REMOVE_FIELD;
          } else {
            // Object field that's a DB item but belongs to the parent object, keep it
            return KEEP_FIELD;
          }
        } else {
          // Object field that's not a DB item, keep it
          return KEEP_FIELD;
        }
      } else {
        // Not an object field, keep field
        return KEEP_FIELD;
      }
    }) as R;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from './confirm-modal.component';
import { ConfirmModalModule } from './confirm-modal.module';

@Injectable({
  providedIn: ConfirmModalModule
})
export class ConfirmModalService {
  constructor(private dialog: MatDialog) {}

  public show(
    title?: string,
    body?: string,
    yesButtonTitle?: string,
    noButtonTitle?: string,
    hideConfirmButton?: boolean,
    hideTitle?: boolean
  ): Observable<boolean> {
    const confirmModalMatDialogRef = ConfirmModalComponent.open(this.dialog, {
      title,
      body,
      yesButtonTitle,
      noButtonTitle,
      hideConfirmButton,
      hideTitle
    });

    return confirmModalMatDialogRef.afterClosed();
  }
}

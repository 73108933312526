import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'csi-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SuccessModalComponent>
  ) {}

  ngOnInit() {}

  static open(dialog: MatDialog, title: string) {
    return dialog.open<SuccessModalComponent>(SuccessModalComponent, {
      data: {
        title: title
      },
      disableClose: true
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageDrawingModule } from 'ngx-image-drawing';
import { InfoPopoverModule } from '../info-popover/info-popover.module';
import { SharedNgxBootstrapModule } from '../shared-ngx-bootstrap/shared-ngx-bootstrap.module';
import { SharedModule } from '../shared.module';
import { ShowLabelInlineDirective } from './directives/show-label-inline/show-label-inline.directive';
import { DrawOverCanvasComponent } from './draw-symptom-control/draw-over-canvas/draw-over-canvas.component';
import { DrawOverImageModalComponent } from './draw-symptom-control/draw-over-image-modal/draw-over-image-modal.component';
import { DrawSymptomControlComponent } from './draw-symptom-control/draw-symptom-control.component';
import { DropdownSymptomControlComponent } from './dropdown-symptom-control/dropdown-symptom-control.component';
import { MethodSymptomControlComponent } from './method-symptom-control/method-symptom-control.component';
import { ModalSymptomControlComponent } from './modal-symptom-control/modal-symptom-control.component';
import { AdditionalFluoresceinGradingModalComponent } from './modals/custom-modals/additional-fluorescein-grading-modal/additional-fluorescein-grading-modal.component';
import { BulbarLimbalModalComponent } from './modals/custom-modals/bulbar-limbal-modal/bulbar-limbal-modal.component';
import { MgeSecretionModalComponent } from './modals/custom-modals/mge-secretion-modal/mge-secretion-modal.component';
import { NitbutModalComponent } from './modals/custom-modals/nitbut-modal/nitbut-modal.component';
import { QuantitativeCornealSensationModalComponent } from './modals/custom-modals/quantitative-corneal-sensation-modal/quantitative-corneal-sensation-modal.component';
import { QuantitativeCornealStainingModalComponent } from './modals/custom-modals/quantitative-corneal-staining-modal/quantitative-corneal-staining-modal.component';
import { QuantitativeLidWiperComponent } from './modals/custom-modals/quantitative-lid-wiper/quantitative-lid-wiper.component';
import { MediaModalComponent } from './modals/media-modal/media-modal.component';
import { SymptomSimpleModalComponent } from './modals/symptom-simple-modal/symptom-simple-modal.component';
import { NumericSymptomControlComponent } from './numeric-symptom-control/numeric-symptom-control.component';
import { SymptomsPipesModule } from './pipes/symptoms-pipes.module';
import { SchemaModule } from './services/schema/schema.module';
import { SymptomControlAdapterComponent } from './symptom-control-adapter/symptom-control-adapter.component';
import { SymptomGroupComponent } from './symptom-group/symptom-group.component';
import { SymptomComponent } from './symptom/symptom.component';
import { TextSymptomControlComponent } from './text-symptom-control/text-symptom-control.component';
import { ThermalScaleModule } from './thermal-scale/thermal-scale.module';
import { ToggleGroupSymptomControlComponent } from './toggle-group-symptom-control/toggle-group-symptom-control.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedNgxBootstrapModule,
    FontAwesomeModule,
    InfoPopoverModule,
    SchemaModule,
    ThermalScaleModule,
    SymptomsPipesModule,
    ImageDrawingModule
  ],
  declarations: [
    SymptomComponent,
    SymptomSimpleModalComponent,
    DropdownSymptomControlComponent,
    NumericSymptomControlComponent,
    TextSymptomControlComponent,
    SymptomControlAdapterComponent,
    ModalSymptomControlComponent,
    MethodSymptomControlComponent,
    ToggleGroupSymptomControlComponent,
    MgeSecretionModalComponent,
    QuantitativeCornealStainingModalComponent,
    AdditionalFluoresceinGradingModalComponent,
    QuantitativeCornealSensationModalComponent,
    ShowLabelInlineDirective,
    NitbutModalComponent,
    QuantitativeLidWiperComponent,
    DrawSymptomControlComponent,
    DrawOverImageModalComponent,
    DrawOverCanvasComponent,
    BulbarLimbalModalComponent,
    SymptomGroupComponent,
    MediaModalComponent,

  ],
  exports: [
    DropdownSymptomControlComponent,
    DrawSymptomControlComponent,
    NumericSymptomControlComponent,
    TextSymptomControlComponent,
    ModalSymptomControlComponent,
    MethodSymptomControlComponent,
    ToggleGroupSymptomControlComponent,
    SymptomControlAdapterComponent,
    SymptomComponent,
    ShowLabelInlineDirective,
    SymptomGroupComponent
  ],
  entryComponents: [
    SymptomSimpleModalComponent,
    MgeSecretionModalComponent,
    QuantitativeCornealStainingModalComponent,
    AdditionalFluoresceinGradingModalComponent,
    QuantitativeCornealSensationModalComponent,
    NitbutModalComponent,
    QuantitativeLidWiperComponent,
    DrawOverImageModalComponent,
    BulbarLimbalModalComponent,
    MediaModalComponent
  ]
})
export class SymptomsModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { Drug, DrugActiveIngredients } from 'src/API';

export enum DrugFormat {
  Name,
  ActiveIngredients,
  NameWithActiveIngredients
}

@Pipe({
  name: 'drug'
})
export class DrugPipe implements PipeTransform {
  transform(medication: Drug, format: DrugFormat = DrugFormat.NameWithActiveIngredients): string {
    if (medication) {
      switch (format) {
        case DrugFormat.Name:
          return medication.name;
        case DrugFormat.ActiveIngredients:
          return `${this.formatActiveIngredients(medication)}`;
        default:
          return `${medication.name} (${this.formatActiveIngredients(medication)})`;
      }
    }

    return null;
  }

  private formatActiveIngredients(medication: Drug): string {
    const routes = medication.routes
      ? medication.routes
          .map(route => (route && route.route_of_administration) || '')
          .filter(route => route !== '')
          .join('/')
      : '';

    const ingredients = medication.active_ingredients
      ? medication.active_ingredients
          .map(ingredient => this.formatActiveIngredient(ingredient))
          .filter(ingredient => ingredient !== '')
          .join(', ')
      : '';

    return `${routes}: ${ingredients}`.toLowerCase();
  }

  private formatActiveIngredient(ingredient: DrugActiveIngredients | null): string {
    if (!ingredient) return '';

    const name = ingredient.ingredient || '';
    const strength = ingredient.strength != null ? ingredient.strength : '';
    const strengthUnit = ingredient.strength_unit || '';

    return `${name} ${strength}${strengthUnit}`.trim();
  }
}

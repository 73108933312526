import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'csi-verify-phone-modal',
  templateUrl: './verify-phone-modal.component.html',
  styleUrls: ['./verify-phone-modal.component.css']
})
export class VerifyPhoneModalComponent implements AfterViewInit {
  @ViewChild('verificationInput', { read: ElementRef, static: false })
  verificationInputChild: ElementRef;

  public matSpinnerOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Verify My Phone',
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };
  public verificationErrorMessageMap = {
    ExpiredCodeException: 'The code has expired. We have sent you a new code.',
    CodeMismatchException: 'The code is invalid.',
    LimitExceededException: 'You have exceeded the limit. Please try again later.'
  };
  public verificationCode = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$')
  ]);
  public errorMessage: string;
  public verificationTextSent: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<VerifyPhoneModalComponent>
  ) {}

  ngAfterViewInit() {
    this.authenticationService.sendPhoneNumberVerification().subscribe(
      () => {
        this.verificationTextSent = true;
        this.verificationInputChild.nativeElement.focus();
      },
      error => {
        this.verificationTextSent = false;
        this.errorMessage = this.verificationErrorMessageMap[error.name];
      }
    );
  }

  onSubmit() {
    if (this.verificationCode.valid) {
      this.matSpinnerOptions.active = true;
      this.authenticationService
        .verifyPhoneNumber(this.verificationCode.value)
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          error => {
            this.errorMessage = this.verificationErrorMessageMap[error.name];
            if (error.name === 'ExpiredCodeException') {
              this.resendVerification();
            }
          }
        );
    }
  }

  resendVerification() {
    this.authenticationService.sendPhoneNumberVerification();
  }
}

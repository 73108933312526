import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';

export enum DryEyeExpansionPanel {
  weather = 'weather',
  medicalHistory = 'medicalHistory',
  medications = 'medications',
  symptom = 'symptom',
  signs = 'signs',
  attachment = 'attachment',
  impressions = 'impressions',
  verdict = 'verdict',
  recommendations = 'recommendations',
  treatmentPerformed = 'treatmentPerformed'
}

@Injectable({
  providedIn: 'root'
})
export class DryEyeFormsService {
  public dryEyeFormsExpansionMap: { [key in DryEyeExpansionPanel]?: boolean } = {};
  public enabledExpansionMap: { [key in DryEyeExpansionPanel]?: boolean } = {};

  public multiComponentQuestionnairesToComponentsMap: { [key: string]: QuestionnaireType[] } = {
    [QuestionnaireType.OSDIAndSPEED]: [QuestionnaireType.OSDI, QuestionnaireType.SPEED],
    [QuestionnaireType.SPEEDII]: [QuestionnaireType.SPEED, QuestionnaireType.SPEEDII]
  };

  constructor() {}

  public updateSymptomScores(
    symptomsAndSignsFormGroup: FormGroup,
    lastCompletedLinkedQuestionnaireByTypeMap: any
  ) {
    Object.keys(lastCompletedLinkedQuestionnaireByTypeMap).forEach(key => {
      const scores = JSON.parse(lastCompletedLinkedQuestionnaireByTypeMap[key].response.scores);
      if (symptomsAndSignsFormGroup.controls[key.toLowerCase()]) {
        symptomsAndSignsFormGroup.controls[key.toLowerCase()].setValue(scores[key]);
      }
    });
  }

  replaceDisplayedQuestionnaireWithLatest(
    replacementQuestionnaireKey: string,
    questionnaireType: string,
    lastCompletedLinkedQuestionnaireByTypeMap: {
      [key: string]: QuestionnaireRequest;
    }
  ) {
    if (
      new Date(lastCompletedLinkedQuestionnaireByTypeMap[replacementQuestionnaireKey].completedAt) >
      new Date(lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType].completedAt)
    ) {
      lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType] =
        lastCompletedLinkedQuestionnaireByTypeMap[replacementQuestionnaireKey];
    }
  }
}

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  Doctor,
  Flag,
  ListNotificationsByTypeIdBasedOnCreatedAtQuery,
  ListSystemNotificationsQuery,
  ModelSortDirection,
  Notification,
  NotificationType,
  SystemNotification,
  UpdateNotificationInput,
  UpdateNotificationMutation
} from 'src/API';
import { parseDateToString } from 'src/app/core/api/date.util';
import { AppSyncService } from 'src/app/core/appsync.service';
import { updateNotification } from 'src/graphql/mutations';
import { listSystemNotifications } from 'src/graphql/queries';
import {
  NotificationProperties,
  NotificationPropertiesInput,
  NotificationStatusInput
} from './../../../API';
import { listNotificationsByTypeIdBasedOnCreatedAt } from './inbox.model';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  public inboxContentTypeToNotificationMap: { [key in NotificationType]: string[] };
  public inboxContent: { [key in NotificationType]: Notification[] } = {
    [NotificationType.questionnaire]: [],
    [NotificationType.referral]: [],
    [NotificationType.intake]: [],
    [NotificationType.system]: []
  };

  public inboxContentTypeToUnreadNotificationCountMap: {
    [key in NotificationType]: number;
  } = {
    [NotificationType.questionnaire]: 0,
    [NotificationType.referral]: 0,
    [NotificationType.intake]: 0,
    [NotificationType.system]: 0
  };

  public totalUnreadNotifications = 0;
  public loggedInDoctor;
  public clinicId;
  public clinicDoctors;

  constructor(private appSyncService: AppSyncService) {}

  initInbox(clinicId: string, doctorId: string, doctors: Doctor[]) {
    this.loggedInDoctor = doctorId;
    this.clinicId = clinicId;
    this.clinicDoctors = doctors;

    this.loadNotifications().subscribe(() => {});
  }

  loadNotifications() {
    this.resetInbox();

    return zip(
      ...[NotificationType.intake, NotificationType.questionnaire, NotificationType.referral].map(
        type => {
          return this.listNotificationByTypeWithinLastMonth(
            type,
            this.clinicId,
            this.getDateStringRangeForTodayAnd30DaysBack()
          ).pipe(
            tap(notifications => {
              for (const notification of notifications) {
                if (notification.properties.length === 0) {
                  this.setToDefaultNotificationProperties(notification);
                } else if (notification.properties.length !== this.clinicDoctors.length) {
                  this.updateNotificationPropertiesForNewDoctors(notification);
                }

                if (notification.properties.length === this.clinicDoctors.length) {
                  const status = this.getNotificationStatusCorrespondingToDoctorId(
                    notification.properties,
                    this.loggedInDoctor
                  );

                  if (status.flag !== Flag.trash) {
                    this.inboxContent[type].push(notification);
                  }
                }
              }
              this.updateTotalUnreadNotificationCount();
            })
          );
        }
      )
    );
  }

  updateNotificationPropertiesForNewDoctors(notification: Notification) {
    const notificationPropertiesInput: NotificationPropertiesInput[] = [];
    for (const doctor of this.clinicDoctors) {
      let isNew = true;
      for (const property of notification.properties) {
        if (property.doctorId === doctor.id) {
          isNew = false;
          // If property corresponding to Doctor id already exists, convert it to proper format
          notificationPropertiesInput.push({
            doctorId: doctor.id,
            status: {
              read: property.status.read,
              flag: property.status.flag
            }
          });
          break;
        }
      }
      if (isNew) {
        // If Doctor does not yet have a corresponding notification status, add a default status
        notificationPropertiesInput.push({
          doctorId: doctor.id,
          status: {
            read: false,
            flag: Flag.none
          }
        });
      }
    }

    this.updateNotification({
      id: notification.id,
      properties: notificationPropertiesInput
    }).subscribe(() => {
      // console.log(
      //   'Updated notification properties to include newly added doctors',
      //   notificationPropertiesInput
      // );
    });
  }

  setToDefaultNotificationProperties(notification: Notification) {
    // console.log('setting default notification properties');
    const notificationPropertiesInput: NotificationPropertiesInput[] = [];
    this.clinicDoctors.forEach(doctor => {
      const defaultNotificationStatusInput: NotificationStatusInput = {
        read: false,
        flag: Flag.none
      };
      // console.log('notification status input: ', defaultNotificationStatusInput);
      notificationPropertiesInput.push({
        doctorId: doctor.id,
        status: defaultNotificationStatusInput
      });
    });
    this.updateNotification({
      id: notification.id,
      properties: notificationPropertiesInput
    }).subscribe(val => {
      // console.log('updated notification: ', val);
    });
  }

  getNotificationStatusCorrespondingToDoctorId(
    notificationProperties: NotificationProperties[],
    doctorId: string
  ) {
    if (!notificationProperties) return undefined;
    return notificationProperties.find(property => {
      return property.doctorId === doctorId;
    }).status;
  }

  decrementUnreadNotificationCount(type: NotificationType) {
    if (this.inboxContentTypeToUnreadNotificationCountMap[type] > 0) {
      this.inboxContentTypeToUnreadNotificationCountMap[type]--;
      this.totalUnreadNotifications--;
      // console.log('A notification has been read... unread:', this.totalUnreadNotifications);
    }
  }

  updateTotalUnreadNotificationCount() {
    this.totalUnreadNotifications = 0;
    Object.keys(this.inboxContent).forEach(key => {
      const notificationList: Notification[] = this.inboxContent[key];
      this.inboxContentTypeToUnreadNotificationCountMap[key] = 0;
      for (const notification of notificationList) {
        const notificationStatus = this.getNotificationStatusCorrespondingToDoctorId(
          notification.properties,
          this.loggedInDoctor
        );
        // console.log('corresponding notification status: ', notificationStatus);
        if (notificationStatus) {
          if (!notificationStatus.read) {
            this.totalUnreadNotifications++;
            this.inboxContentTypeToUnreadNotificationCountMap[key]++;
          }
        } else {
          this.totalUnreadNotifications++;
          this.inboxContentTypeToUnreadNotificationCountMap[key]++;
        }
      }
    });
  }

  setNotificationReadTrue(notification: Notification) {
    const modifiedProperties = notification.properties.map(property => {
      return {
        doctorId: property.doctorId,
        status: {
          read:
            this.loggedInDoctor === property.doctorId
              ? (property.status.read = true && true)
              : property.status.read,
          flag: property.status.flag || Flag.none
        }
      };
    });
    this.decrementUnreadNotificationCount(notification.type);
    this.updateNotification({
      id: notification.id,
      properties: modifiedProperties
    }).subscribe(() => {});
  }

  deleteNotification(notification: Notification, read: boolean) {
    // Delete notification without triggering change detection
    const indexToDelete = this.inboxContent[notification.type].findIndex(
      n => n.id === notification.id
    );
    for (let i = indexToDelete; i > 0 - 1; i--) {
      this.inboxContent[notification.type][i] = this.inboxContent[notification.type][i - 1];
    }
    this.inboxContent[notification.type].shift();

    if (!read) {
      this.decrementUnreadNotificationCount(notification.type);
    }
    const modifiedProperties = notification.properties.map(property => {
      return {
        doctorId: property.doctorId,
        status: {
          read:
            this.loggedInDoctor === property.doctorId
              ? (property.status.read = true && true)
              : property.status.read,
          flag:
            this.loggedInDoctor === property.doctorId
              ? (property.status.flag = Flag.trash && Flag.trash)
              : property.status.flag
        }
      };
    });
    this.updateNotification({
      id: notification.id,
      properties: modifiedProperties
    }).subscribe(val => {
      // console.log('notification deleted', val);
    });
  }

  updateNotification(input: UpdateNotificationInput): Observable<UpdateNotificationMutation> {
    return this.appSyncService.hydrated().pipe(
      switchMap(notification => {
        return notification.mutate({
          mutation: gql(updateNotification),
          variables: { input: input }
        });
      })
    );
  }

  resetInbox() {
    // console.log('resetting inbox...');
    Object.keys(this.inboxContent).forEach(key => {
      this.inboxContent[key] = [];
    });
    // console.log('inbox is reset', this.inboxContent);
  }

  listNotificationByTypeWithinLastMonth(
    type: NotificationType,
    notificationClinicId: string,
    dateRange: string[]
  ): Observable<Notification[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listNotificationsByTypeIdBasedOnCreatedAt),
          variables: {
            typeId: `${notificationClinicId}-${type}`,
            createdAt: { between: dateRange },
            sortDirection: ModelSortDirection.DESC
          },
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<ListNotificationsByTypeIdBasedOnCreatedAtQuery>) => {
        // console.log(result);
        return result.data.listNotificationsByTypeIdBasedOnCreatedAt.items;
      })
    );
  }

  getDateStringRangeForTodayAnd30DaysBack() {
    // Get today's date
    const today = new Date();
    today.setDate(today.getDate() + 2);

    // Calculate the date 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    // Return the date range
    return [parseDateToString(thirtyDaysAgo), parseDateToString(today)];
  }

  listSystemNotifications(fetchFromNetwork: boolean = true): Observable<SystemNotification[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listSystemNotifications),
          variables: {},
          fetchPolicy: fetchFromNetwork ? 'network-only' : 'cache-first'
        })
      ),
      map(
        (result: ApolloQueryResult<ListSystemNotificationsQuery>) =>
          result.data.listSystemNotifications.items
      )
    );
  }
}

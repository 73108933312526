import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'csi-list-view-and-add',
  templateUrl: './list-view-and-add.component.html',
  styleUrls: ['./list-view-and-add.component.scss']
})
export class ListViewAndAddComponent implements OnInit {
  @Input() itemsFormControl: FormControl;
  @Input() cleanFormControl: boolean;
  @Input() defaultItems: string[] = [];
  @Input() itemName: string;
  @Input() validateEmail: boolean;
  @Input() allowRemoveIfOneValue: boolean;
  @Input() readOnly: boolean;
  @Input() requiredDefaultItems: { [key: string]: boolean } = {};
  @Input() disableActions: boolean;
  @Input() set isSaving(isSaving: boolean) {
    if (isSaving) {
      this.addCustomItem();
    }
  }

  public addNewFormControl = new FormControl();
  public defaultItemsNotSelected: string[];

  constructor() {}

  ngOnInit() {
    if (this.cleanFormControl) {
      if (this.itemsFormControl.value) {
        this.itemsFormControl.setValue(this.itemsFormControl.value.length ? [] : null);
      }
    }

    if (Object.keys(this.requiredDefaultItems).length > 0 && this.itemsFormControl.value === null) {
      this.itemsFormControl.setValue(Object.keys(this.requiredDefaultItems));
    }
    this.defaultItemsNotSelected = this.defaultItems.filter(
      item =>
        !this.itemsFormControl.value ||
        this.itemsFormControl.value.length === 0 ||
        !this.itemsFormControl.value.includes(item)
    );

    this.addValidatorsToAddFormControl();
  }

  onEnterKey(event) {
    // Prevent the default "Enter" key behavior
    event.preventDefault();
    this.addCustomItem();
  }

  addCustomItem() {
    if (this.addNewFormControl.value === null) {
      return;
    }
    setTimeout(() => {
      const item = this.addNewFormControl.value;

      this.addNewFormControl.markAsTouched();

      if (this.addNewFormControl.valid) {
        this.itemsFormControl.setValue(
          this.itemsFormControl.value ? [...this.itemsFormControl.value, item] : [item]
        );
        this.itemsFormControl.markAsDirty();
        this.addNewFormControl.setValue(null);
        this.addNewFormControl.markAsUntouched();

        this.clearValidators();
        return;
      }
    });
  }

  clearValidators() {
    this.addNewFormControl.clearValidators();
    this.addNewFormControl.updateValueAndValidity();
  }

  addDefaultItem(index: number) {
    const itemRemoved = this.defaultItemsNotSelected.splice(index, 1)[0];
    this.itemsFormControl.setValue(
      this.itemsFormControl.value ? [...this.itemsFormControl.value, itemRemoved] : [itemRemoved]
    );
  }

  removeItem(index) {
    const itemRemoved = this.itemsFormControl.value.splice(index, 1)[0];

    if (
      this.defaultItems.includes(itemRemoved) &&
      !this.defaultItemsNotSelected.includes(itemRemoved)
    ) {
      this.defaultItemsNotSelected.push(itemRemoved);
    }

    this.addNewFormControl.markAsUntouched();
  }

  duplicateOrDefault(itemsControl: AbstractControl, defaultItems: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      if (
        itemsControl.value &&
        itemsControl.value.length > 0 &&
        itemsControl.value.some(item => item.toLowerCase() === control.value.toLowerCase())
      ) {
        return { duplicate: true };
      }

      if (
        defaultItems &&
        defaultItems.length > 0 &&
        defaultItems.some(item => item.toLowerCase() === control.value.toLowerCase())
      ) {
        return { default: true };
      }
    };
  }

  addValidatorsToAddFormControl() {
    this.addNewFormControl.setValidators(
      [
        Validators.required,
        this.duplicateOrDefault(this.itemsFormControl, this.defaultItems),
        this.validateEmail ? Validators.email : null
      ].filter(validator => validator)
    );

    this.addNewFormControl.updateValueAndValidity();
  }
}

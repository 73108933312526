import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pages'
})
export class PagesPipe implements PipeTransform {
  transform<T>(items: T[], itemsInFirstPage: number, itemsInFollowingPages: number): T[][] {
    const firstPageItems: T[] = items.slice(0, itemsInFirstPage);
    const pages: T[][] = [];
    pages.push(firstPageItems);
    let slicedCount = firstPageItems.length;
    while (slicedCount !== items.length) {
      const nextPage: T[] = items.slice(slicedCount, slicedCount + itemsInFollowingPages);
      pages.push(nextPage);
      slicedCount += nextPage.length;
    }

    return pages;
  }
}

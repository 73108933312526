import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ApolloError } from 'apollo-client';
import { defer, forkJoin, iif, Observable } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { AccountSettingsFormGroup } from 'src/app/authentication/account-settings-form.model';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Clinic, ClinicService } from '../../../core/api/clinic.service';
import { ErrorHandlerService } from '../../../core/api/error-handler.service';
import { StaffService } from '../../../core/api/staff.service';
import { ClinicFormGroup } from '../clinic-form/clinic-form.model';
import { DoctorFormGroup } from '../doctor-form/doctor-form.model';
import { ChangeClinicModalComponent } from './../../../logged-in-navbar/change-clinic-modal/change-clinic-modal.component';

export type DoctorAndClinicInfoWizardMatDialogRef = MatDialogRef<
  DoctorAndClinicInfoWizardModalComponent,
  boolean
>;

@Component({
  selector: 'csi-doctor-and-clinic-info-wizard-modal',
  templateUrl: './doctor-and-clinic-info-wizard-modal.component.html',
  styleUrls: ['./doctor-and-clinic-info-wizard-modal.component.css']
})
export class DoctorAndClinicInfoWizardModalComponent implements OnInit, AfterViewInit {
  public readonly doctorFormGroup = new DoctorFormGroup();
  public readonly clinicFormGroup = new ClinicFormGroup();
  public readonly accountSettingsFormGroup = new AccountSettingsFormGroup();

  // allow TOS step to be required so user can't skip
  public readonly termsOfServiceFormControl = new FormControl(null, [Validators.required]);

  public isSaving = false;
  public isSaveDisabled = true;

  public shouldShowDoctorStep: boolean;
  public shouldShowClinicStep: boolean;
  public shouldShowTermsOfService: boolean;
  public shouldShowTermsOfUse: boolean;
  public shouldShowClinicNotSetup: boolean;
  public shouldShowProfileAccessIncomplete: boolean;

  public saveButtonLabel: string;
  public shouldShowNextButton = true;
  public shouldShowSaveButton: boolean;

  private hasReadTermsOfUse: boolean;
  private hasReadTermsOfService: boolean;

  private isTermsOfUseStep: boolean;
  private isTermsOfServiceStep: boolean;

  // TODO: This will come from the DB
  private readonly TOS_AGREEMENT_VERSION = 1;

  @ViewChild('saveButton', { static: false }) private saveButton: MatButton;
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;
  @ViewChild('termsOfServiceStep', { static: false }) private termsOfServiceStep: MatStep;
  @ViewChild('termsOfUseStep', { static: false }) private termsOfUseStep: MatStep;

  constructor(
    @Inject(MAT_DIALOG_DATA) public doctor: Doctor,
    private dialogRef: MatDialogRef<DoctorAndClinicInfoWizardModalComponent, boolean>,
    private errorHandlerService: ErrorHandlerService,
    private hostElementRef: ElementRef<HTMLElement>,
    private staffService: StaffService,
    private clinicService: ClinicService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {
    this.authenticationService.getLoggedInUser().subscribe(loggedInUser => {
      this.doctorFormGroup.patchCognitoData(loggedInUser);
    });

    this.doctorFormGroup.patchFromDoctor(doctor);
    this.clinicFormGroup.patchFromClinic(doctor.clinic as Clinic);

    this.doctorFormGroup.enable();
    this.clinicFormGroup.enable();
  }

  public static open(
    matDialogService: MatDialog,
    doctor: Doctor,
    modalConfig: MatDialogConfig<never> = {}
  ): DoctorAndClinicInfoWizardMatDialogRef {
    return matDialogService.open<DoctorAndClinicInfoWizardModalComponent, Doctor, boolean>(
      DoctorAndClinicInfoWizardModalComponent,
      {
        width: '850px',
        maxWidth: '95vw',
        height: 'auto',
        maxHeight: '95vh',
        ...modalConfig,
        data: doctor,
        autoFocus: false
      }
    );
  }

  ngOnInit() {
    // When a clinic is not setup, we can assume that the person is a clinic
    // owner setting up his account along with the clinic. This is because
    // doctors will have their account setup and be invited, so they will
    // always have a clinic setup
    const clinicOwner = this.staffService.isClinicOwner || !this.doctor.clinic;

    const isClinicMissingInformation = this.clinicService.isClinicMissingInformation(
      this.doctor.clinic
    );
    this.shouldShowDoctorStep = this.staffService.isDoctorMissingInformation(this.doctor);
    this.shouldShowClinicStep = clinicOwner && isClinicMissingInformation;

    // owner and either the clinic was not setup or they have not accepted the TOS
    // will trigger older clients to accept TOS
    // need "!this.doctor.clinic" again since it might be condition 1 from above
    this.shouldShowTermsOfService =
      !this.doctor.clinic || (clinicOwner && !this.doctor.clinic.agreedToTermsOfService);
    this.shouldShowTermsOfUse = !this.doctor.agreedToTerms;

    this.shouldShowClinicNotSetup =
      !clinicOwner && (!this.doctor.clinic || isClinicMissingInformation);

    // This step should not show up unless have agreed to terms of use.
    // Unable to access our software unless we have set them up with Referral Center or they are linked to a Referral Center.

    const needsProfileSetup =
      this.shouldShowClinicStep ||
      this.shouldShowDoctorStep ||
      this.shouldShowTermsOfService ||
      this.shouldShowTermsOfUse;

    this.shouldShowProfileAccessIncomplete =
      !this.staffService.isProfileAccessComplete(this.doctor) && !needsProfileSetup;
  }

  ngAfterViewInit() {
    // Trigger a step selection change to apply dynamic button config
    const triggeredEvent = new StepperSelectionEvent();
    triggeredEvent.selectedStep = this.stepper.selected;
    // required to avoid Expression has changed error
    setTimeout(() => {
      this.onStepperSelectionChange(triggeredEvent);
    }, 0);
  }

  public save() {
    // Update clinic with acknowledger's signature and title, along
    // with accept TOS
    // NOTE: this step will be after the doctor and clinic forms,
    // meaning this condition cannot be true if the customer is missing
    // information and that's the reason as to why he is in this wizard,
    // because missing information can only happen if they have already
    // accepted the TOS.

    if (this.shouldShowTermsOfService && this.isTermsOfServiceStep) {
      // Update form with TOS
      this.clinicFormGroup.controls.agreedToTermsOfService.setValue(true);

      // Allow going to the next step
      this.termsOfServiceFormControl.clearValidators();
      this.termsOfServiceFormControl.updateValueAndValidity();

      // only go next if next is available
      if (this.shouldShowTermsOfUse) {
        this.stepper.next();

        // Disable the save button so they also read the TOU
        this.isSaveDisabled = !this.hasReadTermsOfUse;

        return;
      }
    }

    // ensure the form fields are valid
    // save can only be triggered after all the "available" forms are filled
    // available: missing info or creating for the first time

    this.clinicFormGroup.markAllAsTouched();
    this.doctorFormGroup.markAllAsTouched();
    if (this.shouldShowClinicStep && !this.clinicFormGroup.valid) return;
    if (this.shouldShowDoctorStep && !this.doctorFormGroup.valid) return;

    this.doctorFormGroup.submitted = true;
    this.clinicFormGroup.submitted = true;

    this.disableInteraction();

    if (
      this.shouldShowDoctorStep &&
      this.accountSettingsFormGroup.controls.preferredMFAType.value
    ) {
      this.authenticationService.setPreferredMFA(
        this.accountSettingsFormGroup.controls.preferredMFAType.value
      );
    }

    iif(
      // only create if the clinic setup screen is showing (isClinicOwner)
      // and the clinic doesn't already exist
      () => this.shouldShowClinicStep && !this.doctor.clinic,
      defer(() =>
        this.clinicService.createClinic(this.clinicFormGroup.toCreateClinicInput()).pipe(
          switchMap(clinic => {
            this.doctor.clinic = clinic;
            return this.updateDoctorAndClinicInfo();
          })
        )
      ),
      defer(() => this.updateDoctorAndClinicInfo())
    ).subscribe({
      error: (error: ApolloError) => {
        this.enableInteraction();

        this.errorHandlerService.handleGraphQLError(error);
      },
      complete: () => {
        this.enableInteraction();

        this.dialogRef.close(true);
      }
    });
  }

  // TODO: convert to an rxjs version for throttling
  public onScroll(e: any) {
    // Once they have read through and want to go back to scroll up, we don't
    // want to trigger this again.
    if (!this.isSaveDisabled) return;
    const scrollTarget = e.target;
    // 50 for ensuring that near the end is good
    const scrolledToBottom =
      scrollTarget.scrollHeight - scrollTarget.scrollTop <= scrollTarget.clientHeight + 20;
    if (scrolledToBottom) {
      this.isSaveDisabled = false;
      if (this.isTermsOfServiceStep) {
        this.hasReadTermsOfService = true;
      }
      if (this.isTermsOfUseStep) {
        this.hasReadTermsOfUse = true;
      }

      // Ensures that the button is focus
      setTimeout(() => {
        this.saveButton.focus();
      }, 250);
    }
  }

  public onNext() {
    this.stepper.selected.stepControl.markAllAsTouched();
    this.stepper.next();
  }

  private hideSaveAndShowNext = () => {
    this.shouldShowSaveButton = false;
    this.shouldShowNextButton = true;
    this.saveButtonLabel = 'Next';
  };

  public onStepperSelectionChange(event: StepperSelectionEvent) {
    let elementToBeFocused = '.mat-horizontal-stepper-content[aria-expanded=true] input';
    // ensure not undefined
    if (event.selectedStep) {
      if (
        ((this.shouldShowDoctorStep && !this.shouldShowClinicStep) ||
          (!this.shouldShowDoctorStep && this.shouldShowClinicStep) ||
          (this.shouldShowDoctorStep &&
            this.shouldShowClinicStep &&
            event.selectedStep.label === 'Clinic Information')) &&
        !this.shouldShowTermsOfService &&
        !this.shouldShowTermsOfUse
      ) {
        this.shouldShowSaveButton = true;
        this.shouldShowNextButton = false;
        this.isSaveDisabled = false;
        this.saveButtonLabel = 'Save';
        return;
      }

      this.isTermsOfServiceStep = event.selectedStep === this.termsOfServiceStep;
      this.isTermsOfUseStep = event.selectedStep === this.termsOfUseStep;

      if (this.isTermsOfServiceStep || this.isTermsOfUseStep) {
        this.shouldShowNextButton = false;
        this.shouldShowSaveButton = true;
        if (this.isTermsOfServiceStep) {
          this.saveButtonLabel = 'I Agree to the Terms of Service';
          this.isSaveDisabled = !this.hasReadTermsOfService;
        } else if (this.isTermsOfUseStep) {
          this.saveButtonLabel = 'I Agree to the Terms of Use & Privacy Policy';
          this.isSaveDisabled = !this.hasReadTermsOfUse;
        }
        elementToBeFocused = '.focusable';
      } else {
        this.hideSaveAndShowNext();
      }
    } else {
      this.hideSaveAndShowNext();
    }

    // Focus on the first input element or the div element inside the active stepper
    // Delay to ensure next step is loaded
    setTimeout(() => {
      const firstInput = this.hostElement.querySelector<HTMLInputElement | HTMLDivElement>(
        elementToBeFocused
      );
      if (firstInput) firstInput.focus();
    }, 250);
  }

  private updateDoctorAndClinicInfo(): Observable<void | Doctor> {
    this.clinicFormGroup.controls.agreedToTermsOfService.setValue(
      this.shouldShowTermsOfService ||
        (this.doctor && this.doctor.clinic && this.doctor.clinic.agreedToTermsOfService)
    );

    return forkJoin([
      this.staffService.updateDoctor(
        this.doctorFormGroup.toUpdateDoctorInput(
          this.doctor.id,
          this.doctor.clinic.id,
          this.shouldShowTermsOfUse || this.doctor.agreedToTerms
        )
      ),
      !this.doctor.clinic.clinicSetup
        ? this.clinicService.updateClinicAndCreateClinicSetup(
            this.clinicFormGroup.toUpdateClinicInput(this.doctor.clinic.id),
            { clinicSetupClinicId: this.doctor.clinic.id }
          )
        : this.clinicService.updateClinic(
            this.clinicFormGroup.toUpdateClinicInput(this.doctor.clinic.id)
          )
    ]).pipe(mapTo(undefined));
  }

  private disableInteraction() {
    this.isSaving = true;
    this.dialogRef.disableClose = true;

    this.doctorFormGroup.disable();
    this.clinicFormGroup.disable();
  }

  private enableInteraction() {
    this.isSaving = false;
    this.dialogRef.disableClose = false;

    this.doctorFormGroup.enable();
    this.clinicFormGroup.enable();
  }

  private get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.dialogRef.close());
  }

  public showChangeClinicModal() {
    ChangeClinicModalComponent.open(this.dialog);
  }
}

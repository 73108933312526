import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const emailValidator: ValidatorFn[] = [
  Validators.email,
  // Default Angular Email Validator accepts emails such as john@gmail, while this is technically a valid email,
  // practically it is not, so we have this extra regex validation.
  Validators.pattern('.*\\.[a-z]{2,4}$')
];

export function emailVerification(
  emailControl: AbstractControl
): (control: AbstractControl) => ValidationErrors | null {
  return function(verifyEmailControl: AbstractControl): ValidationErrors | null {
    const emailValue = emailControl.value;
    const verifyEmailValue = verifyEmailControl.value;

    if (emailValue !== verifyEmailValue ) {
      return { emailMatch: true };
    }

    return null;
  };
}

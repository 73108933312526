import { Directive, ElementRef, HostListener } from '@angular/core';
import { filter } from 'lodash-es';

@Directive({
  selector: '[csiTabAfterNgSelectOnTab]'
})
export class TabAfterNgSelectOnTabDirective {
  private elementSelector = ['input', 'button', 'textarea', 'select', 'object'];

  constructor(private el: ElementRef) {}
  @HostListener('keydown.tab', ['$event'])
  tab(e) {
    this.searchForElement(this.el.nativeElement, true).focus();
    e.preventDefault();
  }

  @HostListener('keydown.shift.tab', ['$event'])
  shiftTab(e) {
    this.searchForElement(this.el.nativeElement, false).focus();
    e.preventDefault();
  }

  private searchForElement(startElement: Element, searchForwards: boolean): any {
    let parent = startElement;
    let sibling;
    do {
      sibling = searchForwards ? parent.nextElementSibling : parent.previousElementSibling;
      while (sibling) {
        if (this.isFocusable(sibling)) {
          return sibling;
        } else {
          const focusableChild = this.getFocusableChild(sibling, searchForwards);
          if (focusableChild) {
            return focusableChild;
          } else {
            sibling = searchForwards ? sibling.nextElementSibling : sibling.previousElementSibling;
          }
        }
      }
      parent = parent.parentElement;
    } while (parent);
    throw new Error(`Could not find any element with selector of ${this.elementSelector}`);
  }

  private isFocusable(sibling: Element): boolean {
    const isTabbableElementType = this.elementSelector.reduce((previous: boolean, current) => {
      return current.toLowerCase() === (sibling as Element).tagName.toLowerCase() || previous;
    }, false);
    if (isTabbableElementType && this.isValidFocusable(sibling)) {
      return true;
    } else {
      return false;
    }
  }

  private getFocusableChild(parent, searchForwards: boolean): Element {
    const selector = this.elementSelector.join(', ');
    let allElementsOfSelectorType = parent.querySelectorAll(selector);
    allElementsOfSelectorType = filter(allElementsOfSelectorType, val =>
      this.isValidFocusable(val)
    );
    if (allElementsOfSelectorType.length > 0) {
      return searchForwards
        ? allElementsOfSelectorType[0]
        : allElementsOfSelectorType[allElementsOfSelectorType.length - 1];
    } else {
      return null;
    }
  }

  private isValidFocusable(element: any): boolean {
    if (
      element.tabIndex === -1 ||
      element.disabled ||
      window.getComputedStyle(element).visibility !== 'visible'
    ) {
      return false;
    }
    while (element) {
      if (window.getComputedStyle(element).display === 'none') {
        return false;
      }
      element = element.parentElement;
    }
    return true;
  }
}

import { NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { defaultTimeoutConfig } from '../core/authentication/csi-user-idle.service';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticatorModalComponent } from './authenticator-modal/authenticator-modal.component';
import { CreateAccountComponent } from './authenticator-modal/create-account/create-account.component';
import { LoginComponent } from './authenticator-modal/login/login.component';
import { RequestResetPasswordComponent } from './authenticator-modal/request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './authenticator-modal/reset-password/reset-password.component';
import { UserVerificationComponent } from './authenticator-modal/user-verification/user-verification.component';

@NgModule({
  imports: [
    SharedModule,
    AuthenticationRoutingModule,
    UserIdleModule.forRoot(defaultTimeoutConfig)
  ],
  declarations: [
    LoginComponent,
    RequestResetPasswordComponent,
    ResetPasswordComponent,
    AuthenticatorModalComponent,
    CreateAccountComponent,
    UserVerificationComponent
  ],
  providers: [],
  entryComponents: [AuthenticatorModalComponent]
})
export class AuthenticationModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TextFavorite, TextFavoriteType } from 'src/API';
import { TextFavoriteSelectComponent } from '../text-favorite-select/text-favorite-select.component';
import { TextFavoriteService } from './../text-favorite.service';

@Component({
  selector: 'csi-text-input-with-favorite',
  templateUrl: './text-input-with-favorite.component.html',
  styleUrls: ['./text-input-with-favorite.component.css']
})
export class TextInputWithFavoriteComponent implements OnInit {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() type: TextFavoriteType;

  constructor(private dialog: MatDialog, private textFavoriteService: TextFavoriteService) {}

  ngOnInit() {}

  openTextFavoriteSelect() {
    this.dialog
      .open(TextFavoriteSelectComponent, { width: '90vw', data: this.type })
      .afterClosed()
      .subscribe((addedFavorites: TextFavorite[]) => {
        if (addedFavorites && addedFavorites.length > 0) {
          this.control.setValue(
            this.control.value +
              (this.control.value ? '\n\n' : '') +
              addedFavorites.map(addedFavorite => '* ' + addedFavorite.text).join('\n\n')
          );
          this.textFavoriteService.setTreatmentFormControlValue(addedFavorites);
        }
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'csi-form-template-text-field-config',
  templateUrl: './form-template-text-field-config.component.html',
  styleUrls: ['./form-template-text-field-config.component.css']
})
export class FormTemplateTextFieldConfigComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;
  constructor() {}

  ngOnInit() {
    (this.templateFieldFormGroup.controls['typeConfig'] as FormGroup).addControl(
      'multiLine',
      new FormControl()
    );
  }
}

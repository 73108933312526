import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { combineLatest } from 'rxjs';
import { ModalSymptomCustomModalOptions } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';

@Component({
  selector: 'csi-quantitative-lid-wiper',
  templateUrl: './quantitative-lid-wiper.component.html',
  styleUrls: ['./quantitative-lid-wiper.component.css']
})
export class QuantitativeLidWiperComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit {
  public horizontalGradeMap = {
    0: 'None',
    1: '<2mm',
    2: '2 - 4 mm',
    3: '5 - 9 mm',
    4: '> 10mm'
  };
  public sagittalGradeMap = {
    0: 'None',
    1: '<25% of the lid wiper width',
    2: '25% to <50% of the lid wiper width',
    3: '50% to <75% of the lid wiper width',
    4: '>=75% of the lid wiper width'
  };

  public upperHorizontalFormKey = this.config.formKey + '-upper-horizontal';
  public upperSagittalFormKey = this.config.formKey + '-upper-sagittal';
  public lowerHorizontalFormKey = this.config.formKey + '-lower-horizontal';
  public lowerSagittalFormKey = this.config.formKey + '-lower-sagittal';

  public subFormGroup = new FormGroup({});

  public mainFormControl: AbstractControl;

  public upperScore;
  public lowerScore;

  constructor(
    public dialogRef: MatDialogRef<QuantitativeLidWiperComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    this.getAllPossibleSymptomControlKeys().forEach(subKey => {
      this.subFormGroup.addControl(subKey, new FormControl(null, Validators.required));
    });

    this.mainFormControl = this.config.formGroup.controls[this.config.formKey];
    // Upper
    combineLatest([
      this.subFormGroup.controls[this.upperHorizontalFormKey].valueChanges,
      this.subFormGroup.controls[this.upperSagittalFormKey].valueChanges
    ]).subscribe(() => {
      this.upperScore = this.getAverage(true);
      this.mainFormControl.setValue(this.getMainValue());
    });

    // Lower
    combineLatest([
      this.subFormGroup.controls[this.lowerHorizontalFormKey].valueChanges,
      this.subFormGroup.controls[this.lowerSagittalFormKey].valueChanges
    ]).subscribe(() => {
      this.lowerScore = this.getAverage(false);
      this.mainFormControl.setValue(this.getMainValue());
    });

    setTimeout(() =>
      this.getAllPossibleSymptomControlKeys().forEach(subKey => {
        const subValue = this.config.formGroup.value[subKey];
        if (subValue) {
          this.subFormGroup.controls[subKey].setValue(subValue);
        }
      })
    );
  }

  getMainValue() {
    let mainValue;
    if ((this.lowerScore && !this.upperScore) || this.lowerScore >= this.upperScore) {
      mainValue = this.lowerScore;
    } else if ((!this.lowerScore && this.upperScore) || this.upperScore >= this.lowerScore) {
      mainValue = this.upperScore;
    }

    if (mainValue === 0) {
      return 'noLidWiper';
    } else if (mainValue < 1.25) {
      return 'grade0';
    }
    if (mainValue >= 1.25 && mainValue <= 2) {
      return 'grade1';
    }
    if (mainValue >= 2.25 && mainValue <= 3) {
      return 'grade2';
    }
    if (mainValue >= 3.25 && mainValue <= 4) {
      return 'grade3';
    }
  }

  getAllPossibleSymptomControlKeys(): string[] {
    return [
      this.upperHorizontalFormKey,
      this.upperSagittalFormKey,
      this.lowerHorizontalFormKey,
      this.lowerSagittalFormKey
    ];
  }

  getAverage(isUpper = true): number {
    return (
      (parseInt(
        this.subFormGroup.controls[
          isUpper ? this.upperHorizontalFormKey : this.lowerHorizontalFormKey
        ].value,
        10
      ) +
        parseInt(
          this.subFormGroup.controls[
            isUpper ? this.upperSagittalFormKey : this.lowerSagittalFormKey
          ].value,
          10
        )) /
      2
    );
  }

  save() {
    this.subFormGroup.markAllAsTouched();
    if (this.subFormGroup.valid) {
      this.config.formGroup.patchValue(this.subFormGroup.value);
      this.dialogRef.close();
    }
  }
}

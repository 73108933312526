import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { EyeDropField } from 'src/app/treatment-picker/treatment.pipe';
import { Treatment } from '../../../core/remote-json/treatments/treatments.service';

export interface UrlToDataUriMap {
  [imageUrl: string]: SafeUrl;
}

@Component({
  selector: 'csi-pdf-export-treatment',
  templateUrl: './pdf-export-treatment.component.html',
  styleUrls: ['./pdf-export-treatment.component.css']
})
export class PdfExportTreatmentComponent {
  @Input() title: string;
  @Input() treatment: Treatment[];
  @Input() treatmentImageDataUris: UrlToDataUriMap;
  @Input() hideDataForFaxing = false;
  @Input() hideImages = false;

  public fieldsToGetForFaxing: EyeDropField[] = [
    EyeDropField.name,
    EyeDropField.type,
    EyeDropField.manufacturer,
    EyeDropField.class,
    EyeDropField.indication,
    EyeDropField.contactLensCompatible,
    EyeDropField.preservatives
  ];
  constructor() {}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { StaffService } from '../api/staff.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserResolver implements Resolve<Doctor> {
  constructor(
    private staffService: StaffService,
    private loadingSpinnerService: LoadingSpinnerService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Doctor> | Observable<never> {
    this.loadingSpinnerService.show();
    return this.staffService.getLoggedInStaff().pipe(
      take(1),
      mergeMap(staff => {
        if (staff) {
          this.staffService.staff = staff;
          return of(staff);
        } else {
          this.rejectAndRedirect();
          return EMPTY;
        }
      }),
      catchError(error => {
        console.dir(error);
        this.rejectAndRedirect();
        return EMPTY;
      }),
      finalize(() => this.loadingSpinnerService.hide())
    );
  }

  private rejectAndRedirect() {
    alert('You are not authorized to view this or it is not found.');
    this.authenticationService.logout().subscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { PDFExportService } from '../pdf-export.service';
import { PdfSummaryService } from './../pdf-summary-export/pdf-summary.service';

declare const google: any;

@Component({
  selector: 'csi-pdf-export-donut-chart',
  templateUrl: './pdf-export-donut-chart.component.html',
  styleUrls: ['./pdf-export-donut-chart.component.css']
})
export class PdfExportDonutChartComponent implements OnInit {
  public severityClassification = this.pdfSummaryService.severityClassification;

  private severityKeys = Object.keys(this.severityClassification);
  private severityDistribution = {};
  private colorMap = {};
  private titleTextStyle = {
    // color: <string>,    // any HTML string color ('red', '#cc00cc')
    fontName: 'Times New Roman', // i.e. 'Times New Roman'
    fontSize: 20 // 12, 18 whatever you want (don't specify px)
    // bold: <boolean>,    // true or false
    // italic: <boolean>   // true of false
  };

  constructor(
    private pdfExportService: PDFExportService,
    private pdfSummaryService: PdfSummaryService
  ) {}

  ngOnInit() {
    Object.entries(this.severityClassification).map(([side, eyeInfo]) => {
      const severityDistribution = {};
      const severityColor = {};
      Object.values(eyeInfo).forEach((eyeInformation: any) => {
        severityColor[eyeInformation.severity] = eyeInformation.color;
        severityDistribution[eyeInformation.severity] = severityDistribution[
          eyeInformation.severity
        ]
          ? severityDistribution[eyeInformation.severity] + eyeInformation.frequency
          : eyeInformation.frequency;
      });

      this.colorMap[side] = { ...severityColor };
      this.severityDistribution[side] = { ...severityDistribution };
    });
    google.charts.load('current', { packages: ['corechart'] });

    google.charts.setOnLoadCallback(() => {
      this.drawChart();
    });
  }

  drawChart(): void {
    for (const severityKey of this.severityKeys) {
      const data = google.visualization.arrayToDataTable([
        ['', ''],
        ...Object.entries(this.severityDistribution[severityKey])
      ]);
      const title = severityKey[0].toUpperCase() + severityKey.substring(1);
      const colors = this.colorMap[severityKey];

      const options = {
        title: title.match(/[A-Z][a-z]+|[0-9]+/g).join(' '),
        width: 400,
        height: 240,
        pieHole: 0.3,
        colors: Object.values(colors),
        titleTextStyle: this.titleTextStyle,
        // legend: { position: 'labeled', textStyle: { color: 'black', fontSize: 18 } }
        pieSliceTextStyle: {
          color: 'black', // Specify your desired color
          fontSize: 12
        }
      };

      const chart = new google.visualization.PieChart(
        document.getElementById('donutchart-' + title)
      );

      chart.draw(data, options);
    }
    this.pdfExportService.chartReady$.next(true);
  }
}

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreateClinicInput, UpdateClinicInput } from '../../../../API';
import { Clinic } from '../../../core/api/clinic.service';
import { AbstractControlsMap } from '../../dynamic-form-group/dynamic-form-group';
import { phoneNumberValidator } from '../../shared-validators/phone-number-validator';
import { EMRIntegrationFormGroup } from '../emr-integration-form/emr-integration-form.model';

interface ClinicFormControls extends AbstractControlsMap {
  id: FormControl;
  name: FormControl;
  address: FormControl;
  province: FormControl;
  city: FormControl;
  country: FormControl;
  postalCode: FormControl;
  phone: FormControl;
  fax: FormControl;
  emrIntegration: EMRIntegrationFormGroup;
  agreedToTermsOfService: FormControl;
  logo: FormControl;
}

export class ClinicFormGroup extends FormGroup {
  controls: ClinicFormControls;

  private _submitted = false;
  public submitted$ = new Subject<boolean>();
  set submitted(submitted: boolean) {
    this._submitted = submitted;
    this.submitted$.next(submitted);
  }
  get submitted(): boolean {
    return this._submitted;
  }

  constructor() {
    super({
      id: new FormControl({ value: null, disabled: true }),
      name: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      province: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, phoneNumberValidator]),
      fax: new FormControl(null, phoneNumberValidator),
      emrIntegration: new EMRIntegrationFormGroup(),
      agreedToTermsOfService: new FormControl(),
      logo: new FormControl()
    });
  }

  // TODO: These are probably not needed anymore since Doctor and Clinic models
  // follow exactly this form's model, can you use generic patchValue method
  // and then go through all controls and disable the ones with values
  public patchFromClinic(clinic: Clinic) {
    if (clinic) {
      this.setValueAndDisable(this.controls.id, clinic.id);
      this.setValueAndDisable(this.controls.phone, clinic.phone);
      this.setValueAndDisable(this.controls.fax, clinic.fax);
      this.setValueAndDisable(this.controls.name, clinic.name);
      this.setValueAndDisable(this.controls.address, clinic.address);
      this.setValueAndDisable(this.controls.province, clinic.province);
      this.setValueAndDisable(this.controls.postalCode, clinic.postalCode);
      this.setValueAndDisable(this.controls.city, clinic.city);
      this.setValueAndDisable(this.controls.country, clinic.country);
      this.controls.emrIntegration.patchValue(clinic.emrIntegration, { defaultFax: clinic.fax });
      this.setValueAndDisable(this.controls.agreedToTermsOfService, clinic.agreedToTermsOfService);
      this.setValueAndDisable(this.controls.logo, clinic.logo);
    }
  }

  // TODO: Should probably have separate view components for read-only / view only data
  // but use disable functionality for now
  private setValueAndDisable(control: FormControl, value: any) {
    control.setValue(value);
    if (value !== undefined && value !== null) {
      control.disable();
    }
  }

  toCreateClinicInput(): CreateClinicInput {
    const { clinicSetupFormGroup, ...createClinicInput } = this.value;

    // if fax numbers are empty or null, remove them since
    // aws is expecting AWSPhone
    const clinicFaxNum = createClinicInput.fax;
    if (clinicFaxNum === null || clinicFaxNum === '') {
      delete createClinicInput.fax;
    }
    const emrFax = createClinicInput.emrIntegration.fax;
    if (emrFax) {
      const emrFaxNum = emrFax.faxNumber;
      if (emrFaxNum === null || emrFaxNum === '') {
        delete createClinicInput.emrIntegration.fax.faxNumber;
      }
    }

    return createClinicInput;
  }

  toUpdateClinicInput(clinicId: string): UpdateClinicInput {
    const { clinicSetupFormGroup, ...clinicInput } = this.value;

    const updateClinicInput: UpdateClinicInput = {
      ...clinicInput,
      id: clinicId
    };

    // if fax numbers are empty or null, remove them since
    // aws is expecting AWSPhone
    const clinicFaxNum = updateClinicInput.fax;
    if (clinicFaxNum === null || clinicFaxNum === '') {
      delete updateClinicInput.fax;
    }
    const emrFax = updateClinicInput.emrIntegration.fax;
    if (emrFax) {
      const emrFaxNum = emrFax.faxNumber;
      if (emrFaxNum === null || emrFaxNum === '') {
        delete updateClinicInput.emrIntegration.fax.faxNumber;
      }
    }

    if (updateClinicInput.logo && (updateClinicInput.logo as any).__typename) {
      delete updateClinicInput.logo;
    }

    return updateClinicInput;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'csi-form-template-checkbox-field-config',
  templateUrl: './form-template-checkbox-field-config.component.html',
  styleUrls: ['./form-template-checkbox-field-config.component.css']
})
export class FormTemplateCheckboxFieldConfigComponent implements OnInit {
  @Input() templateFieldFormGroup: FormGroup;

  constructor() {}

  ngOnInit() {
    (this.templateFieldFormGroup.controls['typeConfig'] as FormGroup).addControl(
      'valuesConfig',
      new FormGroup({})
    );
  }
}

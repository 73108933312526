import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy, QueryOptions } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { AmplifyService } from 'aws-amplify-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, forkJoin, from, iif, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  CognitoGroup,
  CreateDoctorInput,
  CreateDoctorMutation,
  Doctor,
  GetDoctorQuery,
  ModelPatientFilterInput,
  UpdateDoctorInput
} from '../../../API';
import { getAllInfo, maxAWSItemLimit } from '../../../graphql/custom_queries';
import { createDoctor, updateDoctor } from '../../../graphql/mutations';
import { getDoctor } from '../../../graphql/queries';
import { ClinicIdLink } from '../apollo-links/clinic-id-link';
import { AppSyncService } from '../appsync.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { CognitoUserInfo } from '../authentication/models/cognito-user-info.model';
import { Patient, PatientList } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  public isDoctor: boolean;
  public isTech: boolean;
  public isReceptionist: boolean;
  public isClinicOwner: boolean;
  public staff: Doctor;
  public isReferralOnly: boolean;

  public readonly patientFieldsForDisplayFragment = `
    fragment PatientFieldsForDisplay on Patient {
      id
      firstName
      lastName
      dateOfBirth
      email
      gender
      address
      phone
      healthCard {
        province
        number
      }
      phone
      assessments(limit: $assessmentsLimit) @skip(if: $skipAssessments) {
        items {
          createdAt
        }
      }
      createdAt
      updatedAt
    }
  `;

  public readonly patientsConnectionVariables = `
    $limit: Int,
    $skipAssessments: Boolean = false,
    $assessmentsLimit: Int,
    $nextToken: String,
    $patientsSortDirection: ModelSortDirection = DESC,
    $patientsFilter: ModelPatientFilterInput = null`;

  public readonly patientsConnection = `
    patients(filter: $patientsFilter, sortDirection: $patientsSortDirection, limit: $limit, nextToken: $nextToken) {
      items {
        ...PatientFieldsForDisplay
      },
      nextToken
    }
  `;

  private hasCreatedLoggedInDoctor: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly getPatientsForDoctorQuery = gql`
    query GetPatientsForDoctor(
      $id: ID!,
      ${this.patientsConnectionVariables}
    ) {
      getDoctor(id: $id) {
        id
        ${this.patientsConnection}
      }
    }
    ${this.patientFieldsForDisplayFragment}
  `;

  constructor(
    private appSyncService: AppSyncService,
    private authenticationService: AuthenticationService,
    private amplifyService: AmplifyService,
    private clinicIdLink: ClinicIdLink
  ) {
    this.clinicIdLink.staffService = this;
    this.createLoggedInDoctorIfNotAlready().subscribe();
  }

  private createLoggedInDoctorIfNotAlready(): Observable<boolean> {
    if (!this.hasCreatedLoggedInDoctor.value) {
      return this.createLoggedInDoctor().pipe(
        switchMap(doctor => of(true)),
        catchError(() => of(false)),
        tap(result => this.hasCreatedLoggedInDoctor.next(result))
      );
    } else {
      return of(this.hasCreatedLoggedInDoctor.value);
    }
  }

  // TODO: Do this in me resolver in backend at some point (create doctor if necessary)
  private createLoggedInDoctor(): Observable<Doctor> {
    let loggedInUserInfo: CognitoUserInfo;
    return from(this.authenticationService.getCognitoUserInfo()).pipe(
      switchMap(userInfo => {
        loggedInUserInfo = userInfo;
        return this.appSyncService.hydrated();
      }),
      switchMap(client => {
        const input: CreateDoctorInput = {
          id: loggedInUserInfo.attributes.sub,
          practitionerId: loggedInUserInfo.attributes['custom:practitioner_id'],
          email: loggedInUserInfo.attributes.email,
          emailVerified: loggedInUserInfo.attributes.email_verified
        };

        return client.mutate({
          mutation: gql(createDoctor),
          variables: { input: input }
        });
      }),
      map((result: FetchResult<CreateDoctorMutation>) => result.data.createDoctor),
      catchError(error => {
        if (!error.networkError) {
          // logged in doctor already exists in db so return that
          const doctor: Doctor = error.graphQLErrors[0].data as Doctor;
          return of(doctor);
        } else {
          throw error;
        }
      })
    );
  }

  public getDoctor(id: string): Observable<Doctor> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(getDoctor),
          variables: { id: id }
        })
      ),
      map((result: ApolloQueryResult<GetDoctorQuery>) => result.data.getDoctor)
    );
  }

  public watchLoggedInDoctor(): Observable<Doctor> {
    const replacer = (key, value) => (key === 'nextToken' ? null : value);

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.watchQuery({
          query: gql(getAllInfo)
        });
      }),
      map(result => result.data['me']),
      distinctUntilChanged(
        (old, current) => JSON.stringify(old, replacer) === JSON.stringify(current, replacer)
      )
    );
  }

  public getLoggedInStaff(): Observable<Doctor> {
    return this.createLoggedInDoctorIfNotAlready().pipe(
      switchMap(() => this.appSyncService.hydrated()),
      switchMap(client => client.query({ query: gql(getAllInfo) })),
      map(result => {
        const doctor = result.data['me'];
        this.setStaffType(doctor);
        return doctor;
      })
    );
  }

  public updateDoctor(input: UpdateDoctorInput): Observable<Doctor> {
    /*
     * TODO: In the future we should have the server update the pracID on the cognito user
     *  or make the pracID a calculated attribute in Dynamo, retrieved from the cognito user
     */
    return forkJoin([
      iif(
        () => input.practitionerId !== null && input.practitionerId !== undefined,
        from(this.amplifyService.auth().currentAuthenticatedUser()).pipe(
          switchMap(cognitoUser =>
            from(
              this.amplifyService.auth().updateUserAttributes(cognitoUser, {
                'custom:practitioner_id': input.practitionerId
              })
            )
          )
        ),
        of(null)
      ),
      this.appSyncService.hydrated().pipe(
        switchMap(client =>
          client.mutate({
            mutation: gql(updateDoctor),
            variables: { input: input }
          })
        ),
        map(result => result.data.updateDoctor as Doctor)
      )
    ]).pipe(
      mergeMap(results => {
        return from(this.authenticationService.refreshSession().then(() => results[1]));
      })
    );
  }

  public getPatientsForDoctor(
    doctorId: string,
    watch: boolean = false,
    assessmentsLimit: number = 0,
    fetchPolicy: FetchPolicy = 'cache-first',
    nextToken?: string,
    limit: number = Number.POSITIVE_INFINITY,
    patientsFilter?: ModelPatientFilterInput
  ): Observable<PatientList> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const queryOptions: QueryOptions = {
          query: this.getPatientsForDoctorQuery,
          variables: {
            id: doctorId,
            limit: limit > maxAWSItemLimit ? maxAWSItemLimit : limit,
            skipAssessments: assessmentsLimit === 0,
            assessmentsLimit: assessmentsLimit,
            nextToken: nextToken,
            patientsFilter: patientsFilter
          },
          fetchPolicy: fetchPolicy,
          fetchResults: true
        };
        return watch ? client.watchQuery(queryOptions) : client.query(queryOptions);
      }),
      filter(
        (result: ApolloQueryResult<GetDoctorQuery>) => !!result.data && !!result.data.getDoctor
      ),
      map((result: ApolloQueryResult<GetDoctorQuery>) => result.data.getDoctor),
      switchMap((doctor: Doctor) => {
        const nextNextToken: string = doctor.patients.nextToken;
        if (!!nextNextToken && limit === Number.POSITIVE_INFINITY) {
          return this.getPatientsForDoctor(
            doctorId,
            watch,
            assessmentsLimit,
            fetchPolicy,
            nextNextToken,
            limit
          ).pipe(
            map((patients: PatientList) => {
              doctor.patients.items = [...doctor.patients.items, ...patients.items] as any;
              doctor.patients.nextToken = null;
              return doctor;
            })
          );
        } else {
          return of(doctor);
        }
      }),
      map((doctor: Doctor) => {
        return {
          items: doctor.patients.items as Partial<Patient>[],
          nextToken: doctor.patients.nextToken
        } as PatientList;
      })
    );
  }

  public isDoctorApprovedForDryEyeSpecializedForm({ cognitoGroups }: Doctor): boolean {
    return (
      cognitoGroups &&
      (cognitoGroups.includes(CognitoGroup.Seema) ||
        cognitoGroups.includes(CognitoGroup.DryEyeExpertPanel))
    );
  }

  public isDoctorApprovedForQuestionnaireSending(doctor: Doctor): boolean {
    return this.isDoctorApprovedForDryEyeSpecializedForm(doctor);
  }

  public isDoctorMissingInformation(doctor: Doctor): boolean {
    if (!doctor) {
      return true;
    }

    const requiredFields = [doctor.firstName, doctor.lastName, doctor.agreedToTerms];
    return (
      requiredFields.includes(null) ||
      requiredFields.includes(false) ||
      requiredFields.includes(undefined)
    );
  }

  public isProfileAccessComplete(staff: Doctor) {
    if (!staff || !staff.clinic) {
      return false;
    }

    return (
      staff.clinic.referralCenter ||
      staff.clinic.linkedReferralCenter ||
      staff.cognitoGroups.includes(CognitoGroup.DryEyeExpertPanel)
    );
  }

  public isStaffPartOfGroup({ cognitoGroups }: Doctor, groupToCompare: string) {
    return cognitoGroups && cognitoGroups.includes(groupToCompare);
  }

  public setStaffType(doctor: Doctor) {
    if (doctor.clinic) {
      if (doctor.clinic.owner.id === doctor.id) {
        this.isClinicOwner = true;
        return;
      }
      if (doctor.cognitoGroups.includes(CognitoGroup.Doctor)) {
        this.isDoctor = true;
        return;
      }
      if (doctor.cognitoGroups.includes(CognitoGroup.Tech)) {
        this.isTech = true;
        return;
      }
      this.isReceptionist = true;
    }
  }
}

import { Injectable } from '@angular/core';
import { Attachment } from '../attachments/attachments.component';
import { S3AttachmentService, S3Object } from '../attachments/s3-attachment.service';
import { ContentType } from '../shared-pipes/image-to-data-uri.pipe';

export type FileType = 'video' | 'image' | 'pdf' | 'other';

@Injectable({
  providedIn: 'root'
})
export class DataSrcService {
  constructor(private s3AttachmentService: S3AttachmentService) {}

  public getFileTypeFromMime(mime: ContentType | string): FileType {
    let fileType: FileType = null;
    const html5SupportedVideoTypes = ['video/ogg', 'video/webm', 'video/mp4', 'video/quicktime'];

    if (mime.startsWith('application/pdf')) {
      fileType = 'pdf';
    } else if (mime.startsWith('image/')) {
      fileType = 'image';
    } else if (html5SupportedVideoTypes.includes(mime)) {
      fileType = 'video';
    } else {
      fileType = 'other';
    }
    return fileType;
  }
  public getFileTypeFromExtension(filePath: string): FileType {
    const fileExtension = filePath.substr(filePath.lastIndexOf('.'), filePath.length);
    return fileExtension === '.mp4' ? 'video' : 'image';
  }

  public canEnlarge(mime: ContentType): boolean {
    return this.getFileTypeFromMime(mime) !== 'other';
  }

  public getFileName(attachment: Attachment): string {
    return attachment instanceof File
      ? (attachment as File).name
      : this.s3AttachmentService.getFileName(attachment as S3Object);
  }

  public copyFile(file: File, copyAsMp4: boolean = false): Promise<File> {
    return new Promise<File>(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const blob = new Blob([new Uint8Array((e.target as FileReader).result as ArrayBuffer)], {
          type: copyAsMp4 ? 'video/mp4' : file.type
        });
        resolve(new File([blob], file.name, { type: blob.type }));
      };
      reader.readAsArrayBuffer(file);
    });
  }
}

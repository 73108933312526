import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { DryEyeFormsService } from '../dry-eye-forms.service';
import { QuestionnaireRequest, QuestionnaireType } from './../../../../../API';
import { AssessmentUtils } from './../../../../core/api/assessment-utils';
import { Assessment, AssessmentService } from './../../../../core/api/assessment.service';
import { SchemaService } from './../../../symptoms/services/schema/schema.service';
import { SymptomModes } from './../../../symptoms/symptom.model';
import { QuestionnaireResponseModalComponent } from './../../questionnaire-response-modal/questionnaire-response-modal.component';

@Component({
  selector: 'csi-dry-eye-symptom-form',
  templateUrl: './dry-eye-symptom-form.component.html',
  styleUrls: ['./dry-eye-symptom-form.component.scss']
})
export class DryEyeSymptomFormComponent implements OnInit {
  @Input() questionnaireTypeToDisplay: string[] = Object.values(QuestionnaireType).filter(
    item => item !== QuestionnaireType.OSDIAndSPEED
  );

  @Input() symptomsAndSignsFormGroup: FormGroup;
  @Input() assessment: Assessment;
  @Input() onQuestionnaireLinked$: Subject<QuestionnaireRequest[]>;

  @Output() sendQuestionnaire = new EventEmitter<[QuestionnaireType, QuestionnaireType[]]>();
  public readonly QuestionnaireType = QuestionnaireType;
  public readonly SymptomModes = SymptomModes;

  public showQuestionnaireRequestButton: boolean;
  public showSendQuestionnaireButton: boolean;

  public lastCompletedLinkedQuestionnaireByTypeMap: { [key: string]: QuestionnaireRequest } = {};

  constructor(
    public schemaService: SchemaService,
    private dialog: MatDialog,
    private assessmentService: AssessmentService,
    private dryEyeFormsService: DryEyeFormsService,
    private questionnaireRequestService: QuestionnaireRequestService
  ) {}

  ngOnInit() {
    this.showSendQuestionnaireButton =
      !AssessmentUtils.hasAssessmentRequests(this.assessment) &&
      (!this.symptomsAndSignsFormGroup.disabled || this.assessmentService.isViewOnly);

    this.showQuestionnaireRequestButton =
      this.assessment &&
      this.assessment.questionnaireRequests &&
      this.assessment.questionnaireRequests.items.filter(item => item.completedAt).length > 0;

    this.onQuestionnaireLinked$.subscribe((linkedQuestionnaires: QuestionnaireRequest[]) => {
      this.updateLinkedQuestionnaires(linkedQuestionnaires);
    });
    this.updateLinkedQuestionnaires();
  }

  updateLinkedQuestionnaires(newlyLinkedQuestionnaires?: QuestionnaireRequest[]) {
    if (newlyLinkedQuestionnaires) {
      this.lastCompletedLinkedQuestionnaireByTypeMap = this.questionnaireRequestService.getLastCompletedQuestionnairesByScore(
        newlyLinkedQuestionnaires,
        this.lastCompletedLinkedQuestionnaireByTypeMap
      );
    } else {
      let linkedQuestionnaires: any[] = this.assessment
        ? this.assessment.questionnaireRequests.items
        : [];
      this.lastCompletedLinkedQuestionnaireByTypeMap = this.questionnaireRequestService.getLastCompletedQuestionnairesByScore(
        linkedQuestionnaires
      );
    }
    setTimeout(() => {
      this.dryEyeFormsService.updateSymptomScores(
        this.symptomsAndSignsFormGroup,
        this.lastCompletedLinkedQuestionnaireByTypeMap
      );
    });
  }

  showQuestionnaireResponse(questionnaireType: string) {
    QuestionnaireResponseModalComponent.open(
      this.dialog,
      this.lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType.toUpperCase()],
      null,
      questionnaireType
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export type RejectionType = 'completed' | 'expired' | 'not-found';

@Component({
  selector: 'csi-reject-questionnaire-modal',
  templateUrl: './reject-questionnaire-modal.component.html',
  styleUrls: ['./reject-questionnaire-modal.component.css']
})
export class RejectQuestionnaireModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RejectionType) {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckboxConfig, ConfigType, FormFieldTypeConfig } from './../form-template-model';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateBuilderService {
  public formTemplateFormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    message: new FormControl(),
    abbreviation: new FormControl(),
    templateGroups: new FormGroup({}),
    supportingFields: new FormGroup({
      1: new FormGroup({
        label: new FormControl('1'),
        typeConfig: new FormControl({
          type: ConfigType.checkbox,
          valuesConfig: { test1: { label: 'test1' }, test2: { label: 'test2' } }
        } as CheckboxConfig)
      }),
      2: new FormGroup({
        label: new FormControl('2'),
        typeConfig: new FormControl({ type: ConfigType.input } as FormFieldTypeConfig)
      })
    })
  });
  constructor() {}
}

import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { saveAs } from 'file-saver';
import gql from 'graphql-tag';
import { Base64 } from 'js-base64';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HtmlToPdfQuery } from '../../../API';
import { htmlToPdf } from '../../../graphql/queries';
import { AppSyncService } from '../appsync.service';

export type PDF = HtmlToPdfQuery['htmlToPDF'];

@Injectable({
  providedIn: 'root'
})
export class Html2PdfService {
  private logger: Logger = new Logger('Html2PdfService');

  constructor(private appSyncService: AppSyncService) {}

  public saveHtmlAsPdf(html: string, filename: string): Observable<boolean> {
    return this.html2Pdf(html).pipe(switchMap((pdf: PDF) => this.savePdfAs(pdf, filename)));
  }

  public html2Pdf(html: string): Observable<PDF> {
    this.logger.debug('html2Pdf() called');
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        this.logger.debug('initiating graphql html2pdf query');
        return client.query({
          query: gql(htmlToPdf),
          // can't use btoa since it does not support utf-8 characters
          variables: { htmlInBase64: Base64.encode(html) },
          fetchPolicy: 'no-cache'
        });
      }),
      map(result => {
        this.logger.debug('got html result');
        return result.data['htmlToPDF'];
      })
    );
  }

  public savePdfAs(pdf: PDF, filename: string): Observable<boolean> {
    const dataUrl = `data:application/pdf;base64,${pdf.base64}`;
    return from(fetch(dataUrl)).pipe(
      switchMap((response: Response) => response.blob()),
      switchMap((blob: Blob) => {
        saveAs(blob, filename);
        return of(true);
      })
    );
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { ApolloError } from 'apollo-client';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {
  public static trySendingSentryError(error) {
    if (environment.enableSentry) {
      Sentry.captureException(error);
    }
  }

  constructor(private loadingSpinnerService: LoadingSpinnerService) {
    super();

    if (environment.enableSentry) {
      this.initSentry();
    }
  }

  handleError(error: Error | string) {
    if (typeof error === 'string') {
      error = new Error(error);
    }
    ErrorHandlerService.trySendingSentryError(error);
    super.handleError(error);
  }

  public configureSentryScope(email = 'No provided email') {
    Sentry.configureScope(scope => {
      scope.setUser({ email: email, App_Version: environment.version });

      scope.addEventProcessor(async event => {
        this.notifyMouseflow();
        return event;
      });
    });
  }

  public handleGraphQLError(error: ApolloError, hideSpinner: boolean = false) {
    if (hideSpinner) {
      this.loadingSpinnerService.hide();
    }

    if (!this.handlePhoneNumberParseError(error.message)) {
      ErrorHandlerService.trySendingSentryError(error);

      alert(
        'A server error occurred and your request has not been submitted. Please try again or report this to support@csidryeye.com.' +
          `\n\nError message: ${error.message}`
      );
    }
  }

  private handlePhoneNumberParseError(graphqlErrorMessage: string): boolean {
    // Example message: "GraphQL error: Variable 'mobile' has an invalid value. Unable to parse `4444444444` as a valid phone number."

    // Remove the ts-ignore when https://github.com/Microsoft/TypeScript/issues/26235 is resolved
    // @ts-ignore: 2525
    const [, phoneNumber] =
      graphqlErrorMessage.match(/.*Unable to parse (`.*`) as a valid phone number.$/) || [];
    if (!!phoneNumber) {
      alert(`${phoneNumber} is not a valid phone number. Please check the number and try again.`);
      return true;
    }
    return false;
  }

  private initSentry() {
    Sentry.init({
      /* beforeBreadcrumb(breadcrumb, hint) {
        // These lines can find strings that end in " but not end in \", which can be used to
        // blanket delete sensitive data from a stringified object.
        // let str1 = JSON.stringify(breadcrumb);
        // const endsInQuote = RegExp(/Setting value of \w* to (((?!\\").)?(\\")?)*?"/, 'g');
        return breadcrumb;
      }, */
      dsn: 'https://f2a9e30f8a094322a4335cb513199901@sentry.io/1409483',
      maxValueLength: 1000,
      integrations: [new Integrations.ExtraErrorData({ depth: 6 })]
    });
    this.configureSentryScope();
  }

  private notifyMouseflow() {
    window._mfq = window._mfq || [];
    window._mfq.push(['tag', 'SENTRY-ERROR']);
    window._mfq.push(['addFriction', '5']);
  }
}

import { ElementRef, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiKeySymptomControl } from '../abstract-symptom-control/abstract-symptom-control.component';
import { ModalSymptomModalOptions } from '../symptom.model';

export interface SymptomModalConfig<T = ModalSymptomModalOptions> {
  formKey: string;
  modalConfig: T;
  formGroup: FormGroup;
}

export abstract class AbstractSymptomModalComponent<T = ModalSymptomModalOptions>
  implements MultiKeySymptomControl {
  public formKey: string;
  public modalConfig: T;
  protected formGroup: FormGroup;

  protected constructor(
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<T>,
    protected hostElement: ElementRef
  ) {
    Object.assign(this, this.config);
  }

  get isDisabled(): boolean {
    return !this.formGroup.get(this.formKey) || this.formGroup.get(this.formKey).disabled;
  }

  public getAllPossibleSymptomControlKeys(): string[] {
    return [this.formKey];
  }

  protected getExternalKeyFromInternalKey(internalKey: string): string {
    return this.formKey + '-' + internalKey;
  }
}

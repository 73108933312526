import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Assessment } from 'src/API';
import {
  signGroups,
  signToFriendlyNameMap
} from '../signs-attachments-modal/signs-attachments-schema';
import { SignsAttachmentsService } from '../signs-attachments-modal/signs-attachments.service';

@Component({
  selector: 'csi-sign-media-upload',
  templateUrl: './sign-media-upload.component.html',
  styleUrls: ['./sign-media-upload.component.css']
})
export class SignMediaUploadComponent implements OnInit {
  @Input() signKey: string;
  @Input() reload$: Subject<void>;
  @Input() saveFn: () => Observable<Assessment>;

  public signToFriendlyNameMap = signToFriendlyNameMap;
  public signGroups = signGroups;

  public rightEyeMetadata: { [key: string]: string };
  public leftEyeMetadata: { [key: string]: string };

  public rightEyeFormControl: FormControl = new FormControl();
  public leftEyeFormControl: FormControl = new FormControl();

  constructor(public signsAttachmentsService: SignsAttachmentsService) {}

  ngOnInit() {
    this.rightEyeMetadata = this.signsAttachmentsService.generateMetadata(this.signKey, 'right');
    this.leftEyeMetadata = this.signsAttachmentsService.generateMetadata(this.signKey, 'left');
    this.signsAttachmentsService.loadSignAttachmentsToControl(
      this.rightEyeFormControl,
      this.rightEyeMetadata,
      this.leftEyeFormControl,
      this.leftEyeMetadata
    );
    combineLatest([
      this.rightEyeFormControl.valueChanges.pipe(startWith(this.rightEyeFormControl.value)),
      this.leftEyeFormControl.valueChanges.pipe(startWith(this.leftEyeFormControl.value))
    ]).subscribe(([rightEyeControlValue, leftEyeControlValue]) => {
      const signGroup = this.signsAttachmentsService.getSignFromMetadata(this.rightEyeMetadata);
      if (rightEyeControlValue.length > 0 && leftEyeControlValue.length > 0) {
        this.signsAttachmentsService.signGroupComplete[signGroup] = true;
      } else {
        this.signsAttachmentsService.signGroupComplete[signGroup] = false;
      }
    });

    this.rightEyeFormControl.valueChanges.subscribe(value => {
      this.signsAttachmentsService.updateMetadataToAttachmentsMap(this.rightEyeMetadata, value);
    });
    this.leftEyeFormControl.valueChanges.subscribe(value => {
      this.signsAttachmentsService.updateMetadataToAttachmentsMap(this.leftEyeMetadata, value);
    });

    this.reload$.subscribe(() => {
      this.signsAttachmentsService.loadSignAttachmentsToControl(
        this.rightEyeFormControl,
        this.rightEyeMetadata,
        this.leftEyeFormControl,
        this.leftEyeMetadata
      );
    });
  }
}

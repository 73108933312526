import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'formGroupHasValue'
})
export class FormGroupHasValuePipe implements PipeTransform {
  transform(formGroup: FormGroup, changed: any): any {
    if (!formGroup.controls) {
      return false;
    }
    return this.hasValue(formGroup);
  }

  hasValue(formGroup: FormGroup) {
    return Object.values(formGroup.controls).some(control => {
      if (control instanceof FormGroup) {
        return this.hasValue(control);
      }
      return control.value;
    });
  }
}

import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { DropdownSymptom } from '../symptom.model';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { SymptomValidatorsService } from '../../consult-forms/validators/symptom-validators/symptom-validators.service';

@Component({
  selector: 'csi-dropdown-symptom-control',
  templateUrl: './dropdown-symptom-control.component.html',
  styleUrls: [
    '../abstract-symptom-control/abstract-symptom-control.component.css',
    './dropdown-symptom-control.component.css'
  ],
  providers: [
    {
      provide: AbstractSymptomControlComponent,
      useExisting: forwardRef(() => DropdownSymptomControlComponent)
    }
  ]
})
export class DropdownSymptomControlComponent extends AbstractSymptomControlComponent
  implements OnChanges {
  @Input() symptom: DropdownSymptom;
  @Input() formKey: string;
  @Input() formGroup: DryEyeFormGroup;

  valueKeys = [];

  constructor(
    protected symptomValidatorsService: SymptomValidatorsService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.symptom && this.symptom) {
      this.valueKeys = Object.keys(this.symptom.values);
    }
  }

  get shouldShowThermalScale(): boolean {
    return this.symptom.thermalScale && this.value;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'csi-mat-table-column-order',
  templateUrl: './mat-table-column-order.component.html',
  styleUrls: ['./mat-table-column-order.component.css']
})
export class MatTableColumnOrderComponent implements OnInit {
  @Input() columnsToDisplay: string[];
  @Input() originalColumns: string[];

  constructor() {}

  ngOnInit() {}

  hideColumn(event: MatCheckboxChange, column, index) {
    if (!event.checked) {
      this.columnsToDisplay.splice(index, 1);
    } else {
      let indexInOriginal = this.originalColumns.indexOf(column);
      if (indexInOriginal >= this.columnsToDisplay.length) {
        indexInOriginal = this.columnsToDisplay.length -1
      }
      this.columnsToDisplay.splice(indexInOriginal, 0, column);
    }
  }
}

import { Injectable } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  private logoutSubscription: Subscription;

  constructor(private router: Router, private authService: AuthenticationService) {}

  public logout() {
    const routeGuard$ = this.logoutOnceConfirmed();
    this.logoutSubscription = routeGuard$.subscribe(successfulNavigation => {
      if (successfulNavigation) {
        document.location.reload();
      }
      this.unsubscribeFromLogout();
    });
    this.router.navigateByUrl('').then();
  }

  private logoutOnceConfirmed(): Observable<boolean> {
    return this.router.events.pipe(
      filter(e => e instanceof GuardsCheckEnd),
      switchMap((guardsCheckEnd: GuardsCheckEnd) => {
        if (guardsCheckEnd.shouldActivate) {
          return this.authService.logout().pipe(map(() => true));
        } else {
          return of(false);
        }
      })
    );
  }

  private unsubscribeFromLogout() {
    this.logoutSubscription.unsubscribe();
  }
}

import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataCacheType } from 'src/API';
import { FilterField, FilterFieldConfig, FilterFieldType, Operator } from './../filter.model';
import { FilterService } from './../filter.service';

@Component({
  selector: 'csi-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.scss']
})
export class FilterFieldComponent {
  @Input() dataGroupType: DataCacheType;
  @Input() filterField: FilterField;

  public Operator = Operator;

  filterFieldFormGroup = new FormGroup({
    field: new FormControl(),
    operator: new FormControl({ value: null, disabled: true }),
    searchValue: new FormControl({ value: null, disabled: true })
  });

  readonly FilterFieldType = FilterFieldType;

  selectedFilterFieldKey: string;
  selectedFilterFieldConfig: FilterFieldConfig;

  constructor(public filterService: FilterService) {
    this.filterFieldFormGroup.valueChanges.subscribe(value => {
      Object.entries(value).forEach(([k, v]) => {
        this.filterField[k] = v;
      });
    });

    this.filterFieldFormGroup.controls.field.valueChanges.subscribe(v => {
      this.selectedFilterFieldKey = v;
      this.selectedFilterFieldConfig = this.filterService.dataGroupFilterFieldMap[
        this.dataGroupType
      ][v];
      this.filterFieldFormGroup.controls.searchValue.setValue(null);
      this.filterFieldFormGroup.controls.operator.setValue(null);

      if (v) {
        this.filterFieldFormGroup.controls.searchValue.enable();
        this.filterFieldFormGroup.controls.operator.enable();
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThermalScaleDirective } from './thermal-scale.directive';
import { ThermalScaleComponent } from './thermal-scale.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ThermalScaleComponent, ThermalScaleDirective],
  exports: [ThermalScaleComponent, ThermalScaleDirective]
})
export class ThermalScaleModule {}

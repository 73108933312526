import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StaffService } from 'src/app/core/api/staff.service';
import { StaffType } from './../../../../../API';

@Component({
  selector: 'csi-staff-form',
  templateUrl: './staff-form.component.html',
  styleUrls: ['./staff-form.component.css']
})
export class StaffFormComponent implements OnInit {
  @Input() staffFormGroup: FormGroup;

  public StaffType = StaffType;

  public referralOnly: boolean;

  constructor(private staffService: StaffService) {}

  ngOnInit() {
    this.referralOnly = !this.staffService.staff.cognitoGroups.includes('DryEyeExpertPanel');

    if (this.referralOnly) {
      this.staffFormGroup.controls.staffType.setValue(StaffType.Receptionist);
    }
  }

  onPaste() {
    return false;
  }
}

import { EConsultFormGroup } from '../../../../../econsult/econsult-form.model';
import { Observable } from 'rxjs';
import { Treatment } from '../../../../../core/remote-json/treatments/treatments.service';

export interface TreatmentFilter {
  name: string;
  hidden?: boolean;
  selectedByDefault: boolean;
  isPopularFor?(treatment: Treatment): string;
  filter(treatment: Treatment): boolean;
  listensTo?(formGroup: EConsultFormGroup): Observable<FilterShouldActivate>;
}

export class FilterShouldActivate {
  filterToChange: TreatmentFilter;
  changeToActive: boolean;
  equals(compareObject: FilterShouldActivate): boolean {
    return (
      compareObject.filterToChange.name === this.filterToChange.name &&
      compareObject.changeToActive === this.changeToActive
    );
  }

  constructor(filterToChange: TreatmentFilter, changeToActive: boolean) {
    this.filterToChange = filterToChange;
    this.changeToActive = changeToActive;
  }
}

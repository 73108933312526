import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { TextFavoriteDefault } from '../../text-favorite-model';
import { TextFavoriteService } from '../../text-favorite.service';
import { TextFavoriteType } from './../../../../../../API';
@Component({
  selector: 'csi-text-favorite-list',
  templateUrl: './text-favorite-list.component.html',
  styleUrls: ['./text-favorite-list.component.css']
})
export class TextFavoriteListComponent implements OnInit, OnDestroy {
  private _textFavorites: any;
  @Input() set textFavorites(textFavorites: any) {
    this._textFavorites = textFavorites;
    this.filteredTextFavorites = textFavorites;
  }
  get textFavorites() {
    return this._textFavorites;
  }
  @Input() childKeyValue!: any;
  @Input() parentKeyValue!: any;
  @Input() displayedColumns: string[];
  @Input() buttonLabelBooleanMap: { [key: string]: boolean };
  @Input() filterControl: FormControl;
  @Input() isSaving: boolean;
  @Output() textFavoriteAction = new EventEmitter();

  public type = '';

  public filteredTextFavorites = [];

  constructor(public textFavoriteService: TextFavoriteService) {}

  ngOnInit() {
    this.type = this.childKeyValue.key;
    if (
      TextFavoriteType.Recommendations === this.parentKeyValue.key &&
      !this.displayedColumns.includes('friendlyId')
    ) {
      if (this.displayedColumns.includes('action')) {
        this.displayedColumns.splice(2, 0, 'friendlyId');

        return;
      }
      this.displayedColumns.push('friendlyId');
    }
    if (TextFavoriteType.Recommendations !== this.parentKeyValue.key) {
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'friendlyId');
    }

    this.filterControl.valueChanges
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe((filter: string) => {
        this.activeFilter(filter);
        this.filteredTextFavorites = [...this.filteredTextFavorites];
      });
  }

  ngOnDestroy() {}

  activeFilter(filter: string) {
    if (!filter.trim()) {
      this.filteredTextFavorites = this.textFavorites;
    }

    const lowerCaseFilter = filter.toLocaleLowerCase();
    this.filteredTextFavorites = this.textFavorites.filter((textFavorite: TextFavoriteDefault) => {
      return (
        textFavorite.text.toLocaleLowerCase().includes(lowerCaseFilter) ||
        textFavorite.shortcut.toLocaleLowerCase().includes(lowerCaseFilter) ||
        (textFavorite.treatmentTemplate &&
          textFavorite.treatmentTemplate.friendlyId &&
          textFavorite.treatmentTemplate.friendlyId.toLowerCase().includes(lowerCaseFilter))
      );
    });
  }
}

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { iif, of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { Drug } from 'src/API';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { DrugFormat } from './drug.pipe';
import { DrugbankLookupService } from './drugbank-lookup.service';

@Component({
  selector: 'csi-medication-lookup',
  templateUrl: './medication-lookup.component.html',
  styleUrls: ['./medication-lookup.component.scss']
})
export class MedicationLookupComponent {
  @Input() control: FormControl;
  @Input() flagDrugs = false;

  public searchResults$: Observable<ReadonlyArray<Drug>>;
  public typeahead = new Subject<string>();
  public loading = false;
  public notFoundMessage = this.getResponseMessage();
  public isServerErroring = false;

  public readonly DrugFormat = DrugFormat;

  constructor(private drugbankLookupService: DrugbankLookupService) {
    this.searchResults$ = this.typeahead.pipe(
      tap(() => (this.loading = true)),
      debounceTime(200),
      switchMap(searchInput => {
        return iif(
          () => searchInput && searchInput.trim().length >= 2,
          this.drugbankLookupService.searchMedication({ query: searchInput }),
          of([])
        ).pipe(
          map(medications => {
            this.isServerErroring = false;
            this.notFoundMessage = this.getResponseMessage(null, searchInput);
            if (medications) {
              return this.removeAlreadySelectedMedications(
                medications,
                this.control.value as Drug[]
              );
            } else {
              return medications;
            }
          }),
          catchError(err => {
            console.error(err);
            this.isServerErroring = true;
            this.notFoundMessage = this.getResponseMessage(err);
            this.loading = false;
            ErrorHandlerService.trySendingSentryError(err);
            return of([]);
          })
        );
      }),
      tap(() => (this.loading = false))
    );
  }

  public removeMedication(medication: Drug) {
    const index = this.control.value.findIndex((med: { id: string }) => med.id === medication.id);
    if (index !== -1) {
      this.control.value.splice(index, 1);
    }

    // This will update ng-select to reflect the current control values
    // See https://github.com/ng-select/ng-select/blob/6a9016d15a5c393b1c1cfab645df8e9de1de34d5/README.md 'Change Detection'
    this.control.setValue(this.control.value);
  }

  private getResponseMessage(err?: any, searchInput?: string) {
    if (err) {
      let responseDependingOnType: string;
      if (err.status === 0) {
        return `Server did not send response. Ensure you have connection to the internet.`;
      } else if (400 <= err.status && err.status < 500) {
        responseDependingOnType = `Search Error: Bad client request. `;
      } else if (500 <= err.status && err.status < 600) {
        responseDependingOnType = `Server is experiencing issues with your request. `;
      } else {
        responseDependingOnType = `Unknown error with error status of ${err.status}. `;
      }

      return `${responseDependingOnType} Please notify support@csidryeye.com with details and try again later.`;
    } else {
      if (searchInput && searchInput.trim().length < 2)
        return `Search needs at least 2 characters. `;
      return 'No items found.';
    }
  }

  private removeAlreadySelectedMedications(
    medications: Drug[],
    currentMedications: Drug[]
  ): Drug[] {
    return medications.filter(medication => !this.hasMedication(medication, currentMedications));
  }

  private hasMedication(medicineToFind: Drug, medications: Drug[]): boolean {
    return medications && !!medications.find(medication => medication.id === medicineToFind.id);
  }
}

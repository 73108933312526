import { AbstractControl, ValidationErrors } from '@angular/forms';

export class PasswordMatchValidator {
  static validatorForPasswordControl(
    passwordControl: AbstractControl
  ): (control: AbstractControl) => ValidationErrors | null {
    return function(confirmPasswordControl: AbstractControl): ValidationErrors | null {
      const password = passwordControl.value;
      const repeatedPassword = confirmPasswordControl.value;

      if (password && password !== repeatedPassword) {
        return { passwordMatch: true };
      }

      return null;
    };
  }
}

import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawSymptomService {
  public readonly IMAGE_SOURCE = 'assets/images/modal-images/eye-markup/sketch-over-image.png';
  public readonly CANVAS_HEIGHT = 450;
  public readonly CANVAS_WIDTH = 750;

  constructor() {}

  fixCanvasJSON(json) {
    json.src = this.IMAGE_SOURCE;
    json.backgroundImage.src = this.IMAGE_SOURCE;
    if (json.objects) {
      json.objects.forEach(object => {
        object.backgroundColor = null;
        object.fill = null;
      });
    }
  }

  getImageUrlFromJSON(json): Subject<string> {
    const viewOnlyImage$ = new Subject<string>();

    const canvas: fabric.Canvas = new fabric.Canvas('preview-canvas');

    this.fixCanvasJSON(json);

    canvas.loadFromJSON(json, () => {
      canvas.height = this.CANVAS_HEIGHT;
      canvas.width = this.CANVAS_WIDTH;
      viewOnlyImage$.next(canvas.toDataURL());
    });

    return viewOnlyImage$;
  }
}

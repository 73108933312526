import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from 'async';
import invert from 'lodash-es/invert';
import { SymptomModes } from '../symptom.model';

@Pipe({
  name: 'symptomMode'
})
export class SymptomModePipe implements PipeTransform {
  private readonly displayNames: Dictionary<string> = invert(SymptomModes);

  transform(value: SymptomModes): string | boolean {
    return this.displayNames[value];
  }
}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule, ModalModule } from 'ngx-bootstrap';

@NgModule({
  imports: [CommonModule, ModalModule.forRoot(), ButtonsModule.forRoot()],
  exports: [ModalModule, ButtonsModule],
  declarations: []
})
export class SharedNgxBootstrapModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireType } from 'src/API';
import { QuestionnaireRequestUtils } from 'src/app/core/api/questionnaire-request-utils';

@Pipe({
  name: 'questionnaireType'
})
export class QuestionnaireTypePipe implements PipeTransform {
  transform(value: QuestionnaireType): string {
    return QuestionnaireRequestUtils.getQuestionnaireType(value);
  }
}

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { MatDialog, MatTooltip } from '@angular/material';
import { PatientService } from 'src/app/core/api/patient.service';
import { PatientFileModalComponent } from './patient-file-modal/patient-file-modal.component';

@Directive({
  selector: '[csiOpenPatientFileModal]',
  providers: [MatTooltip]
})
export class OpenPatientFileModalDirective {
  @Input() set enabled(val: boolean) {
    this._enabled = val;
    if (!this._enabled) {
      return;
    }
    this.toolTip.message = 'Edit data';
    this.toolTip.position = 'above';
  }
  @Input() patientFileFieldKeys: string[] = [];

  @Output() patientFileSaved: EventEmitter<any> = new EventEmitter();

  private _enabled = false;
  constructor(
    private dialog: MatDialog,
    private toolTip: MatTooltip,
    private patientService: PatientService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (!this._enabled) return;
    event.preventDefault();
    setTimeout(() => {
      this.patientService
        .getPatientProfileFields(this.patientService.patient.id)
        .subscribe(patient => {
          const patientFileDialogRef = this.dialog.open(PatientFileModalComponent, {
            width: '60rem',
            minWidth: '15rem',
            data: {
              ...patient,
              specifiedFieldMode: true,
              specifiedFieldKeys: this.patientFileFieldKeys
            }
          });
          patientFileDialogRef.afterClosed().subscribe(nonModifiableData => {
            if (nonModifiableData) {
              this.patientFileSaved.emit(nonModifiableData);
            }
          });
        });
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.setStyles(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.setStyles(false);
  }

  private setStyles(isHovered: boolean): void {
    if (!this._enabled) {
      return;
    }
    if (isHovered) {
      this.renderer.setStyle(this.el.nativeElement, 'padding', '0.25rem');
      this.renderer.setStyle(this.el.nativeElement, 'border', '1px solid lightgray');
      this.renderer.setStyle(this.el.nativeElement, 'border-radius', '8px');
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'padding');
      this.renderer.removeStyle(this.el.nativeElement, 'border');
      this.renderer.removeStyle(this.el.nativeElement, 'border-radius');
      this.renderer.removeStyle(this.el.nativeElement, 'cursor');
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormTemplateInputFormGroup } from './../form-template-input-form-group';
import { FormTemplateSchema, formTemplateGenericSort } from './../form-template-model';
import { FormTemplateGeneratorService } from './form-template-generator.service';

@Component({
  selector: 'csi-form-template-generator',
  templateUrl: './form-template-generator.component.html',
  styleUrls: ['./form-template-generator.component.css']
})
export class FormTemplateGeneratorComponent implements OnInit {
  @Input() formTemplateSchema: FormTemplateSchema;
  @Input() formTemplateInputFormGroup: FormTemplateInputFormGroup;
  @Input() readOnly: boolean;

  sortFormGroups = formTemplateGenericSort;

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    this.formTemplateGeneratorService.supportingFields = {
      ...this.formTemplateGeneratorService.supportingFields,
      ...this.formTemplateSchema.supportingFields
    };
    this.formTemplateGeneratorService.readOnly = this.readOnly;
  }
}

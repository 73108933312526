import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormTemplateModule } from 'src/app/form-template/form-template.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { IntakeFormViewComponent } from '../intake-form-view.component';

@NgModule({
  declarations: [IntakeFormViewComponent],
  imports: [CommonModule, FormTemplateModule, SharedModule],
  exports: [IntakeFormViewComponent]
})
export class IntakeFormViewModule {}

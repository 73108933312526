import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { Doctor, StaffType, UpdateDoctorInput } from '../../../../API';
import { AbstractControlsMap } from '../../dynamic-form-group/dynamic-form-group';
import { emailValidator } from '../../shared-validators/email-validator';

interface DoctorFormControls extends AbstractControlsMap {
  id: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  practitionerId: FormControl;
  phone: FormControl;
  countryCode: FormControl;
  phoneVerified: FormControl;
  preferredMFAType: FormControl;
  totpVerified: FormControl;
  staffType: FormControl;
}

export class DoctorFormGroup extends FormGroup {
  controls: DoctorFormControls;
  submitted = false;

  constructor() {
    super({
      id: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', emailValidator),
      practitionerId: new FormControl(''),
      phone: new FormControl(''),
      countryCode: new FormControl(''),
      phoneVerified: new FormControl(''),
      preferredMFAType: new FormControl(''),
      totpVerified: new FormControl(''),
      staffType: new FormControl('')
    });
  }

  // TODO: These are probably not needed anymore since Doctor and Clinic models
  // follow exactly this form's model, can you use generic patchValue method
  // and then go through all controls and disable the ones with values
  patchFromDoctor(doctor: Doctor) {
    this.setValueAndDisable(this.controls.id, doctor.id);
    this.setValueAndDisable(this.controls.firstName, doctor.firstName);
    this.setValueAndDisable(this.controls.lastName, doctor.lastName);
    this.setValueAndDisable(this.controls.email, doctor.email);
    this.setValueAndDisable(this.controls.practitionerId, doctor.practitionerId);

    this.setValueAndDisable(
      this.controls.staffType,
      doctor.staffType ||
        (doctor.clinic && doctor.clinic.owner && doctor.clinic.owner.id === doctor.id
          ? StaffType.Owner + '/' + StaffType.Doctor
          : StaffType.Receptionist)
    );
  }

  patchCognitoData(loggedInUser: CognitoUser) {
    if (loggedInUser['attributes']) {
      this.setPhoneAndCountryCodeAndDisable(
        this.controls.phone,
        this.controls.countryCode,
        loggedInUser['attributes']['phone_number']
      );
      this.setValueAndDisable(
        this.controls.phoneVerified,
        loggedInUser['attributes']['phone_number_verified']
      );
      this.setTotpVerifiedAndDisable(
        this.controls.totpVerified,
        loggedInUser['attributes']['custom:totp_verified']
      );
    }
    this.setPreferredMFAAndDisable(this.controls.preferredMFAType, loggedInUser['preferredMFA']);
  }

  // TODO: Should probably have separate view components for read-only / view only data
  // but use disable functionality for now
  private setValueAndDisable(control: FormControl, value: string) {
    control.setValue(value);
    if (value !== undefined && value !== null) {
      control.disable();
    }
  }

  toUpdateDoctorInput(
    doctorId: string,
    clinicId?: string,
    agreedToTerms?: boolean
  ): UpdateDoctorInput {
    return {
      id: doctorId,
      firstName: this.controls.firstName.value,
      lastName: this.controls.lastName.value,
      doctorClinicId: clinicId,
      practitionerId: this.controls.practitionerId.value,
      agreedToTerms,
    };
  }

  setTotpVerifiedAndDisable(control: FormControl, value: string) {
    control.setValue(value === 'true');
    if (value !== undefined && value !== null) {
      control.disable();
    }
  }

  setPreferredMFAAndDisable(control: FormControl, cognitoPreferredMFA: string) {
    control.setValue(
      {
        SMS_MFA: 'SMS',
        SOFTWARE_TOKEN_MFA: 'TOTP',
        NOMFA: 'NOMFA'
      }[cognitoPreferredMFA]
    );
    if (cognitoPreferredMFA !== undefined && cognitoPreferredMFA !== null) {
      control.disable();
    }
  }

  setPhoneAndCountryCodeAndDisable(
    phoneControl: FormControl,
    countryCodeControl: FormControl,
    phoneNumber: string
  ) {
    if (phoneNumber) {
      const parsedPhoneNumber: PhoneNumber = parsePhoneNumberFromString(phoneNumber);
      phoneControl.setValue(parsedPhoneNumber.nationalNumber);
      countryCodeControl.setValue('+' + parsedPhoneNumber.countryCallingCode);
    }
  }
}

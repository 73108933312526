import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { LocationSelectService } from '../../location-select/location-select.service';
import { HealthCardMode, LocationHealthCardModeMap } from '../../location-select/location.model';
import { PatientEntryMethod, PatientFormGroup } from './patient-form.model';

@Component({
  selector: 'csi-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.css']
})
export class PatientFormComponent implements OnInit {
  @Input() formGroup: PatientFormGroup;
  @Input() showResearchConsent = false;
  @Input() canLookupPatients = false;
  @Input() hideTitle = false;
  @Input() showInCard = true;
  @Input() country: string;

  public LocationHealthCardModeMap = LocationHealthCardModeMap;
  public healthCardConfig$: Observable<
    HealthCardMode
  > = this.locationSelectService.getHealthCardConfigForClinic();

  public readonly HealthCardMode = HealthCardMode;
  public readonly PatientEntryMethod = PatientEntryMethod;

  @ViewChild('patientLookupLabel', { static: false }) patientLookupLabel: ElementRef;

  constructor(
    private locationSelectService: LocationSelectService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest([
      this.formGroup.controls.firstName.valueChanges.pipe(startWith(true)),
      this.formGroup.controls.lastName.valueChanges.pipe(startWith(true))
    ]).subscribe(() => {
      this.formGroup.controls.firstName.setValue(
        this.formGroup.controls.firstName.value.trimStart(),
        { onlySelf: true, emitEvent: false }
      );
      this.formGroup.controls.lastName.setValue(
        this.formGroup.controls.lastName.value.trimStart(),
        { onlySelf: true, emitEvent: false }
      );
    });

    this.formGroup.controls.gender.valueChanges.subscribe(val => {
      val === 'other'
        ? this.formGroup.controls.genderOther.enable()
        : this.formGroup.controls.genderOther.disable();
      this.changeRef.detectChanges();
    });

    if (!this.formGroup.controls.gender.value) {
      this.formGroup.controls.genderOther.disable();
    }
  }

  public passFocusToLookupInput(event?: FocusEvent | TouchEvent) {
    this.formGroup.controls.entryMethod.setValue(PatientEntryMethod.LookupExisting);

    const inputBox = this.patientLookupLabel.nativeElement.querySelector('input') as HTMLElement;
    if (inputBox) {
      if (event) {
        event.preventDefault();
      }
      inputBox.focus();
    }
  }

  get canShowForm(): boolean {
    return (
      this.formGroup.controls.entryMethod.value === PatientEntryMethod.AddNewPatient ||
      this.formGroup.controls.id.value
    );
  }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'svg'
})
export class SvgPipe implements PipeTransform {
  public static readonly svgContentType = 'image/svg+xml';

  constructor(private sanitizer: DomSanitizer) {}

  transform(svg: string, bypassSecurity: boolean = false): string | SafeUrl {
    const escapedSvg = svg.replace(/#/g, '%23');
    const inlinedSvg = `data:${SvgPipe.svgContentType};utf8,${escapedSvg}`;
    return bypassSecurity ? this.sanitizer.bypassSecurityTrustUrl(inlinedSvg) : inlinedSvg;
  }
}

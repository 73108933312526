import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TextFavorite, TextFavoriteType } from 'src/API';
import { CreateTextFavoriteInput, UpdateTextFavoriteInput } from './../../../../../API';
import { ClinicSetupService } from './../../clinic-setup.service';
import { TextFavoriteService } from './../text-favorite.service';

interface TextFavoriteSetupActionsModalData {
  action: 'add' | 'update' | 'copy';
  textFavorite?: TextFavorite;
  type: string;
}

@Component({
  selector: 'csi-text-favorite-setup-actions-modal',
  templateUrl: './text-favorite-setup-actions-modal.component.html',
  styleUrls: ['./text-favorite-setup-actions-modal.component.css']
})
export class TextFavoriteSetupActionsModalComponent implements OnInit {
  public TextFavoriteType = TextFavoriteType;
  public duplicateShortcut: TextFavorite;
  public isLoading: boolean;

  public textFavoriteInputFormGroup = new FormGroup({
    id: new FormControl(),
    shortcut: new FormControl(null, Validators.required),
    text: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    copiedTextFavoriteId: new FormControl(null),
    treatmentTemplate: new FormControl()
  });

  constructor(
    private clinicSetupService: ClinicSetupService,
    public textFavoriteService: TextFavoriteService,
    @Inject(MAT_DIALOG_DATA) public data: TextFavoriteSetupActionsModalData,
    private dialogRef: MatDialogRef<TextFavoriteSetupActionsModalComponent>
  ) {}

  ngOnInit() {
    if (this.data.action === 'update') {
      this.textFavoriteInputFormGroup.patchValue(this.data.textFavorite);
    }
    if (this.data.action === 'copy') {
      this.textFavoriteInputFormGroup.controls.copiedTextFavoriteId.setValue(
        this.data.textFavorite.id
      );
      this.textFavoriteInputFormGroup.patchValue(this.data.textFavorite);
      [
        this.textFavoriteInputFormGroup.controls.id,
        this.textFavoriteInputFormGroup.controls.shortcut
      ].forEach(control => control.setValue(null));
    }
    this.textFavoriteInputFormGroup.controls.type.setValue(this.data.type);
    this.textFavoriteInputFormGroup.controls.type.disable();
    // this.toggleTreatmentTemplateControl(this.textFavoriteInputFormGroup.controls.type.value);

    // this.textFavoriteInputFormGroup.controls.type.valueChanges.subscribe(
    //   (type: TextFavoriteType) => {
    //     // this.toggleTreatmentTemplateControl(type);
    //   }
    // );
    this.toggleTreatmentTemplateControl(this.data.type);
  }

  toggleTreatmentTemplateControl(type: string) {
    if (type === TextFavoriteType.Recommendations) {
      this.textFavoriteInputFormGroup.controls.treatmentTemplate.enable();
    } else {
      this.textFavoriteInputFormGroup.controls.treatmentTemplate.setValue(null);
      this.textFavoriteInputFormGroup.controls.treatmentTemplate.disable();
    }
  }

  addTextFavorite(hide = false) {
    this.textFavoriteInputFormGroup.markAllAsTouched();
    if (!this.getDuplicateShortcut() && this.textFavoriteInputFormGroup.valid) {
      this.isLoading = true;
      const {
        treatmentTemplate,
        ...applicableTextFavoriteInputFormGroupValues
      } = this.textFavoriteInputFormGroup.value;

      const createTextFavoriteInput: CreateTextFavoriteInput = {
        ...applicableTextFavoriteInputFormGroupValues,
        textFavoriteClinicId: this.clinicSetupService.clinicId,
        type: this.textFavoriteInputFormGroup.controls.type.value
      };

      if (treatmentTemplate) {
        createTextFavoriteInput.textFavoriteTreatmentTemplateId = treatmentTemplate.id;
      }

      this.textFavoriteService
        .createTextFavorite(createTextFavoriteInput)
        .subscribe(createTextFavoriteMutation => {
          this.updateTextFavoritesFormData(createTextFavoriteMutation.createTextFavorite);

          this.isLoading = false;
          this.dialogRef.close({
            textFavorite: createTextFavoriteMutation.createTextFavorite,
            action: this.data.action,
            hide: hide
          });
        });
    }
  }

  updateTextFavorite() {
    this.textFavoriteInputFormGroup.markAllAsTouched();
    if (!this.getDuplicateShortcut() && this.textFavoriteInputFormGroup.valid) {
      const duplicate = this.getDuplicateShortcut();
      if (!duplicate) {
        this.isLoading = true;
        this.textFavoriteInputFormGroup.controls.type.enable();

        const {
          treatmentTemplate,
          ...applicableTextFavoriteInputFormGroupValues
        } = this.textFavoriteInputFormGroup.value;

        const updateTextFavoriteInput: UpdateTextFavoriteInput = {
          ...applicableTextFavoriteInputFormGroupValues,
          textFavoriteClinicId: this.clinicSetupService.clinicId
        };

        updateTextFavoriteInput.textFavoriteTreatmentTemplateId = treatmentTemplate
          ? treatmentTemplate.id
          : '';

        this.textFavoriteService.updateTextFavorite(updateTextFavoriteInput).subscribe(result => {
          this.isLoading = false;
          this.updateTextFavoritesFormData(result);
          this.dialogRef.close({ textFavorite: result, action: this.data.action });
        });
      }
    }
  }

  getDuplicateShortcut(): TextFavorite {
    this.duplicateShortcut = this.textFavoriteService.textFavoritesFormControl.value.find(
      textFavorite =>
        textFavorite.shortcut === this.textFavoriteInputFormGroup.value.shortcut &&
        textFavorite.type === this.textFavoriteInputFormGroup.value.type &&
        textFavorite.id !== this.textFavoriteInputFormGroup.value.id
    );

    return this.duplicateShortcut;
  }

  updateTextFavoritesFormData(textFavoriteToUpdate: TextFavorite) {
    if (this.data.action === 'update') {
      const textFavorites = this.textFavoriteService.textFavoritesFormControl
        .value as TextFavorite[];
      const indexOfTextFavoriteToUpdate = textFavorites.findIndex(
        textFavorite => textFavoriteToUpdate.id === textFavorite.id
      );
      textFavorites[indexOfTextFavoriteToUpdate] = textFavoriteToUpdate;
      this.textFavoriteService.textFavoritesFormControl.setValue([...textFavorites]);
    } else {
      this.textFavoriteService.textFavoritesFormControl.setValue([
        ...this.textFavoriteService.textFavoritesFormControl.value,
        textFavoriteToUpdate
      ]);
    }
  }
}

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateTextFavoriteInput,
  CreateTextFavoriteMutation,
  DeleteTextFavoriteMutation,
  TextFavorite,
  TextFavoriteType,
  TreatmentTemplate,
  UpdateTextFavoriteInput
} from 'src/API';
import { AppSyncService } from 'src/app/core/appsync.service';
import { maxAWSItemLimit } from 'src/graphql/custom_queries';
import { createTextFavorite, deleteTextFavorite, updateTextFavorite } from 'src/graphql/mutations';
import { listTextFavorites } from 'src/graphql/queries';
import { defaultTreatmentTemplateMap } from '../treatment-template/treatment-template-model';
import { ListTextFavoritesQuery, ListTextFavoritesQueryVariables } from './../../../../API';
import { TreatmentsService } from './../../../core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from './../clinic-setup.service';
import { TreatmentTemplateTreatmentType } from './../treatment-template/treatment-template.service';
import { defaultTextFavoriteMap, TextFavoriteDefault } from './text-favorite-model';

type TextFavoriteList = (
  | TextFavoriteDefault
  | {
      id?: string;
      shortcut?: string;
      text?: string;
      type?: TextFavoriteType;
      treatmentTemplate?: TreatmentTemplate;
      textFavoriteTreatmentTemplateId?: string;
      textFavoriteClinicId?: string;
      createdAt?: string;
      updatedAt?: string;
    }
)[];

type TreatmentControlMap = { [key in TreatmentTemplateTreatmentType]: FormControl };

@Injectable({
  providedIn: 'root'
})
export class TextFavoriteService {
  // public listOfOrder: string[] = [];
  public textFavoriteFilteredData = {};

  public textFavoriteDefaultValuesMap = defaultTextFavoriteMap;
  defaultTreatmentTemplateMap = defaultTreatmentTemplateMap;

  public textFavoritesFormControl = this.clinicSetupService.clinicSetupFormGroup.controls
    .textFavorites;
  public textFavoriteConfig = this.clinicSetupService.clinicSetupFormGroup.controls
    .textFavoriteConfig;
  public isLoading = true;
  public treatmentControlMap: TreatmentControlMap;

  constructor(
    private appSyncService: AppSyncService,
    private clinicSetupService: ClinicSetupService,
    private treatmentsService: TreatmentsService
  ) {
    if (!this.textFavoritesFormControl.value) {
      this.getAllTextFavorites(this.clinicSetupService.clinicId, null).subscribe(
        allTextFavorites => {
          allTextFavorites = [
            ...Object.values(this.textFavoriteDefaultValuesMap),
            ...allTextFavorites
          ];

          allTextFavorites.forEach(textFavorite => {
            if (textFavorite.textFavoriteTreatmentTemplateId && !textFavorite.treatmentTemplate) {
              textFavorite.treatmentTemplate = this.defaultTreatmentTemplateMap[
                textFavorite.textFavoriteTreatmentTemplateId
              ];
            }
          });

          this.textFavoritesFormControl.setValue(allTextFavorites);
          this.isLoading = false;
        }
      );
    }
  }

  resetTreatmentTemplateFromTextFavorites(deletedTreatmentTemplateId: string) {
    this.textFavoritesFormControl.value.forEach((textFavorite: TextFavorite) => {
      if (
        textFavorite.treatmentTemplate &&
        textFavorite.treatmentTemplate.id === deletedTreatmentTemplateId
      ) {
        textFavorite.treatmentTemplate = null;
      }
    });
  }

  getAllTextFavorites(
    clinicId: string,
    nextToken: string,
    textFavorites: TextFavoriteList = []
  ): Observable<TextFavoriteList> {
    const listTextFavoritesQueryVariables: ListTextFavoritesQueryVariables = {
      textFavoriteClinicId: clinicId,
      nextToken,
      limit: maxAWSItemLimit
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listTextFavorites),
          variables: listTextFavoritesQueryVariables
        })
      ),
      mergeMap(({ data }: { data: ListTextFavoritesQuery }) => {
        if (data.listTextFavorites.nextToken) {
          return this.getAllTextFavorites(
            clinicId,
            data.listTextFavorites.nextToken,
            textFavorites
          ).pipe(
            map(nextTextFavorites => [
              ...textFavorites,
              ...data.listTextFavorites.items,
              ...nextTextFavorites
            ])
          );
        }
        return of([...textFavorites, ...data.listTextFavorites.items]);
      })
    );
  }

  public getTextFavoritesForType(type: TextFavoriteType): TextFavorite[] {
    if (!this.textFavoritesFormControl.value) {
      return [];
    }
    const listOfHiddenTextFavorite =
      this.clinicSetupService.clinicSetupFormGroup.controls.hiddenTextFavorites.value || [];
    return this.removeDuplicates(
      [
        ...this.textFavoritesFormControl.value,
        ...Object.values(this.textFavoriteDefaultValuesMap)
      ].filter(textFavorite => {
        return (
          textFavorite.type === type && !listOfHiddenTextFavorite.includes(textFavorite.shortcut)
        );
      })
    );
  }

  private removeDuplicates(favorites: TextFavorite[]): TextFavorite[] {
    const seenTextFavorites = new Set<string>();
    return favorites.filter(favorite => {
      const id =
        favorite.shortcut +
        ((favorite.treatmentTemplate && favorite.treatmentTemplate.friendlyId) || '') +
        favorite.text;
      if (id && seenTextFavorites.has(id)) {
        return false;
      }
      seenTextFavorites.add(id);
      return true;
    });
  }

  public createTextFavorite(
    createTextFavoriteInput: CreateTextFavoriteInput
  ): Observable<CreateTextFavoriteMutation> {
    return this.appSyncService
      .hydrated()
      .pipe(
        switchMap(client => {
          return client.mutate({
            mutation: gql(createTextFavorite),
            variables: { input: createTextFavoriteInput }
          });
        })
      )
      .pipe(map(createTextFavoriteMutation => createTextFavoriteMutation.data));
  }

  public updateTextFavorite(
    updateTextFavoriteInput: UpdateTextFavoriteInput
  ): Observable<TextFavorite> {
    return this.appSyncService
      .hydrated()
      .pipe(
        switchMap(client => {
          return client.mutate({
            mutation: gql(updateTextFavorite),
            variables: { input: updateTextFavoriteInput }
          });
        })
      )
      .pipe(map(updateTextFavoriteMutation => updateTextFavoriteMutation.data.updateTextFavorite));
  }

  public deleteTextFavorite(id: string): Observable<DeleteTextFavoriteMutation> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(deleteTextFavorite),
          variables: { input: { id } }
        });
      })
    );
  }

  public setTreatmentFormControlValue(textFavorites: TextFavorite[]) {
    const treatmentKeys: string[] = [];

    textFavorites.forEach(textFavorite => {
      if (textFavorite.treatmentTemplate) {
        Object.entries(this.treatmentControlMap).forEach(
          ([treatmentControlKey, treatmentControl]) => {
            if (textFavorite.treatmentTemplate[treatmentControlKey]) {
              treatmentControl.setValue(
                this.treatmentsService.treatmentKeysToTreatments([
                  ...new Set([
                    ...treatmentKeys,
                    ...textFavorite.treatmentTemplate[treatmentControlKey],
                    ...((treatmentControl.value &&
                      treatmentControl.value.map(treatment => treatment.id)) ||
                      [])
                  ])
                ])
              );
            }
          }
        );
      }
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compareValueToConditions'
})
export class CompareValueToConditionsPipe implements PipeTransform {
  transform(value: any, conditions: any[]): any {
    let res = false;
    for (const condition of conditions) {
      res =
        value !== undefined &&
        (value === condition ||
          (typeof value === 'number' && value >= condition) ||
          (condition === '' && typeof value === 'string' && value.length >= 1));
      if (res) return true;
    }
    return false;
  }
}

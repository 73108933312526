import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CompareOperator,
  DescriptiveTextConfig,
  DescriptiveTextTypeEnum,
  DisplayFormatEnum,
  FormTemplateFieldAutopopulateConfig,
  MapConditionConfig,
  TransformValueConfig,
  TransformValueTypeEnum
} from './autopopulate.model';

@Injectable({
  providedIn: 'root'
})
export class AutopopulateService {
  constructor() {}

  populateFormGroup(
    formGroup: FormGroup,
    autopopulateConfig: { [key: string]: FormTemplateFieldAutopopulateConfig[] },
    incomingData: any,
    resetControls?: boolean
  ) {
    incomingData = this.flattenByOneLevel(incomingData);
    Object.keys(autopopulateConfig).forEach(key => {
      const formControl: FormControl = this.getFormControl(formGroup, key);
      if (formControl && autopopulateConfig[key]) {
        if (resetControls) {
          formControl.reset();
        }
        autopopulateConfig[key].forEach(autopopulateFieldConfig => {
          const mapConditionSatisfied = this.evaluateMapCondition(
            autopopulateFieldConfig.mapConditionConfig,
            this.getJsonDataViaDotNotation(
              autopopulateFieldConfig.incomingDataValuePath,
              incomingData
            )
          );

          if (mapConditionSatisfied) {
            const transformedValue = this.transformValue(
              formControl,
              autopopulateFieldConfig.transformValueConfig,
              this.getJsonDataViaDotNotation(
                autopopulateFieldConfig.incomingDataValuePath,
                incomingData
              )
            );
            this.formatAndApplyValue(
              formControl,
              autopopulateFieldConfig.transformValueConfig.displayFormat,
              transformedValue
            );
          }
        });
      } else {
        // console.warn('Control does not exist: ' + key);
      }
    });
  }

  getJsonDataViaDotNotation(path: string, json: JSON) {
    try {
      return path.split('.').reduce((a, b) => a[b], json);
    } catch {
      // console.warn('Unable to find JSON value: ' + path);
      return null;
    }
  }

  evaluateMapCondition(mapConditionConfig: MapConditionConfig, incomingValue: any): boolean {
    return this.compareValues(
      incomingValue,
      mapConditionConfig.comparisonValue || null,
      mapConditionConfig.comparisonOperator
    );
  }

  transformValue(
    formControl: FormControl,
    transformValueConfig: TransformValueConfig,
    incomingValue: any
  ) {
    const typeConfig = transformValueConfig.typeConfig;

    if (typeConfig['value']) {
      incomingValue = typeConfig['value'];
    }
    incomingValue = this.applyDescriptiveText(
      transformValueConfig.descriptiveTextConfig,
      incomingValue
    );

    switch (typeConfig.type) {
      case TransformValueTypeEnum.none: {
        return incomingValue;
      }
      case TransformValueTypeEnum.specifyValue: {
        // formControl.patchValue(incomingValue);
        return typeConfig.value;
      }
      case TransformValueTypeEnum.sum: {
        if (!isNaN(incomingValue)) {
          // formControl.patchValue((formControl.value || 0) + incomingValue);
          return (formControl.value || 0) + incomingValue;
        }
        break;
      }
      case TransformValueTypeEnum.map: {
        if (typeof incomingValue === 'string') {
          if (typeConfig.map[incomingValue]) {
            // console.log('applying map: ' + incomingValue, typeConfig.map[incomingValue]);
            // formControl.patchValue(typeConfig.map[incomingValue]);
            return typeConfig.map[incomingValue];
          }
        }
        return incomingValue;
      }
    }
  }

  formatAndApplyValue(
    formControl: FormControl,
    displayFormat: DisplayFormatEnum,
    incomingValue: any
  ) {
    switch (displayFormat) {
      case DisplayFormatEnum.list: {
        if (incomingValue === null) {
          return;
        }

        let tempValue = formControl.value;

        if (Array.isArray(incomingValue)) {
          incomingValue = incomingValue.join('\n');
        }

        tempValue = (tempValue || '') + incomingValue + '\n';
        formControl.patchValue(tempValue);
        break;
      }
      case DisplayFormatEnum.none: {
        formControl.patchValue(incomingValue);
        break;
      }
    }
  }

  applyDescriptiveText(descriptiveTextConfig: DescriptiveTextConfig, incomingValue: any) {
    if (!descriptiveTextConfig) return incomingValue;
    // console.log('applying descriptive text');
    switch (descriptiveTextConfig.type) {
      case DescriptiveTextTypeEnum.pre: {
        return descriptiveTextConfig.preDescriptiveText + incomingValue;
      }
      case DescriptiveTextTypeEnum.post: {
        return incomingValue + descriptiveTextConfig.postDescriptiveText;
      }
      case DescriptiveTextTypeEnum.preAndPost: {
        return (
          descriptiveTextConfig.preDescriptiveText +
          incomingValue +
          descriptiveTextConfig.postDescriptiveText
        );
      }
    }
  }

  public compareValues(
    incomingValue: any,
    comparisonValue: any,
    operator: CompareOperator
  ): boolean {
    if (incomingValue === undefined) return false;
    // console.log('comparing: ' + incomingValue + ' with ' + comparisonValue);
    switch (operator) {
      case '>': {
        return incomingValue > comparisonValue;
      }
      case '>=': {
        return incomingValue >= comparisonValue;
      }
      case '<': {
        return incomingValue < comparisonValue;
      }
      case '<=': {
        return incomingValue <= comparisonValue;
      }
      case '=': {
        return incomingValue === comparisonValue;
      }
      case '!=': {
        return incomingValue !== comparisonValue;
      }
      case '!empty': {
        if (
          (typeof incomingValue === 'string' || Array.isArray(incomingValue)) &&
          incomingValue.length > 0
        ) {
          return true;
        }
        return false;
      }
      case 'none': {
        return true;
      }
      // case 'includes': {
      //   return patientData.includes(conditionData);
      // }
    }
  }

  getFormControl(formGroup: FormGroup, key: string) {
    let control: any = formGroup;
    const keys = key.split('.');
    if (keys.length === 1) {
      return formGroup.controls[key];
    }
    keys.forEach(k => {
      try {
        control = control['controls'][k];
      } catch (e) {
        // console.warn('Control not found: ' + k);
        return null;
      }
    });
    return control;
  }

  flattenByOneLevel(data: JSON) {
    const res = Object.values(data).reduce((acc, val) => {
      acc = { ...acc, ...val };
      return acc;
    }, {});
    // console.log('flattened', res);
    return res;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'csi-no-results-message',
  templateUrl: './no-results-message.component.html',
  styleUrls: ['./no-results-message.component.css']
})
export class NoResultsMessageComponent {
  @Input() icon: string = 'search_off'; // Default icon, changeable with [icon]="'your_icon'" in your template

}

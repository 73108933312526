import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SymptomModes } from '../../../symptoms/symptom.model';

interface DryEyeFormControls {
  assessmentMethod: FormControl;
}

export class DryEyeFormGroup extends FormGroup {
  controls: DryEyeFormControls & { [key: string]: AbstractControl };
  submitted = false;
  assessmentMethod: BehaviorSubject<SymptomModes>;

  constructor() {
    super({
      assessmentMethod: new FormControl('', Validators.required)
    });
    this.assessmentMethod = new BehaviorSubject<SymptomModes>(null);

    this.controls.assessmentMethod.valueChanges.subscribe(symptomMode =>
      this.assessmentMethod.next(symptomMode)
    );
  }

  // The advanced modal dialogs create formgroup controls only when user
  // opens the modal. So these controls may not exist in this.controls at
  // time of patchValue. So we optimistically create controls for keys that
  // do not have a control yet.
  //
  // Todo: Some refactoring is needed here. At minimum, we should
  // loop through the dry eye schema and create controls beforehand, asking each modal
  // for its own schema through ComponentLookupService. This way patchValue will just work.
  // The ideal though is to separate the form form data structure from the
  // UI. Form structure should be independent of the current value of the form.
  patchValue(
    value: {
      [key: string]: any;
    },
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    Object.keys(value).forEach(key => {
      if (!this.controls[key]) {
        this.addControl(key, new FormControl(value[key]));
      }
    });
    super.patchValue(value, options);
  }
}

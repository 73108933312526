export interface ModelWithDates {
  createdAt?: string;
  updatedAt?: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export const ModelUtils = {
  sortByDate(
    items: ModelWithDates[],
    dateField: 'createdAt' | 'updatedAt' = 'updatedAt',
    sortOrder: 'asc' | 'desc' = 'desc'
  ): ModelWithDates[] {
    const sortInt = sortOrder === 'desc' ? 1 : -1;

    items.sort(
      (a: ModelWithDates, b: ModelWithDates) =>
        sortInt * (new Date(b[dateField]).getTime() - new Date(a[dateField]).getTime())
    );

    return items;
  }
};

export function noSort() {
  return 0;
}

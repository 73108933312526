import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { finalize, mergeMap } from 'rxjs/operators';
import { TotpQrCodeModalComponent } from './totp-qr-code-modal/totp-qr-code-modal.component';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'csi-select-mfa-method',
  templateUrl: './select-mfa-method.component.html',
  styleUrls: ['./select-mfa-method.component.css']
})
export class SelectMfaMethodComponent implements OnInit {
  @Input() mfaFormControl: FormControl;
  @Input() totpVerifiedControl: FormControl;
  @Input() phoneVerifiedControl: FormControl;

  public currentMFAOption: string;

  constructor(private authenticationService: AuthenticationService, private dialog: MatDialog) {}

  mfaTypeOptions = { SMS: 'SMS', TOTP: 'TOTP', NOMFA: 'None' };

  public matSpinnerOptions = {
    active: false,
    text: 'Set Up',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  ngOnInit() {
    this.currentMFAOption = this.mfaFormControl.value;
  }

  setUpMFA() {
    this.matSpinnerOptions.active = true;
    if (this.mfaFormControl.value === 'TOTP') {
      this.authenticationService
        .setupTOTP()
        .pipe(
          finalize(() => (this.matSpinnerOptions.active = false)),
          mergeMap(code => this.dialog.open(TotpQrCodeModalComponent, { data: code }).afterClosed())
        )
        .subscribe((verified: boolean) => {
          if (verified) {
            this.totpVerifiedControl.setValue(true);
          }
        });
    }
  }
}

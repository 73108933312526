import { Directive } from '@angular/core';

/**
 * INFO:
 *
 * This directive functionally does nothing. It's purpose is to allow autocomplete and prevent
 * un-allowed attribute errors on csi-numeric-symptom-control, csi-text-symptom-control, and
 * csi-symptom-control-adapter components. When this attribute is applied to one of those
 * components it forces the control to show the symptom name using mat-label inline in the
 * mat-form-field rather than relying on external display.
 */

@Directive({
  selector:
    // tslint:disable-next-line:max-line-length
    'csi-symptom-control-adapter[csiShowLabelInline],csi-numeric-symptom-control[csiShowLabelInline],csi-text-symptom-control[csiShowLabelInline]'
})
export class ShowLabelInlineDirective {}

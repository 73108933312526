import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { HealthCardMode, LocationHealthCardModeMap } from '../location-select/location.model';
import { HealthCardFormGroup } from '../patient-form/patient-form.model';

@Component({
  selector: 'csi-healthcard-form-field',
  templateUrl: './healthcard-form-field.component.html',
  styleUrls: ['./healthcard-form-field.component.css']
})
export class HealthcardFormFieldComponent implements OnInit {
  @Input() formGroup: HealthCardFormGroup;
  @Input() clinicCountry: string;

  @HostBinding('hidden') isHidden = false;

  public readonly HealthCardMode = HealthCardMode;
  public healthCardConfig: HealthCardMode;

  ngOnInit() {
    this.healthCardConfig = LocationHealthCardModeMap[this.clinicCountry];
    this.formGroup.controls.country.patchValue(this.clinicCountry);
    if (this.healthCardConfig === HealthCardMode.numberOnly) {
      setTimeout(() => this.formGroup.controls.province.setValue(null));
    } else if (this.healthCardConfig === HealthCardMode.noHealthCard) {
      this.isHidden = true;
      setTimeout(() => {
        this.formGroup.controls.province.setValue(null);
        this.formGroup.controls.number.setValue(null);
      });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { notificationMap, NotificationMapEntry } from './notification-map';
import { WelcomeMessageConfigFormGroup } from '../clinic-setup-form.model';

@Component({
  selector: 'csi-customizable-welcome-notification',
  templateUrl: './customizable-welcome-notification.component.html',
  styleUrls: ['./customizable-welcome-notification.component.css']
})
export class CustomizableWelcomeNotificationComponent implements OnInit {
  @Input() public formGroup: WelcomeMessageConfigFormGroup;

  public notificationMap: Record<string, NotificationMapEntry> = notificationMap;

  ngOnInit(): void {}
}

import { Component, Input } from '@angular/core';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import { DryEyeFormGroup } from '../dry-eye-form.model';
import { LayoutsService } from './../../../../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';

@Component({
  selector: 'csi-dry-eye-assessment-method-form',
  templateUrl: './dry-eye-assessment-method-form.component.html',
  styleUrls: ['./dry-eye-assessment-method-form.component.scss']
})
export class DryEyeAssessmentMethodFormComponent {
  public readonly SymptomModes = SymptomModes;

  @Input() formGroup: DryEyeFormGroup;

  constructor(
    public layoutsService: LayoutsService,
    public confirmModalService: ConfirmModalService
  ) {}

  get assessmentMethod(): SymptomModes {
    return this.formGroup.controls.assessmentMethod.value;
  }

  noSort() {
    return 0;
  }

  onMethodChange(newMethod: SymptomModes) {
    const currentAssessmentMethod = this.formGroup.controls.assessmentMethod.value;

    const currentFriendlyMethod = this.layoutsService.assessmentMethodFriendlyName[
      currentAssessmentMethod
    ];
    const newFriendlyMethod = this.layoutsService.assessmentMethodFriendlyName[newMethod];

    if (!currentAssessmentMethod) {
      this.formGroup.controls.assessmentMethod.setValue(newMethod);
      return;
    }

    if (
      (currentAssessmentMethod !== SymptomModes.PreOp && newMethod === SymptomModes.PreOp) ||
      (currentAssessmentMethod === SymptomModes.PreOp && newMethod !== SymptomModes.PreOp)
    ) {
      return this.confirmModalService
        .show(
          `Changing Assessment Method From ${currentFriendlyMethod} to ${newFriendlyMethod}`,
          `Are you sure you want to change the assessment method from ${currentFriendlyMethod} to ${newFriendlyMethod}?
           <br>This will overwrite the existing values?`,
          'Overwrite and Change',
          'Cancel'
        )
        .subscribe(confirmed => {
          if (confirmed) {
            this.formGroup.controls.assessmentMethod.setValue(newMethod);
          }
        });
    }

    this.formGroup.controls.assessmentMethod.setValue(newMethod);
  }
}

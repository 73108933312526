import { Component, HostBinding, Input } from '@angular/core';
import { BuckitizedThermalScaleConfig, LinearThermalScaleConfig } from './thermal-scale.model';

@Component({
  selector: 'csi-thermal-scale',
  templateUrl: './thermal-scale.component.html',
  styleUrls: ['./thermal-scale.component.css']
})
export class ThermalScaleComponent {
  @Input() value: number | string;
  @Input() config: BuckitizedThermalScaleConfig | LinearThermalScaleConfig;
  @Input() smoothTickMovements = true;
  @Input() lang: string;

  readonly rangeConstraints = { max: 0.97, min: 0.03 };

  getTransform(scaledValue: number): string {
    return `translate(${scaledValue * 100}%)`;
  }

  shouldRightAlign(normalizedValue: number): boolean {
    return normalizedValue > 0.5;
  }

  @HostBinding('class.thermal-scale-with-label') private get shouldMakeRoomForLabel() {
    return this.config.showLabel || this.config.showLabel === undefined;
  }
}

import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import clamp from 'lodash-es/clamp';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BuckitizedThermalScaleConfig, LinearThermalScaleConfig } from './thermal-scale.model';
import {
  maxThermalScaleColor,
  minThermalScaleColor,
  ThermalScaleService,
  ThermalScaleType
} from './thermal-scale.service';

export interface ThermalScaleDirectiveContext {
  $implicit: number;
  label: string;
  normalizedValue: number;
  minLabel: string;
  maxLabel: string;
}

export interface ThermalScaleRangeConstraints {
  min: number;
  max: number;
}

@Directive({
  selector: '[csiThermalScale]'
})
export class ThermalScaleDirective implements OnChanges {
  @Input('csiThermalScaleFrom') value: string | number;
  @Input('csiThermalScaleWithConfig') config:
    | BuckitizedThermalScaleConfig
    | LinearThermalScaleConfig;
  @Input('csiThermalScaleDebounce') valueDebounceTime = 500;
  @Input('csiThermalScaleConstrainTo') rangeConstraints: ThermalScaleRangeConstraints = {
    min: 0,
    max: 1
  };
  @Input('csiThermalScaleLang') lang = 'en';

  private context: ThermalScaleDirectiveContext;
  private startValue = 0.0;
  private endValue = 1.0;

  private valueEmitter: Subject<number>;
  private subscribedToValueEmitter = false;
  private hasView = false;
  private _shouldHide = true;

  private translateObject;

  constructor(
    private thermalScaleService: ThermalScaleService,
    private templateRef: TemplateRef<ThermalScaleDirectiveContext>,
    private viewContainerRef: ViewContainerRef,
    private changeRef: ChangeDetectorRef,
    private translocoService: TranslocoService
  ) {
    setTimeout(() => {
      this.translateObject = this.translocoService.getTranslation(this.lang);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      if (this.type === ThermalScaleType.Numeric && this.config && this.config['linear']) {
        const linearConfig = this.config as LinearThermalScaleConfig;
        const rangeComponents = linearConfig.linear.replace(/\s/g, '').split('-');

        this.startValue = parseFloat(rangeComponents[0]);
        this.endValue = parseFloat(rangeComponents[1]);

        if (isNaN(this.startValue) || isNaN(this.endValue)) {
          console.error(`Thermal scale config condition not understood '${linearConfig.linear}'`);
          alert(`Thermal scale config condition not understood '${linearConfig.linear}'`);

          this.startValue = 0.0;
          this.endValue = 1.0;
        }
      } else {
        this.startValue = 0.0;
        this.endValue = 1.0;
      }
    }

    if (changes.value) {
      if (!this.subscribedToValueEmitter) {
        this.subscribeToValueChanges();
      }
      this.setColor(this.thermalScaleService.getColorValue(this.value, this.config));
    }
  }

  private subscribeToValueChanges() {
    this.valueEmitter = new Subject<number>().pipe(debounceTime(this.valueDebounceTime)) as Subject<
      number
    >;
    this.valueEmitter.subscribe(normalizedColorValue => this.setContext(normalizedColorValue));

    this.subscribedToValueEmitter = true;
  }

  private setContext(normalizedColorValue: number) {
    if (this.context) {
      Object.assign(this.context, this.createContext(normalizedColorValue));
    } else {
      this.context = this.createContext(normalizedColorValue);
    }

    this.shouldHide ? this.hide() : this.show();

    this.changeRef.markForCheck();
  }

  private createContext(normalizedColorValue: number): ThermalScaleDirectiveContext {
    return {
      $implicit: this.scaleValue(normalizedColorValue),
      normalizedValue: normalizedColorValue,
      label:
        this.config.showLabel === false
          ? null
          : this.translateObject[this.thermalScaleService.getLabel(this.value, this.config)] ||
            this.thermalScaleService.getLabel(this.value, this.config),
      minLabel: this.minLabel,
      maxLabel: this.maxLabel
    };
  }

  private get type(): ThermalScaleType {
    return typeof this.value === 'number' ? ThermalScaleType.Numeric : ThermalScaleType.Discrete;
  }

  private setColor(colorValue: number) {
    if (colorValue !== null) {
      this.shouldHide = false;
      this.valueEmitter.next(colorValue);
    } else {
      this.shouldHide = true;
    }
  }

  private hide() {
    if (this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }

  private show() {
    if (!this.hasView && this.context) {
      this.hasView = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
    }
  }

  private scaleValue(normalizedColorValue: number): number {
    const rangeDiff = this.rangeConstraints.max - this.rangeConstraints.min;
    const scaledValue = normalizedColorValue * rangeDiff + this.rangeConstraints.min;

    return clamp(scaledValue, this.rangeConstraints.min, this.rangeConstraints.max);
  }

  private get minLabel(): string {
    const labelForColor = this.thermalScaleService.getLabelForColor(
      minThermalScaleColor,
      this.config
    );

    return (
      this.translateObject['response.' + labelForColor] ||
      this.translateObject['response.' + labelForColor.toUpperCase()] ||
      this.translateObject['response.' + labelForColor.toLowerCase()] ||
      labelForColor
    );
  }

  private get maxLabel(): string {
    const labelForColor = this.thermalScaleService.getLabelForColor(
      maxThermalScaleColor,
      this.config
    );

    return (
      this.translateObject['response.' + labelForColor] ||
      this.translateObject['response.' + labelForColor.toUpperCase()] ||
      this.translateObject['response.' + labelForColor.toLowerCase()] ||
      labelForColor
    );
  }

  private set shouldHide(value: boolean) {
    this._shouldHide = value;
    this._shouldHide ? this.hide() : this.show();
  }

  private get shouldHide(): boolean {
    return this._shouldHide;
  }
}

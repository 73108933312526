import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { concat, identity, Observable, of } from 'rxjs';
import { every } from 'rxjs/operators';
import { DryEyeExpertPanelGuard } from '../authentication/guards/dry-eye-expert-panel.guard';
import { MissingDoctorAndClinicInfoGuard } from '../authentication/guards/missing-doctor-and-clinic-info.guard';
import { DomainOption, HOSTING_DOMAIN_ACCESSOR } from '../core/domain-accessor';

@Injectable({
  providedIn: 'root'
})
export class CSIDryEyeGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private dryEyeExpertPanelGuard: DryEyeExpertPanelGuard,
    private missingDoctorAndClinicInfoGuard: MissingDoctorAndClinicInfoGuard,
    @Inject(HOSTING_DOMAIN_ACCESSOR) private domain: DomainOption
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
    if (this.domain === DomainOption.Seema) {
      // Tried to specify this in the routing file but can't, see https://github.com/angular/angular/issues/18662
      window.location.href =
        'https://portal.csidryeye.com/refer/link/53ee9705-d288-4cba-a933-0bb97e095e81';
      return of(false);
    } else {
      return (
        this.shouldSkip(next) ||
        concat(
          this.dryEyeExpertPanelGuard.canActivate(next, state),
          this.missingDoctorAndClinicInfoGuard.canActivate(next, state)
        ).pipe(every(identity))
      );
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  shouldSkip(activatedRoute: ActivatedRouteSnapshot): boolean {
    return (
      activatedRoute.data.skipCSIDryEyeGuard ||
      activatedRoute.children.some(childRoute => this.shouldSkip(childRoute))
    );
  }
}

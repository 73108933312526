import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

interface MedicalHistoryFormControls {
  medicalHistory: FormControl;
  drugAllergies: FormControl;
  seasonalAllergies: FormControl;
}

export class MedicalHistoryFormGroup extends FormGroup {
  controls: MedicalHistoryFormControls & { [key: string]: AbstractControl };
  submitted = false;

  constructor() {
    super({
      medicalHistory: new FormControl('', Validators.required),
      drugAllergies: new FormControl('', Validators.required),
      seasonalAllergies: new FormControl('', Validators.required)
    });
  }
}

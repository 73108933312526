import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector
} from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService { 
  private loadingSpinnerComponentRef: ComponentRef<LoadingSpinnerComponent>;
  private semaphore = 0;

  constructor(
    private componentFactory: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  // TODO: Add option to set config params of spinner
  public show() {
    this.semaphore += 1;

    if (this.semaphore === 1) {
      const loadingSpinnerFactory = this.componentFactory.resolveComponentFactory(
        LoadingSpinnerComponent
      );
      const host = document.createElement(loadingSpinnerFactory.selector);
      document.body.appendChild(host);

      this.loadingSpinnerComponentRef = loadingSpinnerFactory.create(this.injector, [], host);
      this.loadingSpinnerComponentRef.instance.fixedPositioning = true;
      this.appRef.attachView(this.loadingSpinnerComponentRef.hostView);
    }
  }

  public hide() {
    if (this.semaphore === 1) {
      this.loadingSpinnerComponentRef.destroy();
      this.loadingSpinnerComponentRef = undefined;
    }

    this.semaphore -= 1;
  }
}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import {
  Treatment,
  TreatmentsService
} from 'src/app/core/remote-json/treatments/treatments.service';

@Component({
  selector: 'csi-treatment-pick-viewer',
  templateUrl: './treatment-pick-viewer.component.html',
  styleUrls: ['./treatment-pick-viewer.component.css']
})
export class TreatmentPickViewerComponent implements OnInit {
  @Input() treatments: string[] = [];
  @Input() productType: 'rx' | 'otc' | 'exercise';

  public treatmentToView: Treatment;
  public treatmentData: Treatment[] = [];
  @ViewChild('treatmentModalTemplate', { static: true }) treatmentModalTemplate: TemplateRef<any>;

  constructor(private treatmentsService: TreatmentsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.treatmentData = (this.treatments || [])
      .filter((treatmentId: any) => {
        return !!treatmentId;
      })
      .map((treatmentId: any) => {
        if (typeof treatmentId === 'object') return treatmentId;
        return this.treatmentsService.idToTreatmentMap.get(treatmentId);
      });
  }

  viewTreatment(treatment, event: Event) {
    this.treatmentToView = treatment;
    this.dialog.open(this.treatmentModalTemplate, {
      position: { top: '5rem' },
      minWidth: '50rem',
      minHeight: '18.725rem',
      maxHeight: '85vh'
    });
  }
}

import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FilterChipsComponent } from './treatment-picker/filter-chips/filter-chips.component';
import { TreatmentPickerComponent } from './treatment-picker/treatment-picker.component';
import { TreatmentPipe } from './treatment.pipe';

@NgModule({
  declarations: [TreatmentPickerComponent, FilterChipsComponent],
  imports: [CommonModule, SharedModule],
  providers: [TitleCasePipe],
  exports: [TreatmentPickerComponent]
})
export class TreatmentPickerModule {}

import { Component, Input, OnInit } from '@angular/core';
import { Treatment } from 'src/app/core/remote-json/treatments/treatments.service';
import { SchemaService } from '../../symptoms/services/schema/schema.service';
import { SymptomMethod } from '../../symptoms/symptom.model';
import { ThermalScaleConfig } from '../../symptoms/thermal-scale/thermal-scale.model';
import { PdfSummaryService } from './pdf-summary.service';

@Component({
  selector: 'csi-pdf-summary-export',
  templateUrl: './pdf-summary-export.component.html',
  styleUrls: ['./pdf-summary-export.component.css']
})
export class PdfSummaryExportComponent implements OnInit {
  @Input() title: string;
  @Input() treatment: Treatment;
  @Input() signs = false;
  @Input() value: string | number;
  @Input() method: SymptomMethod;
  @Input() units: string;
  @Input() symptomKey: string;
  @Input() config: ThermalScaleConfig;
  @Input() lang: string;
  public customTreatment: { [key: string]: Treatment[] } = {};
  public treatmentKeys = ['recommendedExercises', 'recommendedEyeDrops', 'recommendedProcedures'];
  protected thermalScale: any = {};
  protected colorToSet: string = '';
  public colorMap = this.pdfSummaryService.colorMap;

  constructor(public schemaService: SchemaService, private pdfSummaryService: PdfSummaryService) {}

  ngOnInit() {
    if (!this.signs) {
      this.treatmentKeys.forEach(key => {
        (this.treatment[key] || []).forEach(treatmentKey => {
          this.customTreatment[treatmentKey.class] = [
            treatmentKey,
            ...(this.customTreatment[treatmentKey.class] || [])
          ];
        });
      });
    } else {
      this.thermalScale = this.thermalScaleConfig();
      const temp = this.pdfSummaryService.getColorFromValue(this.value, this.thermalScale);
      this.colorToSet = temp ? temp.replace(/\s/g, '') : '';
    }
  }

  thermalScaleConfig() {
    return this.config
      ? this.config
      : this.schemaService.getThermalScaleConfig(this.symptomKey, this.method);
  }
}

import { Component, Input } from '@angular/core';
import { MedicalHistoryFormGroup } from './medical-history-form.model';

@Component({
  selector: 'csi-medical-history-form',
  templateUrl: './medical-history-form.component.html',
  styleUrls: ['./medical-history-form.component.css']
})
export class MedicalHistoryFormComponent {
  @Input() formGroup: MedicalHistoryFormGroup;
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatSlider } from '@angular/material/slider';
import { Logger } from 'aws-amplify';
import range from 'lodash-es/range';

@Component({
  selector: 'csi-labeled-mat-slider',
  templateUrl: './labeled-mat-slider.component.html',
  styleUrls: ['./labeled-mat-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/* Limitations:
    - Does not support case where slider.step !== slider.stepIntervals
    - Does not support vertical sliders
    - Case where slider min, max, etc.. values dynamically change is not tested
  */
export class LabeledMatSliderComponent implements AfterContentInit {
  @Input() showNumericTopLabels: boolean;
  @Input() squeezeInLastBottomLabel: boolean;
  @Input() bottomLabels: string[] = [];
  @Input() showPlusSign: boolean;
  @Input() alwaysDisplayBottomLabelEndpoints: boolean;
  @Input() onlyDisplayTopLabelEndpoints: boolean;

  @Input() translateLabel: boolean;

  private _firstLabelIndex = 0;
  @Input('firstLabelIndex')
  set firstLabelIndex(value: number) {
    this._firstLabelIndex = Number(value);
  }
  get firstLabelIndex(): number {
    return this._firstLabelIndex;
  }

  @ContentChild(MatSlider, { static: false }) slider: MatSlider;
  @ContentChild(NgControl, { static: false }) ngControl: NgControl;

  private logger: Logger = new Logger('LabeledMatSliderComponent');

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    if (!this.slider) {
      this.logger.error('No MatSlider provided inside LabledMaterSlider!');
    } else {
      this.slider.valueChange.subscribe(value => {
        this.changeDetectorRef.detectChanges();
      });

      this.ngControl.statusChanges.subscribe(status => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  get topLabels(): string[] {
    return this.showNumericTopLabels
      ? this.tickIndexToSliderValue.map(value =>
          (this.slider.displayWith ? this.slider.displayWith(value) : value).toString()
        )
      : [];
  }

  get numberOfTicks(): number {
    return (this.slider.max - this.slider.min + 1) / this.slider.step;
  }

  get thumbContainerStyles(): { [key: string]: string }[] {
    const transformIncrement = (this.slider.step / (this.slider.max - this.slider.min)) * 100;
    const transforms = range(-100, transformIncrement, transformIncrement);
    return transforms.map(transform => {
      return { transform: `translateX(${transform}%)` };
    });
  }

  get selectedTickIndex(): number {
    return this.tickIndexToSliderValue.indexOf(this.slider.value);
  }

  get tickIndexToSliderValue(): number[] {
    return range(this.slider.min, this.slider.max + 1, this.slider.step);
  }

  setValue(selectedIndex: number) {
    if (!this.isDisabled) {
      const value = this.tickIndexToSliderValue[selectedIndex];
      if (this.ngControl) {
        this.ngControl.control.setValue(value);
      } else {
        this.slider.value = value;
      }
    }
  }

  get isDisabled(): boolean {
    return (this.slider && this.slider.disabled) || (this.ngControl && this.ngControl.disabled);
  }
}

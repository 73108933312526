import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Notification } from 'src/API';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { ReferralService } from 'src/app/referral/referral.service';
import { QuestionnaireResponseModalComponent } from 'src/app/shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';
import { InboxModalComponent } from '../inbox-modal/inbox-modal.component';
import { NotificationFormat } from './../inbox.model';

@Component({
  selector: 'csi-notification-content-display',
  templateUrl: './notification-content-display.component.html',
  styleUrls: ['./notification-content-display.component.css']
})
export class NotificationContentDisplayComponent implements OnInit, OnChanges {
  @Input() notification: Notification;
  public notificationContent = null;
  public NotificationFormat = NotificationFormat;
  public customScoreIndicator: boolean;
  public customFlaggingText =
    '<span style="color: rgb(238, 99, 49);font-weight: bold;"> Based on the custom clinic questionnaire score, this patient is at risk.</span>';
  public safeTextBodyContent: SafeHtml | null = null;

  constructor(
    public router: Router,
    private questionnaireRequestService: QuestionnaireRequestService,
    private referralService: ReferralService,
    public inboxDialogRef: MatDialogRef<InboxModalComponent>,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.notification && this.notification.content) {
      this.notificationContent = JSON.parse(this.notification.content);
      this.setCustomScoreIndicator();

      const textBody = (this.notificationContent && this.notificationContent.textBody) || '';
      this.safeTextBodyContent = this.sanitizer.bypassSecurityTrustHtml(
        this.customScoreIndicator ? textBody + this.customFlaggingText : textBody
      );
    }
  }

  setCustomScoreIndicator() {
    this.customScoreIndicator = this.questionnaireRequestService.customSpeedThreshold(
      JSON.parse(this.notificationContent.questionnaireScores || '{}').SPEED
    );
  }

  viewQuestionnaireResponse() {
    this.questionnaireRequestService
      .getQuestionnaireRequest(this.notificationContent.id)
      .subscribe(questionnaire => {
        QuestionnaireResponseModalComponent.open(this.dialog, questionnaire);
      });
  }

  goToReferral() {
    this.referralService.currentReferral = null;
    const url = `/refer/${this.notificationContent.id}`;
    this.router.navigateByUrl(url);
    this.inboxDialogRef.close();
  }

  goToIntakeForm() {
    const url = `/intake-forms/${this.notificationContent.id}`;
    this.router.navigateByUrl(url);
    this.inboxDialogRef.close();
  }
}

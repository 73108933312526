import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DropDownConfig,
  FormFieldConfig,
  formTemplateGenericSort
} from './../../../form-template-model';
import { FormTemplateGeneratorService } from './../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-dropdown-field-generator',
  templateUrl: './form-template-dropdown-field-generator.component.html',
  styleUrls: ['./form-template-dropdown-field-generator.component.css']
})
export class FormTemplateDropdownFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: DropDownConfig };
  @Input() formTemplateFieldKey: string;
  @Input() formTemplateGroupFormGroup: FormGroup;
  @Input() control: FormControl;
  @Input() readOnly: boolean;
  @Input() formatForPDF: boolean;
  @Input() supportingFields: { [key: string]: FormFieldConfig };

  sortValues = formTemplateGenericSort;

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {}
}

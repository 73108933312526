import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReferralForm } from 'src/API';
import { referralFormTypeKeyFriendlyNameMap } from 'src/app/referral/referral.service';
import { AssessmentService } from './../../../../core/api/assessment.service';

interface ReferralFormsSetupActionsModalData {
  action: 'add' | 'update';
  referralForm?: ReferralForm;
}

@Component({
  selector: 'csi-referral-forms-setup-actions-modal',
  templateUrl: './referral-forms-setup-actions-modal.component.html',
  styleUrls: ['./referral-forms-setup-actions-modal.component.css']
})
export class ReferralFormsSetupActionsModalComponent implements OnInit {
  public referralFormFormGroup = new FormGroup({
    fileControl: new FormControl(null, Validators.required),
    referralFormName: new FormControl(null, Validators.required),
    referralFormType: new FormControl(null, Validators.required)
  });

  public referralFormTypeKeyFriendlyNameMap = referralFormTypeKeyFriendlyNameMap;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReferralFormsSetupActionsModalData,
    private dialogRef: MatDialogRef<ReferralFormsSetupActionsModalComponent>,
    public assessmentService: AssessmentService
  ) {}

  ngOnInit() {
    if (this.data.referralForm) {
      this.referralFormFormGroup.controls.fileControl.setValue([this.data.referralForm.file]);
      this.referralFormFormGroup.controls.referralFormName.setValue(this.data.referralForm.name);
      this.referralFormFormGroup.controls.referralFormType.setValue(this.data.referralForm.type);
    }
  }

  performReferralFormAction() {
    this.referralFormFormGroup.markAllAsTouched();
    if (this.referralFormFormGroup.valid) {
      this.dialogRef.close({
        name: this.referralFormFormGroup.controls.referralFormName.value,
        type: this.referralFormFormGroup.controls.referralFormType.value,
        file: this.referralFormFormGroup.controls.fileControl.value[0]
      });
    }
  }
}

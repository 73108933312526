import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { signGroups } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments-schema';
import { Attachment } from 'src/app/shared/attachments/attachments.component';
import { DataSrcService } from 'src/app/shared/enlargeable-media/data-src.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import {
  Eye,
  SignMediaSelectionDialogData
} from '../signs-attachments-modal/signs-attachments-schema';
import { SignsAttachmentsService } from '../signs-attachments-modal/signs-attachments.service';

@Component({
  selector: 'csi-sign-media-selection-modal',
  templateUrl: './sign-media-selection-modal.component.html',
  styleUrls: ['./sign-media-selection-modal.component.scss']
})
export class SignMediaSelectionModalComponent implements OnInit {
  public attachments: Attachment[] | File[] = [];
  public metadataToAttachmentsMap: { [key: string]: Attachment[] } = {};
  public mediaFormControl: FormControl = new FormControl();
  public signGroups = signGroups;

  public empty = false;

  constructor(
    private signsAttachmentsService: SignsAttachmentsService,
    @Inject(MAT_DIALOG_DATA) public data: SignMediaSelectionDialogData,
    public dialogRef: MatDialogRef<SignMediaSelectionModalComponent>,
    public loadingSpinnerService: LoadingSpinnerService,
    public dataSrcService: DataSrcService
  ) {}

  ngOnInit() {
    this.attachments = [...this.getfilteredAttachments()];
    this.empty = this.attachments.length === 0 ? true : false;
    this.metadataToAttachmentsMap = { ...this.sortByMetadata(this.attachments) };
  }

  sortByMetadata(attachments: Attachment[]) {
    let map: { [key: string]: Attachment[] } = {};
    for (let attachment of attachments) {
      const sign = this.signsAttachmentsService.getSignFromMetadata(
        JSON.parse(attachment['metadata'])
      );
      const friendlyName = this.signGroups[sign.toUpperCase()].name;
      if (friendlyName) {
        map[friendlyName] = [...(map[friendlyName] || []), attachment];
      }
    }
    return map;
  }

  getfilteredAttachments() {
    let filtered = [];
    for (let attachment of this.signsAttachmentsService.attachmentsFormControl.value) {
      const metadata = JSON.parse(attachment['metadata']);
      if (
        metadata &&
        this.matchingEyeLocation(
          metadata,
          this.data.metadata['sign'].substring(0, this.data.metadata['sign'].indexOf('_')) as Eye
        ) &&
        metadata.sign !== this.data.metadata.sign
      ) {
        filtered.push(attachment);
      }
    }
    return filtered;
  }

  matchingEyeLocation(metadata: { [key: string]: string }, eye: Eye) {
    if (!metadata.sign) {
      return false;
    }
    if (eye === metadata.sign.substring(0, metadata.sign.indexOf('_'))) return true;
    return false;
  }

  public static open(
    matDialog: MatDialog,
    metadata: { [key: string]: string },
    signFormControl: FormControl
  ) {
    return matDialog.open<SignMediaSelectionModalComponent, SignMediaSelectionDialogData>(
      SignMediaSelectionModalComponent,
      {
        width: '1000px',
        maxWidth: '75vw',
        data: {
          metadata,
          signFormControl
        }
      }
    );
  }

  public getFileName(attachment: Attachment): string {
    return this.dataSrcService.getFileName(attachment);
  }
}

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[csiNiceDateInput]'
})
export class NiceDateInputDirective {
  // FIXME: This doesn't work in Firefox and probably doesn't work in Edge either

  /**
   * This method listens for input events (user modifying input field) on its
   * host input element and automatically inserts or delete's /'s as necessary.
   *
   * Right now only insertText events and deleteContentBackward events are
   * supported. In other words there is no support for the user pasting into
   * the input box or deleting with the delete key (forward delete).
   */
  @HostListener('input', ['$event']) niceTypeDate(
    event: UIEvent & { inputType: string; data: string }
  ) {
    const inputElement = event.target as HTMLInputElement;
    if (event.data === '/' || !this.isNumber(event.data, 0) || inputElement.value.length > 10) {
      inputElement.value = inputElement.value.slice(0, -1);
      return;
    }

    if (inputElement.value.length === 2 || inputElement.value.length === 5) {
      if (event.inputType === 'insertText') {
        inputElement.value += '/';
      } else if (event.inputType === 'deleteContentBackward') {
        inputElement.value = inputElement.value.slice(0, -1);
      }
    }
  }

  private isNumber(str: string, index: number): boolean {
    return '0123456789'.indexOf(str[index]) > -1;
  }
}

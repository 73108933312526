import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ModalSymptomCustomModalOptions } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEventPattern, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { FloatLabelType } from '@angular/material/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

function isNumberValidator(control: AbstractControl): ValidationErrors | null {
  return typeof control.value === 'number' ? null : { notNumeric: true };
}

@Component({
  selector: 'csi-quantitative-corneal-sensation',
  templateUrl: './quantitative-corneal-sensation-modal.component.html',
  styleUrls: ['./quantitative-corneal-sensation-modal.component.css']
})
export class QuantitativeCornealSensationModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit, OnDestroy {
  public internalFormGroup = new FormGroup({
    superior: new FormControl('', [isNumberValidator, Validators.min(0)]),
    nasal: new FormControl('', [isNumberValidator, Validators.min(0)]),
    inferior: new FormControl('', [isNumberValidator, Validators.min(0)]),
    temporal: new FormControl('', [isNumberValidator, Validators.min(0)]),
    central: new FormControl('', [isNumberValidator, Validators.min(0)])
  });

  /*
   * TODO: Use fromEvent() when addEventListener on MediaQueryList is supported by Safari
   *       see https://gitlab.com/clarity-lv/dry-eye-frontend/merge_requests/88#note_184166825
   */
  public readonly shouldFloatLabel$: Observable<FloatLabelType> = fromEventPattern<FloatLabelType>(
    handler => {
      const mediaQuery = window.matchMedia('(max-width: 400px)');
      const mediaQueryListener = (event: MediaQueryListEvent | MediaQueryList) => {
        handler(event.matches ? 'always' : 'auto');
      };

      // tslint:disable-next-line:deprecation
      mediaQuery.addListener(mediaQueryListener);
      mediaQueryListener(mediaQuery);

      // tslint:disable-next-line:deprecation
      return mediaQuery.removeListener.bind(mediaQuery, mediaQueryListener);
    },
    (_, removeListener) => {
      removeListener();
    }
  ).pipe(
    untilDestroyed(this),
    shareReplay(1)
  );

  constructor(
    public dialogRef: MatDialogRef<QuantitativeCornealSensationModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    this.internalFormGroup.statusChanges.subscribe(() => {
      if (this.internalFormGroup.invalid) {
        this.fixValidationErrors();
      }
    });

    this.loadInternalForm();
  }

  ngOnDestroy() {}

  submit() {
    if (this.allValuesFilledAndValid) {
      this.saveInternalForm();
      this.formGroup.get(this.formKey).setValue(this.value);

      this.dialogRef.close();
    }
  }

  public get value(): string {
    const averageGrade = this.averageGrade;
    if (averageGrade >= 6) {
      return 'normal';
    } else if (averageGrade > 4) {
      return 'mild';
    } else if (averageGrade > 2) {
      return 'moderate';
    } else {
      return 'severe';
    }
  }

  public get averageGrade(): number {
    return (
      Math.round(
        (Object.keys(this.internalFormGroup.controls)
          .map(controlKey => this.internalFormGroup.controls[controlKey].value)
          .reduce((sum, value) => value + sum) /
          5) *
          100
      ) / 100
    );
  }

  public get allValuesFilledAndValid(): boolean {
    return (
      Object.keys(this.internalFormGroup.controls)
        .map(controlKey => this.internalFormGroup.controls[controlKey])
        .every(control => control.value !== undefined && control.value !== null) &&
      this.internalFormGroup.valid
    );
  }

  public getAllPossibleSymptomControlKeys(): string[] {
    const keys: string[] = super.getAllPossibleSymptomControlKeys();
    const internalKeys: string[] = Object.keys(this.internalFormGroup.controls).map(controlKey =>
      this.getExternalKeyFromInternalKey(controlKey)
    );
    return keys.concat(internalKeys);
  }

  private fixValidationErrors() {
    Object.keys(this.internalFormGroup.controls)
      .map(controlKey => this.internalFormGroup.controls[controlKey])
      .filter(control => control.invalid && control.value !== '')
      .forEach(invalidControl => {
        if (invalidControl.value === null) {
          invalidControl.setValue('');
        } else {
          invalidControl.setValue(0);
        }
      });
  }

  private saveInternalForm() {
    Object.keys(this.internalFormGroup.controls).forEach(controlkey => {
      const internalControl = this.internalFormGroup.get(controlkey);

      const externalKey = this.getExternalKeyFromInternalKey(controlkey);
      const externalControl = this.formGroup.get(externalKey);

      if (externalControl) {
        externalControl.setValue(internalControl.value);
      } else {
        this.formGroup.addControl(externalKey, new FormControl(internalControl.value));
      }
    });
  }

  private loadInternalForm() {
    Object.keys(this.internalFormGroup.controls).forEach(controlKey => {
      const internalControl = this.internalFormGroup.get(controlKey);

      const externalKey = this.getExternalKeyFromInternalKey(controlKey);
      const externalControl = this.formGroup.get(externalKey);

      if (externalControl) {
        internalControl.setValue(externalControl.value);
      }
    });

    if (this.isDisabled) {
      this.internalFormGroup.disable();
    }
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Logger } from 'aws-amplify';
import { BehaviorSubject, defer, from, iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../api/error-handler.service';
import { CognitoUserInfo } from './models/cognito-user-info.model';
import { LoginError } from './models/error-models/LoginError';
import { ResendVerificationError } from './models/error-models/ResendVerificationError';
import { ResetPasswordError } from './models/error-models/ResetPasswordError';
import { ResetPasswordRequestError } from './models/error-models/ResetPasswordRequestError';
import { SignupError } from './models/error-models/SignupError';
import { VerificationError } from './models/error-models/VerificationError';

function errorResponse(mappedError: any, defaultError: any) {
  return throwError(mappedError !== undefined ? mappedError : defaultError);
}

export type MFAType = 'SMS' | 'TOTP' | 'NOMFA';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private logger = new Logger('AuthenticationService');

  private loggedInUser$: BehaviorSubject<CognitoUser> = new BehaviorSubject(null);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private errorHandlerService: ErrorHandlerService,
    private router: Router
  ) {}

  public checkUserCurrentlyAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((cognitoUser: any) => {
        return !!cognitoUser;
      }),
      catchError(() => of(false))
    );
  }

  public getCurrentAuthenticatedUser(): Observable<CognitoUser> {
    return from(Auth.currentAuthenticatedUser()).pipe(catchError(() => of(null)));
  }

  public refreshSession(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.loggedInUser$.getValue().getSession((err, session) => {
        this.loggedInUser$.getValue().refreshSession(session.getRefreshToken(), () => {
          resolve(true);
        });
      });
    });
  }
  public signUp(
    email: string,
    password: string,
    phone: string,
    redirectTo: string
  ): Observable<any> {
    email = email.toLowerCase();

    const params = {
      username: email,
      password: password,
      attributes: {
        'custom:origin': this.document.location.origin,
        'custom:redirect_to': redirectTo
      }
    };
    if (phone) {
      params.attributes['phone_number'] = phone;
    }
    return from(Auth.signUp(params)).pipe(
      catchError(error =>
        errorResponse(
          {
            UsernameExistsException: SignupError.UserAlreadyExists
          }[error.code],
          SignupError.Unknown
        )
      )
    );
  }

  public verifySignUp(email: string, code: string): Observable<any> {
    email = email.toLowerCase();
    return from(Auth.confirmSignUp(email, code)).pipe(
      catchError(err =>
        errorResponse(
          {
            ExpiredCodeException: VerificationError.ExpiredCode,
            NotAuthorizedException: VerificationError.AlreadyConfirmed,
            CodeMismatchException: VerificationError.InvalidCode,
            LimitExceededException: VerificationError.LimitExceeded,
            UserNotFoundException: VerificationError.UserNotFound
          }[err.code],
          VerificationError.Unknown
        )
      ),
      tap(() => {
        window.location.reload();
      })
    );
  }

  public verifySignIn(
    user: CognitoUser | any,
    code: string,
    mfaType: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
  ): Observable<any> {
    return from(Auth.confirmSignIn(user, code, mfaType)).pipe(
      tap(()=> window.location.reload()),
      catchError(err =>
        errorResponse(
          {
            ExpiredCodeException: VerificationError.ExpiredCode,
            NotAuthorizedException: VerificationError.AlreadyConfirmed,
            CodeMismatchException: VerificationError.InvalidCode,
            LimitExceededException: VerificationError.LimitExceeded,
            UserNotFoundException: VerificationError.UserNotFound
          }[err.code],
          VerificationError.Unknown
        )
      )
    );
  }

  public resendVerificationEmail(email: string): Observable<any> {
    email = email.toLowerCase();
    return from(Auth.resendSignUp(email)).pipe(
      catchError(() => throwError(ResendVerificationError.Unknown))
    );
  }

  public login(email: string, password: string): Observable<CognitoUser> {
    email = email.toLowerCase();
    return from(Auth.signIn(email, password)).pipe(
      catchError(err => {
        return errorResponse(
          {
            NotAuthorizedException: LoginError.NotAuthorized,
            NetworkError: LoginError.NetworkError,
            TooManyAttempts: LoginError.TooManyAttempts,
            UserDisabled: LoginError.UserDisabled,
            UserNotConfirmedException: LoginError.UserNotConfirmed,
            UserNotFoundException: LoginError.UserNotFound
          }[
            err.message === 'Password attempts exceeded'
              ? 'TooManyAttempts'
              : err.message === 'User is disabled.'
              ? 'UserDisabled'
              : err.message === 'User does not exist.'
              ? 'UserNotFoundException'
              : err.code
          ],
          LoginError.Unknown
        );
      }),
      mergeMap((cognitoUser: CognitoUser) =>
        // Handles users created via backend DryEyeCreateUser lambda where temp password is created
        // TODO: Force user to change password rather than keeping temp password
        iif(
          () =>
            cognitoUser['challengeName'] &&
            cognitoUser['challengeName'] === 'NEW_PASSWORD_REQUIRED',
          defer(() =>
            from(
              Auth.completeNewPassword(
                cognitoUser,
                password,
                cognitoUser['challengeParam']['requiredAttributes']
              )
            )
          ),
          defer(() => of(cognitoUser))
        )
      ),
      tap((cognitoUser: CognitoUser) => {
        try {
          // TODO Issue auth state and logged in email address via BehaviourSubject,
          // TODO so other services can subscribe and configure themselves when user logs in
          window._mfq.push(['identify', email]);
          this.errorHandlerService.configureSentryScope(email);
        } catch (error) {
          this.logger.warn('Ignoring mouseflow/sentry error', error);
        }
        if (cognitoUser['signInUserSession']) {
          Auth.updateUserAttributes(cognitoUser, {
            'custom:origin': this.document.location.origin
          });
        }
        if(!cognitoUser['challengeName'])
            window.location.reload();
      })
    );
  }

  public sendPasswordResetEmail(email: string): Observable<any> {
    email = email.toLowerCase();
    return from(Auth.forgotPassword(email)).pipe(
      catchError(err =>
        errorResponse(
          {
            UserNotFoundException: ResetPasswordRequestError.UserNotFound
          }[err.code],
          ResetPasswordRequestError.Unknown
        )
      )
    );
  }

  public resetPassword(email: string, code: string, password: string): Observable<any> {
    email = email.toLowerCase();
    return from(Auth.forgotPasswordSubmit(email, code, password)).pipe(
      catchError(err =>
        errorResponse(
          {
            ExpiredCodeException: ResetPasswordError.ExpiredCode,
            NotAuthorizedException: ResetPasswordError.CodeAlreadyUsed,
            CodeMismatchException: ResetPasswordError.InvalidCode
          }[err.code],
          ResetPasswordError.Unknown
        )
      )
    );
  }

  public logout(): Observable<any> {
    return from(Auth.signOut()).pipe(tap(() => this.router.navigate([''])));
  }

  public getCognitoUserInfo(): Promise<CognitoUserInfo> {
    return Auth.currentUserInfo().then(userInfo => {
      return userInfo as CognitoUserInfo;
    });
  }

  public getJwtToken(): Promise<string> {
    return Auth.currentSession().then(session => {
      return session.getIdToken().getJwtToken();
    });
  }

  public getCurrentCredentials(): Promise<ICredentials> {
    return Auth.currentCredentials();
  }

  public setLoggedInUser(loggedInUser: CognitoUser) {
    this.loggedInUser$.next(loggedInUser);
  }

  public getLoggedInUser(): Observable<CognitoUser> {
    return this.loggedInUser$.pipe(take(1), shareReplay());
  }

  private updateLoggedInUser() {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser => {
      this.loggedInUser$.next(cognitoUser);
    });
  }

  public setupTOTP(): Observable<string> {
    return from(Auth.setupTOTP(this.loggedInUser$.getValue())).pipe(
      map(code => {
        return (
          'otpauth://totp/AWSCognito:' +
          this.loggedInUser$.getValue()['username'] +
          '?secret=' +
          code +
          '&issuer=' +
          'CSIDryEye'
        );
      })
    );
  }

  public verifyTotp(challengeAnswer): Observable<CognitoUserSession> {
    return from(Auth.verifyTotpToken(this.loggedInUser$.getValue(), challengeAnswer));
  }

  public setPreferredMFA(mfaType: MFAType): Observable<string> {
    return from(Auth.setPreferredMFA(this.loggedInUser$.getValue(), mfaType)).pipe(
      map(status => {
        this.updateLoggedInUser();
        return status;
      })
    );
  }

  public updateUserAttribute(attributeName: string, value: string) {
    this.loggedInUser$
      .getValue()
      .updateAttributes([{ Name: attributeName, Value: value }], () => {});
  }

  public updatePhoneNumber(phoneNumber: string): Observable<string> {
    return from(
      Auth.updateUserAttributes(this.loggedInUser$.getValue(), { phone_number: phoneNumber })
    ).pipe(
      map(status => {
        this.updateLoggedInUser();
        return status;
      })
    );
  }

  public sendPhoneNumberVerification(): Observable<void> {
    return from(Auth.verifyCurrentUserAttribute('phone_number'));
  }

  public verifyPhoneNumber(verificationCode: string): Observable<string> {
    return from(Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode));
  }

  public getIdentityId(): Observable<string> {
    return from(this.getCurrentCredentials()).pipe(map(credentials => credentials.identityId));
  }
}

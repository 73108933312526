import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'csi-totp-qr-code-modal',
  templateUrl: './totp-qr-code-modal.component.html',
  styleUrls: ['./totp-qr-code-modal.component.css']
})
export class TotpQrCodeModalComponent implements OnInit {
  private totpVerificationError = {
    EnableSoftwareTokenMFAException: 'The code you have entered is invalid.'
  };

  public totpVerified = false;
  public qrData = '';
  public secretKey = '';
  public errorMessage: string;
  public matSpinnerOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Verify',
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  public formGroup = new FormGroup({
    verificationCode: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern('^[0-9]*$')
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: string,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<TotpQrCodeModalComponent, boolean>
  ) {
    this.qrData = data;
    this.secretKey = this.qrData.substring(
      this.qrData.indexOf('secret=') + 7,
      this.qrData.indexOf('&issuer')
    );
  }

  ngOnInit() {}

  public submit() {
    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;
      this.authenticationService.verifyTotp(this.formGroup.value.verificationCode).subscribe(
        () => {
          this.matSpinnerOptions.active = false;
          this.totpVerified = true;
          this.authenticationService.updateUserAttribute('custom:totp_verified', 'true');

          setTimeout(() => this.dialogRef.close(true), 1000);
        },
        err => {
          this.errorMessage = !!this.totpVerificationError[err.name]
            ? this.totpVerificationError[err.name]
            : err.message;
          this.matSpinnerOptions.active = false;
        }
      );
    }
  }

  clearServerErrors() {
    this.errorMessage = undefined;
  }
}

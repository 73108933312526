import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ErrorStateMatcher, MatCheckboxChange, MatSelectChange } from '@angular/material';
import cloneDeep from 'lodash-es/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as Papa from 'papaparse/papaparse.js';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormErrorScrollingService } from '../../../shared/form-error-scrolling/form-error-scrolling.service';
import { PatientFormGroup } from '../../../shared/patient-form/patient-form.model';
import { CSVPatientsFormGroup } from './csv-patients-form-group.model';
import {
  ImportWizardFileReceivedService,
  PatientAttribute
} from './import-wizard-file-received.service';

@Component({
  selector: 'csi-import-wizard-file-received',
  templateUrl: './import-wizard-file-received.component.html',
  styleUrls: ['./import-wizard-file-received.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportWizardFileReceivedComponent implements OnDestroy, OnInit {
  @Input() file$: Observable<File[]>;
  @Input() csvPatientsFormGroup: CSVPatientsFormGroup;
  @Output() successfulValidation: EventEmitter<null> = new EventEmitter();
  @Output() goBack: EventEmitter<null> = new EventEmitter();
  @Output() cancel: EventEmitter<null> = new EventEmitter();
  @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef;
  protected readonly patientAttributeSynonyms = Array.from(
    this.importWizardFileReceivedService.patientAttributeMappings.keys()
  );
  public readonly hasHeader = true;
  protected readonly PatientAttribute = PatientAttribute;
  protected patientsColumns: string[];
  public patients: string[][];
  protected matSelectors: FormGroup;
  protected validPatientValues: boolean[][] = [];
  public matErrorMissingColumn: string;
  public matErrorInvalidValue: string;
  protected errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => !!this.matErrorMissingColumn
  };
  public csvValidationError: string | null;
  private rawCSVData: string[][];

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private importWizardFileReceivedService: ImportWizardFileReceivedService,
    private formErrorScrollingService: FormErrorScrollingService
  ) {}

  ngOnInit() {
    this.file$
      .pipe(
        switchMap(files =>
          files[0] ? this.importWizardFileReceivedService.removeCharFromFile(files[0]) : of()
        ),
        untilDestroyed(this)
      )
      .subscribe(modifiedFile => {
        if (!!modifiedFile) {
          Papa.parse(modifiedFile, {
            complete: results => {
              this.rawCSVData = results.data.filter(data => {
                return (
                  data
                    .toString()
                    .replace(/,/g, '')
                    .trim() !== ''
                );
              });
              this.processCSVResults(this.hasHeader);
            }
          });
        }
      });
  }

  ngOnDestroy() {}

  public fileHeaderOptionChange(containsHeader: MatCheckboxChange) {
    this.processCSVResults(containsHeader.checked);
  }

  public validatePatients() {
    this.validPatientValues = this.importWizardFileReceivedService.getValidEntries(
      this.csvPatientsFormGroup.controls.patients.controls as PatientFormGroup[],
      this.matSelectors
    );

    this.csvPatientsFormGroup.updateThisAndDescendants();
    this.setMatErrors();
    if (
      this.csvPatientsFormGroup.controls.patients.valid &&
      !this.csvValidationError &&
      this.matSelectors.valid
    ) {
      this.successfulValidation.emit(null);
    } else if (!!this.tableContainer) {
      this.formErrorScrollingService.scrollToFirstInvalidControlInView(
        this.tableContainer,
        undefined,
        { isHostMatTable: true, isHostScrollable: true }
      );
    }
  }

  protected changeMatSelector(matSelectChange: MatSelectChange, columnIndex: number) {
    this.importWizardFileReceivedService.removeDuplicateOptions(
      matSelectChange.value,
      columnIndex,
      this.matSelectors
    );
    this.mapCSVPatientsToPatientFormGroups();
  }

  private setMatErrors() {
    this.matErrorInvalidValue = '';
    this.matErrorMissingColumn = '';
    if (
      this.matSelectors &&
      this.matSelectors.errors &&
      this.matSelectors.errors['missingRequired'] != null
    ) {
      this.matErrorMissingColumn =
        'Patient Last Name, First Name, and one of Patient Email, Phone, or Birthday are required in the header.';
    }
    if (
      this.csvPatientsFormGroup.controls.patients.errors &&
      this.csvPatientsFormGroup.controls.patients.errors['invalidColumns'] != null
    ) {
      this.matErrorInvalidValue = 'Cells contain invalid values. Please fix before continuing.';
    }
  }

  private processCSVResults(header: boolean) {
    const csvDataClone = cloneDeep(this.rawCSVData).filter(
      row => row.length !== 1 || row[0] !== ''
    );
    this.patients = csvDataClone.splice(header ? 1 : 0);
    this.patientsColumns = header
      ? csvDataClone[0]
      : this.patients[0].map((val, index) => index.toString());
    this.csvValidationError = this.importWizardFileReceivedService.csvValidator(
      this.patients,
      this.patientsColumns
    );
    this.changeDetectorRef.markForCheck();

    if (this.csvValidationError) {
      return;
    }
    this.buildMatSelectorForms();
    this.validPatientValues = [];
    this.mapCSVPatientsToPatientFormGroups();
  }

  private mapCSVPatientsToPatientFormGroups() {
    this.csvPatientsFormGroup.reset();
    while (this.csvPatientsFormGroup.controls.patients.controls.length !== 0) {
      this.csvPatientsFormGroup.controls.patients.controls.pop();
    }
    this.importWizardFileReceivedService
      .csvDataToPatientFormGroups(this.patients, this.patientsColumns, this.matSelectors)
      .subscribe(patients => {
        this.csvPatientsFormGroup.controls.patients.controls.push(...patients);
        this.csvPatientsFormGroup.updateThisAndDescendants();
      });
  }

  private buildMatSelectorForms() {
    const controlsConfig = this.importWizardFileReceivedService.createControlsConfig(
      this.patientsColumns
    );
    this.matSelectors = this.formBuilder.group(controlsConfig, {
      validators: [this.importWizardFileReceivedService.createControlsValidator()]
    });
  }
}

import { noSort } from 'src/app/core/api/model-utils';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FormTemplateBuilderService } from './../../form-template-builder.service';

@Component({
  selector: 'csi-form-template-value-field-config',
  templateUrl: './form-template-value-field-config.component.html',
  styleUrls: ['./form-template-value-field-config.component.css']
})
export class FormTemplateValueFieldConfigComponent implements OnInit {
  @Input() valuesFormGroup: FormGroup;

  public newValueFormControl = new FormControl(null, this.duplicate(this.valuesFormGroup));

  public noSort = noSort;

  constructor(public formTemplateBuilderService: FormTemplateBuilderService) {}

  ngOnInit() {}



  log(a) {
    console.log(a);
  }

  addValue() {
    if (this.newValueFormControl.valid) {
      this.valuesFormGroup.addControl(
        this.newValueFormControl.value,
        new FormGroup({
          label: new FormControl(this.newValueFormControl.value),
          showSubField: new FormControl(),
          subFieldIdList: new FormControl()
        })
      );
    }
  }

  duplicate(itemsFormGroup: FormGroup): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      if (
        itemsFormGroup &&
        itemsFormGroup.value &&
        itemsFormGroup.value.length > 0 &&
        Object.values(itemsFormGroup as FormGroup).some(
          (item: FormControl) => item.value.toLowerCase() === control.value.toLowerCase()
        )
      ) {
        return { duplicate: true };
      }
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireRequest } from 'src/API';

@Pipe({
  name: 'questionnaireStatus'
})
export class QuestionnaireStatusPipe implements PipeTransform {
  private twoWeeksBack = this.getDaysBackInMilliSeconds(14);

  transform(questionnaire: QuestionnaireRequest): 'Completed' | 'Expired' | 'Incomplete' {
    const createdAtDate = new Date(questionnaire.createdAt).getTime();

    if (questionnaire.completedAt) {
      return 'Completed';
    }
    if (!questionnaire.completedAt && createdAtDate - this.twoWeeksBack <= 0) {
      return 'Expired';
    }
    return 'Incomplete';
  }

  getDaysBackInMilliSeconds(days: number): number {
    const oneDayInMilliSeconds = 86400000;
    const daysInMilliSeconds = days * oneDayInMilliSeconds;

    const dateToday = new Date();
    dateToday.setHours(0);
    dateToday.setMinutes(0);
    dateToday.setMilliseconds(0);

    return dateToday.getTime() - daysInMilliSeconds;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreatmentFilter } from '../../../shared/consult-forms/dry-eye-forms/dry-eye-assessment-form/treatment-filters/treatment-filter.model';

@Component({
  selector: 'csi-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.css']
})
export class FilterChipsComponent {
  @Input() activeFilters: TreatmentFilter[];
  @Input() allFilters: TreatmentFilter[];
  @Output() selectedFilters: EventEmitter<TreatmentFilter[]> = new EventEmitter<
    TreatmentFilter[]
  >();

  onChange(selectedFilters: TreatmentFilter[]) {
    this.selectedFilters.emit(selectedFilters);
  }
}

import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  QuestionAnswer,
  QuestionGroup,
  QuestionnaireFormBuilder
} from '../simple-questionnaire/questionnaire-form-builder';

const bottomLabels: string[] = [
  'UNKNOWN',
  'NEVER',
  'SOMETIMES',
  'HALF THE TIME',
  'USUALLY',
  'ALWAYS'
];

export const osdiQuestionGroups: QuestionGroup[] = [
  {
    id: 'generalSymptoms',
    title: 'Have you experienced any of the following ***during the last week***?',
    questions: [
      {
        id: 'lightSensitivity',
        title: 'Eyes that are sensitive to light?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: false
      },
      {
        id: 'grittyFeeling',
        title: 'Eyes that feel gritty?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: false
      },
      {
        id: 'painOrSoreness',
        title: 'Painful or sore eyes?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: false
      },
      {
        id: 'blurredVision',
        title: 'Blurred vision?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: false
      },
      {
        id: 'poorVision',
        title: 'Poor vision?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: false
      }
    ]
  },
  {
    id: 'limitedActivities',
    title:
      'Have your eyes limited you in performing any of the following ***during the last week***?',
    questions: [
      {
        id: 'reading',
        title: 'Reading?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      },
      {
        id: 'drivingAtNight',
        title: 'Driving at night?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      },
      {
        id: 'workingWithScreens',
        title: 'Working with a computer or ATM?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      },
      {
        id: 'watchingTV',
        title: 'Watching TV?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      }
    ]
  },
  {
    id: 'weatherExacerbations',
    title:
      'Have your eyes felt uncomfortable in any of the following situations ***during the last week***?',
    questions: [
      {
        id: 'windyConditions',
        title: 'Windy conditions?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      },
      {
        id: 'lowHumidity',
        title: 'Places with low humidity (very dry)?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      },
      {
        id: 'airConditioned',
        title: 'Areas that are air conditioned?',
        minValue: 0,
        maxValue: 4,
        labels: bottomLabels,
        showNotApplicable: true
      }
    ]
  }
];

interface OSDIGeneralSymptomAnswers {
  blurredVision: QuestionAnswer;
  grittyFeeling: QuestionAnswer;
  lightSensitivity: QuestionAnswer;
  painOrSoreness: QuestionAnswer;
  poorVision: QuestionAnswer;
}

interface OSDILimitedActivitiesAnswers {
  drivingAtNight: QuestionAnswer;
  reading: QuestionAnswer;
  watchingTV: QuestionAnswer;
  workingWithScreens: QuestionAnswer;
}

interface OSDIWeatherExacerbationsAnswers {
  airConditioned: QuestionAnswer;
  lowHumidity: QuestionAnswer;
  windyConditions: QuestionAnswer;
}

interface OSDIAnswers {
  generalSymptoms: OSDIGeneralSymptomAnswers;
  limitedActivities: OSDILimitedActivitiesAnswers;
  weatherExacerbations: OSDIWeatherExacerbationsAnswers;
}

const totalOSDIQuestions = 12;

const logger = new Logger('OSDIService');

const osdiScoreCalculator = (value: OSDIAnswers): number => {
  let numberOfQuestionsAnswered = 0;
  let sumOfAnswers = 0;
  let totalQuestions = 0;
  Object.keys(value).forEach(key1 =>
    Object.keys(value[key1]).forEach(key2 => {
      totalQuestions++;
      if (!value[key1][key2].notApplicable) {
        numberOfQuestionsAnswered++;
        sumOfAnswers += value[key1][key2].answer;
      }
    })
  );

  if (totalQuestions !== totalOSDIQuestions) {
    throw new Error(
      `Number of OSDI Questions (${totalQuestions}) do no match total number of expected questions for OSDI (${totalOSDIQuestions}).`
    );
  }

  const score = (sumOfAnswers * 100) / (numberOfQuestionsAnswered * 4);
  logger.debug(
    `Calculated OSDI Score of ${score} with:`,
    sumOfAnswers,
    numberOfQuestionsAnswered,
    value
  );
  return Math.round(score);
};

@Injectable()
export class OSDIService extends QuestionnaireFormBuilder {
  title = 'OSDI';
  questionnaireKey = 'osdi';

  constructor(formBuilder: FormBuilder) {
    super(osdiQuestionGroups, formBuilder, osdiScoreCalculator);
  }
}

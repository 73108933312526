import { EyeDrop } from '../../../../../../core/remote-json/treatments/treatments.service';
import { TreatmentFilter } from '../treatment-filter.model';

export class ByPreservatives implements TreatmentFilter {
  private _name = 'Preservative Free';

  public selectedByDefault: false;

  get name(): string {
    return this._name;
  }

  filter(eyeDrop: EyeDrop): boolean {
    return !eyeDrop.preservatives || eyeDrop.preservatives.length === 0;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {
  transform(value: any): any {
    const currentDate = new Date();
    const compareTime = new Date(value).getTime();
    const timeDiff = currentDate.getTime() - compareTime;

    // Convert the time difference to days
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }
}

import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  QuestionAnswer,
  QuestionGroup,
  QuestionnaireFormBuilder
} from '../simple-questionnaire/questionnaire-form-builder';

const bottomFrequencyLabels: string[] = ['UNKNOWN', 'NEVER', 'SOMETIMES', 'OFTEN', 'CONSTANT'];
const bottomSeverityLabels: string[] = [
  'UNKNOWN',
  'NO PROBLEMS',
  'TOLERABLE',
  'UNCOMFORTABLE',
  'BOTHERSOME',
  'INTOLERABLE'
];

const ignoreList = {
  patientEnteredMedications: true
};

export const speedSliderQuestionGroups: QuestionGroup[] = [
  {
    id: 'frequencyOfSymptoms',
    title: 'Report the FREQUENCY of your symptoms',
    questions: [
      {
        id: 'drynessGrittinessScratchiness',
        title: 'Dryness, Grittiness or Scratchiness',
        minValue: 0,
        maxValue: 3,
        labels: bottomFrequencyLabels,
        showNotApplicable: false
      },
      {
        id: 'sorenessIrritation',
        title: 'Soreness or Irritation',
        minValue: 0,
        maxValue: 3,
        labels: bottomFrequencyLabels,
        showNotApplicable: false
      },
      {
        id: 'burningWatering',
        title: 'Burning or Watering',
        minValue: 0,
        maxValue: 3,
        labels: bottomFrequencyLabels,
        showNotApplicable: false
      },
      {
        id: 'eyeFatigue',
        title: 'Eye Fatigue',
        minValue: 0,
        maxValue: 3,
        labels: bottomFrequencyLabels,
        showNotApplicable: false
      }
    ]
  },
  {
    id: 'severityOfSymptoms',
    title: 'Report the SEVERITY of your symptoms',
    questions: [
      {
        id: 'drynessGrittinessScratchiness',
        title: 'Dryness, Grittiness or Scratchiness',
        minValue: 0,
        maxValue: 4,
        labels: bottomSeverityLabels,
        showNotApplicable: false
      },
      {
        id: 'sorenessIrritation',
        title: 'Soreness or Irritation',
        minValue: 0,
        maxValue: 4,
        labels: bottomSeverityLabels,
        showNotApplicable: false
      },
      {
        id: 'burningWatering',
        title: 'Burning or Watering',
        minValue: 0,
        maxValue: 4,
        labels: bottomSeverityLabels,
        showNotApplicable: false
      },
      {
        id: 'eyeFatigue',
        title: 'Eye Fatigue',
        minValue: 0,
        maxValue: 4,
        labels: bottomSeverityLabels,
        showNotApplicable: false
      }
    ]
  }
];

const logger = new Logger('SPEEDService');

interface SPEEDAnswerGroup {
  drynessGrittinessScratchiness: QuestionAnswer;
  sorenessIrritation: QuestionAnswer;
  burningWatering: QuestionAnswer;
  eyeFatigue: QuestionAnswer;
}

interface SPEEDAnswers {
  frequencyOfSymptoms: SPEEDAnswerGroup;
  severityOfSymptoms: SPEEDAnswerGroup;
}

const speedScoreCalculator = (value: SPEEDAnswers): number => {
  let score = 0;
  // score is sum of all severity and frequency values
  Object.keys(value).forEach(key => {
    if (!ignoreList[key]) {
      Object.keys(value[key]).forEach(subkey => {
        const answer: number = value[key][subkey].answer;
        if (answer < 0) {
          const errorMsg = `Invalid answer (${answer}) passed to SPEED score calculator for [${key}][${subkey}]`;
          logger.error(errorMsg, value);
          throw new Error(errorMsg);
        }
        score += value[key][subkey].answer;
      });
    }
  });
  return score;
};

@Injectable()
export class SPEEDService extends QuestionnaireFormBuilder {
  title = 'SPEED';
  questionnaireKey = 'speed';

  constructor(formBuilder: FormBuilder) {
    super(speedSliderQuestionGroups, formBuilder, speedScoreCalculator);
  }
}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TreatmentPickerModule } from 'src/app/treatment-picker/treatment-picker.module';
import { TextFavoriteModule } from '../../logged-in-navbar/clinic-setup-modal/text-favorite/text-favorite.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { UpdateOnBlurModule } from '../directives/update-on-blur/update-on-blur.module';
import { InfoPopoverModule } from '../info-popover/info-popover.module';
import { MedicationLookupModule } from '../medication-lookup/medication-lookup.module';
import { NiceDateInputModule } from '../nice-date-input/nice-date-input.module';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { SharedModule } from '../shared.module';
import { SymptomsPipesModule } from '../symptoms/pipes/symptoms-pipes.module';
import { SymptomsModule } from '../symptoms/symptoms.module';
import { InterpretationModule } from './../interpretation/interpretation.module';
import { AssessmentFormComponent } from './assessment-form/assessment-form.component';
import { ClinicFormComponent } from './clinic-form/clinic-form.component';
import { ConsultFormsErrorStateMatcher } from './consult-forms-error-state-matcher';
import { DoctorAndClinicInfoWizardModalComponent } from './doctor-and-clinic-info-wizard-modal/doctor-and-clinic-info-wizard-modal.component';
import { DoctorFormModule } from './doctor-form/doctor-form.module';
import { DryEyeAssessmentFormComponent } from './dry-eye-forms/dry-eye-assessment-form/dry-eye-assessment-form.component';
import { DryEyeCategoryComponent } from './dry-eye-forms/dry-eye-category/dry-eye-category.component';
import { DryEyeAssessmentMethodFormComponent } from './dry-eye-forms/dry-eye-form/dry-eye-assessment-method-form/dry-eye-assessment-method-form.component';
import { DryEyeFormComponent } from './dry-eye-forms/dry-eye-form/dry-eye-form.component';
import { DryEyeFormsComponent } from './dry-eye-forms/dry-eye-forms.component';
import { DryEyeMedicalHistoryFormComponent } from './dry-eye-forms/dry-eye-medical-history-form/dry-eye-medical-history-form.component';
import { MedicalHistoryFieldsComponent } from './dry-eye-forms/dry-eye-medical-history-form/medical-history-fields/medical-history-fields.component';
import { PreviousHistoryDropdownComponent } from './dry-eye-forms/dry-eye-medical-history-form/previous-history-dropdown/previous-history-dropdown.component';
import { SymptomDurationComponent } from './dry-eye-forms/dry-eye-medical-history-form/symptom-duration/symptom-duration.component';
import { ToggleableMedicalHistoryTextFieldComponent } from './dry-eye-forms/dry-eye-medical-history-form/toggleable-medical-history-text-field/toggleable-medical-history-text-field.component';
import { DryEyeSymptomFormComponent } from './dry-eye-forms/dry-eye-symptom-form/dry-eye-symptom-form.component';
import { PreOpAnalysisComponent } from './dry-eye-forms/pre-op/pre-op-analysis/pre-op-analysis.component';
import { PreOpReportBranchComponent } from './dry-eye-forms/pre-op/pre-op-report/pre-op-report-branch/pre-op-report-branch.component';
import { PreOpReportComponent } from './dry-eye-forms/pre-op/pre-op-report/pre-op-report.component';
import { PreOpSignsComponent } from './dry-eye-forms/pre-op/pre-op-signs/pre-op-signs.component';
import { PreOpComponent } from './dry-eye-forms/pre-op/pre-op.component';
import { WeatherComponent } from './dry-eye-forms/weather/weather.component';
import { EmrIntegrationFormComponent } from './emr-integration-form/emr-integration-form.component';
import { MedicalHistoryFormComponent } from './medical-history-form/medical-history-form.component';
import { PatientFormComponent } from './patient-form/patient-form.component';
import { PatientModalComponent } from './patient-modal/patient-modal.component';
import { RealtimeMediaModalComponent } from './realtime-media-modal/realtime-media-modal.component';
import { SymptomValidatorsService } from './validators/symptom-validators/symptom-validators.service';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UpdateOnBlurModule,
    NiceDateInputModule,
    AttachmentsModule,
    SymptomsModule,
    SymptomsPipesModule,
    InfoPopoverModule,
    MedicationLookupModule,
    SharedPipesModule,
    TreatmentPickerModule,
    SharedModule,
    InterpretationModule,
    DoctorFormModule,
    MatExpansionModule,
    TextFavoriteModule,
  ],
  declarations: [
    PatientFormComponent,
    PatientModalComponent,
    MedicalHistoryFormComponent,
    AssessmentFormComponent,
    DryEyeFormComponent,
    DryEyeAssessmentFormComponent,
    DryEyeMedicalHistoryFormComponent,
    DryEyeAssessmentMethodFormComponent,
    DryEyeFormsComponent,
    DryEyeCategoryComponent,
    ClinicFormComponent,
    EmrIntegrationFormComponent,
    DoctorAndClinicInfoWizardModalComponent,
    PreviousHistoryDropdownComponent,
    ToggleableMedicalHistoryTextFieldComponent,
    WeatherComponent,
    SymptomDurationComponent,
    PreOpComponent,
    DryEyeSymptomFormComponent,
    MedicalHistoryFieldsComponent,
    PreOpSignsComponent,
    PreOpAnalysisComponent,
    PreOpReportComponent,
    PreOpReportBranchComponent,
    RealtimeMediaModalComponent
  ],
  exports: [
    PatientFormComponent,
    PatientModalComponent,
    MedicalHistoryFormComponent,
    AssessmentFormComponent,
    DryEyeFormsComponent,
    DryEyeCategoryComponent,
    ClinicFormComponent,
    EmrIntegrationFormComponent,
    DoctorAndClinicInfoWizardModalComponent,
    DoctorFormModule,
    DryEyeMedicalHistoryFormComponent,

  ],
  providers: [
    SymptomValidatorsService,
    { provide: ErrorStateMatcher, useClass: ConsultFormsErrorStateMatcher }
  ],
  entryComponents: [PreOpReportComponent, RealtimeMediaModalComponent]
})
export class ConsultFormsModule {}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreOpAnalysisService {
  public allAnalysisStateKeys: Set<string>;
  public significantAnalysisStateKeys: Set<string>;
  public inSignificantAnalysisStateKeys: Set<string>;

  public remarkableRequiredAnalysisStateKeys: Set<string>;
  public unRemarkableRequiredAnalysisStateKeys: Set<string>;

  constructor() {}
}

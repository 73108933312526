import { Component, Input } from '@angular/core';
import { Treatment } from '../../core/remote-json/treatments/treatments.service';

@Component({
  selector: 'csi-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.css']
})
export class TreatmentComponent {
  @Input() treatment: Treatment;
}

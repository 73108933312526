export class Birthday {
  private _day: number;
  private _month: number; // range of 0-11
  private _year: number;

  get day() {
    return this._day;
  }
  get month() {
    return this._month;
  }
  get year() {
    return this._year;
  }
  set day(day: number) {
    this._day = day ? day : undefined;
    this.makeDayValid();
  }
  set month(month: number) {
    if (month === 0) {
      this._month = month;
    } else {
      this._month = month ? month : undefined;
    }
    this.makeDayValid();
  }
  set year(year: number) {
    this._year = year ? year : undefined;
    this.makeDayValid();
  }
  public toDate(): Date {
    if (
      this.isNullOrUndefined(this._day) &&
      this.isNullOrUndefined(this._month) &&
      this.isNullOrUndefined(this._year)
    ) {
      return undefined;
    } else {
      return new Date(this._year, this._month, this._day);
    }
  }
  public setDate(date: Date) {
    this.year = date ? date.getFullYear() : undefined;
    this.month = date ? date.getMonth() : undefined;
    this.day = date ? date.getDate() : undefined;
  }

  private makeDayValid() {
    const maxDaysInMonth = new Date(this._year || null, this._month + 1 || 1, 0).getDate();
    if (maxDaysInMonth < this._day) {
      this._day = maxDaysInMonth;
    }
  }

  private isNullOrUndefined(value: number) {
    return value === null || value === undefined;
  }
}

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';
import { AssessmentUtils } from 'src/app/core/api/assessment-utils';
import { Assessment } from 'src/app/core/api/assessment.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import { QuestionnaireResponseModalComponent } from '../../questionnaire-response-modal/questionnaire-response-modal.component';
import { AssessmentService } from './../../../../core/api/assessment.service';
import { getQuestionnaireKeys } from './../../../../core/api/questionnaire-request-utils';
import { ClinicSetupService } from './../../../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { LayoutsService } from './../../../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { DryEyeFormsService } from './../dry-eye-forms.service';
import { DryEyeFormGroup } from './dry-eye-form.model';

@Component({
  selector: 'csi-dry-eye-form',
  templateUrl: './dry-eye-form.component.html',
  styleUrls: ['./dry-eye-form.component.scss']
})
export class DryEyeFormComponent implements OnChanges, OnInit, AfterViewInit {
  public readonly SymptomModes = SymptomModes;
  public showQuestionnaireRequestButton: boolean;
  public showSendQuestionnaireButton: boolean;
  public simpleSignKeys: string[];

  public readonly QuestionnaireType = QuestionnaireType;
  public questionnaireKeys = getQuestionnaireKeys();
  public lastCompletedLinkedQuestionnaireByTypeMap: { [key: string]: QuestionnaireRequest } = {};
  public showOptionalSymptoms: boolean;

  @Input() formGroup: DryEyeFormGroup;
  @Input() assessment: Assessment;
  @Input() onQuestionnaireLinked$: Subject<QuestionnaireRequest[]>;

  constructor(
    public assessmentService: AssessmentService,
    public schemaService: SchemaService,
    public clinicSetupService: ClinicSetupService,
    public layoutsService: LayoutsService,
    public dryEyeFormsService: DryEyeFormsService,
    public patientService: PatientService,
    private questionnaireRequestService: QuestionnaireRequestService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.showSendQuestionnaireButton =
      !AssessmentUtils.hasAssessmentRequests(this.assessment) &&
      (!this.formGroup.disabled || this.assessmentService.isViewOnly);

    this.showQuestionnaireRequestButton =
      this.assessment &&
      this.assessment.questionnaireRequests &&
      this.assessment.questionnaireRequests.items.filter(item => item.completedAt).length > 0;

    this.simpleSignKeys = this.schemaService.symptomKeys.filter(symptomMethodKey =>
      this.isSimpleSymptom(symptomMethodKey)
    );

    // Update symptom scores for Assessment methods excluding preop
    this.onQuestionnaireLinked$.subscribe((linkedQuestionnaires: QuestionnaireRequest[]) => {
      this.updateLinkedQuestionnaires(linkedQuestionnaires);
    });
    this.updateLinkedQuestionnaires();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGroup) {
      this.addSchemaVersion();
    }
  }

  updateLinkedQuestionnaires(newlyLinkedQuestionnaires?: QuestionnaireRequest[]) {
    if (newlyLinkedQuestionnaires) {
      this.lastCompletedLinkedQuestionnaireByTypeMap = this.questionnaireRequestService.getLastCompletedQuestionnairesByScore(
        newlyLinkedQuestionnaires,
        this.lastCompletedLinkedQuestionnaireByTypeMap
      );
    } else {
      const linkedQuestionnaires: QuestionnaireRequest[] = this.assessment
        ? this.assessment.questionnaireRequests.items
        : [];

      this.lastCompletedLinkedQuestionnaireByTypeMap = this.questionnaireRequestService.getLastCompletedQuestionnairesByScore(
        linkedQuestionnaires
      );
    }
    setTimeout(() => {
      this.dryEyeFormsService.updateSymptomScores(
        this.formGroup,
        this.lastCompletedLinkedQuestionnaireByTypeMap
      );
    });
  }

  ngAfterViewInit() {}

  showQuestionnaireResponse(questionnaireType: string) {
    QuestionnaireResponseModalComponent.open(
      this.dialog,
      this.lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType.toUpperCase()],
      null,
      this.patientService.patient.id,
      this.lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType.toUpperCase()].type
    );
  }

  public shouldShowSymptom(symptomKey: string): boolean {
    return (
      (this.isSimpleMode && this.isSimpleSymptom(symptomKey)) ||
      (this.isAdvancedMode && this.isAdvancedSymptom(symptomKey))
    );
  }

  public isSimpleSymptom(symptomKey: string) {
    return this.schemaService.symptomMap[symptomKey].modes.includes(SymptomModes.Simple);
  }

  public isAdvancedSymptom(symptomKey: string) {
    return this.schemaService.symptomMap[symptomKey].modes.includes(SymptomModes.Advanced);
  }

  get assessmentMethod(): SymptomModes {
    return this.formGroup.controls.assessmentMethod.value;
  }

  get isSimpleMode(): boolean {
    return this.assessmentMethod === SymptomModes.Simple;
  }

  get isAdvancedMode(): boolean {
    return this.assessmentMethod === SymptomModes.Advanced;
  }

  private addSchemaVersion() {
    if (!this.formGroup.controls['schemaVersion']) {
      this.formGroup.addControl(
        'schemaVersion',
        new FormControl(this.schemaService.schema.version)
      );
    } else {
      this.formGroup.controls['schemaVersion'].setValue(this.schemaService.schema.version);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ResetPasswordRequestError } from 'src/app/core/authentication/models/error-models/ResetPasswordRequestError';
import { AuthenticatorMode, matSpinnerOptions } from '../authenticator-modal.component';

@Component({
  selector: 'csi-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['../authenticator-modal.component.css', './request-reset-password.component.css']
})
export class RequestResetPasswordComponent implements OnInit {
  public readonly ResetPasswordRequestError = ResetPasswordRequestError;
  @Input() emailControl: FormGroup;
  @Input() authenticatorMode$: BehaviorSubject<AuthenticatorMode>;

  public formGroup: FormGroup;

  public didSendResetPasswordEmail = false;
  public loading = false;
  public didSubmit = false;
  public AuthenticatorMode = AuthenticatorMode;
  public errorResponse: ResetPasswordRequestError;
  public matSpinnerOptions = { ...matSpinnerOptions };

  constructor(private authenticationService: AuthenticationService) {
    this.matSpinnerOptions.text = 'Send Password Reset Link';
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: this.emailControl
    });
  }

  sendResetPasswordEmail() {
    this.didSubmit = true;

    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;
      this.loading = true;

      this.authenticationService
        .sendPasswordResetEmail(this.formGroup.value.email)
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          () => {
            this.didSendResetPasswordEmail = true;
          },
          (error: ResetPasswordRequestError) => {
            this.errorResponse = error;
          }
        );
    }
  }

  clearServerErrors() {
    this.errorResponse = undefined;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { ChangePasswordModalComponent } from 'src/app/shared/consult-forms/doctor-form/change-password-modal/change-password-modal.component';
import { SelectMfaMethodComponent } from 'src/app/shared/consult-forms/doctor-form/select-mfa-method/select-mfa-method.component';
import { TotpQrCodeModalComponent } from 'src/app/shared/consult-forms/doctor-form/select-mfa-method/totp-qr-code-modal/totp-qr-code-modal.component';
import { SharedModule } from '../../shared.module';
import { SaveVerifyPhoneComponent } from '../doctor-form/update-phone/update-phone.component';
import { VerifyPhoneModalComponent } from '../doctor-form/update-phone/verify-phone-modal/verify-phone-modal.component';
import { DoctorFormComponent } from './doctor-form.component';

@NgModule({
  entryComponents: [
    TotpQrCodeModalComponent,
    VerifyPhoneModalComponent,
    ChangePasswordModalComponent
  ],
  imports: [CommonModule, SharedModule, QRCodeModule],
  declarations: [
    SelectMfaMethodComponent,
    TotpQrCodeModalComponent,
    SaveVerifyPhoneComponent,
    VerifyPhoneModalComponent,
    DoctorFormComponent,
    ChangePasswordModalComponent
  ],
  exports: [DoctorFormComponent]
})
export class DoctorFormModule {}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TreatmentTemplateViewModalComponent } from '../../treatment-template/treatment-template-view-modal/treatment-template-view-modal.component';
import { TreatmentTemplateService } from '../../treatment-template/treatment-template.service';

@Component({
  selector: 'csi-treatment-template-select',
  templateUrl: './treatment-template-select.component.html',
  styleUrls: ['./treatment-template-select.component.css']
})
export class TreatmentTemplateSelectComponent implements OnInit {
  @Input() treatmentTemplateFormControl: FormControl;
  @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;

  constructor(
    public dialog: MatDialog,
    public treatmentTemplateService: TreatmentTemplateService
  ) {}

  ngOnInit() {}

  viewItemDetails(treatmentTemplates, isClickable) {
    this.dialog
      .open(TreatmentTemplateViewModalComponent, {
        data: {
          treatmentTemplates,
          isClickable,
          filterHidden: true
        }
      })
      .afterClosed()
      .subscribe(template => {
        if (template) {
          this.treatmentTemplateFormControl.setValue(template);
        }
      });
  }

  delete() {
    this.treatmentTemplateFormControl.setValue(null);
  }
}

export const formTemplateGenericSort = (
  a: { value: { sort: number } },
  b: { value: { sort: number } }
) => {
  return a.value.sort - b.value.sort;
};

export enum Compare {
  equals = 'equals',
  lessThan = '<',
  lessThanOrEqual = '<=',
  greaterThan = '>',
  greaterThanOrEqual = '>='
}

export enum TriggerGroupType {
  and = 'and',
  or = 'or'
}

export interface Trigger {
  key: string;
  compareValue: any;
  compare: Compare;
}

export interface TriggerGroup {
  type: TriggerGroupType;
  triggers: Trigger[];
}

export interface FormTemplateGroup {
  title: string;
  nextLabel: string;
  formFields: { [key: string]: FormFieldConfig };
  sort?: number;
}

export interface FormFieldConfig {
  label: string;
  typeConfig: FormFieldTypeConfig;
  required?: boolean;
  triggerGroups?: TriggerGroup[];
  sort?: number;
}

export enum TextInputType {
  text = 'text',
  number = 'number',
  email = 'email',
  tel = 'tel'
}

export interface ValueConfig {
  label: string;
  showSubField?: boolean;
  subFieldIdList?: { id: string; required: boolean }[];
  subFieldTitle?: string;
  sort?: number;
}

export type FormFieldTypeConfig =
  | TextConfig
  | DropDownConfig
  | RadioConfig
  | SliderConfig
  | CheckboxConfig
  | DateConfig
  | ListConfig
  | PhoneConfig
  | EmailConfig
  | HealthCardConfig;

export enum ConfigType {
  input = 'input',
  dropdown = 'dropdown',
  phone = 'phone',
  email = 'email',
  radio = 'radio',
  slider = 'slider',
  checkbox = 'checkbox',
  date = 'date',
  list = 'list',
  healthcard = 'healthcard'
}

export interface HealthCardConfig {
  type: ConfigType.healthcard;
}

export interface TextConfig {
  type: ConfigType.input;
  multiLine: boolean;
  title?: string;
}

export interface ListConfig {
  type: ConfigType.list;
  itemName: string;
}

export interface DropDownConfig {
  type: ConfigType.dropdown;
  valuesConfig: { [key: string]: ValueConfig };
}

export interface PhoneConfig {
  type: ConfigType.phone;
}

export interface EmailConfig {
  type: ConfigType.email;
  inputType: 'email';
}

export interface RadioConfig {
  type: ConfigType.radio;
  valuesConfig: { [key: string]: ValueConfig };
}

export interface SliderConfig {
  type: ConfigType.slider;
  min: number;
  max: number;
  step: number;
  minLabel?: string;
  maxLabel?: string;
  showPlusSign?: boolean;
  bottomLabels?: string[];
}

export interface CheckboxConfig {
  type: ConfigType.checkbox;
  valuesConfig: { [key: string]: ValueConfig };
}

export interface DateConfig {
  type: ConfigType.date;
}

export interface FormTemplateSchema {
  name: string;
  id: string;
  type: 'intake' | 'questionnaire' | 'referral' | 'assessment';
  message: string;
  abbreviation: string;
  templateGroups: { [key: string]: FormTemplateGroup };
  supportingFields: {
    [key: string]: FormFieldConfig;
  };
  availableLanguages?: { id: string; label: string }[];
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isNullOrUndefined } from 'util';
import { normalizeToSymptomKey } from '../../form.model';
import { SchemaService } from '../services/schema/schema.service';
import {
  DropdownSymptom,
  MethodSymptom,
  ModalSymptom,
  ModalSymptomCustomModalOptions,
  ModalSymptomModalOptions,
  ModalSymptomTypes,
  Symptom,
  SymptomMethod,
  SymptomTypes
} from '../symptom.model';

@Pipe({
  name: 'symptomValue'
})

// Takes internal value for a symptom and returns user-friendly value to display
export class SymptomValuePipe implements PipeTransform {
  constructor(private schemaService: SchemaService, private translocoService: TranslocoService) {}

  transform(
    internalValue: any,
    symptomKey: string,
    method?: SymptomMethod,
    lang: string = 'en'
  ): any {
    symptomKey = normalizeToSymptomKey(symptomKey);

    const translationObject = this.translocoService.getTranslation(lang);

    const translationKey =
      'signs.' + symptomKey + '.' + ((method && method + '.') || '') + 'values.' + internalValue;

    if (translationObject[translationKey]) {
      return translationObject[translationKey];
    } else if (symptomKey && symptomKey in this.schemaService.symptomMap) {
      const symptom: Symptom = this.schemaService.symptomMap[symptomKey];
      return this.getDisplayValueForSymptom(internalValue, symptom, method);
    }

    return internalValue;
  }

  private getDisplayValueForSymptom(
    internalValue: string | number,
    symptom: Symptom,
    method?: SymptomMethod
  ): string | number {
    switch (symptom.type) {
      case SymptomTypes.Modal:
        return this.getDisplayValueForModalSymptom(internalValue, (<ModalSymptom>symptom).modal);

      case SymptomTypes.Dropdown:
        return (<DropdownSymptom>symptom).values[internalValue];

      case SymptomTypes.Method:
        return this.getDisplayValueForMethodSymptom(internalValue, <MethodSymptom>symptom, method);

      default:
        return internalValue;
    }
  }

  private getDisplayValueForModalSymptom(
    internalValue: string | number,
    modalSymptomOptions: ModalSymptomModalOptions
  ): string | number {
    if (
      (modalSymptomOptions.type === ModalSymptomTypes.Simple ||
        isNullOrUndefined((<ModalSymptomCustomModalOptions>modalSymptomOptions).valueMap)) &&
      modalSymptomOptions.values
    ) {
      return modalSymptomOptions.values[internalValue];
    } else if ((<ModalSymptomCustomModalOptions>modalSymptomOptions).valueMap) {
      return (<ModalSymptomCustomModalOptions>modalSymptomOptions).valueMap(internalValue);
    } else {
      return internalValue;
    }
  }

  private getDisplayValueForMethodSymptom(
    internalValue: string | number,
    methodSymptom: MethodSymptom,
    method?: SymptomMethod
  ): string | number {
    const symptom: Symptom = this.schemaService.getSymptomForMethod(methodSymptom, method);
    return this.getDisplayValueForSymptom(internalValue, symptom);
  }
}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PreOpService } from '../pre-op.service';
import { PreOpAnalysisService } from './pre-op-analysis.service';

@Component({
  selector: 'csi-pre-op-analysis',
  templateUrl: './pre-op-analysis.component.html',
  styleUrls: ['./pre-op-analysis.component.scss']
})
export class PreOpAnalysisComponent implements OnInit, AfterViewInit {
  @Input() type: string;
  @Input() group: string;

  constructor(
    public preOpService: PreOpService,
    public preOpAnalysisService: PreOpAnalysisService
  ) {}

  ngOnInit() {
    this.setAnalysisStates();

    this.preOpService.analysisStateUpdate$.subscribe(() => {
      setTimeout(() => {
        setTimeout(() => {
          this.setAnalysisStates();
          this.preOpService.isAnalysisNormal =
            this.preOpAnalysisService.significantAnalysisStateKeys.size +
              this.preOpAnalysisService.inSignificantAnalysisStateKeys.size <
            4;
        });
      });
    });
  }

  ngAfterViewInit(): void {}

  analysisStateSetInit() {
    this.preOpAnalysisService.allAnalysisStateKeys = new Set<string>();
    this.preOpAnalysisService.significantAnalysisStateKeys = new Set<string>();
    this.preOpAnalysisService.inSignificantAnalysisStateKeys = new Set<string>();
    // Need to look into and maybe use required ones only
    this.preOpAnalysisService.remarkableRequiredAnalysisStateKeys = new Set<string>();
    this.preOpAnalysisService.unRemarkableRequiredAnalysisStateKeys = new Set<string>();
  }

  toggleRemarkableField(analysisStateKey: string) {
    const analysisState = this.preOpService.analysisState[analysisStateKey];

    analysisState.significant = !analysisState.significant;

    this.setAnalysisStates();
  }

  setAnalysisStates() {
    this.analysisStateSetInit();

    Object.entries(this.preOpService.analysisState).forEach(([key, analysisStateItem]) => {
      if (!analysisStateItem.abnormal) {
        return;
      }

      if (analysisStateItem.significant) {
        if (!this.group || this.group === analysisStateItem.group) {
          this.preOpAnalysisService.significantAnalysisStateKeys.add(key);
          this.preOpAnalysisService.allAnalysisStateKeys.add(key);
        }
        if (analysisStateItem.required) {
          this.preOpAnalysisService.remarkableRequiredAnalysisStateKeys.add(key);
          this.preOpAnalysisService.allAnalysisStateKeys.add(key);
        }
        return;
      }

      this.preOpAnalysisService.inSignificantAnalysisStateKeys.add(key);
      this.preOpAnalysisService.allAnalysisStateKeys.add(key);
      if (analysisStateItem.required) {
        this.preOpAnalysisService.unRemarkableRequiredAnalysisStateKeys.add(key);
        this.preOpAnalysisService.allAnalysisStateKeys.add(key);
      }
    });
  }
}

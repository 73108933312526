import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';

@Pipe({
  name: 'getQuestionnaire'
})
export class GetQuestionnairePipe implements PipeTransform {
  transform(
    questionnaireRequests: { items: Partial<QuestionnaireRequest>[] },
    type: QuestionnaireType
  ): Partial<QuestionnaireRequest> {
    return (
      (questionnaireRequests &&
        questionnaireRequests.items.length > 0 &&
        questionnaireRequests.items.find(questionnaireRequest => {
          const simpleTypeMatch = questionnaireRequest.type === type;
          if (simpleTypeMatch) {
            return true;
          }
          if (type === QuestionnaireType.OSDI || type === QuestionnaireType.SPEED) {
            return questionnaireRequest.type === QuestionnaireType.OSDIAndSPEED;
          }
        })) ||
      null
    );
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.css']
})

// export interface CheckboxSchema {
//   description: string;
//   toggleableField?: boolean;
//   type?: string;
// }
export class CheckboxQuestionComponent implements OnInit, OnDestroy {
  @Input() checkboxGroupSchema;
  @Input() formGroup: FormGroup;
  @Input() viewOnly = false;

  @Output() change = new EventEmitter<string>();

  public noSort = noSort;
  public atLeastOneCheckboxSelected = false;
  public selectedCheckboxesCounter = 0;

  constructor() {}

  ngOnInit() {
    Object.keys(this.checkboxGroupSchema).forEach(controlKey => {
      this.addToFormGroupIfKeyDoesNotExist(controlKey, this.formGroup);
      if (this.formGroup.controls[controlKey].value === true) {
        this.selectedCheckboxesCounter++;
        this.atLeastOneCheckboxSelected = true;
      }
      const checkboxSchema = this.checkboxGroupSchema[controlKey];

      if (checkboxSchema.toggleableField) {
        this.addToFormGroupIfKeyDoesNotExist(
          controlKey + 'Value',
          this.formGroup,
          checkboxSchema.required ? true : false
        );
        this.formGroup.controls[controlKey].valueChanges.subscribe(val => {
          val
            ? this.formGroup.controls[controlKey + 'Value'].enable()
            : this.formGroup.controls[controlKey + 'Value'].disable();
        });
        if (checkboxSchema.required && this.formGroup.controls[controlKey].value === false) {
          this.formGroup.controls[controlKey + 'Value'].disable();
        }
      }
    });
    this.updateAtLeastOneCheckboxSelectedProperty();

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateAtLeastOneCheckboxSelectedProperty();
    });
  }

  ngOnDestroy() {}

  private updateAtLeastOneCheckboxSelectedProperty() {
    this.atLeastOneCheckboxSelected = Object.keys(this.checkboxGroupSchema).some(
      controlKey =>
        this.formGroup.controls[controlKey] && this.formGroup.controls[controlKey].value === true
    );
  }

  public onChange(key: string) {
    this.change.emit(key);
  }

  addToFormGroupIfKeyDoesNotExist(key, formGroup: FormGroup, required?: boolean) {
    if (!formGroup.controls[key]) {
      formGroup.addControl(key, new FormControl(null, required ? Validators.required : null));
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  SignGroup,
  signToFriendlyNameMap
} from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments-schema';
import { LayoutsService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { SchemaService } from '../services/schema/schema.service';
import { SymptomModes } from '../symptom.model';

@Component({
  selector: 'csi-symptom-group',
  templateUrl: './symptom-group.component.html',
  styleUrls: ['./symptom-group.component.scss']
})
export class SymptomGroupComponent implements OnInit {
  @Input() public formGroup: DryEyeFormGroup;
  @Input() public signGroup: SignGroup;
  @Input() public show: { [key: string]: string } = {};

  public allSignsHidden = true;
  public signToFriendlyNameMap = signToFriendlyNameMap;

  constructor(public layoutsService: LayoutsService, public schemaService: SchemaService) {}

  ngOnInit() {
    for (let sign of this.signGroup.signs) {
      if (this.show[sign]) {
        this.allSignsHidden = false;
        return;
      }
    }
  }

  get assessmentMethod(): SymptomModes {
    return this.formGroup.controls.assessmentMethod.value;
  }
}

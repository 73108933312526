import { Injectable } from '@angular/core';
import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import { getOperationDefinition } from 'apollo-utilities';
import { Logger } from 'aws-amplify';
import { camelCase } from 'lodash-es';
import { iif } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaffService } from '../api/staff.service';

/** This apollo link intercepts most graphql create mutations and inserts clinic id into it if it has not already been set.
 * Clinic ID has to be added to every object currently so that group auth works
 * You can remove this once pipeline resolvers are implemented and where clinic id can be retrieved through the doctor model in the backend
 * See https://github.com/aws-amplify/amplify-cli/issues/318 and https://github.com/aws-amplify/amplify-cli/issues/1055
 */
export const clinicIdLink = (staffService: StaffService, logger: Logger) => {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    return new Observable(observer => {
      let handle;
      const { operation: operationType } = getOperationDefinition(operation.query);
      const isMutation = operationType === 'mutation';
      const operationName = operation.operationName;

      let addClinicIdPromise: Promise<Operation> = Promise.resolve(operation);

      if (
        isMutation &&
        (operationName === 'CreateAssessment' ||
          operationName === 'CreateAssessmentRequest' ||
          operationName === 'CreateQuestionnaireRequest' ||
          operationName === 'CreatePatient')
      ) {
        const objectName = camelCase(operationName.split('Create', 2)[1]);
        const clinicIdKey = `${objectName}ClinicId`;
        const doctorIdKey = `${objectName}DoctorId`;
        const doctorId = operation.variables.input[doctorIdKey];
        const clinicId = operation.variables.input[clinicIdKey];

        if (!clinicId) {
          logger.debug('Create Mutation without clinic id - will try to add clinic id');

          addClinicIdPromise = iif(
            () => !!doctorId,
            staffService.getDoctor(doctorId).pipe(map(doctor => doctor.clinic && doctor.clinic.id)),
            staffService.getLoggedInStaff().pipe(map(doctor => doctor.clinic && doctor.clinic.id))
          )
            .pipe(
              map(retrievedClinicId => {
                operation.variables.input[clinicIdKey] = retrievedClinicId;
                logger.debug(
                  `Set ${clinicIdKey} with clinic id of ${retrievedClinicId}`,
                  operation
                );
                return operation;
              })
            )
            .toPromise();
        }
      }

      addClinicIdPromise
        .then(forward)
        .then(observable => {
          handle = observable.subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(err => {
          observer.error(err);
        });

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    });
  });
};

@Injectable({
  providedIn: 'root'
})
export class ClinicIdLink extends ApolloLink {
  private link: ApolloLink;
  private _doctorService: StaffService;
  private logger = new Logger('ClinicIdLink');
  constructor() {
    super();
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    if (!!this.link) {
      return this.link.request(operation, forward);
    } else {
      // passthrough
      return forward ? forward(operation) : Observable.of();
    }
  }

  public get staffService() {
    return this._doctorService;
  }

  // To avoid circular dependency staffService can't be injected and instead needs to be passed here
  public set staffService(value: StaffService) {
    this._doctorService = value;
    if (!!this._doctorService) {
      this.link = clinicIdLink(this._doctorService, this.logger);
    }
  }
}

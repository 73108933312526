import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { Flag, Notification, NotificationType } from 'src/API';
import { noSort } from 'src/app/core/api/model-utils';
import { InboxService } from '../inbox.service';

@Component({
  selector: 'csi-inbox-modal',
  templateUrl: './inbox-modal.component.html',
  styleUrls: ['./inbox-modal.component.css']
})
export class InboxModalComponent implements OnInit {
  public inboxContent: { [key in NotificationType]: Notification[] } = this.inboxService
    .inboxContent;
  public notificationSelected$: Subject<string> = new Subject<string>();
  public tabIndexToKeyMap = [];
  public selectedTabIndex = 0;
  public currentSelectedNotificationId: string;
  public currentNotificationDisplayed: Notification;
  public sortOptions = {
    date: 'Date',
    unread: 'Unread',
    flagged: 'Flagged'
  };
  public defaultIsAsc = false;
  public defaultSortOption = 'date';
  public notificationTypeToIsAsc: { [key in NotificationType]: boolean } = {
    [NotificationType.intake]: false,
    [NotificationType.questionnaire]: false,
    [NotificationType.referral]: false,
    [NotificationType.system]: false
  };

  public notificationTypeToSortByFormControl: { [key in NotificationType]: FormControl } = {
    [NotificationType.intake]: new FormControl(this.defaultSortOption),
    [NotificationType.questionnaire]: new FormControl(this.defaultSortOption),
    [NotificationType.referral]: new FormControl(this.defaultSortOption),
    [NotificationType.system]: new FormControl(this.defaultSortOption)
  };

  public loading = false;

  constructor(public inboxService: InboxService) {}

  ngOnInit() {
    this.loading = true;
    this.inboxService.loadNotifications().subscribe(() => {
      this.sortAll();
      this.loading = false;
    });

    // this.inboxService.listSystemNotifications().subscribe(val => {});

    Object.keys(this.inboxContent).forEach(key => {
      this.tabIndexToKeyMap.push(key);
    });

    this.notificationSelected$.subscribe(id => {
      this.currentSelectedNotificationId = id;
      this.setDisplayedNotificationContent();
    });

    Object.keys(this.notificationTypeToSortByFormControl).forEach((key: NotificationType) => {
      this.notificationTypeToSortByFormControl[key].valueChanges.subscribe(() => {
        this.sort(key);
      });
    });
  }

  ngOnDestroy() {
    this.notificationSelected$.unsubscribe();
  }

  public static open(matDialogService: MatDialog) {
    return matDialogService.open(InboxModalComponent, {
      maxWidth: '90vw',
      data: {}
    });
  }

  public setDisplayedNotificationContent() {
    for (const notification of this.inboxContent[this.tabIndexToKeyMap[this.selectedTabIndex]]) {
      if (notification.id === this.currentSelectedNotificationId) {
        this.currentNotificationDisplayed = notification;
        return;
      }
    }

    this.currentNotificationDisplayed = null;
  }

  public onTabChanged() {
    this.currentNotificationDisplayed = null;
    // Short delay to account for tab change animation
    setTimeout(() => {
      this.setDisplayedNotificationContent();
    }, 300);
  }

  public markAllAsRead(notificationType) {
    for (const notification of this.inboxContent[notificationType]) {
      const status = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
        notification.properties,
        this.inboxService.loggedInDoctor
      );
      // Prevent unnecessary updates
      if (!status.read) {
        this.inboxService.setNotificationReadTrue(notification);
      }
    }
    // this.inboxService.updateTotalUnreadNotificationCount();
  }

  public refreshAll() {
    this.loading = true;
    this.inboxService.loadNotifications().subscribe(() => {
      this.sortAll();
      this.loading = false;
    });
  }

  public toggleIsAsc() {
    const notificationType = this.tabIndexToKeyMap[this.selectedTabIndex];
    this.notificationTypeToIsAsc[notificationType] = !this.notificationTypeToIsAsc[
      notificationType
    ];
    this.sort();
  }

  public sortAll() {
    Object.keys(NotificationType).forEach((key: NotificationType) => {
      this.sort(key);
    });
  }

  public sort(key: NotificationType = this.tabIndexToKeyMap[this.selectedTabIndex]) {
    const isAsc = this.notificationTypeToIsAsc[key];
    switch (this.notificationTypeToSortByFormControl[key].value) {
      case 'date':
        this.inboxContent[key].sort((a, b) => {
          return this.compare(
            new Date(a.createdAt).getTime(),
            new Date(b.createdAt).getTime(),
            isAsc
          );
        });
        break;
      case 'unread':
        this.inboxContent[key].sort((a, b) => {
          return this.compareByUnread(a, b, isAsc);
        });
        break;
      case 'flagged':
        this.inboxContent[key].sort((a, b) => {
          return this.compareByFlagged(a, b, isAsc);
        });
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareByUnread(a: Notification, b: Notification, isAsc: boolean) {
    const aRead = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
      a.properties,
      this.inboxService.loggedInDoctor
    ).read;
    const bRead = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
      b.properties,
      this.inboxService.loggedInDoctor
    ).read;
    if (aRead !== bRead) {
      return (!aRead ? 1 : -1) * (isAsc ? 1 : -1);
    }

    return this.compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), isAsc);
  }

  compareByFlagged(a: Notification, b: Notification, isAsc: boolean) {
    const aFlag = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
      a.properties,
      this.inboxService.loggedInDoctor
    ).flag;
    const bFlag = this.inboxService.getNotificationStatusCorrespondingToDoctorId(
      b.properties,
      this.inboxService.loggedInDoctor
    ).flag;
    if (aFlag !== bFlag) {
      if (aFlag === Flag.important) {
        return 1 * (isAsc ? 1 : -1);
      } else if (bFlag === Flag.important) {
        return -1 * (isAsc ? 1 : -1);
      } else {
        return this.compare(
          new Date(a.createdAt).getTime(),
          new Date(b.createdAt).getTime(),
          isAsc
        );
      }
    }

    return this.compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), isAsc);
  }
  public noSort = noSort;
}

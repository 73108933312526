import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailDomain'
})
export class EmailDomainPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return (value && value.split('@')[1]) || '';
  }
}

import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

export type ConfirmModalMatDialogRef = MatDialogRef<ConfirmModalComponent, boolean>;

export interface ConfirmModalConfig {
  title?: string;
  body?: string;
  yesButtonTitle?: string;
  noButtonTitle?: string;
  hideConfirmButton?: boolean;
  hideTitle?: boolean;
}

@Component({
  selector: 'csi-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements ConfirmModalConfig {
  title = 'Are you sure?';
  body = '';
  yesButtonTitle = 'Yes';
  noButtonTitle = 'No';
  hideConfirmButton = false;
  hideTitle = false;

  constructor(@Inject(MAT_DIALOG_DATA) private config: ConfirmModalConfig) {
    Object.assign(this, this.config);
  }

  public static open(
    matDialogService: MatDialog,
    content: ConfirmModalConfig,
    config: MatDialogConfig<never> = {}
  ): ConfirmModalMatDialogRef {
    return matDialogService.open<ConfirmModalComponent, ConfirmModalConfig, boolean>(
      ConfirmModalComponent,
      {
        ...config,
        data: content
      }
    );
  }
}

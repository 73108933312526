import { TreatmentTemplate } from './../../../../API';
export const defaultTreatmentTemplateMap: {
  [key: string]: Omit<TreatmentTemplate, '__typename'> & { showByDefault?: boolean };
} = {
  ['aque-mod']: {
    id: 'aque-mod',
    friendlyId: 'aque-mod',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'systane-ultra-high-performance'],
    rxEyeDrops: ['restasis-and-restasis-multidose'],
    procedures: ['punctal-plugs'],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['aque-mild']: {
    id: 'aque-mild',
    friendlyId: 'aque-mild',
    otcEyeDrops: ['systane-original']
  },
  ['evap-mod']: {
    id: 'evap-mod',
    friendlyId: 'evap-mod',
    otcEyeDrops: ['ocusoft-retaine'],
    procedures: [
      'lipiflow-thermal-pulsation-treatment',
      'inmode-radiofrequency-therapy-(rf)',
      'intense-pulsed-light-therapy-(ipl)'
    ],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mix-mild']: {
    id: 'mix-mild',
    friendlyId: 'mix-mild',
    otcEyeDrops: ['systane-balance-restorative'],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['mix-mod']: {
    id: 'mix-mild',
    friendlyId: 'mix-mild',
    otcEyeDrops: ['mgd', 'biodrop-md-plus', 'prn-omega-softgel'],
    rxEyeDrops: ['cequa'],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'lipiflow-thermal-pulsation-treatment',
      'inmode-radiofrequency-therapy-(rf)'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['evap-sev']: {
    id: 'evap-sev',
    friendlyId: 'evap-sev',
    otcEyeDrops: ['ocusoft-retaine'],
    rxEyeDrops: ['autologous-serum'],
    procedures: [
      'intraductal-probing',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['aque-sev']: {
    id: 'aque-sev',
    friendlyId: 'aque-sev',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['cequa', 'autologous-serum'],
    procedures: ['punctal-plugs']
  },
  ['evap-mild']: {
    id: 'evap-mild',
    friendlyId: 'evap-mild',
    otcEyeDrops: ['systane-balance-restorative', 'hylo-dual-dry-eye-and-allergy-relief'],
    procedures: ['lipiflow-thermal-pulsation-treatment', 'miboflo'],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mix-severe']: {
    id: 'mix-severe',
    friendlyId: 'mix-severe',
    otcEyeDrops: ['ocusoft-retaine'],
    rxEyeDrops: ['cequa', 'autologous-serum'],
    procedures: ['intense-pulsed-light-therapy-(ipl)', 'inmode-radiofrequency-therapy-(rf)'],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mild-aqueous']: {
    id: 'mild-aqueous',
    friendlyId: 'mild-aqueous',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: [],
    procedures: ['punctal-plugs'],
    exercises: ['blinking-exercises']
  },
  ['moderate-aqueous']: {
    id: 'moderate-aqueous',
    friendlyId: 'moderate-aqueous',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
    procedures: ['punctal-plugs', 'boston-sight-scleral', 'scleral-contact-lens', 'itear'],
    exercises: ['blinking-exercises']
  },
  ['severe-aqueous']: {
    id: 'severe-aqueous',
    friendlyId: 'severe-aqueous',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'tranquileyes'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
    procedures: [
      'punctal-plugs',
      'amniotic-membrane-transplantation',
      'scleral-lenses',
      'boston-sight-scleral'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['mild-mixed']: {
    id: 'mild-mixed',
    friendlyId: 'mild-mixed',
    otcEyeDrops: ['mgd', 'prn-omega-softgel'],
    rxEyeDrops: [],
    procedures: ['miboflo'],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },
  ['moderate-mixed']: {
    id: 'moderate-mixed',
    friendlyId: 'moderate-mixed',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'punctal-plugs',
      'itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },
  ['severe-mixed']: {
    id: 'severe-mixed',
    friendlyId: 'severe-mixed',
    otcEyeDrops: ['mgd', 'hylo-dual', 'ocunox', 'prn-omega-softgel', 'tranquileyes'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'punctal-plugs',
      'amniotic-membrane-transplantation',
      'boston-sight-scleral',
      'intraductal-probing',
      'itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['mild-evaporative']: {
    id: 'mild-evaporative',
    friendlyId: 'mild-evaporative',
    otcEyeDrops: ['mgd', 'hylo-dual', 'systane-balance-restorative', 'prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: ['miboflo'],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },
  ['moderate-evaporative']: {
    id: 'moderate-evaporative',
    friendlyId: 'moderate-evaporative',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['severe-evaporative']: {
    id: 'severe-evaporative',
    friendlyId: 'severe-evaporative',
    otcEyeDrops: ['mgd', 'hylo-dual', 'ocunox', 'prn-omega-softgel', 'tranquileyes'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'punctal-plugs',
      'amniotic-membrane-transplantation',
      'boston-sight-scleral',
      'intraductal-probing',
      'itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['neurotrophic-stage-1']: {
    id: 'neurotrophic-stage-1',
    friendlyId: 'neurotrophic-stage-1',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
    procedures: [''],
    exercises: ['blinking-exercises', 'glad-press-seal']
  },
  ['neurotrophic-stage-2']: {
    id: 'neurotrophic-stage-2',
    friendlyId: 'neurotrophic-stage-2',
    otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes'],
    rxEyeDrops: [
      'restasis-and-restasis-multidose',
      'cequa',
      'xiidra',
      'autologous-serum',
      'bandage-contact-lens',
      'amniotic-membrane-transplantation',
      'vigamox',
      'prp'
    ],
    procedures: ['tarsorraphy', 'tape-tarsorrhaphy'],
    exercises: ['blinking-exercises', 'tape-tarsorrhaphy']
  },
  ['neurotrophic-stage-3']: {
    id: 'neurotrophic-stage-3',
    friendlyId: 'neurotrophic-stage-3',
    otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes', 'ocunox'],
    rxEyeDrops: [
      'restasis-and-restasis-multidose',
      'cequa',
      'xiidra',
      'autologous-serum',
      'bandage-contact-lens',
      'amniotic-membrane-transplantation',
      'vigamox',
      'topical-n-acetylcysteine',
      'doxycycline',
      'prp'
    ],
    procedures: ['tarsorraphy', 'tape-tarsorrhaphy'],
    exercises: ['blinking-exercises', 'tape-tarsorrhaphy']
  },

  ['contact-lens-induced-keratopathy']: {
    id: 'contact-lens-induced-keratopathy',
    friendlyId: 'contact-lens-induced-keratopathy',
    otcEyeDrops: ['hylo'],
    rxEyeDrops: ['lotemax'],
    procedures: [''],
    exercises: ['']
  },

  ['slk']: {
    id: 'slk',
    friendlyId: 'slk',
    otcEyeDrops: ['i-drop-pur-gel', 'tranquileyes'],
    rxEyeDrops: ['lotemax', 'cequa'],
    procedures: ['bandage-contact-lens'],
    exercises: ['']
  },

  ['corneal-toxicity']: {
    id: 'corneal-toxicity',
    friendlyId: 'corneal-toxicity',
    otcEyeDrops: ['refresh-optive-pf', 'systane-bion-tears'],
    rxEyeDrops: ['autologous-serum', 'prednisolone-sodium-phosphate-minums', 'prp'],
    procedures: ['bandage-contact-lens'],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['trichiasis']: {
    id: 'trichiasis',
    friendlyId: 'trichiasis',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel'],
    rxEyeDrops: ['vigamox'],
    procedures: ['eyelash-apilation-cauterization'],
    exercises: ['']
  },

  ['lagophthalmos']: {
    id: 'lagophthalmos',
    friendlyId: 'lagophthalmos',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'ocunox', 'tranquileyes'],
    rxEyeDrops: [''],
    procedures: ['tarsorraphy'], // I didn't know what add for eyelid surgery (Other)
    exercises: ['glad-press-seal', 'tape-tarsorrhaphy']
  },

  ['bacterial-blepharokeratoconjunctivitis']: {
    id: 'bacterial-blepharokeratoconjunctivitis',
    friendlyId: 'bacterial-blepharokeratoconjunctivitis',
    otcEyeDrops: [
      'i-drop-pur-gel',
      'hylo',
      'prn-omega-softgel',
      'systane-lid-wipes',
      'i-lid-n-lash-plus'
    ],
    rxEyeDrops: ['tobradex-drops', 'vigamox', 'fucithalmic-viscous'],
    procedures: ['blephex-(microblepharoexfoliation)'],
    exercises: ['warm-compresses']
  },

  ['exposure-keratopathy']: {
    id: 'exposure-keratopathy',
    friendlyId: 'exposure-keratopathy',
    otcEyeDrops: [
      'i-drop-pur-gel',
      'hylo-gel',
      'ocunox',
      'prn-omega-softgel',
      'tranquileyes',
      'sleeptite-sleeprite'
    ],
    rxEyeDrops: ['erythromycin-ointment'],
    procedures: [
      'punctal-plugs',
      'bandage-contact-lens',
      'boston-sight-scleral',
      'tape-tarsorrhaphy',
      'tarsorraphy'
    ],
    exercises: ['visual-hygiene', 'glad-press-seal']
  },

  ['gpc/vkc']: {
    id: 'gpc/vkc',
    friendlyId: 'gpc/vkc',
    otcEyeDrops: ['hylo-dual', 'thealoz-duo'],
    // added restasis-and-restasis-multidose fot restasis.
    rxEyeDrops: [
      'pataday',
      'prednisolone-sodium-phosphate-minums',
      'alrex',
      'restasis-and-restasis-multidose',
      'cequa',
      'xiidra'
    ], // no Zaditor,
    procedures: [''],
    exercises: ['cool-compresses'] // I dont't see the discontune contact lense.
  },

  ['allergic-conjunctivitis']: {
    id: 'allergic-conjunctivitis',
    friendlyId: 'allergic-conjunctivitis',
    otcEyeDrops: ['hylo-dual'],
    // prednisolone-sodium-phosphate-minums for prednisolone minuims added restasis-and-restasis-multidose fot restasis.
    rxEyeDrops: [
      'pataday',
      'prednisolone-sodium-phosphate-minums',
      'alrex',
      'restasis-and-restasis-multidose',
      'cequa',
      'xiidra'
    ], // Zaditor doesn't exist
    procedures: [''],
    exercises: ['cool-compresses']
  },

  ['viral/bacterial-conjunctivitis']: {
    id: 'viral/bacterial-conjunctivitis',
    friendlyId: 'viral/bacterial-conjunctivitis',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['vigamox', 'erythromycin-ointment'],
    procedures: [''],
    exercises: ['cool-compresses']
  },

  ['bacterial-blepharitis']: {
    id: 'bacterial-blepharitis',
    friendlyId: 'bacterial-blepharitis',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],

    rxEyeDrops: [
      'erythromycin-ointment',
      'fucithalmic-viscous',
      'blephamide-drop',
      'blephamide-ointment'
    ],
    procedures: ['blephex-(microblepharoexfoliation)', 'low-level-light-therapy'],
    exercises: ['warm-compresses']
  },

  ['demodex-blepharitis']: {
    id: 'demodex-blepharitis',
    friendlyId: 'demodex-blepharitis',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'xiidra', 'cequa', 'doxycycline'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'low-level-light-therapy',
      'miboflo',
      'lipiflow-thermal-pulsation-treatment',
      'intense-pulsed-light-therapy-(ipl)'
    ],
    exercises: ['warm-compresses']
  },

  ['epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion']: {
    id: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    friendlyId: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    otcEyeDrops: ['thealoz-duo', 'sleeptite-sleeprite'], // Muro 128 ointment at night time.
    rxEyeDrops: [''],
    procedures: ['bandage-contact-lens', 'epithelial-debridement', 'phototheraputic-keratectomy'],
    exercises: ['glad-press-seal', 'warm-compresses']
  },

  ['medication-induced']: {
    id: 'medication-induced',
    friendlyId: 'medication-induced',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['autologous-serum', 'prp'],
    procedures: [''],
    exercises: ['warm-compresses', 'blinking-exercises']
  },

  ['corneal-neuralgia']: {
    id: 'corneal-neuralgia',
    friendlyId: 'corneal-neuralgia',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'ocunox'],
    rxEyeDrops: [
      'lotemax',
      'restasis-and-restasis-multidose',
      'cequa',
      'xiidra',
      'autologous-serum',
      'prp'
    ], // still missing gabapentin
    procedures: [
      'bandage-contact-lens',
      'boston-sight-scleral',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'lipiflow-thermal-pulsation-treatment',
      'miboflo'
    ],
    exercises: ['warm-compresses', 'blinking-exercises']
  },

  ['conjunctivochalasis-mild']: {
    id: 'conjunctivochalasis-mild',
    friendlyId: 'conjunctivochalasis-mild',
    otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['']
  },
  ['conjunctivochalasis-moderate']: {
    id: 'conjunctivochalasis-moderate',
    friendlyId: 'conjunctivochalasis-moderate',
    otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['warm-compresses']
  },
  ['conjunctivochalasis-severe']: {
    id: 'conjunctivochalasis-severe',
    friendlyId: 'conjunctivochalasis-severe',
    otcEyeDrops: ['i-drop-pur-gel', 'thealoz-duo', 'prn-omega-softgel'],
    rxEyeDrops: ['alrex', 'prednisolone-sodium-phosphate-minums'],
    procedures: [''],
    exercises: ['warm-compresses']
  },

  ['floppy-eyelid-syndrome-mild']: {
    id: 'floppy-eyelid-syndrome-mild',
    friendlyId: 'floppy-eyelid-syndrome-mild',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['']
  },
  ['floppy-eyelid-syndrome-moderate']: {
    id: 'floppy-eyelid-syndrome-moderate',
    friendlyId: 'floppy-eyelid-syndrome-moderate',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox', 'sleeptite-sleeprite'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['glad-press-seal']
  },
  ['floppy-eyelid-syndrome-severe']: {
    id: 'floppy-eyelid-syndrome-severe',
    friendlyId: 'floppy-eyelid-syndrome-severe',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
    rxEyeDrops: [
      'restasis-and-restasis-multidose',
      'xiidra',
      'cequa',
      'prednisolone-sodium-phosphate-minums',
      'tobradex-ointment'
    ],
    procedures: [''], // eyelid-surgery(other)
    exercises: ['glad-press-seal']
  },

  ['mechanical-(entropion,distichiasis,tarsal-entropion)']: {
    id: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    friendlyId: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'ocunox'],
    rxEyeDrops: ['erythromycin-ointment'],
    procedures: ['bandage-contact-lens', 'eyelash-apilation-cauterization'], // eyelid-surgery(other) need to be added.
    exercises: ['']
  },

  ['lid-wiper-epitheliopathy']: {
    id: 'lid-wiper-epitheliopathy',
    friendlyId: 'lid-wiper-epitheliopathy',
    otcEyeDrops: ['mgd', 'hylo-dual', 'tranquileyes'],
    rxEyeDrops: [
      'alrex',
      'prednisolone-sodium-phosphate-minums',
      'lotemax',
      'cequa',
      'autologous-serum',
      'prp'
    ],
    procedures: [
      'punctal-plugs',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'miboflo',
      'lipiflow-thermal-pulsation-treatment',
      'itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  'mild-aqueous-1': {
    id: 'mild-aqueous-1',
    exercises: ['visual-hygiene', 'warm-compresses', 'blinking-exercises'],
    friendlyId: 'mild aqueous',
    otcEyeDrops: ['hydroeye', 'avenova', 'oasis-tears', 'bruder-mask', 'zocushield', 'eztears'],
    procedures: ['zest'],
    rxEyeDrops: ['vevye'],
    showByDefault: true
  },

  'moderate-aqueous-1': {
    id: 'moderate-aqueous-1',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    friendlyId: 'moderate aqueous',
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: ['low-level-light-therapy', 'zest'],
    rxEyeDrops: ['vevye', 'tyrvaya'],
    showByDefault: true
  },
  'severe-aqueous-1': {
    id: 'severe-aqueous-1',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    friendlyId: 'severe aqueous',
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: ['low-level-light-therapy', 'zest'],
    rxEyeDrops: ['prp', 'autologous-serum', 'vevye', 'tyrvaya'],
    showByDefault: true
  },
  'mild-evaporative-1': {
    id: 'mild-evaporative-1',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    friendlyId: 'mild evaporative',
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: ['low-level-light-therapy', 'zest'],
    rxEyeDrops: ['miebo'],
    showByDefault: true
  },
  'moderate-evaporative-1': {
    id: 'moderate-evaporative-1',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    friendlyId: 'moderate evaporative',
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'low-level-light-therapy',
      'zest'
    ],
    rxEyeDrops: ['miebo', 'tyrvaya', 'eysuvis', 'vevye'],
    showByDefault: true
  },
  'severe-evaporative-1': {
    id: 'severe-evaporative-1',
    friendlyId: 'severe evaporative',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'low-level-light-therapy',
      'zest'
    ],
    rxEyeDrops: ['miebo', 'vevye', 'tyrvaya', 'eysuvis', 'prp', 'autologous-serum'],
    showByDefault: true
  },

  'mild-mixed-1': {
    id: 'mild-mixed-1',
    friendlyId: 'mild mixed',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: ['low-level-light-therapy', 'zest'],
    rxEyeDrops: ['miebo', 'vevye', 'tyrvaya'],
    showByDefault: true
  },

  'moderate-mixed-1': {
    id: 'moderate-mixed-1',
    friendlyId: 'moderate mixed',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'low-level-light-therapy',
      'zest'
    ],
    rxEyeDrops: ['miebo', 'eysuvis', 'tyrvaya', 'vevye'],
    showByDefault: true
  },

  'severe-mixed-1': {
    id: 'severe-mixed-1',
    friendlyId: 'severe mixed',
    exercises: ['warm-compresses', 'visual-hygiene', 'blinking-exercises'],
    otcEyeDrops: [
      'oasis-tears-plus',
      'zocushield',
      'avenova',
      'hydroeye',
      'eztears',
      'bruder-mask'
    ],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'low-level-light-therapy',
      'zest'
    ],
    rxEyeDrops: ['miebo', 'tyrvaya', 'vevye', 'eysuvis', 'prp', 'autologous-serum'],
    showByDefault: true
  }
};

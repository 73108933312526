import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() checkboxControlKeyToNamePairs: { [key: string]: string };

  @Output() change = new EventEmitter<boolean>(); // Emits true if at least one checkbox is selected

  public noSort = noSort;
  public atLeastOneCheckboxSelected = false;
  public selectedCheckboxesCounter = 0;

  constructor() {}

  ngOnInit() {
    Object.keys(this.checkboxControlKeyToNamePairs).forEach(controlKey => {
      this.addToFormGroupIfKeyDoesNotExist(controlKey, this.formGroup);
      if (this.formGroup.controls[controlKey].value === true) {
        this.selectedCheckboxesCounter++;
        this.atLeastOneCheckboxSelected = true;
      }
    });
    this.addToFormGroupIfKeyDoesNotExist('SPEED_includeMedication', this.formGroup);
    if (this.formGroup.controls['SPEED_includeMedication'].value === true) {
      this.selectedCheckboxesCounter++;
      this.atLeastOneCheckboxSelected = true;
    }
  }

  public onChange(key: string) {
    if (this.formGroup.controls[key].value === true) {
      this.selectedCheckboxesCounter++;
    } else {
      this.selectedCheckboxesCounter--;
    }
    if (this.selectedCheckboxesCounter > 0) {
      this.atLeastOneCheckboxSelected = true;
    } else if (this.selectedCheckboxesCounter === 0) {
      this.atLeastOneCheckboxSelected = false;
    } else {
      console.log('Selected checkboxes less than zero error!');
    }
    this.change.emit(this.atLeastOneCheckboxSelected);
  }

  addToFormGroupIfKeyDoesNotExist(key: string, formGroup: FormGroup) {
    if (!formGroup.controls[key]) {
      formGroup.addControl(key, new FormControl(false));
    }
  }
}

import {
  DryEyeEConsultFormControls,
  EConsultFormGroup
} from '../../../../../../econsult/econsult-form.model';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { EyeDrop } from '../../../../../../core/remote-json/treatments/treatments.service';
import { FilterShouldActivate, TreatmentFilter } from '../treatment-filter.model';
import { CONTACT_LENS_FIELD } from '../../../../../symptoms/services/schema/schema.model';

export class ByContactCompatibility implements TreatmentFilter {
  private _name = 'Contact Lens Compatible';
  public selectedByDefault = false;

  get name(): string {
    return this._name;
  }

  listensTo(formGroup: EConsultFormGroup): Observable<FilterShouldActivate> {
    return of(formGroup).pipe(
      switchMap(value => {
        const medicalHistoryForm = (value.controls as DryEyeEConsultFormControls)
          .medicalHistoryForm;
        if (medicalHistoryForm && medicalHistoryForm.controls[CONTACT_LENS_FIELD]) {
          const control = medicalHistoryForm.controls[CONTACT_LENS_FIELD];
          if (control) {
            return control.valueChanges.pipe(
              map((value2: boolean) => new FilterShouldActivate(this, value2))
            );
          }
        }
        return of(new FilterShouldActivate(this, null));
      }),
      distinctUntilChanged((prev, next) => prev.equals(next))
    );
  }

  filter(eyeDrop: EyeDrop): boolean {
    return eyeDrop.contactLensCompatible;
  }
}

import { Injectable } from '@angular/core';
import {
  BothEyes,
  Condition,
  ContributionLevel,
  ContributorBasedOnEvidence,
  Operator,
  PropertiesMapForSignsAndSymptoms,
  PropertyForSignAndSymptom,
  ScaleToNumberMap
} from './interpretation.model';

@Injectable({
  providedIn: 'root'
})
export class InterpretationService {
  public getContributorAndEvidenceForAllMessageGroups(
    patientData,
    conditionsForInterpretationCategory
  ): ContributorBasedOnEvidence[] {
    const messageGroupKeys = Object.keys(conditionsForInterpretationCategory);
    const messagesBasedOnEvidence: ContributorBasedOnEvidence[] = [];
    messageGroupKeys.forEach(messageGroupKey => {
      const messageGroups = conditionsForInterpretationCategory[messageGroupKey];
      const contributorBasedOnEvidence: ContributorBasedOnEvidence = {
        contributor: null,
        evidence: [],
        contributionLevel: null
      };

      const contributorIfOnlyMainConditionMatches = messageGroups['otherwise'];
      let contributionLevel: ContributionLevel = null;
      const specialNote = messageGroups['specialNote'];

      const mainConditionEvidence = !!messageGroups['MAIN_CONDITION']
        ? this.getEvidenceForConditionsThatMatch(patientData, messageGroups['MAIN_CONDITION'])
        : [];
      contributorBasedOnEvidence.evidence = mainConditionEvidence;

      if (!messageGroups['MAIN_CONDITION'] || mainConditionEvidence.length > 0) {
        const contributionLevelKeys = Object.keys(messageGroups).filter(key =>
          ['significant', 'probable', 'possible'].includes(key)
        );
        let evidenceListForContributor: string[];
        contributionLevelKeys.some((contributionLevelKey: ContributionLevel) => {
          evidenceListForContributor = this.getEvidenceForConditionsThatMatch(
            patientData,
            messageGroups[contributionLevelKey]
          );
          if (evidenceListForContributor.length > 0) {
            contributionLevel = contributionLevelKey;
            return true;
          }
        });

        if (contributionLevel || contributorIfOnlyMainConditionMatches) {
          const contributor: string = contributionLevel
            ? messageGroups[contributionLevel]['contributor']
            : contributorIfOnlyMainConditionMatches['contributor'];
          const plural = contributionLevel
            ? messageGroups[contributionLevel]['plural']
            : contributorIfOnlyMainConditionMatches['plural'];

          contributorBasedOnEvidence.contributor = contributor;
          contributorBasedOnEvidence.evidence = contributorBasedOnEvidence.evidence.concat(
            evidenceListForContributor
          );
          contributorBasedOnEvidence.contributionLevel =
            contributionLevel || contributorIfOnlyMainConditionMatches['contributionLevel'];
          contributorBasedOnEvidence.plural = plural;
          if (specialNote) {
            contributorBasedOnEvidence.specialNote = specialNote;
          }

          messagesBasedOnEvidence.push(contributorBasedOnEvidence);
        }
      }
    });
    return messagesBasedOnEvidence;
  }

  public getEvidenceForConditionsThatMatch(
    patientData: any,
    conditionsForInterpretationCategory: any
  ): string[] {
    const logicalOrGroups = Object.keys(
      conditionsForInterpretationCategory
    ).filter(logicalOrGroup => /LOGICAL_OR_GROUP_[0-9]+/.test(logicalOrGroup));
    let allEvidences: string[] = [];
    let allConditionsMatch = true;

    logicalOrGroups.forEach(logicalOrGroup => {
      const signOrSymptomKeys = Object.keys(conditionsForInterpretationCategory[logicalOrGroup]);
      const conditionsForTheLogicalOrGroup = conditionsForInterpretationCategory[logicalOrGroup];
      const logicalOrGroupEvidences: string[] = [];

      signOrSymptomKeys.forEach(signOrSymptomKey => {
        const evidenceForCondition: string = this.getEvidenceIfConditionMatches(
          patientData,
          conditionsForTheLogicalOrGroup[signOrSymptomKey],
          signOrSymptomKey
        );
        if (evidenceForCondition) {
          logicalOrGroupEvidences.push(evidenceForCondition);
        }
      });
      allConditionsMatch = allConditionsMatch && logicalOrGroupEvidences.length > 0;
      allEvidences = allEvidences.concat(logicalOrGroupEvidences);
    });

    return allConditionsMatch ? allEvidences : [];
  }

  private getEvidenceIfConditionMatches(
    patientData,
    signOrSymptomMap: Condition,
    signOrSymptomKey
  ): string {
    let conditionMatch = false;

    const specialCondition: boolean = signOrSymptomMap.specialCondition;
    const singleValueForBothEyes: boolean = this.getPropertyForSignsAndSymptoms(
      signOrSymptomKey,
      'singleValueForBothEyes'
    );
    const hasQualitativeOrQuantitativeProperty: boolean = this.getPropertyForSignsAndSymptoms(
      signOrSymptomKey,
      'qualitativeOrQuantitative'
    );

    if (specialCondition) {
      conditionMatch = this.checkSpecialCondition(
        patientData,
        signOrSymptomMap,
        signOrSymptomKey,
        hasQualitativeOrQuantitativeProperty
      );
    } else if (singleValueForBothEyes) {
      conditionMatch = this.compareValues(
        this.getPropertyForSignsAndSymptoms(signOrSymptomKey, 'convertToNumericValue')
          ? ScaleToNumberMap[patientData[signOrSymptomKey]]
          : patientData[signOrSymptomKey],
        signOrSymptomMap.condition,
        signOrSymptomMap.operator
      );
    } else if (hasQualitativeOrQuantitativeProperty) {
      conditionMatch = this.checkConditionForQualitativeOrQuantitative(
        patientData,
        signOrSymptomMap,
        signOrSymptomKey
      );
    } else {
      conditionMatch = this.checkConditionForBothEyes(
        patientData,
        signOrSymptomMap,
        signOrSymptomKey
      );
    }

    return conditionMatch ? signOrSymptomMap.evidence || signOrSymptomKey : '';
  }

  private checkConditionForBothEyes(
    patientData,
    signOrSymptomMap: Condition,
    signOrSymptomKey
  ): boolean {
    const convertToNumericValue = this.getPropertyForSignsAndSymptoms(
      signOrSymptomKey,
      'convertToNumericValue'
    );
    const bothEyeValues = this.getValueForBothEyes(patientData, signOrSymptomKey, {
      left: convertToNumericValue,
      right: convertToNumericValue
    });
    const valueToCompare = signOrSymptomMap.condition;
    const operator = signOrSymptomMap.operator;
    return (
      this.compareValues(bothEyeValues.left, valueToCompare, operator) ||
      this.compareValues(bothEyeValues.right, valueToCompare, operator)
    );
  }

  private checkConditionForQualitativeOrQuantitative(
    patientData,
    signOrSymptomMap: Condition,
    signOrSymptomKey
  ): boolean {
    const qualitativeOrQuantitative: BothEyes = this.getValueForBothEyes(
      patientData,
      signOrSymptomKey + '_METHOD'
    );

    const conditionRight: Condition = signOrSymptomMap[qualitativeOrQuantitative.right];
    const conditionLeft: Condition = signOrSymptomMap[qualitativeOrQuantitative.left];

    const convertToNumericValue = {
      left: this.getPropertyForSignsAndSymptoms(
        signOrSymptomKey,
        'convertToNumericValue',
        qualitativeOrQuantitative.left as string
      ),
      right: this.getPropertyForSignsAndSymptoms(
        signOrSymptomKey,
        'convertToNumericValue',
        qualitativeOrQuantitative.right as string
      )
    };

    const bothEyeValues = this.getValueForBothEyes(
      patientData,
      signOrSymptomKey,
      convertToNumericValue
    );

    const rightValueToCompare = conditionRight ? conditionRight.condition : null;
    const leftValueToCompare = conditionLeft ? conditionLeft.condition : null;
    const rightOperator = conditionRight ? conditionRight.operator : null;
    const leftOperator = conditionLeft ? conditionLeft.operator : null;

    return (
      this.compareValues(bothEyeValues.right, rightValueToCompare, rightOperator) ||
      this.compareValues(bothEyeValues.left, leftValueToCompare, leftOperator)
    );
  }

  private getValueForBothEyes(
    patientData,
    key,
    convertToNumericValue = { right: false, left: false }
  ): BothEyes {
    return {
      left: convertToNumericValue.left
        ? ScaleToNumberMap[patientData['LEFT_' + key]]
        : patientData['LEFT_' + key],
      right: convertToNumericValue.right
        ? ScaleToNumberMap[patientData['RIGHT_' + key]]
        : patientData['RIGHT_' + key]
    };
  }

  private checkSpecialCondition(
    patientData,
    signOrSymptomMap: Condition,
    signOrSymptomKey,
    hasQualitativeOrQuantitativeProperty: boolean
  ) {
    return signOrSymptomMap.condition(
      this.getPropertyForSignsAndSymptoms(signOrSymptomKey, 'singleValueForBothEyes')
        ? patientData[signOrSymptomKey]
        : this.getValueForBothEyes(patientData, signOrSymptomKey),
      hasQualitativeOrQuantitativeProperty
        ? this.getValueForBothEyes(patientData, signOrSymptomKey + '_METHOD')
        : null
    );
  }

  private getPropertyForSignsAndSymptoms(
    signsAndSymptomsKey,
    valueToGet: PropertyForSignAndSymptom,
    qualitativeOrQuantitative: string = ''
  ) {
    const propertiesMapForSignAndSymptom = PropertiesMapForSignsAndSymptoms[signsAndSymptomsKey];
    return propertiesMapForSignAndSymptom
      ? !!qualitativeOrQuantitative
        ? propertiesMapForSignAndSymptom[qualitativeOrQuantitative]
          ? propertiesMapForSignAndSymptom[qualitativeOrQuantitative][valueToGet]
          : null
        : propertiesMapForSignAndSymptom[valueToGet]
      : null;
  }

  private compareValues(patientData, conditionData, operator: Operator): boolean {
    // Can't do !patientData as patientData can be 0
    if (patientData === null || patientData === undefined) {
      return false;
    }
    switch (operator) {
      case '>': {
        return patientData > conditionData;
      }
      case '>=': {
        return patientData >= conditionData;
      }
      case '<': {
        return patientData < conditionData;
      }
      case '<=': {
        return patientData <= conditionData;
      }
      case '=': {
        return patientData === conditionData;
      }
      case '!=': {
        return patientData !== conditionData;
      }
    }
  }
}

import {
  AfterContentInit,
  ContentChildren,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Logger } from 'aws-amplify';

// This UpdateOnBlurDirective updates Angular form controls when they experience a blur event instead of
// just the change event. The reason for this is that Angular doesn't actually use the HTML input to store
// state it does that on its own. So problems can arise when say the user uses their browsers autofill to
// put a value inside a control, Angular won't detect the new value. So to get around this I made this
// directive that when applied automatically looks at all the Angular form controls and force updates
// them after the user finishes interacting with them.
// See https://github.com/angular/angular/issues/12395

@Directive({
  selector: '[csiUpdateOnBlur]'
})
export class UpdateOnBlurDirective implements AfterContentInit, OnDestroy {
  @Input() excludeBlurUpdateFor: string[];

  @ContentChildren(NgControl, { read: ViewContainerRef }) controls: QueryList<ViewContainerRef>;

  private changesSubscription;
  private oldControls: { controlElement: HTMLInputElement; eventListener: () => void }[] = [];
  private logger = new Logger('UpdateOnBlur');

  ngAfterContentInit() {
    this.changesSubscription = this.controls.changes.subscribe(() => {
      this.oldControls.forEach(controlPair => {
        controlPair.controlElement.removeEventListener('blur', controlPair.eventListener);
      });

      this.oldControls = this.controls
        .filter(controlViewContainerRef => {
          const controlElement = controlViewContainerRef.element.nativeElement;
          if (controlElement.type) {
            return controlElement.type !== 'radio' && controlElement.type !== 'checkbox';
          } else {
            return controlElement.tagName !== 'MAT-SLIDER';
          }
        })
        .map(controlViewContainerRef => {
          const controlElement = controlViewContainerRef.element.nativeElement;
          return {
            controlElement: controlElement,
            eventListener: () => {
              const shouldControlBeExcluded =
                this.excludeBlurUpdateFor &&
                controlElement.matches(this.excludeBlurUpdateFor.join(','));

              if (!shouldControlBeExcluded) {
                controlViewContainerRef.injector
                  .get(NgControl)
                  .control.setValue(controlElement.value);

                this.logger.debug(
                  `Setting value of ${controlViewContainerRef.element.nativeElement.localName} to ${
                    controlElement.value
                  }`
                );
              }
            }
          };
        });

      this.oldControls.forEach(controlPair => {
        controlPair.controlElement.addEventListener('blur', controlPair.eventListener);
      });
    });

    this.controls.notifyOnChanges();
  }

  ngOnDestroy() {
    this.changesSubscription.unsubscribe();
  }

  @HostListener('submit') onSubmit() {
    this.controls
      .map(controlViewContainerRef => controlViewContainerRef.element.nativeElement)
      .forEach((controlElement: HTMLInputElement) => {
        controlElement.blur();
      });
  }
}

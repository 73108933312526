import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

@Directive({
  selector: '[csiMarkdownNoPTags]'
})
export class MarkdownNoPTagsDirective implements AfterViewInit, OnChanges {
  @Input('csiMarkdownNoPTags') text: string;
  constructor(private elementRef: ElementRef, private markdownService: MarkdownService) {}

  ngOnChanges() {
    this.applyMarkdown();
  }

  ngAfterViewInit() {}

  applyMarkdown() {
    this.disablePTags();
    this.elementRef.nativeElement.innerHTML = this.markdownService.compile(
      this.text || this.elementRef.nativeElement.innerHTML
    );
  }

  disablePTags() {
    // See https://github.com/markedjs/marked/issues/395
    this.markdownService.renderer.paragraph = text => text;
  }
}

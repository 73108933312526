import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { gql } from 'graphql-tag';
// import * as zlib from 'zlib';
import * as pako from 'pako';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetFilteredDataQuery, Priority, ReferralType } from 'src/API';
import { ReferralService } from 'src/app/referral/referral.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { getFilteredData } from 'src/graphql/queries';
import { FilterType, Gender, GetFilteredDataQueryVariables } from './../../API';
import { AppSyncService } from './../core/appsync.service';
import { ClinicSetupService } from './../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import {
  FilterFieldSchema,
  FilterFieldType,
  FilterGroup,
  commonFilterFieldConfig
} from './filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  dataGroupFilterFieldMap: FilterFieldSchema = {
    assessment: {
      createdAt: commonFilterFieldConfig.calender('Created At'),
      updatedAt: commonFilterFieldConfig.calender('Updated At'),
      ...Object.keys(this.schemaService.symptomMap).reduce((acc, key) => {
        const fieldConfig = commonFilterFieldConfig.sign(this.schemaService.symptomMap, key);
        return {
          ...acc,
          [key]: fieldConfig
        };
      }, {}),
      conditions: {
        label: 'Conditions',
        operators: ['contains'],
        typeConfig: {
          type: 'checkbox',
          options: {
            ...this.schemaService.checkboxConditionMap,
            ...this.schemaService.medicalConditionMap,
            ...this.schemaService.environmentalSymptomMap
          }
        }
      },
      ...Object.entries(this.schemaService.radioConditionMap).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            label: value.name,
            operators: ['equals'],
            typeConfig: {
              type: 'dropdown',
              options: value.values
            }
          }
        }),
        {}
      )
    },
    referral: {
      createdAt: commonFilterFieldConfig.calender('Created At'),
      'patient.firstName': commonFilterFieldConfig.text('Patient First Name'),
      'patient.lastName': commonFilterFieldConfig.text('Patient Last Name'),
      'doctor.firstName': commonFilterFieldConfig.text('Doctor First Name'),
      'doctor.lastName': commonFilterFieldConfig.text('Doctor Last Name'),
      'clinic.name': commonFilterFieldConfig.text('Referring Clinic Name'),
      status: {
        label: 'Status',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: this.referralService.referralStatus.reduce(
            (acc, status) => ({ ...acc, status }),
            {}
          )
        }
      },
      priority: {
        label: 'Priority',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(Priority).reduce(
            (acc, priority) => ({ ...acc, [priority]: priority.split('_').join(' ') }),
            {}
          )
        }
      },
      diseaseArea: commonFilterFieldConfig.text('Disease Area'),
      feedbackHistory: commonFilterFieldConfig.text('Feedback History'),
      type: {
        label: 'Referral Type',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(ReferralType).reduce(
            (acc, referralType) => ({
              ...acc,
              [referralType]: this.referralService.referralTypeFriendlyMap[referralType]
            }),
            {}
          )
        }
      }
    },
    patient: {
      firstName: commonFilterFieldConfig.text('First Name'),
      lastName: commonFilterFieldConfig.text('Health Card'),
      phone: commonFilterFieldConfig.text('Phone'),
      email: commonFilterFieldConfig.text('Email'),
      dateOfBirth: commonFilterFieldConfig.calender('Date Of Birth'),
      gender: {
        label: 'Referral Type',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(Gender).reduce((acc, gender) => ({ ...acc, [gender]: gender }), {})
        }
      },
      createdAt: commonFilterFieldConfig.calender('Added On')
    }
  };

  public filterTypeCachedFilterMap: {
    [k in FilterType]: { data: { [key: string]: any } };
  } = {
    patient: { data: {} },
    assessment: { data: {} },
    referral: { data: {} },
    doctor: { data: {} }
  };

  constructor(
    private schemaService: SchemaService,
    private referralService: ReferralService,
    private appsyncService: AppSyncService,
    private clinicSetupService: ClinicSetupService
  ) {}

  queryFilterData(type: FilterType, filter: FilterGroup[]): Observable<any[]> {
    const fetchAllData = Object.keys(this.filterTypeCachedFilterMap[type].data).length <= 0;

    return this.appsyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(getFilteredData),
          variables: {
            type,
            filter: JSON.stringify(filter || { fetch: fetchAllData ? 'all' : 'updates' }),
            clinicId: this.clinicSetupService.clinicId
          } as GetFilteredDataQueryVariables,
          fetchPolicy: fetchAllData ? 'cache-first' : 'network-only'
        })
      ),
      map((result: ApolloQueryResult<GetFilteredDataQuery>) =>
        JSON.parse(
          pako.inflate(JSON.parse(result.data.getFilteredData).data, {
            to: 'string'
          })
        )
      ),
      map((data: any[]) => {
        data.forEach(item => {
          this.filterTypeCachedFilterMap[type].data[item.id] = item;
        });
        return [...Object.values(this.filterTypeCachedFilterMap[type].data)];
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { IntakeFormService } from '../intake-form.service';
import { IntakeForm } from './../../../API';

@Component({
  selector: 'csi-intake-forms-view',
  templateUrl: './intake-forms-view.component.html',
  styleUrls: ['./intake-forms-view.component.css']
})
export class IntakeFormsViewComponent implements OnInit {
  constructor(
    public intakeService: IntakeFormService,
    private clinicSetupService: ClinicSetupService,
    private loadingSpinnerService: LoadingSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public isLoaded: boolean;
  public isLinked: boolean;

  ngOnInit() {
    this.loadingSpinnerService.show();
    this.intakeService
      .getIntakeForms(this.clinicSetupService.clinicId)

      .subscribe(() => {
        this.isLoaded = true;
        this.loadingSpinnerService.hide();
      });
  }

  onIntakeFormSelect(intakeForm: IntakeForm) {
    this.intakeService.currentIntakeForm = intakeForm;
    this.router.navigate([intakeForm.id], {
      relativeTo: this.activatedRoute
    });
  }

  noSort() {
    return 0;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTooltip } from '@angular/material';
import { map, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { S3Object } from 'src/app/shared/attachments/s3-attachment.service';
import { UpdateReferralCenterInput } from './../../../API';
import { ReferralForm, ReferralService } from './../../referral/referral.service';
import { S3AttachmentService } from './../../shared/attachments/s3-attachment.service';
import { ClinicSetupService } from './../clinic-setup-modal/clinic-setup.service';

@Component({
  selector: 'csi-referral-center-setup-modal',
  templateUrl: './referral-center-setup-modal.component.html',
  styleUrls: ['./referral-center-setup-modal.component.css']
})
export class ReferralCenterSetupModalComponent implements OnInit {
  public isSaving: boolean;
  public readonly copyLink = 'Copy Referral Address';
  public readonly copied = 'Copied';
  public toolTipContent = this.copyLink;
  public shouldHighlight = false;
  public showDarkHighlight: boolean;

  public referralCenterFormGroup = new FormGroup({
    diseaseAreas: new FormControl(this.referralService.diseaseAreasForReferralCenter, [
      Validators.required
    ]),
    notificationRecipients: new FormControl(this.referralService.notificationRecipients, [
      Validators.required
    ]),
    status: new FormControl(this.referralService.referralStatus),
    referralForms: new FormControl(
      (this.referralService.referralCenter.referralForms && [
        ...this.referralService.referralCenter.referralForms
      ]) ||
        []
    ),
    agreement: new FormControl(this.referralService.referralCenter.agreement || ''),
    education: new FormControl(this.referralService.referralCenter.educationEmbedLink)
  });

  public referralCenterLink = `${window.location.protocol}//${window.location.host}/refer/link/${this.referralService.referralCenter.id}`;
  public isClinicAdmin: boolean;
  constructor(
    public referralService: ReferralService,
    private dialogRef: MatDialogRef<ReferralCenterSetupModalComponent>,
    private authService: AuthenticationService,
    private s3AttachmentService: S3AttachmentService,
    private clinicSetupService: ClinicSetupService
  ) {}

  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;

  ngOnInit() {
    this.isClinicAdmin = this.clinicSetupService.isClinicAdmin;
  }

  static open(dialog: MatDialog) {
    dialog.open(ReferralCenterSetupModalComponent, { width: '850px', maxWidth: '95vw' });
  }

  save() {
    this.referralCenterFormGroup.markAllAsTouched();
    if (this.referralCenterFormGroup.valid) {
      this.isSaving = true;

      // Delay execution to help other forms save first
      setTimeout(() => {
        const referralCenterFormGroupValue = this.referralCenterFormGroup.value;

        // Automatically moving other to the end
        const indexOfOther = referralCenterFormGroupValue.diseaseAreas.findIndex(
          diseaseArea => diseaseArea.toLowerCase() === 'other'
        );

        if (indexOfOther >= 0) {
          referralCenterFormGroupValue.diseaseAreas.splice(indexOfOther, 1);
          referralCenterFormGroupValue.diseaseAreas.push('Other');
        }

        const updateReferralCenterInput: UpdateReferralCenterInput = {
          id: this.referralService.referralCenter.id
        };

        if (this.referralCenterFormGroup.controls.diseaseAreas.value) {
          updateReferralCenterInput.diseaseAreas = this.referralCenterFormGroup.controls.diseaseAreas.value;
        }

        if (this.referralCenterFormGroup.controls.notificationRecipients.value) {
          updateReferralCenterInput.notificationRecipients = this.referralCenterFormGroup.controls.notificationRecipients.value;
        }

        if (this.referralCenterFormGroup.controls.agreement.value) {
          updateReferralCenterInput.agreement = this.referralCenterFormGroup.controls.agreement.value.replace(
            /max-width.*?;|width.*?;/g,
            ''
          );
        }
        if (this.referralCenterFormGroup.controls.education.value) {
          updateReferralCenterInput.educationEmbedLink = this.referralCenterFormGroup.controls.education.value;
        }

        this.authService
          .getIdentityId()
          .pipe(
            map(identityId => {
              return (this.referralCenterFormGroup.controls.referralForms
                .value as ReferralForm[]).map((referralForm: ReferralForm) => ({
                name: referralForm.name,
                type: referralForm.type,
                file: this.s3AttachmentService.convertToS3ObjectInput(
                  identityId,
                  referralForm.file as S3Object
                )
              }));
            }),
            mergeMap(referralForms => {
              return this.referralService.updateReferralCenter({
                ...updateReferralCenterInput,
                referralForms
              });
            })
          )
          .subscribe(result => {
            this.isSaving = false;
            this.referralService.diseaseAreasForReferralCenter =
              referralCenterFormGroupValue.diseaseAreas;
            this.referralService.notificationRecipients =
              referralCenterFormGroupValue.notificationRecipients;
            this.referralService.referralCenter.referralForms = result.referralForms;
            this.referralService.referralCenter.agreement = referralCenterFormGroupValue.agreement;
            this.referralService.referralCenter.educationEmbedLink =
              referralCenterFormGroupValue.educationEmbedLink;
            this.dialogRef.close();
          });
      });
    }
  }

  onCopy() {
    this.toolTipContent = this.copied;
    this.tooltip.show();
    setTimeout(() => (this.toolTipContent = this.copyLink), 3500);
    setTimeout(() => {
      this.tooltip.show();
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldGroupComponent } from './mat-form-field-group.component';
import { MatFormFieldGroupInputComponent } from './mat-form-field-group-input/mat-form-field-group-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [MatFormFieldGroupComponent, MatFormFieldGroupInputComponent],
  imports: [CommonModule, MatFormFieldModule],
  exports: [MatFormFieldGroupComponent]
})
export class CSIMatFormFieldGroupModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CustomFormsComponent } from './custom-forms/custom-forms.component';
import { FormTemplateBuilderComponent } from './form-template-builder/form-template-builder.component';
import { FormTemplateCheckboxFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-checkbox-field-config/form-template-checkbox-field-config.component';
import { FormTemplateDateFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-date-field-config/form-template-date-field-config.component';
import { FormTemplateDropdownFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-dropdown-field-config/form-template-dropdown-field-config.component';
import { FormTemplateFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-field-config.component';
import { FormTemplateRadioFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-radio-field-config/form-template-radio-field-config.component';
import { FormTemplateSliderFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-slider-field-config/form-template-slider-field-config.component';
import { FormTemplateTextFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-text-field-config/form-template-text-field-config.component';
import { FormTemplateValueFieldConfigComponent } from './form-template-builder/form-template-field-config/form-template-value-field-config/form-template-value-field-config.component';
import { FormTemplateFieldComponent } from './form-template-builder/form-template-field/form-template-field.component';
import { FormTemplateGroupComponent } from './form-template-builder/form-template-group/form-template-group.component';
import { FormTemplateCheckboxFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-checkbox-field-generator/form-template-checkbox-field-generator.component';
import { FormTemplateDateFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-date-field-generator/form-template-date-field-generator.component';
import { FormTemplateDropdownFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-dropdown-field-generator/form-template-dropdown-field-generator.component';
import { FormTemplateEmailFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-email-field-generator/form-template-email-field-generator.component';
import { FormTemplateFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-field-generator.component';
import { FormTemplatePhoneFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-phone-field-generator/form-template-phone-field-generator.component';
import { FormTemplateRadioFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-radio-field-generator/form-template-radio-field-generator.component';
import { FormTemplateSliderFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-slider-field-generator/form-template-slider-field-generator.component';
import { FormTemplateTextFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-text-field-generator/form-template-text-field-generator.component';
import { FormTemplateGeneratorComponent } from './form-template-generator/form-template-generator.component';
import { FormTemplateGroupGeneratorComponent } from './form-template-generator/form-template-group-generator/form-template-group-generator.component';
import { FormTemplateHealthcardFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-healthcard-field-generator/form-template-healthcard-field-generator.component';
import { FormTemplateListFieldGeneratorComponent } from './form-template-generator/form-template-field-generator/form-template-list-field-generator/form-template-list-field-generator.component';

@NgModule({
  declarations: [
    FormTemplateGeneratorComponent,
    CustomFormsComponent,
    FormTemplateBuilderComponent,
    FormTemplateGroupComponent,
    FormTemplateFieldComponent,
    FormTemplateFieldConfigComponent,
    FormTemplateDropdownFieldConfigComponent,
    FormTemplateTextFieldConfigComponent,
    FormTemplateRadioFieldConfigComponent,
    FormTemplateSliderFieldConfigComponent,
    FormTemplateCheckboxFieldConfigComponent,
    FormTemplateDateFieldConfigComponent,
    FormTemplateValueFieldConfigComponent,
    FormTemplateGroupGeneratorComponent,
    FormTemplateFieldGeneratorComponent,
    FormTemplateTextFieldGeneratorComponent,
    FormTemplateCheckboxFieldGeneratorComponent,
    FormTemplateDropdownFieldGeneratorComponent,
    FormTemplateDateFieldGeneratorComponent,
    FormTemplateRadioFieldGeneratorComponent,
    FormTemplateSliderFieldGeneratorComponent,
    FormTemplateEmailFieldGeneratorComponent,
    FormTemplatePhoneFieldGeneratorComponent,
    FormTemplateHealthcardFieldGeneratorComponent,
    FormTemplateListFieldGeneratorComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [FormTemplateGeneratorComponent]
})
export class FormTemplateModule {}

import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';
import { Assessment } from 'src/app/core/api/assessment.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { QuestionnaireScorePipe } from 'src/app/shared/shared-pipes/questionnaire-score.pipe';
import { QuestionnaireResponseModalComponent } from '../../../questionnaire-response-modal/questionnaire-response-modal.component';
import { QuestionnaireRequestService } from './../../../../../core/api/questionnaire-request.service';

@Component({
  selector: 'csi-link-assessment-to-questionnaire-modal',
  templateUrl: './link-assessment-to-questionnaire-modal.component.html',
  styleUrls: ['./link-assessment-to-questionnaire-modal.component.css'],
  providers: [QuestionnaireScorePipe]
})
export class LinkQuestionnaireModalComponent implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(this.newlyLinkedQuestionnaires);
  }
  public saveFn: () => Observable<Assessment>;
  public questionnaireRequests: QuestionnaireRequest[];

  public assessment: Assessment;
  public assessmentId: string;
  public currentDateString: string;

  public linking = false;
  public notEmpty = false;

  public questionnaireRequestsByType: {
    [key in Exclude<QuestionnaireType, QuestionnaireType.CUSTOM>]: QuestionnaireRequest[];
  } & { [key: string]: QuestionnaireRequest[] };

  public questionnaireTypeLinkedStatus: {
    [key in Exclude<QuestionnaireType, QuestionnaireType.CUSTOM>]: boolean;
  } & { [key: string]: boolean };

  public newlyLinkedQuestionnaires: QuestionnaireRequest[] = [];

  public readonly questionnaireHeaderMap = {
    createdAt: 'Sent On',
    completedAt: 'Completed At',
    assessment: 'Linked Assessment Date',
    score: 'Score'
  };

  public readonly questionnaireHeaderKeysWithoutActionKey = Object.keys(
    this.questionnaireHeaderMap
  );

  public readonly questionnaireHeaderKeysWithActionsKey = [
    ...this.questionnaireHeaderKeysWithoutActionKey,
    'actions'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<LinkQuestionnaireModalComponent>,
    private dialog: MatDialog,
    public patientService: PatientService,
    public questionnaireRequestService: QuestionnaireRequestService,
    public loadingSpinnerService: LoadingSpinnerService,
    public confirmModalService: ConfirmModalService,
    public datePipe: DatePipe
  ) {
    this.saveFn = data.saveFn;

    this.questionnaireRequestsByType = {
      [QuestionnaireType.CDERFS]: [],
      [QuestionnaireType.DERFS]: [],
      [QuestionnaireType.DEQ]: [],
      [QuestionnaireType.SPEED]: [],
      [QuestionnaireType.SPEEDII]: [],
      [QuestionnaireType.OSDI]: [],
      [QuestionnaireType.OSDIAndSPEED]: []
    };

    this.questionnaireTypeLinkedStatus = {
      [QuestionnaireType.CDERFS]: false,
      [QuestionnaireType.DERFS]: false,
      [QuestionnaireType.DEQ]: false,
      [QuestionnaireType.SPEED]: false,
      [QuestionnaireType.SPEEDII]: false,
      [QuestionnaireType.OSDI]: false,
      [QuestionnaireType.OSDIAndSPEED]: false
    };

    const pathname = location.pathname;
    this.assessmentId = pathname.substring(pathname.indexOf(':') + 1, pathname.length - 1);
    this.assessment =
      this.assessmentId !== 'new' &&
      this.patientService.assessments.find(assessment => assessment.id === this.assessmentId);

    this.currentDateString =
      this.assessmentId === 'new'
        ? this.datePipe.transform(new Date())
        : this.datePipe.transform(this.assessment.createdAt);
  }

  ngOnInit() {
    this.questionnaireRequests = this.patientService.questionnaires;

    this.dialogRef.backdropClick().subscribe(() => {
      if (this.linking) return;
      this.dialogRef.close(this.newlyLinkedQuestionnaires);
    });

    this.groupQuestionnaireRequestsByType();
    this.filterQuestionnairesByType();
  }

  private filterQuestionnairesByType() {
    Object.keys(this.questionnaireRequestsByType).forEach(questionnaireType => {
      const lastCompletedQuestionnaire = this.getLastCompletedLinkedToCurrentAssessmentQuestionnaire(
        questionnaireType
      );
      if (lastCompletedQuestionnaire) {
        this.questionnaireRequestsByType[questionnaireType] = [
          ...this.filterQuestionnairesStartingFromDate(
            lastCompletedQuestionnaire.completedAt,
            this.questionnaireRequestsByType[questionnaireType]
          )
        ];
      }
      if (
        questionnaireType === QuestionnaireType.CDERFS ||
        questionnaireType === QuestionnaireType.DERFS
      ) {
        this.questionnaireRequestsByType[questionnaireType] = [
          ...this.filterQuestionnairesToOneYearFromCompletion(
            this.questionnaireRequestsByType[questionnaireType]
          )
        ];
      } else {
        this.questionnaireRequestsByType[questionnaireType] = [
          ...this.filterQuestionnairesToThreeWeeksFromCompletion(
            this.questionnaireRequestsByType[questionnaireType]
          )
        ];
      }

      if (this.questionnaireRequestsByType[questionnaireType].length > 0) {
        this.notEmpty = true;
      }
    });
  }

  private groupQuestionnaireRequestsByType() {
    this.questionnaireRequests.forEach(questionnaireRequest => {
      if (questionnaireRequest.type === QuestionnaireType.CUSTOM) {
        if (!questionnaireRequest.config) {
          return;
        }
        const questionnaireType =
          (questionnaireRequest.config as any) instanceof Object
            ? questionnaireRequest.config['schema']['abbreviation']
            : JSON.parse(questionnaireRequest.config).schema.abbreviation;
        // const questionnaireType = (JSON.parse(questionnaireRequest.config || '{}')
        //     .schema as FormTemplateSchema).abbreviation;

        if (!this.questionnaireRequestsByType[questionnaireType]) {
          this.questionnaireRequestsByType[questionnaireType] = [];
        }
        this.questionnaireRequestsByType[questionnaireType].push(questionnaireRequest);

        this.questionnaireTypeLinkedStatus[questionnaireType] = false;
      } else {
        this.questionnaireRequestsByType[questionnaireRequest.type as QuestionnaireType].push(
          questionnaireRequest
        );
      }
    });
  }

  private getLastCompletedLinkedToCurrentAssessmentQuestionnaire(questionnaireType: string) {
    if (this.assessmentId === 'new') return null;
    const linkedQuestionnaires = this.questionnaireRequestsByType[questionnaireType].filter(
      questionnaire => {
        return questionnaire.assessment ? questionnaire.assessment.id === this.assessmentId : false;
      }
    );
    const mostRecentDate = new Date(
      Math.max.apply(
        null,
        linkedQuestionnaires.map(item => {
          return new Date(item.completedAt);
        })
      )
    );
    const mostRecentObject = linkedQuestionnaires.filter(item => {
      const d = new Date(item.completedAt);
      return d.getTime() === mostRecentDate.getTime();
    })[0];
    return mostRecentObject;
  }

  filterQuestionnairesToThreeWeeksFromCompletion(questionnairesToFilter: QuestionnaireRequest[]) {
    return this.filterQuestionnairesByTimeFrameFromCompletion(questionnairesToFilter, 21);
  }

  filterQuestionnairesToOneYearFromCompletion(questionnairesToFilter: QuestionnaireRequest[]) {
    return this.filterQuestionnairesByTimeFrameFromCompletion(questionnairesToFilter, 365);
  }

  private filterQuestionnairesStartingFromDate(
    questionnaireDate: string,
    questionnairesToFilter: QuestionnaireRequest[]
  ) {
    const date = new Date(questionnaireDate);
    const time = date.getTime();
    return questionnairesToFilter.filter(questionnaire => {
      return new Date(questionnaire.completedAt).getTime() >= time;
    });
  }

  // Return questionnaires completed between the assessment time minus the negative offset, and the assessment time plus the positive offset.
  filterQuestionnairesByTimeOffsetFromCompletion(
    questionnairesToFilter: QuestionnaireRequest[],
    negativeTimeOffsetInMilliseconds: number,
    positiveTimeOffsetInMilliseconds: number
  ) {
    let assessmentDate = new Date();
    if (this.assessmentId !== 'new') assessmentDate = new Date(this.assessment.createdAt);
    assessmentDate.setHours(0, 0, 0, 0);
    const assessmentTime = assessmentDate.getTime();
    const maxQuestionnaireTime = assessmentTime + positiveTimeOffsetInMilliseconds;
    const minQuestionnaireTime = assessmentTime - negativeTimeOffsetInMilliseconds;

    return questionnairesToFilter.filter(questionnaire => {
      if (!questionnaire.completedAt) return false;
      const questionnaireDate = new Date(questionnaire.completedAt);
      questionnaireDate.setHours(0, 0, 0, 0);
      const questionnaireTime = questionnaireDate.getTime();
      return questionnaireTime <= maxQuestionnaireTime && questionnaireTime >= minQuestionnaireTime;
    });
  }

  filterQuestionnairesByTimeFrameFromCompletion(
    questionnairesToFilter: QuestionnaireRequest[],
    timeFrameInDays: number
  ) {
    let assessmentDate = new Date();
    if (this.assessmentId !== 'new') assessmentDate = new Date(this.assessment.createdAt);
    assessmentDate.setHours(0, 0, 0, 0);

    return questionnairesToFilter.filter(questionnaire => {
      if (!questionnaire.completedAt) return false;
      const questionnaireDate = new Date(questionnaire.completedAt);

      questionnaireDate.setHours(0, 0, 0, 0);
      return this.isWithinTimeFrame(assessmentDate, questionnaireDate, timeFrameInDays);
    });
  }

  isWithinTimeFrame(assessmentDate: Date, questionnaireDate: Date, timeFrameInDays: number) {
    const oneDayInMilliseconds = 86400000;
    const timeDifferenceInMilliseconds = Math.abs(
      questionnaireDate.getTime() - assessmentDate.getTime()
    );
    return timeDifferenceInMilliseconds <= oneDayInMilliseconds * timeFrameInDays;
  }

  showQuestionnaireResponse(questionnaireRequest: QuestionnaireRequest) {
    QuestionnaireResponseModalComponent.open(this.dialog, questionnaireRequest);
  }

  linkQuestionnaire(questionnaire: QuestionnaireRequest) {
    this.loadingSpinnerService.show();

    let questionnaireType: any = questionnaire.type;
    if (questionnaireType === QuestionnaireType.CUSTOM) {
      questionnaireType =
        (questionnaire.config as any) instanceof Object
          ? questionnaire.config['schema']['abbreviation']
          : JSON.parse(questionnaire.config).schema.abbreviation;
    }
    const selectedQuestionnaire = this.questionnaireRequestsByType[questionnaireType].find(
      patientQuestionnaire => patientQuestionnaire.id === questionnaire.id
    );

    // Assign to temporary empty assessment
    selectedQuestionnaire.assessment = {
      __typename: 'Assessment',
      createdAt: new Date().toDateString()
    };

    this.questionnaireTypeLinkedStatus = {
      ...this.questionnaireTypeLinkedStatus,
      ...{ [questionnaireType]: true }
    };

    if (this.assessmentId === 'new') {
      this.save(questionnaire);
    } else {
      this.updateQuestionnaire(questionnaire, this.assessment);
    }
    this.questionnaireRequestsByType[questionnaireType] = [
      ...this.filterQuestionnairesStartingFromDate(
        questionnaire.completedAt,
        this.questionnaireRequestsByType[questionnaireType]
      )
    ];

    this.loadingSpinnerService.hide();
  }

  onLink(questionnaire: QuestionnaireRequest) {
    let confirmButtonLabel = 'Link';
    if (this.assessmentId === 'new') {
      confirmButtonLabel = 'Create Assessment & Link';
    }

    let questionnaireSpecificMessage =
      'This will overwrite any existing score in the ' + questionnaire.type + ' symtpom field.';
    if (
      questionnaire.type === QuestionnaireType.CDERFS ||
      questionnaire.type === QuestionnaireType.DERFS
    ) {
      questionnaireSpecificMessage =
        // tslint:disable-next-line:quotemark
        'This will overwrite all existing information filled out in Medical History for this encounter. ';
    } else if (questionnaire.type === QuestionnaireType.OSDIAndSPEED) {
      questionnaireSpecificMessage =
        'This will overwrite any existing score in the OSDI and SPEED symtpom fields for this encounter.';
    }

    this.confirmModalService
      .show(
        'Link Questionnaire',
        // tslint:disable-next-line:quotemark
        `Linking to ${
          this.questionnaireRequestService.questionnaireTypeToFriendlyNameMap[questionnaire.type]
        } Questionnaire completed on ${this.datePipe.transform(
          questionnaire.completedAt
        )}. ${questionnaireSpecificMessage} Once linked to this questionnaire, you cannot unlink. Do you still wish to continue?`,
        confirmButtonLabel,
        'Cancel'
      )
      .subscribe(continueLinking => {
        if (continueLinking) {
          this.linkQuestionnaire(questionnaire);
        }
      });
  }

  save(questionnaire: QuestionnaireRequest) {
    this.linking = true;
    this.saveFn().subscribe(assessmentData => {
      this.updateQuestionnaire(questionnaire, assessmentData);
    });
  }

  updateQuestionnaire(questionnaire: QuestionnaireRequest, assessmentData: Assessment) {
    this.linking = true;
    this.assessmentId = assessmentData.id;
    this.questionnaireRequestService
      .updateQuestionnaireRequest(
        {
          id: questionnaire.id,
          questionnaireRequestAssessmentId: this.assessmentId
        },
        false
      )
      .subscribe(questionnaireResult => {
        assessmentData.questionnaireRequests = {
          ...assessmentData.questionnaireRequests,
          items: assessmentData.questionnaireRequests
            ? [...assessmentData.questionnaireRequests.items, questionnaireResult]
            : [questionnaireResult]
        };
        questionnaire.assessment = assessmentData;
      });
    this.newlyLinkedQuestionnaires.push(questionnaire);
    this.linking = false;
  }
}

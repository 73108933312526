import { Injectable } from '@angular/core';
import { SymptomValuePipe } from '../../symptoms/pipes/symptom-value.pipe';
import { SymptomsPipe } from '../../symptoms/pipes/symptoms.pipe';
import { SchemaService } from '../../symptoms/services/schema/schema.service';
import { SymptomModes } from '../../symptoms/symptom.model';
interface KeyValueMap {
  [key: string]: string;
}

export interface EyeInformation {
  frequency: number;
  color: string;
  severity: string;
}

interface SignDataClassification {
  leftEye: {};
  rightEye: {};
}
interface ColorDescriptor {
  [color: string]: {
    text: string;
    severity: string;
    colorCode: string; // Hexadecimal color code
  };
}

@Injectable({
  providedIn: 'root'
})
export class PdfSummaryService {
  private severityLevelArray = [
    'Normal',
    'Moderate',
    'Severe',
    'Mild',
    'Mild To Moderate',
    'Moderate To Severe'
  ];

  public severityClassification: {
    [key in 'leftEye' | 'rightEye']: { [key: string]: EyeInformation };
  } = {
    leftEye: {},
    rightEye: {}
  };
  public colorMap: ColorDescriptor = {
    green: {
      text: 'Optimal',
      severity: 'Normal',
      colorCode: '#40c340'
    },
    yellow: {
      text: 'Elevated',
      severity: 'Mild',
      colorCode: '#ffc107'
    },
    orange: {
      text: 'High',
      severity: 'Mild To Moderate',
      colorCode: '#ffa500'
    },
    yelloworange: {
      text: 'Significant',
      severity: 'Moderate',
      colorCode: '#ffc107' // Example yellow-orange color code
    },
    orangered: {
      // Adding orange-red color descriptor
      text: 'Urgent',
      severity: 'Moderate To Severe',
      colorCode: '#ff5900' // Orange-Red color, you can adjust this
    },
    red: {
      text: 'Critical',
      severity: 'Severe',
      colorCode: '#ff0000'
    }
    // Add more colors as needed ffbf00
  };
  // private severityClassification: { ['leftEye', 'rightEye']: SignDataClassification } = {};

  constructor(
    private symptomPipe: SymptomsPipe,
    private schemaService: SchemaService,
    private symptomValuePipe: SymptomValuePipe
  ) {}

  public getSeverityLevel(formKeyValue: KeyValueMap, mode: SymptomModes) {
    this.severityClassification = { leftEye: {}, rightEye: {} };
    if (formKeyValue) {
      const result = this.symptomPipe.transform(formKeyValue);
      result.forEach(signData => {
        const symptomKey = signData.key;

        ['rightEye', 'leftEye'].forEach(sideKey => {
          const side = signData[sideKey];
          if (side) {
            const parsedValue = this.parseValue(side.value);
            const thermalScale = this.schemaService.getThermalScaleConfig(symptomKey, side.method);

            let severityColor = this.getColorFromValue(parsedValue, thermalScale);
            if (severityColor) {
              severityColor = severityColor.replace(/\s/g, '');
              this.severityClassification[sideKey][severityColor] = this.severityClassification[
                sideKey
              ][severityColor]
                ? {
                    frequency: ++this.severityClassification[sideKey][severityColor].frequency,
                    color: this.severityClassification[sideKey][severityColor].color,
                    severity: this.severityClassification[sideKey][severityColor].severity
                  }
                : {
                    frequency: 1,
                    color:
                      (this.colorMap[severityColor] && this.colorMap[severityColor].colorCode) ||
                      '',
                    severity:
                      (this.colorMap[severityColor] && this.colorMap[severityColor].severity) || ''
                  };
            }
          }
        });
      });
    }
  }

  getSeverity(value, symptomKey) {
    if (symptomKey) {
      const symptomValue = this.symptomValuePipe.transform(value, symptomKey);

      let symptomSeverity;

      this.severityLevelArray.forEach(severity => {
        if (('' + symptomValue).toLowerCase().includes(severity.toLowerCase())) {
          symptomSeverity = severity;
        }
      });

      return symptomSeverity;
    }
  }

  public getColorFromValue(value: number | string, thermalScale: any = {}): string | null {
    if (thermalScale === null || thermalScale === undefined) return null;

    for (const [color, condition] of Object.entries(thermalScale)) {
      if (color === 'linear') {
        return this.mapLinearValueToColor(+value);
      }
      if (color === 'hide') {
        if (typeof condition === 'string' && condition.startsWith('>')) {
          const comparValue = +condition.slice(1);
          if (+value > comparValue) {
            return '';
          }
        }
      }

      if (typeof condition === 'string') {
        if (condition.includes('-')) {
          let [min, max] = condition.split('-').map(Number);

          if (min > max) {
            const temp = max;
            max = min;
            min = temp;
          }

          if (typeof value === 'number' && value >= min && value <= max) {
            return color;
          }
        } else if (condition.startsWith('>=')) {
          const compareValue = +condition.slice(2);
          if (typeof value === 'number' && value >= compareValue) {
            return color;
          }
        } else if (condition.startsWith('>')) {
          const compareValue = +condition.slice(1);
          if (typeof value === 'number' && value > compareValue) {
            return color;
          }
        } else if (condition.startsWith('<')) {
          const compareValue = +condition.slice(1);
          if (typeof value === 'number' && value < compareValue) {
            return color;
          }
        } else if (value === condition) {
          return color;
        }
      }
    }

    return null;
  }

  private mapLinearValueToColor(value: number): string {
    if (value > 10) return 'green';

    const severity = 10 - value;
    if (severity <= 2) {
      return 'green';
    } else if (severity <= 5) {
      return 'yellow';
    } else if (severity <= 8) {
      return 'orange';
    } else {
      return 'red';
    }
  }
  parseValue(val: any) {
    if (typeof val === 'object') {
      return Object.entries(val)
        .map(([k, v]) => `${k[0].toUpperCase() + k.substring(1)}: ${v}`)
        .join(', ');
    }
    return val;
  }
}

import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { EConsultService } from 'src/app/econsult/service/econsult.service';
import { AssessmentFormGroup } from './assessment-form.model';

@Component({
  selector: 'csi-assessment-form',
  templateUrl: './assessment-form.component.html',
  styleUrls: ['./assessment-form.component.css']
})
export class AssessmentFormComponent {
  @Input() formGroup: AssessmentFormGroup;
  @Input() showImageRequiredError = false;

  public expandAttachments$: Observable<boolean> = this.econsultService.shouldExpandAttachments();

  constructor(private econsultService: EConsultService) {}

  get hasImages(): boolean {
    return this.formGroup.controls.images.value && this.formGroup.controls.images.value.length > 0;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Treatment } from 'src/app/core/remote-json/treatments/treatments.service';
import { EConsultFormGroup } from 'src/app/econsult/econsult-form.model';
import {
  CreateTreatmentTemplateInput,
  TreatmentTemplate,
  UpdateTreatmentTemplateInput
} from './../../../../../API';
import { TreatmentFiltersCDSService } from './../../../../shared/consult-forms/dry-eye-forms/dry-eye-assessment-form/treatment-filters/treatment-filters-cds.service';
import { ClinicSetupService } from './../../clinic-setup.service';
import { TreatmentTemplateService } from './../treatment-template.service';

interface TreatmentTemplateValues {
  id: string;
  friendlyId: string;
  copiedTreatmentTemplateId: string;
  treatmentTemplateClinicId: string;
  otcEyeDrops: Treatment[];
  rxEyeDrops: Treatment[];
  procedures: Treatment[];
  exercises: Treatment[];
}

interface TreatmentTemplateActionModalData {
  isView: boolean;
  action: 'add' | 'update';
  treatmentTemplate?: TreatmentTemplate;
}

@Component({
  selector: 'csi-treatment-template-actions-modal',
  templateUrl: './treatment-template-actions-modal.component.html',
  styleUrls: ['./treatment-template-actions-modal.component.css'],
  providers: [TreatmentFiltersCDSService, EConsultFormGroup]
})
export class TreatmentTemplateActionsModalComponent implements OnInit {
  public isLoading: boolean;
  public treatmentIdControl = new FormControl(null, [
    Validators.required,
    Validators.pattern(/^[a-zA-Z0-9\-\_\.]{1,15}$/),
    this.treatmentTemplateIdValidator()
  ]);

  public exercisesControl = new FormControl();
  public otcEyeDropsControl = new FormControl();
  public rxEyeDropsControl = new FormControl();
  public proceduresControl = new FormControl();

  public treatmentTemplateFormGroup = new FormGroup({
    id: new FormControl(),
    copiedTreatmentTemplateId: new FormControl(),
    friendlyId: this.treatmentIdControl,
    otcEyeDrops: this.otcEyeDropsControl,
    rxEyeDrops: this.rxEyeDropsControl,
    procedures: this.proceduresControl,
    exercises: this.exercisesControl
  });

  constructor(
    public treatmentFiltersCDSService: TreatmentFiltersCDSService,
    private treatmentTemplateService: TreatmentTemplateService,
    private clinicSetupService: ClinicSetupService,
    private dialogRef: MatDialogRef<TreatmentTemplateActionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TreatmentTemplateActionModalData
  ) {}

  ngOnInit() {
    if (this.data.treatmentTemplate) {
      this.treatmentTemplateFormGroup.patchValue(this.data.treatmentTemplate);

      if (this.data.action === 'add') {
        this.treatmentTemplateFormGroup.controls.copiedTreatmentTemplateId.setValue(
          this.data.treatmentTemplate.id
        );
        [
          this.treatmentTemplateFormGroup.controls.friendlyId,
          this.treatmentTemplateFormGroup.controls.id
        ].forEach(control => control.setValue(null));
      }
    }
    if (this.data.isView) {
      this.treatmentIdControl.disable();
      this.exercisesControl.disable();
      this.otcEyeDropsControl.disable();
      this.rxEyeDropsControl.disable();
      this.proceduresControl.disable();
    }
  }

  addTreatmentTemplate() {
    if (this.treatmentTemplateFormGroup.valid) {
      this.isLoading = true;
      this.treatmentTemplateService
        .addTreatmentTemplate(
          this.parseTreatmentTemplateMutationInput({
            ...this.treatmentTemplateFormGroup.value,
            treatmentTemplateClinicId: this.clinicSetupService.clinicId
          }) as CreateTreatmentTemplateInput
        )
        .subscribe(addedTreatmentTemplate => {
          this.treatmentTemplateService.addToTreatmentTemplateControl(addedTreatmentTemplate);
          this.isLoading = false;
          this.dialogRef.close(addedTreatmentTemplate);
        });
    }
  }

  updateTreatmentTemplate() {
    if (this.treatmentTemplateFormGroup.valid) {
      this.isLoading = true;
      this.treatmentTemplateService
        .updateTreatmentTemplate(
          this.parseTreatmentTemplateMutationInput({
            ...this.treatmentTemplateFormGroup.value,
            treatmentTemplateClinicId: this.clinicSetupService.clinicId
          }) as UpdateTreatmentTemplateInput
        )
        .subscribe(updatedTreatmentTemplate => {
          this.treatmentTemplateService.updateTreatmentTemplateControl(updatedTreatmentTemplate);
          this.isLoading = false;
          this.dialogRef.close(updatedTreatmentTemplate);
        });
    }
  }

  parseTreatmentTemplateMutationInput({
    id,
    friendlyId,
    treatmentTemplateClinicId,
    copiedTreatmentTemplateId,
    ...treatmentFormControls
  }: TreatmentTemplateValues): CreateTreatmentTemplateInput | UpdateTreatmentTemplateInput {
    const treatmentTemplateMutationInput:
      | CreateTreatmentTemplateInput
      | UpdateTreatmentTemplateInput = {
      friendlyId,
      treatmentTemplateClinicId,
      copiedTreatmentTemplateId,
      id
    };
    Object.entries(treatmentFormControls).forEach(([key, values]: [string, Treatment[]]) => {
      if (values) {
        treatmentTemplateMutationInput[key] = values.map(
          value => value.id || ((value as any) as string)
        );
      }
    });

    return treatmentTemplateMutationInput;
  }

  private treatmentTemplateIdValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        !this.treatmentTemplateService.treatmentTemplatesFormControl.value ||
        this.treatmentTemplateService.treatmentTemplatesFormControl.value.length <= 0
      ) {
        return null;
      }

      if (
        this.treatmentTemplateService.treatmentTemplatesFormControl.value.some(
          (treatmentTemplate: TreatmentTemplate) =>
            treatmentTemplate.friendlyId === control.value &&
            treatmentTemplate.id !== this.treatmentTemplateFormGroup.controls.id.value
        )
      ) {
        return { duplicate: true };
      }
      return null;
    };
  }
}

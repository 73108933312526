import { CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatTable, Sort } from '@angular/material';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TextFavorite } from 'src/API';
import { ClinicSetupService } from '../../clinic-setup.service';
import { defaultTreatmentTemplateMap } from '../../treatment-template/treatment-template-model';
import { TextFavoriteType, TreatmentTemplate } from './../../../../../API';
import { ConfirmModalService } from './../../../../shared/confirm-modal/confirm-modal.service';
import { defaultTextFavoriteMap } from './../text-favorite-model';
import { TextFavoriteSetupActionsModalComponent } from './../text-favorite-setup-actions-modal/text-favorite-setup-actions-modal.component';
import { TextFavoriteService } from './../text-favorite.service';

interface CategoryConfig<T> {
  custom: T extends 'Tab' ? { name: string } : any[];
  sysDefault: T extends 'Tab' ? { name: string } : any[];
  hidden: T extends 'Tab' ? { name: string } : any[];
  orderList: T extends 'Tab' ? { name: string } : any[];
}

type Category = keyof CategoryConfig<'Tab'>;

interface TabConfig {
  name: string;
  childTabConfig: CategoryConfig<'Tab'>;
}

interface FavoriteIdType {
  id: string;
  type: string;
  position?: number;
}

export interface TextFavoriteAction {
  textFavorite: TextFavorite;
  action: string;
  hide: boolean;
}

@Component({
  selector: 'csi-text-favorite-setup',
  templateUrl: './text-favorite-setup.component.html',
  styleUrls: ['./text-favorite-setup.component.scss']
})
export class TextFavoriteSetupComponent implements OnInit, OnDestroy {
  @ViewChild('favoriteTable', { read: ElementRef, static: false }) table: ElementRef;
  @ViewChild('table', { static: false }) tableData: MatTable<any>;

  @Input() isSaving: boolean;
  @Input() textFavoritesFormControl: FormControl;
  @Input() textFavoriteConfigFormControl: FormControl;
  @Input() textFavoritesHiddenFormControl: FormControl;

  public filterControl: FormControl = new FormControl();

  public showLess = true;

  public filteredFavorites: TextFavorite[] = this.clinicSetupService.textFavorites;

  public textFavoriteCategoryMap: { [keys in TextFavoriteType]: CategoryConfig<'Filter'> } = {
    Recommendations: {
      custom: [],
      sysDefault: [],
      hidden: [],
      orderList: []
    },
    Impressions: {
      custom: [],
      sysDefault: [],
      hidden: [],
      orderList: []
    },
    Goals: {
      custom: [],
      sysDefault: [],
      hidden: [],
      orderList: []
    }
  };

  public parentActiveTab: TextFavoriteType = TextFavoriteType['Recommendations'];
  public childActiveTab: Category = 'sysDefault';

  private childTabConfig: CategoryConfig<'Tab'> = {
    sysDefault: { name: 'System Default' },
    custom: { name: 'Custom' },
    hidden: { name: 'Hidden' },
    orderList: { name: 'Order List' }
  };

  public parentTabMap: { [keys in TextFavoriteType]: TabConfig } = {
    Recommendations: {
      name: 'Recommendations',
      childTabConfig: this.childTabConfig
    },
    Impressions: {
      name: 'Impressions',
      childTabConfig: this.childTabConfig
    },
    Goals: {
      name: 'Goals',
      childTabConfig: this.childTabConfig
    }
  };
  private childTabArray = Object.keys(this.childTabConfig) as Category[];
  private parentTabArray = Object.keys(this.parentTabMap) as TextFavoriteType[];

  public currentTextFavorites = [];

  buttonLabelBooleanMap: { [key: string]: boolean } = {};

  displayedColumns: string[] = ['shortcut', 'text'];
  displayColumnMap: { [key: string]: any } = {
    shortcut: { name: 'Shortcut' },
    text: { name: 'Text' },
    type: { name: 'Type' },
    friendlyId: { name: 'Treatment Template ID', showIn: 'Recommendations' },
    action: { name: 'Action' }
  };

  public duplicateShortcut: boolean;

  public activeIndex = null;

  private textFavoriteConfigMap: { [key: string]: FavoriteIdType[] } = {};

  constructor(
    public clinicSetupService: ClinicSetupService,
    public textFavoriteService: TextFavoriteService,
    private dialog: MatDialog,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    if (this.clinicSetupService.isClinicAdmin) {
      this.displayedColumns.push('action');
      this.displayColumnMap['action'] = { name: 'Action' };
    }

    this.categorizeTextFavorites();
    this.textFavoriteConfigMap = this.textFavoriteConfigFormControl.value || this.getAllOrderList();
  }
  ngOnDestroy(): void {}

  getCurrentTextFavorites() {
    return [
      ...(this.textFavoriteCategoryMap[this.parentActiveTab]['custom'] || []),
      ...this.textFavoriteCategoryMap[this.parentActiveTab]['sysDefault']
    ];
  }

  deleteTextFavorite(textFavoriteId: string) {
    this.confirmModalService
      .show(
        'Delete Text Favorite',
        'Are you sure you want to delete this text favorite? This action cannot be done.',
        'Delete Text Favorite',
        'Cancel'
      )
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            this.isSaving = true;
            return this.textFavoriteService.deleteTextFavorite(textFavoriteId);
          }
          return of(null);
        })
      )
      .subscribe(result => {
        if (result) {
          this.isSaving = false;
          const indexOfTextFavorite = this.findIndexOfTextFavorite(textFavoriteId);
          this.removeFromTextFavoritesArray(textFavoriteId);

          this.textFavoritesFormControl.value.splice(indexOfTextFavorite, 1);
        }
      });
  }

  findIndexOfTextFavorite(textFavoriteId: string, getItem: boolean = false): any {
    const textFavoritesFormControlValue: TextFavorite[] = [...this.textFavoritesFormControl.value];

    if (!textFavoritesFormControlValue) {
      return -1;
    }
    const index = textFavoritesFormControlValue.findIndex(
      textFavorite => textFavorite.id.toLowerCase().trim() === textFavoriteId.toLowerCase().trim()
    );
    if (index < 0) return -1;
    return getItem ? textFavoritesFormControlValue[index] : index;
  }

  removeFromTextFavoritesArray(textFavoriteId: string, childTab: Category = null) {
    childTab = childTab || this.childActiveTab;
    const index = this.textFavoriteCategoryMap[this.parentActiveTab][childTab].findIndex(
      textFavorite => textFavorite.id === textFavoriteId
    );
    this.textFavoriteCategoryMap[this.parentActiveTab][childTab].splice(index, 1);
    this.textFavoriteCategoryMap[this.parentActiveTab][childTab] = [
      ...this.textFavoriteCategoryMap[this.parentActiveTab][childTab]
    ];
    return index;
  }

  textFavoriteAction({
    textFavorite,
    action
  }: {
    textFavorite: TextFavorite;
    action: 'add' | 'update' | 'copy' | 'hide' | 'show' | 'remove';
  }) {
    if (['add', 'update', 'copy'].includes(action)) {
      this.handleTextFavoriteUpdates(textFavorite, action);
      return;
    }
    this.handleTextFavoriteStatusChange(textFavorite, action);
  }

  onParentSelect(event) {
    this.parentActiveTab = this.parentTabArray[event.index];
    this.childActiveTab = 'sysDefault';
  }
  onChildSelect(event) {
    this.childActiveTab = this.childTabArray[event.index];
    if (this.childActiveTab === 'orderList') {
      this.sortOrder();
    }
  }

  noSort() {
    return 0;
  }

  announceSortChange(sort: Sort, currentTextFavorites: Partial<TextFavorite>[]) {
    this.currentTextFavorites = [
      ...currentTextFavorites.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'shortcut':
            return this.compare(a.shortcut, b.shortcut, isAsc);
          case 'text':
            return this.compare(a.text.length, b.text.length, isAsc);
          case 'type':
            return this.compare(a.type, b.type, isAsc);
          case 'friendlyId':
            return this.compare(
              a.treatmentTemplate && a.treatmentTemplate.friendlyId.length,
              b.treatmentTemplate && b.treatmentTemplate.friendlyId.length,
              isAsc
            );
        }
      })
    ];
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortOrder() {
    this.currentTextFavorites = this.getCurrentTextFavorites().sort(
      (a, b) =>
        (this.textFavoriteConfigMap[this.parentActiveTab] || []).findIndex(
          textFav => textFav.id === a.id
        ) -
        (this.textFavoriteConfigMap[this.parentActiveTab] || []).findIndex(
          textFav => textFav.id === b.id
        )
    );
  }

  handleTextFavoriteUpdates(
    textFavorite: TextFavorite,
    action: 'add' | 'update' | 'copy' | 'hide' | 'show' | 'remove'
  ) {
    return this.dialog
      .open(TextFavoriteSetupActionsModalComponent, {
        width: '75vw',
        data: { textFavorite, action, type: this.parentTabMap[this.parentActiveTab].name }
      })
      .afterClosed()
      .subscribe((textFavoriteActionConfig: TextFavoriteAction) => {
        if (!textFavoriteActionConfig.textFavorite) return of(null);

        const updatedTextFavorite = textFavoriteActionConfig.textFavorite;
        if (!!updatedTextFavorite.textFavoriteTreatmentTemplateId) {
          updatedTextFavorite.treatmentTemplate = defaultTreatmentTemplateMap[
            updatedTextFavorite.textFavoriteTreatmentTemplateId
          ] as TreatmentTemplate;
        }

        this.addToCustomArray(updatedTextFavorite, 0);

        if (textFavoriteActionConfig.hide) {
          this.toggleShowHide(textFavorite, 'sysDefault', 'hidden');
        }

        this.textFavoriteCategoryMap = JSON.parse(JSON.stringify(this.textFavoriteCategoryMap));
      });
  }

  handleTextFavoriteStatusChange(
    textFavorite: TextFavorite,
    action: 'add' | 'update' | 'copy' | 'hide' | 'show' | 'remove'
  ) {
    if (action === 'hide') {
      this.toggleShowHide(textFavorite, 'sysDefault', 'hidden');
    }

    if (action === 'show') {
      this.toggleShowHide(textFavorite, 'hidden', 'sysDefault');
    }

    if (action === 'remove') {
      this.deleteTextFavorite(textFavorite.id);
    }

    this.textFavoriteCategoryMap = JSON.parse(JSON.stringify(this.textFavoriteCategoryMap));
  }

  toggleShowHide(textFavorite: TextFavorite, from: Category, to: Category) {
    this.textFavoriteCategoryMap[this.parentActiveTab][from] = this.textFavoriteCategoryMap[
      this.parentActiveTab
    ][from].filter(item => item.id !== textFavorite.id);

    if (to === 'hidden') {
      this.textFavoritesHiddenFormControl.setValue([
        ...(this.textFavoritesHiddenFormControl.value || []),
        textFavorite.id
      ]);
    }

    if (from === 'hidden') {
      this.textFavoritesHiddenFormControl.setValue(
        (this.textFavoritesHiddenFormControl.value || []).filter(
          hiddenTextFavoriteId => hiddenTextFavoriteId !== textFavorite.id
        )
      );
    }

    this.textFavoritesHiddenFormControl.markAsDirty();

    this.textFavoriteCategoryMap[this.parentActiveTab][to] = [
      ...this.textFavoriteCategoryMap[this.parentActiveTab][to],
      textFavorite
    ];
  }

  moveItem(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.currentTextFavorites = [...this.currentTextFavorites];
    this.setOrder();
  }
  reorder(previousIndex, direction: 'up' | 'down') {
    moveItemInArray(
      this.currentTextFavorites,
      previousIndex,
      direction === 'up' ? previousIndex - 1 : previousIndex + 1
    );
    this.currentTextFavorites = [...this.currentTextFavorites];
    this.setOrder();
  }

  onDragMoved(event: CdkDragMove, scrollableContainer: HTMLElement) {
    const scrollSpeed = 30;
    const containerRect = scrollableContainer.getBoundingClientRect();
    const dragRect = event.pointerPosition;

    const topTrigger = containerRect.top + 50;
    const bottomTrigger = containerRect.bottom - 50;
    if (dragRect.y < topTrigger) {
      scrollableContainer.scrollBy({
        top: -scrollSpeed,
        behavior: 'smooth'
      });
    } else if (dragRect.y > bottomTrigger) {
      scrollableContainer.scrollBy({
        top: scrollSpeed,
        behavior: 'smooth'
      });
    }
  }
  setOrder() {
    this.currentTextFavorites = [...this.currentTextFavorites];
    const textFavItemList = this.currentTextFavorites.map(
      (current: TextFavorite, index: number) => ({
        id: current.id,
        type: current.type,
        position: index
      })
    );
    this.currentTextFavorites.sort(
      (a, b) =>
        textFavItemList.findIndex(textFavorite => textFavorite.id === a.id) -
        textFavItemList.findIndex(textFavorite => textFavorite.id === b.id)
    );
    this.textFavoriteCategoryMap[this.parentActiveTab][this.childActiveTab] = [
      ...this.currentTextFavorites
    ];
    this.textFavoriteConfigMap = this.getAllOrderList();
    this.textFavoriteConfigFormControl.setValue(this.textFavoriteConfigMap);
    this.textFavoriteConfigFormControl.markAsDirty();
  }

  private addToCustomArray(textFav: TextFavorite, index: number) {
    this.textFavoriteCategoryMap[this.parentActiveTab]['custom'].splice(index, 0, textFav);
  }

  private getAllOrderList() {
    const keyMap: { [key: string]: FavoriteIdType[] } = {};
    this.parentTabArray.forEach(key => {
      keyMap[key] = this.textFavoriteCategoryMap[key]['orderList'].map(textFav => ({
        id: textFav.id,
        type: textFav.type
      }));
    });
    return keyMap;
  }

  private categorizeTextFavorites() {
    (this.textFavoritesFormControl.value || []).forEach((textFavorite: TextFavorite) => {
      const childKey = defaultTextFavoriteMap[textFavorite.id] ? 'sysDefault' : 'custom';
      if (!(this.textFavoritesHiddenFormControl.value || []).includes(textFavorite.id)) {
        this.textFavoriteCategoryMap[textFavorite.type][childKey] = [
          ...this.textFavoriteCategoryMap[textFavorite.type][childKey],
          textFavorite
        ];

        this.textFavoriteCategoryMap[textFavorite.type]['orderList'] = [
          ...this.textFavoriteCategoryMap[textFavorite.type]['orderList'],
          textFavorite
        ];
      } else {
        this.textFavoriteCategoryMap[textFavorite.type]['hidden'] = [
          ...this.textFavoriteCategoryMap[textFavorite.type]['hidden'],
          textFavorite
        ];
      }
    });
  }
}

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractControlsMap } from '../dynamic-form-group/dynamic-form-group';

interface ResponseFormControls extends AbstractControlsMap {
  id: FormControl;
  type: FormControl;
  response: FormControl;
  createdAt: FormControl;
}

export class ResponseFormGroup extends FormGroup {
  controls: ResponseFormControls;
  submitted = false;

  constructor() {
    super({
      id: new FormControl(''),
      type: new FormControl(''),
      createdAt: new FormControl(''),
      response: new FormControl('', Validators.required)
    });
  }
}

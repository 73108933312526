import { ValidatorFn, Validators } from "@angular/forms";
export const preProcedureSchema: { [key: string]: ReferralSignsConfig } = {
  uncorrectedVisualAcuity: {
    name: 'Uncorrected Visual Acuity',
    fieldType: 'Text'
  },
  currentSpectacle: {
    name: 'Current Spectacles',
    fieldType: 'Text'
  },
  currentSpectacleVisualAcuity: {
    name: 'Current Spectacles Visual Acuity',
    fieldType: 'Text'
  },
  manifestRefraction: {
    name: 'Manifest Refraction',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  manifestRefractionVisualAcuity: {
    name: 'manifest Refraction Visual Acuity',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  cycloplegicRefraction: {
    name: 'Cycloplegic Refraction',
    fieldType: 'Text'
  },
  cycloplegicRefractionVisualAcuity: {
    name: 'Cycloplegic Refraction Visual Acuity',
    fieldType: 'Text'
  },
  k: {
    name: "K 's",
    fieldType: 'Text'
  },
  iops: {
    name: "IOP's mmHg",
    fieldType: 'Text'
  },
  crystallineLens: {
    name: 'Crystalline Lens Grading',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  pupilSize: {
    name: 'Pupil Size (Dim)',
    fieldType: 'Text'
  },
  splitLampExam: {
    name: 'Split Lamp Exam',
    fieldType: 'Text',

    validators: [Validators.required]
  },
  fundus: {
    name: 'Fundus',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  eyeMarkUp: {
    name: 'Eye Mark Up',
    fieldType: 'Draw'
  }
};
export const   postProcedureSchema: { [key: string]: ReferralSignsConfig } = {
  postExam: {
    name: 'Post-OP Exam',
    fieldType: 'Dropdown',
    dropdownValues: ['1 WEEK', '1 MONTH', '4 MONTH', '1 YEAR', 'OTHER'],
    validators: [Validators.required]
  },
  uncorrectedVision: {
    name: 'Uncorrected Visual Acuity',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  manifestRefraction: {
    name: 'Manifest Refraction',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  manifestRefractionVisualAcuity: {
    name: 'Manifest Refraction Visual Acuity',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  k: {
    name: "K 's",
    fieldType: 'Text'
  },
  iops: {
    name: "IOP's mmHg",
    fieldType: 'Text',
    validators: [Validators.required]
  },
  splitLampExam: {
    name: 'Split Lamp Exam',
    fieldType: 'Text',

    validators: [Validators.required]
  },
  lens: {
    name: 'Lens/IOL/ICL Status',
    fieldType: 'Text',
    validators: [Validators.required]
  },
  fundus: {
    name: 'Fundus',
    fieldType: 'Text',
    validators: [Validators.required]
  }
};

interface ReferralSignsConfig {
  name?: string;
  fieldType?: 'Text' | 'Dropdown' | 'Checkbox' | 'radio' | 'Draw';
  procedureType?: 'PRE' | 'POST';
  dropdownValues?: string[];
  side?: 'LEFT' | 'RIGHT' | 'BOTH';
  validators?: ValidatorFn[];
}
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'csi-toggleable-medical-history-text-field',
  templateUrl: './toggleable-medical-history-text-field.component.html',
  styleUrls: ['./toggleable-medical-history-text-field.component.css']
})
export class ToggleableMedicalHistoryTextFieldComponent implements OnDestroy {
  private _control: FormControl;
  @Input() set control(control: FormControl) {
    this._control = control;

    control.statusChanges.pipe(untilDestroyed(this)).subscribe(s => {
      if (control.disabled) {
        this.isViewOnly = true;
        this.hideTextField = false;
      } else {
        this.isViewOnly = false;
        this.setToggleValueBasedOnControlValue();
      }
    });
  }
  get control(): FormControl {
    return this._control;
  }

  @Input() label: string;

  public isViewOnly = false;
  public hideTextField = true;

  private _toggleValue: boolean;
  set toggleValue(value: boolean) {
    this._toggleValue = value;
    this.hideTextField = !value;
  }
  get toggleValue(): boolean {
    return this._toggleValue;
  }

  constructor(private changeRef: ChangeDetectorRef) {}

  private setToggleValueBasedOnControlValue() {
    if (this.control.value === 'none') {
      this.toggleValue = false;
    } else if (this.control.value) {
      this.toggleValue = true;
    } else if (this.toggleValue === undefined) {
      this.hideTextField = true;
    }
  }

  public onToggle(val: boolean) {
    this.toggleValue = val;
    if (val === false) {
      this.control.setValue('none');
    } else {
      this.control.setValue(null);
    }
  }

  ngOnDestroy() {}
}

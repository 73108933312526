import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Storage } from 'aws-amplify';
import { ClinicSetupService } from './../logged-in-navbar/clinic-setup-modal/clinic-setup.service';

export function arrayNotEmptyValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const isValid = control.value && control.value.length > 0;
    return isValid ? null : { required: true };
  };
}

@Component({
  selector: 'app-treatment-request',
  templateUrl: './treatment-request.component.html',
  styleUrls: ['./treatment-request.component.css']
})
export class TreatmentRequestComponent {
  treatmentForm: FormGroup;
  types = ['Eye Drop', 'Gel Capsule', 'Eye Mask', 'Eye Spray', 'Other'];
  classificationMap = {
    Exercise: 'exercise',
    'OTC Product': 'otc',
    Rx: 'rx',
    Procedure: 'procedure'
  };

  public showRxFields = false;
  public showPhotoField = false;
  public isSaving = false;

  constructor(private clinicSetupService: ClinicSetupService, private _snackBar: MatSnackBar) {
    this.treatmentForm = this.initializeForm();
  }

  ngOnInit() {
    this.setupValueChangeSubscriptions();
  }

  private initializeForm(): FormGroup {
    return new FormGroup({
      treatmentName: new FormControl('', Validators.required),
      type: new FormControl(''),
      customType: new FormControl(''),
      manufacturer: new FormControl(''),
      classification: new FormControl('', Validators.required),
      dosage: new FormControl(''),
      preservatives: new FormControl([]),
      contactLensCompatible: new FormControl(''),
      starIngredients: new FormControl([], arrayNotEmptyValidator()),
      linkOrPhotoToggle: new FormControl('link'),
      link: new FormControl(''),
      photo: new FormControl([]),
      comments: new FormControl('')
    });
  }

  private setupValueChangeSubscriptions(): void {
    this.treatmentForm.controls.linkOrPhotoToggle.valueChanges.subscribe(
      this.handleLinkOrPhotoToggle.bind(this)
    );
    this.treatmentForm.controls.type.valueChanges.subscribe(this.handleTypeChange.bind(this));
    this.treatmentForm.controls.classification.valueChanges.subscribe(
      this.handleClassificationChange.bind(this)
    );
  }

  private handleLinkOrPhotoToggle(value: string): void {
    if (value === 'photo') {
      this.toggleField('photo', true, [Validators.required, arrayNotEmptyValidator()]);
      this.toggleField('link', false);
      this.showPhotoField = true;
    } else {
      this.toggleField('photo', false);
      this.toggleField('link', true, Validators.required);
      this.showPhotoField = false;
    }
  }

  private handleTypeChange(value: string) {
    if (value === 'Other') {
      this.treatmentForm.controls.customType.enable();
      if (this.treatmentForm.controls.type.value !== null) {
        this.treatmentForm.controls.type.setValidators(null);
        this.treatmentForm.controls.customType.setValidators(Validators.required);
      }
    } else {
      this.treatmentForm.controls.customType.disable();
    }
    this.treatmentForm.controls.customType.updateValueAndValidity();
  }

  private handleClassificationChange(value: string): void {
    const isRxOrOtc = value === 'otc' || value === 'rx';
    this.toggleField('type', isRxOrOtc, Validators.required);
    this.toggleField('dosage', isRxOrOtc, Validators.required);
    this.toggleField('contactLensCompatible', isRxOrOtc, Validators.required);
    this.toggleField('starIngredients', isRxOrOtc, [Validators.required, arrayNotEmptyValidator()]);
    this.showRxFields = isRxOrOtc;
  }

  private toggleField(
    controlName: string,
    enable: boolean,
    validators?: ValidatorFn | ValidatorFn[]
  ): void {
    const control = this.treatmentForm.controls[controlName];
    if (enable) {
      control.enable();
      if (validators) control.setValidators(validators);
    } else {
      control.disable();
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.treatmentForm.invalid) {
      this.markFormGroupTouched(this.treatmentForm);
      return;
    }

    this.treatmentForm.disable();
    this.isSaving = true;

    const formValues = this.treatmentForm.getRawValue();
    const starIngredientsArray =
      formValues.starIngredients &&
      formValues.starIngredients.map((ingredient: string) => {
        const [name, description] = ingredient.includes(':')
          ? ingredient.split(':')
          : [ingredient, ''];
        return { name: name.trim(), description: description.trim() };
      });

    const imagePromise =
      formValues.photo && formValues.photo[0]
        ? this.convertImageToBase64(formValues.photo[0])
        : Promise.resolve('');

    imagePromise
      .then(base64Image => this.prepareTreatmentData(formValues, base64Image, starIngredientsArray))
      .then(treatmentData => this.uploadToS3(treatmentData))
      .then(() => {
        this._snackBar.open('Treatment request submitted successfully.', '', { duration: 3000 });
        this.treatmentForm.reset();
        this.treatmentForm.controls.linkOrPhotoToggle.setValue('link');
      })
      .catch(error => {
        console.error('Error:', error);
        this._snackBar.open('An error occurred while submitting the treatment request.', '', {
          duration: 3000
        });
      })
      .finally(() => {
        this.isSaving = false;
        this.treatmentForm.enable();
      });
  }

  private prepareTreatmentData(
    formValues: any,
    base64Image: string,
    starIngredientsArray: any[]
  ): any {
    return {
      id: formValues.treatmentName.toLowerCase().replace(/\s+/g, '-'),
      name: formValues.treatmentName,
      type: formValues.type === 'Other' ? formValues.customType : formValues.type,
      manufacturer: formValues.manufacturer,
      preservatives: formValues.preservatives || '',
      starIngredients: starIngredientsArray,
      dosage: formValues.dosage || '',
      imageOrLink: base64Image || formValues.link,
      contactLensCompatible: formValues.contactLensCompatible === 'Yes',
      class: this.classificationMap[formValues.classification],
      comments: formValues.comments || ''
    };
  }

  private convertImageToBase64(photo: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(photo as File);
    });
  }

  private uploadToS3(treatmentData: any): Promise<void> {
    const dateNow = new Date();
    const date = `${dateNow.getTime()}`;
    const fileName = `${this.clinicSetupService.clinic.name} ${date} ${treatmentData.id}.json`;
    const jsonBlob = new Blob([JSON.stringify(treatmentData, null, 2)], {
      type: 'application/json'
    });

    return Storage.put(fileName, jsonBlob, { bucket: 'treatment-request', level: 'public' })
      .then(() => undefined)
      .catch(err => {
        console.error('Error uploading file:', err);
        throw err;
      });
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control) control.markAsTouched();
    });
  }

  noSort(): number {
    return 0;
  }
}

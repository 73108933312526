import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { AssessmentBodyUtils } from '../../../../econsult/assessment-body/assessment-body-utils';
import { DryEyeMedicalHistory } from '../../../../econsult/assessment-body/assessment-body.model';
import { AssessmentRouteData } from '../../../../encounters/assessment/assessment.component';
import { LayoutsService } from './../../../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { DryEyeMedicalHistoryFormGroup } from './dry-eye-medical-history-form.model';

@Component({
  selector: 'csi-dry-eye-medical-history-form',
  templateUrl: './dry-eye-medical-history-form.component.html',
  styleUrls: ['./dry-eye-medical-history-form.component.css']
})
export class DryEyeMedicalHistoryFormComponent implements OnChanges, OnDestroy {
  @Input() formGroup: DryEyeMedicalHistoryFormGroup;
  @Input() flagDrugs = true;
  @Input() isFirstEncounter: boolean;
  @Input() isMedicationSection: boolean;
  @Input() assessmentRouteData$: Observable<AssessmentRouteData>;
  @Input() assessmentMethod: string;
  @Input() onPatientFileSaved$: Subject<Object>;
  @Input() onQuestionnaireLinked$: Subject<void>;

  public isCurrentlyEmpty$: Observable<boolean>;

  constructor(public schemaService: SchemaService, public layoutsService: LayoutsService) {}

  public formatThumbLabel(value: number | null): number {
    if (!value) {
      return 0;
    }

    return Math.round(value / 10);
  }

  public patchMedicalHistory(newMedicalHistory: Partial<DryEyeMedicalHistory>) {
    this.formGroup.reset();
    this.formGroup.patchValue(newMedicalHistory as any);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGroup) {
      this.schemaService.checkboxConditionKeys.forEach(key => {
        if (!this.formGroup.controls[key]) {
          this.formGroup.addControl(key, new FormControl());
        }
      });

      this.schemaService.medicalConditionKeys.forEach(key => {
        if (!this.formGroup.controls[key]) {
          this.formGroup.addControl(key, new FormControl());
        }
      });

      this.schemaService.environmentalSymptomKeys.forEach(key => {
        if (!this.formGroup.controls[key]) {
          this.formGroup.addControl(key, new FormControl());
        }
      });

      this.schemaService.radioConditionKeys.forEach(key => {
        if (!this.formGroup.controls[key]) {
          const required = !!this.schemaService.radioConditionMap[key].required;
          this.formGroup.addControl(
            key,
            new FormControl(undefined, required ? Validators.required : undefined)
          );
        }
      });

      this.addSchemaVersion();
    }

    this.isCurrentlyEmpty$ = merge(of(this.formGroup.value), this.formGroup.valueChanges).pipe(
      map(val => !AssessmentBodyUtils.isHistoryNonEmpty(val)),
      untilDestroyed(this)
    );
  }

  ngOnDestroy() {}

  private addSchemaVersion() {
    if (!this.formGroup.controls['schemaVersion']) {
      this.formGroup.addControl(
        'schemaVersion',
        new FormControl(this.schemaService.schema.version)
      );
    } else {
      this.formGroup.controls['schemaVersion'].setValue(this.schemaService.schema.version);
    }
  }
}

import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { omit } from 'lodash-es';
import { defer, forkJoin, iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AssessmentRequestType,
  CreateClinicInput,
  CreatePatientInput,
  Doctor,
  UpdateDoctorInput,
  UpdatePatientInput
} from '../../../API';
import {
  Assessment,
  AssessmentRequest,
  AssessmentService
} from '../../core/api/assessment.service';
import { Clinic, ClinicService } from '../../core/api/clinic.service';
import { Patient, PatientService } from '../../core/api/patient.service';
import { StaffService } from '../../core/api/staff.service';
import { AssessmentFormService } from '../../encounters/assessment-form.service';
import { ClinicFormGroup } from '../../shared/consult-forms/clinic-form/clinic-form.model';
import { DoctorFormGroup } from '../../shared/consult-forms/doctor-form/doctor-form.model';
import { ResponseFormGroup } from '../../shared/response-form/response-form.model';
import { AssessmentBody } from '../assessment-body/assessment-body.model';
import { EConsultFormGroup, EntryMethod } from '../econsult-form.model';
import { CognitoGroup, CreateDoctorMutation } from './../../../API';

export interface SavedManualAssessmentResult {
  assessment: Assessment;
  updatedDoctor: CreateDoctorMutation['createDoctor'];
  updatedPatient: Patient;
}

@Injectable()
export class EConsultService {
  private logger = new Logger('EConsultService');

  constructor(
    private assessmentService: AssessmentService,
    private assessmentFormService: AssessmentFormService,
    private staffService: StaffService,
    private clinicService: ClinicService,
    private patientService: PatientService,
    private formGroup: EConsultFormGroup
  ) {}

  public saveManualAssessment(doctor: Doctor): Observable<SavedManualAssessmentResult> {
    // TODO: There is a lot of back and forth with the server here as creating this assessment is a multi-step process of
    // creating clinic if needed, associating doctor with clinic, creating patient, then assessment, then assessment request.
    // And if one step fails midway, it will create bad data in db. This should really all be done in the backend through one end point.
    // Ideally through pipeline resolvers and employing dynamodb transactions.
    return iif(
      () => !doctor.clinic,
      this.createClinicAndUpdateDoctor(
        this.formGroup.manualEntryControls.doctorInformationForm,
        this.formGroup.manualEntryControls.clinicInformationForm,
        doctor
      ),
      of(doctor)
    ).pipe(
      switchMap(doctorWithClinic => {
        this.logger.debug('got doctor, initiating patient save');
        // create or update patient
        const patient = this.formGroup.manualEntryControls.patientInformationForm.toApiInput();
        patient.patientDoctorId = patient.patientDoctorId || doctorWithClinic.id;

        return forkJoin([
          patient.id
            ? this.patientService.updatePatient(patient as UpdatePatientInput)
            : this.patientService.createPatient(patient as CreatePatientInput),
          this.updateDoctorAndClinicIfDirty(
            this.formGroup.manualEntryControls.doctorInformationForm,
            this.formGroup.manualEntryControls.clinicInformationForm,
            doctorWithClinic
          )
        ]).pipe(
          map(
            ([updatedPatient, [updatedDoctor, updatedClinic]]) =>
              ({ updatedDoctor, updatedPatient } as Partial<SavedManualAssessmentResult>)
          )
        );
      }),
      switchMap(partialSavedAssessmentResult => {
        this.logger.debug('saved patient');

        return this.assessmentFormService
          .saveAssessment(
            this.formGroup.controls.id.value,
            this.formGroup.assessmentType,
            this.formGroup.getRawValue() as AssessmentBody,
            this.formGroup.attachments,
            partialSavedAssessmentResult.updatedPatient,
            partialSavedAssessmentResult.updatedDoctor
          )
          .pipe(
            map(
              assessment =>
                ({ ...partialSavedAssessmentResult, assessment } as SavedManualAssessmentResult)
            )
          );
      })
    );
  }

  public submitAssessmentRequest(
    type: AssessmentRequestType = AssessmentRequestType.EReferral,
    doctor: Doctor
  ): Observable<AssessmentRequest> {
    if (this.formGroup.entryMethod === EntryMethod.AttachFromEMR) {
      return this.submitAssessmentRequestViaEMRAttachment(type, doctor);
    } else {
      return this.submitAssessmentRequestViaManualEntry(type, doctor);
    }
  }

  public submitAssessmentRequestResponse(
    assessmentRequest: AssessmentRequest,
    responseForm: ResponseFormGroup
  ): Observable<AssessmentRequest> {
    return this.assessmentService
      .respondToAssessmentRequest(responseForm.controls.response.value, assessmentRequest.id)
      .pipe(
        switchMap(updatedAssessmentRequest =>
          this.assessmentService.getAssessmentRequest(updatedAssessmentRequest.id)
        )
      );
  }

  private submitAssessmentRequestViaEMRAttachment(
    type: AssessmentRequestType = AssessmentRequestType.EReferral,
    doctor: Doctor
  ): Observable<AssessmentRequest> {
    return this.assessmentService
      .createAssessment(
        this.formGroup.assessmentType,
        // remove attachments as we will upload these separately through S3
        // Todo: Retain original attachment name
        omit(this.formGroup.value, 'emrAttachments'),
        this.formGroup.emrControls.emrAttachments.value
      )
      .pipe(
        switchMap(assessment => {
          return this.assessmentService.createAssessmentRequest({
            assessmentRequestAssessmentId: assessment.id,
            type: type,
            assessmentRequestDoctorId: doctor.id,
            priority: this.formGroup.controls.priority.value
          });
        })
      );
  }

  private submitAssessmentRequestViaManualEntry(
    type: AssessmentRequestType = AssessmentRequestType.EReferral,
    doctor: Doctor
  ): Observable<AssessmentRequest> {
    return this.saveManualAssessment(doctor).pipe(
      switchMap(savedManualAssessmentResult => {
        return this.assessmentService.createAssessmentRequest({
          assessmentRequestDoctorId: savedManualAssessmentResult.updatedDoctor.id,
          type: type,
          assessmentRequestAssessmentId: savedManualAssessmentResult.assessment.id,
          priority: this.formGroup.controls.priority.value
        });
      })
    );
  }

  private createClinicAndUpdateDoctor(
    doctorInformationForm: DoctorFormGroup,
    clinicInformationForm: ClinicFormGroup,
    doctor: Doctor
  ): Observable<Doctor> {
    return defer(() => {
      const newClinic: CreateClinicInput = clinicInformationForm.toCreateClinicInput();
      return this.clinicService.createClinic(newClinic).pipe(
        switchMap(clinic => {
          const updatedDoctor: UpdateDoctorInput = doctorInformationForm.toUpdateDoctorInput(
            doctor.id,
            clinic.id
          );
          return this.staffService.updateDoctor(updatedDoctor);
        })
      );
    });
  }

  private updateDoctorAndClinicIfDirty(
    doctorInformationForm: DoctorFormGroup,
    clinicInformationForm: ClinicFormGroup,
    doctor: Doctor
  ): Observable<[Doctor, Clinic]> {
    return forkJoin([
      iif(
        () => doctorInformationForm.dirty,
        this.staffService.updateDoctor(
          doctorInformationForm.toUpdateDoctorInput(doctor.id, doctor.clinic.id)
        ),
        of(doctor)
      ),
      iif(
        () => clinicInformationForm.dirty,
        this.clinicService.updateClinic(
          clinicInformationForm.toUpdateClinicInput(doctor.clinic.id)
        ),
        of(doctor.clinic)
      )
    ]) as Observable<[Doctor, Clinic]>;
  }

  public shouldExpandAttachments(): Observable<boolean> {
    return this.staffService.getLoggedInStaff().pipe(
      map((doctor: Doctor) => {
        return (
          this.formGroup.assessmentRequestType === AssessmentRequestType.EReferral &&
          this.staffService.isStaffPartOfGroup(doctor, CognitoGroup.Seema)
        );
      })
    );
  }
}

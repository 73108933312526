import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ConsentSource } from 'src/API';

export interface ConsentData {
  consentsToResearch: boolean;
  consentsToPrivacyForm: boolean;
  consentSource: ConsentSource;
}

@Component({
  selector: 'csi-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.css']
})
export class ConsentFormComponent {
  public country: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { country: string }) {
    this.country = data.country;
  }
}

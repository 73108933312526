import { ElementRef, Injectable, NgZone } from '@angular/core';

export class FormErrorScrollingOptions {
  isHostScrollable?: boolean;
  isHostMatTable?: boolean;
  isHostMatStep?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FormErrorScrollingService {
  private readonly defaultView: HTMLElement;

  constructor(private ngZone: NgZone) {
    this.defaultView = window.document.body;
  }

  public scrollToFirstInvalidControl() {
    this.scrollToFirstInvalidControlInView(this.defaultView);
  }

  public scrollToFirstInvalidControlInView(
    view: ElementRef<HTMLElement> | HTMLElement,
    async: boolean = true,
    options?: FormErrorScrollingOptions
  ) {
    const hostElement = view instanceof HTMLElement ? view : view.nativeElement;
    if (async) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.scrollToFirstInvalidControlInViewSync(hostElement, options);
        });
      });
    } else {
      this.scrollToFirstInvalidControlInViewSync(hostElement, options);
    }
  }

  private scrollToFirstInvalidControlInViewSync(
    hostElement: HTMLElement,
    options: FormErrorScrollingOptions = {}
  ) {
    const firstErrorElement = hostElement.querySelector('.form-error,mat-error') as HTMLElement;

    if (firstErrorElement) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      const anchor = document.createElement('span');
      anchor.style.position = 'absolute';
      const firstErrorPosition = firstErrorElement.style.position;

      if (options.isHostScrollable) {
        if (options.isHostMatTable) {
          anchor.style.top = '-58px';
        } else if (options.isHostMatStep) {
          anchor.style.top = '-250px';
        } else {
          anchor.style.top = '-0px';
        }
        firstErrorElement.appendChild(anchor);
        firstErrorElement.style.position = 'relative';
      } else {
        anchor.style.top = `${firstErrorElement.getBoundingClientRect().top + scrollTop - 150}px`;
        document.body.appendChild(anchor);
      }
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

      const intersectionObserver = new IntersectionObserver(entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          intersectionObserver.disconnect();
          anchor.remove();
          firstErrorElement.style.position = firstErrorPosition;
        }
      });

      intersectionObserver.observe(anchor);
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LineChartPoint, ReferenceLine } from '../patient-chart.component';

@Component({
  selector: 'csi-patient-chart-series-tooltip',
  templateUrl: './patient-chart-series-tooltip.component.html',
  styleUrls: ['./patient-chart-series-tooltip.component.css']
})
export class PatientChartSeriesTooltipComponent implements OnChanges {
  @Input() points: (LineChartPoint & {
    color: string;
  })[];
  @Input() referenceLines: ReferenceLine[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referenceLines']) {
      const largestFirst = (refLine1: ReferenceLine, refLine2: ReferenceLine) =>
        refLine2.value - refLine1.value;
      this.referenceLines.sort(largestFirst);
    }
  }

  public toSeverity(normalizedValue: number): string {
    if (this.referenceLines) {
      const refLine = this.referenceLines
        .slice(1)
        .find((referenceLine: ReferenceLine) => normalizedValue >= referenceLine.value);
      return !!refLine ? refLine.name : normalizedValue.toString();
    }
  }
}

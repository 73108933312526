import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DryEyeCategory,
  DryEyeSeverity,
  DryEyeType,
  isDryEyeCategoryEqual
} from '../../../../core/remote-json/treatments/dry-eye-category.model';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DryEyeCategoryComponent),
  multi: true
};

@Component({
  selector: 'csi-dry-eye-category',
  templateUrl: './dry-eye-category.component.html',
  styleUrls: ['./dry-eye-category.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DryEyeCategoryComponent implements ControlValueAccessor, AfterViewInit {
  // FIXME: Once Array.prototype.flatMap becomes availabe in IE Edge use that in-place of map and reduce (see https://caniuse.com/#search=flatmap)
  static readonly allCategories: DryEyeCategory[] = Object.keys(DryEyeSeverity)
    .map(severity =>
      Object.keys(DryEyeType).map(type => {
        return { severity: DryEyeSeverity[severity], type: DryEyeType[type] };
      })
    )
    .reduce((flattenedAray, dryEyeTypeArray) => flattenedAray.concat(dryEyeTypeArray));

  disabled = false;
  svgTransform = 'scale(-1,1) translate(-100,0)';

  private _selectedCategory: DryEyeCategory;

  private _onChange: (_: any) => {};

  private _onTouched = () => {};

  @Input() preOpScreening: boolean;
  constructor(private location: Location, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    // HACK to get Safari to render severity scale properly
    setTimeout(() => {
      this.svgTransform = ` ${this.svgTransform}`;
    }, 750);
  }

  set selectedCategory(category: DryEyeCategory) {
    this._selectedCategory = category === this._selectedCategory ? null : category;
    this._onChange(this._selectedCategory);
  }

  get selectedCategory(): DryEyeCategory {
    return this._selectedCategory;
  }

  get allCategories(): DryEyeCategory[] {
    return DryEyeCategoryComponent.allCategories;
  }

  writeValue(value: DryEyeCategory) {
    if (!!value && !this.preOpScreening) {
      this._selectedCategory = DryEyeCategoryComponent.allCategories.find(category =>
        isDryEyeCategoryEqual(category, value)
      );
    } else {
      this._selectedCategory = value;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  get svgFillStyle(): string {
    return `fill: url('${this.location.path()}#scaleGradient');`;
  }
}

import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface InsertTextConfig {
  items: Array<{ label: string; textToInsert: string }>;
}

@Component({
  selector: 'csi-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.css']
})
export class CustomMessageComponent {
  @Input() title!: string; // Required: Dynamic title
  @Input() description!: string; // Required: Help text or description
  @Input() insertTextConfig!: InsertTextConfig; // Required: Insert text options
  @Input() control: FormControl;
}
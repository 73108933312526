import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/typings/paginator';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Patient } from 'src/API';
import { PatientService } from 'src/app/core/api/patient.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { PatientSummaryPaginatorService } from './patient-summary-paginator.service';

@Component({
  selector: 'csi-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class PatientSummaryComponent implements OnInit, OnDestroy {
  @Input() loadPatients: boolean;
  public statuses = this.clinicSetupService.interactionsSetup.statuses;
  public patientsInteraction: any = [];
  public statusFilter = new FormControl();
  public patientCode = ['Interested', 'Not Interested'];
  public readonly columnsToDisplay = [
    'firstName',
    'lastName',
    'birthday',
    'email',
    'phone',
    'interactions',
    'Patient Interested'
  ];

  public allColumns = [
    'firstName',
    'lastName',
    'birthday',
    'email',
    'phone',
    'interactions',
    'Patient Interested'
  ];

  public patientsLoaded = false;
  public totalNumberOfPatient: number;
  public hasMorePatientsToLoad: boolean;
  public statusFilterControl = new FormControl();
  public readonly pageChange$ = new Subject<PageEvent>();
  public readonly patientsPerPage = 50;

  public expandedElement: any | null;
  constructor(
    private patientService: PatientService,
    private clinicSetupService: ClinicSetupService,
    public patientSummaryPaginatorService: PatientSummaryPaginatorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.patientSummaryPaginatorService.reset();
  }

  ngOnInit() {
    this.patientsInteraction = this.patientSummaryPaginatorService.currentPatientSummaries;
    this.pageChange$
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(currentPage => this.changePage(currentPage.pageIndex));
    this.patientSummaryPaginatorService.reset();
    this.patientSummaryPaginatorService.pageChange(0);
    this.statusFilterControl.valueChanges.subscribe(status => {
      if (status) {
        status = status.split(' ').join('');
        this.patientSummaryPaginatorService.currentPatientSummaries = this.patientSummaryPaginatorService.currentPatientSummaries.filter(
          patientsInteraction =>
            patientsInteraction.interactions.items.find(interaction => {
              const patientInterested = this.statuses[interaction.status].notInterested
                ? 'NotInterested'
                : 'Interested';
              return (
                patientInterested.toLocaleLowerCase().trim() === status.toLocaleLowerCase().trim()
              );
            })
        );
      } else {
        this.resetPaginationData();
      }
    });
  }
  ngOnDestroy() {}

  determinePatientStatus(patientArray: Patient) {
    const patientStatus =
      patientArray.interactions.items[
        patientArray.interactions.items.length - patientArray.interactions.items.length
      ];
    if (this.statuses[patientStatus.status] === undefined) return 'Not Interested';
    return this.statuses[patientStatus.status].notInterested ? 'Not interested' : 'Interested';
  }
  changePage(pageIndex: number) {
    this.changeDetectorRef.detectChanges();
    this.patientSummaryPaginatorService.pageChange(pageIndex);
  }

  resetPaginationData() {
    this.patientSummaryPaginatorService.reset();
    this.pageChange$.next({
      pageIndex: 0,
      length: null,
      pageSize: null,
      previousPageIndex: null
    });
  }
}

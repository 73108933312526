import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateBeforeToday'
})
export class DateBeforeTodayPipe implements PipeTransform {
  transform(dateToCheckStr: string): boolean {
    const dateToday = new Date();
    const dateToCheck = new Date(dateToCheckStr);

    dateToday.setHours(0, 0, 0, 0);
    dateToCheck.setHours(0, 0, 0, 0);

    return dateToday.getTime() > dateToCheck.getTime();
  }
}

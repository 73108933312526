import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from './authentication/guards/auth.guard';
import { LoggedInUserResolver } from './core/route-resolvers/logged-in-user.resolver';
import { AssessmentRequestResolver } from './econsult/route-resolvers/assessment-request.resolver';
import { MobileImageWizardComponent } from './encounters/signs/mobile-image-wizard/mobile-image-wizard.component';
import { FilterDashboardComponent } from './filter/filter-dashboard/filter-dashboard.component';
import { FormTemplateGeneratorComponent } from './form-template/form-template-generator/form-template-generator.component';
import { IntakeFormComponent } from './intake-form/intake-form.component';
import { CSIDryEyeGuard } from './landing-page/csi-dry-eye.guard';
import { PrivacyPolicyComponent } from './landing-page/privacy-policy/privacy-policy.component';
import { MobileUploadComponent } from './shared/attachments/mobile-upload/mobile-upload.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { TreatmentRequestComponent } from './treatment-request/treatment-request.component';

export const enum AppRoutePath {
  patients = 'patients'
}

const econsultRoutes: Routes = [
  {
    path: 'assessment-requests',
    children: [
      { path: '', redirectTo: '/erefer', pathMatch: 'full' },
      {
        path: ':id',
        canActivate: [AuthGuard],
        data: {
          authGuardLoginSubtitle: 'Login to view the assessment request.',
          hideNavbar: true
        },
        resolve: {
          assessmentRequest: AssessmentRequestResolver,
          loggedInDoctor: LoggedInUserResolver
        },
        loadChildren: () =>
          import('./econsult/routing/econsult-routing.module').then(m => m.EConsultRoutingModule)
      }
    ]
  }
];

const csiDryEyeRoutes: Routes = [
  {
    path: '',
    canActivate: [CSIDryEyeGuard],
    canActivateChild: [CSIDryEyeGuard],
    data: {
      authGuardLoginTitle: 'CSI Dry Eye',
      authGuardLoginSubtitle:
        'Access to csidryeye.com is by invite only. Enter your information to continue.'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'patients'
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
        data: {
          skipCSIDryEyeGuard: true
        }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          skipCSIDryEyeGuard: true
        }
      },
      {
        path: 'app',
        resolve: {
          loggedInDoctor: LoggedInUserResolver
        },
        loadChildren: () =>
          import('./econsult/routing/econsult-routing.module').then(m => m.EConsultRoutingModule)
      },
      {
        path: 'patients',
        resolve: {
          loggedInDoctor: LoggedInUserResolver
        },
        loadChildren: () =>
          import('./patients/patients-routing.module').then(m => m.PatientsRoutingModule)
      },
      {
        path: ':id/:metadataKey/:metadataValue/upload',
        component: MobileUploadComponent
      },
      {
        path: ':id/mobile-media-wizard',
        component: MobileImageWizardComponent
      },
      {
        path: 'mass-clinics',
        resolve: {
          loggedInDoctor: LoggedInUserResolver
        },
        loadChildren: () =>
          import('./mass-clinics/mass-clinics.module').then(m => m.MassClinicsModule)
      },
      {
        path: 'intake-form/:id',
        component: IntakeFormComponent,
        data: {
          skipCSIDryEyeGuard: true
        }
      },
      {
        path: 'intake-forms',
        resolve: {
          loggedInDoctor: LoggedInUserResolver
        },
        loadChildren: () => import('./intake-form/intake-form.module').then(m => m.IntakeFormModule)
      },
      {
        path: 'refer',
        resolve: { loggedInDoctor: LoggedInUserResolver },
        loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule)
      },
      {
        path: 'dashboard',
        resolve: { loggedInDoctor: LoggedInUserResolver },
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'report',
        component: FilterDashboardComponent,
        resolve: { loggedInDoctor: LoggedInUserResolver }
      },
      {
        path: 'custom-forms',
        component: FormTemplateGeneratorComponent,
        resolve: { loggedInDoctor: LoggedInUserResolver }
      },
      {
        path: 'treatment-request',
        component: TreatmentRequestComponent,
        resolve: { loggedInDoctor: LoggedInUserResolver }
      }
    ]
  }
];

export function urlsThatStartWithQ(url: UrlSegment[]) {
  const match = url.length > 0 && url[0].path.toLowerCase().startsWith('q');
  return match ? { consumed: url.slice(0, 1) } : null;
}

const questionnairesRoutes: Routes = [
  {
    path: 'questionnaires',
    loadChildren: () =>
      import('./questionnaires/questionnaires.module').then(m => m.QuestionnairesModule)
  },
  {
    matcher: urlsThatStartWithQ,
    redirectTo: 'questionnaires'
  }
];

const routes: Routes = [
  ...csiDryEyeRoutes,
  ...econsultRoutes,
  ...questionnairesRoutes,
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Disabled until https://github.com/angular/angular/issues/24547 is resolved
      // (or we write extra workaround code where needed)
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}

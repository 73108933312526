export const RIGHT_EYE_KEY_PREFIX = 'RIGHT_';
export const LEFT_EYE_KEY_PREFIX = 'LEFT_';
export const SYMPTOM_METHOD_KEY_SUFFIX = '_METHOD';

// TODO Consider moving these static funcitons into schema.service
export function rightEyeKey(key: string): string {
  return key ? RIGHT_EYE_KEY_PREFIX + key : undefined;
}

export function leftEyeKey(key: string): string {
  return key ? LEFT_EYE_KEY_PREFIX + key : undefined;
}

export function symptomKeyFromFormKey(formKey: string): string {
  const regex = new RegExp(`^(${RIGHT_EYE_KEY_PREFIX}|${LEFT_EYE_KEY_PREFIX})`, 'g');
  return formKey.replace(regex, '');
}

export function normalizeToSymptomKey(key: string): string {
  const regex = new RegExp(
    `^(${RIGHT_EYE_KEY_PREFIX}|${LEFT_EYE_KEY_PREFIX})?(.+?)(${SYMPTOM_METHOD_KEY_SUFFIX})?$`,
    'g'
  );
  return key.replace(regex, '$2');
}

export function symptomMethodKey(key: string): string {
  return key ? key + SYMPTOM_METHOD_KEY_SUFFIX : undefined;
}

export class ConsultJSON {
  doctorInformation: {
    name: string;
    practiceID: string;
    officePhone: string;
    officeFax: string;
    dateOfEConsult: string;
  };
  patientInformation: {
    name: string;
    gender: string;
    dateOfBirth: string;
    healthcareNumber: string;
    address: string;
    phone: string;
    email: string;
  };

  medicalHistory: {
    referralReason: string;
    referralReasonDescription?: string;
    medications: string;
    autoImmuneConditions: string;
    seasonalAllergies: string;
    drugAllergies: string;
    eyeItchiness: number;
    eyeTearingQuantity: number;
  } & { [key: string]: string };

  symptoms: {
    assessmentMethod: string;
    osdi: number;
    speed: number;

    rightEye: { [symptomKey: string]: string | number };
    leftEye: { [symptomKey: string]: string | number };
  };

  assessment: {
    impressions: string;
    recommendedPlan: string;
  };

  constructor(jsonStr: string) {
    Object.assign(this as Object, JSON.parse(jsonStr));
  }
}

export enum FormKeys {
  doctorName,
  practiceID,
  officePhone,
  officeFax,
  consultDate,

  patientName,
  patientGender,
  patientDateOfBirth,
  patientHealthCardNumber,
  patientAddress,
  patientPhone,
  patientEmail,

  referralReason,
  referralReasonDescription,
  medications,
  autoImmuneConditions,
  seasonalAllergies,
  drugAllergies,

  assessmentMethod,
  osdi,
  speed,
  deq,

  impressions,
  recommendedPlan
}

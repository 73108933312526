import { Treatment } from '../../../../../../core/remote-json/treatments/treatments.service';
import {
  DryEyeEConsultFormControls,
  EConsultFormGroup
} from '../../../../../../econsult/econsult-form.model';
import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ITCHINESS_FIELD } from '../../../dry-eye-medical-history-form/dry-eye-medical-history-form.model';
import { FilterShouldActivate, TreatmentFilter } from '../treatment-filter.model';

export class ByItchiness implements TreatmentFilter {
  private readonly threshold = 50;
  private _name = 'Itchy Eyes';

  public selectedByDefault = false;

  get name(): string {
    return this._name;
  }
  filter(treatment: Treatment): boolean {
    return !!treatment.indicationsRule && !!treatment.indicationsRule.itchiness;
  }
  listensTo(formGroup: EConsultFormGroup) {
    return of(formGroup).pipe(
      switchMap(value => {
        const medicalHistoryForm = (value.controls as DryEyeEConsultFormControls)
          .medicalHistoryForm;
        if (medicalHistoryForm && medicalHistoryForm.controls[ITCHINESS_FIELD]) {
          const control = medicalHistoryForm.controls[ITCHINESS_FIELD];
          if (control) {
            return control.valueChanges.pipe(
              map(
                (itchiness: number) => new FilterShouldActivate(this, itchiness >= this.threshold)
              )
            );
          }
        }
        return of(new FilterShouldActivate(this, null));
      }),
      distinctUntilChanged((prev, next) => prev.equals(next))
    );
  }
}

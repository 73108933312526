import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'csi-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css']
})
export class OverlayComponent implements AfterViewInit {
  @ViewChild('overlay', { static: true }) overlayElement: ElementRef;

  ngAfterViewInit() {
    this.overlayElement.nativeElement.focus();
  }

  public onKeydown(event: KeyboardEvent) {
    event.preventDefault();
  }
  public onClick(event: MouseEvent) {
    event.preventDefault();
  }
}

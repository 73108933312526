import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { Drug } from 'src/API';
import { SideEffectFlaggingService } from './side-effect-flagging.service';

@Pipe({
  name: 'sideEffects'
})
export class SideEffectsPipe implements PipeTransform {
  private sideEffectsFriendlyNameMap = {
    dryEyes: 'Dry Eyes',
    glaucoma: 'Glaucoma',
    maculopathy: 'Maculopathy',
    opticNeuritis: 'Optic Neuritis/Neuropathy'
  };

  constructor(private service: SideEffectFlaggingService) {}

  transform(medication: Drug | Drug[]): Observable<string> {
    if (Array.isArray(medication)) {
      if (medication.length <= 0) {
        return of('');
      }
      return from(medication as Drug[]).pipe(
        mergeMap(medicine => this.transform(medicine)),
        first(sideEffects => !!sideEffects, '')
      );
    } else {
      return this.service.getSideEffects(medication as Drug).pipe(
        map(sideEffects => {
          return Object.entries(sideEffects)
            .filter(([key, value]) => value)
            .map(([key, value]) => this.sideEffectsFriendlyNameMap[key])
            .join(', ');
        })
      );
    }
  }
}

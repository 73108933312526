import { FormControl, FormGroup } from '@angular/forms';
import { AbstractControlsMap } from '../../shared/dynamic-form-group/dynamic-form-group';
import { InteractionsSetup } from './interactions-setup.model';

interface InteractionsSetupFormControl extends AbstractControlsMap {
  types: FormControl;
  statuses: FormControl;
  communicationMethods: FormControl;
  surgeryTypes: FormControl;
}

export class InteractionsSetupFormGroup extends FormGroup {
  controls: InteractionsSetupFormControl;
  constructor() {
    super({
      types: new FormControl(),
      statuses: new FormControl({}),
      communicationMethods: new FormControl(),
      surgeryTypes: new FormControl()
    });
  }

  patchValueFromInteractionSetup(interactionsSetup: InteractionsSetup) {
    if (interactionsSetup) {
      this.patchValue(interactionsSetup);
    }
  }
}

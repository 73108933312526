import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';
import { GenderService } from './gender.service';

@Component({
  selector: 'csi-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.scss']
})
export class GenderComponent implements OnInit {
  @Input() genderMap: { [key: string]: string } = this.genderService.genderMap;
  @Input() genderFormGroup: FormGroup;
  @Input() otherGenderKey: string = this.genderService.otherGenderKey;
  @Input() otherGenderDescriptionLabel: string = this.genderService.otherGenderDescriptionLabel;

  @Input() viewOnly = false;

  @Output() genderChange = new EventEmitter<string>();

  public noSort = noSort;

  constructor(public genderService: GenderService) {}

  ngOnInit() {
    Object.keys(this.genderMap).forEach(genderKey => {
      this.addToFormGroupIfKeyDoesNotExist(genderKey, this.genderFormGroup);
    });

    this.addToFormGroupIfKeyDoesNotExist(this.otherGenderKey + 'Value', this.genderFormGroup, true);

    this.genderFormGroup.controls[this.otherGenderKey].valueChanges.subscribe(val =>
      val
        ? this.genderFormGroup.controls[this.otherGenderKey + 'Value'].enable()
        : this.genderFormGroup.controls[this.otherGenderKey + 'Value'].disable()
    );
  }

  addToFormGroupIfKeyDoesNotExist(key, formGroup: FormGroup, required?: boolean) {
    if (!formGroup.controls[key]) {
      formGroup.addControl(key, new FormControl(null, required ? Validators.required : null));
    }
  }
}

// export enum InteractionType {
//   communicationAttempt = 'communicationAttempt',
//   initial = 'initial',
//   preOp = 'preOp',
//   postOp = 'postOp',
//   bookedAppointment = 'bookedAppointment'
// }

type InteractionListItem = string[];

export type InteractionTypes = InteractionListItem;
export type CommunicationMethods = InteractionListItem;
export type SurgeryTypes = InteractionListItem;

export const interactionStatus = {
  name: '',
  bookAppointment: false,
  failCommunicationStatus: false,
  notInterested: false,
  selectSurgeryType: false,
  failSurgery: false,
  onlyShowFor: null as InteractionTypes,
  color: ''
};

export type InteractionStatus = Partial<typeof interactionStatus>;
export interface InteractionStatusWithKey {
  [key: string]: InteractionStatus;
}
export const interactionStatusKeys = Object.keys(interactionStatus);

//   [
//   'HOT LEAD',
//   'COLD LEAD/INQUIRING ONLY',
//   'QUICK',
//   'FULL',
//   'CORNEAL ASSESS',
//   'Finance Appt',
//   'SURGERY BOOKED',
//   'SEEMA CONSULT',
//   'NON-CANDIDATE',
//   'LEFT MESSAGE',
//   'Cancelled',
//   'EMAILED',
//   'Pt Not Interested',
//   'Pricing Concern',
//   'Sx booked elsewhere',
//   'SURGERY DONE',
//   'Still Shopping',
//   'NO SHOW ASSESS',
//   'WANTS TO RESEARCH',
//   'Sx Date Pending',
//   'Monovision OD',
//   'TU BOOKED',
//   'Dry Eye',
//   'Intraocular only',
//   'IOL calcs pending',
//   'no show',
//   'Full DONE',
//   'Waiting on Referral',
//   'Dr. A chart review need'
// ];


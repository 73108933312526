import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ContributionLevel,
  ContributorBasedOnEvidence,
  InterpretationConditions,
  InterpretationView
} from '../interpretation.model';
import { InterpretationService } from '../interpretation.service';
import { SideEffectsPipe } from './../../medication-lookup/side-effects.pipe';

interface DryEyeMimickers {
  signs: ContributorBasedOnEvidence[];
  symptoms: ContributorBasedOnEvidence[];
}

@Component({
  selector: 'csi-dry-eye-mimickers',
  templateUrl: './dry-eye-mimickers.component.html',
  styleUrls: ['./dry-eye-mimickers.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DryEyeMimickersComponent implements OnInit {
  @Input() signsOrSymptoms: string;
  @Input() contributorsBasedOnEvidence: ContributorBasedOnEvidence[];
  @Input() interpretationView: InterpretationView;

  @Input() set patientData(patientData) {
    let dryEyeMimickersBasedOnSigns: ContributorBasedOnEvidence[];
    let dryEyeMimickersBasedOnSymptoms: ContributorBasedOnEvidence[];
    dryEyeMimickersBasedOnSigns = this.interpretationService.getContributorAndEvidenceForAllMessageGroups(
      patientData,
      InterpretationConditions.dryEyeMimickersBasedOnSigns
    );
    dryEyeMimickersBasedOnSymptoms = this.interpretationService.getContributorAndEvidenceForAllMessageGroups(
      patientData,
      InterpretationConditions.dryEyeMimickersBasedOnSymptoms
    );

    // Checking for dry eye flagging couldn't be fit in the interpretation logic, this is done in the component for now
    this.sideEffectsPipe.transform(patientData['medications']).subscribe(sideEffects => {
      if (sideEffects.toLowerCase().includes('dry eyes')) {
        dryEyeMimickersBasedOnSymptoms.push({
          contributor: 'Medication used by the patient',
          contributionLevel: 'possible',
          evidence: ['Medication that may cause dry eyes is used'],
          specialNote:
            'Please inquire from the patient if symptoms worsened after taking the drug(s) associated with dry eye symptoms'
        });
      }

      this.dryEyeMimickers.signs = dryEyeMimickersBasedOnSigns;
      this.dryEyeMimickers.symptoms = dryEyeMimickersBasedOnSymptoms;

      this.hasDryEyeMimickers.emit(
        dryEyeMimickersBasedOnSymptoms.length > 0 || dryEyeMimickersBasedOnSigns.length > 0
      );
    });
  }

  @Input() evidenceList: string[];
  @Output() hasDryEyeMimickers: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dryEyeMimickers: DryEyeMimickers = { signs: [], symptoms: [] };

  constructor(
    private interpretationService: InterpretationService,
    private sideEffectsPipe: SideEffectsPipe
  ) {}

  ngOnInit() {}

  public createMessage(contributionLevel: ContributionLevel, contributors, plural = false): string {
    const severityHtml = `<span class='contribution-level ${contributionLevel}'>${contributionLevel}</span>`;
    const contributorsHtml = `<b>${contributors}</b>`;
    return (
      `Consider ${contributorsHtml} ` +
      (plural ? `as ${severityHtml} contributors` : `as a ${severityHtml} contributor`) +
      ` to Ocular Surface Disease.`
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'csi-new-password-input',
  templateUrl: './new-password-input.component.html',
  styleUrls: ['./new-password-input.component.css']
})
export class NewPasswordInputComponent implements OnInit {
  @Input() passwordControl: FormControl;
  @Input() confirmPasswordControl: FormControl;
  @Input() passwordLabel: 'New Password' | 'Password';
  @Input() confirmPasswordLabel: 'Confirm New Password' | 'Confirm Password';

  constructor() {}

  ngOnInit() {}
}

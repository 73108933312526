import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import cleanDeep from 'clean-deep';
import isEmpty from 'lodash-es/isEmpty';
import isPlainObject from 'lodash-es/isPlainObject';
import merge from 'lodash-es/merge';
import mergeWith from 'lodash-es/mergeWith';
import { ConsentSource, CreatePatientInput, UpdatePatientInput } from '../../../API';
import { HealthCardMode, LocationHealthCardModeMap } from '../location-select/location.model';
import { emailValidator } from '../shared-validators/email-validator';
import { phoneNumberValidator } from '../shared-validators/phone-number-validator';

export enum PatientEntryMethod {
  AddNewPatient = 'addNewPatient',
  LookupExisting = 'lookupExisting'
}

export enum PatientFormControlType {
  // The enum values can be set to anything, but for now they are the names of the form control themselves
  Id = 'id',
  PatientDoctorId = 'patientDoctorId',
  EntryMethod = 'entryMethod',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  HealthCard = 'healthCard',
  Gender = 'gender',
  GenderOther = 'genderOther',
  Phone = 'phone',
  Email = 'email',
  Address = 'address',
  ReferralSource = 'referred by',
  ConsentsToResearch = 'consentsToResearch',
  ConsentSource = 'consentSource',
  consentsToPrivacyForm = 'consentsToPrivacyForm',
  Other = 'other',
  LinkedReferralPatientId = 'linkedReferralPatientId',
  City = 'city',
  Province = 'province',
  Country = 'country',
  PostalCode = 'postalCode'
}

interface HealthCardFormControls {
  province: FormControl;
  number: FormControl;
  country: FormControl;
}

export class HealthCardFormGroup extends FormGroup {
  constructor() {
    super(
      {
        province: new FormControl(),
        number: new FormControl(''),
        country: new FormControl()
      },
      [HealthCardFormGroup.healthCardValidator]
    );
  }
  controls: HealthCardFormControls & { [key: string]: AbstractControl };

  private static healthCardValidator = (control: HealthCardFormGroup) => {
    if (control) {
      let isValid = true;
      let errorMessage: string;

      const healthCardConfig: HealthCardMode =
        LocationHealthCardModeMap[control.controls.country.value];

      if (healthCardConfig === HealthCardMode.provinceAndNumber) {
        if (control.controls.number.value && !control.controls.province.value) {
          isValid = false;
          errorMessage = 'State/Prov must be selected if healthcare # is filled';
        }
        if (control.controls.province.value && !control.controls.number.value) {
          isValid = false;
          errorMessage = 'Healthcard # must be filled if state/prov is selected';
        }
      }

      return isValid ? null : { healthCardInvalid: errorMessage };
    }
    return null;
  };

  public reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    // Add this since GraphQL can return null for a value
    // If we find we need this in many places, then maybe create a CSIFormGroup class that
    // extends FormGroup with this utility method
    if (value === null) {
      value = {};
    }

    super.reset(value, options);
  }

  patchValue(
    value: {
      [key: string]: any;
    },
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    // See https://github.com/angular/angular/issues/21021
    if (value === null) {
      value = {};
    }
    if (value.province && value.number) {
      value.number = value.province + value.number;
      value.province = '';
    }

    super.patchValue(value, options);
  }
}

interface PatientFormControls {
  id: FormControl & { member: PatientFormControlType };
  patientDoctorId: FormControl & { member: PatientFormControlType };
  entryMethod: FormControl & { member: PatientFormControlType };
  firstName: FormControl & { member: PatientFormControlType };
  lastName: FormControl & { member: PatientFormControlType };
  dateOfBirth: FormControl & { member: PatientFormControlType };
  healthCard: HealthCardFormGroup & { member: PatientFormControlType };
  gender: FormControl & { member: PatientFormControlType };
  genderOther: FormControl & { member: PatientFormControlType };
  phone: FormControl & { member: PatientFormControlType };
  email: FormControl & { member: PatientFormControlType };
  address: FormControl & { member: PatientFormControlType };
  city: FormControl & { member: PatientFormControlType };
  province: FormControl & { member: PatientFormControlType };
  country: FormControl & { member: PatientFormControlType };
  postalCode: FormControl & { member: PatientFormControlType };
  referralSource: FormControl & { member: PatientFormControlType };
  consentsToResearch: FormControl & { member: PatientFormControlType };
  consentSource: FormControl & { member: PatientFormControlType };
  consentsToPrivacyForm: FormControl & { member: PatientFormControlType };
  linkedReferralPatientId: FormControl & { member: PatientFormControlType };
}

export class PatientFormGroup extends FormGroup {
  controls: PatientFormControls & {
    [key: string]: AbstractControl & { member: PatientFormControlType };
  };
  submitted = false;

  constructor() {
    super({
      id: new FormControl(),
      patientDoctorId: new FormControl(),
      entryMethod: new FormControl(PatientEntryMethod.AddNewPatient),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', [
        (control: AbstractControl) => {
          return control.value && isNaN(control.value) && typeof control.value !== 'string'
            ? { invalidDate: 'Date is invalid' }
            : null;
        }
      ]),
      healthCard: new HealthCardFormGroup(),
      gender: new FormControl(''),
      genderOther: new FormControl(''),
      phone: new FormControl('', phoneNumberValidator),
      email: new FormControl('', emailValidator),
      address: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      country: new FormControl(''),
      postalCode: new FormControl(''),
      referralSource: new FormControl(''),
      consentsToResearch: new FormControl(''),
      consentSource: new FormControl(ConsentSource.Doctor),
      consentsToPrivacyForm: new FormControl(''),
      linkedReferralPatientId: new FormControl('')
    });
    this.controls.id.member = PatientFormControlType.Id;
    this.controls.patientDoctorId.member = PatientFormControlType.PatientDoctorId;
    this.controls.entryMethod.member = PatientFormControlType.EntryMethod;
    this.controls.firstName.member = PatientFormControlType.FirstName;
    this.controls.lastName.member = PatientFormControlType.LastName;
    this.controls.dateOfBirth.member = PatientFormControlType.DateOfBirth;
    this.controls.healthCard.member = PatientFormControlType.HealthCard;
    this.controls.gender.member = PatientFormControlType.Gender;
    this.controls.genderOther.member = PatientFormControlType.GenderOther;
    this.controls.phone.member = PatientFormControlType.Phone;
    this.controls.email.member = PatientFormControlType.Email;
    this.controls.address.member = PatientFormControlType.Address;
    this.controls.referralSource.member = PatientFormControlType.ReferralSource;
    this.controls.consentsToResearch.member = PatientFormControlType.ConsentsToResearch;
    this.controls.consentSource.member = PatientFormControlType.ConsentSource;
    this.controls.consentsToPrivacyForm.member = PatientFormControlType.consentsToPrivacyForm;
    this.controls.linkedReferralPatientId.member = PatientFormControlType.LinkedReferralPatientId;

    this.resetOnEntryMethodChange();
  }

  toApiInput(): CreatePatientInput | UpdatePatientInput {
    this.enable();
    const input = mergeWith({}, this.value, (value: any, srcValue: any, key: string) => {
      // AWS does not like empty strings for AWSEmail and Enums and nested object properties
      // so set them to null instead. And dateOfBirth is an AWSDate object so convert to the
      // format it expects.
      // If we run into this case a lot might be worth refactoring into some kind of
      // central helper service or FormGroup class that we extend.
      if (typeof srcValue === 'string' && srcValue.trim() === '') {
        return null;
      } else if (isPlainObject(srcValue) && isEmpty(cleanDeep(srcValue))) {
        return null;
      } else if (key === 'dateOfBirth') {
        return this.toAWSDate(new Date(this.controls.dateOfBirth.value));
      } else {
        merge(value, srcValue);
      }
    });
    delete input.entryMethod;

    if (!input.linkedReferralPatientId) {
      delete input.linkedReferralPatientId;
    }

    this.disable();
    return input;
  }

  private toAWSDate(value: Date | string): string {
    // Date from AWS is in YYYY-MM-DD format, so just remove tz at end of date string
    if (typeof value === 'string') {
      return value;
    } else {
      if (value && value.toString() !== 'Invalid Date') {
        value = value.toString().split(/\+|-/)[0];
        return new Date(value).toISOString().split('T')[0];
      }
      return null;
    }
  }

  public reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    if (!value) {
      value = {};
    }
    if (!value.entryMethod) {
      value.entryMethod = value.id
        ? PatientEntryMethod.LookupExisting
        : PatientEntryMethod.AddNewPatient;
    }

    if (!value.consentSource) {
      value.consentSource = ConsentSource.Doctor;
    }

    super.reset(value, options);
  }

  public getControl(type: PatientFormControlType): AbstractControl | undefined {
    return this.controls[
      Object.keys(this.controls).find(controlKey => this.controls[controlKey].member === type)
    ];
  }

  private resetOnEntryMethodChange() {
    this.controls.entryMethod.valueChanges.subscribe(value => {
      const oldValue = this.value.entryMethod;
      if (
        oldValue === PatientEntryMethod.LookupExisting &&
        value === PatientEntryMethod.AddNewPatient
      ) {
        setTimeout(() => this.reset());
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  ConfigType,
  FormFieldConfig,
  TriggerGroup,
  TriggerGroupType
} from '../../form-template-model';
import { FormTemplateGeneratorService } from '../form-template-generator.service';

@Component({
  selector: 'csi-form-template-field-generator',
  templateUrl: './form-template-field-generator.component.html',
  styleUrls: ['./form-template-field-generator.component.scss']
})
export class FormTemplateFieldGeneratorComponent implements OnInit {
  @Input() formTemplateFieldControl: FormControl | FormGroup;
  @Input() formTemplateFieldConfig: FormFieldConfig;
  @Input() formTemplateFieldKey: string;
  @Input() isSubField = false;
  @Input() set disableControl(disable: boolean) {
    setTimeout(() => {
      if (disable) {
        this.formTemplateFieldControl.disable();

        if (!this.formTemplateGeneratorService.readOnly) {
          this.resetFormControl();
        }
      } else {
        if (!this.formTemplateGeneratorService.readOnly) {
          this.formTemplateFieldControl.enable();
        }
        if (this.formTemplateFieldConfig.typeConfig.type === 'checkbox') {
          const valuesConfig = this.formTemplateFieldConfig.typeConfig.valuesConfig;
          Object.keys(this.formTemplateFieldControl['controls']).forEach(key => {
            if (!valuesConfig[key]) {
              this.formTemplateFieldControl['controls'][key].disable();
            }
          });
        }
      }
    });
  }

  hideField = false;
  triggerFormControls: { [key: string]: FormControl | FormGroup | FormArray } = {};
  // t: { [key: string]: FormControl | FormGroup };

  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    // console.log('form-template=field', this.formTemplateFieldKey, this.formTemplateFieldControl);
    if (
      this.formTemplateFieldConfig.triggerGroups &&
      this.formTemplateFieldConfig.triggerGroups.length > 0
    ) {
      this.setTriggerFormControls();
      combineLatest(
        Object.values(this.triggerFormControls).map(
          (control: FormControl | FormGroup) =>
            control.valueChanges.pipe(startWith(null as any)) as Observable<any>
        )
      ).subscribe(() => {
        this.evaluateTriggerGroups();
      });
    }
  }

  resetFormControl() {
    if (this.formTemplateFieldConfig.typeConfig.type === ConfigType.slider) {
      // Sliders have an initial value
      this.formTemplateFieldControl.reset(
        this.formTemplateFieldConfig.typeConfig.min - this.formTemplateFieldConfig.typeConfig.step
      );
    } else {
      this.formTemplateFieldControl.reset();
    }
  }

  setTriggerFormControls() {
    this.formTemplateFieldConfig.triggerGroups.forEach(triggerGroup => {
      triggerGroup.triggers.forEach(trigger => {
        let triggerFormControl = this.formTemplateFieldControl.parent;
        trigger.key.split('.').forEach(keyFragment => {
          triggerFormControl = triggerFormControl.controls[keyFragment];
        });

        this.triggerFormControls[trigger.key] = triggerFormControl;
      });
    });
  }

  evaluateTriggerGroups() {
    const triggerField = this.formTemplateFieldConfig.triggerGroups.some(triggerGroup =>
      this.evaluateTriggerGroup(triggerGroup)
    );

    this.formTemplateFieldControl[
      triggerField && !this.formTemplateGeneratorService.readOnly ? 'enable' : 'disable'
    ]();
    this.hideField = !triggerField;

    // console.log(!this.hideField);
  }

  evaluateTriggerGroup(triggerGroup: TriggerGroup) {
    return triggerGroup.triggers[triggerGroup.type === TriggerGroupType.or ? 'some' : 'every'](
      trigger => {
        const triggerFormControl = this.triggerFormControls[trigger.key];
        return this.formTemplateGeneratorService.evaluateTrigger(
          triggerFormControl.value,
          trigger.compareValue,
          trigger.compare
        );
      }
    );
  }
}

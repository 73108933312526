import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DryEyeFormsService } from '../dry-eye-forms.service';
import { DryEyeExpansionPanel } from './../dry-eye-forms.service';
import { PreOpFormGroup } from './pre-op-form.model';
import {
  AnalysisState,
  OSDFactors,
  preOperativeKeratometryKeys,
  preOperativeKeratometryValues,
  preOperativeKeys,
  PreOPExpansionPanel,
  signsSchema
} from './pre-op.model';

@Injectable({
  providedIn: 'root'
})
export class PreOpService {
  public expansionMap: { [key in PreOPExpansionPanel]?: boolean };
  public enabledMap: { [key in PreOPExpansionPanel]?: boolean };
  public isOSDFactorsAbnormal: { [key in OSDFactors]?: boolean };
  public surgeryProceeds: boolean;
  public positiveScreen: boolean;
  public isOSDRuledIn: boolean;
  public showOptionalNonInvasiveTest: boolean;
  public osdFormGroup: PreOpFormGroup;
  public lookExamsFormGroup: PreOpFormGroup;
  public liftExamsFormGroup: PreOpFormGroup;
  public pullExamsFormGroup: PreOpFormGroup;
  public pushExamsFormGroup: PreOpFormGroup;

  public neuropathicPain = false;
  public neurotrophicCornea = false;

  public isAnalysisNormal = true;

  public signsSchema = signsSchema;
  public preOperativeKeys = preOperativeKeys;
  public preOperativeKeratometryTypes = preOperativeKeratometryKeys;
  public preOperativeKeratometryValues = preOperativeKeratometryValues;

  public defaultKeys = {
    keratometry: {
      group: null,
      keys: Object.keys(preOperativeKeratometryKeys),
      label: 'Keratometry'
    },
    osd: { group: 1, keys: [], label: 'OSD Screen' },
    look: { group: 'look', keys: [], label: 'Look' },
    lift: { group: 'lift', keys: [], label: 'Lift' },
    pull: { group: 'pull', keys: [], label: 'Pull' },
    push: { group: 'push', keys: [], label: 'Push' }
  };

  public branchKeySignKeysMap = {
    preOperativeVisit: this.preOperativeKeys
  };

  public analysisState: AnalysisState = null;

  public analysisStateUpdate$ = new Subject();

  public finalizedSurgeryProceeds: boolean;
  public finalizedSurgeryFails: boolean;

  public assessmentSaved$ = new Subject();

  constructor(public dryEyeFormsService: DryEyeFormsService) {
    this.stateMapsInit();

    Object.values(this.defaultKeys).forEach(value => {
      if (value.group) {
        value.keys = Object.keys(this.signsSchema).filter(
          key => this.signsSchema[key].group === value.group
        );
      }
    });
  }

  checkForOSDLikeliness() {
    this.positiveScreen = this.isOSDFactorsAbnormal.signs || this.isOSDFactorsAbnormal.symptom;
  }

  checkForAbnormality() {
    this.isOSDRuledIn = Object.values(this.isOSDFactorsAbnormal).some(
      osdFactor => osdFactor === true
    );
    this.calculateNeuroPathic();
  }

  calculateNeuroPathic() {
    this.neurotrophicCornea = false;
    this.neuropathicPain = false;

    if (
      this.isOSDFactorsAbnormal.symptom &&
      !this.isOSDFactorsAbnormal.signs &&
      !this.isOSDFactorsAbnormal.stains
    ) {
      this.neuropathicPain = true;
      this.surgeryProceeds = true;
    }
    if (
      !this.isOSDFactorsAbnormal.symptom &&
      this.isOSDFactorsAbnormal.signs &&
      this.isOSDFactorsAbnormal.stains
    ) {
      this.surgeryProceeds = false;
      this.neurotrophicCornea = true;
    }
  }
  changeExpandedPanel(
    currentExpansionPanelKey: PreOPExpansionPanel,
    nextExpansionPanelKey: PreOPExpansionPanel | DryEyeExpansionPanel,
    optionalNextExpansionPanelKey?: PreOPExpansionPanel,
    event?: PointerEvent,
    formGroup?: FormGroup
  ) {
    if (formGroup && formGroup.invalid) {
      if (formGroup instanceof PreOpFormGroup) {
        formGroup.submitted = true;
      }
      formGroup.markAllAsTouched();
    } else {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.expansionMap[currentExpansionPanelKey] = false;
      this.expansionMap[nextExpansionPanelKey] = true;
      this.expansionMap[optionalNextExpansionPanelKey] = true;
    }
  }

  stateMapsInit() {
    this.expansionMap = {
      symptom: true,
      preOperativeVisit: false,
      preOperativeKeratometryMeasurements: false,
      osdScreen: false,
      osdScreeningSigns: false,
      optionalNoninvasiveOSDTests: false,
      clinicalExams: false,
      impressions: false,
      analysis: false,
      verdict: false,
      treatmentsPlan: false
    };

    this.enabledMap = {
      symptom: true,
      preOperativeVisit: false,
      preOperativeKeratometryMeasurements: false,
      osdScreen: false,
      osdScreeningSigns: false,
      optionalNoninvasiveOSDTests: false,
      clinicalExams: false,
      impressions: false,
      analysis: false,
      verdict: false,
      treatmentsPlan: false
    };

    this.isOSDFactorsAbnormal = {
      symptom: false,
      signs: false,
      stains: false,
      exams: false
    };

    this.analysisState = {
      speed: {
        abnormal: false,
        message: 'There is abnormality in symptoms: Speed score was above the threshold of 5.',
        simpleMessage: 'Speed score > 5',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      speedii: {
        abnormal: false,
        message: 'There is abnormality in symptoms: Speed II score was above the threshold of 4.',
        simpleMessage: 'Speed II score > 4',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      yesToDropsOrOintments: {
        abnormal: false,
        message: 'Patient has declared that they use Drops or Ointments.',
        simpleMessage: 'Patient uses drops or ointments.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      noToDropsLastFourHours: {
        abnormal: false,
        message: "Patient's Drops/Ointments do not last 4 hrs.",
        simpleMessage: 'Drops/Ointments do not last 4 hrs.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      noToGelsLastTweleveHours: {
        abnormal: false,
        message: "Patient's Gel does not last 12 hrs.",
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      yesToBlepharitis: {
        abnormal: false,
        message: 'Patient has been told they have Blepharitis.',
        simpleMessage: 'Patient may have Blepharitis.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      yesToStye: {
        abnormal: false,
        message: 'Patient has been told they have STYE.',
        simpleMessage: 'Patient may have STYE.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      frequentlyOrALotToFluctuatingVisionProblems: {
        abnormal: false,
        message:
          'Patient frequently or always has fluctuating vision problems that get better with blinking.',
        simpleMessage: 'Patient may have fluctuating vision problems.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      noToContactLensHoliday: {
        abnormal: false,
        message: 'Contact lens holiday not greater than 2 weeks for patient.',
        simpleMessage: 'CL holiday not greater than 2 weeks.',
        technicianNote: '',
        significant: true,
        group: 'preoperativeVisit'
      },
      yesToEyeDrops: {
        abnormal: false,
        message: 'Eye drops were used 2 Hrs prior.',
        technicianNote: '',
        significant: true,
        group: 'preoperativeVisit'
      },
      rightEyeKeratometryDValueDifferences: {
        abnormal: false,
        message: 'A difference of more than one diopter between the right eye keratometry tests.',
        simpleMessage: 'Right eye keratometry tests diopter value difference > 1.',
        significant: true,
        required: true,
        group: 'keratometryMeasurements'
      },
      leftEyeKeratometryDValueDifferences: {
        abnormal: false,
        message: 'A difference of more than one diopter between the left eye keratometry tests.',
        simpleMessage: 'Left eye keratometry tests diopter value difference > 1.',
        significant: true,
        required: true,
        group: 'keratometryMeasurements'
      },
      rightEyeKeratometryClassificationDifferences: {
        abnormal: false,
        message: 'A difference in the classification between the right eye keratometry tests.',
        simpleMessage: 'Right eye keratometry tests classification difference.',
        significant: true,
        required: true,
        group: 'keratometryMeasurements'
      },
      leftEyeKeratometryClassificationDifferences: {
        abnormal: false,
        message: 'A difference in the classification between the left eye keratometry tests.',
        simpleMessage: 'Left eye keratometry tests classification difference.',
        significant: true,
        required: true,
        group: 'keratometryMeasurements'
      },
      osmolarity: {
        abnormal: false,
        message: 'Osmolarity difference greater than 8 mOsms/L asymmetry between both eyes.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      rightOsmolarity: {
        abnormal: false,
        message: 'Abnormality in the Osmolarity right eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      leftOsmolarity: {
        abnormal: false,
        message: 'Abnormality in the Osmolarity left eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      rightMMP9: {
        abnormal: false,
        message: 'Abnormality in the MMP9 right eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      leftMMP9: {
        abnormal: false,
        message: 'Abnormality in the MMP9 left eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      rightEpithelialThickness: {
        abnormal: false,
        message: 'Abnormality in the Epithelial Thickness right eye test.',
        significant: true,
        group: 'osdScreenSigns'
      },
      leftEpithelialThickness: {
        abnormal: false,
        message: 'Abnormality in the Epithelial Thickness left eye test.',
        significant: true,
        group: 'osdScreenSigns'
      },
      rightSchirmerTest: {
        abnormal: false,
        message: 'Abnormality in the Schirmer Test right eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      leftSchirmerTest: {
        abnormal: false,
        message: 'Abnormality in the Schirmer Test left eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      rightMeibography: {
        abnormal: false,
        message: 'Abnormality in the Meibography right eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      leftMeibography: {
        abnormal: false,
        message: 'Abnormality in the Meibography left eye test.',
        significant: true,
        required: true,
        group: 'osdScreenSigns'
      },
      rightNITBUT: {
        abnormal: false,
        message: 'Abnormality in the NITBUT right eye test.',
        significant: true,
        required: true,
        group: 'optionalNonInvasiveOsdTests'
      },
      leftNITBUT: {
        abnormal: false,
        message: 'Abnormality in the NITBUT left eye test.',
        significant: true,
        required: true,
        group: 'optionalNonInvasiveOsdTests'
      },
      rightLipidLayer: {
        abnormal: false,
        message: 'Abnormality in the Lipid Layer right eye test.',
        significant: true,
        required: true,
        group: 'optionalNonInvasiveOsdTests'
      },
      leftLipidLayer: {
        abnormal: false,
        message: 'Abnormality in the Lipid Layer left eye test.',
        significant: true,
        required: true,
        group: 'optionalNonInvasiveOsdTests'
      }
    };

    this.analysisStateUpdate$ = new Subject();
    this.osdFormGroup = null;
    this.lookExamsFormGroup = null;
    this.liftExamsFormGroup = null;
    this.pullExamsFormGroup = null;
    this.pushExamsFormGroup = null;
  }
}

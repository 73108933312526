import {
  AfterViewInit,
  Component,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AssessmentRequestType } from 'src/API';
import { ResponseFormGroup } from './response-form.model';

@Component({
  selector: 'csi-response-form',
  templateUrl: './response-form.component.html',
  styleUrls: ['./response-form.component.css']
})
export class ResponseFormComponent implements AfterViewInit, OnDestroy {
  @Input() formGroup: ResponseFormGroup;

  @ViewChild('eReferral', { static: true }) eReferralTemplate: TemplateRef<any>;

  private snackToDismiss: MatSnackBarRef<EmbeddedViewRef<any>>;

  constructor(private snackBar: MatSnackBar) {}

  ngAfterViewInit() {
    if (!this.isEConsult()) {
      setTimeout(() => {
        this.snackToDismiss = this.snackBar.openFromTemplate(this.eReferralTemplate, {
          verticalPosition: 'top',
          panelClass: ['snack-make-room-for-navbar', 'response-snack']
        });
      });
    }
  }

  ngOnDestroy() {
    if (!!this.snackToDismiss) {
      this.snackToDismiss.dismiss();
      this.snackToDismiss = null;
    }
  }

  public isEConsult(): boolean {
    return this.formGroup.controls.type.value === AssessmentRequestType.EConsult;
  }

  public get hasResponse(): boolean {
    return !!this.formGroup.controls.response.value;
  }
}

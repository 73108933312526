import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material';
import { IntakeFormType } from 'src/app/intake-form/intake-form-schema';

@Component({
  selector: 'csi-intake-form-setup-modal',
  templateUrl: './intake-form-setup-modal.component.html',
  styleUrls: ['./intake-form-setup-modal.component.css']
})
export class IntakeFormSetupModalComponent implements OnInit {
  @Input() intakeFormLink: string;
  @Input() intakeFormType: IntakeFormType;
  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;

  public readonly copyLink = 'Copy Intake Form Link';
  public readonly copied = 'Copied';
  public toolTipContent = this.copyLink;

  constructor() {}

  ngOnInit() {}

  onCopy() {
    this.toolTipContent = this.copied;
    this.tooltip.show();
    setTimeout(() => (this.toolTipContent = this.copyLink), 3500);
    setTimeout(() => {
      this.tooltip.show();
    });
  }

  downloadQrCode(qrCode) {
    const qrCodeElement = qrCode.qrcElement.nativeElement.querySelector('img').src;
    const imageBlob = this.convertBase64ToBlob(qrCodeElement);
    const imageData = new Blob([imageBlob], { type: 'image/png' });
    const url = window.URL.createObjectURL(imageData);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qrCode';
    link.click();
  }
  convertBase64ToBlob(base64Image: any) {
    const parts = base64Image.split(';base64,');

    const imageType = parts[0].split(':')[1];

    const decodedData = atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }
}

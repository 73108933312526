import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'csi-mat-form-field-group',
  templateUrl: './mat-form-field-group.component.html',
  styleUrls: ['./mat-form-field-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatFormFieldGroupComponent {
  @Input() control: AbstractControl = new FormControl();

  constructor(private hostElementRef: ElementRef) {}

  @HostBinding('class.csi-mat-form-field-group-has-label') get hasLabel(): boolean {
    return (
      this.hostElement.querySelectorAll(
        'csi-mat-form-field-group-input + .mat-form-field-label-wrapper > label > mat-label'
      ).length === 2
    );
  }

  private get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }
}

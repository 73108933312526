import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { InteractionStatus } from 'src/app/patients/patient/interactions/interactions.model';
import { ClinicSetupService } from './../clinic-setup-modal/clinic-setup.service';
import { InteractionStatusSetupActionsModalComponent } from './interaction-status-setup-actions-modal/interaction-status-setup-actions-modal.component';
import { InteractionsSetupFormGroup } from './interactions-setup-form.model';
import { defaultInteractionTypes, requiredInteractionTypes, typeOfSurgeries } from './interactions-setup.model';

@Component({
  selector: 'csi-interactions-setup-modal',
  templateUrl: './interactions-setup-modal.component.html',
  styleUrls: ['./interactions-setup-modal.component.css']
})
export class InteractionsSetupModalComponent implements OnInit {
  public isSaving;
  public statusKeyFriendlyNameMap = {
    name: 'Name',
    bookAppointment: 'Book Appointment',
    failCommunicationStatus: 'Fail Communication',
    notInterested: 'Patient Not Interested',
    selectSurgeryType: 'Select Surgery Type',
    onlyShowFor: 'Only Show For',
    failSurgery: 'Fail Surgery',
    color: 'Color'
  };

  public interactionsFormGroup = new InteractionsSetupFormGroup();
  public statusKeysWithoutActions = Object.keys(this.statusKeyFriendlyNameMap);
  public allInteractionStatusKeys;
  // public allInteractionStatusKeys = [...this.statusKeysWithoutActions, 'actions'];
  public listOfDefaultSurgeries = typeOfSurgeries;
  public listOfDefaultInteractionType = defaultInteractionTypes;
  public requiredInteractionTypeMap = requiredInteractionTypes;
  public isClinicAdmin :boolean;


  constructor(
    private dialog: MatDialog,
    private clinicSetupService: ClinicSetupService,
    private matDialogRef: MatDialogRef<InteractionsSetupModalComponent>
  ) {}

  ngOnInit() {
    this.isClinicAdmin  = this.clinicSetupService.isClinicAdmin;
    this.allInteractionStatusKeys = (this.isClinicAdmin ) ? [...this.statusKeysWithoutActions, 'actions'] : this.statusKeysWithoutActions;
    this.interactionsFormGroup.patchValueFromInteractionSetup(
      this.clinicSetupService.interactionsSetup
    );
  }

  public static open(matDialogService: MatDialog, trackingData?: any) {
    return matDialogService.open(InteractionsSetupModalComponent, {
      width: '80vw',
      minWidth: '54rem',
      maxWidth: '95vw',
      data: { trackingData }
    });
  }

  openStatusActionModal(status?: InteractionStatus) {
    InteractionStatusSetupActionsModalComponent.open(
      this.dialog,
      this.interactionsFormGroup.controls.types.value,
      this.interactionsFormGroup.controls.surgeryTypes.value,
      status
    )
      .afterClosed()
      .subscribe(newOrModifiedStatus => {
        if (newOrModifiedStatus) {
          this.interactionsFormGroup.controls.statuses.value[
            newOrModifiedStatus.name
          ] = newOrModifiedStatus;
          this.interactionsFormGroup.controls.statuses.setValue({
            ...this.interactionsFormGroup.controls.statuses.value
          });
        }
      });
  }

  deleteStatus(statusKey) {
    delete this.interactionsFormGroup.controls.statuses.value[statusKey];
    this.interactionsFormGroup.controls.statuses.setValue({
      ...this.interactionsFormGroup.controls.statuses.value
    });
  }

  save() {
    this.isSaving = true;
    this.clinicSetupService
      .updateClinicSetup({
        id: this.clinicSetupService.clinicSetupId,
        interactionsSetup: JSON.stringify(this.interactionsFormGroup.value)
      })
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe(() => {
        this.clinicSetupService.interactionsSetup = this.interactionsFormGroup.value;
        this.matDialogRef.close();
      });
  }
}

import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { formatDate } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  LOCALE_ID,
  NgZone
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { Observable, iif, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { Clinic, ClinicService } from 'src/app/core/api/clinic.service';
import { Html2PdfService } from 'src/app/core/api/html-2-pdf.service';
import { IntakeFormPdfExportComponent } from './intake-form-pdf-export/intake-form-pdf-export.component';

@Injectable({
  providedIn: 'root'
})
export class IntakeFormPdfExportService {
  private pdfExportComponentPortal: ComponentPortal<IntakeFormPdfExportComponent>;
  private debugWindow: Window;
  private bodyPortalHost: DomPortalHost;
  private logger: Logger = new Logger('PDFExportService');
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private html2PdfService: Html2PdfService,
    private clinicService: ClinicService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private ngZone: NgZone
  ) {
    this.setupPortals();
  }

  public downloadPDFForIntakeForm(
    intakeFrom: any,
    clinic: Clinic | Doctor['clinic'],
    debug: boolean = false
  ): Observable<boolean> {
    const filename = `Intake-Form_${this.getDateForPDFFilename()}.pdf`;
    return this.getHtmlForIntakeForm(intakeFrom, clinic, false, debug).pipe(
      switchMap(html => this.html2PdfService.saveHtmlAsPdf(html, filename))
    );
  }

  public getHtmlForIntakeForm(
    intakeFrom: any,
    clinic: Clinic | Doctor['clinic'],
    hideDataForFaxing: boolean = false,
    debug: boolean = false
  ): Observable<string> {
    if (debug) {
      // Open the debug window here since this spot is the only spot that won't trigger browser
      // popup blocking if this method is called from a click handler.
      this.openDebugWindow();
    }
    return iif(() => !!clinic, this.clinicService.getClinicLogo(clinic.id), of(null)).pipe(
      switchMap(clinicLogo => {
        const componentRef = this.bodyPortalHost.attach(this.pdfExportComponentPortal);
        (componentRef.location.nativeElement as HTMLElement).style.display = 'none';

        componentRef.instance.data = intakeFrom;
        componentRef.instance.clinic = clinic;
        componentRef.instance.clinicLogo = clinicLogo;

        this.logger.debug('here is my data', componentRef.instance.data);

        return (componentRef.instance.html as Observable<string>).pipe(
          tap(html => {
            this.bodyPortalHost.detach();

            if (debug) {
              this.writeHTMLToDebugWindow(html);
            }
          })
        );
      })
    );
  }

  private getDateForPDFFilename(): string {
    return formatDate(new Date(), 'yyyy-mm-ddThh-mm-ss', this.locale);
  }
  private openDebugWindow() {
    this.debugWindow = window.open('', '_blank');
    this.debugWindow.document.title = 'CSI PDF Debug Loading...';
  }
  private writeHTMLToDebugWindow(html: string) {
    this.debugWindow.document.write(html);
    this.debugWindow.document.title = 'CSI PDF Debug';

    // For some reason the dry eye category component renders weird without this.
    // The 227px is the minimum but it can be set higher (lower values also cause
    // weird rendering). After 1 cycle we can clear the height (we do this
    // otherwise the body has no padding at the bottom).
    this.debugWindow.document.body.style.height = '227px';
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.debugWindow.document.body.style.height = '';
      });
    });
  }

  private setupPortals() {
    this.pdfExportComponentPortal = new ComponentPortal(IntakeFormPdfExportComponent);
    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocationSelectService } from 'src/app/shared/location-select/location-select.service';

type PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'csi-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.css']
})
export class AddressAutocompleteComponent implements OnInit {
  @Input() public addressFormGroup: FormGroup;
  @Input() public showRequiredFields: boolean;

  public readonly countryWithProvincesOrStates$: Observable<
    string[]
  > = this.locationSelectService.getCountriesWithStateOrProvince();

  constructor(private locationSelectService: LocationSelectService) {}

  ngOnInit() {}

  onCountryLocationChange() {
    this.addressFormGroup.controls.province.setValue('');
  }

  onAddressSelected(event: PlaceResult) {
    let country = '';
    let province = '';
    let postal_code = '';
    let city = '';

    event.address_components.forEach(address_component => {
      if (address_component.types.includes('country')) {
        country = address_component.short_name;
      } else if (address_component.types.includes('postal_code')) {
        postal_code = address_component.long_name;
      } else if (address_component.types.includes('administrative_area_level_1')) {
        province = address_component.short_name;
      } else if (
        address_component.types.includes('locality') &&
        address_component.types.includes('political')
      ) {
        city = address_component.long_name;
      }
    });

    const formControls = this.addressFormGroup.controls;
    formControls.address.setValue(event.name);
    formControls.country.setValue(country);
    formControls.city.setValue(city);

    formControls.province.setValue(province);

    formControls.postalCode.setValue(postal_code);
    this.addressFormGroup.markAsDirty();
    this.addressFormGroup.markAllAsTouched();
  }
}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Drug, DrugLookupQuery, DrugLookupQueryVariables } from 'src/API';
import { AppSyncService } from 'src/app/core/appsync.service';
import { drugLookup } from 'src/graphql/queries';
export type MedicationLookupResult = DrugLookupQuery['drugLookup'];

@Injectable({
  providedIn: 'root'
})
export class DrugbankLookupService {
  constructor(private appSyncService: AppSyncService) {}

  public searchMedication(query: DrugLookupQueryVariables): Observable<Drug[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.query({
          query: gql(drugLookup),
          variables: query,
          fetchPolicy: 'no-cache'
        });
      }),
      map(result => {
        const drugMedications: Drug[] = result.data['drugLookup'];
        return drugMedications;
      })
    );
  }
}

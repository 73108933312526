import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatTooltip } from '@angular/material';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash-es';
import { concat, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  Treatment,
  TreatmentClass,
  TreatmentsService
} from '../../core/remote-json/treatments/treatments.service';
import { TreatmentFilter } from '../../shared/consult-forms/dry-eye-forms/dry-eye-assessment-form/treatment-filters/treatment-filter.model';

@Component({
  selector: 'csi-treatment-picker',
  templateUrl: './treatment-picker.component.html',
  styleUrls: ['./treatment-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreatmentPickerComponent implements OnInit {
  @Input() dropdownPosition = 'auto';
  @Input() control: FormControl;
  @Input() treatmentClass: TreatmentClass = 'otc';
  @Input() set allFilters(filters: TreatmentFilter[]) {
    this._allFilters = filters.filter(filter => !filter.hidden);
  }
  @Input() set activeFilters(filters: TreatmentFilter[]) {
    this._activeFilters = filters;
    this.refreshTreatmentList();
  }
  get activeFilters() {
    return this._activeFilters;
  }

  public treatmentToView: Treatment;
  public filteredTreatments$: Observable<Treatment[]>;
  public typeahead = new Subject<string>();
  public treatments$: Observable<ReadonlyArray<Treatment>>;
  public _allFilters: TreatmentFilter[];

  readonly faSearchPlus = faSearchPlus;

  @ViewChild('viewTreatmentTooltip', { static: false }) viewTreatmentTooltip: MatTooltip;
  @ViewChild('treatmentModalTemplate', { static: true }) treatmentModalTemplate: TemplateRef<any>;

  private _activeFilters: TreatmentFilter[] = [];
  private previousSearchText = '';

  constructor(private dialog: MatDialog, private treatmentsService: TreatmentsService) {}

  ngOnInit() {
    this.initializeTreatments();
    this.filteredTreatments$ = concat(
      this.treatments$.pipe(map(treatments => [...treatments])),
      this.typeahead.pipe(
        tap(searchText => (this.previousSearchText = searchText)),
        switchMap(searchText => this.getTreatments(searchText))
      )
    ).pipe(map(treatments => this.sortByPopularity(treatments)));
  }

  get placeholder(): string {
    if (this.treatmentClass === 'rx') {
      return 'Search prescription eye drops, gels, and tablets...';
    } else if (this.treatmentClass === 'otc') {
      return 'Search over the counter eye drops, gels, and wipes...';
    } else if (this.treatmentClass === 'exercise') {
      return 'Search exercises...';
    } else {
      return 'Search procedures...';
    }
  }

  public setSelectedFilters(updatedFilters: TreatmentFilter[]) {
    this.activeFilters = updatedFilters;
    this.refreshTreatmentList();
  }

  public clearPreviousText() {
    this.previousSearchText = '';
    this.refreshTreatmentList();
  }

  public refreshTreatmentList() {
    this.typeahead.next(this.previousSearchText);
  }

  public isPopular(treatment: Treatment): boolean {
    return this.popularForFilters(treatment).some(popularFor => !!popularFor);
  }

  private popularForFilters(treatment: Treatment): string[] {
    return this._allFilters
      ? this._allFilters.map(filter =>
          filter.isPopularFor ? filter.isPopularFor(treatment) : null
        )
      : [];
  }

  public tooltipForPopularIcon(treatment: Treatment): string {
    return `Popular for ${startCase(
      this.popularForFilters(treatment).reduce((previous, current) =>
        current ? `${current}, ${previous}` : previous
      )
    )} Category`;
  }

  viewTreatment(treatment: Treatment, event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.treatmentToView = treatment;
    this.dialog.open(this.treatmentModalTemplate, {
      position: { top: '5rem' },
      minWidth: '50rem',
      minHeight: '18.725rem',
      maxHeight: '85vh'
    });

    this.viewTreatmentTooltip.hide();
  }

  private sortByPopularity(treatments: Treatment[]): Treatment[] {
    const sortAlphabetically = (treatmentA, treatmentB) =>
      treatmentA.name > treatmentB.name ? 1 : -1;
    const popularTreatments = treatments
      .filter(treatment => this.isPopular(treatment))
      .sort(sortAlphabetically);
    const notPopularTreatments = treatments
      .filter(treatment => !this.isPopular(treatment))
      .sort(sortAlphabetically);
    return [...popularTreatments, ...notPopularTreatments];
  }

  private initializeTreatments() {
    if (this.treatmentClass === 'rx') {
      this.treatments$ = this.treatmentsService.rxEyeDrops$;
    } else if (this.treatmentClass === 'otc') {
      this.treatments$ = this.treatmentsService.otcEyeDrops$;
    } else if (this.treatmentClass === 'exercise') {
      this.treatments$ = this.treatmentsService.exercises$;
    } else {
      this.treatments$ = this.treatmentsService.procedures$;
    }
  }

  private getTreatments(term: string): Observable<Treatment[]> {
    return this.treatments$.pipe(
      map(treatments =>
        treatments.filter(
          treatment => this.hasTerm(term, treatment) && this.passesFilters(treatment)
        )
      )
    );
  }

  private passesFilters(treatment: Treatment): boolean {
    return this.activeFilters.every(filter => filter.filter(treatment));
  }

  private hasTerm(term: string, treatment: Treatment): boolean {
    if (!!term) {
      const words: string[] = term.toLocaleLowerCase().split(/\W+-/);
      return words.every(word => treatment.searchText.includes(word));
    }
    return true;
  }
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { QuestionGroup } from '../questionnaire-form-builder';

@Component({
  selector: 'csi-question-step',
  templateUrl: './question-step.component.html',
  styleUrls: ['./question-step.component.css']
})
export class QuestionStepComponent {
  @Input() questionGroup: QuestionGroup;
  @Input() formGroup: FormGroup;
  @Input() hasError: boolean;
  @Input() questionKey: string;

  constructor(public translocoService: TranslocoService) { }

  ngOnInit() {
  }
}

import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

interface DrawModalData {
  formControl: FormControl;
}

@Component({
  selector: 'csi-draw-over-image-modal',
  templateUrl: './draw-over-image-modal.component.html',
  styleUrls: ['./draw-over-image-modal.component.css']
})
export class DrawOverImageModalComponent {
  public save = false;

  public commentFormControl = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DrawModalData) {
    if (data.formControl.value) {
      this.commentFormControl.setValue(data.formControl.value.csiComments);
    }
    if (data.formControl.disabled) {
      this.commentFormControl.disable();
    }
  }
}

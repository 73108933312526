import { Component, Input, ViewChild } from '@angular/core';
import uniq from 'lodash-es/uniq';
import { DryEyeFormGroup } from '../../consult-forms/dry-eye-forms/dry-eye-form/dry-eye-form.model';
import { PreOpFormGroup } from '../../consult-forms/dry-eye-forms/pre-op/pre-op-form.model';
import { AbstractSymptomControlComponent } from '../abstract-symptom-control/abstract-symptom-control.component';
import { Symptom, SymptomTypes } from '../symptom.model';

@Component({
  selector: 'csi-symptom-control-adapter',
  templateUrl: './symptom-control-adapter.component.html',
  styleUrls: ['./symptom-control-adapter.component.css']
})
export class SymptomControlAdapterComponent {
  public readonly SymptomTypes = SymptomTypes;

  @Input() formKey: string;
  @Input() symptom: Symptom;
  @Input() formGroup: DryEyeFormGroup | PreOpFormGroup;

  @ViewChild(AbstractSymptomControlComponent, { static: false })
  symptomControl: AbstractSymptomControlComponent;

  get allPossibleSymptomControlKeys(): string[] {
    return uniq(this.symptomControl.getAllPossibleSymptomControlKeys());
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Patient } from '../../../core/api/patient.service';
import {
  PatientFormControlType,
  PatientFormGroup
} from '../../../shared/consult-forms/patient-form/patient-form.model';
import { CSVPatientsFormGroup } from '../import-wizard-file-received/csv-patients-form-group.model';
import { MassClinicService } from './../../../mass-clinics/mass-clinic.service';
import { ImportWizardCheckExistingPatientsService } from './import-wizard-check-existing-patients.service';

@Component({
  selector: 'csi-import-wizard-check-existing-patients',
  templateUrl: './import-wizard-check-existing-patients.component.html',
  styleUrls: ['./import-wizard-check-existing-patients.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportWizardCheckExistingPatientsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isMassClinic: boolean;
  @Input() csvPatientsFormGroup: CSVPatientsFormGroup;
  @Output() cancel: EventEmitter<null> = new EventEmitter();
  @Output() patientsCreated: EventEmitter<null> = new EventEmitter();
  @Output() goBack: EventEmitter<null> = new EventEmitter();
  public matSpinnerOptions: MatProgressButtonOptions;
  protected existingPatients: boolean[] = [];
  public awsError: string;
  public patients: Patient[] = [];
  protected columnNamesMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    healthCard:'Health Card',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    action: ''
  };

  protected get displayableControls(): string[] {
    return this.importWizardCheckExistingPatientsService
      .reduceToUniqueKeys(this.patients)
      .filter(
        attribute =>
          this.patients.every(patient => patient[attribute] !== '') &&
          !!this.columnNamesMap[attribute as PatientFormControlType]
      );
  }

  protected get columns(): string[] {
    return this.csvPatientsFormGroup.hasExistingPatients
      ? [...this.displayableControls, 'action']
      : this.displayableControls;
  }

  constructor(
    private importWizardCheckExistingPatientsService: ImportWizardCheckExistingPatientsService,
    private changeDetectorRef: ChangeDetectorRef,
    private massClinicService: MassClinicService
  ) {}

  ngOnInit() {
    this.patients = [...(this.csvPatientsFormGroup.controls.patients.value as Patient[])];
    this.matSpinnerOptions = {
      active: true,
      text: '',
      raised: true,
      buttonColor: 'primary',
      spinnerSize: 19,
      mode: 'indeterminate'
    };
    this.importWizardCheckExistingPatientsService
      .getAndPatchDuplicates(
        this.csvPatientsFormGroup.controls.patients.controls as PatientFormGroup[]
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.awsError = 'Could not connect to the database: ' + err.toString();
          return of([]);
        })
      )
      .subscribe(existingPatients => {
        this.setExistingPatients(existingPatients);
        this.matSpinnerOptions.active = false;
        this.updateButtonText();
      });
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['csvPatientsFormGroup']) {
      this.csvPatientsFormGroup.controls.shouldCreateDuplicates.valueChanges.subscribe(() =>
        this.updateButtonText()
      );
    }
  }

  protected createPatients() {
    this.matSpinnerOptions.active = true;
    this.csvPatientsFormGroup.controls.shouldCreateDuplicates.disable();
    const patientsToCreate = this.csvPatientsFormGroup.patientsToCreate;
    this.importWizardCheckExistingPatientsService
      .createAndPatchNewPatients(patientsToCreate)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.csvPatientsFormGroup.controls.shouldCreateDuplicates.enable();
        this.csvPatientsFormGroup.updateThisAndDescendants();
        setTimeout(() => this.patientsCreated.emit(null));
      });
  }

  protected editPatient(patientIndex: number) {
    this.massClinicService
      .openPatientModal(
        this.csvPatientsFormGroup.controls.patients.controls[patientIndex].value,
        false
      )
      .afterClosed()
      .subscribe((modifiedPatient: Patient) => {
        if (modifiedPatient) {
          this.csvPatientsFormGroup.controls.patients.controls[patientIndex].patchValue(
            modifiedPatient
          );
          this.patients[patientIndex] = modifiedPatient;
          this.patients = [...this.patients];
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private updateButtonText() {
    this.matSpinnerOptions.text =
      this.csvPatientsFormGroup.patientsToCreate.length > 0
        ? 'Create ' +
          this.csvPatientsFormGroup.patientsToCreate.length +
          ' New Patient' +
          (this.csvPatientsFormGroup.patientsToCreate.length === 1 ? '' : 's')
        : 'Continue';
  }

  private setExistingPatients(existingPatients: boolean[]) {
    this.existingPatients = existingPatients;
    this.csvPatientsFormGroup.controls.doesPatientExist.setValue(existingPatients);
    this.changeDetectorRef.markForCheck();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireRequest } from './../../../../API';

@Pipe({
  name: 'isQuestionnaireLinked'
})
export class IsQuestionnaireLinkedPipe implements PipeTransform {
  transform(questionnaireQuery: { items: Partial<QuestionnaireRequest>[] }): boolean {
    return (
      questionnaireQuery &&
      questionnaireQuery.items &&
      questionnaireQuery.items.filter(questionnaire => questionnaire.response).length > 0
    );
  }
}

import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalSymptomCustomModalOptions } from '../../../symptom.model';
import {
  AbstractSymptomModalComponent,
  SymptomModalConfig
} from '../../abstract-symptom-modal.component';

@Component({
  selector: 'csi-mge-secretion-modal',
  templateUrl: './mge-secretion-modal.component.html',
  styleUrls: ['./mge-secretion-modal.component.scss']
})
export class MgeSecretionModalComponent
  extends AbstractSymptomModalComponent<ModalSymptomCustomModalOptions>
  implements OnInit {
  public readonly numberOfGlands = 15;
  public glandControls: FormControl[] = Array.from(
    { length: this.numberOfGlands },
    () => new FormControl()
  );

  right = ['6', '7', '8', '9', '10'].map(num => `${this.formKey}-gland${num}`);
  center = ['1', '2', '3', '4', '5'].map(num => `${this.formKey}-gland${num}`);
  left = ['11', '12', '13', '14', '0'].map(num => `${this.formKey}-gland${num}`);

  all = [...this.left, ...this.center, ...this.right];

  public internalFormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<MgeSecretionModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: SymptomModalConfig<ModalSymptomCustomModalOptions>,
    protected hostElement: ElementRef
  ) {
    super(config, hostElement);
  }

  ngOnInit() {
    (this.hostElement.nativeElement as HTMLElement).parentElement.style.top = '-20px';

    this.glandControls.forEach((glandControl, index) => {
      const glandKey = this.glandKeyForIndex(index);
      this.internalFormGroup.addControl(glandKey, glandControl);

      const externalControl = this.formGroup.get(glandKey);
      if (externalControl) {
        glandControl.setValue(externalControl.value.toString());
      } else {
        glandControl.setValue('0');
      }

      if (this.isDisabled) {
        glandControl.disable();
      }
    });
  }

  public setValue() {
    this.glandControls.forEach((glandControl, index) => {
      const glandKey = this.glandKeyForIndex(index);
      const glandValue = parseInt(glandControl.value, 10);
      const externalControl = this.formGroup.get(glandKey);

      if (externalControl) {
        externalControl.setValue(glandValue);
      } else {
        this.formGroup.addControl(glandKey, new FormControl(glandValue));
      }
    });

    this.formGroup.get(this.formKey).setValue(this.value);

    this.dialogRef.close();
  }

  get totalScore(): number {
    return this.glandControls
      .map(glandControl => parseInt(glandControl.value, 10))
      .reduce((total, grade) => total + grade);
  }

  get value(): string {
    const totalScore = this.totalScore;
    if (totalScore <= 4) {
      return 'minimal';
    } else if (totalScore > 4 && totalScore <= 8) {
      return 'mild';
    } else if (totalScore > 8 && totalScore <= 13) {
      return 'moderate';
    } else {
      return 'severe';
    }
  }

  public getAllPossibleSymptomControlKeys(): string[] {
    const glandKeys: string[] = this.glandControls.map((control, index) =>
      this.glandKeyForIndex(index)
    );
    return super.getAllPossibleSymptomControlKeys().concat(glandKeys);
  }

  private glandKeyForIndex(index: number): string {
    return this.getExternalKeyFromInternalKey('gland' + index);
  }
}
